import React from "react";
import Lottie from "react-lottie";

import { loaderOptions } from "containers/App/components/Loader/Loader";

import "./loaderWrapper.scss";

interface LoaderProps {
  isLoading?: boolean;
  loaderSize?: number;
}
const LOADER_SIZE = 150;

export const Loader: React.FunctionComponent<LoaderProps> = ({ isLoading = true, loaderSize = LOADER_SIZE }) => {
  return (
    <div className="loaderWrapper">
      {isLoading && <Lottie options={loaderOptions} height={loaderSize} width={loaderSize} />}
    </div>
  );
};
