import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import moment from "moment";
import ReactPlayer from "react-player";

import { getCommunity, getCommunitySubscriptions, getSubscriptionAttention } from "../../store/selectors";
import CreateEventCard from "../../components/CommunityDashboard/CreateEventCard/CreateEventCard";
import MeetingRoomCard from "../../components/CommunityDashboard/MeetingRoomCard/MeetingRoomCard";
import { actions, EventType } from "../../../Sermons";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../../constants";
import { getSermonList } from "../../../Sermons/store/selectors";
import { EFeature, EStripeStatusCode } from "../../interfaces";
import SubscriptionInfo from "../../components/CommunityDashboard/SubscriptionInfo/SubscriptionInfo";
import EngagementCard from "../../components/CommunityDashboard/EngagementCard/EngagementCard";

import SubscriptionAttentionBanner from "shared/components/AttentionBanner/SubscriptionAttentionBanner";
import { setDashBoardDisabled } from "containers/App/store/actions";
import { LINKS } from "shared/constants/links";
import { useFeatures } from "shared/hooks/FeaturesHook";
import { scrollToTop } from "utils/functions";
import first_engagement_card from "assets/icons/first_engagement_card.svg";
import second_engagement_card from "assets/icons/second_engagement_card.svg";
import third_engagement_card from "assets/icons/third_engagement_card.svg";
import create_event_card_image from "assets/images/create_event_card_image.png";
import create_meeting_card_image from "assets/images/create_meeting_card_image.png";

import "./communityDashboardContainer.scss";

const { COMMUNITY_SETTINGS, EVENT, EVENT_NEW } = NamesOfParentRoutes;
const { BILLING_SETTINGS } = NameOfRoutes;

const ENGAGEMENT_CARDS_INFO = [
  {
    imgSrc: first_engagement_card,
    title: "Altar Support",
    description: "Find answers to your questions in articles and videos, or chat with our team.",
    link: LINKS.support,
  },
  {
    imgSrc: second_engagement_card,
    title: "Altar Community",
    description: "Ask questions and discuss tips with fellow users and provide feedback to Altar Live.",
    link: LINKS.tribe,
  },
  {
    imgSrc: third_engagement_card,
    title: "Product Walkthrough",
    description: "Get a personalized 1:1 consultation with an expert!",
    link: LINKS.walkthrough,
  },
];

const CREATE_EVENT_CARDS_INFO = [
  {
    type: "stream" as EventType,
    imgSrc: create_event_card_image,
    title: "Create an Event",
    description: "Create an event with a video feed & video-conference lobby.",
    badge: "",
  },
  {
    type: "meeting" as EventType,
    imgSrc: create_meeting_card_image,
    title: "Create a Meeting",
    description: "Create a meeting with video-conference lobby.",
    badge: "PREMIUM FEATURE",
  },
];

const CommunityDashboardContainer: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const features = useFeatures();

  const community = useSelector(getCommunity());
  const communitySubscriptions = useSelector(getCommunitySubscriptions());
  const events = useSelector(getSermonList());
  const subscriptionAttention = useSelector(getSubscriptionAttention());

  const dashboardPrayerRooms = useMemo(() => {
    return events.filter(event => event.is_default);
  }, [events]);

  const subscriptionInfo = useMemo(() => {
    let leftDays = 1;
    const subscription = communitySubscriptions?.currentSubscriptions.find(sb =>
      [EStripeStatusCode.trialing, EStripeStatusCode.active].includes(sb.stripe_status_code),
    );
    if (subscription) {
      leftDays += moment(subscription.next_payment_date).diff(moment(), "days");
    }
    return { leftDays, subscription };
  }, [communitySubscriptions]);

  const hasMeetingFeature = useMemo(() => {
    return features.includes(EFeature.meeting);
  }, [features]);

  const addNewEvent = useCallback(
    (type: EventType, isDefault: boolean) => {
      if (community?.code) {
        if (type === EventType.meeting && !!subscriptionInfo.subscription && !hasMeetingFeature) {
          history.push(`/${community.code}${COMMUNITY_SETTINGS}${BILLING_SETTINGS}`);
          return;
        }
        if (subscriptionInfo.subscription) {
          history.push(
            `/${community.code}${EVENT}/${type}${EVENT_NEW}?to_dashboard=1${isDefault ? "&is_default=1" : ""}`,
          );
        }
      }
    },
    [community?.code, hasMeetingFeature, history, subscriptionInfo.subscription],
  );

  const purchaseButtonHandler = () => {
    if (community?.code) {
      history.push(`/${community.code}${COMMUNITY_SETTINGS}${BILLING_SETTINGS}`);
    }
  };

  useEffect(() => {
    community &&
      dispatch(
        actions.fetchSermonsList.request({
          search: "",
          community_id: community.id,
          order: "starting_at",
          direction: "asc",
          is_default: true,
        }),
      );
  }, [community, dispatch]);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    dispatch(setDashBoardDisabled(false));
  }, [dispatch]);

  return (
    <div className={classnames("communityDashboardWrapper", { subscriptionAttention })}>
      {subscriptionAttention && <SubscriptionAttentionBanner />}
      <div className="communityDashboard">
        <div className="communityDashboard__presentCard">
          <div className="communityName">
            {subscriptionInfo.subscription?.stripe_status_code === EStripeStatusCode.active
              ? community?.name
              : "Welcome to the future of online ministry"}
          </div>
          <div className="description">
            {subscriptionInfo.subscription?.stripe_status_code === EStripeStatusCode.trialing
              ? "Welcome to your Altar Live community space. You are currently on a free trial. Have questions? We'd love to help you meet your goals."
              : "This is your community space where you can plan and create new events, meetings and rooms."}
          </div>
          <SubscriptionInfo subscriptionInfo={subscriptionInfo} purchaseButtonHandler={purchaseButtonHandler} />
        </div>
        <div className="communityDashboard__createCards">
          <div className="title">Events and Meetings</div>
          <div className="cards">
            {CREATE_EVENT_CARDS_INFO.map(({ type, ...restInfo }) => (
              <CreateEventCard
                info={restInfo}
                onAddNewEvent={addNewEvent}
                disabledCard={!subscriptionInfo.subscription}
                disabledMeeting={type === EventType.meeting && !!subscriptionInfo.subscription && !hasMeetingFeature}
                type={type}
                key={type}
              />
            ))}
          </div>
        </div>
        <div
          className={`communityDashboard__personalMeetings ${
            !subscriptionInfo.subscription || !hasMeetingFeature
              ? "communityDashboard__personalMeetings--disabledCard"
              : ""
          }`}
        >
          <div className="title">Personal Meeting Rooms</div>
          <div className="meetings">
            {community &&
              dashboardPrayerRooms.map(meeting => (
                <MeetingRoomCard
                  meeting={meeting}
                  key={meeting?.id}
                  community={community}
                  disabledCard={!subscriptionInfo.subscription || !hasMeetingFeature}
                />
              ))}
          </div>
          <div className="addNewRoom">
            <button onClick={() => addNewEvent(EventType.meeting, true)}>
              <span>Add New Room</span>
            </button>
          </div>
        </div>
        <div className="communityDashboard__engagements">
          <div className="title">Level Up your Online Engagement</div>
          <div className="content">
            <div className="content__cards">
              {ENGAGEMENT_CARDS_INFO.map((item, i) => (
                <EngagementCard item={item} key={i} />
              ))}
            </div>
            <div className="content__video">
              <ReactPlayer url="https://www.youtube.com/watch?v=PPcnPXxARAo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityDashboardContainer;
