import { useSelector } from "react-redux";
import { useMemo } from "react";

import { EventType } from "../../../containers/Sermons";
import { selectClassrooms, selectFoolClassroom } from "../../../containers/Meeting/store/selectors";
import { getEvent } from "../../../containers/Sermons/store/selectors";
import { getMember } from "../../../containers/Auth/store/selectors";
import { selectRoomCode } from "../../../containers/BreakoutRooms/store/selectors";

const useClassroomHook = () => {
  const classrooms = useSelector(selectClassrooms());
  const event = useSelector(getEvent());
  const roomCode = useSelector(selectRoomCode());
  const member = useSelector(getMember());
  const classroom_is_full = useSelector(selectFoolClassroom());

  const currentRoom = useMemo(
    () => (event?.type === EventType.meeting ? classrooms?.[0] : classrooms.find(c => c.code === roomCode)),
    [classrooms, roomCode, event],
  );

  const isClassroomFull = useMemo(() => {
    return (
      classroom_is_full ||
      (event?.type === EventType.meeting &&
        !member &&
        currentRoom &&
        currentRoom.members?.length === currentRoom.room_size)
    );
  }, [classroom_is_full, event, member, currentRoom]);

  return { isClassroomFull };
};
export default useClassroomHook;
