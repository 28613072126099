import { Reducer } from "redux";
import produce from "immer";

import { AppActionTypes } from "./constants";
import { IAppStateType } from "../interfaces";

const initialState: IAppStateType = {
  loading: 0,
  errorMessage: null,
  hubspotIsOpened: false,
  hubspotInited: false,
  disableDashboard: false,
  ipIsBlocked: false,
  deviceFingerprint: null,
};

const reducer: Reducer<IAppStateType> = (state: IAppStateType = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.START_LOAD:
      return produce(state, nextState => {
        nextState.loading = state.loading + 1;
      });
    case AppActionTypes.STOP_LOAD:
      return produce(state, nextState => {
        nextState.loading = Math.max(0, state.loading - 1);
      });
    // messaging
    case AppActionTypes.ERROR_MESSAGE: {
      return produce(state, nextState => {
        nextState.errorMessage = action.payload;
      });
    }
    case AppActionTypes.SET_HUBSPOT_OPENED: {
      return produce(state, nextState => {
        nextState.hubspotIsOpened = action.payload;
      });
    }
    case AppActionTypes.SET_HUBSPOT_INITED: {
      return produce(state, nextState => {
        nextState.hubspotInited = action.payload;
      });
    }
    case AppActionTypes.SET_DASHBOARD_DISABLED: {
      return produce(state, nextState => {
        nextState.disableDashboard = action.payload;
      });
    }
    case AppActionTypes.SET_IP_BLOCKED: {
      return produce(state, nextState => {
        nextState.ipIsBlocked = action.payload;
      });
    }
    case AppActionTypes.SET_DEVICE_FINGERPRINT: {
      return produce(state, nextState => {
        nextState.deviceFingerprint = action.payload;
      });
    }
    default:
      return state;
  }
};
export { reducer as AppReducer };
