import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  actions,
  EEventChangeScope,
  EEventRepeat,
  EventStatus,
  EventType,
  getMeetingType,
  IEvent,
} from "../../../../Sermons";
import ChangeScopePopup from "../../../../Sermons/components/Sermon/ChangeScopePopup";
import { notificationActions } from "../../../../Notifications/store/actions";
import { clearCurrentSermon, fetchEventShortLink } from "../../../../Sermons/store/actions";
import { NamesOfParentRoutes } from "../../../../../constants";
import { ICommunity } from "../../../interfaces";
import { getCommunity as getCommunityAction } from "../../../store/actions";
import RecordsListPopup from "../../../../Sermons/components/Sermon/RecordsListPopup/RecordsListPopup";
import { EventPermissions } from "../../../../Auth";
import { getCommunity } from "../../../store/selectors";

import { EFileType } from "shared/interfaces/File";
import { copyTextHandler, usePermissions } from "shared";
import ThreeDotsPopup from "shared/components/ThreeDotsPopup/ThreeDotsPopup";
import community_pre_image from "assets/icons/community_pre_image.svg";

import "./meetingRoomCard.scss";

interface IMeetingRoomCard {
  meeting: IEvent;
  community: ICommunity;
  disabledCard: boolean;
}

const { STREAM, EVENT } = NamesOfParentRoutes;

const MeetingRoomCard: React.FC<IMeetingRoomCard> = ({ meeting, community, disabledCard }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentCommunity = useSelector(getCommunity());

  const [showDelete, setShowDelete] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [deleteScope, setDeleteScope] = useState(EEventChangeScope.current);
  const [duplicateScope, setDuplicateScope] = useState(EEventChangeScope.current);
  const [openRecords, setOpenRecords] = useState(false);

  const canDuplicate = usePermissions([EventPermissions.duplicate], currentCommunity?.id);
  const meetingRecords = useMemo(
    () => meeting.files?.filter(file => file.type === EFileType.meeting_record) || [],
    [meeting],
  );

  const clickCardHandler = () => {
    if (disabledCard) {
      return;
    }
    dispatch(clearCurrentSermon());
    const { type } = meeting;
    let path = `/${community.code}`;
    if (type === EventType.stream || type === EventType.meeting) {
      if (meeting.status === EventStatus.draft) {
        return;
      }
      path = `${STREAM}/${meeting.code}`;
    }
    history.push(path);
  };

  const onEditHandler = (e: React.MouseEvent) => {
    if (disabledCard) {
      return;
    }
    e.stopPropagation();
    history.push(`/${currentCommunity?.code}${EVENT}/${meeting.code}/edit?to_dashboard=1&is_default=1`);
  };

  const copyLinkCallback = (shortLink?: string) => {
    if (shortLink && !disabledCard) {
      copyTextHandler(shortLink);
      dispatch(notificationActions.success("The link is copied to the clipboard"));
    }
  };

  const onCopyLinkHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (meeting.join_link) {
      copyLinkCallback(meeting.join_link);
      return;
    }
    if (meeting.code) {
      dispatch(
        fetchEventShortLink.request({
          code: meeting.code,
          avoidInstApp: true,
          callback: val => {
            copyLinkCallback(val);
          },
        }),
      );
    }
  };

  const onRecordingsHandler = (e: React.MouseEvent) => {
    if (disabledCard) {
      return;
    }
    e.stopPropagation();
    setOpenRecords(true);
  };

  const onDeleteHandler = (e: React.MouseEvent) => {
    if (disabledCard) {
      return;
    }
    e.stopPropagation();
    setShowDelete(true);
  };

  const onDuplicateHandler = (e: React.MouseEvent) => {
    if (disabledCard) {
      return;
    }
    e.stopPropagation();
    setShowDuplicate(true);
  };

  const onCancelDeleting = () => setShowDelete(false);
  const onCancelDuplicating = () => setShowDuplicate(false);
  const onScopeChange = (e: React.ChangeEvent<HTMLInputElement>) => setDeleteScope(e.target.value as EEventChangeScope);
  const onDuplicateScopeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setDuplicateScope(e.target.value as EEventChangeScope);
  const handleDeleteEvent = useCallback(() => {
    if (meeting?.id) {
      dispatch(
        actions.removeEvent.request({
          eventId: meeting.id,
          scope: deleteScope,
          callback: () => {
            dispatch(getCommunityAction.request(community.code));
          },
          options: {
            type: meeting ? getMeetingType(meeting.type) : void 0,
          },
        }),
      );
    }
  }, [meeting, dispatch, deleteScope, community]);

  const handleDuplicateEvent = useCallback(() => {
    if (meeting?.id) {
      dispatch(
        actions.duplicateEvent.request({
          eventId: meeting.id,
          scope: duplicateScope,
          callback: () => {
            dispatch(getCommunityAction.request(community.code));
            setShowDuplicate(false);
          },
          options: {
            type: meeting ? getMeetingType(meeting.type) : void 0,
          },
        }),
      );
    }
  }, [meeting, dispatch, duplicateScope, community]);

  return (
    <div className="meetingRoomCard" onClick={clickCardHandler}>
      {meetingRecords.length ? (
        <RecordsListPopup opened={openRecords} files={meetingRecords} onCancel={() => setOpenRecords(false)} />
      ) : null}
      <ChangeScopePopup
        variant="delete"
        opened={showDelete}
        eventType={meeting.type}
        scope={meeting.repeat !== EEventRepeat.never ? deleteScope : undefined}
        onConfirm={handleDeleteEvent}
        onCancel={onCancelDeleting}
        onScopeChange={onScopeChange}
      />
      <ChangeScopePopup
        variant="duplicate"
        opened={showDuplicate}
        eventType={meeting.type}
        scope={meeting.repeat !== EEventRepeat.never ? duplicateScope : undefined}
        onConfirm={handleDuplicateEvent}
        onCancel={onCancelDuplicating}
        onScopeChange={onDuplicateScopeChange}
      />
      <div className="meetingRoomCard-img">
        <img src={community_pre_image} alt="community img" />
      </div>
      <div className="meetingRoomCard-name">{meeting.name}</div>
      <div className="meetingRoomCard-labels">
        {meeting.status === EventStatus.draft && <div className="label draft">Draft</div>}
      </div>
      <div className="meetingRoomCard-actions">
        <ThreeDotsPopup skipScroll={true}>
          <div className="edit" onClick={onEditHandler}>
            Edit
          </div>
          <div className="divider" />
          <div className="copyUrl" onClick={onCopyLinkHandler}>
            Copy Link
          </div>
          {meetingRecords.length ? (
            <>
              <div className="divider" />
              <div className="recordings" onClick={onRecordingsHandler}>
                Recordings
              </div>
            </>
          ) : null}

          {canDuplicate && (
            <>
              <div className="divider" />
              <div className="duplicate" onClick={onDuplicateHandler}>
                Duplicate
              </div>
            </>
          )}
          <div className="divider" />
          <div className="delete" onClick={onDeleteHandler}>
            Delete
          </div>
        </ThreeDotsPopup>
      </div>
    </div>
  );
};

export default MeetingRoomCard;
