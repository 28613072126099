import { createAsyncAction } from "typesafe-actions";

import { AwsActionTypes } from "./constants";
import { ICreateFile } from "../../interfaces/File";

export const uploadToS3 = createAsyncAction(
  AwsActionTypes.UPLOAD_TO_S3_REQUEST,
  AwsActionTypes.UPLOAD_TO_S3_SUCCESS,
  AwsActionTypes.UPLOAD_TO_S3_FAILURE,
  AwsActionTypes.UPLOAD_TO_S3_CANCEL,
)<ICreateFile & { file: File }, number, string, undefined>();
