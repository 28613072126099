import React from "react";

import { SocialLoginButton, SquareButton, StyledTextField } from "shared";

import "./styles.scss";

type Props = {
  onSocialSuccess: (data: any) => void;
  onSubmit: () => void;
  submitText: string;
  email: string;
  emailError?: string | null;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEmailBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const JoinOptions: React.FC<Props> = ({
  onSocialSuccess,
  email,
  onSubmit,
  submitText,
  emailError,
  handleEmailChange,
  handleEmailBlur,
}) => {
  return (
    <div className="join-options">
      <SocialLoginButton
        provider="google"
        text="Continue with Google"
        onSuccess={onSocialSuccess}
        onFailure={() => {}}
        className="login-google"
      />
      <SocialLoginButton
        provider="facebook"
        text="Continue with Facebook"
        onSuccess={onSocialSuccess}
        onFailure={() => {}}
      />
      <div className="divider">
        <div className="text">OR</div>
        <div className="line" />
      </div>
      <StyledTextField
        fullWidth
        label="Email address"
        name="email"
        className="join-email"
        value={email}
        onChange={handleEmailChange}
        onBlur={handleEmailBlur}
        errors={emailError ? { email: emailError } : null}
      />
      <SquareButton
        onClick={onSubmit}
        variant={!email ? "disabled" : "blue"}
        disabled={!email}
        className="submit-button"
      >
        {submitText}
      </SquareButton>
    </div>
  );
};

export default JoinOptions;
