import React, { FunctionComponent, useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";

import PrivateRoute from "../../../containers/App/PrivateRoute";

import { APP_NAME } from "containers/App/constants";

interface IPageProps extends RouteProps {
  title?: string;
  authentificated?: any;
}

const Page: FunctionComponent<IPageProps> = props => {
  const { authentificated, title, ...rest } = props;

  useEffect(() => {
    document.title = title ? `${APP_NAME} | ${props.title}` : APP_NAME;
  });

  return authentificated ? <PrivateRoute /> : <Route {...rest} />;
};

export default Page;
