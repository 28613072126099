import React from "react";
import { useDispatch } from "react-redux";
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from "react-share";

import { notificationActions } from "containers/Notifications/store/actions";
import { copyTextHandler, StyledEmailIcon, StyledFBIcon, StyledLinkIcon, StyledTwitterIcon } from "shared";
import { IEvent } from "containers/Sermons";

import "./index.scss";

interface SocialButtonsProps {
  event: IEvent;
}

export default function SocialButtons({ event }: SocialButtonsProps) {
  const dispatch = useDispatch();

  const copyLinkHandler = () => {
    if (event?.join_link) {
      copyTextHandler(event.join_link);
      dispatch(notificationActions.success("The link is copied to the clipboard"));
    }
  };

  return event.join_link ? (
    <div className="social">
      <div className="social-title">Share with others</div>
      <div className="social-buttons" onClick={e => e.stopPropagation()}>
        <button className="btn stroke-color" onClick={copyLinkHandler}>
          <StyledLinkIcon />
        </button>
        <EmailShareButton subject={event.name} url={event.join_link}>
          <div className="btn fill-color">
            <StyledEmailIcon />
          </div>
        </EmailShareButton>
        <FacebookShareButton url={event.join_link} quote={event.name}>
          <div className="btn fill-color">
            <StyledFBIcon />
          </div>
        </FacebookShareButton>
        <TwitterShareButton url={event.join_link} title={event.name}>
          <div className="btn fill-color">
            <StyledTwitterIcon />
          </div>
        </TwitterShareButton>
      </div>
    </div>
  ) : null;
}
