import * as Yup from "yup";
import { Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { BlockDivider } from "../";
import { AppearanceFavicon } from "../AppearanceFavicon";
import { AppearanceDetails } from "../AppearanceDetails";
import { AppearancePage } from "../AppearancePage";
import { AppearanceLinks } from "../AppearanceLinks";
import { prepareLandingSettingsForm } from "../../../utils";

import { selectors, actions } from "containers/Community/store";
import { ICommunityLandingSettings } from "containers/Community";
import { getImageBase64 } from "shared/utils/dataModifiers";
import { urlValidator, colorValidator } from "shared/constants/validations";

import "./index.scss";

const appearanceSettings = [
  {
    title: "Appearance Details",
    description: "",
    component: <AppearanceDetails />,
  },
  {
    title: "Community Page Settings",
    description: "Customize your Community Page Address.",
    component: <AppearancePage />,
  },
  {
    title: "Navigation Links",
    description: "You can add up to five links for your community.",
    component: <AppearanceLinks />,
  },
  {
    title: "Favicon",
    description: "Customize the icon some browsers display for your site.",
    component: <AppearanceFavicon />,
  },
];

export const landingSettingsValidationSchema = Yup.object({
  theme_color: Yup.string()
    .required("Please choose an theme colour")
    .oneOf(["dark", "light"], "Please choose dark or light theme colour"),
  accent_color: colorValidator.required("Please choose an accent colour"),
  textColor: Yup.string().nullable(),
  textCase: Yup.string().nullable(),
  logo: Yup.string().required("Please add a community logo."),
  links: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Please add a navigation link name"),
      value: Yup.string()
        .required("Please add a navigation link")
        .matches(urlValidator, "Wrong navigation link format"),
    }),
  ),
  favicon_url: Yup.string().nullable(),
});

const LandingSettings: React.FC = () => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const community = useSelector(selectors.getCommunity());
  const currentFormToSubmit = useSelector(selectors.getCommunitySettingsFormSubmit());
  const subdomains = useSelector(selectors.selectAllSubdomains());
  const landingSettings = useSelector(selectors.selectLandingSettings());
  const [formValues, setFormValues] = useState(prepareLandingSettingsForm());

  const handleSubmit = useCallback(
    ({ logo, favicon_url, ...data }: ICommunityLandingSettings) => {
      if (community) {
        const isLogoChanged = landingSettings ? landingSettings.logo !== logo : true;
        const isFaviconChanged = landingSettings ? landingSettings.favicon_url !== favicon_url : true;
        const logo_base64 = getImageBase64(logo, isLogoChanged);
        const favicon_base64 = getImageBase64(favicon_url, isFaviconChanged);

        const body = {
          ...data,
          logo_base64,
          favicon_base64,
        };

        if (landingSettings?.id) {
          dispatch(
            actions.updateCommunityLandingSettings.request({
              ...body,
              community_id: community.id,
              id: landingSettings?.id,
            }),
          );
        } else {
          dispatch(
            actions.createCommunityLandingSettings.request({
              ...body,
              community_id: community.id,
            }),
          );
        }
        dispatch(actions.changeCommunitySettingsForm(null));
      }
    },
    [community, landingSettings, dispatch],
  );

  const validationSchema = useMemo(
    () =>
      landingSettingsValidationSchema.shape({
        subdomain: Yup.string()
          .max(60, "Subdomain field is max 60 characters")
          .required("Subdomain is required")
          .matches(new RegExp("^(?!app$)[a-z0-9-]{0,}$"), "Wrong domain link format")
          .test("subdomain-is-taken", "Subdomain is already taken", (value: string) => {
            const name = value && value.trim();
            if (community) {
              return name !== formValues.subdomain.trim() ? !subdomains.includes(name) : true;
            }
            return value ? !subdomains.includes(name) : true;
          }),
      }),
    [subdomains, community, formValues],
  );

  const defaultSubdomain = useMemo(() => {
    const regex = /([a-zA-Z0-9])/g;
    const defaultName = community?.name?.match(regex)?.join("").toLowerCase() || "churchwebsite";

    let existingNameCount = 0;
    let domain: string;

    do {
      domain = `${defaultName}${existingNameCount ? `-${existingNameCount}` : ""}`;
      existingNameCount++;
    } while (subdomains.includes(domain));
    return domain;
  }, [community, subdomains]);

  useEffect(() => {
    if (!landingSettings && community) {
      dispatch(actions.getSubdomainList.request());
      dispatch(actions.getCommunityLandingSettings.request(community.id));
    }
  }, [community, landingSettings, dispatch]);

  useEffect(() => {
    setFormValues(prepareLandingSettingsForm(landingSettings, defaultSubdomain));
  }, [landingSettings, dispatch, defaultSubdomain]);

  useEffect(() => {
    return () => {
      dispatch(actions.clearCurrentLandingSettings());
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentFormToSubmit && formRef.current) {
      (formRef?.current as any).handleSubmit();
    }
  }, [currentFormToSubmit, formRef]);

  useEffect(() => {
    return () => {
      dispatch(actions.changeCommunitySettingsForm(null));
    };
  }, [dispatch]);

  return (
    <div className="landing-settings-wrapper">
      <Formik
        innerRef={formRef as any}
        validationSchema={validationSchema}
        initialValues={formValues}
        onSubmit={values => handleSubmit(values)}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={false}
      >
        {() => (
          <>
            {appearanceSettings.map(({ title, description, component }, index) => {
              return (
                <div
                  className={classnames("appearance-item", { lastItem: appearanceSettings.length === index + 1 })}
                  key={index}
                >
                  <div className="appearance-item-title">{title}</div>
                  <BlockDivider />
                  <div className="appearance-item-description">{description}</div>
                  {component}
                </div>
              );
            })}
          </>
        )}
      </Formik>
    </div>
  );
};

export default LandingSettings;
