import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Field, FieldAttributes, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import RRule from "rrule";
import { FormControlLabel } from "@material-ui/core";
import { useHistory } from "react-router";

import RecurrencePopup from "../RecurrencePopup";
import { CopyItem } from "../../CopyItem";
import Divider from "../Divider";
import { EFeature } from "../../../../../Community";
import { getCommunity } from "../../../../../Community/store/selectors";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../../../../constants";

import {
  AlertDialog,
  copyTextHandler,
  CustomSwitchControl,
  CustomTextField,
  DropDown,
  ImageUpload,
  SquareButton,
  SquareDatePicker,
  SquareTextField,
  SquareTimePicker,
  StyledCheckBox,
  StyledLink,
} from "shared";
import {
  correctEventEndDate,
  getDatePickerFormat,
  getEventRepeatOptions,
  getEventTypeName,
  prepareEventSchedule,
} from "containers/Sermons/utils";
import {
  EEventReCurringEnds,
  EEventRepeat,
  EventStatus,
  ICreateEventSchedule,
  IEventForm,
  IEventSchedule,
} from "containers/Sermons/interfaces";
import { getEvent } from "containers/Sermons/store/selectors";
import { notificationActions } from "containers/Notifications/store/actions";
import { classroomOptions, daysOfWeek } from "containers/Sermons/constants";
import { eventCountMembersValidate } from "shared/constants/validations";
import { useFeatures } from "shared/hooks/FeaturesHook";
import { LINKS } from "shared/constants/links";
import EventTourPreview from "shared/components/EventTourPreview";
import preview_platform_tour from "assets/images/tour/preview_platform_tour.png";
import empty_image2 from "assets/icons/empty_image2.svg";

import "./styles.scss";

const { COMMUNITY_SETTINGS } = NamesOfParentRoutes;
const { BILLING_SETTINGS } = NameOfRoutes;

type TEventSchedule = IEventSchedule | ICreateEventSchedule;

interface IScheduleEventData {
  schedule: TEventSchedule;
  starting_at: string;
  ending_at: string;
}

const hasScheduleBeenChanged = (oldScheduleData: IScheduleEventData | null, newScheduleData: IScheduleEventData) => {
  const fields: (keyof IEventSchedule)[] = ["frequency", "interval", "occurrences"];
  const getTime = (value: string) => new Date(value).getTime();
  return oldScheduleData
    ? getTime(oldScheduleData.starting_at) !== getTime(newScheduleData.starting_at) ||
        getTime(oldScheduleData.ending_at) !== getTime(newScheduleData.ending_at) ||
        (oldScheduleData.schedule.until &&
          newScheduleData.schedule.until &&
          getTime(oldScheduleData.schedule.until) !== getTime(newScheduleData.schedule.until)) ||
        oldScheduleData.schedule.day_of_week.length !== newScheduleData.schedule.day_of_week.length ||
        oldScheduleData.schedule.day_of_week.some(day => !newScheduleData.schedule.day_of_week.includes(day)) ||
        fields.some(field => oldScheduleData.schedule[field] !== newScheduleData.schedule[field])
    : false;
};

const getRepeatText = (schedule: TEventSchedule) => {
  const { day_of_week, interval } = schedule;

  const days = [...day_of_week]
    .sort((a, b) => daysOfWeek[a].order - daysOfWeek[b].order)
    .map(day => daysOfWeek[day].full)
    .join(", ");
  const text = `Every ${interval === 1 ? "week" : `${interval} weeks`} on ${days}`;
  return text;
};

const getEndsText = (schedule: TEventSchedule, recurring_ends_on: EEventReCurringEnds, starting_at?: string) => {
  const { until, occurrences, day_of_week, scheduled_until } = schedule;
  const text = "Ends on";
  if (recurring_ends_on === EEventReCurringEnds.on && until) {
    return `${text} ${moment(until || scheduled_until).format("MM/DD/YYYY")}`;
  }
  if (recurring_ends_on === EEventReCurringEnds.after && scheduled_until) {
    return `${text} ${moment(scheduled_until).format("MM/DD/YYYY")}`;
  }

  if (recurring_ends_on === EEventReCurringEnds.after && occurrences && starting_at) {
    const startDate = new Date(starting_at);
    const until = moment().add(60, "d").toDate();
    const rule = new RRule({
      freq: 2,
      interval: schedule.interval,
      byweekday: day_of_week.length ? schedule.day_of_week : undefined,
      dtstart: startDate,
      count: occurrences,
      until: until,
    });
    const dates = rule.all();
    return dates.length ? `${text} ${moment(dates[dates.length - 1]).format("MM/DD/YYYY")}` : null;
  }
  return null;
};

const getRepeatCustomData = (
  schedule?: TEventSchedule,
  recurring_ends_on?: EEventReCurringEnds,
  starting_at?: string,
) => {
  if (!schedule || !recurring_ends_on) {
    return {
      repeat: null,
      ends: null,
    };
  }
  return {
    repeat: getRepeatText(schedule),
    ends: getEndsText(schedule, recurring_ends_on, starting_at),
  };
};

interface GeneralStepInterface {
  isMeetingEvent: boolean;
  isDefaultEvent: boolean;
}

const GeneralStep: React.FC<GeneralStepInterface> = ({ isMeetingEvent, isDefaultEvent }) => {
  const {
    values: {
      starting_at,
      ending_at,
      image_url,
      repeat,
      schedule,
      recurring_ends_on,
      is_featured,
      has_platform_tour,
      tour_title,
      tour_description,
      class_room_settings,
      type,
      show_on_landing,
      restricted_generalChat,
      start_with_audio_muted,
      start_with_video_muted,
    },
    errors: { ending_at: endingAtError, schedule: scheduleError },
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormikContext<IEventForm>();

  const dispatch = useDispatch();
  const currentEvent = useSelector(getEvent());
  const features = useFeatures();
  const history = useHistory();

  const community = useSelector(getCommunity());

  const [showCustomSchedule, setShowCustomSchedule] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [repeatTextData, setRepeatTextData] = useState(getRepeatCustomData());
  const [defaultScheduleValues, setDefaultScheduleValues] = useState(prepareEventSchedule(schedule, repeat));

  const initialScheduleData = useRef<IScheduleEventData | null>(null);
  const prevRepeat = useRef(repeat);
  const prevRecurringEndsOn = useRef(recurring_ends_on);

  const eventTypeName = useMemo(() => getEventTypeName(type), [type]);
  const weekDayNthOfMonth = useMemo(() => {
    const nthOfMonth = Math.ceil(moment(starting_at).date() / 7);
    return nthOfMonth < 4 ? nthOfMonth : 4;
  }, [starting_at]);
  const repeatOptions = useMemo(() => {
    return getEventRepeatOptions(starting_at, weekDayNthOfMonth).filter(({ value }) => {
      const showLastNthOfMoth = weekDayNthOfMonth !== 4 ? value !== EEventRepeat.lastOfMonthly : true;
      return (
        !(currentEvent && currentEvent.repeat !== EEventRepeat.never && value === EEventRepeat.never) &&
        showLastNthOfMoth
      );
    });
  }, [starting_at, currentEvent, weekDayNthOfMonth]);

  const hasPlatformTourFeature = useMemo(() => features.includes(EFeature.platform_tour), [features]);

  useEffect(() => {
    setDefaultScheduleValues(prevState =>
      !prevState.id && schedule.id ? prepareEventSchedule(schedule, repeat) : prevState,
    );
  }, [repeat, schedule]);

  useEffect(() => {
    correctEventEndDate(starting_at, ending_at, endDate => setFieldValue("ending_at", endDate));
  }, [starting_at, ending_at, setFieldValue]);

  useEffect(() => {
    if (currentEvent?.schedule) {
      initialScheduleData.current = {
        schedule: currentEvent.schedule,
        starting_at: currentEvent.starting_at,
        ending_at: currentEvent.ending_at,
      };
    }
  }, [currentEvent]);

  useEffect(() => {
    const newSchedule = prepareEventSchedule(schedule, repeat);
    if (initialScheduleData.current) {
      const scheduleChanged = hasScheduleBeenChanged(initialScheduleData.current, {
        schedule,
        starting_at,
        ending_at,
      });
      newSchedule.scheduled_until = !scheduleChanged ? initialScheduleData.current.schedule.scheduled_until : void 0;
    }
    setRepeatTextData(getRepeatCustomData(newSchedule, recurring_ends_on, starting_at));
  }, [starting_at, schedule, recurring_ends_on, ending_at, repeat]);

  useEffect(() => {
    switch (repeat) {
      case EEventRepeat.weekly:
        setFieldValue("schedule", prepareEventSchedule(defaultScheduleValues, repeat, starting_at));
        break;
      case EEventRepeat.monthly:
        setFieldValue(
          "schedule",
          prepareEventSchedule({ ...defaultScheduleValues, bysetpos: weekDayNthOfMonth }, repeat, starting_at),
        );
        break;
      case EEventRepeat.lastOfMonthly:
        if (weekDayNthOfMonth !== 4) {
          setFieldValue("repeat", EEventRepeat.monthly);
        } else {
          setFieldValue(
            "schedule",
            prepareEventSchedule({ ...defaultScheduleValues, bysetpos: -1 }, repeat, starting_at),
          );
        }
        break;
    }
  }, [defaultScheduleValues, repeat, setFieldValue, starting_at, weekDayNthOfMonth]);

  const copyLinkHandler = useCallback(() => {
    if (currentEvent?.join_link) {
      copyTextHandler(currentEvent.join_link);
      dispatch(notificationActions.success("The link is copied to the clipboard"));
    }
  }, [currentEvent, dispatch]);

  const handleChangeRepeatOptions = useCallback(
    (newRepeat: string | number) => {
      if (newRepeat === EEventRepeat.custom) {
        setShowCustomSchedule(true);
        setDefaultScheduleValues(prepareEventSchedule(schedule, repeat));
        prevRecurringEndsOn.current = recurring_ends_on;
        prevRepeat.current = repeat;
      } else {
        prevRepeat.current = newRepeat as EEventRepeat;
        setFieldValue("schedule", defaultScheduleValues);
        setRepeatTextData(getRepeatCustomData());
      }
      setFieldValue("repeat", newRepeat);
    },
    [setFieldValue, defaultScheduleValues, repeat, schedule, recurring_ends_on],
  );
  const handleCancelCustomDialog = useCallback(() => {
    setFieldValue("schedule", defaultScheduleValues);
    setFieldValue("repeat", prevRepeat.current);
    setFieldValue("recurring_ends_on", prevRecurringEndsOn.current);

    setRepeatTextData(
      getRepeatCustomData(
        prevRepeat.current === EEventRepeat.custom ? defaultScheduleValues : void 0,
        recurring_ends_on,
        starting_at,
      ),
    );
    setShowCustomSchedule(false);
  }, [setFieldValue, defaultScheduleValues, starting_at, recurring_ends_on]);

  const handleConfirmCustomDialog = useCallback(() => {
    if (!scheduleError) {
      setShowCustomSchedule(false);
      const newSchedule = prepareEventSchedule(schedule, repeat);
      setFieldValue("schedule", newSchedule);
      prevRepeat.current = EEventRepeat.custom;
      prevRecurringEndsOn.current = recurring_ends_on;
    }
  }, [scheduleError, schedule, repeat, setFieldValue, recurring_ends_on]);

  const onChangeRoomSizeHandler = useCallback(
    (value: string | number) => {
      if (eventCountMembersValidate.test(value.toString()) || value.toString() === "") {
        setFieldValue("class_room_settings.room_size", value);
      }
    },
    [setFieldValue],
  );

  const upgradeSubHandler = useCallback(() => {
    history.push(`/${community?.code}${COMMUNITY_SETTINGS}${BILLING_SETTINGS}`);
  }, [community?.code, history]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const showPreviewPopup = () => {
    setShowPreview(true);
  };

  const closeTour = useCallback(() => {
    setShowPreview(false);
  }, []);

  return (
    <div className="event-general-step">
      <AlertDialog
        open={showCustomSchedule}
        mode="confirm"
        title="Recurrence"
        confirmText="Done"
        onCancel={handleCancelCustomDialog}
        onConfirm={handleConfirmCustomDialog}
      >
        <RecurrencePopup />
      </AlertDialog>
      <div className="event-general-step-header">
        <p>{`Please enter the details for the ${eventTypeName}.`}</p>
        {currentEvent?.join_link && ![EventStatus.draft, EventStatus.initial].includes(currentEvent.status) && (
          <div className="copy-link">
            <p>Share this link with your members or paste to your website</p>
            <CopyItem copyText={currentEvent.join_link} onClick={copyLinkHandler} />
          </div>
        )}
      </div>
      <Divider />
      <div className="event-general-step-content">
        <div className="top-block">
          <div className="top-block-main">
            <Field name="name">
              {({ field, form }: FieldAttributes<any>) => (
                <div className="item">
                  <SquareTextField
                    className="field"
                    fullWidth
                    label="Name"
                    placeholder={`Type ${eventTypeName} name`}
                    name="name"
                    errors={form.touched.name && form.errors}
                    value={field.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    required
                  />
                </div>
              )}
            </Field>
            <Field name="subject">
              {({ field, form }: FieldAttributes<any>) => (
                <div className="item">
                  <SquareTextField
                    className="field subject"
                    fullWidth
                    label="Description"
                    placeholder={`Type description of the ${eventTypeName}`}
                    name="subject"
                    multiline
                    rows={4}
                    errors={form.errors}
                    value={field.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyUp={handleKeyDown}
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="top-block-image">
            <p className="label">Share Image</p>
            <p className="help">{`This image will appear when you share the link to the ${eventTypeName}.`}</p>
            <div className="image">
              <ImageUpload
                showOptions
                maxMb={5}
                value={image_url}
                onChange={handleChange("image_url")}
                backgroundImage={`url(${empty_image2})`}
              />
            </div>
          </div>
        </div>
        {!isDefaultEvent ? (
          <>
            <div className="event-dates event-section">
              <p className="event-section-label">
                {`Select the date and time of the actual ${eventTypeName} (according to your local time zone).`}
              </p>
              <div className="pickers">
                <Field name="starting_at">
                  {({ field, form, meta }: FieldAttributes<any>) => (
                    <>
                      <SquareDatePicker label="Date" {...field} {...form} {...meta} format={getDatePickerFormat()} />
                      <SquareTimePicker label="Start time" {...field} {...form} {...meta} />
                    </>
                  )}
                </Field>
                <Field name="ending_at">
                  {({ field, form, meta }: FieldAttributes<any>) => (
                    <SquareTimePicker label="End time" {...field} {...form} {...meta} />
                  )}
                </Field>
                {endingAtError && <p className="helper-text">{endingAtError}</p>}
              </div>
            </div>
            <div className="event-repeat">
              <div className="event-repeat-options">
                <p className="label">Repeat</p>
                <Field name="repeat">
                  {() => (
                    <DropDown
                      size="full"
                      width={250}
                      items={repeatOptions}
                      spacer={false}
                      value={repeat}
                      onChange={handleChangeRepeatOptions}
                      isMenu
                      skipScroll
                    />
                  )}
                </Field>
              </div>
              {repeat === EEventRepeat.custom && (
                <div className="custom-text">
                  <p className="custom-text-repeat">{repeatTextData.repeat}</p>
                  {recurring_ends_on !== EEventReCurringEnds.never && (
                    <p className="custom-text-ends">{repeatTextData.ends}</p>
                  )}
                </div>
              )}
            </div>
          </>
        ) : null}
        {!isMeetingEvent ? (
          <>
            <div className=" event-section">
              <p className="event-section-label">
                Don't allow attendees to use chat until 1 hour before event begins. If selected, logged-in and anonymous
                attendees won't be able to send messages in General Chat until 1 hour before start time.
              </p>
              <FormControlLabel
                control={
                  <StyledCheckBox
                    withBorder={false}
                    checked={restricted_generalChat}
                    onChange={handleChange}
                    name="restricted_generalChat"
                  />
                }
                label="Disable chat until 1 hour before event begins"
              />
            </div>
            <div className="event-section">
              <p className="event-section-label">
                Choose whether your attendees microphones and cameras are defaulted off or on when they first join a
                row, table or room.
              </p>
              <CustomSwitchControl
                checked={start_with_audio_muted}
                theme="orange"
                onChange={handleChange}
                label="Everyone starts with Microphone Off"
                name="start_with_audio_muted"
              />
              <CustomSwitchControl
                checked={start_with_video_muted}
                theme="orange"
                onChange={handleChange}
                label="Everyone starts with Camera Off"
                name="start_with_video_muted"
              />
            </div>

            <div className=" event-section">
              <p className="event-section-label">
                Mark this event as featured. Featured events are shown in the top banner of your community landing page.
              </p>
              <FormControlLabel
                control={
                  <StyledCheckBox withBorder={false} checked={is_featured} onChange={handleChange} name="is_featured" />
                }
                label="Featured Event"
              />
            </div>
            <div className=" event-section">
              <p className="event-section-label">
                Show logged in and anonymous attendees a platform tour when they first join an event. Logged in
                attendees who choose to Skip Tour will not see the tour again when they join future events. Anonymous
                attendees will see the tour every time they join the event. All attendees can click the Show Me Around
                button at any time to see the platform tour.
              </p>
              <div className="event-section-label">
                <FormControlLabel
                  control={
                    <StyledCheckBox
                      withBorder={false}
                      checked={!!has_platform_tour}
                      onChange={handleChange}
                      name="has_platform_tour"
                      disabled={!hasPlatformTourFeature}
                    />
                  }
                  label="Show attendees platform tour when they join"
                />
              </div>
              <div className="top-block">
                {has_platform_tour && (
                  <div className="top-block-main">
                    <p className="event-section-label">
                      You can customize the first popup in the platform tour to welcome people to your specific event.
                    </p>
                    <Field name="tour_title">
                      {({ field, form }: FieldAttributes<any>) => (
                        <div className="item">
                          <CustomTextField
                            className="field"
                            fullWidth
                            label="Tour Title"
                            name="tour_title"
                            multiline
                            rows={2}
                            errors={form.touched.tour_title && form.errors}
                            value={field.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            showMaxCounter={true}
                            max={70}
                            required
                          />
                        </div>
                      )}
                    </Field>
                    <Field name="tour_description">
                      {({ field, form }: FieldAttributes<any>) => (
                        <div className="item">
                          <CustomTextField
                            className="field subject"
                            fullWidth
                            label="Description"
                            name="tour_description"
                            multiline
                            rows={4}
                            errors={form.touched.tour_description && form.errors}
                            value={field.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyUp={handleKeyDown}
                            showMaxCounter={true}
                            max={300}
                            required
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                )}
                {has_platform_tour || !hasPlatformTourFeature ? (
                  <div className="top-block-image">
                    <img src={preview_platform_tour} alt="preview" className={"previewPlatformTour"} />
                    <SquareButton width={208} type="button" variant={"blue"} onClick={showPreviewPopup}>
                      Preview Platform Tour
                    </SquareButton>
                    {!hasPlatformTourFeature && (
                      <>
                        <SquareButton
                          width={384}
                          type="button"
                          variant={"orange"}
                          onClick={upgradeSubHandler}
                          className={"upgrade-subscription"}
                        >
                          Upgrade Subscription
                        </SquareButton>
                        <StyledLink className="learn-more" href={LINKS.create_event}>
                          Learn More About This Feature
                        </StyledLink>
                      </>
                    )}
                  </div>
                ) : null}
                <EventTourPreview
                  show={showPreview}
                  showMeAround={showPreview}
                  closeTour={closeTour}
                  skipTour={closeTour}
                  theme={"light"}
                  countSteps={4}
                  tourTitle={tour_title}
                  tourDescription={tour_description}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {class_room_settings ? (
              <div className="class-room-configuration-wrapper">
                <div className="class-room-member-count-wrapper">
                  <div className="class-room-section-label">Choose how many people can join the meeting</div>
                  <Field name="class_room_settings.room_size">
                    {({ form }: FieldAttributes<any>) => (
                      <DropDown
                        size="full"
                        width={216}
                        items={classroomOptions}
                        spacer={false}
                        value={class_room_settings?.room_size}
                        onChange={onChangeRoomSizeHandler}
                        editable
                        error={form.errors.class_room_settings?.room_size}
                      />
                    )}
                  </Field>
                </div>
                <div className="class-room-meeting-settings-wrapper">
                  <div className="class-room-section-label">Audio Settings</div>
                  <FormControlLabel
                    className="settings-item"
                    control={
                      <StyledCheckBox
                        withBorder={false}
                        checked={start_with_audio_muted}
                        onChange={handleChange}
                        name="start_with_audio_muted"
                      />
                    }
                    label="Everyone starts with Microphone Off"
                  />
                </div>
              </div>
            ) : null}
            {!isDefaultEvent ? (
              <div className="landingPage-settings">
                <div className="landingPage-settings-label">Community Landing Page Settings</div>
                <FormControlLabel
                  className="landingPage-settings-checkBox"
                  control={
                    <StyledCheckBox
                      withBorder={false}
                      checked={show_on_landing}
                      onChange={handleChange}
                      name="show_on_landing"
                    />
                  }
                  label="Show on Community Landing Page"
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default GeneralStep;
