import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RecordingsItem } from "../RecordingsItem";

import { getEvent } from "containers/Sermons/store/selectors";
import { usePermissions } from "shared";
import { EZoomMeetingRecordingType } from "containers/Sermons/interfaces";
import { EventPermissions } from "containers/Auth/interfaces";

import "./recordings.scss";

const Recordings: React.FC = () => {
  const { t } = useTranslation();
  const event = useSelector(getEvent());

  const editPermissions = useMemo(() => [EventPermissions.edit], []);
  const canEdit = usePermissions(editPermissions, event?.community_id);

  if (!event) return null;
  const { recordings, type } = event;

  const recordingsList = recordings
    .filter(({ recording_type }) => recording_type === EZoomMeetingRecordingType.AS)
    .map((recording, idx) => (
      <RecordingsItem key={recording.id} recording={recording} name={`Recording ${idx + 1}`} showDownload={canEdit} />
    ));
  return (
    <div className="recordings">
      {recordingsList.length > 0 && (
        <>
          <p className="recordings-title">
            You can view {`${canEdit ? "or download " : ""}`}recording of this {t(`event.type.${type}`)} here
          </p>
          <div className="recordings-list">
            <table>
              <tbody>{recordingsList}</tbody>
            </table>
          </div>
          {/*<Button width={472} type="button" variant="blue-outline">*/}
          {/*  download all recording (zip)*/}
          {/*</Button>*/}
        </>
      )}
    </div>
  );
};
export default Recordings;
