import React from "react";

import { BlockDivider } from "../BlockDivider";

import faq_icon from "assets/icons/faq-icon.svg";

import "./index.scss";

type FAQItem = {
  title: string;
  value: string;
};

interface FAQProps {
  items: FAQItem[];
}

const FAQ = ({ items }: FAQProps) => {
  return (
    <div className="faq-wrapper">
      <div className="faq-title">Frequently Asked Questions</div>
      <BlockDivider />
      <div className="faq-content">
        {items.map(f => (
          <div key={f.title} className="faq-item">
            <div className="item-title">
              <img src={faq_icon} alt="faq-ico" />
              <span>{f.title}</span>
            </div>
            <div className="item-content">{f.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
