import produce from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import * as actions from "./actions";
import { IFileState } from "../../interfaces/File";

const initialState: IFileState = {
  file: null,
};

type Action = ActionType<typeof actions>;

const reducer = createReducer<IFileState, Action>(initialState).handleAction(actions.setFile, (state, action) =>
  produce(state, nextState => {
    nextState.file = action.payload;
  }),
);

export default reducer;
