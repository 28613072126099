import { createAsyncAction, action } from "typesafe-actions";
import { Socket } from "socket.io-client";

import { SermonActionTypes } from "./constants";
import {
  IEventPreview,
  IFetchSermonShape,
  ICreateSermon,
  IEvent,
  IUpdateSermon,
  IGenerateSignatureParams,
  IMeetingSignature,
  IRemoveEvent,
  EmojisType,
  ICheckSlotQuery,
  IFetchFileUrl,
  IEventIntegrations,
  IEventSettings,
  IPoll,
  IAnnouncement,
  IFetchAnnouncementsShape,
  ICreateAnnouncementShape,
  IUpdateAnnouncementShape,
  IPollVote,
  IReadAnnouncementsShape,
  IPollReadResponse,
  IPollRead,
  IDuplicateEvent,
  IChangeEventStatusOptions,
  IWaitingToJoinMember,
  IPollTemplate,
  IAnnouncementTemplate,
  IPollToSubmit,
  ICreateMultipleSermons,
} from "../interfaces";
import { EventMemberTypes } from "../constants";
import { IMember } from "../../Member";

import { ResponseError } from "shared/interfaces";

export const fetchSermonsList = createAsyncAction(
  SermonActionTypes.FETCH_SERMONS_LIST,
  SermonActionTypes.FETCH_SERMONS_LIST_SUCCESS,
  SermonActionTypes.FETCH_SERMONS_LIST_FAILURE,
)<IFetchSermonShape, IEventPreview[], undefined>();

export const fetchSermon = createAsyncAction(
  SermonActionTypes.FETCH_SERMON_BY_ID_REQUEST,
  SermonActionTypes.FETCH_SERMON_BY_ID_SUCCESS,
  SermonActionTypes.FETCH_SERMON_BY_ID_FAILURE,
)<{ code: string; relations?: string[]; customErrorHandling?: boolean }, IEvent, ResponseError>();

export const createSermon = createAsyncAction(
  SermonActionTypes.CREATE_SERMON_REQUEST,
  SermonActionTypes.CREATE_SERMON_SUCCESS,
  SermonActionTypes.CREATE_SERMON_FAILURE,
)<ICreateSermon, IEvent, undefined>();

export const createMultipleSermons = (payload: ICreateMultipleSermons) =>
  action(SermonActionTypes.CREATE_MULTIPLE_SERMONS_REQUEST, payload);

export const updateSermon = createAsyncAction(
  SermonActionTypes.UPDATE_SERMON_REQUEST,
  SermonActionTypes.UPDATE_SERMON_SUCCESS,
  SermonActionTypes.UPDATE_SERMON_FAILURE,
)<IUpdateSermon, IEvent, undefined>();

export const fetchFileUrl = createAsyncAction(
  SermonActionTypes.FETCH_EVENT_FILE,
  SermonActionTypes.FETCH_EVENT_FILE_SUCCESS,
  SermonActionTypes.FETCH_EVENT_FILE_FAILURE,
)<IFetchFileUrl, undefined, undefined>();

export const startEvent = createAsyncAction(
  SermonActionTypes.START_EVENT_REQUEST,
  SermonActionTypes.START_EVENT_SUCCESS,
  SermonActionTypes.START_EVENT_FAILURE,
)<number, undefined, undefined>();

export const removeEvent = createAsyncAction(
  SermonActionTypes.REMOVE_EVENT_REQUEST,
  SermonActionTypes.REMOVE_EVENT_SUCCESS,
  SermonActionTypes.REMOVE_EVENT_FAILURE,
)<IRemoveEvent, number, undefined>();

export const duplicateEvent = createAsyncAction(
  SermonActionTypes.DUPLICATE_EVENT_REQUEST,
  SermonActionTypes.DUPLICATE_EVENT_SUCCESS,
  SermonActionTypes.DUPLICATE_EVENT_FAILURE,
)<IDuplicateEvent, number, undefined>();

export const checkAvailableLicenseSlot = createAsyncAction(
  SermonActionTypes.CHECK_SLOT_REQUEST,
  SermonActionTypes.CHECK_SLOT_SUCCESS,
  SermonActionTypes.CHECK_SLOT_FAILURE,
)<{ data: ICheckSlotQuery; callback?: () => void }, boolean, undefined>();

export const clearCurrentSermon = () => action(SermonActionTypes.CLEAR_CURRENT_SERMON);
export const clearEventError = () => action(SermonActionTypes.CLEAR_EVENT_ERROR);

export const startSermon = (eventId: number, callback?: () => void) =>
  action(SermonActionTypes.START_SERMON, { eventId, callback });

export const closeSocket = () => action(SermonActionTypes.CLOSE_SOCKET);

export const setSocketConnected = (connected: boolean) => action(SermonActionTypes.SET_SOCKET_CONNECTED, connected);

export const socketInitialized = (socket: Socket) => action(SermonActionTypes.SOCKET_INITIALIZED, socket);
export const socketInitializedFailure = (error: Error) => action(SermonActionTypes.SOCKET_INITIALIZED_FAILURE, error);

export const mapZoomId = (zoomId: number, memberId: number | string, customName?: string) => {
  return {
    type: SermonActionTypes.SEND_SERMON_EVENT,
    payload: {
      method: "map",
      zoomId,
      memberId,
      customName,
    },
  };
};

export const connectToEvent = (
  memberId: number | string,
  type: EventMemberTypes,
  color: string,
  guestName?: string,
  guestId?: string,
  member?: IMember,
) => {
  return {
    type: SermonActionTypes.WS_CONNECT_TO_EVENT,
    payload: {
      method: "eventConnect",
      memberId,
      type,
      color,
      guestName,
      guestId,
      member,
    },
  };
};

export const setMembersMap = (map: Record<string, string>) => {
  return {
    type: SermonActionTypes.WS_MEMBERS_MAP,
    map,
  };
};

export const setEventMembers = createAsyncAction(
  SermonActionTypes.WS_EVENT_MEMBERS_REQUEST,
  SermonActionTypes.WS_EVENT_MEMBERS_SUCCESS,
  SermonActionTypes.WS_EVENT_MEMBERS_FAILURE,
)<{ data: Record<string, string> }, { data: Record<string, string> }, undefined>();

export const setMeetingMute = (mute: boolean) => ({
  mute,
  type: SermonActionTypes.WS_STATE_MUTE,
});

export const setMeetingSpotlight = (zoomId: number) => ({
  zoomId,
  type: SermonActionTypes.WS_STATE_SPOTLIGHT,
});

export const setMeetingUnmuted = (unmuted: number[]) => ({
  unmuted,
  type: SermonActionTypes.WS_STATE_UNMUTED,
});

export const updateStateEvent = (data: Partial<IEvent>) => ({
  data,
  type: SermonActionTypes.WS_EVENT_UPDATED,
});

export const changeSlide = (data: { id: number }) => ({
  data,
  type: SermonActionTypes.WS_SLIDE_CHANGED,
});

export const receiveReaction = (data: { name: string }) => ({
  data,
  type: SermonActionTypes.WS_REACTION_RECEIVED,
});

export const changeStatusOptions = (payload: IChangeEventStatusOptions | null) => ({
  payload,
  type: SermonActionTypes.WS_CHANGE_EVENT_STATUS_OPTIONS,
});

export const sendReaction = (name: EmojisType) => {
  return {
    type: SermonActionTypes.WS_SEND_REACTION,
    payload: {
      method: "reaction",
      name,
    },
  };
};

export const removeMemberFromEvent = (memberId: number | string) =>
  action(SermonActionTypes.WS_REMOVE_MEMBER_FROM_EVENT, {
    memberId,
    method: "removeMemberFromEvent",
  });

export const leaveEvent = (memberId: number | string) =>
  action(SermonActionTypes.WS_LEAVE_EVENT, {
    memberId,
    method: "leaveEvent",
  });

export const changeMemberUserName = (userId: number | string, username: string) =>
  action(SermonActionTypes.WS_UPDATE_MEMBER, {
    userId,
    username,
    method: "changeMemberUserName",
  });

export const joinMeetingKnockResponse = (allowedMemberIds: number[], deniedMemberIds: number[]) =>
  action(SermonActionTypes.JOIN_MEETING_KNOCK_RESPONSE, {
    allowedMemberIds,
    deniedMemberIds,
    method: "joinMeetingKnockResponse",
  });

export const knockJoinMeeting = createAsyncAction(
  SermonActionTypes.KNOCK_JOIN_MEETING_REQUEST,
  SermonActionTypes.KNOCK_JOIN_MEETING_SUCCESS,
  SermonActionTypes.KNOCK_JOIN_MEETING_FAILURE,
  SermonActionTypes.KNOCK_JOIN_MEETING_CANCEL,
)<{ member: IMember; method: string }, IWaitingToJoinMember[], undefined, undefined>();

export const setKnockRejected = (isKnockRejected: boolean) => action(SermonActionTypes.KNOCK_REJECTED, isKnockRejected);

export const setBlockedEventMembers = (ids: string[]) => action(SermonActionTypes.WS_BLOCKED_EVENT_MEMBERS, ids);

export const fetchSermonSignature = createAsyncAction(
  SermonActionTypes.FETCH_SERMON_SIGNATURE_REQUEST,
  SermonActionTypes.FETCH_SERMON_SIGNATURE_SUCCESS,
  SermonActionTypes.FETCH_SERMON_SIGNATURE_FAILURE,
)<IGenerateSignatureParams, IMeetingSignature, undefined>();

export const fetchEventIntegrations = createAsyncAction(
  SermonActionTypes.EVENT_INTEGRATIONS_REQUEST,
  SermonActionTypes.EVENT_INTEGRATIONS_SUCCESS,
  SermonActionTypes.EVENT_INTEGRATIONS_FAILURE,
)<string, IEventIntegrations, undefined>();

export const fetchEventShortLink = createAsyncAction(
  SermonActionTypes.EVENT_SHORT_LINK_REQUEST,
  SermonActionTypes.EVENT_SHORT_LINK_SUCCESS,
  SermonActionTypes.EVENT_SHORT_LINK_FAILURE,
  SermonActionTypes.EVENT_SHORT_LINK_CLEAR,
)<{ code: string; callback?: (data?: string) => void; avoidInstApp: boolean }, string, undefined, undefined>();

export const updateEventSettings = (payload: Partial<IEventSettings>) =>
  action(SermonActionTypes.UPDATE_EVENT_SETTINGS, payload);
export const clearEventSettings = () => action(SermonActionTypes.CLEAR_EVENT_SETTINGS);

export const connectSocket = () => action(SermonActionTypes.CONNECT_SOCKET);

export const setGoingLiveTime = (seconds: number) => action(SermonActionTypes.SET_GOING_LIVE_TIME, seconds);

export const setAllowEnded = (allow: boolean) => action(SermonActionTypes.SET_ALLOW_ENDED, allow);

export const setChangingStatus = (value: boolean) => action(SermonActionTypes.SET_CHANGING_STATUS, value);

export const setIsLeavingEvent = (value: boolean) => action(SermonActionTypes.SET_LEAVING_EVENT, value);

export const fetchPolls = createAsyncAction(
  SermonActionTypes.FETCH_POLLS_REQUEST,
  SermonActionTypes.FETCH_POLLS_SUCCESS,
  SermonActionTypes.FETCH_POLLS_FAILURE,
)<{ meeting_id: number }, IPoll, undefined>();

export const createPoll = createAsyncAction(
  SermonActionTypes.CREATE_POLL_REQUEST,
  SermonActionTypes.CREATE_POLL_SUCCESS,
  SermonActionTypes.CREATE_POLL_FAILURE,
)<IPollToSubmit, IPoll, undefined>();

export const updatePoll = createAsyncAction(
  SermonActionTypes.UPDATE_POLL_REQUEST,
  SermonActionTypes.UPDATE_POLL_SUCCESS,
  SermonActionTypes.UPDATE_POLL_FAILURE,
)<IPollToSubmit, IPoll, undefined>();

export const deletePoll = createAsyncAction(
  SermonActionTypes.DELETE_POLL_REQUEST,
  SermonActionTypes.DELETE_POLL_SUCCESS,
  SermonActionTypes.DELETE_POLL_FAILURE,
)<{ poll_id: number }, number, undefined>();

export const resetPollNotificationCount = () => action(SermonActionTypes.RESET_POLL_NOTIFICATION_COUNT);

export const pollVote = createAsyncAction(
  SermonActionTypes.POLL_VOTE_REQUEST,
  SermonActionTypes.POLL_VOTE_SUCCESS,
  SermonActionTypes.POLL_VOTE_FAILURE,
)<{ data: IPollVote[]; callback: (isVoted: boolean) => void }, IPollVote[], undefined>();

export const readPolls = createAsyncAction(
  SermonActionTypes.READ_POLLS_REQUEST,
  SermonActionTypes.READ_POLLS_SUCCESS,
  SermonActionTypes.READ_POLLS_FAILURE,
)<IPollRead, IPollReadResponse[], undefined>();

export const fetchPollTemplates = createAsyncAction(
  SermonActionTypes.FETCH_POLL_TEMPLATES_REQUEST,
  SermonActionTypes.FETCH_POLL_TEMPLATES_SUCCESS,
  SermonActionTypes.FETCH_POLL_TEMPLATES_FAILURE,
)<number, IPollTemplate[], undefined>();

export const createPollTemplate = createAsyncAction(
  SermonActionTypes.CREATE_POLL_TEMPLATE_REQUEST,
  SermonActionTypes.CREATE_POLL_TEMPLATE_SUCCESS,
  SermonActionTypes.CREATE_POLL_TEMPLATE_FAILURE,
)<IPollTemplate, IPollTemplate, undefined>();

export const updatePollTemplate = createAsyncAction(
  SermonActionTypes.UPDATE_POLL_TEMPLATE_REQUEST,
  SermonActionTypes.UPDATE_POLL_TEMPLATE_SUCCESS,
  SermonActionTypes.UPDATE_POLL_TEMPLATE_FAILURE,
)<{ id: number; preparedData: IPollTemplate }, IPollTemplate, undefined>();

export const deletePollTemplate = createAsyncAction(
  SermonActionTypes.DELETE_POLL_TEMPLATE_REQUEST,
  SermonActionTypes.DELETE_POLL_TEMPLATE_SUCCESS,
  SermonActionTypes.DELETE_POLL_TEMPLATE_FAILURE,
)<number, number, undefined>();

export const getRecurringEvent = createAsyncAction(
  SermonActionTypes.GET_RECURRING_EVENT_REQUEST,
  SermonActionTypes.GET_RECURRING_EVENT_SUCCESS,
  SermonActionTypes.GET_RECURRING_EVENT_FAILURE,
  SermonActionTypes.GET_RECURRING_EVENT_CLEAR,
)<{ eventIdentifier: number | string; shouldRedirect?: boolean }, IEvent, undefined, undefined>();

export const setParticipantsLimitExceeded = (exceeded: boolean) =>
  action(SermonActionTypes.PARTICIPANTS_LIMIT_EXCEEDED, exceeded);

export const fetchAnnouncementsList = createAsyncAction(
  SermonActionTypes.FETCH_ANNOUNCEMENTS_LIST,
  SermonActionTypes.FETCH_ANNOUNCEMENTS_LIST_SUCCESS,
  SermonActionTypes.FETCH_ANNOUNCEMENTS_LIST_FAILURE,
)<IFetchAnnouncementsShape, IAnnouncement[], undefined>();

export const createAnnouncement = createAsyncAction(
  SermonActionTypes.CREATE_ANNOUNCEMENT,
  SermonActionTypes.CREATE_ANNOUNCEMENT_SUCCESS,
  SermonActionTypes.CREATE_ANNOUNCEMENT_FAILURE,
)<ICreateAnnouncementShape, IAnnouncement, undefined>();

export const updateAnnouncement = createAsyncAction(
  SermonActionTypes.UPDATE_ANNOUNCEMENT,
  SermonActionTypes.UPDATE_ANNOUNCEMENT_SUCCESS,
  SermonActionTypes.UPDATE_ANNOUNCEMENT_FAILURE,
)<IUpdateAnnouncementShape, IAnnouncement, undefined>();

export const deleteAnnouncement = createAsyncAction(
  SermonActionTypes.DELETE_ANNOUNCEMENT,
  SermonActionTypes.DELETE_ANNOUNCEMENT_SUCCESS,
  SermonActionTypes.DELETE_ANNOUNCEMENT_FAILURE,
)<number, undefined, undefined>();

export const wsAcnnouncementPublished = (announcement: IAnnouncement) =>
  action(SermonActionTypes.WS_ANNOUNCEMENT_PUBLISHED, announcement);

export const wsAcnnouncementDraft = (announcement: IAnnouncement) =>
  action(SermonActionTypes.WS_ANNOUNCEMENT_DRAFT, announcement);

export const wsAcnnouncementDeleted = (announcementId: number) =>
  action(SermonActionTypes.WS_ANNOUNCEMENT_DELETED, announcementId);

export const resetNewAnnouncements = () => action(SermonActionTypes.RESET_NEW_ANNOUNCEMENTS);
export const setSelectedAnnouncement = (announcement: IAnnouncement | null) =>
  action(SermonActionTypes.SET_SELECTED_ANNOUNCEMENT, announcement);

export const readAnnoucements = createAsyncAction(
  SermonActionTypes.READ_ANNOUNCEMENTS_REQUEST,
  SermonActionTypes.READ_ANNOUNCEMENTS_SUCCESS,
  SermonActionTypes.READ_ANNOUNCEMENTS_FAILURE,
)<IReadAnnouncementsShape, IAnnouncement[], undefined>();

export const setEventHostOrAdmin = (value: boolean) => action(SermonActionTypes.SET_EVENT_HOST_OR_ADMIN, value);

export const checkVimeoConnection = createAsyncAction(
  SermonActionTypes.CHECK_VIMEO_CONNECTION,
  SermonActionTypes.CHECK_VIMEO_CONNECTION_SUCCESS,
  SermonActionTypes.CHECK_VIMEO_CONNECTION_FAILURE,
)<{ vimeo_token: string; showNotification: boolean }, boolean, undefined>();

export const deleteFile = createAsyncAction(
  SermonActionTypes.DELETE_FILE_REQUEST,
  SermonActionTypes.DELETE_FILE_SUCCESS,
  SermonActionTypes.DELETE_FILE_FAILURE,
)<{ id: number }, undefined, undefined>();

export const fetchAnnouncementTemplates = createAsyncAction(
  SermonActionTypes.FETCH_ANNOUNCEMENT_TEMPLATES_REQUEST,
  SermonActionTypes.FETCH_ANNOUNCEMENT_TEMPLATES_SUCCESS,
  SermonActionTypes.FETCH_ANNOUNCEMENT_TEMPLATES_FAILURE,
)<number, IAnnouncementTemplate[], undefined>();

export const createAnnouncementTemplate = createAsyncAction(
  SermonActionTypes.CREATE_ANNOUNCEMENT_TEMPLATE_REQUEST,
  SermonActionTypes.CREATE_ANNOUNCEMENT_TEMPLATE_SUCCESS,
  SermonActionTypes.CREATE_ANNOUNCEMENT_TEMPLATE_FAILURE,
)<IAnnouncementTemplate, IAnnouncementTemplate, undefined>();

export const updateAnnouncementTemplate = createAsyncAction(
  SermonActionTypes.UPDATE_ANNOUNCEMENT_TEMPLATE_REQUEST,
  SermonActionTypes.UPDATE_ANNOUNCEMENT_TEMPLATE_SUCCESS,
  SermonActionTypes.UPDATE_ANNOUNCEMENT_TEMPLATE_FAILURE,
)<{ id: number; preparedData: IAnnouncementTemplate }, IAnnouncementTemplate, undefined>();

export const deleteAnnouncementTemplate = createAsyncAction(
  SermonActionTypes.DELETE_ANNOUNCEMENT_TEMPLATE_REQUEST,
  SermonActionTypes.DELETE_ANNOUNCEMENT_TEMPLATE_SUCCESS,
  SermonActionTypes.DELETE_ANNOUNCEMENT_TEMPLATE_FAILURE,
)<number, number, undefined>();
