import React from "react";
import { FormControlLabel, Switch, SwitchProps } from "@material-ui/core";
import classnames from "classnames";

import "./customSwitchControl.scss";

interface ICustomSwitch extends SwitchProps {
  checked?: boolean;
  name?: string;
  label?: string;
  theme?: "blue" | "orange";
}

const compileSwitchProps = () => ({
  classes: {
    root: "switch-root",
    thumb: "switch-thumb",
    switchBase: "switch-switchBase",
    checked: "switch-checked",
    track: "switch-track",
  },
});

const CustomSwitchControl: React.FunctionComponent<ICustomSwitch> = ({ label, theme = "orange", ...props }) => (
  <div className={classnames("custom-switch", theme)}>
    <FormControlLabel control={<Switch {...props} {...compileSwitchProps()} />} label={label} />
  </div>
);
export default CustomSwitchControl;
