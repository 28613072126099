import React, { Component } from "react";

interface AudioVisualiserInterface {
  audioData: Uint8Array;
}

class AudioVisualiser extends Component<AudioVisualiserInterface> {
  canvas: React.RefObject<HTMLCanvasElement>;
  constructor(props: AudioVisualiserInterface) {
    super(props);
    this.canvas = React.createRef();
  }

  componentDidUpdate() {
    this.draw();
  }

  draw() {
    if (this.canvas.current) {
      const { audioData } = this.props;
      const canvas = this.canvas.current;
      const height = canvas?.height;
      const width = canvas?.width;
      const context = canvas.getContext("2d");
      let x = 0;
      const sliceWidth = (width * 1.0) / audioData.length;

      if (context) {
        context.lineWidth = 2;
        context.strokeStyle = "greenyellow";
        context.clearRect(0, 0, width, height);

        context.beginPath();
        context.moveTo(0, height / 2);
        audioData.forEach(item => {
          const y = (item / 255.0) * height;
          context.lineTo(x, y);
          x += sliceWidth;
        });
        context.lineTo(x, height / 2);
        context.stroke();
      }
    }
  }

  render() {
    return <canvas width="464" height="40" ref={this.canvas} />;
  }
}

export default AudioVisualiser;
