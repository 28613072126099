import React from "react";

import StyledButton from "../StyledButton/StyledButton";

import { ICreateButton } from "shared";

import "./blueTextButton.scss";

const BlueTextButton: React.FunctionComponent<ICreateButton> = ({ children, className, ...rest }) => {
  return (
    <StyledButton className={`blueTextButton ${className || ""}`} {...rest}>
      <span className="blueTextButton-text">{children}</span>
    </StyledButton>
  );
};
export default BlueTextButton;
