import React from "react";

import { IconBase } from "../IconBase";

import { IStyledSVG } from "shared/interfaces/StyledSVG";

export const StyledSquare: React.FC<IStyledSVG> = ({
  style = {},
  fill = "#93808D",
  width = "80",
  height = "80",
  className = "",
  opacity = "0.1",
}) => (
  <IconBase
    width={width}
    style={style}
    height={height}
    fill="none"
    className={className}
    opacity={opacity}
    viewBox="0 0 80 80"
  >
    <path d="M80 0H0V80H80V0Z" fill={fill} />
  </IconBase>
);

export default StyledSquare;
