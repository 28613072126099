import React, { ChangeEvent } from "react";
import classNames from "classnames";
import { FormikErrors } from "formik";

import { Button, SquareTextField } from "shared/components";

import "./styles.scss";

interface StreamRelayInputProps {
  value: string;
  name: string;
  errors?: FormikErrors<any>;
  label: string;
  placeholder?: string;
  buttonText?: string;
  inputType?: "text" | "number" | "password";
  disabled?: boolean;
  className?: string;
  buttonWidth?: number;
  withPasswordIcon?: boolean;
  handleChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleBlur?: (e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleButtonClick: () => void;
}

const StreamRelayInput: React.FC<StreamRelayInputProps> = ({
  label,
  buttonText,
  inputType = "text",
  className,
  buttonWidth = 82,
  withPasswordIcon = true,
  handleChange,
  handleBlur,
  handleButtonClick,
  ...props
}) => {
  return (
    <div className={classNames("stream-relay-input", className)}>
      <p className="label">{label}</p>
      <div className="stream-relay-input-wrapper">
        <SquareTextField
          className="field"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          type={inputType}
          showAdornment={withPasswordIcon}
          {...props}
        />
        {buttonText && (
          <Button type="button" width={buttonWidth} variant="blue-text" onClick={handleButtonClick}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default StreamRelayInput;
