import { createSelector } from "reselect";

import { AltarStore } from "shared/types";

const selectCMember = (state: AltarStore) => state.member;

export const getMember = () => createSelector(selectCMember, state => state.communityMember);

export const getMembersToModal = () => createSelector(selectCMember, state => state.membersToModal);

export const getChatToken = () => createSelector(selectCMember, state => state.chat_token);

export const getError = () => createSelector(selectCMember, state => state.error);

export const getExistMember = () => createSelector(selectCMember, state => state.memberToConnect);

export const getRestrictedMembers = () => createSelector(selectCMember, state => state.restrictedMembers);

export const getCurrentRestrictedMember = () => createSelector(selectCMember, state => state.currentRestrictedMember);
