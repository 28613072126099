export const SET_LANGUAGE = "INCREMENT_ENTHUSIASM";
export type SET_LANGUAGE = typeof SET_LANGUAGE;
export const SET_COUNTRY = "SET_COUNTRY";
export type SET_COUNTRY = typeof SET_COUNTRY;
export const AUTHENTICATE = "AUTHENTICATE";
export type AUTHENTICATE = typeof AUTHENTICATE;

export const NamesOfParentRoutes = {
  AUTH: "/auth",
  COMMUNITIES: "/communities",
  REGISTER: "/register",
  INVITE: "/invite",
  ONBOARDING: "/onboarding",
  CREATION: "/creation",
  EVENT: "/event",
  STREAM: "/stream",
  BREAKOUTROOMS: "/breakoutrooms",
  WATCH_PARTY: "/watchparty",
  EVENT_DETAILS: "/details",
  EVENT_EDIT: "/edit",
  EVENT_NEW: "/new",
  EVENTS: "/events",
  USERS: "/users",
  COMMUNITY_SETTINGS: "/settings",
  MEETING: "/meeting",
  CLASSROOMS: "/classrooms",
  DASHBOARD: "/dashboard",
  MEMBERS: "/people",
};

export const NameOfRoutes = {
  SIGN_IN: "/",
  SIGN_UP: "/signup",
  AUTH_BY_TOKEN: "/:token",
  RESET: "/reset",
  RESET_COMPLETE: "/resetcompleted",
  MEMBER_VIEW: "/public/:communityCode/people/:memberCode",
  INTRODUCTION_REVIEW: "/public/:communityCode/people/:memberCode/:introductionHash",
  AUTH_BY_HASH: "/link/:hash",
  EVENT_AUTH_SUCCESS: "/auth-success",
  FORGOT: "/forgot",
  EVENT_RECURRING: "/recurring",
  GENERAL_SETTINGS: "/general",
  LANDING_SETTINGS: "/landing",
  BILLING_SETTINGS: "/billing",
  PRICING_SETTINGS: "/pricing",
  TEMPLATES: "/templates",
};

export const TINYMCE_API_KEY = "3n9xw7b4d14fkbnf7mszd5vd32a27s5qvqi7q1t6y5q20860";

export const LAST_FLOW_KEY = "lastFlow";
export const EVENT_FROM_KEY = "eventFrom";

export const APP_ROOT_ID = "root";

export const MODAL_ROOT_ID = "modal-root";
