export enum BreakOutRoomActionTypes {
  SET_ROOM_CODE = "@BREAKOUT_ROOMS/SET_ROOM_CODE",

  GET_USER_MEDIA = "@BREAKOUT_ROOMS/GET_USER_MEDIA",
  GET_USER_MEDIA_SUCCESS = "@BREAKOUT_ROOMS/GET_USER_MEDIA_SUCCESS",
  GET_USER_MEDIA_FAILURE = "@BREAKOUT_ROOMS/GET_USER_MEDIA_FAILURE",

  CONNECT_SOCKET = "@BREAKOUT_ROOMS/CONNECT_SOCKET",
  CONNECT_SOCKET_SUCCESS = "@BREAKOUT_ROOMS/CONNECT_SOCKET_SUCCESS",
  CONNECT_SOCKET_FAILURE = "@BREAKOUT_ROOMS/CONNECT_SOCKET_FAILURE",

  CALL_ROOM = "@BREAKOUT_ROOMS/CALL_ROOM",

  EXIT_ROOM = "@BREAKOUT_ROOMS/EXIT_ROOM",
  EXIT_ROOM_SUCCESS = "@BREAKOUT_ROOMS/EXIT_ROOM_SUCCESS",
  EXIT_ROOM_FAILURE = "@BREAKOUT_ROOMS/EXIT_ROOM_FAILURE",

  ADD_STREAM = "@BREAKOUT_ROOMS/ADD_STREAM",

  REMOVE_STREAM = "@BREAKOUT_ROOMS/REMOVE_STREAM",

  STREAM_CONNECTION_ISSUE = "@BREAKOUT_ROOMS/ADD_CONNECTION_ISSUE",

  HANDLE_CONNECTION_ISSUE = "@BREAKOUT_ROOMS/HANDLE_CONNECTION_ISSUE",

  LEAVE_ROOM = "@BREAKOUT_ROOMS/LEAVE_ROOM",

  JOIN_ROOM = "@BREAKOUT_ROOMS/JOIN_ROOM",

  RESET_STATE = "@BREAKOUT_ROOMS/RESET_STATE",

  UPDATE_ROOMS = "@BREAKOUT_ROOMS/UPDATE_ROOMS",
  UPDATE_ROOMS_SUCCESS = "@BREAKOUT_ROOMS/UPDATE_ROOMS_SUCCESS",
  UPDATE_ROOMS_FAILURE = "@BREAKOUT_ROOMS/UPDATE_ROOMS_FAILURE",

  JOIN_MEETING = "@BREAKOUT_ROOMS/JOIN_MEETING",
  JOIN_MEETING_SUCCESS = "@BREAKOUT_ROOMS/JOIN_MEETING_SUCCESS",
  JOIN_MEETING_FAILURE = "@BREAKOUT_ROOMS/JOIN_MEETING_FAILURE",

  CHANGE_MATES_SOUND = "@BREAKOUT_ROOMS/CHANGE_MATES_SOUND",

  PAGE_RELOAD = "@BREAKOUT_ROOMS/PAGE_RELOAD",
  PAGE_RELOAD_SUCCESS = "@BREAKOUT_ROOMS/PAGE_RELOAD_SUCCESS",
  PAGE_RELOAD_FAILURE = "@BREAKOUT_ROOMS/PAGE_RELOAD_FAILURE",

  HANDLE_FRIEND_MUTE = "@BREAKOUT_ROOMS/HANDLE_FRIEND_MUTE",

  HANDLE_FRIEND_VIDEO = "@BREAKOUT_ROOMS/HANDLE_FRIEND_VIDEO",

  MUTE_ME = "@BREAKOUT_ROOMS/MUTE_ME",

  DISABLE_MY_VIDEO = "@BREAKOUT_ROOMS/DISABLE_MY_VIDEO",

  GET_USER_MEDIA_DEVICES = "@BREAKOUT_ROOMS/GET_USER_MEDIA_DEVICES",
  GET_USER_MEDIA_DEVICES_SUCCESS = "@BREAKOUT_ROOMS/GET_USER_MEDIA_DEVICES_SUCCESS",
  GET_USER_MEDIA_DEVICES_FAILURE = "@BREAKOUT_ROOMS/GET_USER_MEDIA_DEVICES_FAILURE",

  UPDATE_STREAM_SETTINGS = "@BREAKOUT_ROOMS/UPDATE_STREAM_SETTINGS",
  UPDATE_STREAM_SETTINGS_SUCCESS = "@BREAKOUT_ROOMS/UPDATE_STREAM_SETTINGS_SUCCESS",
  UPDATE_STREAM_SETTINGS_FAILURE = "@BREAKOUT_ROOMS/UPDATE_STREAM_SETTINGS_FAILURE",

  ASK_USER_MEDIA_PERMISSIONS = "@BREAKOUT_ROOMS/ASK_USER_MEDIA_PERMISSIONS",

  CHECK_USER_MEDIA_PERMISSIONS = "@BREAKOUT_ROOMS/CHECK_USER_MEDIA_PERMISSIONS",
  CHECK_USER_MEDIA_PERMISSIONS_SUCCESS = "@BREAKOUT_ROOMS/CHECK_USER_MEDIA_PERMISSIONS_SUCCESS",
  CHECK_USER_MEDIA_PERMISSIONS_FAILURE = "@BREAKOUT_ROOMS/CHECK_USER_MEDIA_PERMISSIONS_FAILURE",

  UPDATE_ROOM = "@BREAKOUT_ROOMS/UPDATE_ROOM",

  CHANGE_BREAKOUT_ROOM_SCREEN = "@BREAKOUT_ROOMS/CHANGE_BREAKOUT_ROOM_SCREEN",
}
