import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { StyledTextField, SocialLoginButton, SquareButton, getSubdomain } from "shared";
import { actions, ILoginForm } from "containers/Auth";
import { searchParamsToObject } from "shared/utils/searchParams";
import google from "assets/icons/socialLogin/google.svg";
import facebook from "assets/icons/socialLogin/facebook.svg";

type Props = {
  onSocialLoginSuccess: (data: any) => void;
};
const Join: React.FC<Props> = ({ onSocialLoginSuccess }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { subdomain_part, provider } = searchParamsToObject(history.location.search);

  const { subdomain } = getSubdomain();

  const { values, errors, touched, setFieldValue, handleBlur } = useFormikContext<ILoginForm>();

  useEffect(() => {
    dispatch(actions.clearForgotEmail());
  }, [dispatch]);

  useEffect(() => {
    if (history.location.pathname.includes("stream") && subdomain_part) {
      const button = document.getElementById(`${provider}_social`);
      setTimeout(() => {
        const theEvent = document.createEvent("MouseEvent");
        localStorage.setItem("subdomain_part", subdomain_part);
        theEvent.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        button?.dispatchEvent(theEvent);
      }, 1000);
    }
  }, [history, subdomain_part, provider, subdomain]);

  const subdomainSocialLogin = (provider: string) => {
    const url = window.location.href.replace(subdomain, "app");
    window.location.replace(url + "?subdomain_part=" + subdomain + "&provider=" + provider);
  };

  const socialLoginSuccess = (data: any) => {
    onSocialLoginSuccess(data);
  };

  return (
    <div className="event-auth-join">
      <div className="eventAuthDialog-description">
        Please login or sign up to interact with other online attendees.
      </div>
      <div className="social-media-buttons-wrapper">
        {subdomain === "app" || !subdomain ? (
          <SocialLoginButton
            provider="google"
            text="Continue with Google"
            onSuccess={socialLoginSuccess}
            onFailure={() => {}}
            id="google_social"
            className="event"
          />
        ) : (
          <div className="social-button">
            <button className="google" type="button" onClick={() => subdomainSocialLogin("google")}>
              <img src={google} alt="google" />
              <span>Continue with Google</span>
            </button>
          </div>
        )}
        {subdomain === "app" || !subdomain ? (
          <SocialLoginButton
            provider="facebook"
            text="Continue with Facebook"
            onSuccess={socialLoginSuccess}
            onFailure={() => {}}
            id="facebook_social"
            className="event"
          />
        ) : (
          <div className="social-button">
            <button className="facebook" type="button" onClick={() => subdomainSocialLogin("facebook")}>
              <img src={facebook} alt="facebook" />
              <span>Continue with Facebook</span>
            </button>
          </div>
        )}
        <div className="divider">
          <div className="text">
            <span>OR</span>
          </div>
          <div className="line" />
        </div>
      </div>
      <div className="eventAuthDialog-email">
        <p className="eventAuthDialog-description">Continue with Email</p>
        <StyledTextField
          fullWidth
          label={"Email"}
          placeholder=""
          name="email"
          value={values.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue("email", e.target.value.trim())}
          onBlur={handleBlur}
          errors={touched.email ? errors : null}
        />
      </div>
      <div className="eventAuthDialog-buttons">
        <SquareButton className="proceed" type="submit" variant="blue">
          Proceed
        </SquareButton>
      </div>
    </div>
  );
};

export default Join;
