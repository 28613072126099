import { action, createAsyncAction, createStandardAction } from "typesafe-actions";

import { CommunityActionsTypes } from "./constants";
import {
  ICommunityItem,
  ICommunity,
  ICreateCommunity,
  IUpdateCommunity,
  ICommunitySubscriptions,
  IUpgradeCommunitySubscription,
  ICommunitySubscriptionRequest,
  ICommunityIntroStep,
  SendHubSpotFormDataRequest,
  IDeleteCommunity,
  IMAUData,
  IAnalytics,
  EAnalyticsRange,
  ICreateCommunityLandingSettings,
} from "../interfaces";
import { IUser } from "../../Auth";
import { CommunityState } from "./reducer";

import { RequestWithCallback, ResponseError } from "shared/interfaces";

export const getCommunities = createAsyncAction(
  CommunityActionsTypes.GET_COMMUNITIES_LIST,
  CommunityActionsTypes.GET_COMMUNITIES_LIST_SUCCESS,
  CommunityActionsTypes.GET_COMMUNITIES_LIST_FAILURE,
)<string | undefined, ICommunityItem[], undefined>();

export const getUserCommunities = createAsyncAction(
  CommunityActionsTypes.GET_USER_COMMUNITIES_LIST,
  CommunityActionsTypes.GET_USER_COMMUNITIES_LIST_SUCCESS,
  CommunityActionsTypes.GET_USER_COMMUNITIES_LIST_FAILURE,
)<{ search?: string; currentUser: IUser }, ICommunityItem[], undefined>();

export const getCommunity = createAsyncAction(
  CommunityActionsTypes.GET_COMMUNITY,
  CommunityActionsTypes.GET_COMMUNITY_SUCCESS,
  CommunityActionsTypes.GET_COMMUNITY_FAILURE,
)<string, ICommunity & { hash?: string }, ResponseError>();

export const getCommunityWithVimeoToken = createAsyncAction(
  CommunityActionsTypes.GET_COMMUNITY_WITH_VIMEO_TOKEN,
  CommunityActionsTypes.GET_COMMUNITY_WITH_VIMEO_TOKEN_SUCCESS,
  CommunityActionsTypes.GET_COMMUNITY_WITH_VIMEO_TOKEN_FAILURE,
)<
  { code: string; type: string; callback: (field: string, value: any, shouldValidate?: boolean) => void },
  ICommunity,
  ResponseError
>();

export const createCommunity = createAsyncAction(
  CommunityActionsTypes.CREATE_COMMUNITY,
  CommunityActionsTypes.CREATE_COMMUNITY_SUCCESS,
  CommunityActionsTypes.CREATE_COMMUNITY_FAILURE,
)<ICreateCommunity, ICommunityItem, undefined>();

export const updateCommunity = createAsyncAction(
  CommunityActionsTypes.UPDATE_COMMUNITY,
  CommunityActionsTypes.UPDATE_COMMUNITY_SUCCESS,
  CommunityActionsTypes.UPDATE_COMMUNITY_FAILURE,
)<IUpdateCommunity, ICommunityItem & { hash?: string }, undefined>();

export const deleteCommunity = createAsyncAction(
  CommunityActionsTypes.DELETE_COMMUNITY,
  CommunityActionsTypes.DELETE_COMMUNITY_SUCCESS,
  CommunityActionsTypes.DELETE_COMMUNITY_FAILURE,
)<RequestWithCallback<IDeleteCommunity>, number, undefined>();

export const getAllUsers = createAsyncAction(
  CommunityActionsTypes.GET_ALL_USERS_REQUEST,
  CommunityActionsTypes.GET_ALL_USERS_SUCCESS,
  CommunityActionsTypes.GET_ALL_USERS_FAILURE,
)<undefined, IUser[], undefined>();

export const joinToNewCommunity = createAsyncAction(
  CommunityActionsTypes.JOIN_TO_NEW_COMMUNITY_REQUEST,
  CommunityActionsTypes.JOIN_TO_NEW_COMMUNITY_SUCCESS,
  CommunityActionsTypes.JOIN_TO_NEW_COMMUNITY_FAILURE,
)<string, undefined, undefined>();

export const communityInvitationHash = createAsyncAction(
  CommunityActionsTypes.COMMUNITY_INVITATION_HASH_REQUEST,
  CommunityActionsTypes.COMMUNITY_INVITATION_HASH_SUCCESS,
  CommunityActionsTypes.COMMUNITY_INVITATION_HASH_FAILURE,
  CommunityActionsTypes.COMMUNITY_INVITATION_HASH_CLEAR,
)<string, string, undefined, undefined>();

export const getCommunitySubscriptions = createAsyncAction(
  CommunityActionsTypes.COMMUNITY_SUBSCRIPTIONS_REQUEST,
  CommunityActionsTypes.COMMUNITY_SUBSCRIPTIONS_SUCCESS,
  CommunityActionsTypes.COMMUNITY_SUBSCRIPTIONS_FAILURE,
)<ICommunitySubscriptionRequest, ICommunitySubscriptions, ResponseError>();

export const communityUpgradeSubscription = createAsyncAction(
  CommunityActionsTypes.COMMUNITY_UPGRADE_SUBSCRIPTION_REQUEST,
  CommunityActionsTypes.COMMUNITY_UPGRADE_SUBSCRIPTION_SUCCESS,
  CommunityActionsTypes.COMMUNITY_UPGRADE_SUBSCRIPTION_FAILURE,
)<{ data: IUpgradeCommunitySubscription; cb: () => void }, any, ResponseError>();

export const communityStripeAccountLink = createAsyncAction(
  CommunityActionsTypes.COMMUNITY_STRIPE_ACCOUNT_LINK_REQUEST,
  CommunityActionsTypes.COMMUNITY_STRIPE_ACCOUNT_LINK_SUCCESS,
  CommunityActionsTypes.COMMUNITY_STRIPE_ACCOUNT_LINK_FAILURE,
  CommunityActionsTypes.COMMUNITY_STRIPE_ACCOUNT_LINK_CLEAR,
)<{ communityCode: string; returnPath: string }, string, undefined, undefined>();

export const goToStripeAccount = (payload: { communityCode: string; returnPath: string }) =>
  action(CommunityActionsTypes.GO_TO_STRIPE_ACCOUNT, payload);

export const updateAnalyticsCommunity = () => action(CommunityActionsTypes.UPDATE_ANALYTICS_COMMUNITY);
export const clearCurrentCommunity = () => action(CommunityActionsTypes.CLEAR_CURRENT_COMMUNITY);

export const getCommunityUsers = createAsyncAction(
  CommunityActionsTypes.COMMUNITY_USERS_REQUEST,
  CommunityActionsTypes.COMMUNITY_USERS_SUCCESS,
  CommunityActionsTypes.COMMUNITY_USERS_FAILURE,
)<number, IUser[], undefined>();

export const changeCommunitySettingsForm = createStandardAction(CommunityActionsTypes.CHANGE_COMMUNITY_SETTINGS_FORM)<
  CommunityState["communitySettingsFormSubmit"]
>();

export const getSubdomainList = createAsyncAction(
  CommunityActionsTypes.GET_SUBDOMAIN_LIST,
  CommunityActionsTypes.GET_SUBDOMAIN_LIST_SUCCESS,
  CommunityActionsTypes.GET_SUBDOMAIN_LIST_FAILURE,
)<void, string[], undefined>();

export const getCommunityLandingSettings = createAsyncAction(
  CommunityActionsTypes.GET_COMMUNITY_LANDING_SETTINGS,
  CommunityActionsTypes.GET_COMMUNITY_LANDING_SETTINGS_SUCCESS,
  CommunityActionsTypes.GET_COMMUNITY_LANDING_SETTINGS_FAILURE,
)<number, CommunityState["landingSettings"], undefined>();

export const createCommunityLandingSettings = createAsyncAction(
  CommunityActionsTypes.CREATE_COMMUNITY_LANDING_SETTINGS,
  CommunityActionsTypes.CREATE_COMMUNITY_LANDING_SETTINGS_SUCCESS,
  CommunityActionsTypes.CREATE_COMMUNITY_LANDING_SETTINGS_FAILURE,
)<ICreateCommunityLandingSettings, CommunityState["landingSettings"], undefined>();

export const updateCommunityLandingSettings = createAsyncAction(
  CommunityActionsTypes.UPDATE_COMMUNITY_LANDING_SETTINGS,
  CommunityActionsTypes.UPDATE_COMMUNITY_LANDING_SETTINGS_SUCCESS,
  CommunityActionsTypes.UPDATE_COMMUNITY_LANDING_SETTINGS_FAILURE,
)<ICreateCommunityLandingSettings, CommunityState["landingSettings"], undefined>();
export const getCommunitiesCount = createAsyncAction(
  CommunityActionsTypes.GET_COMMUNITIES_COUNT_REQUEST,
  CommunityActionsTypes.GET_COMMUNITIES_COUNT_SUCCESS,
  CommunityActionsTypes.GET_COMMUNITIES_COUNT_FAILURE,
)<undefined, number, undefined>();

export const clearCurrentLandingSettings = () => action(CommunityActionsTypes.CLEAR_CURRENT_LANDING_SETTINGS);

export const getCommunityStreamingStatistics = createAsyncAction(
  CommunityActionsTypes.GET_COMMUNITIES_STREAMING_STATISTICS_REQUEST,
  CommunityActionsTypes.GET_COMMUNITIES_STREAMING_STATISTICS_SUCCESS,
  CommunityActionsTypes.GET_COMMUNITIES_STREAMING_STATISTICS_FAILURE,
)<number, CommunityState["streamingStatistics"], undefined>();

export const getCommunityIntroductionSteps = createAsyncAction(
  CommunityActionsTypes.GET_COMMUNITY_INTRODUCTION_STEPS_REQUEST,
  CommunityActionsTypes.GET_COMMUNITY_INTRODUCTION_STEPS_SUCCESS,
  CommunityActionsTypes.GET_COMMUNITY_INTRODUCTION_STEPS_FAILURE,
)<number, ICommunityIntroStep[], undefined>();

export const completeCommunityIntroductionStep = createStandardAction(
  CommunityActionsTypes.COMPLETE_COMMUNITY_INTRODUCTION_STEP,
)<{ community_id: number; step_id: number }>();

export const createTrialForCommunityWithoutSubscription = createStandardAction(
  CommunityActionsTypes.CREATE_TRIAL_FOR_COMMUNITY_WITHOUT_SUBSCRIPTION,
)<{ community_code: string; callback: () => void }>();

export const setSubscriptionAttention = createStandardAction(
  CommunityActionsTypes.SET_SUBSCRIPTION_ATTENTION,
)<boolean>();

export const sendHubSpotFormData = createAsyncAction(
  CommunityActionsTypes.SEND_HUB_SPOT_DATA,
  CommunityActionsTypes.SEND_HUB_SPOT_DATA_SUCCESS,
  CommunityActionsTypes.SEND_HUB_SPOT_DATA_FAILED,
)<RequestWithCallback<SendHubSpotFormDataRequest>, undefined, undefined>();

export const getMAUData = createAsyncAction(
  CommunityActionsTypes.GET_MAU_DATA_REQUEST,
  CommunityActionsTypes.GET_MAU_DATA_SUCCESS,
  CommunityActionsTypes.GET_MAU_DATA_FAILURE,
)<number, IMAUData, undefined>();

export const fetchAnalytics = createAsyncAction(
  CommunityActionsTypes.FETCH_ANALYTICS_REQUEST,
  CommunityActionsTypes.FETCH_ANALYTICS_SUCCESS,
  CommunityActionsTypes.FETCH_ANALYTICS_FAILURE,
)<{ id: number; dateRange: { from: string; to: string }; range: EAnalyticsRange }, IAnalytics, undefined>();

export const setMAUBannerHidden = createStandardAction(CommunityActionsTypes.SET_MAU_BANNER_HIDDEN)<boolean>();
