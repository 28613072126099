import React from "react";

import { EStripeStatusCode, IProduct } from "../../../interfaces";

import { Button } from "shared";

import "./subscriptionInfo.scss";

interface ISubscriptionInfo {
  subscriptionInfo: {
    leftDays: number;
    subscription?: IProduct;
  };
  purchaseButtonHandler: () => void;
}

const SubscriptionInfo: React.FC<ISubscriptionInfo> = ({ subscriptionInfo, purchaseButtonHandler }) => {
  return (
    <>
      {subscriptionInfo.subscription?.stripe_status_code === EStripeStatusCode.trialing ? (
        <div className="planInfo">
          <Button variant="orange" width={224} onClick={purchaseButtonHandler}>
            <span>I'm Ready to Purchase</span>
          </Button>
          <div className="planInfo-timeLeft">
            Free trial ends {subscriptionInfo.leftDays !== 1 && " in"}
            <span>{subscriptionInfo.leftDays === 1 ? " today" : ` ${subscriptionInfo.leftDays} days`}</span>
          </div>
        </div>
      ) : !subscriptionInfo.subscription ? (
        <div className="planInfo">
          <Button variant="orange" width={224} onClick={purchaseButtonHandler}>
            <span>Activate Account</span>
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default SubscriptionInfo;
