import React, { useEffect, useRef, useState } from "react";

import { MAIN_SPEAKERS } from "./constants";

import { ClickOutsideHook } from "shared";

interface IProps {
  speaker: typeof MAIN_SPEAKERS[number];
}

const EcammSpeakers: React.FC<IProps> = ({ speaker: { img, name, title, description } }) => {
  const ref = useRef(null);

  const { isOutside } = ClickOutsideHook(ref);

  const [showDescription, setShowDescription] = useState(false);
  useEffect(() => {
    if (isOutside) {
      setShowDescription(false);
    }
  }, [isOutside]);
  return (
    <div
      className={`speaker ${showDescription ? "active" : ""}`}
      onClick={() => setShowDescription(prevState => !prevState)}
      ref={ref}
    >
      <img className="speaker-image" src={img} alt="pic" />
      <div className="speaker-name">{name}</div>
      <div className="speaker-title">{title}</div>
      {showDescription ? <div className="speaker-description">{description}</div> : null}
    </div>
  );
};

export default EcammSpeakers;
