import React, { useMemo } from "react";
import classnames from "classnames";

import { IMember } from "../../../interfaces";

import { useCurrentUserMember } from "shared/hooks";
import { MemberImage } from "shared/components/MemberImage";
import { StyledCheckBox } from "shared/components";

import "./membersTable.scss";

interface IMemberTable {
  members: any[];
  onMemberClick: (member: IMember) => void;
  onSelectMember: (member: IMember, isSelected: boolean) => void;
  canEdit: boolean;
  selectedMembers: IMember[];
}

const MembersTable: React.FunctionComponent<IMemberTable> = props => {
  const { onMemberClick, onSelectMember, members, canEdit, selectedMembers } = props;

  const currentUserMember = useCurrentUserMember();
  const memberList = useMemo(() => {
    return members.map((member, idx) => {
      const invitedAt = !!(member.invitation && member.invitation.created_at);
      const isSelected = !!selectedMembers.find(m => m.id === member.id);
      const handleSelectCheckbox = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        onSelectMember(member, isSelected);
      };
      return (
        <tr
          key={idx}
          className="members-table__body_info"
          onClick={() => onMemberClick({ ...member, email: member.email })}
        >
          <td>
            <div className="member__info">
              <div
                className={classnames("item-select", { opened: isSelected && canEdit, disableOpen: !canEdit })}
                onClick={handleSelectCheckbox}
              >
                <StyledCheckBox checked={isSelected} theme="light" />
              </div>
              <MemberImage member={member} />
              <div className="member__info_name">
                {`${member?.first_name} ${member?.last_name}`}
                {currentUserMember && currentUserMember.id === member?.id && (
                  <span className="member__info_name-current-user">&nbsp; (You)</span>
                )}
              </div>
            </div>
          </td>
          <td>
            <span>{canEdit ? member.email : ""}</span>
          </td>
          <td>
            {canEdit && (
              <span
                className={`status ${
                  member.has_accepted_invitation ? "accepted" : invitedAt ? "pending" : "not-invited"
                }`}
              >
                {member.has_accepted_invitation ? "Accepted" : invitedAt ? "Pending" : "Not Invited"}
              </span>
            )}
          </td>
        </tr>
      );
    });
  }, [members, selectedMembers, canEdit, onMemberClick, onSelectMember, currentUserMember]);

  return (
    <table className="members-table">
      <thead className="members-table__head">
        <tr className="members-table__head_label">
          <th>
            <div className="members-table__head_label_user">
              <span>Name</span>
            </div>
          </th>
          <th>
            <span>Email</span>
          </th>
          <th>
            <span>
              <span>Status</span>
            </span>
          </th>
        </tr>
      </thead>

      <tbody className="members-table__body">{memberList}</tbody>
    </table>
  );
};

export default React.memo(MembersTable);
