import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { updateEventSettings } from "../../../store/actions";
import { EventDashboardTabs } from "../../../interfaces";
import { getEventMembers, getEventSettings } from "../../../store/selectors";

import { selectStream } from "containers/BreakoutRooms/store/selectors";

import "./index.scss";

const EventMembersCounter = () => {
  const dispatch = useDispatch();
  const eventMembers = useSelector(getEventMembers());
  const { activeDashboardTab } = useSelector(getEventSettings());
  const stream = useSelector(selectStream());

  const handleClickCounter = () => {
    dispatch(
      updateEventSettings({
        activeDashboardTab: EventDashboardTabs.members,
      }),
    );
  };

  return (
    <div
      className={classnames("event-members-counter-wrapper", {
        leftSideBar: !!stream,
        rightSideBar: !!activeDashboardTab,
        bothSideBars: !!activeDashboardTab && !!stream,
      })}
      onClick={handleClickCounter}
    >
      <div className="event-members-counter-icon" />
      <div className="event-members-counter-label">{`${eventMembers.length} Attendee${
        eventMembers.length > 1 ? "s" : ""
      }`}</div>
    </div>
  );
};
export default EventMembersCounter;
