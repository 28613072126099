import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../Button";
import { actions as communityActions, ICommunityItem } from "../../../containers/Community";
import { deleteUser } from "../../../containers/User/store/actions";
import { getUserCommunities } from "../../../containers/Community/store/selectors";
import { AlertDialog } from "../AlertDialog";
import { StyledLink } from "../StyledLink";
import { LINKS } from "../../constants/links";
import { getUser } from "../../../containers/Auth/store/selectors";
import { openWidget } from "../../../utils/hubspot";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../constants";

import config from "config";

import "./styles.scss";

enum ECannotDeleteUserReason {
  lastMember = "lastMember",
  mainContact = "mainContact",
}

type Props = {
  isSeatSamePage: boolean;
  alertVariant?: "brown";
  closeEdit?: () => void;
};

const { COMMUNITY_SETTINGS } = NamesOfParentRoutes;
const { GENERAL_SETTINGS } = NameOfRoutes;

const DeleteUserButton: React.FC<Props> = ({ isSeatSamePage, alertVariant, closeEdit }) => {
  const dispatch = useDispatch();
  const userCommunities = useSelector(getUserCommunities());
  const currentUser = useSelector(getUser());

  const [cannotDeleteUserReason, setCannotDeleteUserReason] = useState<string | null>(null);
  const [openDeleteUserAlert, setOpenDeleteUserAlert] = useState(false);
  const [mainContactCommunities, setMainContactCommunities] = useState<ICommunityItem[]>([]);

  const onConfirmDeleteUser = useCallback(() => {
    if (currentUser) {
      dispatch(deleteUser.request({ user_id: currentUser.id, isSeatSamePage }));
    }
    setOpenDeleteUserAlert(false);
  }, [currentUser, dispatch, isSeatSamePage]);

  const onConfirmCannotDelete = useCallback(() => {
    setCannotDeleteUserReason(null);
    if (cannotDeleteUserReason === ECannotDeleteUserReason.lastMember) {
      openWidget();
      closeEdit?.();
    }
  }, [cannotDeleteUserReason, closeEdit]);

  const deleteUserHandler = useCallback(() => {
    if (currentUser) {
      const mainContactCommunities: ICommunityItem[] = [];
      const lastMainContactCommunities: ICommunityItem[] = [];
      userCommunities.forEach(c => {
        if (c.main_contact_id === currentUser.id) {
          c.members_count === 1 ? lastMainContactCommunities.push(c) : mainContactCommunities.push(c);
        }
      });
      if (mainContactCommunities.length || lastMainContactCommunities.length) {
        const reason = lastMainContactCommunities.length
          ? ECannotDeleteUserReason.lastMember
          : ECannotDeleteUserReason.mainContact;
        const communitiesList = lastMainContactCommunities.length ? lastMainContactCommunities : mainContactCommunities;
        setCannotDeleteUserReason(reason);
        setMainContactCommunities(communitiesList);
      } else {
        setOpenDeleteUserAlert(true);
      }
    }
  }, [userCommunities, currentUser]);

  useEffect(() => {
    if (currentUser) {
      dispatch(communityActions.getUserCommunities.request({ currentUser }));
    }
  }, [currentUser, dispatch]);

  return (
    <div className="deleteUser">
      <AlertDialog
        open={openDeleteUserAlert}
        title="Delete My User Account"
        message="Are you sure you want to delete your entire account record, along with associate personal data? Your data will be permanently deleted after 24 hours."
        onConfirm={onConfirmDeleteUser}
        onCancel={() => setOpenDeleteUserAlert(false)}
        mode="confirm"
        confirmText="Yes, delete"
        variant={alertVariant}
        confirmClassName="delete-user-button"
      />
      <AlertDialog
        open={!!cannotDeleteUserReason}
        title={
          cannotDeleteUserReason === ECannotDeleteUserReason.lastMember
            ? "Please contact Altar Customer Support"
            : "Please change the Main Contact"
        }
        message={
          <div className="deleteUserAlert-content">
            {cannotDeleteUserReason === ECannotDeleteUserReason.lastMember
              ? "You are the only one member for the following Altar community account. We cannot delete yourself as the Main Contact until you have switched ownership of the community to someone else."
              : "You are the Main Contact for the following Altar community account. You need to change the Main Contact before deleting your User Account."}
            <ul className="deleteUserAlert-content-communitiesList">
              {mainContactCommunities.map((c, index) =>
                index < 5 ? (
                  <StyledLink
                    key={c.id}
                    className="deleteUserAlert-content-communitiesList-item"
                    href={`${config.applicationUrl}/${c.code}${COMMUNITY_SETTINGS}${GENERAL_SETTINGS}`}
                  >
                    <li>{c.name}</li>
                  </StyledLink>
                ) : null,
              )}
            </ul>
            <StyledLink className="deleteUserAlert-content-changeMainContact" href={LINKS.changeMainContact}>
              How to change main contact
            </StyledLink>
          </div>
        }
        dialogClassName="deleteUserAlert"
        onConfirm={onConfirmCannotDelete}
        onCancel={() => setCannotDeleteUserReason(null)}
        mode="info"
        confirmText={cannotDeleteUserReason === ECannotDeleteUserReason.lastMember ? "Contact us" : "Got it"}
        variant={alertVariant}
      />
      <Button variant="red" type="button" onClick={deleteUserHandler}>
        Delete My User Account
      </Button>
      <div className="deleteUser-description">
        This will delete your entire account record, along with associated personal data.
      </div>
    </div>
  );
};
export default DeleteUserButton;
