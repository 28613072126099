import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { ChannelListMessengerProps, useChatContext } from "stream-chat-react";
import * as Client from "stream-chat";
import { useDispatch, useSelector } from "react-redux";

import { showGreeterCustomChannel } from "../../store/actions";
import { EmptyChatState } from "../EmptyChatState";
import { CustomGreeterChannel } from "../CustomGreeterChannel";
import { EventType } from "../../../Sermons";
import { MAX_QUERY_CHANNELS_LIMIT } from "../../constants";

import { actions, selectors } from "containers/Discussions/store";
import { WrappedLoader } from "shared/components";
import { IMember } from "containers/Member/interfaces";
import { useMemberCID } from "shared/hooks/MemberCIDHook";
import { getMember } from "containers/Auth/store/selectors";
import { DiscussionTheme } from "containers/Discussions/containers/DiscussionsContainer/DiscussionsContainer";
import { getEvent } from "containers/Sermons/store/selectors";
import { useGreetingChannel } from "shared";

import "./channelList.scss";

interface ChannelListProps extends ChannelListMessengerProps {
  channels?: Client.Channel[];
  onChangeChannel?: () => void;
  theme?: DiscussionTheme;
  onReplyGreeterCustomCh: () => void;
  isEventDiscussions: boolean;
}

const filterAllowedChannels = (channels: ReactElement[], channelIds?: Set<string>) => {
  if (channelIds) {
    return channels.filter(
      channel => channelIds.has(channel?.props?.channel?.id) && !channel?.props?.channel?.disconnected,
    );
  }
  return channels;
};

const sortChannels = (children: ReactElement, channelIds?: Set<string>): ReactElement[] => {
  let sortedChildren: ReactElement[] = [];
  const { props } = children;
  if (props.children) {
    sortedChildren = filterAllowedChannels(props.children, channelIds);
  }
  const sortedChannelIds = new Set<string>(sortedChildren.map(channel => channel?.props?.channel?.data?.id));
  return Array.from(sortedChannelIds).map(id =>
    sortedChildren.find(channel => channel?.props?.channel?.data?.id === id),
  ) as ReactElement[];
};

const filterEmptyChannels = (
  channels: ReactElement[],
  member?: IMember,
  cid?: string,
  activeChannel?: string,
  checkIsCreator?: boolean,
) => {
  return channels.filter(channel => {
    const hasMessages = channel?.props?.channel?.state?.messages?.length > 0;
    let isCreator = false;
    if (checkIsCreator) {
      isCreator = String(channel?.props?.channel?.data?.created_by?.id) === String(member?.id);
    }
    const isAnonymous = !member && cid;
    return hasMessages || isCreator || isAnonymous || channel?.props?.channel?.id === activeChannel;
  });
};

const CustomChannelList: React.FC<ChannelListProps> = props => {
  const {
    setChannels,
    children,
    loading,
    onChangeChannel,
    theme,
    onReplyGreeterCustomCh,
    isEventDiscussions,
    channels,
  } = props;
  const dispatch = useDispatch();

  const cid = useMemberCID();
  const member = useSelector(getMember());
  const selectedChannel = useSelector(selectors.getSelectedChannelDetails());
  const activeChannel = useSelector(selectors.getActiveChannel());
  const channelIds = useSelector(selectors.getEventChannelIds());
  const event = useSelector(getEvent());
  const isGreeterCustomChannel = useSelector(selectors.showGreeterCustomChannel());
  const { setActiveChannel } = useChatContext();

  const filteredChildren = useMemo(() => {
    const sortedChildren = sortChannels(children as ReactElement, channelIds);
    return filterEmptyChannels(sortedChildren, member, cid, activeChannel, !!event);
  }, [channelIds, member, cid, activeChannel, children, event]);

  const { currentUserIsGreeter, isGreeterChannelExist, eventHasGreeter } = useGreetingChannel(selectedChannel);

  const setChannel = useCallback(
    async (channel: Client.Channel) => {
      if (setActiveChannel) {
        setActiveChannel(channel);
        onChangeChannel && onChangeChannel();
      }
    },
    [setActiveChannel, onChangeChannel],
  );

  useEffect(() => {
    channels && !loading && dispatch(actions.setGetStreamChannels(channels));
  }, [channels, dispatch, loading]);

  useEffect(() => {
    const currentChannel = !!channels && channels.find(channel => channel.id === activeChannel);
    if (currentChannel) {
      setChannel(currentChannel);
    }
  }, [dispatch, setChannel, channels, selectedChannel, activeChannel]);

  useEffect(() => {
    dispatch(
      showGreeterCustomChannel(
        event?.type !== EventType.meeting &&
          !isGreeterChannelExist &&
          eventHasGreeter &&
          isEventDiscussions &&
          !currentUserIsGreeter,
      ),
    );
  }, [dispatch, channelIds, currentUserIsGreeter, isEventDiscussions, eventHasGreeter, isGreeterChannelExist, event]);

  useEffect(() => {
    if (channels && channels.length > MAX_QUERY_CHANNELS_LIMIT) {
      setChannels?.(channels);
    }
  }, [channels, setChannels]);

  return (filteredChildren.length && channels?.length) ||
    loading ||
    (isGreeterCustomChannel && isEventDiscussions && !currentUserIsGreeter) ? (
    <WrappedLoader isLoading={loading}>
      {!currentUserIsGreeter && isEventDiscussions && isGreeterCustomChannel ? (
        <CustomGreeterChannel onReplyGreeterCustomCh={onReplyGreeterCustomCh} event={event} />
      ) : null}
      <div className="channelList-items">{filteredChildren}</div>
    </WrappedLoader>
  ) : (
    <EmptyChatState theme={theme} title="No Chats here yet" listType="message" />
  );
};

export default React.memo(CustomChannelList);
