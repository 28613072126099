import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import * as HttpStatus from "http-status-codes";
import classnames from "classnames";

import { NamesOfParentRoutes } from "../../../../constants";
import { PresenterAdvices } from "../PresenterAdvices";
import CountdownTimer from "../CountdownTimer";

import { Button, StyledTextField, StyledLink, EmptyText, useCurrentUserMember, IRouteProps } from "shared";
import { getUser, authentificated } from "containers/Auth/store/selectors";
import {
  checkMeetingRole,
  EventStatus,
  EventType,
  getEventTimeRange,
  ICountdown,
  IEvent,
  SermonRoles,
  formatMonthDay,
  getTimeFormat,
  Recordings,
  selectors,
  actions,
} from "containers/Sermons";
import { isMatchedPath } from "containers/Community/utils";
import { LINKS } from "shared/constants/links";
import { selectors as communitySelectors, actions as communityActions } from "containers/Community/store";
import { useIsMobile } from "shared/hooks/IsMobileHook";

import "./countdown.scss";

const { EVENTS, AUTH, EVENT, INVITE } = NamesOfParentRoutes;

const getRemainingTime = (event: IEvent | null) => moment(event?.starting_at).diff(moment());

const Countdown: React.FC<ICountdown> = ({ onStartMeeting, permissionsGranted, customNameData, logIn, signUp }) => {
  const { t } = useTranslation();
  const { eventCode } = useParams<IRouteProps>();

  const history = useHistory();

  const isFromInvitation = isMatchedPath(history.location.pathname, `${EVENT}/:event_id${INVITE}`);

  const event = useSelector(selectors.getEvent());
  const currentUser = useSelector(getUser());
  const eventError = useSelector(selectors.getError());
  const isAuthenticated = useSelector(authentificated());
  const communityInvitationHash = useSelector(communitySelectors.getCommunityInvitationHash());

  const currentUserMember = useCurrentUserMember();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isFromInvitation && eventCode && !isAuthenticated) {
      history.push(AUTH, {
        redirectTo: `${EVENT}/${eventCode}`,
      });
    }
  }, [isFromInvitation, eventCode, isAuthenticated, history]);

  useEffect(
    () => () => {
      dispatch(actions.clearEventError());
      dispatch(communityActions.communityInvitationHash.cancel());
    },
    [dispatch],
  );

  useEffect(() => {
    if (!communityInvitationHash && event?.community?.code) {
      dispatch(communityActions.communityInvitationHash.request(event.community.code));
    }
  }, [dispatch, event, communityInvitationHash]);

  const remainingTime = getRemainingTime(event);

  const [timeHasCome, setTimeHasCome] = useState(remainingTime <= 0);

  const isProducer = useMemo(
    (): boolean =>
      Boolean(event && currentUserMember && checkMeetingRole(event, currentUserMember, SermonRoles.producer)),
    [event, currentUserMember],
  );

  const isPresenter = useMemo(
    (): boolean =>
      Boolean(event && currentUserMember && checkMeetingRole(event, currentUserMember, SermonRoles.presenter)),
    [event, currentUserMember],
  );
  const getEmptyText = useCallback(() => {
    if (eventError?.code && [HttpStatus.FORBIDDEN, HttpStatus.NOT_FOUND].includes(eventError.code)) {
      const errorKey = eventError.code === HttpStatus.NOT_FOUND ? "notFound" : "noAccess";
      return (
        <div className="empty-text">
          <h3 className="empty-text-title">Sorry!</h3>
          <p className="empty-text-body">
            {t(`errors.event.${errorKey}`, {
              eventType: eventError?.meta?.eventType === EventType.meeting ? "meeting" : "event",
            })}
          </p>
        </div>
      );
    }
  }, [t, eventError]);

  const canStart = useMemo((): boolean => {
    if (!event || [EventStatus.draft, EventStatus.ended].includes(event.status)) {
      return false;
    }
    if (event.type === EventType.liveService) {
      return event.status === EventStatus.live || ((isPresenter || isProducer) && event.status === EventStatus.started);
    }
    return event.status === EventStatus.started || (timeHasCome && event.status === EventStatus.scheduled);
  }, [event, timeHasCome, isPresenter, isProducer]);

  const timerLabel = useMemo((): string => {
    if (!event || [EventStatus.draft, EventStatus.ended].includes(event.status)) {
      return "";
    }
    if (
      event.type === EventType.meeting &&
      (event.status === EventStatus.started || (timeHasCome && event.status === EventStatus.scheduled))
    ) {
      return "You can now join the meeting";
    }
    if (event.status === EventStatus.live) {
      return "Event is now live";
    }
    if (!isPresenter && !isProducer && event.type === EventType.liveService) {
      return "Please wait for the organizer to start the meeting";
    }
    if (event.status === EventStatus.started) {
      return "Presenters can now join";
    }
    if (!timeHasCome) {
      return `${t(`event.type.${event.type}`)} will start in`;
    }
    return "Please wait for the producer to start the meeting from a tablet";
  }, [isPresenter, isProducer, event, t, timeHasCome]);

  const timeFormat = getTimeFormat();
  const locale = moment.locale();

  const eventDate = useMemo(() => (event && locale ? formatMonthDay(event.starting_at) : ""), [event, locale]);
  const eventTimeRange = useMemo(() => (event ? getEventTimeRange(event, timeFormat) : ""), [event, timeFormat]);

  const backToEventsHandler = useCallback(() => {
    if (event?.community) {
      const { community } = event;
      dispatch(actions.clearCurrentSermon());
      return history.push(community ? `/${community.code}${EVENTS}` : EVENTS);
    }
  }, [event, history, dispatch]);

  const isMobile = useIsMobile();

  return (
    <div className={`countdown ${isPresenter ? "presenter-view" : ""}`}>
      <div className={classnames("countdown-content", { fullWidth: eventError })}>
        <div className="countdown-content-main block">
          <div className="bg-element el1" />
          <div className="bg-element el2" />
          <div className="bg-element el3" />
          <div className="bg-element el4" />
          {event && event.status === EventStatus.ended ? (
            <div className="event-ended">
              <h1>The {t(`event.type.${event.type}`)} has ended</h1>
              <Recordings />
              {currentUser && (
                <Button variant="blue-text" width={346} className="back-to-events-button" onClick={backToEventsHandler}>
                  back to events & meetings
                </Button>
              )}
            </div>
          ) : (
            <>
              {eventError ? (
                <EmptyText message={getEmptyText()} show={!!eventError} />
              ) : (
                <div className={classnames("countdown-content-main-container", isProducer ? "producer-view" : "")}>
                  {!currentUser && (
                    <div className="countdown-content-main-timer-wrapper anonymous-name">
                      <h3 className="countdown-content-main-timer-title">Hi there!</h3>
                      <div className="countdown-content-main-timer-description">
                        We're so glad you're here, please enter your name for other members to see.
                      </div>
                      <StyledTextField
                        fullWidth
                        placeholder=""
                        name="customName"
                        className="countdown-content-main-customName"
                        value={customNameData.value}
                        onChange={customNameData.onInputChange}
                        errors={customNameData.errors}
                      />
                    </div>
                  )}
                  <div className="countdown-content-main-timer-wrapper">
                    <div className="countdown-content-main-timer">
                      <h3 className="countdown-content-main-timer-title">{timerLabel}</h3>
                      <CountdownTimer initTime={remainingTime} onTimeHasCome={() => setTimeHasCome(true)} inTwoLines />
                    </div>
                    {!isPresenter && (
                      <div className="countdown-content-main-action">
                        {isMobile && (
                          <p>
                            Please join the event in the
                            <br />
                            Altar Live app
                          </p>
                        )}
                        <Button
                          width={296}
                          onClick={onStartMeeting}
                          disabled={!canStart || !!customNameData.errors}
                          variant={!canStart || !!customNameData.errors ? "disabled" : "orange"}
                        >
                          {t(isMobile ? "join on mobile app" : "join")}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {isPresenter && event && (
                <PresenterAdvices
                  isProducer={isProducer}
                  isPresenter={isPresenter}
                  event={event}
                  onStartMeeting={onStartMeeting}
                  canJoin={Boolean(canStart && ((!isPresenter && !isProducer) || permissionsGranted))}
                />
              )}
            </>
          )}
          {!isPresenter && !isProducer && (
            <div className="countdown-content-main-member">
              {currentUser ? (
                <p className="welcome-text">Welcome back, {currentUser.first_name}</p>
              ) : (
                <>
                  <div className="sign-in">
                    Already a Member?
                    <Button variant="blue-outline" width={136} onClick={logIn}>
                      {t("log in")}
                    </Button>
                  </div>
                  <div className="sign-up">
                    Join our Community?
                    <Button variant="blue-outline" width={136} onClick={signUp}>
                      {t("sign up")}
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="countdown-content-help block">
          {!isPresenter && (
            <div className="countdown-content-help-mobile">
              <div>
                <p>We also have a mobile app you can watch the service from.</p>
                <StyledLink href={LINKS.participantIOS} className="countdown-content-help-link">
                  learn more
                </StyledLink>
              </div>
            </div>
          )}
          <div className="countdown-content-help-guides">
            <StyledLink href={LINKS.support} className="countdown-content-help-link">
              <span className="help-link">Help & Guides</span>
            </StyledLink>
          </div>
        </div>
      </div>
      <div className={classnames("countdown-event block", { hideEventBlock: !!eventError })}>
        <div className="countdown-event-details">
          <h3>{event?.name}</h3>
          <p className="subject">{event?.subject}</p>
          <p>{eventDate}</p>
          <p>{eventTimeRange}</p>
        </div>
      </div>
    </div>
  );
};
export default Countdown;
