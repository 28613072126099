import { createSelector } from "reselect";

import { AltarStore } from "../../../shared";

const selectDiscussions = (state: AltarStore) => state.discussions;

export const getChannels = () => createSelector(selectDiscussions, state => state?.channels);
export const getSelectedChannelDetails = () => createSelector(selectDiscussions, state => state.selectedChannel);
export const getActiveChannel = () => createSelector(selectDiscussions, state => state?.activeChannel);
export const getShowChatList = () => createSelector(selectDiscussions, state => state?.showChatList);
export const getStreamChannels = () => createSelector(selectDiscussions, state => state.getStreamChannels);
export const getCreateChatWithMember = () => createSelector(selectDiscussions, state => state.createChatWithMember);
export const getUnreadMessagesCount = () => createSelector(selectDiscussions, state => state.unreadMessagesCount);
export const getEventChannelIds = () => createSelector(selectDiscussions, state => state.channelsIds);
export const getSentMessagesDates = () => createSelector(selectDiscussions, state => state.sentMessagesDates);
export const getAllowGeneralChatInput = () => createSelector(selectDiscussions, state => state.allowGeneralChatInput);
export const showGreeterCustomChannel = () =>
  createSelector(selectDiscussions, state => state.showGreeterCustomChannel);
export const getCreatedGeneralChannel = () => createSelector(selectDiscussions, state => state.createdGeneralChatId);
