import "./customMessage.scss";

import classnames from "classnames";
import getEmojiRegex from "emoji-regex";
import moment from "moment";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Message, StreamChat, Attachment as AttachmentType } from "stream-chat";
import {
  Attachment,
  MessageUIComponentProps,
  ReactionSelector,
  useMessageContext,
  useEmojiContext,
  useChannelActionContext,
  useUserRole,
} from "stream-chat-react";

import { authentificated, getMember } from "../../../Auth/store/selectors";
import { IGreeter, IMember } from "../../../Member";
import { DiscussionTheme } from "../../containers/DiscussionsContainer/DiscussionsContainer";
import { EditMessageObj } from "../../interfaces";
import ActionsTooltip from "../ActionsTooltip/ActionsTooltip";
import { CustomAvatar } from "../CustomAvatar";
import { CustomReactionsList } from "../ReactionsList";
import { memberHasEventRoleOrAdmin } from "../../../Sermons";
import { MessageOptionMenu } from "../MessageOptionMenu";

import { getSelectedChannelDetails } from "containers/Discussions/store/selectors";
import { AlertDialog } from "shared";
import { EventStatus } from "containers/Sermons/interfaces";
import { getEvent } from "containers/Sermons/store/selectors";
import { timeToAMPM } from "shared/utils/getStream";
import { EventTooltip } from "shared/components";
import chat_error from "assets/icons/chat_error.svg";
import edit_icon_gray from "assets/icons/edit_icon_gray.svg";
import trash from "assets/icons/trash.svg";
import emoji_gray from "assets/icons/emoji-gray.svg";

interface SimpleMessageProps extends MessageUIComponentProps {
  setEditMessageDetails: (obj: EditMessageObj) => void;
  userImage?: string;
  userName: string;
  handleUserClick: () => void;
  client: StreamChat;
  theme?: DiscussionTheme;
  member?: IMember;
  color?: string;
  channelGreeter?: IGreeter;
  messageWithoutHeader: boolean;
  generalChannel?: boolean;
  isModerator?: boolean;
  restrictionIconSrc?: string;
}

const emojiRegex = getEmojiRegex();

const tooltipText = (date?: Date | string) => {
  if (!date) return "";
  const messageDate = moment(date);
  return `${messageDate.format("MMM D, YYYY")} at ${messageDate.format("h:mm  A")}`;
};

export const SimpleMessage: React.FC<SimpleMessageProps> = props => {
  const {
    client,
    clearEditingState,
    setEditMessageDetails,
    userName,
    theme,
    member,
    color,
    channelGreeter,
    messageWithoutHeader = false,
    generalChannel,
    restrictionIconSrc,
  } = props;
  const { message, setEditingState, handleReaction } = useMessageContext();
  const { isMyMessage, isModerator } = useUserRole(message);
  const { updateMessage } = useChannelActionContext();
  const { user, html, created_at, attachments, latest_reactions, reaction_counts, reaction_scores, type } = message;
  const [actionsAnchor, setActionsAnchor] = useState<Element | null>(null);
  const [deleteDialogIsOpened, setDeleteDialogIsOpened] = useState(false);
  const messageWrapperRef = useRef(null);
  const event = useSelector(getEvent());
  const eventIsEnded = event?.status === EventStatus.ended;
  const currentUserMember = useSelector(getMember());
  const selectedChannel = useSelector(getSelectedChannelDetails());
  const canDelete = useMemo(() => {
    return (
      isMyMessage ||
      (event &&
        currentUserMember &&
        (selectedChannel?.is_group || (generalChannel && memberHasEventRoleOrAdmin(event, currentUserMember))) &&
        isModerator)
    );
  }, [currentUserMember, event, isMyMessage, selectedChannel, generalChannel, isModerator]);

  const {
    emojiConfig: { defaultMinimalEmojis },
  } = useEmojiContext();

  const handleActionsClose = () => {
    setActionsAnchor(null);
  };

  const isAuthenticated = useSelector(authentificated());

  const editedMark = useMemo(() => {
    return message.isEdited ? '<span class="customMessage-header-edited">(edited)</span>' : "";
  }, [message]);

  const htmlWithEmojiSpans = useMemo(
    () => `<div>
      ${html?.replace(emojiRegex, '<span class="emoji">$&</span>').replace("<a ", '<a target="_blank"')}
      ${editedMark}
    </div>`,
    [html, editedMark],
  );

  const handleDeleteMessage = useCallback(async () => {
    const data = await client.deleteMessage(message.id);
    updateMessage && updateMessage(data.message);
  }, [updateMessage, client, message.id]);

  const reactionHandler = useCallback(
    async (reactionType: string, event?: React.BaseSyntheticEvent) => {
      handleActionsClose();
      handleReaction && event && handleReaction(reactionType, event);
    },
    [handleReaction],
  );

  const clearEditingStateHandler = useCallback(
    async (response?: { message: Message }) => {
      if (response) {
        await client.updateMessage({ ...response.message, isEdited: true }, client.userID);
      }

      clearEditingState?.();
    },
    [client, clearEditingState],
  );

  const handleEditClick = useCallback(
    (e: React.MouseEvent) => {
      setEditingState?.(e);
      setEditMessageDetails({
        message,
        clearEditingState: clearEditingStateHandler,
      });
    },
    [clearEditingStateHandler, message, setEditMessageDetails, setEditingState],
  );

  const isOnlyMedia = useMemo(() => {
    return (
      !html &&
      !!attachments &&
      attachments.length === 1 &&
      (attachments[0].type === "image" ||
        (attachments[0].mime_type && (attachments[0].mime_type as string).includes("video")))
    );
  }, [html, attachments]);

  const isStreamTheme = useMemo(() => {
    return theme === "stream";
  }, [theme]);

  const showActions = useMemo(() => {
    return !eventIsEnded && (isAuthenticated || !generalChannel);
  }, [eventIsEnded, isAuthenticated, generalChannel]);

  const renderReactions = useMemo(
    () => (
      <div className="customMessage-reactions-wrapper">
        <div className="customMessage-reactions">
          <ReactionSelector
            detailedView={false}
            handleReaction={reactionHandler}
            latest_reactions={latest_reactions || []}
            reaction_counts={reaction_scores || {}}
          />
        </div>
      </div>
    ),
    [reactionHandler, latest_reactions, reaction_scores],
  );
  return (
    <div
      className={classnames(
        "customMessageWrapper",
        { "customMessageWrapper-owner": isMyMessage },
        { "customMessageWrapper-grouped": messageWithoutHeader },
      )}
    >
      <AlertDialog
        title="Delete the message?"
        message="Are you sure you want to delete this message?"
        open={deleteDialogIsOpened}
        onConfirm={() => {
          setDeleteDialogIsOpened(false);
          handleDeleteMessage();
        }}
        onCancel={() => setDeleteDialogIsOpened(false)}
        confirmText="Delete"
        cancelText="Cancel"
        mode="cancel"
        variant="brown"
        dialogClassName="alert-dialog-popup"
        confirmClassName="defaultButtons-remove"
      />
      <div className="customMessage-container">
        <ActionsTooltip
          onClose={handleActionsClose}
          open={Boolean(actionsAnchor)}
          anchorEl={actionsAnchor}
          className="emodji-reactions-popover"
        >
          <div className={classnames("customMessage-header-actionsBtn", { opened: !!actionsAnchor, event })}>
            {renderReactions}
          </div>
        </ActionsTooltip>
        {!messageWithoutHeader && (
          <div className="customMessage-header">
            <div className="customMessage-header-messageInfo">
              {isStreamTheme && !isMyMessage && (
                <div className="customMessage-header-messageInfo-avatar">
                  <CustomAvatar
                    image={member?.image_url || undefined}
                    name={userName}
                    color={color}
                    size={32}
                    member={member}
                  />
                  {restrictionIconSrc ? <img src={restrictionIconSrc} alt="img" className="restriction" /> : null}
                </div>
              )}
              <div className="customMessage-header-ellipsis">
                {user && <p className="customMessage-header-userName">{isMyMessage ? "You" : userName}</p>}
                <div className="customMessage-header-ellipsis-cont">
                  {channelGreeter && (
                    <span className="customMessage-header-ellipsis-cont-greeter">
                      {channelGreeter.title ? channelGreeter.title : ""}
                    </span>
                  )}
                  <p className="customMessage-header-date">{timeToAMPM(created_at)}</p>
                  {!isMyMessage && event && isModerator && member ? <MessageOptionMenu member={member} /> : null}
                </div>
              </div>
              {isStreamTheme && isMyMessage && (
                <div className="customMessage-header-messageInfo-avatar">
                  <CustomAvatar
                    image={member?.image_url || undefined}
                    name={userName}
                    color={color}
                    size={32}
                    member={member}
                  />
                  {restrictionIconSrc ? <img src={restrictionIconSrc} alt="img" className="restriction" /> : null}
                </div>
              )}
            </div>
          </div>
        )}
        <EventTooltip
          enterDelay={1000}
          enterNextDelay={1000}
          leaveDelay={100}
          title={tooltipText(created_at)}
          placement={isMyMessage ? "top-end" : "top-start"}
          classNames={classnames("customMessage-tooltip", { own: isMyMessage })}
        >
          <div
            ref={messageWrapperRef}
            className={classnames(
              "customMessage",
              { "customMessage-owner": isMyMessage },
              { "customMessage-mediaOnly": isOnlyMedia },
            )}
          >
            {type !== "error" ? (
              <div className="customMessage-text" dangerouslySetInnerHTML={{ __html: htmlWithEmojiSpans }} />
            ) : (
              <div className="error-wrapper">
                <div className="error-header">Only visible to you</div>
                <div className="error-content">
                  <img src={chat_error} alt="chat_error" />
                  {message.text}
                </div>
              </div>
            )}
            {showActions && (
              <div
                className={classnames("customMessage-actions-wrapper", {
                  event,
                  "short-message": message.text && message.text.length < 10,
                })}
              >
                <div>
                  <div className="customMessage-actions">
                    <img
                      id={`reaction-icon-${message.created_at}`}
                      onClick={() => {
                        setActionsAnchor(messageWrapperRef.current);
                      }}
                      className="customMessage-actions-reactions"
                      src={emoji_gray}
                      alt="reaction icon"
                    />
                    {(isMyMessage || canDelete) && (
                      <div className={classnames("customMessage-actions-divider left", { event })} />
                    )}
                    {isMyMessage && (
                      <img
                        onClick={handleEditClick}
                        className="customMessage-actions-edit"
                        src={edit_icon_gray}
                        alt="edit icon"
                      />
                    )}
                    {isMyMessage && canDelete && (
                      <div className={classnames("customMessage-actions-divider right", { event })} />
                    )}
                    {canDelete && (
                      <img
                        onClick={() => setDeleteDialogIsOpened(true)}
                        className="customMessage-actions-delete"
                        src={trash}
                        alt="trash"
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            <div>
              {latest_reactions && !!latest_reactions.length && reaction_counts && (
                <div
                  className={classnames("customMessage-reactionsList", {
                    owner: isMyMessage,
                    disabled: !showActions,
                  })}
                >
                  <CustomReactionsList
                    emojiConfig={defaultMinimalEmojis}
                    handleReaction={(type, e) => {
                      if (!showActions) return;
                      handleReaction && handleReaction(type, e);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </EventTooltip>
        {attachments && !!attachments.length && (
          <div className={classnames("customMessage-attachments", { "customMessage-attachments-owner": isMyMessage })}>
            {attachments.map((a: AttachmentType, index: number) => (
              <Attachment key={index} attachments={[a]} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(SimpleMessage);
