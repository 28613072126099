import { call, put, race, select, take, takeLatest, delay } from "redux-saga/effects";
import { AnyAction } from "redux";
import * as jwt from "jsonwebtoken";
import * as HttpStatus from "http-status-codes";

import { setOnboardingUser } from "../../Onboarding/store/actions";
import { forgotEmail, getToken, getUser, isMagicLinkSent } from "./selectors";
import { AuthShape, ForgotShape, IUser } from "..";
import { NameOfRoutes, NamesOfParentRoutes, LAST_FLOW_KEY } from "../../../constants";
import { startLoading, stopLoading } from "../../App/store/actions";
import { notificationActions } from "../../Notifications/store/actions";
import api from "../api";
import { tokenHandler } from "../../../shared/utils";
import history from "../../../shared/history/history";

import { actions, constants } from "./";

import { identifyVisitor } from "utils/hubspot";
import { ELocalStorage } from "shared/interfaces/Localstorage";
import { searchParamsToObject } from "shared/utils/searchParams";
import { IMember } from "containers/Member/interfaces";
import { trackLogInEvent } from "utils/fbPixel";
import { LastFlowEnum } from "shared/interfaces";
import {
  EFirebaseSignUpMethod,
  FIREBASE_EVENTS,
  FirebaseLoginPayload,
  FirebaseSignUpPayload,
} from "shared/interfaces/Firebase";
import { handleFirebaseEvent } from "utils/firebase";

const { SIGN_IN } = NameOfRoutes;
const { AUTH, CREATION } = NamesOfParentRoutes;

function* getUserDataSaga() {
  try {
    yield put(startLoading());
    const token = tokenHandler.get() || (yield select(getToken()));
    if (token) {
      const user: IUser & { hubspotVisitorToken?: string } = yield call(api.getUserInfo);
      if (user) {
        if (user.hubspotVisitorToken) {
          identifyVisitor(user.hubspotVisitorToken, user.email);
          delete user.hubspotVisitorToken;
        }
        yield put(actions.getUserData.success(user));
      } else {
        yield put(actions.logout.request({ reload: true }));
        yield put(actions.getUserData.failure());
      }
    } else {
      yield put(actions.logout.request({ reload: true }));
      yield put(actions.getUserData.failure());
    }
  } catch (error: any) {
    yield put(actions.getUserData.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* logoutSaga({ payload }: ReturnType<typeof actions.logout.request>) {
  yield tokenHandler.remove();
  yield put(actions.logout.success());
  const lastFlow = window.localStorage.getItem(LAST_FLOW_KEY);
  if (!payload || !payload.isSeatSamePage) {
    yield history.push(`${lastFlow === LastFlowEnum.community ? CREATION : AUTH}${SIGN_IN}`);
  }
  if (payload?.reload) {
    yield window.location.reload();
  }
}

function* registrationSaga({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const { id }: { id: number } = yield call(api.registration, payload);
    yield put(actions.registration.success(id));
  } catch (error: any) {
    yield put(notificationActions.error("Cannot register the user", (error && error.message) || ""));
    yield put(actions.registration.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* loginSaga({ payload: { email, password, redirectTo = "/", callback, skipActions } }: { payload: AuthShape }) {
  try {
    yield put(startLoading());
    const { token } = yield call(api.login, { email, password });
    yield put(setOnboardingUser(null));
    const decoded: any = jwt.decode(token);
    const subdomain = localStorage.getItem("subdomain_part");
    localStorage.removeItem(ELocalStorage.subscriptionAttentionClosed);
    if (decoded) {
      const { user } = decoded;
      if (user && user.is_active) {
        yield tokenHandler.set(token);
        const firebaseData: FirebaseLoginPayload = {
          method: EFirebaseSignUpMethod.email,
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          user_id: user.id,
        };
        handleFirebaseEvent(FIREBASE_EVENTS.LOGIN, firebaseData);
        trackLogInEvent(user);
        if (!skipActions) {
          yield put(actions.login.success());
          yield put(actions.getUserData.request());
        }
        if (redirectTo) {
          history.push(redirectTo);
        }
      } else {
        yield put(actions.login.success(user.id));
      }
    }
    if (subdomain) {
      localStorage.removeItem("subdomain_part");
      const url = window.location.href.replace("app", subdomain);
      window.location.replace(url + "?token=" + token);
    }
    yield callback && callback();
  } catch (e: any) {
    yield put(actions.login.failure(e));
  } finally {
    yield put(stopLoading());
  }
}

function* socialLoginSaga({ payload }: ReturnType<typeof actions.socialLogin.request>) {
  try {
    yield put(startLoading());
    if (!payload) {
      throw new Error("No data provided");
    }
    const { token, is_new } = yield call(api.socialLogin, payload);
    yield put(actions.setUserIsNew(is_new));
    const decoded: any = jwt.decode(token);
    const subdomain = localStorage.getItem("subdomain_part");
    localStorage.removeItem(ELocalStorage.subscriptionAttentionClosed);
    if (decoded) {
      const { user }: { user: IUser } = decoded;
      if (user && user.is_active) {
        yield tokenHandler.set(token);
        yield put(actions.login.success());
        yield put(actions.getUserData.request());

        // wait for user data or error
        yield race([take(actions.getUserData.success), take(actions.getUserData.failure)]);
        const firebaseDataLogin: FirebaseLoginPayload = {
          method: payload.provider === "google" ? EFirebaseSignUpMethod.google : EFirebaseSignUpMethod.facebook,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          user_id: user.id,
        };
        if (is_new) {
          const firebaseDataSignUp: FirebaseSignUpPayload = { ...firebaseDataLogin };
          const currentUser: IUser | null = yield select(getUser());
          const { community } = currentUser?.members[0] || {};
          if (community) {
            firebaseDataSignUp.community_id = community.id;
            firebaseDataSignUp.community_name = community.name;
            firebaseDataSignUp.is_admin = 0;
          }
          handleFirebaseEvent(FIREBASE_EVENTS.SIGN_UP, firebaseDataSignUp);
        }
        handleFirebaseEvent(FIREBASE_EVENTS.LOGIN, firebaseDataLogin);
        trackLogInEvent(user);
      } else {
        yield put(actions.login.success(user.id));
      }
    }
    if (subdomain) {
      localStorage.removeItem("subdomain_part");
      const url = window.location.href.replace("app", subdomain);
      window.location.replace(url + "?token=" + token);
    }
    yield payload.callback && payload.callback();
  } catch (error: any) {
    yield put(actions.setUserIsNew());
    yield put(notificationActions.error("Cannot login", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* forgotSaga({ payload }: { payload: ForgotShape }) {
  const { email, redirectTo, showNotification, communityCreation } = payload;
  try {
    yield put(startLoading());
    yield call(api.forgot, { email, redirectTo, communityCreation });
    const emailSent: string | null = yield select(forgotEmail());
    yield put(actions.setEmailResent(!!emailSent));
    yield put(actions.setForgotEmail(payload.email));
    yield put(actions.setError(null));
  } catch (e: any) {
    yield put(showNotification ? notificationActions.error(e.message) : actions.forgot.failure(e));
  } finally {
    yield put(stopLoading());
  }
}

function* resetSaga({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const { redirectTo, ...rest } = payload;
    const { token } = yield call(api.reset, rest);
    yield tokenHandler.set(token);
    const decoded: any = jwt.decode(token);
    if (decoded) {
      const { user } = decoded;
      const firebaseData: FirebaseLoginPayload = {
        method: EFirebaseSignUpMethod.email,
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
        user_id: user.id,
      };
      handleFirebaseEvent(FIREBASE_EVENTS.LOGIN, firebaseData);
      trackLogInEvent(user);
    }
    yield put(actions.login.success());
    yield put(actions.getUserData.request());
    if (!redirectTo) {
      history.push("/");
    } else {
      history.push(redirectTo);
    }
  } catch (e: any) {
    yield put(actions.reset.failure(e));
  } finally {
    yield put(stopLoading());
  }
}

function* confirmVerifyUserSaga({
  payload: { hash, code, redirectTo, callback },
}: ReturnType<typeof actions.confirmVerifyUser.request>) {
  try {
    yield put(startLoading());
    const { token } = hash
      ? yield call(api.confirmVerifyUser, { token: hash })
      : code
      ? yield call(api.confirmVerifyUserByCode, { code })
      : { token: "" };
    if (token) {
      yield tokenHandler.set(token);
      yield put(actions.login.success());
      yield put(actions.getUserData.request());

      // wait for user data or error
      yield race([take(actions.getUserData.success), take(actions.getUserData.failure)]);
      const decoded: any = jwt.decode(token);
      const { user }: { user: IUser } = decoded;
      const firebaseDataLogin: FirebaseLoginPayload = {
        method: EFirebaseSignUpMethod.email,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        user_id: user.id,
      };
      const firebaseDataSignUp: FirebaseSignUpPayload = { ...firebaseDataLogin };
      const currentUser: IUser | null = yield select(getUser());
      const member = currentUser?.members[0];
      const { community } = member || {};
      if (community) {
        firebaseDataSignUp.community_id = community.id;
        firebaseDataSignUp.community_name = community.name;
        firebaseDataSignUp.is_admin = member?.should_create_as_manager ? 1 : 0;
      }

      put(actions.confirmVerifyUser.success());

      handleFirebaseEvent(FIREBASE_EVENTS.SIGN_UP, firebaseDataSignUp);
      handleFirebaseEvent(FIREBASE_EVENTS.LOGIN, firebaseDataLogin);
      trackLogInEvent(user);
      if (code) {
        callback?.();
      } else {
        yield history.push(redirectTo || "/");
      }
    }
  } catch (e: any) {
    if (e.code === HttpStatus.NOT_FOUND) {
      if (code) {
        yield put(actions.confirmVerifyUser.failure(e.message));
      } else {
        yield put(
          notificationActions.error("Confirmation link has expired", "The confirmation link has expired please login"),
        );
      }
    } else {
      yield put(actions.login.failure(e));
    }
  } finally {
    yield put(stopLoading());
  }
}

function* getMemberSaga({ payload }: ReturnType<typeof actions.getMember.request>) {
  try {
    const member: IMember = yield call(api.getMember, payload.community_id, payload.kind);
    yield put(actions.getMember.success(member));
  } catch (error: any) {
    const { subdomain_part, provider } = searchParamsToObject(history.location.search);
    if (!subdomain_part && !provider) {
      yield put(notificationActions.error("Cannot get user discussions", (error && error.message) || ""));
    }
  }
}

export function* sendMagicLinkSaga({ payload }: ReturnType<typeof actions.sendMagicLink.request>) {
  try {
    yield put(startLoading());
    yield call(api.sendMagicLink, payload);
    const emailSent: boolean = yield select(isMagicLinkSent());
    yield put(actions.setEmailResent(emailSent));
    yield put(actions.sendMagicLink.success());
  } catch (e: any) {
    yield put(actions.sendMagicLink.failure());
    yield put(notificationActions.error("Cannot send Magic link"));
  } finally {
    yield put(stopLoading());
  }
}

export function* magicLoginSaga({ payload }: ReturnType<typeof actions.magicLogin>) {
  try {
    yield put(startLoading());
    const { token } = yield call(api.magicLogin, { hash: payload.hash });
    yield tokenHandler.set(token);
    localStorage.removeItem(ELocalStorage.subscriptionAttentionClosed);
    const decoded: any = jwt.decode(token);
    if (decoded) {
      const { user } = decoded;
      const firebaseData: FirebaseLoginPayload = {
        method: EFirebaseSignUpMethod.magicLink,
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
        user_id: user.id,
      };
      handleFirebaseEvent(FIREBASE_EVENTS.LOGIN, firebaseData);
      trackLogInEvent(user);
    }
    yield put(actions.login.success());
    yield put(actions.getUserData.request());
    yield history.push(payload.redirectTo || "/");
  } catch (e: any) {
    yield put(
      notificationActions.error(
        "Cannot login",
        "Oops, looks like the Magic Link has expired. Please send yourself another one",
      ),
    );
  } finally {
    yield put(stopLoading());
  }
}

export function* getTokenByHashSaga({ payload }: ReturnType<typeof actions.getTokenByHash>) {
  try {
    let token = "";
    while (!token) {
      const response: { token: string | null } = yield call(api.getTokenByHash, payload);
      if (response.token) {
        token = response.token;
      }
      yield delay(1500);
    }

    tokenHandler.set(token);
    yield put(actions.login.success());
    yield put(actions.getUserData.request());
  } catch (e) {
    // skip errors
  }
}

function* authSagas() {
  yield takeLatest(actions.login.request, loginSaga);
  yield takeLatest(actions.socialLogin.request, socialLoginSaga);
  yield takeLatest(constants.AuthActionTypes.MAGIC_LOGIN, magicLoginSaga);
  yield takeLatest(constants.AuthActionTypes.LOGOUT, logoutSaga);
  yield takeLatest(constants.AuthActionTypes.CHECK_USER, getUserDataSaga);
  yield takeLatest(constants.AuthActionTypes.REGISTRATION, registrationSaga);
  yield takeLatest(actions.forgot.request, forgotSaga);
  yield takeLatest(constants.AuthActionTypes.RESET, resetSaga);
  yield takeLatest(constants.AuthActionTypes.CONFIRM_VERIFY_USER, confirmVerifyUserSaga);
  yield takeLatest(actions.getMember.request, getMemberSaga);
  yield takeLatest(constants.AuthActionTypes.SEND_MAGIC_LINK_REQUEST, sendMagicLinkSaga);
  yield takeLatest(constants.AuthActionTypes.GET_TOKEN_BY_HASH, getTokenByHashSaga);
}

export default authSagas;
