import React from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { ELocalStorage } from "../../interfaces/Localstorage";
import { EStripeResult } from "../../interfaces/Stripe";

import { goToStripeAccount, setSubscriptionAttention } from "containers/Community/store/actions";
import { getCommunity } from "containers/Community/store/selectors";
import attention from "assets/icons/attention.svg";
import cross_icon_white from "assets/icons/cross_icon_white.svg";

import "./subscriptionAttentionBanner.scss";

const SubscriptionAttentionBanner: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const community = useSelector(getCommunity());

  const cancelClickHandler = () => {
    if (community) {
      dispatch(setSubscriptionAttention(false));
      const existAttentionClosed = JSON.parse(localStorage.getItem(ELocalStorage.subscriptionAttentionClosed) || "[]");
      localStorage.setItem(
        ELocalStorage.subscriptionAttentionClosed,
        JSON.stringify([...existAttentionClosed, community.id]),
      );
    }
  };

  const goToStripeAccountHandler = () => {
    if (community) {
      dispatch(
        goToStripeAccount({
          communityCode: community.code,
          returnPath: `${pathname}?stripeSuccess=${EStripeResult.billing}`,
        }),
      );
    }
  };

  return (
    <div className="attentionBanner-wrapper">
      <div className="attentionBanner">
        <div className="attentionBanner-info">
          <img src={attention} alt="attention" />
          <div className="attentionBanner-info-title">Attention!</div>
          <div className="attentionBanner-info-description">There was an issue with your payment method</div>
        </div>
        <div className="attentionBanner-actions">
          <div className="attentionBanner-actions-billing" onClick={goToStripeAccountHandler}>
            Manage Account Billing Details
          </div>
          <div className="attentionBanner-actions-close" onClick={cancelClickHandler}>
            <img src={cross_icon_white} alt="close" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAttentionBanner;
