const entriesFromPolyfill = (entries: IterableIterator<[string, string]>) => {
  const obj: { [k: string]: string } = {};
  // @ts-ignore
  for (const [key, value] of entries) {
    obj[key] = value;
  }
  return obj;
};

export const searchParamsToObject = (querystring: string) => {
  const params: URLSearchParams = new URLSearchParams(querystring);
  return Object.fromEntries?.(params.entries()) || entriesFromPolyfill(params.entries());
};

export const objectToSearchParams = (params: { [k: string]: string }) => {
  const searchParams = new URLSearchParams(params).toString();
  return searchParams ? `?${searchParams}` : "";
};

export const generateQueryStringFromArray = (arr: (number | string)[], key: string) => {
  return arr.reduce((queryStr: string, item, idx) => queryStr + `${idx ? "&" : ""}${key}=${item}`, "?");
};
