export const MediaConstraints = {
  video: {
    width: {
      min: 300,
      max: 480,
    },
    height: {
      min: 200,
      max: 360,
    },
    frameRate: {
      max: 30,
    },
  },
  audio: {
    echoCancellation: true,
  },
};
