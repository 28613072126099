import React, { PropsWithChildren } from "react";

import "./styles.scss";

type Props = {
  onClose?: () => void;
};

const TopNotification: React.FC<PropsWithChildren<Props>> = ({ children, onClose }) => {
  return (
    <div className="top-notification">
      <div className="top-notification-left" />
      <div className="top-notification-center">
        <div className="top-notification-center-content">{children}</div>
        {onClose && <div className="top-notification-center-close" onClick={onClose} />}
      </div>
      <div className="top-notification-right" />
    </div>
  );
};
export default TopNotification;
