import React, { useEffect, useState } from "react";
import classnames from "classnames";
import moment from "moment";

import { IMember } from "../../../interfaces";
import EntityMainInfo from "../../../../../shared/components/EntityMainInfo";

import { getInitials, SquareButton } from "shared";

import "./styles.scss";

type Props = {
  member: IMember;
  invitedAt: string | null;
  showExtraData: boolean;
  sendPrivateInvitation?: () => void;
};

const MemberDialogView: React.FC<Props> = ({ member, invitedAt, showExtraData, sendPrivateInvitation }) => {
  const [profileImageError, setProfileImageError] = useState(false);

  useEffect(() => {
    setProfileImageError(false);
  }, [member]);

  return (
    <div className="member-dialog-view">
      <EntityMainInfo>
        <div className={classnames("avatar", { empty: !member.image_url || profileImageError })}>
          {member.image_url && !profileImageError ? (
            <img
              src={member.image_url}
              alt={`${member.first_name} ${member.last_name}`}
              onError={() => setProfileImageError(true)}
            />
          ) : (
            getInitials(member)
          )}
        </div>
        <div className="item">{`${member.first_name} ${member.last_name}`}</div>
        {showExtraData && (
          <>
            <div className="item">{`${member.email}`}</div>
            {member.phone_number && <div className="item">{`${member.phone_number}`}</div>}
          </>
        )}
      </EntityMainInfo>
      {showExtraData && member.should_create_as_manager && (
        <div className="member-dialog-view-admin">
          <p className="sub-title">This Member has Admin Access</p>
          <p className="grey-text">
            Admins can create, edit, and delete all public and private events and meetings, as well as manage members
            and permissions.
          </p>
        </div>
      )}
      {showExtraData && sendPrivateInvitation && (
        <div className="member-dialog-view-status">
          <p className="grey-text full-width">
            <span>Status</span>
            <span>{invitedAt ? `Invited ${moment(invitedAt).format("ll")}` : ""}</span>
          </p>
          <div className="full-width">
            <div className="sub-title">
              {member.has_accepted_invitation ? "Accepted" : invitedAt ? "Pending" : "Not Invited"}
            </div>
            {!member.has_accepted_invitation && (
              <SquareButton
                type="button"
                variant="blue-text"
                width={invitedAt ? 104 : 55}
                className="save"
                onClick={sendPrivateInvitation}
              >
                {`Invite${invitedAt ? " again" : ""}`}
              </SquareButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberDialogView;
