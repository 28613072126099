import config from "../config";

export const init = () => {
  if (config.rewardfulAppId) {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://r.wdfl.co/rw.js";
    s.setAttribute("data-rewardful", config.rewardfulAppId);
    document.head.append(s);
  }
};

// @ts-ignore
export const getReferralId = (): string | undefined => window.Rewardful?.referral;
