import React from "react";
import { useSnackbar } from "notistack";
import "./notistackActionButton.scss";

interface INotificationActionProps {
  snackKey: number | string | undefined;
}

const NotistackActionButton: React.FunctionComponent<INotificationActionProps> = ({ snackKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <div className="notificationActionButton" onClick={() => closeSnackbar(snackKey)}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.70709 0.29288C1.31656 -0.097637 0.683397 -0.0976251 0.29288 0.292907C-0.097637 0.683438 -0.0976251 1.3166 0.292907 1.70712L4.58619 6.00024L0.293214 10.2931C-0.0973177 10.6836 -0.0973301 11.3167 0.293187 11.7073C0.683704 12.0978 1.31687 12.0978 1.7074 11.7073L6.00064 7.41421L10.2939 11.7073C10.6844 12.0978 11.3176 12.0978 11.7081 11.7073C12.0986 11.3167 12.0986 10.6836 11.7081 10.2931L7.41479 5.99993L11.7078 1.70712C12.0983 1.3166 12.0983 0.683438 11.7078 0.292907C11.3173 -0.0976251 10.6841 -0.097637 10.2936 0.29288L6.00033 4.58596L1.70709 0.29288Z"
          fill="rgba(255, 255, 255, 0.5)"
        />
      </svg>
    </div>
  );
};

export default NotistackActionButton;
