import produce from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { BreakOutRoomScreenTypes, BreakoutRoomsStateType } from "../interfaces";
import * as actions from "./actions";

export const initialState = {
  isConnecting: false,
  isEnded: false,
  userMediaError: null,
  error: null,
  roomCode: null,
  stream: null,
  streams: [],
  rooms: [],
  volumeChanged: false,
  isImutted: false,
  isVideoDisabled: false,
  userDevices: null,
  mediaPermissions: null,
  currentBreakOutRoomScreen: BreakOutRoomScreenTypes.tables,
};

type Action = ActionType<typeof actions>;

const reducer = createReducer<BreakoutRoomsStateType, Action>(initialState)
  .handleAction(actions.setRoomCode, (state, action) =>
    produce(state, nextState => {
      nextState.roomCode = action.payload;
    }),
  )
  .handleAction(actions.getUserMedia.success, (state, action) =>
    produce(state, nextState => {
      nextState.stream = action.payload;
      nextState.userMediaError = null;
    }),
  )
  .handleAction(actions.connectSocket.success, (state, action) =>
    produce(state, nextState => {
      nextState.roomCode = action.payload;
      nextState.isConnecting = true;
      nextState.isEnded = false;
    }),
  )
  .handleAction(actions.exitRoom.success, state =>
    produce(state, (nextState = initialState) => {
      nextState.isEnded = true;
      nextState.isConnecting = false;
      nextState.roomCode = null;
      nextState.stream = null;
      nextState.streams = [];
      nextState.isImutted = false;
      nextState.userMediaError = null;
    }),
  )
  .handleAction(actions.joinRoom, state =>
    produce(state, (nextState = initialState) => {
      nextState.isEnded = false;
    }),
  )
  .handleAction(actions.getUserMedia.failure, (state, action) =>
    produce(state, nextState => {
      nextState.stream = null;
      nextState.userMediaError = action.payload;
    }),
  )
  .handleAction(actions.connectSocket.failure, (state, action) =>
    produce(state, nextState => {
      nextState.error = action.payload;
    }),
  )
  .handleAction(actions.exitRoom.failure, (state, action) =>
    produce(state, nextState => {
      nextState.error = action.payload;
    }),
  )
  .handleAction(actions.addStream, (state, action) =>
    produce(state, nextState => {
      nextState.streams = state.streams.find(({ clientId }) => clientId === action.payload.clientId)
        ? state.streams
        : [...state.streams, action.payload];
    }),
  )
  .handleAction(actions.streamConnectionIssue, (state, action) =>
    produce(state, nextState => {
      const currentStreamIndex = state.streams.findIndex(({ clientId }) => clientId === action.payload.clientId);
      if (currentStreamIndex !== -1) {
        nextState.streams[currentStreamIndex].hasConnectionIssue = action.payload.hasConnectionIssue;
      } else {
        nextState.streams = state.streams;
      }
    }),
  )
  .handleAction(actions.handleConnectionIssue, (state, action) =>
    produce(state, nextState => {
      const currentStreamIndex = state.streams.findIndex(({ member }) => member.id === action.payload.member.id);
      if (currentStreamIndex !== -1) {
        nextState.streams[currentStreamIndex] = action.payload;
      } else {
        nextState.streams = state.streams;
      }
    }),
  )
  .handleAction(actions.updateRooms.success, (state, action) =>
    produce(state, nextState => {
      nextState.rooms = action.payload;
    }),
  )
  .handleAction(actions.muteMe, (state, action) =>
    produce(state, nextState => {
      nextState.isImutted = action.payload;
    }),
  )
  .handleAction(actions.disableMyVideo, (state, action) =>
    produce(state, nextState => {
      nextState.isVideoDisabled = action.payload;
    }),
  )
  .handleAction(actions.removeStream, (state, action) =>
    produce(state, nextState => {
      nextState.streams = state.streams.filter(({ clientId }) => clientId !== action.payload);
    }),
  )
  .handleAction(actions.handleFriendMuted, (state, action) =>
    produce(state, nextState => {
      const current_stream_index = state.streams.findIndex(s => s.clientId === action.payload.from_id);
      if (current_stream_index !== -1) {
        if (state.streams[current_stream_index].isMutted !== action.payload.isMutted) {
          nextState.streams[current_stream_index].isMutted = action.payload.isMutted;
        }
      }
    }),
  )
  .handleAction(actions.handleFriendVideo, (state, action) =>
    produce(state, nextState => {
      const current_stream_index = state.streams.findIndex(s => s.clientId === action.payload.from_id);
      if (current_stream_index !== -1) {
        if (state.streams[current_stream_index].isVideoDisabled !== action.payload.isVideoDisabled) {
          nextState.streams[current_stream_index].isVideoDisabled = action.payload.isVideoDisabled;
        }
      }
    }),
  )
  .handleAction(actions.getUserMediaDevices.success, (state, action) =>
    produce(state, nextState => {
      nextState.userDevices = action.payload;
    }),
  )
  .handleAction(actions.updateStreamSettings.success, (state, action) =>
    produce(state, nextState => {
      if (state.stream) {
        state?.stream?.getTracks()?.forEach((track: MediaStreamTrack) => {
          track.stop();
        });
      }
      nextState.stream = action.payload;
    }),
  )
  .handleAction(actions.checkUserMediaPermissions.success, (state, action) =>
    produce(state, nextState => {
      nextState.mediaPermissions = action.payload;
    }),
  )
  .handleAction(actions.checkUserMediaPermissions.failure, state =>
    produce(state, nextState => {
      nextState.mediaPermissions = null;
    }),
  )
  .handleAction(actions.changeCurrentBreakoutRoomScreen, (state, action) =>
    produce(state, nextState => {
      nextState.currentBreakOutRoomScreen = action.payload;
    }),
  )
  .handleAction(actions.resetState, state =>
    produce(state, nextState => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      nextState = initialState;
    }),
  );

export default reducer;
