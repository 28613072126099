import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StreamChat } from "stream-chat";

import config from "../../../config";
import * as actions from "../../../containers/Auth/store/actions";
import * as communityActions from "../../../containers/Community/store/actions";
import * as memberActions from "../../../containers/Member/store/actions";
import { authentificated, getMember, getOnboardingData } from "../../../containers/Auth/store/selectors";
import { getCommunity } from "../../../containers/Community/store/selectors";
import { getChatToken } from "../../../containers/Member/store/selectors";
import { useMemberCID } from "../MemberCIDHook";
import { useCustomName } from "../CustomName/CustomName";
import { ECreationKind } from "../../interfaces";

import { getEvent } from "containers/Sermons/store/selectors";

export const GetStreamContext = React.createContext<StreamChat | undefined>(undefined);

const GetStreamProvider: React.FunctionComponent = ({ children }) => {
  const currentMember = useSelector(getMember());
  const isAuthenticated = useSelector(authentificated());
  const community = useSelector(getCommunity());
  const chatToken = useSelector(getChatToken());
  const event = useSelector(getEvent());
  const memberCID = useMemberCID();
  const onboardingData = useSelector(getOnboardingData());

  const customName = useCustomName();

  const dispatch = useDispatch();
  const [chatClient, setChatClient] = useState<StreamChat | undefined>();
  const [discussionToken, setDiscussionsToken] = useState<string>();

  const disconnectChatClient = useCallback(async () => {
    if (chatClient) {
      setChatClient(undefined);
      await chatClient.disconnectUser();
    }
  }, [chatClient]);

  const connectChatClient = useCallback(
    async (memberId: number | string) => {
      try {
        if (!discussionToken) {
          return;
        }
        const userDetails = {
          id: memberId.toString(),
        };

        const newChatClient = StreamChat.getInstance(config.getStreamToken);
        await newChatClient.connectUser(userDetails, discussionToken);

        setChatClient(newChatClient);
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [discussionToken],
  );

  const connectGetStream = useCallback(async () => {
    try {
      if (!discussionToken) {
        return;
      }
      const token = await chatClient?.tokenManager.getToken();
      const isAlreadyConnected = token === discussionToken;
      if (isAlreadyConnected) {
        return;
      }
      await disconnectChatClient();

      if (currentMember && currentMember.id) {
        await connectChatClient(currentMember.id);
      } else if (memberCID) {
        await connectChatClient(memberCID);
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [memberCID, currentMember, discussionToken, chatClient, disconnectChatClient, connectChatClient]);

  useEffect(() => {
    setDiscussionsToken(currentMember?.chat_token || chatToken);
  }, [currentMember, chatToken]);

  useEffect(() => {
    if (!community) {
      return;
    }
    if (currentMember && !currentMember.chat_token) {
      dispatch(memberActions.fetchChatToken.request(community.id));
      return;
    }
    if (memberCID && !isAuthenticated && !discussionToken) {
      dispatch(
        memberActions.fetchAnonymousChatToken.request({
          id: memberCID,
          name: customName,
        }),
      );
    }
  }, [memberCID, dispatch, currentMember, community, discussionToken, customName, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && community && !onboardingData) {
      dispatch(
        actions.getMember.request({
          community_id: community?.id,
          kind: event?.id ? ECreationKind.event : ECreationKind.community,
        }),
      );
    }
  }, [dispatch, isAuthenticated, community, onboardingData, event?.id]);

  useEffect(() => {
    if (!!event && !community && !currentMember) {
      const { community } = event;
      community && community?.code && dispatch(communityActions.getCommunity.request(community.code));
    }
  }, [event, community, currentMember, dispatch]);

  useEffect(() => {
    connectGetStream();
  }, [connectGetStream]);

  return <GetStreamContext.Provider value={chatClient}>{children}</GetStreamContext.Provider>;
};

export default GetStreamProvider;
