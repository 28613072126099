import {
  actionChannel,
  apply,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
  throttle,
  all,
} from "redux-saga/effects";
import { AnyAction } from "redux";
import { capitalize } from "lodash";
import * as HttpStatus from "http-status-codes";
import { Socket } from "socket.io-client";
import { TakeableChannel } from "@redux-saga/core";
import { EventChannel } from "redux-saga";

import {
  fetchBlobByB64,
  playNotificationAudio,
  replaceSlidesBlobsUrlsWithUploadedFilesUrls,
  validateEventChangingStatus,
} from "./sagaHelpers";
import {
  AnnouncementType,
  ClassRoomSettings,
  EventStatus,
  EventType,
  IAnnouncement,
  IAnnouncementTemplate,
  ICheckSlotQuery,
  ICreateAnnouncementShape,
  IEvent,
  IEventIntegrations,
  IEventPreview,
  IFetchAnnouncementsShape,
  IFetchFileUrl,
  IMeetingSignature,
  IPoll,
  IPollReadResponse,
  IPollTemplate,
  IReadAnnouncementsShape,
  IUpdateAnnouncementShape,
  IWaitingToJoinMember,
  Notifications,
  PollStatus,
} from "../interfaces";
import { startLoading, stopLoading } from "../../App/store/actions";
import { enableNotification, enablePopup, notificationActions } from "../../Notifications/store/actions";
import { SermonActionTypes } from "./constants";
import { api } from "../api";
import { fetchRestrictedMembers } from "../../Member/store/actions";

import { actions } from "./index";

import { logout } from "containers/Auth/store/actions";
import { AltarStore } from "shared/types";
import { handleFirebaseCreateEvent, handleFirebaseJoinEvent } from "utils/firebase";
import { getHasPaidSubscriptions } from "containers/Community/store/selectors";
import { getChatNotificationSettings } from "containers/Notifications/store/selectors";
import { IChatNotificationSettings } from "containers/Notifications/interfaces";
import history from "shared/history/history";
import { createSocket, createSocketChannel } from "shared/utils/socket";
import { setEventClassrooms, setFullClassroom, setPreJoinPage } from "containers/Meeting/store/actions";
import { MeetingActionTypes } from "containers/Meeting/store/constants";
import {
  createMultipleSermons,
  createSermon,
  setKnockRejected,
  updateEventSettings,
} from "containers/Sermons/store/actions";
import { EventDashboardTabs } from "containers/Sermons/interfaces";

function* fetchSermonsList({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const { community_id, ...rest } = payload;
    const events: IEventPreview[] = yield call(api.fetchSermonsList, community_id, rest);
    yield put(actions.fetchSermonsList.success(events));
  } catch (error: any) {
    yield put(notificationActions.error("Cannot fetch events", (error && error.message) || ""));
    yield put(actions.fetchSermonsList.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* fetchSermon({ payload }: { payload: { code: string; relations?: string[]; customErrorHandling?: boolean } }) {
  try {
    yield put(startLoading());
    const event: IEvent = yield call(api.fetchSermon, payload.code, payload.relations);
    yield put(actions.fetchSermon.success(event));
  } catch (error: any) {
    if (payload.customErrorHandling) {
      yield put(actions.fetchSermon.failure(error));
    } else {
      yield put(notificationActions.error("Cannot fetch events", (error && error.message) || ""));
      if (error.code === HttpStatus.FORBIDDEN) {
        yield put(logout.request({ reload: true }));
      }
    }
  } finally {
    yield put(stopLoading());
  }
}

function* createEvent({
  payload: {
    data,
    callback,
    options = {
      action: Notifications.created,
      type: Notifications.liveService,
    },
    silently = false,
  },
}: ReturnType<typeof createSermon.request>) {
  let onSuccess;
  const hasPaidSubscription: boolean = yield select(getHasPaidSubscriptions());

  try {
    yield put(startLoading());

    const newData = { ...data };
    if (data.slides?.length) {
      const { slides, cleanUp } = yield call(
        replaceSlidesBlobsUrlsWithUploadedFilesUrls,
        data.communityId,
        data.slides,
      );
      newData.slides = slides;
      onSuccess = cleanUp;
    }

    const newEvent: IEvent = yield call(api.createSermon, newData);
    yield put(actions.createSermon.success(newEvent));
    if (!silently) {
      yield put(
        notificationActions.success(
          `Successfully ${options.action}`,
          `The ${options.type} was successfully ${options.action}`,
        ),
      );
    }
    handleFirebaseCreateEvent(newEvent, hasPaidSubscription);

    callback && typeof callback === "function" && callback(newEvent);
  } catch (error: any) {
    yield put(notificationActions.error(`Cannot create ${options.type}`, (error && error.message) || ""));
  } finally {
    typeof onSuccess === "function" && onSuccess();
    yield put(stopLoading());
  }
}

function* createMultipleEvents({ payload }: ReturnType<typeof createMultipleSermons>) {
  const { names, callback, ...rest } = payload;
  yield all(
    names.map(name => {
      return call(createEvent, { payload: { ...rest, data: { ...rest.data, name } } } as ReturnType<
        typeof createSermon.request
      >);
    }),
  );
  if (callback && typeof callback === "function") {
    callback();
  }
}

function* updateSermon({
  payload: {
    data,
    callback,
    options = {
      action: Notifications.updated,
      type: Notifications.liveService,
    },
    changeStatusOptions,
  },
}: AnyAction) {
  let onSuccess;
  const hasPaidSubscription: boolean = yield select(getHasPaidSubscriptions());
  try {
    yield put(startLoading());
    const { id, scope, ...rest } = data;

    const newData = { ...rest };
    if (data.slides?.length) {
      const { slides, cleanUp } = yield call(
        replaceSlidesBlobsUrlsWithUploadedFilesUrls,
        data.communityId,
        data.slides,
      );
      newData.slides = slides;
      onSuccess = cleanUp;
    }

    const updatedEvent: IEvent = yield call(api.updateSermon, id, { ...newData, changeStatusOptions }, scope);
    yield put(actions.updateSermon.success(updatedEvent));
    if (callback && typeof callback === "function") {
      yield put(
        notificationActions.success(
          `Successfully ${options.action}`,
          `The ${options.type} was successfully ${options.action}`,
        ),
      );
      handleFirebaseCreateEvent(updatedEvent, hasPaidSubscription);
      yield call(callback);
    }
  } catch (error: any) {
    yield put(notificationActions.error(`Cannot update ${options.type}`, (error && error.message) || ""));
  } finally {
    typeof onSuccess === "function" && onSuccess();
    yield put(stopLoading());
  }
}

function* fetchFileUrl({ payload }: { payload: IFetchFileUrl }) {
  try {
    yield put(startLoading());
    const { community_id, image_base64, callback } = payload;
    const { blob, blobUrl } = yield call(fetchBlobByB64, image_base64);
    const data = new FormData();
    data.append("files[]", blob, btoa(blobUrl));
    const blobUrlToUrlMap: { [i: string]: string } = yield call(api.uploadMeetingSlidesFiles, community_id, data);
    const fileUrl = blobUrlToUrlMap[btoa(blobUrl)];
    if (fileUrl) yield call(callback, fileUrl);
  } catch (error: any) {
    yield put(notificationActions.error("Cannot upload image", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* startEvent({ payload: id }: AnyAction) {
  try {
    yield put(startLoading());
    yield call(api.startEvent, id);
    yield put(actions.startEvent.success());
  } catch (error: any) {
    yield put(notificationActions.error("Cannot start event", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* removeEvent({
  payload: { eventId, scope, callback, options = { type: Notifications.liveService } },
}: AnyAction) {
  try {
    yield put(startLoading());
    yield call(api.removeSermon, eventId, scope);
    yield put(actions.removeEvent.success(eventId));
    yield put(notificationActions.success(` ${capitalize(options.type)} deleted`, `The ${options.type} was deleted`));
    if (callback && typeof callback === "function") yield call(callback);
  } catch (error: any) {
    const message =
      error.code === HttpStatus.BAD_REQUEST
        ? "Please end the event on the Producer tablet first."
        : (error && error.message) || "";
    yield put(notificationActions.error(`Sorry, you cannot delete a ${options.type}.`, message));
  } finally {
    yield put(stopLoading());
  }
}

function* duplicateEvent({ payload: { eventId, scope, callback } }: AnyAction) {
  try {
    yield put(startLoading());
    yield call(api.duplicateSermon, { id: eventId, scope });
    yield put(actions.duplicateEvent.success(eventId));
    if (callback && typeof callback === "function") yield call(callback);
  } catch (error: any) {
    const message = error?.message || "";
    yield put(notificationActions.error(`Sorry, you cannot create a duplicate event.`, message));
  } finally {
    yield put(stopLoading());
  }
}

interface IWSAction {
  method: string;
  args: any[];
}

function* startSocket({ payload }: AnyAction): Generator {
  const { eventId, callback } = payload;
  let socket: Socket;
  let socketChannel: EventChannel<any>;
  try {
    const outgoingChannel = (yield actionChannel([
      SermonActionTypes.SEND_SERMON_EVENT,
      SermonActionTypes.WS_SEND_REACTION,
      SermonActionTypes.WS_CONNECT_TO_EVENT,
      SermonActionTypes.CLOSE_SOCKET,
      SermonActionTypes.WS_REMOVE_MEMBER_FROM_EVENT,
      SermonActionTypes.WS_LEAVE_EVENT,
      SermonActionTypes.KNOCK_JOIN_MEETING_REQUEST,
      SermonActionTypes.JOIN_MEETING_KNOCK_RESPONSE,
      MeetingActionTypes.JOIN_TO_CLASSROOM,
      MeetingActionTypes.LEAVE_CLASSROOM,
      SermonActionTypes.WS_UPDATE_MEMBER,
    ])) as TakeableChannel<any>;
    yield fork(createSocket, eventId, actions);
    const socketAction = (yield take([
      SermonActionTypes.CLOSE_SOCKET,
      SermonActionTypes.SOCKET_INITIALIZED,
      SermonActionTypes.SOCKET_INITIALIZED_FAILURE,
    ])) as AnyAction;
    if (socketAction.type === SermonActionTypes.SOCKET_INITIALIZED_FAILURE) {
      throw socketAction.payload;
    }
    if (socketAction.type === SermonActionTypes.CLOSE_SOCKET) {
      const socketInitializedAction = (yield take(SermonActionTypes.SOCKET_INITIALIZED)) as AnyAction;
      return socketInitializedAction.payload.close();
    } else {
      socket = socketAction.payload;
    }
    yield put(actions.connectSocket());
    socketChannel = (yield call(createSocketChannel, socket)) as EventChannel<any>;
    callback && callback();

    while (true) {
      const [outgoing, income] = (yield race([take(outgoingChannel), take(socketChannel)])) as [AnyAction, IWSAction];

      const { auth, sermons } = (yield select()) as AltarStore;

      if (outgoing) {
        const { type, payload } = outgoing;
        const { method, ...args } = payload || {};

        switch (type) {
          case MeetingActionTypes.LEAVE_CLASSROOM: {
            yield apply(socket, socket.send, [method, {}]);
            break;
          }
          case MeetingActionTypes.JOIN_TO_CLASSROOM: {
            yield apply(socket, socket.send, [method, args]);
            break;
          }
          case SermonActionTypes.SEND_SERMON_EVENT: {
            yield apply(socket, socket.send, [method, args]);
            break;
          }
          case SermonActionTypes.WS_UPDATE_MEMBER: {
            yield apply(socket, socket.send, [method, args]);
            break;
          }
          case SermonActionTypes.WS_SEND_REACTION:
          case SermonActionTypes.WS_REMOVE_MEMBER_FROM_EVENT:
          case SermonActionTypes.WS_LEAVE_EVENT: {
            yield apply(socket, socket.send, [method, args]);
            break;
          }
          case SermonActionTypes.WS_CONNECT_TO_EVENT: {
            const { user } = auth;
            const { sermon } = sermons;
            if (sermon) {
              handleFirebaseJoinEvent(user, sermon, args?.guestName);
            }
            yield apply(socket, socket.send, [method, args]);
            break;
          }
          case SermonActionTypes.CLOSE_SOCKET: {
            socketChannel.close();
            break;
          }
          case SermonActionTypes.KNOCK_JOIN_MEETING_REQUEST: {
            yield apply(socket, socket.send, [method, args]);
            break;
          }
          case SermonActionTypes.JOIN_MEETING_KNOCK_RESPONSE: {
            yield apply(socket, socket.send, [method, args]);
            break;
          }
        }
      } else if (income) {
        const { method, args } = income;
        switch (method) {
          case "mapping": {
            yield put(actions.setMembersMap(args[0]));
            break;
          }
          case "spotlight": {
            yield put(actions.setMeetingSpotlight(+args[0]));
            break;
          }
          case "mute": {
            yield put(actions.setMeetingMute(args[0]));
            break;
          }
          case "unmuted": {
            yield put(actions.setMeetingUnmuted(args[0]));
            break;
          }
          case "eventUpdated": {
            const { changeStatusOptions, ...eventData } = args[0];
            const { sermon, changingStatus } = sermons;
            if (sermon?.type === EventType.stream || sermon?.type === EventType.meeting) {
              if (eventData.status === EventStatus.ended) {
                yield put(actions.setAllowEnded(true));
              }
              if (
                sermon?.was_started === eventData?.was_started &&
                (validateEventChangingStatus({ from: sermon.status, to: eventData.status }) || changeStatusOptions) &&
                !changingStatus
              ) {
                yield put(actions.setChangingStatus(true));
              } else {
                yield put(actions.setChangingStatus(false));
              }
            }
            if (changeStatusOptions) {
              yield put(actions.changeStatusOptions(changeStatusOptions));
            }
            yield put(actions.updateStateEvent(eventData));
            break;
          }
          case "slideChanged": {
            yield put(actions.changeSlide(args[0]));
            break;
          }
          case "reaction": {
            yield put(actions.receiveReaction(args[0]));
            break;
          }
          case "eventMembers": {
            if (Array.isArray(args[0])) {
              // @ts-ignore
              yield put(actions.setEventMembers.request(args[0]));
            }
            break;
          }

          case "userChangedName": {
            if (Array.isArray(args[0])) {
              // @ts-ignore
              yield put(actions.setEventMembers.request(args[0]));
            }

            break;
          }
          case "triggerMemberUpdate": {
            yield apply(socket, socket.send, ["getMembersList"]);
            break;
          }
          case "blockedMembers": {
            yield put(actions.setBlockedEventMembers(args[0]));
            break;
          }
          case "goingLiveTime": {
            if (Number.isInteger(args[0])) {
              yield put(actions.setGoingLiveTime(args[0]));
            }
            break;
          }
          case "participantsLimitExceeded": {
            yield put(actions.setParticipantsLimitExceeded(true));
            break;
          }
          case "announcementPublished": {
            const announcement = args[0] as IAnnouncement;
            yield put(actions.wsAcnnouncementPublished(announcement));
            const notificationData = {
              id: announcement.id,
              title: announcement.title,
              message: announcement.description,
              button_text: announcement.button_text,
              button_link: announcement.button_link,
            };

            switch (announcement.type) {
              case AnnouncementType.popup:
                yield put(enablePopup(notificationData));
                break;
              case AnnouncementType.notification: {
                yield put(enableNotification(notificationData));
                const notificationSettings = (yield select(getChatNotificationSettings())) as IChatNotificationSettings;
                if (notificationSettings.notifySound) {
                  yield call(playNotificationAudio);
                }
                break;
              }
              case AnnouncementType.panel: {
                yield put(
                  updateEventSettings({
                    announcementToFind: announcement.id,
                    activeDashboardTab: EventDashboardTabs.announcements,
                  }),
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case "announcementDraft": {
            yield put(actions.wsAcnnouncementDraft(args[0]));
            break;
          }
          case "announcementDeleted": {
            yield put(actions.wsAcnnouncementDeleted(args[0]?.id));
            break;
          }
          case "classroomFull": {
            yield put(setFullClassroom(true));
            break;
          }
          case "joinClassrooms": {
            if (Array.isArray(args[0])) {
              const classrooms: ClassRoomSettings[] = [...args[0]];
              yield put(setEventClassrooms.request({ classrooms }));
            }
            break;
          }
          case "pollUpdated": {
            const poll: IPoll = args[0];
            if (poll) {
              yield put(actions.updatePoll.success(poll));
              if ([PollStatus.closed, PollStatus.published].includes(poll.status)) {
                yield put(
                  updateEventSettings({
                    pullToFind: poll.id,
                    activeDashboardTab: EventDashboardTabs.polls,
                  }),
                );
              }
            }
            break;
          }
          case "pollCreated": {
            const poll: IPoll = args[0];
            if (poll) {
              yield put(actions.createPoll.success(poll));
              if (poll.status === PollStatus.published) {
                yield put(
                  updateEventSettings({
                    pullToFind: poll.id,
                    activeDashboardTab: EventDashboardTabs.polls,
                  }),
                );
              }
            }
            break;
          }
          case "pollDeleted": {
            yield put(actions.deletePoll.success(args[0]));
            break;
          }
          case "pollVote": {
            yield put(actions.pollVote.success(args[0]));
            break;
          }
          case "restrictedMembers": {
            yield put(fetchRestrictedMembers.success(args[0]));
            break;
          }
          case "knockJoinMeeting": {
            const filteredMembers = args[0]?.filter(
              (waitingMember: IWaitingToJoinMember) =>
                !sermons.waitingToJoinMembers.some(({ member }) => member.id === waitingMember.member.id),
            );
            if (filteredMembers.length) {
              yield put(actions.knockJoinMeeting.success([...sermons.waitingToJoinMembers, ...filteredMembers]));
            }
            break;
          }
          case "joinMeetingKnockResponse": {
            if (args[0].deniedMemberIds.includes(auth.member?.id)) {
              yield put(setKnockRejected(true));
            } else if (args[0].allowedMemberIds.includes(auth.member?.id)) {
              yield put(setPreJoinPage(false));
            }
            yield put(
              actions.knockJoinMeeting.success(
                sermons.waitingToJoinMembers.filter(
                  waitingMember =>
                    !(
                      args[0]?.allowedMemberIds.includes(waitingMember.member.id) ||
                      args[0]?.deniedMemberIds.includes(waitingMember.member.id)
                    ),
                ),
              ),
            );
            break;
          }
          default:
            // eslint-disable-next-line no-console
            console.warn(`Unknown event ${method}`);
        }
      }
    }
  } catch (error: any) {
    const message = `[BROWSER] socket.io: ${error}`;
    // eslint-disable-next-line no-console
    console.log(message);
  }
}

function* setEventMembersSaga({ payload }: ReturnType<typeof actions.setEventMembers.request>) {
  try {
    yield put(actions.setEventMembers.success(payload));
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* fetchSignature({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const sign: IMeetingSignature = yield call(api.generateSignature, payload);
    yield put(actions.fetchSermonSignature.success(sign));
  } catch (error: any) {
    yield put(notificationActions.error("Cannot fetch signature", (error && error.message) || ""));
    if (error.code === 403) {
      yield put(logout.request({ reload: true }));
    }
  } finally {
    yield put(stopLoading());
  }
}

function* checkAvailableSlots({ payload }: AnyAction & { payload: { data: ICheckSlotQuery; callback?: () => void } }) {
  try {
    const { callback, data } = payload;
    const { isAvailable } = yield call(api.checkAvailableEventSlot, data);
    yield put(actions.checkAvailableLicenseSlot.success(isAvailable));
    if (isAvailable) {
      callback && callback();
    }
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* fetchEventIntegrations({ payload: code }: { type: string; payload: string }) {
  try {
    yield put(startLoading());
    const data: IEventIntegrations = yield call(api.fetchEventIntegrations, code);
    yield put(actions.fetchEventIntegrations.success(data));
  } catch (error: any) {
    yield put(actions.fetchEventIntegrations.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* fetchEventShortLink({
  payload,
}: {
  type: string;
  payload: { code: string; callback: (data: string) => void; avoidInstApp: boolean };
}) {
  try {
    yield put(startLoading());
    const { code, callback, avoidInstApp } = payload;
    const data: { shortLink: string } = yield call(api.fetchEventShortLink, code, avoidInstApp);
    yield put(actions.fetchEventShortLink.success(data.shortLink));
    callback?.(data.shortLink);
  } catch (error: any) {
    yield put(actions.fetchEventShortLink.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* getRecurringEventSaga({ payload }: ReturnType<typeof actions.getRecurringEvent.request>) {
  const { eventIdentifier, shouldRedirect = true } = payload;
  try {
    yield put(startLoading());
    const event: IEvent = yield call(api.getRecurringEvent, eventIdentifier);
    yield put(actions.getRecurringEvent.success(event));
  } catch (error: any) {
    yield put(actions.getRecurringEvent.failure());
    if (shouldRedirect) {
      yield put(notificationActions.error("The recurring event is not available"));
      history.push("/");
    }
  } finally {
    yield put(stopLoading());
  }
}

function* fetchAnnouncementsSaga({ payload }: { payload: IFetchAnnouncementsShape }) {
  const { meeting_id } = payload;
  try {
    yield put(startLoading());
    const data: IAnnouncement[] = yield call(api.fetchAnnouncements, meeting_id);
    yield put(actions.fetchAnnouncementsList.success(data));
  } catch (err: any) {
    yield put(actions.fetchAnnouncementsList.failure());
    yield put(notificationActions.error("Cannot fetch announcements", (err && (err as any).message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* createAnnouncementSaga({ payload }: { payload: ICreateAnnouncementShape }) {
  try {
    yield put(startLoading());
    const data: IAnnouncement = yield call(api.createAnnouncement, payload);
    yield put(actions.createAnnouncement.success(data));
    yield put(notificationActions.success("Announcement was successfully created"));
  } catch (err: any) {
    yield put(actions.createAnnouncement.failure());
    yield put(notificationActions.error("Cannot create announcement", (err && (err as any).message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* updateAnnouncementSaga({ payload }: { payload: IUpdateAnnouncementShape }) {
  try {
    yield put(startLoading());
    const data: IAnnouncement = yield call(api.updateAnnouncement, payload);
    yield put(actions.updateAnnouncement.success(data));
    yield put(notificationActions.success("Announcement was successfully updated"));
  } catch (err: any) {
    yield put(actions.createAnnouncement.failure());
    yield put(notificationActions.error("Cannot update announcement", (err && (err as any).message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* deleteAnnouncementSaga({ payload }: { payload: number }) {
  try {
    yield put(startLoading());
    yield call(api.deleteAnnouncement, payload);
    yield put(actions.deleteAnnouncement.success());
    yield put(notificationActions.success("Announcement was successfully deleted"));
  } catch (err: any) {
    yield put(actions.createAnnouncement.failure());
    yield put(notificationActions.error("Cannot delete announcement", (err && (err as any).message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* readAnnouncementsSaga({ payload }: { payload: IReadAnnouncementsShape }) {
  try {
    const data: IAnnouncement[] = yield call(api.readAnnouncements, payload);
    yield put(actions.readAnnoucements.success(data));
  } catch (err: any) {
    yield put(actions.readAnnoucements.failure());
    yield put(notificationActions.error("Cannot read announcements", (err && (err as any).message) || ""));
  }
}

function* fetchPolls({ payload }: ReturnType<typeof actions.fetchPolls.request>) {
  const { meeting_id } = payload;
  try {
    const polls: IPoll = yield call(api.fetchPolls, meeting_id);
    yield put(actions.fetchPolls.success(polls));
  } catch (error: any) {
    yield put(actions.fetchPolls.failure());
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* readPollsSaga({ payload }: ReturnType<typeof actions.readPolls.request>) {
  try {
    const readPolls: IPollReadResponse[] = yield call(api.readPolls, payload);
    yield put(actions.readPolls.success(readPolls));
  } catch (err: any) {
    yield put(actions.readPolls.failure());
    yield put(notificationActions.error("Cannot read polls", (err && (err as any).message) || ""));
  }
}

function* createPoll({ payload }: ReturnType<typeof actions.createPoll.request>) {
  const { poll, callback } = payload;
  try {
    yield call(api.createPoll, poll);
    callback?.(true, false);
  } catch (error: any) {
    callback?.(false, false);
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* updatePoll({ payload }: ReturnType<typeof actions.updatePoll.request>) {
  const { poll, callback } = payload;
  try {
    yield call(api.updatePoll, poll);
    callback?.(true, false);
  } catch (error: any) {
    callback?.(false, false);
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* deletePoll({ payload }: ReturnType<typeof actions.deletePoll.request>) {
  const { poll_id } = payload;
  try {
    yield call(api.deletePoll, poll_id);
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* pollVoteSaga({ payload }: ReturnType<typeof actions.pollVote.request>) {
  const { data, callback } = payload;
  try {
    yield call(api.pollVote, data);
  } catch (error: any) {
    callback(false);
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* fetchPollTemplatesSaga({ payload }: ReturnType<typeof actions.fetchPollTemplates.request>) {
  try {
    const pollTemplates: IPollTemplate[] = yield call(api.fetchPollTemplates, payload);
    yield put(actions.fetchPollTemplates.success(pollTemplates));
  } catch (error: any) {
    yield put(actions.fetchPollTemplates.failure());
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* createPollTemplateSaga({ payload }: ReturnType<typeof actions.createPollTemplate.request>) {
  try {
    const createdPollTemplate: IPollTemplate = yield call(api.createPollTemplate, payload);
    yield put(actions.createPollTemplate.success(createdPollTemplate));
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* updatePollTemplateSaga({ payload }: ReturnType<typeof actions.updatePollTemplate.request>) {
  const { id, preparedData } = payload;
  try {
    const updatedPollTemplate: IPollTemplate = yield call(api.updatePollTemplate, id, preparedData);
    yield put(actions.updatePollTemplate.success(updatedPollTemplate));
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* deletePollTemplateSaga({ payload }: ReturnType<typeof actions.deletePollTemplate.request>) {
  try {
    yield call(api.deletePollTemplate, payload);
    yield put(actions.deletePollTemplate.success(payload));
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* checkVimeoConnection({ payload }: ReturnType<typeof actions.checkVimeoConnection.request>) {
  try {
    yield put(startLoading());
    const result: { message: string; valid: boolean } = yield call(api.checkVimeoConnection, payload.vimeo_token);
    yield put(actions.checkVimeoConnection.success(result.valid));
    if (payload.showNotification) {
      yield put(notificationActions[result.valid ? "success" : "error"](result.message));
    }
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* deleteFile({ payload }: ReturnType<typeof actions.deleteFile.request>) {
  try {
    const { id } = payload;

    yield put(startLoading());
    yield call(api.deleteFile, id);
    yield put(actions.deleteFile.success());
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* fetchAnnouncementTemplatesSaga({ payload }: ReturnType<typeof actions.fetchAnnouncementTemplates.request>) {
  try {
    const templates: IAnnouncementTemplate[] = yield call(api.fetchAnnouncementTemplates, payload);
    yield put(actions.fetchAnnouncementTemplates.success(templates));
  } catch (error: any) {
    yield put(actions.fetchAnnouncementTemplates.failure());
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* createAnnouncementTemplateSaga({ payload }: ReturnType<typeof actions.createAnnouncementTemplate.request>) {
  try {
    const createdTemplate: IAnnouncementTemplate = yield call(api.createAnnouncementTemplate, payload);
    yield put(actions.createAnnouncementTemplate.success(createdTemplate));
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* updateAnnouncementTemplateSaga({ payload }: ReturnType<typeof actions.updateAnnouncementTemplate.request>) {
  const { id, preparedData } = payload;
  try {
    const updatedTemplate: IAnnouncementTemplate = yield call(api.updateAnnouncementTemplate, id, preparedData);
    yield put(actions.updateAnnouncementTemplate.success(updatedTemplate));
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* deleteAnnouncementTemplateSaga({ payload }: ReturnType<typeof actions.deleteAnnouncementTemplate.request>) {
  try {
    yield call(api.deleteAnnouncementTemplate, payload);
    yield put(actions.deleteAnnouncementTemplate.success(payload));
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* sermonSagas() {
  yield takeLatest(SermonActionTypes.FETCH_SERMONS_LIST, fetchSermonsList);
  yield takeLatest(actions.fetchSermon.request, fetchSermon);
  yield takeLatest(SermonActionTypes.CREATE_SERMON_REQUEST, createEvent);
  yield takeLatest(SermonActionTypes.UPDATE_SERMON_REQUEST, updateSermon);
  yield takeLatest(actions.fetchFileUrl.request, fetchFileUrl);
  yield takeLatest(SermonActionTypes.START_EVENT_REQUEST, startEvent);
  yield takeLatest(SermonActionTypes.REMOVE_EVENT_REQUEST, removeEvent);
  yield takeLatest(SermonActionTypes.DUPLICATE_EVENT_REQUEST, duplicateEvent);
  yield takeLatest(SermonActionTypes.FETCH_SERMON_SIGNATURE_REQUEST, fetchSignature);
  yield takeLatest(SermonActionTypes.CHECK_SLOT_REQUEST, checkAvailableSlots);
  yield takeLeading(SermonActionTypes.START_SERMON, startSocket);
  yield takeLatest(SermonActionTypes.EVENT_INTEGRATIONS_REQUEST, fetchEventIntegrations);
  yield takeLatest(SermonActionTypes.EVENT_SHORT_LINK_REQUEST, fetchEventShortLink);
  yield takeLatest(SermonActionTypes.GET_RECURRING_EVENT_REQUEST, getRecurringEventSaga);
  yield takeLatest(actions.fetchAnnouncementsList.request, fetchAnnouncementsSaga);
  yield takeLatest(actions.createAnnouncement.request, createAnnouncementSaga);
  yield takeLatest(actions.updateAnnouncement.request, updateAnnouncementSaga);
  yield takeLatest(actions.deleteAnnouncement.request, deleteAnnouncementSaga);
  yield takeLatest(actions.readAnnoucements.request, readAnnouncementsSaga);
  yield takeLatest(SermonActionTypes.FETCH_POLLS_REQUEST, fetchPolls);
  yield takeLatest(SermonActionTypes.CREATE_POLL_REQUEST, createPoll);
  yield takeLatest(SermonActionTypes.POLL_VOTE_REQUEST, pollVoteSaga);
  yield takeLatest(SermonActionTypes.UPDATE_POLL_REQUEST, updatePoll);
  yield takeLatest(SermonActionTypes.DELETE_POLL_REQUEST, deletePoll);
  yield takeLatest(SermonActionTypes.READ_POLLS_REQUEST, readPollsSaga);
  yield takeLatest(SermonActionTypes.FETCH_POLL_TEMPLATES_REQUEST, fetchPollTemplatesSaga);
  yield takeLatest(SermonActionTypes.CREATE_POLL_TEMPLATE_REQUEST, createPollTemplateSaga);
  yield takeLatest(SermonActionTypes.UPDATE_POLL_TEMPLATE_REQUEST, updatePollTemplateSaga);
  yield takeLatest(SermonActionTypes.DELETE_POLL_TEMPLATE_REQUEST, deletePollTemplateSaga);
  yield takeLatest(SermonActionTypes.CREATE_MULTIPLE_SERMONS_REQUEST, createMultipleEvents);
  yield takeEvery(actions.checkVimeoConnection.request, checkVimeoConnection);
  yield takeLatest(actions.deleteFile.request, deleteFile);
  yield throttle(3000, SermonActionTypes.WS_EVENT_MEMBERS_REQUEST, setEventMembersSaga);
  yield takeLatest(SermonActionTypes.FETCH_ANNOUNCEMENT_TEMPLATES_REQUEST, fetchAnnouncementTemplatesSaga);
  yield takeLatest(SermonActionTypes.CREATE_ANNOUNCEMENT_TEMPLATE_REQUEST, createAnnouncementTemplateSaga);
  yield takeLatest(SermonActionTypes.UPDATE_ANNOUNCEMENT_TEMPLATE_REQUEST, updateAnnouncementTemplateSaga);
  yield takeLatest(SermonActionTypes.DELETE_ANNOUNCEMENT_TEMPLATE_REQUEST, deleteAnnouncementTemplateSaga);
}

export default sermonSagas;
