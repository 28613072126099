export enum MemberActionTypes {
  FETCH_MEMBERS_TO_MODAL = "@@MEMBER/FETCH_MEMBERS_TO_MODAL",
  FETCH_MEMBERS_TO_MODAL_SUCCESS = "@@MEMBER/FETCH_MEMBERS_TO_MODAL_SUCCESS",
  FETCH_MEMBERS_TO_MODAL_FAILURE = "@@MEMBER/FETCH_MEMBERS_TO_MODAL_FAILURE",

  FETCH_EXIST_MEMBER = "@@MEMBER/FETCH_ESIXT_MEMBER",
  FETCH_EXIST_MEMBER_SUCCESS = "@@MEMBER/FETCH_ESIXT_MEMBER_SUCCESS",
  FETCH_EXIST_MEMBER_FAILURE = "@@MEMBER/FETCH_ESIXT_MEMBER_FAILURE",
  FETCH_EXIST_MEMBER_CLEAR = "@@MEMBER/FETCH_EXIST_MEMBER_CLEAR",

  FETCH_MEMBER_IN_COMMUNITY = "@@MEMBER/FETCH_MEMBER_IN_COMMUNITY",
  FETCH_MEMBER_IN_COMMUNITY_SUCCESS = "@@MEMBER/FETCH_MEMBER_IN_COMMUNITY_SUCCESS",
  FETCH_MEMBER_IN_COMMUNITY_FAILURE = "@@MEMBER/FETCH_MEMBER_IN_COMMUNITY_FAILURE",

  CREATE_MEMBER = "@@MEMBER/CREATE_MEMBER",
  CREATE_MEMBER_SUCCESS = "@@MEMBER/CREATE_MEMBER_SUCCESS",
  CREATE_MEMBER_FAILURE = "@@MEMBER/CREATE_MEMBER_FAILURE",

  UPDATE_MEMBER = "@@MEMBER/UPDATE_MEMBER",
  UPDATE_MEMBER_SUCCESS = "@@MEMBER/UPDATE_MEMBER_SUCCESS",
  UPDATE_MEMBER_FAILURE = "@@MEMBER/UPDATE_MEMBER_FAILURE",

  CONNECT_MEMBER_TO_COMMUNITY = "@@MEMBER/CONNECT_MEMBER_TO_COMMUNITY",
  CONNECT_MEMBER_TO_COMMUNITY_SUCCESS = "@@MEMBER/CONNECT_MEMBER_TO_COMMUNITY_SUCCESS",
  CONNECT_MEMBER_TO_COMMUNITY_FAILURE = "@@MEMBER/CONNECT_MEMBER_TO_COMMUNITY_FAILURE",

  REMOVE_MEMBER = "@@MEMBER/REMOVE_MEMBER",
  REMOVE_MEMBER_SUCCESS = "@@MEMBER/REMOVE_MEMBER_SUCCESS",
  REMOVE_MEMBER_FAILURE = "@@MEMBER/REMOVE_MEMBER_FAILURE",

  LEAVE_MEMBER = "@@MEMBER/LEAVE_MEMBER",
  LEAVE_MEMBER_SUCCESS = "@@MEMBER/LEAVE_MEMBER_SUCCESS",
  LEAVE_MEMBER_FAILURE = "@@MEMBER/LEAVE_MEMBER_FAILURE",

  REMOVE_MEMBERS = "@@MEMBER/REMOVE_MEMBERS",
  REMOVE_MEMBERS_SUCCESS = "@@MEMBER/REMOVE_MEMBERS_SUCCESS",
  REMOVE_MEMBERS_FAILURE = "@@MEMBER/REMOVE_MEMBERS_FAILURE",

  CHECK_USER_IS_VERIFIED_REQUEST = "@@MEMBER/CHECK_USER_IS_VERIFIED_REQUEST",
  CHECK_USER_IS_VERIFIED_SUCCESS = "@@MEMBER/CHECK_USER_IS_VERIFIED_SUCCESS",
  CHECK_USER_IS_VERIFIED_FAILURE = "@@MEMBER/CHECK_USER_IS_VERIFIED_FAILURE",

  FETCH_MEMBER_BY_CODE = "@@MEMBER/FETCH_MEMBER_BY_CODE",
  FETCH_MEMBER_BY_CODE_SUCCESS = "@@MEMBER/FETCH_MEMBER_BY_CODE_SUCCESS",
  FETCH_MEMBER_BY_CODE_FAILURE = "@@MEMBER/FETCH_MEMBER_BY_CODE_FAILURE",

  FETCH_MEETING_MEMBERS_REQUEST = "@@MEMBER/FETCH_MEETING_MEMBERS_REQUEST",
  FETCH_MEETING_MEMBERS_SUCCESS = "@@MEMBER/FETCH_MEETING_MEMBERS_SUCCESS",
  FETCH_MEETING_MEMBERS_FAILURE = "@@MEMBER/FETCH_MEETING_MEMBERS_FAILURE",

  SEND_PRIVATE_INVITATION_REQUEST = "@@MEMBER/SEND_PRIVATE_INVITATION_REQUEST",
  SEND_PRIVATE_INVITATION_SUCCESS = "@@MEMBER/SEND_PRIVATE_INVITATION_SUCCESS",
  SEND_PRIVATE_INVITATION_FAILURE = "@@MEMBER/SEND_PRIVATE_INVITATION_FAILURE",

  FETCH_INVITATION_DATE_REQUEST = "@@MEMBER/FETCH_INVITATION_DATE_REQUEST",
  FETCH_INVITATION_DATE_SUCCESS = "@@MEMBER/FETCH_INVITATION_DATE_SUCCESS",
  FETCH_INVITATION_DATE_FAILURE = "@@MEMBER/FETCH_INVITATION_DATE_FAILURE",

  GET_MEMBER_INVITATION_LINK = "@@MEMBER/GET_MEMBER_INVITATION_LINK",

  FETCH_CHAT_TOKEN = "@@AUTH/FETCH_CHAT_TOKEN",
  FETCH_CHAT_TOKEN_SUCCESS = "@@AUTH/FETCH_CHAT_TOKEN_SUCCESS",
  FETCH_CHAT_TOKEN_FAILURE = "@@AUTH/FETCH_CHAT_TOKEN_FAILURE",

  FETCH_ANONYMOUS_CHAT_TOKEN = "@@AUTH/FETCH_ANONYMOUS_CHAT_TOKEN",
  FETCH_ANONYMOUS_CHAT_TOKEN_SUCCESS = "@@AUTH/FETCH_ANONYMOUS_CHAT_TOKEN_SUCCESS",
  FETCH_ANONYMOUS_CHAT_TOKEN_FAILURE = "@@AUTH/FETCH_ANONYMOUS_CHAT_TOKEN_FAILURE",

  FETCH_RESTRICTED_MEMBERS_REQUEST = "@@MEMBER/FETCH_RESTRICTED_MEMBERS_REQUEST",
  FETCH_RESTRICTED_MEMBERS_SUCCESS = "@@MEMBER/FETCH_RESTRICTED_MEMBERS_SUCCESS",
  FETCH_RESTRICTED_MEMBERS_FAILURE = "@@MEMBER/FETCH_RESTRICTED_MEMBERS_FAILURE",

  RESTRICT_MEMBER = "@@MEMBER/RESTRICT_MEMBER",

  SET_CURRENT_RESTRICTED_MEMBER = "@@MEMBER/SET_CURRENT_RESTRICTED_MEMBER",

  DELETE_MEMBER_RESTRICTION = "@@MEMBER/DELETE_MEMBER_RESTRICTION",
}
