import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import classnames from "classnames";

import { CompleteIntroSuccessPopup } from "../../components/CommunityDashboard/CompleteSuccessProfilePopup";
import ProgressMenu from "../../components/CommunityDashboard/ProgressMenu/ProgressMenu";
import { getCommunity, getCommunityIntroductionSteps } from "../../store/selectors";
import { actions } from "../../store";
import { ECommunityIntroStepNames } from "../../interfaces";
import { completeCommunityIntroductionStep } from "../../store/actions";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../../constants";
import { EventType } from "../../../Sermons";

import { searchParamsToObject } from "shared/utils/searchParams";
import { LINKS } from "shared/constants/links";
import ProgressCircle from "shared/components/ProgressCircle/ProgressCircle";

import "./communityIntroStepsContainer.scss";

const { EVENT, EVENT_NEW, COMMUNITY_SETTINGS, MEMBERS } = NamesOfParentRoutes;
const { LANDING_SETTINGS } = NameOfRoutes;

const CommunityIntroStepsContainer: React.FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [openProgressMenu, setOpenProgressMenu] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  const introductionSteps = useSelector(getCommunityIntroductionSteps());
  const community = useSelector(getCommunity());

  const introComplete = useMemo(() => {
    const completedSteps = introductionSteps.filter(step => step.checked);
    const percentageComplete = (completedSteps.length / introductionSteps.length) * 100 || 0;
    return { completedSteps, percentageComplete };
  }, [introductionSteps]);

  const { intro } = useMemo(() => searchParamsToObject(location.search), [location]);

  const communityCompleteIntro = useCallback(() => {
    if (community) {
      dispatch(
        actions.updateCommunity.request({
          communityCode: community.code,
          communityId: community.id,
          silent: true,
          community: {
            complete_intro: true,
          },
        }),
      );
    }
  }, [dispatch, community]);

  const stepClickHandler = (name: ECommunityIntroStepNames, step_id: number) => {
    setOpenProgressMenu(false);

    const shouldStepCheck = [
      ECommunityIntroStepNames.connect_stream_feed,
      ECommunityIntroStepNames.customize_community,
      ECommunityIntroStepNames.link_website,
      ECommunityIntroStepNames.invite_team,
    ].includes(name);

    if (community) {
      if (shouldStepCheck) {
        dispatch(completeCommunityIntroductionStep({ community_id: community.id, step_id }));
      }
      switch (name) {
        case ECommunityIntroStepNames.create_stream:
        case ECommunityIntroStepNames.create_meeting: {
          const eventType = ECommunityIntroStepNames.create_meeting === name ? EventType.meeting : EventType.stream;
          history.push(`/${community.code}${EVENT}/${eventType}${EVENT_NEW}`);
          break;
        }
        case ECommunityIntroStepNames.connect_stream_feed:
          window.open(LINKS.streamUrlSupport.default, "_blank");
          break;
        case ECommunityIntroStepNames.customize_community:
          history.push(`/${community.code}${COMMUNITY_SETTINGS}${LANDING_SETTINGS}`);
          break;
        case ECommunityIntroStepNames.link_website:
          window.open(LINKS.connectingAltarLiveToWebsite, "_blank");
          break;
        case ECommunityIntroStepNames.invite_team:
          history.push(`/${community.code}${MEMBERS}`);
          break;
        default:
          return;
      }
    }
  };

  useEffect(() => {
    if (community) {
      dispatch(actions.getCommunityIntroductionSteps.request(community?.id));
    }
  }, [community, dispatch]);

  useEffect(() => {
    if (introductionSteps.length > 0 && introductionSteps.length === introComplete?.completedSteps.length) {
      if (community && !community.complete_intro) {
        setOpenSuccessPopup(true);
        communityCompleteIntro();
      }
    }
  }, [introComplete, introductionSteps, community, communityCompleteIntro]);

  useEffect(() => {
    if (intro) {
      setOpenProgressMenu(true);
      history.replace({ search: "" });
    }
  }, [intro, history]);

  return (
    <>
      <CompleteIntroSuccessPopup show={openSuccessPopup} onClose={() => setOpenSuccessPopup(false)} />
      {community && !community.complete_intro && (
        <div className={classnames("completeProfile", { isCollapsed })}>
          <ProgressCircle
            percentage={introComplete.percentageComplete}
            colour="#01A555"
            strokeWidth="10px"
            radius={22}
            baseColour="#D7E2EA"
            onClick={() => setOpenProgressMenu(true)}
          />
          <ProgressMenu
            openProgressMenu={openProgressMenu}
            closeProgressMenu={() => setOpenProgressMenu(false)}
            introductionSteps={introductionSteps}
            communityCompleteIntro={communityCompleteIntro}
            stepClickHandler={stepClickHandler}
          />
          <div className="completeProfile-stepText" onClick={() => setOpenProgressMenu(true)}>
            First Steps
          </div>
        </div>
      )}
    </>
  );
};

export default CommunityIntroStepsContainer;
