import produce from "immer";
import { Reducer } from "redux";

import { IDiscussionsState } from "../interfaces";
import { DiscussionsActionTypes } from "./constants";

const initialState: IDiscussionsState = {
  channels: [],
  unreadMessagesCount: {
    personalUnread: 0,
    eventGeneralUnread: 0,
  },
  sentMessagesDates: [],
  allowGeneralChatInput: false,
  showGreeterCustomChannel: false,
  createdGeneralChatId: null,
};

export const DiscussionsReducer: Reducer<IDiscussionsState> = (state: IDiscussionsState = initialState, action) => {
  switch (action.type) {
    case DiscussionsActionTypes.FETCH_CHANNELS_SUCCESS:
      return produce(state, nextState => {
        nextState.channels = action.payload;
      });
    case DiscussionsActionTypes.GET_SELECTED_CHANNEL_DETAILS_SUCCESS:
      return produce(state, nextState => {
        nextState.selectedChannel = action.payload;
      });
    case DiscussionsActionTypes.CREATE_NEW_CHAT_SUCCESS:
      return produce(state, nextState => {
        nextState.activeChannel = action.payload.id;
      });
    case DiscussionsActionTypes.CREATE_ANONYMOUS_CHANNEL_WITH_GREETER_SUCCESS:
      return produce(state, nextState => {
        nextState.activeChannel = action.payload.id;
      });
    case DiscussionsActionTypes.CLEAR_SELECTED_CHANNEL:
      return produce(state, nextState => {
        nextState.activeChannel = undefined;
        nextState.selectedChannel = undefined;
      });
    case DiscussionsActionTypes.SET_ACTIVE_CHANNEL:
      return produce(state, nextState => {
        nextState.activeChannel = action.payload;
      });
    case DiscussionsActionTypes.SET_GETSTREAM_CHANNELS:
      return produce(state, nextState => {
        nextState.getStreamChannels = action.payload;
      });
    case DiscussionsActionTypes.CLEAR_GETSTREAM_CHANNELS:
      return produce(state, nextState => {
        nextState.getStreamChannels = undefined;
      });
    case DiscussionsActionTypes.SHOW_CHAT_LIST:
      return produce(state, nextState => {
        nextState.showChatList = action.payload;
      });
    case DiscussionsActionTypes.CREATE_CHAT_WITH_MEMBER:
      return produce(state, nextState => {
        nextState.createChatWithMember = action.payload;
      });
    case DiscussionsActionTypes.CLEAR_CREATE_CHAT_WITH_MEMBER:
      return produce(state, nextState => {
        nextState.createChatWithMember = undefined;
      });

    case DiscussionsActionTypes.SET_UNREAD_MESSAGES_COUNT:
      return produce(state, nextState => {
        nextState.unreadMessagesCount = { ...state.unreadMessagesCount, ...action.payload };
      });
    case DiscussionsActionTypes.CLEAR_UNREAD_MESSAGES_COUNT:
      return produce(state, nextState => {
        nextState.unreadMessagesCount = { ...initialState.unreadMessagesCount };
      });

    case DiscussionsActionTypes.SET_EVENT_CHANNELS_IDS:
      return produce(state, nextState => {
        nextState.channelsIds = action.payload;
      });
    case DiscussionsActionTypes.CLEAR_EVENT_CHANNELS_IDS:
      return produce(state, nextState => {
        nextState.channelsIds = undefined;
      });
    case DiscussionsActionTypes.SET_SENT_MESSAGES_DATES:
      return produce(state, nextState => {
        nextState.sentMessagesDates = action.payload;
      });
    case DiscussionsActionTypes.SET_ALLOW_GENERAL_CHAT_INPUT: {
      return produce(state, nextState => {
        nextState.allowGeneralChatInput = action.payload;
      });
    }
    case DiscussionsActionTypes.SHOW_GREETER_CUSTOM_CHANNEL: {
      return produce(state, nextState => {
        nextState.showGreeterCustomChannel = action.payload;
      });
    }
    case DiscussionsActionTypes.CREATE_GENERAL_CHAT_SUCCESS: {
      return produce(state, nextState => {
        nextState.createdGeneralChatId = action.payload;
      });
    }
    default:
      return state;
  }
};
