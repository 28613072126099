import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Registration } from "../../components";
import { RegisterShape } from "../../interfaces";
import { registrationValidationSchema } from "../../constants";
import { NamesOfParentRoutes } from "../../../../constants";
import { getImageBase64 } from "../../../../shared/utils/dataModifiers";
import EmailSent from "../../components/EmailSent";
import { createProfile, sendUserConfirmationEmail } from "../../../Onboarding/store/actions";
import AuthWrapper from "../../components/AuthWrapper";

import { actions, selectors } from "containers/Auth";
import { ECreationKind } from "shared";

const { AUTH } = NamesOfParentRoutes;

const RegistrationContainer: React.FC = () => {
  const [initialValues, setInitialValues] = useState<RegisterShape>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    image_url: "",
    password: "",
    password_confirm: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const userId = useSelector(selectors.getUnverifiedUserId());
  const emailResent = useSelector(selectors.isEmailResent());
  const registrationData = useSelector(selectors.getRegistrationData());
  const onboardingData = useSelector(selectors.getOnboardingData());
  useEffect(() => {
    if (registrationData) {
      setInitialValues(prevState => ({ ...prevState, ...registrationData }));
    }
  }, [registrationData]);
  const handleSubmit = (values: RegisterShape) => {
    const { image_url, phone, ...rest } = values;
    const image_base64 = getImageBase64(image_url, true);
    if (
      onboardingData &&
      onboardingData.invitationHash &&
      (!onboardingData.email || onboardingData.email === rest.email)
    ) {
      const shouldLogin = !!onboardingData.email;
      const data = {
        ...rest,
        phone_number: phone,
        image_base64,
      };
      return dispatch(
        createProfile.request({
          data,
          token: onboardingData.invitationHash,
          shouldLogin,
          kind: ECreationKind.community,
        }),
      );
    }

    dispatch(actions.registration.request({ ...rest, phone, image_base64 }));
  };

  const handleBack = useCallback(
    (values: RegisterShape) => {
      dispatch(actions.setUnverifiedUserId(null));

      const { password, password_confirm, image_base64, ...rest } = values;
      dispatch(actions.setRegistrationData({ ...rest, image_url: rest.image_url || null }));
      history.push(AUTH);
    },
    [dispatch, history],
  );

  return (
    <Formik
      validationSchema={registrationValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ values }) => {
        const handleResendEmail = () => {
          dispatch(
            sendUserConfirmationEmail.request({
              email: values.email,
              showNotification: false,
              callback: () => {
                dispatch(actions.setEmailResent(true));
              },
            }),
          );
        };
        return userId ? (
          <AuthWrapper>
            <EmailSent
              title={emailResent ? "Confirmation email has been resent" : "You’re almost finished!"}
              subTitle={
                <>
                  Email has been sent to <span className="email-value">{values.email}</span>.
                  <br />
                  Please check your inbox to continue.
                </>
              }
              onChangeEmail={() => handleBack(values)}
              onResendEmail={handleResendEmail}
            />
          </AuthWrapper>
        ) : (
          <Registration onBack={() => handleBack(values)} />
        );
      }}
    </Formik>
  );
};

export default RegistrationContainer;
