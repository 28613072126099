import React from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import classnames from "classnames";

import "./scheduler.scss";

interface IScheduler {
  children: React.ReactNode;
  date: Date;
}

const Scheduler = (props: IScheduler) => {
  const { date } = props;
  const isToday = moment().isSame(date, "day");
  const day = moment(date).format("D");

  return (
    <Grid container direction="row" justify="space-between" wrap="nowrap" className={"scheduler"}>
      <Grid item className={"scheduler-leftBlock"}>
        <div className={"scheduler-line"}>
          <div className={classnames("scheduler-dateSquare", isToday && "scheduler-dateSquare-today")}>
            <div className={"scheduler-dateInfo"}>
              <div className="scheduler-dateInfo-dayOfWeek">{moment(date).format("ddd")}</div>
              <div className={classnames("scheduler-dateInfo-day", isToday && "scheduler-dateInfo-day-today")}>
                {day}
              </div>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item className={"scheduler-childrenContainer"}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default Scheduler;
