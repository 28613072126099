import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { NamesOfParentRoutes } from "../../../../constants";
import { BreakoutRoomTypes } from "../../../BreakoutRooms/interfaces";

import { ClassRoomSettings, EventMembersCounter } from "containers/Sermons";
import { selectClassrooms } from "containers/Meeting/store/selectors";
import { authentificated, getMember } from "containers/Auth/store/selectors";
import { setCurrentClassroom } from "containers/Meeting/store/actions";
import { CustomAvatar } from "containers/Discussions";
import { EventAuthAlert, IRouteProps } from "shared";
import { getEventMembers } from "containers/Sermons/store/selectors";
import { IMember } from "containers/Member";
import { TablesClassroomSwitcher } from "containers/Meeting/components";
import { selectRooms, selectStream } from "containers/BreakoutRooms/store/selectors";
import { exitRoom } from "containers/BreakoutRooms/store/actions";
import default_classroom_image from "assets/images/default-classroom-image.svg";

import "./index.scss";

const { STREAM, CLASSROOMS } = NamesOfParentRoutes;

export default function ClassroomsContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const stream = useSelector(selectStream());
  const isAuthenticated = useSelector(authentificated());
  const classrooms = useSelector(selectClassrooms());
  const member = useSelector(getMember());
  const eventMembers = useSelector(getEventMembers());
  const rooms = useSelector(selectRooms());

  const { communityCode, eventCode } = useParams<IRouteProps>();

  const [selectSeatAlertOpened, setSelectSeatAlertOpened] = useState(false);

  const lobbyClassrooms = useMemo(() => classrooms.filter(c => !c.is_main), [classrooms]);
  const tables = useMemo(() => rooms.filter(r => r.type === BreakoutRoomTypes.lobby), [rooms]);

  const joinRoom = (c: ClassRoomSettings) => {
    if (isAuthenticated && member) {
      dispatch(setCurrentClassroom(c));
      history.push(`${communityCode ? `/${communityCode}` : ""}${STREAM}/${eventCode}${CLASSROOMS}/${c.code}`);
      if (stream) {
        dispatch(exitRoom.request());
      }
    } else {
      setSelectSeatAlertOpened(true);
    }
  };

  const renderMembers = (members: IMember[], countToRender: number) => {
    const membersToRender = members?.slice(0, countToRender).map(m => {
      const eventMember = eventMembers.find(eM => eM.memberId === m.id);
      return (
        <div className="attendee" key={m.id}>
          <CustomAvatar
            image={m?.image_url || void 0}
            size={48}
            color={eventMember?.color || "#211B1F"}
            member={m}
            classNames="attendeePic"
          />
        </div>
      );
    });
    if (members.length > countToRender) {
      membersToRender.push(<div className="attendee counter">+{members.length - countToRender}</div>);
    }
    return membersToRender;
  };

  return (
    <div className="class-room-container-wrapper">
      <EventAuthAlert
        title="Room"
        message="You need to be logged in to join the room with other people. Please sign up or login."
        open={selectSeatAlertOpened}
        onClose={() => setSelectSeatAlertOpened(false)}
      />
      {tables.length ? <TablesClassroomSwitcher breakOutRoomJoined={!!stream} /> : null}
      <div className="class-room-content">
        <div className={`classrooms ${lobbyClassrooms.length < 3 ? "fixed-height" : ""}`}>
          {lobbyClassrooms.map(c => (
            <div className="classroom-item" key={c.code}>
              <div className="title-wrapper">
                <div className="classroom-title">{c.name}</div>
                <div className="count">
                  {c.members?.length || 0}/{c.room_size}
                </div>
              </div>
              <div className="classroom-body">
                <div className="classroom-description">{c.description}</div>
                {!c.members?.length ? (
                  <div className="attendees-wrapper">
                    {Array.from({ length: 4 }, (_, i) => (
                      <div className="attendee" key={i}>
                        <img src={default_classroom_image} alt="pavel" />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="attendees-wrapper">{renderMembers(c.members, 7)}</div>
                )}
                <div className="join-wrapper">
                  {(c.members?.length || 0) < c.room_size ? (
                    <button onClick={() => joinRoom(c)}>JOIN</button>
                  ) : (
                    <button>FULL</button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <EventMembersCounter />
    </div>
  );
}
