import React from "react";
import classnames from "classnames";
import { FieldAttributes } from "formik";

import { DropDown } from "shared";
import { TIMEZONES } from "shared/constants/time";

import "./timeZonePicker.scss";

interface ITimeZonePicker extends FieldAttributes<any> {
  value: number;
  onChange: () => void;
  label?: string;
}

const TimeZonePicker: React.FC<ITimeZonePicker> = props => {
  const { value, name, setFieldValue, label = "Time Zone" } = props;
  return (
    <div className="timeZonePicker">
      <span className="timeZonePicker-label">{label}</span>
      <div className={classnames("timeZonePicker-controls", label && "timeZonePicker-controls-label")}>
        <DropDown
          size="full"
          items={TIMEZONES}
          spacer={false}
          value={value}
          onChange={value => setFieldValue(name, value)}
        />
      </div>
    </div>
  );
};

export default TimeZonePicker;
