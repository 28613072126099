import { MemberStreamInterface } from "./MemberStream";
import { RoomInterface } from "./RoomInterface";

export type MediaDevicesList<T> = {
  [key in MediaDeviceKind]: T[];
};

export interface IMediaPermissionsState {
  cameraPermission: PermissionState | null;
  micPermission: PermissionState | null;
}

export enum BreakOutRoomScreenTypes {
  tables = "tables",
  classrooms = "classrooms",
}

export interface BreakoutRoomsStateType {
  isConnecting: boolean;
  isEnded: boolean;
  userMediaError: Error | null;
  error: Error | null;
  roomCode: string | null;
  stream: MediaStream | null;
  streams: MemberStreamInterface[];
  rooms: RoomInterface[];
  isImutted: boolean;
  isVideoDisabled: boolean;
  userDevices: MediaDevicesList<MediaDeviceInfo> | null;
  mediaPermissions: PermissionState | null;
  currentBreakOutRoomScreen: BreakOutRoomScreenTypes;
}
