import { createSelector } from "reselect";

import { AltarStore } from "shared/types";

const selectCommunity = (state: AltarStore) => state.community;

export const getAllCommunities = () => createSelector(selectCommunity, state => state.allCommunities);
export const getUserCommunities = () => createSelector(selectCommunity, state => state.userCommunities);
export const getCommunity = () => createSelector(selectCommunity, state => state.community);
export const getAllUsers = () => createSelector(selectCommunity, state => state.allUsers);
export const getCommunityInvitationHash = () => createSelector(selectCommunity, state => state.communityInvitationHash);
export const getCommunitySubscriptions = () => createSelector(selectCommunity, state => state.subscriptions);
export const getCommunityUsers = () => createSelector(selectCommunity, state => state.users);
export const getHasPaidSubscriptions = () =>
  createSelector(
    selectCommunity,
    state => !!state.subscriptions?.currentSubscriptions.some(subscription => subscription.title !== "Free"),
  );
export const getCommunitySettingsFormSubmit = () =>
  createSelector(selectCommunity, state => state.communitySettingsFormSubmit);

export const selectAllSubdomains = () => createSelector(selectCommunity, state => state.allSudomains);

export const selectLandingSettings = () => createSelector(selectCommunity, state => state.landingSettings);
export const getCommunitiesCount = () => createSelector(selectCommunity, state => state.communitiesCount);
export const getCommunityStreamingStatistics = () =>
  createSelector(selectCommunity, state => state.streamingStatistics);

export const getCommunityIntroductionSteps = () => createSelector(selectCommunity, state => state.introductionSteps);
export const getSubscriptionAttention = () => createSelector(selectCommunity, state => state.subscriptionAttention);
export const getMAUData = () => createSelector(selectCommunity, state => state.mauData);
export const getMAUBannerHidden = () => createSelector(selectCommunity, state => state.mauBannerHidden);
export const getAnalytics = () => createSelector(selectCommunity, state => state.analytics);
