import { IMember } from "containers/Member";

export enum EventDashboardTabs {
  chat = "chat",
  members = "members",
  welcome = "welcome",
  auth = "auth",
  signUp = "signUp",
  editProfile = "editProfile",
  announcements = "announcements",
  polls = "polls",
  hostNotes = "hostNotes",
}

export interface SearchRoom {
  id?: number;
  name?: string | null;
  members?: IMember[];
}
