import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import classnames from "classnames";

import { getCommunity, getMAUBannerHidden, getMAUData } from "../../../store/selectors";
import { setMAUBannerHidden } from "../../../store/actions";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../../../constants";

import { AlertDialog, SquareButton } from "shared";
import attention from "assets/icons/attention.svg";
import cross_arrow_white from "assets/icons/cross_arrow_white.svg";

import "./styles.scss";

const { COMMUNITY_SETTINGS } = NamesOfParentRoutes;
const { BILLING_SETTINGS } = NameOfRoutes;

type Props = {
  panel: "hidden" | "collapsed" | "shown";
};

const MAULimitBanner: React.FC<Props> = ({ panel }) => {
  const [popupIsShown, setPopupIsShown] = useState(false);
  const mauData = useSelector(getMAUData());
  const isMAUBannerHidden = useSelector(getMAUBannerHidden());
  const community = useSelector(getCommunity());

  const dispatch = useDispatch();
  const history = useHistory();

  return !isMAUBannerHidden && mauData && mauData.allowed <= mauData.active ? (
    <div className={classnames("mau-limit-banner", `panel-${panel}`)}>
      {!isMAUBannerHidden && popupIsShown && (
        <AlertDialog
          mode="confirm"
          open
          onCancel={() => setPopupIsShown(false)}
          onConfirm={() => dispatch(setMAUBannerHidden(true))}
          confirmText="Hide"
          title="Thanks, I Got it!"
          message="I want to hide this reminder. I understand my Monthly Active User capacity has been reached and I can choose to upgrade or pay for additional active users at an overage rate."
        />
      )}
      <div className="attention-icon">
        <img src={attention} alt="attention" />
      </div>
      <div>
        <b>Your community is growing!</b> You have reached 100% your MAU capacity, but no one will be blocked.
      </div>
      <div className="need-more-mau">
        <SquareButton
          variant="white-text"
          className="need-more-mau-link"
          onClick={() => history.push(`/${community?.code}${COMMUNITY_SETTINGS}${BILLING_SETTINGS}`)}
        >
          Need More MAU? Upgrade Your Plan Now
        </SquareButton>
      </div>
      <div className="close-banner" onClick={() => setPopupIsShown(true)}>
        <img src={cross_arrow_white} alt="close" />
      </div>
    </div>
  ) : null;
};

export default MAULimitBanner;
