import React from "react";

import { IconBase } from "../Figures/IconBase";

import { IStyledSVG } from "shared/interfaces/StyledSVG";

export const StyledAltarLogo: React.FC<IStyledSVG> = ({
  style = {},
  fill = "#FFFFFF",
  width = "144",
  height = "40",
  className = "",
  opacity = 1,
  onClick,
}) => (
  <IconBase
    fill="none"
    width={width}
    style={style}
    height={height}
    className={className}
    opacity={opacity}
    viewBox="0 0 144 40"
    onClick={onClick}
  >
    <path d="M82.2368 39.5778V7.51678H88.5844V39.5778H82.2368Z" fill={fill} />
    <path
      d="M104.982 34.6725V39.4088C103.895 39.798 102.648 39.9949 101.256 39.9949C96.8137 39.9949 94.5975 37.7394 94.5975 33.2329V21.5846H91.3931V16.9937H94.5975V7.58234H100.922V16.989H104.733V21.5799H100.922V32.4967C100.922 33.3877 101.096 33.9973 101.444 34.3208C101.792 34.6491 102.385 34.8132 103.222 34.8132C103.905 34.8179 104.493 34.7663 104.982 34.6725Z"
      fill={fill}
    />
    <path
      d="M126.66 37.1163C126.816 37.9088 127.046 38.6122 127.352 39.2265V39.5829H121.005C120.727 39.039 120.525 38.3637 120.398 37.5571C118.958 39.1843 117.066 40.0003 114.723 40.0003C113.297 40.0003 111.998 39.7095 110.827 39.1234C109.655 38.5372 108.728 37.7306 108.051 36.7037C107.373 35.6767 107.034 34.5137 107.034 33.2195C107.034 30.8373 107.924 29.0038 109.693 27.7189C111.467 26.434 114.078 25.7869 117.527 25.7869H120.106V24.5114C120.106 23.4657 119.842 22.6591 119.311 22.0823C118.779 21.5055 117.984 21.2148 116.925 21.2148C115.96 21.2148 115.212 21.4399 114.676 21.8947C114.139 22.3449 113.871 22.9921 113.871 23.8268H107.547C107.547 22.4762 107.947 21.2523 108.752 20.1503C109.556 19.053 110.69 18.1808 112.168 17.543C113.641 16.9053 115.339 16.5817 117.268 16.5817C119.057 16.5817 120.642 16.8818 122.021 17.4821C123.404 18.0823 124.487 18.978 125.268 20.1738C126.049 21.3695 126.439 22.8326 126.439 24.5583V34.012C126.43 35.2828 126.505 36.3191 126.66 37.1163ZM118.577 34.6216C119.273 34.223 119.786 33.74 120.106 33.1726V29.393H117.697C116.229 29.393 115.137 29.7119 114.417 30.3449C113.697 30.978 113.339 31.8033 113.339 32.8162C113.339 33.5243 113.594 34.1011 114.102 34.5466C114.61 34.9921 115.292 35.2125 116.144 35.2125C117.066 35.2125 117.875 35.0155 118.577 34.6216Z"
      fill={fill}
    />
    <path
      d="M143.12 16.6473C143.482 16.6989 143.774 16.7551 144 16.8255L143.915 22.9216C143.106 22.8091 142.358 22.7528 141.676 22.7528C139.412 22.7528 137.939 23.4984 137.257 24.9849V39.5781H130.933V16.9896H136.881L137.069 19.7235C137.629 18.7059 138.335 17.9275 139.196 17.3835C140.057 16.8395 141.022 16.5676 142.099 16.5676C142.419 16.5723 142.758 16.6004 143.12 16.6473Z"
      fill={fill}
    />
    <path
      d="M57.7212 17.1956V39.5778H64.0688V30.2883H70.8023V39.5778H77.1499V17.1956C77.1499 11.8498 72.8021 7.51685 67.4379 7.51685C62.0737 7.51685 57.7212 11.8498 57.7212 17.1956ZM64.0688 25.6974V15.4606C64.0688 13.6083 65.5745 12.103 67.4379 12.103C69.2965 12.103 70.807 13.6036 70.807 15.4606V25.6974H64.0688Z"
      fill={fill}
    />
    <path
      d="M14.6335 14.6729C14.6994 12.5158 15.6028 10.4666 17.1462 8.96601C18.6943 7.46542 20.7599 6.62134 22.915 6.62134C25.0701 6.62134 27.1358 7.46073 28.6839 8.96601C30.2319 10.4666 31.1307 12.5158 31.1966 14.6729V18.8324H37.8924V14.6729C37.803 10.7526 36.1843 7.01994 33.3893 4.27667C30.5849 1.53341 26.8252 0 22.915 0C19.0001 0 15.2405 1.53341 12.4455 4.27667C9.65045 7.01994 8.02708 10.748 7.94238 14.6729V18.8324H14.6335V14.6729Z"
      fill={fill}
    />
    <path
      d="M4.02784 39.9957H10.7236V32.6569H35.1071V39.9957H41.8029V32.6569H45.8308V25.9464H0V32.6569H4.02784V39.9957Z"
      fill={fill}
    />
  </IconBase>
);

export default StyledAltarLogo;
