import { IContentShape } from "../../App/interfaces";
import { API, METHODS } from "../../../shared/constants";
import { request } from "../../../shared/utils";
import { IMemberDialogValues } from "../interfaces";
import { IRestrictMember } from "../../Sermons";

export default {
  fetchMembersInCommunity: (communityCode: string, params?: IContentShape) =>
    request(METHODS.GET, API.FETCH_MEMBER(communityCode))({ params }),
  fetchMemberByCode: (communityCode: string, memberCode: string) =>
    request(METHODS.GET, API.FETCH_MEMBER_BY_CODE(communityCode, memberCode))(),
  removeMember: (payload: { id: number; community_id: number }) =>
    request(METHODS.DELETE, API.REMOVE_MEMBER(payload))(),
  leaveMember: (payload: { id: number; community_id: number }) => request(METHODS.DELETE, API.LEAVE_MEMBER(payload))(),
  removeMembers: ({ community_id, queryString }: { queryString: string; community_id: number }) =>
    request(METHODS.DELETE, API.REMOVE_MEMBERS(community_id, queryString))(),
  fetchExistMember: (params: { email: string }) => request(METHODS.GET, API.FETCH_MEMBER_BY_EMAIL)({ params }),
  fetchMeetingMembers: (meetingCode: string) => request(METHODS.GET, API.FETCH_MEETING_MEMBERS(meetingCode))(),
  createMember: (payload: IMemberDialogValues) => request(METHODS.POST, API.MEMBER)(payload),
  updateMember: (payload: IMemberDialogValues & { hubSpotUserToken?: string }) =>
    payload.id && request(METHODS.PUT, API.UPDATE_MEMBER(payload.id))(payload),
  connectMember: (payload: IMemberDialogValues) => request(METHODS.POST, API.CONNECT_MEMBER)(payload),

  fetchPersonalInvitation: (communityCode: string, memberCode: string) =>
    request(METHODS.GET, API.FETCH_PERSONAL_INVITATION(communityCode, memberCode)),

  checkIsUserClaimed: (userId: number, communityId: number) =>
    request(METHODS.GET, API.CHECK_IS_USER_CLAIMED(userId, communityId))(),

  sendPrivateInvitations: (payload: { memberIds: number[] }) =>
    request(METHODS.POST, API.SEND_PRIVATE_INVITATIONS)(payload),

  fetchInvitationDate: (communityId: number, memberId: number) =>
    request(METHODS.GET, API.FETCH_INVITATION_DATE(communityId, memberId))(),
  fetchDiscussionToken: (community_id: number) => request(METHODS.GET, API.MEMBER_DISCUSSION_TOKEN(community_id))(),
  fetchAnonymousDiscussionToken: (payload: { name: string; id: string }) =>
    request(METHODS.POST, API.ANONYMOUS_DISCUSSION_TOKEN)(payload),
  restrictMember: (body: IRestrictMember) => request(METHODS.POST, API.RESTRICT_MEMBER)(body),
  fetchRestrictedMembers: (event_id: number) => request(METHODS.GET, API.FETCH_RESTRICTED_MEMBERS(event_id))(),
  deleteMemberRestriction: (member_id: number, event_id: number) =>
    request(METHODS.DELETE, API.DELETE_MEMBER_RESTRICTION(member_id, event_id))(),
};
