export enum AppActionTypes {
  ALL_CHANGED = "@@APP/ALL_CHANGED",
  START_LOAD = "@@APP/START_LOAD",
  STOP_LOAD = "@@APP/STOP_LOAD",

  SET_LOADING = "@@APP/SET_LOADING",
  ERROR_MESSAGE = "@@APP/ERROR_MESSAGE",
  SET_LIST_PROPS = "@@APP/SET_LIST_PROPS",
  SET_HUBSPOT_OPENED = "@@APP/SET_HUBSPOT_OPENED",
  SET_HUBSPOT_INITED = "@@APP/SET_HUBSPOT_INITED",
  SET_DASHBOARD_DISABLED = "@@APP/SET_DASHBOARD_DISABLED",

  SET_IP_BLOCKED = "@@APP/SET_IP_BLOCKED",
  SET_DEVICE_FINGERPRINT = "@@APP/SET_DEVICE_FINGERPRINT",
}
