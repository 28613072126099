import React, { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { PermissionsAlert } from "../../EventSeats/PermissionsAlert";
import { NamesOfParentRoutes } from "../../../../../constants";
import { BenchAttendee } from "../../EventSeats";

import { exitRoom, joinRoom } from "containers/BreakoutRooms/store/actions";
import { authentificated } from "containers/Auth/store/selectors";
import { RoomInterface } from "containers/BreakoutRooms/interfaces";
import { selectRoomCode } from "containers/BreakoutRooms/store/selectors";
import { getEventSettings } from "containers/Sermons/store/selectors";
import { EventAuthAlert, IRouteProps } from "shared";
import { useRoomsAttendees } from "shared/hooks/RoomHooks";

import "./index.scss";

const { STREAM, BREAKOUTROOMS, WATCH_PARTY } = NamesOfParentRoutes;
interface TableItemProps {
  room: RoomInterface;
  index: number;
  changeName?: (room: RoomInterface) => void;
  className?: string;
  isAuditorium: boolean;
}

export interface AttendeeClassMatrixInterface {
  [key: number]: string;
}

const AttendeeClassMatrix: AttendeeClassMatrixInterface = {
  0: "top",
  1: "right",
  2: "bottom",
  3: "left",
};

const TableItem: React.FC<TableItemProps> = ({ isAuditorium, room, index, changeName, className }) => {
  const [selectSeatAlertOpened, setSelectSeatAlertOpened] = useState(false);
  const [mediaPermissionsAlert, setMediaPermissionsAlert] = useState(false);

  const { eventCode, communityCode } = useParams<IRouteProps>();

  const isAuthenticated = useSelector(authentificated());
  const roomCode = useSelector(selectRoomCode());
  const { memberToFind } = useSelector(getEventSettings());

  const dispatch = useDispatch();

  const history = useHistory();

  const ref = createRef<HTMLDivElement>();

  const [roomState] = useRoomsAttendees(room);

  const joinToRoom = useCallback(() => {
    const path = `${communityCode ? `/${communityCode}` : ""}${STREAM}/${eventCode}${
      isAuditorium ? WATCH_PARTY : BREAKOUTROOMS
    }/${room.code}`;
    if (roomCode) {
      return dispatch(
        exitRoom.request({
          callback: () => {
            dispatch(joinRoom());
            history.push(path);
          },
        }),
      );
    }
    dispatch(joinRoom());
    history.push(path);
  }, [dispatch, history, communityCode, eventCode, roomCode, room.code, isAuditorium]);

  const navigateToRoom = useCallback(() => {
    if (!isAuthenticated) {
      setSelectSeatAlertOpened(true);
      return;
    }
    if (roomState.filled) return;

    setMediaPermissionsAlert(true);
  }, [isAuthenticated, roomState.filled]);

  const openChangeNameAlert = () => {
    if (changeName) {
      changeName(room);
    }
  };

  const isMemberToFindInBench = useCallback(() => {
    return roomState.attendees.find(a => a?.id === memberToFind);
  }, [roomState.attendees, memberToFind]);

  useEffect(() => {
    if (isMemberToFindInBench()) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [ref, isMemberToFindInBench]);

  const attendees = useMemo(
    () =>
      roomState.attendees.map((att, index) => (
        <BenchAttendee key={att ? att?.id : index} attendee={att} className={AttendeeClassMatrix[index]} />
      )),
    [roomState.attendees],
  );

  return (
    <>
      <EventAuthAlert
        title="Table"
        message="You need to be logged in to join a table with other people. Please sign up or log in."
        open={selectSeatAlertOpened}
        onClose={() => setSelectSeatAlertOpened(false)}
      />
      {mediaPermissionsAlert && (
        <PermissionsAlert setMediaPermissionsAlert={setMediaPermissionsAlert} joinToRoom={joinToRoom} />
      )}
      <div className={classnames("table-item-wrapper", className)} ref={ref}>
        <div className="table-content">
          <div className={classnames("table-name", { "can-edit": changeName })}>
            <span onClick={openChangeNameAlert}>{room.name || String(index + 1).padStart(2, "0")}</span>
          </div>
          <div
            className={classnames("table-seats", `${roomState.filled ? "not-available" : ""}`)}
            onClick={navigateToRoom}
          >
            <div className="attendee-list">{attendees}</div>
            <div className="table">
              <div className="button-wrapper">{roomState.filled ? "FULL" : "JOIN"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(TableItem);
