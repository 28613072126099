import React from "react";
import { Link } from "react-router-dom";

import { TTarget } from "shared/interfaces";

type Props = {
  children: React.ReactNode;
  to: string;
  className?: string;
  onClick?: () => void;
  target?: TTarget;
};

const RedirectLink: React.FunctionComponent<Props> = ({ children, to, className, onClick, target = "_self" }) => (
  <div>
    <Link target={target} className={className} to={to} onClick={onClick}>
      {children}
    </Link>
  </div>
);

export default RedirectLink;
