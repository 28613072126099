import React from "react";
import { RouteChildrenProps, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { NameOfRoutes, NamesOfParentRoutes } from "../../../../constants";
import { getSubscriptionAttention } from "../../store/selectors";

import {
  GeneralSettings,
  LandingSettings,
  SubscriptionDetails,
  PaymentDetails,
  Templates,
} from "containers/Community/components/CommunitySettingsContainer";
import { changeCommunitySettingsForm } from "containers/Community/store/actions";
import { isMatchedPath } from "containers/Community/utils";
import { Button, Header, IRouteProps, Page } from "shared";

import "./index.scss";

const SETTINGS_LINKS = [
  {
    name: "General Information",
    value: `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.GENERAL_SETTINGS}`,
  },
  {
    name: "Appearance",
    value: `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.LANDING_SETTINGS}`,
  },
  {
    name: "Subscription Details",
    value: `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.BILLING_SETTINGS}`,
  },
  {
    name: "Templates",
    value: `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.TEMPLATES}`,
  },
];

const CommunitySettings: React.FC<RouteChildrenProps<any, any>> = ({ location, history }) => {
  const dispatch = useDispatch();

  const subscriptionAttention = useSelector(getSubscriptionAttention());

  const showNavigation = !isMatchedPath(location.pathname, [
    `/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.GENERAL_SETTINGS}`,
    `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.GENERAL_SETTINGS}`,
    `/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.LANDING_SETTINGS}`,
    `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.LANDING_SETTINGS}`,
    `/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.BILLING_SETTINGS}`,
    `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.BILLING_SETTINGS}`,
    `/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.PRICING_SETTINGS}`,
    `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.PRICING_SETTINGS}`,
    `/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.TEMPLATES}`,
    `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.TEMPLATES}`,
  ]);

  const { communityCode } = useParams<IRouteProps>();

  const changeSettingsPage = (navUrl: string) => {
    if (communityCode) return history.replace(`/${communityCode}${navUrl}`);
    return history.push(navUrl);
  };

  const getCurrentPage = () => {
    const isGeneralSettings = isMatchedPath(location.pathname, [
      `/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.GENERAL_SETTINGS}`,
      `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.GENERAL_SETTINGS}`,
    ]);
    const isLandingSettings = isMatchedPath(location.pathname, [
      `/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.LANDING_SETTINGS}`,
      `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.LANDING_SETTINGS}`,
    ]);
    const isBillingSettings = isMatchedPath(location.pathname, [
      `/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.BILLING_SETTINGS}`,
      `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.BILLING_SETTINGS}`,
    ]);

    const isPricingSettings = isMatchedPath(location.pathname, [
      `/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.PRICING_SETTINGS}`,
      `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.PRICING_SETTINGS}`,
    ]);

    const isTemplatesSettings = isMatchedPath(location.pathname, [
      `/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.TEMPLATES}`,
      `${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.TEMPLATES}`,
    ]);

    if (isGeneralSettings) return "General Information";
    if (isLandingSettings) return "Appearance";
    if (isBillingSettings) return "Subscription Details";
    if (isPricingSettings) return "Pricing Details";
    if (isTemplatesSettings) return "Templates";

    return "Community Settings";
  };

  const submitForm = () => {
    dispatch(changeCommunitySettingsForm(String(Date.now())));
  };

  return (
    <div className={classnames("community-settings-wrapper", { subscriptionAttention })}>
      <Header
        title={getCurrentPage()}
        subscriptionAttention={subscriptionAttention}
        className={classnames("settings-header", { active: !showNavigation })}
        onTitleClick={
          !showNavigation
            ? () =>
                getCurrentPage() === "Pricing Details"
                  ? changeSettingsPage(`${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.BILLING_SETTINGS}`)
                  : changeSettingsPage(NamesOfParentRoutes.COMMUNITY_SETTINGS)
            : void 0
        }
      >
        {!showNavigation &&
        getCurrentPage() !== "Subscription Details" &&
        getCurrentPage() !== "Pricing Details" &&
        getCurrentPage() !== "Templates" ? (
          <Button variant="blue" width={145} type="submit" onClick={() => submitForm()}>
            Save Changes
          </Button>
        ) : null}
      </Header>
      <div className="settings-content-wrapper">
        {showNavigation ? (
          <div className="navigation-wrapper">
            {SETTINGS_LINKS.map(item => {
              return (
                <div className="navigation-item" key={item.value} onClick={() => changeSettingsPage(item.value)}>
                  <div className="title">{item.name}</div>
                  <div className="chevron" />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <Page
              path={`/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.GENERAL_SETTINGS}`}
              exact
              title="General Settings"
              component={GeneralSettings}
            />
            <Page
              path={`${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.GENERAL_SETTINGS}`}
              exact
              title="General Settings"
              component={GeneralSettings}
            />
            <Page
              path={`/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.LANDING_SETTINGS}`}
              exact
              title="Landing Settings"
              component={LandingSettings}
            />
            <Page
              path={`${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.LANDING_SETTINGS}`}
              exact
              title="Landing Settings"
              component={LandingSettings}
            />
            <Page
              path={`${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.BILLING_SETTINGS}`}
              exact
              title="Subscription Details"
              component={SubscriptionDetails}
            />
            <Page
              path={`/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.BILLING_SETTINGS}`}
              exact
              title="Subscription Settings"
              component={SubscriptionDetails}
            />
            <Page
              path={`${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.PRICING_SETTINGS}`}
              exact
              title="Pricing Details"
              component={PaymentDetails}
            />
            <Page
              path={`/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.PRICING_SETTINGS}`}
              exact
              title="Pricing Settings"
              component={PaymentDetails}
            />
            <Page
              path={`/:communityCode${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.TEMPLATES}`}
              exact
              title="Templates"
              component={Templates}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CommunitySettings;
