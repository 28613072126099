import config from "../../config";
import { EEventChangeScope } from "../../containers/Sermons";

// export const BASE_HOST = "http://localhost:4000";
const BASE_URL = config.baseApiUrl;
const ANNOUNCEMENTS_URL = `${BASE_URL}/announcement`;

export default {
  LOGIN: `${BASE_URL}/auth/login`,
  SOCIAL_LOGIN: `${BASE_URL}/auth/social_login`,
  MAGIC_LOGIN: `${BASE_URL}/auth/magic-login`,
  FORGOT: `${BASE_URL}/auth/forgot`,
  RESET: `${BASE_URL}/auth/reset`,
  REGISTRATION: `${BASE_URL}/auth/registration`,
  USER_INFO: `${BASE_URL}/user`,

  USERS: `${BASE_URL}/user/all`,
  CREATE_USER_BY_EMAIL: `${BASE_URL}/user`,
  UPDATE_USER_BY_EMAIL: (id: number) => `${BASE_URL}/user/change-email/${id}`,
  UPDATE_USER: (id: number) => `${BASE_URL}/user/${id}`,
  CONFIRM_USER_EMAIL: (hash: string) => `${BASE_URL}/user/email-confirmation/${hash}`,
  DELETE_USER: (id: number) => `${BASE_URL}/user/${id}`,
  SEND_MAGIC_LINK: `${BASE_URL}/user/login-link`,
  COMMUNITIES: `${BASE_URL}/community`,
  COMMUNITY: (code: string) => `${BASE_URL}/community/${code}`,
  COMMUNITY_VIMEO_TOKEN: (code: string) => `${BASE_URL}/community/${code}/vimeoToken`,
  CHECK_VIMEO_TOKEN: (token: string) => `${BASE_URL}/community/checkVimeoConnection/${token}`,
  COMMUNITY_USERS: (communityId: number) => `${BASE_URL}/community/${communityId}/users`,
  COMMUNITY_LANDING_EVENTS: (communityId: number) => `${BASE_URL}/meeting/landing/${communityId}`,
  COMMUNITY_LANDING: (subdomain: string) => `${BASE_URL}/community/landing/${subdomain}`,
  COMMUNITY_LANDING_SETTINGS_SUBDOMAIN: `${BASE_URL}/community/landing_settings/subdomains`,
  COMMUNITY_LANDING_SETTINGS: (communityId: number) => `${BASE_URL}/community/landing_settings/${communityId}`,
  CREATE_COMMUNITY_LANDING_SETTINGS: `${BASE_URL}/community/landing_settings`,

  COMMUNITY_SUBSCRIPTIONS: (code: string) => `${BASE_URL}/stripe/community-subscriptions/${code}`,
  COMMUNITY_SUBSCRIPTION_UPGRADE: (code: string) => `${BASE_URL}/stripe/community-subscriptions/${code}`,
  COMMUNITY_STRIPE_ACCOUNT_LINK: `${BASE_URL}/stripe/create-billing-portal-session`,
  CREATE_STRIPE_CHECKOUT_SESSION: `${BASE_URL}/stripe/create-checkout-session`,
  ADD_PAYMENT_METHOD: `${BASE_URL}/stripe/add-payment-method`,
  CREATE_TRIAL_FOR_COMMUNITY_WITHOUT_SUBSCRIPTION: `${BASE_URL}/stripe/subscription`,

  CREATE_COMMUNITY: `${BASE_URL}/community`,
  UPDATE_COMMUNITY: (id: number | string) => `${BASE_URL}/community/${id}`,
  DELETE_COMMUNITY: (id: number) => `${BASE_URL}/community/${id}`,
  GET_COMMUNITIES_COUNT: `${BASE_URL}/community/count`,
  GET_COMMUNITY_STREAMING_STATISTICS: (id: number) => `${BASE_URL}/community/${id}/streaming`,
  GET_COMMUNITY_INTRODUCTION_STEPS: (community_id: number) => `${BASE_URL}/community/${community_id}/introduction`,
  COMPLETE_COMMUNITY_INTRODUCTION_STEP: (community_id: number) => `${BASE_URL}/community/${community_id}/introduction`,
  HOT_SPOT_FORM: (community_id: number) => `${BASE_URL}/community/feedback/${community_id}`,
  GET_MAU_DATA: (id: number) => `${BASE_URL}/community/${id}/mau`,
  FETCH_ANALYTICS: (id: number) => `${BASE_URL}/community/${id}/analytics`,

  CONFIRM_VERIFY: (token: string) => `${BASE_URL}/user/verify/${token}`,
  CONFIRM_VERIFY_BY_CODE: (code: string) => `${BASE_URL}/user/verifyByCode/${code}`,
  SEND_USER_CONFIRMATION_EMAIL: `${BASE_URL}/user/verify`,

  FETCH_CURRENT_MEMBER: (community_id: number) => `${BASE_URL}/member/${community_id}`,
  SEARCH_MEMBERS: `${BASE_URL}/member/search`,
  FETCH_MEMBER: (communityCode: string) => `${BASE_URL}/community/${communityCode}/member`,
  UPDATE_MEMBER: (id: number | string) => `${BASE_URL}/member/${id}`,
  FETCH_MEMBER_BY_CODE: (communityCode: string, memberCode: string) =>
    `${BASE_URL}/member/${communityCode}/${memberCode}`,
  FETCH_MEMBER_BY_ID_PUBLIC: (community_id: string, member_id: string) =>
    `${BASE_URL}/member/public/${community_id}/${member_id}`,
  LEAVE_MEMBER: (payload: { id: number; community_id: number }) =>
    `${BASE_URL}/community/${payload.community_id}/member/${payload.id}/leave`,
  REMOVE_MEMBER: (payload: { id: number; community_id: number }) =>
    `${BASE_URL}/community/${payload.community_id}/member/${payload.id}`,
  REMOVE_MEMBERS: (community_id: number, queryString: string) =>
    `${BASE_URL}/community/${community_id}/member${queryString}`,
  MEMBER: `${BASE_URL}/member`,
  FETCH_MEMBER_BY_EMAIL: `${BASE_URL}/member/email`,
  CONNECT_MEMBER: `${BASE_URL}/member/connect`,
  REGISTER_MEMBER: `${BASE_URL}/member/register`,
  RESTRICT_MEMBER: `${BASE_URL}/member/restriction`,
  FETCH_RESTRICTED_MEMBERS: (event_id: number) => `${BASE_URL}/member/${event_id}/restriction`,
  DELETE_MEMBER_RESTRICTION: (member_id: number, event_id: number) =>
    `${BASE_URL}/member/${member_id}/${event_id}/restriction`,
  FETCH_MEETING_MEMBERS: (meetingCode: string) => `${BASE_URL}/meeting/${meetingCode}/members`,
  SEND_PRIVATE_INVITATIONS: `${BASE_URL}/community/sendInvitations`,

  FETCH_COMMUNITY_INVITATION: (communityCode: string) => `${BASE_URL}/invitation/community/${communityCode}`,
  FETCH_COMMUNITY_BY_HASH: (hash: string) => `${BASE_URL}/invitation/${hash}`,
  FETCH_USER_BY_EMAIL: (email: string) => `${BASE_URL}/user/check/${email}`,
  CHECK_EMAIL_IS_CONFIRMED: (hash: string) => `${BASE_URL}/checkConfirmedEmail/${hash}`,
  CREATE_PROFILE: (hash: string) => `${BASE_URL}/invitation/${hash}`,
  CHECK_USER_VERIFICATION: (userId: number) => `${BASE_URL}/user/verified/${userId}`,
  CHECK_IS_USER_CLAIMED: (userId: number, communityId: number) =>
    `${BASE_URL}/user/${userId}/community/${communityId}/claimed`,
  JOIN_TO_NEW_COMMUNITY: (hash: string) => `${BASE_URL}/invitation/join/${hash}`,
  FETCH_INVITATION_DATE: (communityId: number, memberId: number) =>
    `${BASE_URL}/invitation/community/${communityId}/member/${memberId}`,
  FETCH_PERSONAL_INVITATION: (communityCode: string, memberCode: string) =>
    `${BASE_URL}/invitation/personal/${communityCode}/${memberCode}`,
  GET_COMMUNITY_APP_LINK: (communityCode: string) => `${BASE_URL}/community/${communityCode}/applink`,

  FETCH_SERMONS: (community_id: number) => `${BASE_URL}/meeting/list/${community_id}`,
  FETCH_SERMON: (eventCode: string, relations?: string[]) =>
    `${BASE_URL}/meeting/byCode/${eventCode}${relations ? `?relations=${relations.join()}` : ""}`,
  CHECK_AVAILABLE_EVENT_SLOT: `${BASE_URL}/meeting/timeslot/check`,
  UPLOAD_MEETING_SLIDES_FILES: (community_id: number) => `${BASE_URL}/meeting/slidesFiles/${community_id}`,
  CREATE_SERMON: `${BASE_URL}/meeting`,
  UPDATE_SERMON: (id: number, scope?: EEventChangeScope) =>
    `${BASE_URL}/meeting/${id}${scope ? `?scope=${scope}` : ""}`,
  START_EVENT: (id: number) => `${BASE_URL}/meeting/${id}/start`,
  REMOVE_SERMON: (id: number, scope?: EEventChangeScope) =>
    `${BASE_URL}/meeting/${id}${scope ? `?scope=${scope}` : ""}`,
  DUPLICATE_SERMON: `${BASE_URL}/meeting/duplicate`,
  GENERATE_SIGNATURE: `${BASE_URL}/meeting/sign`,
  FETCH_PRESENTERS_CODES: `${BASE_URL}/meeting/codes`,
  LOG_DATA: `${BASE_URL}/logs`,
  FETCH_POLLS: `${BASE_URL}/poll`,
  CREATE_POLL: `${BASE_URL}/poll`,
  UPDATE_POLL: `${BASE_URL}/poll`,
  DELETE_POLL: (poll_id: number) => `${BASE_URL}/poll/${poll_id}`,
  POLL_VOTE: `${BASE_URL}/poll/vote`,
  POLL_READ: `${BASE_URL}/poll/read`,
  FETCH_POLL_TEMPLATES: `${BASE_URL}/poll/templates`,
  CREATE_POLL_TEMPLATE: `${BASE_URL}/poll/templates`,
  UPDATE_POLL_TEMPLATE: (pollTemplate_id: number) => `${BASE_URL}/poll/templates/${pollTemplate_id}`,
  DELETE_POLL_TEMPLATE: (pollTemplate_id: number) => `${BASE_URL}/poll/templates/${pollTemplate_id}`,

  MEMBER_DISCUSSION_TOKEN: (community_id: number) => `${BASE_URL}/chat/token/${community_id}`,
  ANONYMOUS_DISCUSSION_TOKEN: `${BASE_URL}/chat/token/anonymous`,
  CREATE_NEW_CHAT: (community_id: number) => `${BASE_URL}/chat/channel/${community_id}`,
  CREATE_ANONYMOUS_CHANNEL_WITH_GREETER: (meeting_id: number) => `${BASE_URL}/chat/channel/anonymous/${meeting_id}`,
  CREATE_GENERAL_CHANNEL: `${BASE_URL}/chat/channel/general`,
  FETCH_CHANNELS: (community_id: number) => `${BASE_URL}/chat/channels/${community_id}`,
  FETCH_ANONYMOUS_CHANNELS: `${BASE_URL}/chat/channels/anonymous`,
  GET_CHANNEL_DETAILS: (id: string) => `${BASE_URL}/chat/channel/${id}`,
  LEAVE_FROM_CHAT: (id: string) => `${BASE_URL}/chat/channel/leave/${id}`,
  UPDATE_CHANNEL: (community_id: number, id: string) => `${BASE_URL}/chat/channel/${community_id}/${id}`,
  DELETE_CHANNEL: (community_id: number, channel_id: string) =>
    `${BASE_URL}/chat/channel/delete/${community_id}/${channel_id}`,
  JOIN_CHANNEL: (community_id: number, channel_id: string) =>
    `${BASE_URL}/chat/channel/join/${community_id}/${channel_id}`,
  ADD_MODERATORS: `${BASE_URL}/chat/channel/moderators`,
  FETCH_EVENT_INTEGRATIONS: (eventCode: string) => `${BASE_URL}/meeting/${eventCode}/integrations`,
  FETCH_EVENT_SHORT_LINK: (eventCode: string, avoidInstApp: boolean) =>
    `${BASE_URL}/meeting/${eventCode}/shortLink?avoidInstApp=${avoidInstApp}`,
  GET_RECURRING_EVENT: (eventIdentifier: number | string) => `${BASE_URL}/meeting/recurring/${eventIdentifier}`,
  GENERATE_EIGHT_TO_EIGHT_TOKEN: `${BASE_URL}/meeting/eightToeight/createToken`,
  GET_CLASSROOMS: (meeting_id: number) => `${BASE_URL}/meeting/classrooms/${meeting_id}`,

  UPDATE_ROOM: (id: number | string) => `${BASE_URL}/room/${id}`,

  GET_TOKEN_BY_HASH: (hash: string) => `${BASE_URL}/auth/token/${hash}`,

  CREATE_FILE: `${BASE_URL}/file`,
  UPDATE_FILE: (id: number) => `${BASE_URL}/file/${id}`,
  DELETE_FILE: (id: number) => `${BASE_URL}/file/${id}`,

  FETCH_ANNOUNCEMENTS: `${ANNOUNCEMENTS_URL}`,
  CREATE_ANNOUNCEMENT: `${ANNOUNCEMENTS_URL}`,
  UPDATE_ANNOUNCEMENT: (id: number) => `${ANNOUNCEMENTS_URL}/${id}`,
  DELETE_ANNOUNCEMENT: (id: number) => `${ANNOUNCEMENTS_URL}/${id}`,
  READ_ANNOUNCEMENTS: `${ANNOUNCEMENTS_URL}/read`,

  FETCH_ANNOUNCEMENT_TEMPLATES: `${ANNOUNCEMENTS_URL}/templates`,
  CREATE_ANNOUNCEMENT_TEMPLATE: `${ANNOUNCEMENTS_URL}/templates`,
  UPDATE_ANNOUNCEMENT_TEMPLATE: (template_id: number) => `${ANNOUNCEMENTS_URL}/templates/${template_id}`,
  DELETE_ANNOUNCEMENT_TEMPLATE: (template_id: number) => `${ANNOUNCEMENTS_URL}/templates/${template_id}`,

  DOWNLOAD_PARTICIPANTS: (eventId: number) => `${BASE_URL}/meeting/${eventId}/participants`,
  DOWNLOAD_ANALYTICS_REPORT: (id: number) => `${BASE_URL}/community/analytics/${id}/reports`,
};
