import { createAsyncAction } from "typesafe-actions";
import { Stripe } from "@stripe/stripe-js";

import { StripeActionTypes } from "./constants";
import { AddPaymentMethodOptions, RedirectToCheckoutOptions } from "../../interfaces/Stripe";

export const loadStripe = createAsyncAction(
  StripeActionTypes.LOAD_REQUEST,
  StripeActionTypes.LOAD_SUCCESS,
  StripeActionTypes.LOAD_FAIL,
  StripeActionTypes.LOAD_CLEAR,
)<string, Stripe | null, undefined, undefined>();

export const redirectToCheckout = createAsyncAction(
  StripeActionTypes.REDIRECT_TO_CHECKOUT_REQUEST,
  StripeActionTypes.REDIRECT_TO_CHECKOUT_SUCCESS,
  StripeActionTypes.REDIRECT_TO_CHECKOUT_FAILURE,
)<RedirectToCheckoutOptions, undefined, string>();

export const addPaymentMethod = createAsyncAction(
  StripeActionTypes.ADD_PAYMENT_METHOD,
  StripeActionTypes.ADD_PAYMENT_METHOD_SUCCESS,
  StripeActionTypes.ADD_PAYMENT_METHOD_FAILURE,
)<AddPaymentMethodOptions, undefined, string>();
