import React from "react";
import { useTranslation } from "react-i18next";

import { INotification } from "../../interfaces";
import "./notistackContainer.scss";

const NotistackContent: React.FunctionComponent<INotification> = ({ title, message, variant }) => {
  const { t } = useTranslation();

  return (
    <div className="notificationContent">
      <p className="title">{title ? t(title) : variant === "error" ? "Error occurred" : "Success"}</p>
      <p className="message">{t(message || "")}</p>
    </div>
  );
};

export default NotistackContent;
