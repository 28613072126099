import React from "react";

interface ICanView {
  condition: boolean;
  children: React.ReactNode;
  hideWithDisplay?: boolean;
}

const CanView = (props: ICanView) => {
  const { children, condition, hideWithDisplay } = props;
  if (!hideWithDisplay) {
    return condition ? <>{children}</> : null;
  } else {
    return (
      <div
        style={{
          display: condition ? "block" : "none",
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </div>
    );
  }
};

export default CanView;
