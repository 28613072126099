import { AnyAction } from "redux";
import { call, fork, put, take, takeLatest, takeLeading } from "redux-saga/effects";
import { EventChannel } from "redux-saga";
import { Socket } from "socket.io-client";

import history from "../../../shared/history/history";
import { startLoading, stopLoading } from "../../App/store/actions";
import api from "../api";
import { LandingActionTypes } from "./constants";

import { actions } from "./";

import { createSocket, createSocketChannel } from "shared/utils/socket";
import { IEvent } from "containers/Sermons";
import { getCommunity } from "containers/Community/store/actions";
import { ICommunity, ICommunityLandingSettings } from "containers/Community";
import { prepareSchedulers } from "utils/functions";

interface IWSAction {
  method: string;
  args: any[];
}

function* loadLandingSettings({
  payload: { subdomain, redirectTo },
}: ReturnType<typeof actions.loadLandingSettings.request>) {
  try {
    yield put(startLoading());

    const { settings, community }: { settings: ICommunityLandingSettings; community: ICommunity } = yield call(
      api.getLandingSettings,
      subdomain,
    );
    yield put(actions.loadLandingSettings.success(settings));
    yield put(getCommunity.success(community));
  } catch (e: any) {
    yield put(actions.loadLandingSettings.failure());
    if (redirectTo) {
      history.push(redirectTo);
    }
  } finally {
    yield put(stopLoading());
  }
}

function* loadLandingEvents({ payload }: ReturnType<typeof actions.loadLandingEvents.request>) {
  try {
    yield put(startLoading());

    const { scheduleEvents, count }: { scheduleEvents: IEvent[]; count: number } = yield call(
      api.loadLandingEvents,
      payload,
    );
    const schedule = prepareSchedulers(scheduleEvents);
    if (payload.params.schedule_direction === "upcoming") {
      yield put(actions.loadLandingEvents.success(schedule));
      yield put(actions.setEventsCount(count));
    } else {
      yield put(actions.setPastEvents(schedule));
      yield put(actions.setPastEventsCount(count));
    }
  } catch (e: any) {
    yield put(actions.loadLandingEvents.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* startSocketCommunity({ payload }: AnyAction) {
  const { communityId, callback } = payload;
  let socket: Socket;
  let socketChannel: EventChannel<any>;
  try {
    yield fork(createSocket, `community_${communityId}`, actions);
    const socketAction: AnyAction = yield take([
      LandingActionTypes.CLOSE_SOCKET,
      LandingActionTypes.SOCKET_INITIALIZED,
      LandingActionTypes.SOCKET_INITIALIZED_FAILURE,
    ]);
    if (socketAction.type === LandingActionTypes.SOCKET_INITIALIZED_FAILURE) {
      throw socketAction.payload;
    }
    if (socketAction.type === LandingActionTypes.CLOSE_SOCKET) {
      const socketInitializedAction: AnyAction = yield take(LandingActionTypes.SOCKET_INITIALIZED);
      return socketInitializedAction.payload.close();
    } else {
      socket = socketAction.payload;
    }
    yield put(actions.connectSocket());
    socketChannel = yield call(createSocketChannel, socket);
    callback && callback();
    while (true) {
      const income: IWSAction = yield take(socketChannel);
      if (income) {
        const { method, args } = income;

        switch (method) {
          case "eventCreated": {
            const eventData = args[0];
            yield put(actions.wsEventCreated(eventData as IEvent));
            break;
          }
          case "eventUpdated": {
            const eventData = args[0];
            if (!eventData.is_archived) {
              yield put(actions.wsSetEventStatus(eventData as IEvent));
            } else {
              yield put(actions.wsSetEventArchived(eventData as IEvent));
            }
            break;
          }
          default:
            // eslint-disable-next-line no-console
            console.warn(`Unknown event ${method}`);
        }
      }
    }
  } catch (error: any) {
    const message = `[BROWSER][COMMUNITY_SOCKET] socket.io: ${error}`;
    // eslint-disable-next-line no-console
    console.log(message);
  }
}

function* landingSaga() {
  yield takeLatest(actions.loadLandingSettings.request, loadLandingSettings);
  yield takeLatest(actions.loadLandingEvents.request, loadLandingEvents);
  yield takeLeading(LandingActionTypes.OPEN_SOCKET, startSocketCommunity);
}
export default landingSaga;
