import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { usePrevious } from "../PreviousHook";

import { EventStatus } from "containers/Sermons";
import { getAllowEnded, getChangingStatus, getEventStatus } from "containers/Sermons/store/selectors";

const streamStatuses = [EventStatus.live, EventStatus.scheduled];
const lobbyStatuses = [EventStatus.lobby];

export const useStreamStatuses = (): [boolean, EventStatus | null] => {
  const [prevEventStatus, setPrevEventStatus] = useState<EventStatus | null>(null);

  const eventStatus = useSelector(getEventStatus());
  const allowEnded = useSelector(getAllowEnded());
  const changingStatus = useSelector(getChangingStatus());
  const [isStream, setIsStream] = useState(false);

  const prev = usePrevious(eventStatus);

  useEffect(() => {
    if (!!prev && !!eventStatus) {
      setPrevEventStatus(prev);
    }
    // eslint-disable-next-line
  }, [eventStatus]);

  useEffect(() => {
    if (eventStatus) {
      setIsStream(
        (streamStatuses.includes(eventStatus) && !changingStatus) ||
          (lobbyStatuses.includes(eventStatus) &&
            !!prevEventStatus &&
            streamStatuses.includes(prevEventStatus) &&
            changingStatus) ||
          (eventStatus === EventStatus.ended &&
            !!prevEventStatus &&
            streamStatuses.includes(prevEventStatus) &&
            allowEnded),
      );
    }
  }, [eventStatus, prevEventStatus, allowEnded, changingStatus]);
  return [isStream, prevEventStatus];
};
