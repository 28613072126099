import physical_digital_guide from "assets/images/ecamm/guides/physical_digital.png";
import speedify_sponsor from "assets/images/ecamm/sponsors/speedify_sponsor.png";
import ecamm_sponsor from "assets/images/ecamm/sponsors/ecamm_sponsor.png";
import stampede_sponsor from "assets/images/ecamm/sponsors/stampede_sponsor.png";
import castmagic_sponsor from "assets/images/ecamm/sponsors/castmagic_sponsor.png";
import shure_sponsor from "assets/images/ecamm/sponsors/shure_sponsor.png";
import obsbot_sponsor from "assets/images/ecamm/sponsors/obsbot_sponsor.png";
import fuljens_henry from "assets/images/ecamm/speakers/fuljens_henry.png";
import laura_davidson from "assets/images/ecamm/speakers/laura_davidson.png";
import doc_rock from "assets/images/ecamm/speakers/doc_rock.png";
import ana_gil from "assets/images/ecamm/speakers/ana_gil.png";
import cat_mulvihill from "assets/images/ecamm/speakers/cat_mulvihill.png";
import david_cruwys from "assets/images/ecamm/speakers/david_cruwys.png";
import jeff_dwoskin from "assets/images/ecamm/speakers/jeff_dwoskin.png";
import liz_wilcox from "assets/images/ecamm/speakers/liz_wilcox.png";
import kate_fawkes from "assets/images/ecamm/speakers/kate_fawkes.png";
import michelle_williams from "assets/images/ecamm/speakers/michelle_williams.png";
import kirk_nugent from "assets/images/ecamm/speakers/kirk_nugent.png";
import martin_mcKenna from "assets/images/ecamm/speakers/martin_mcKenna.png";
import paul_gowder from "assets/images/ecamm/speakers/paul_gowder.png";
import rob_balasabas from "assets/images/ecamm/speakers/rob_balasabas.png";
import tanya_smith from "assets/images/ecamm/speakers/tanya_smith.png";
import stephanie_garcia from "assets/images/ecamm/speakers/stephanie_garcia.png";
import liza_kraff from "assets/images/ecamm/speakers/liza_kraff.png";
import matt_haas from "assets/images/ecamm/speakers/matt_haas.png";
import luria_petrucci from "assets/images/ecamm/speakers/luria_petrucci.png";
import kendra_losee from "assets/images/ecamm/speakers/kendra_losee.png";
import leslie_samuel from "assets/images/ecamm/speakers/leslie_samuel.png";
import andrew_moon from "assets/images/ecamm/speakers/andrew_moon.png";
import erik_fisher from "assets/images/ecamm/speakers/erik_fisher.png";
import alec_johnson from "assets/images/ecamm/speakers/alec_johnson.png";
import joe_casabona from "assets/images/ecamm/speakers/joe_casabona.png";
import india_delgado from "assets/images/ecamm/speakers/india_delgado.png";
import yvonne_heimann from "assets/images/ecamm/speakers/yvonne_heimann.png";
import phil_pallen from "assets/images/ecamm/speakers/phil_pallen.png";
import jeff_sieh from "assets/images/ecamm/speakers/jeff_sieh.png";
import greg_wasserman from "assets/images/ecamm/speakers/greg_wasserman.png";
import alex_gizis from "assets/images/ecamm/speakers/alex_gizis.png";

export const ECAMM_COMMUNITY_ID =
  process.env.REACT_APP_ENV === "production"
    ? 2423
    : ["qa", "test"].includes(process.env.REACT_APP_ENV || "")
    ? 234
    : 1602;

export const GRAB_FREE_PASS_LINK = "https://leap.ecamm.com/";

export const GUIDES = [
  {
    img: physical_digital_guide,
    title: "Leap into Tools & Tactics Guidebook",
    description:
      "Looking for the perfect companion this event? Grab the Leap into Tools + Tactics digital guidebook. This all-encompassing Notion guide includes tips, tricks, and resources from experts. Plus this guide unlocks access to all the replay videos from this year and all past Leap Into Live events. That's over 300 hours of expert content about live video creation, selling, and podcasting!",
    price: "$45.00",
    link: "https://events.ecamm.com/products/leap-guidebook",
  },
];

export const SPONSORS = [
  {
    img: ecamm_sponsor,
    title: "Ecamm",
    description: "",
    link: "https://www.ecamm.live/",
    style: { borderRadius: "12px" },
  },
  {
    img: shure_sponsor,
    title: "Shure",
    description: "",
    link: "https://www.shure.com/",
    style: {},
  },
  {
    img: stampede_sponsor,
    title: "Stampede Social",
    description: "",
    link: "https://www.stampede.social/",
    style: { borderRadius: "12px" },
  },
  {
    img: castmagic_sponsor,
    title: "Castmagic",
    description: "",
    link: "https://www.castmagic.io/",
    style: { borderRadius: "12px" },
  },
  {
    img: obsbot_sponsor,
    title: "OBSBOT",
    description: "",
    link: "https://www.obsbot.com/",
    style: { borderRadius: "12px" },
  },
  {
    img: speedify_sponsor,
    title: "Speedify",
    description: "",
    link: "https://www.speedify.com/",
    style: { borderRadius: "12px" },
  },
];

export const KEYNOTE_SPEAKERS = [
  {
    img: stephanie_garcia,
    name: "Stephanie Garcia",
    title: "",
    event_names: ["Creators: The Next $1B Business"],
    description:
      "Stephanie Garcia is the founder of Captivate on Command® and the host of Lights, Camera, Live® where she helps brands succeed on camera. As a Master Neuro Linguistic Programming (NLP)  Practitioner, Trainer, and ad agency veteran, Stephanie combines her marketing experience to help individuals communicate with confidence so they can ignite their ideas and be brilliant for prospects and customers alike. Named one of the Top 50 Digital Marketing Thought Leaders by the University of Missouri St. Louis, her work has been recognized and awarded by Forbes, Online Marketing Media And Advertising, PR Daily, Forrester, and Gartner 1to1 Media. Stephanie is the host of Lights, Camera, Live, and the co-founder of Leap Into Live Streaming Bootcamp. She has spoken at Social Media Marketing World, VidCon, Podcast Movement, and many more. Stephanie is the co-author of The Ultimate Guide to Social Media, published by Entrepreneur Press. She lives in San Diego, CA.",
  },
  {
    img: leslie_samuel,
    name: "Leslie Samuel",
    title: "",
    event_names: ["KEYNOTE: Automate Content Creation"],
    description:
      'Leslie Samuel is a business coach for high-performing entrepreneurs. As the host of the podcast Learning with Leslie, he guides entrepreneurs in making an impact from home, at home, and beyond. "Changing the world one person at a time” is the mission he strives towards. As a former university professor, he has a passion for education. He founded Interactive Biology, a blog and YouTube channel dedicated to making biology fun for students and teachers with now over 280K subscribers.',
  },
  {
    img: luria_petrucci,
    name: "Luria Petrucci",
    title: "",
    event_names: ["KEYNOTE: More Audience Engagement"],
    description:
      "Luria helps entrepreneurs unleash their authentic self through professional videos and live streams. For 18 years, Luria has created over 6,000 videos with over 1 Billion views using video and live streaming shows. She's appeared on CNN, FOX, NBC, MSNBC, ABC & BBC. She’s worked on LIVE Video for top brands like AT&T, Samsung, GoDaddy, and Panasonic. Live Streaming Pros has built LIVE video studios for top influencers like Amy Porterfield, Pat Flynn, Ryan Levesque, Donald Miller, Stu McLaren & Michael Hyatt.",
  },
];

export const MAIN_SPEAKERS = [
  {
    img: ana_gil,
    name: "ana gil",
    title: "",
    event_names: ["Let's Practice! Coworking time"],
    description:
      "ana is the owner and CEO of ana gil studios, which specializes in headshots photography and video production. Her mission is to empower women and businesses to leverage the power of video marketing to promote themselves and their businesses. She has worked with major companies like Micron Technology, Ecamm Network, and The Jim and Juli Boeheim Foundation. ana also hosts and produces her webcast, officiallyunofficialtv, which promotes local events and women. Fun fact: ana writes her name in lowercase.",
  },
  {
    img: doc_rock,
    name: "Doc Rock",
    title: "",
    event_names: ["Welcome to Leap - Day 1", "Welcome to Leap - Day 2", "Welcome to Leap - Day 3"],
    description:
      "Doc Rock is on a mission to positively touch a billion people’s lives by collaborating and sharing stories and ideas. As the Community Manager at Ecamm, he works with content creators, live streamers, podcasters, and video producers to help them find their voice and share their passion through video. ",
  },
  {
    img: kate_fawkes,
    name: "Katie Fawkes",
    title: "",
    event_names: [
      "Welcome to Leap - Day 1",
      "Welcome to Leap - Day 2",
      "Welcome to Leap - Day 3",
      "Let's Practice! Coworking time ",
    ],
    description:
      "Katie Fawkes is the Director of Marketing at Ecamm where she gets to work alongside some of the most amazing live streamers, podcasters, and content creators out there, helping them reach their goals and grow their business through video and live streaming. In addition to heading up Ecamm’s Marketing team, Katie is also the co-host of the video podcast, The Flow. She’s been working in the world of Marketing for her entire career and the coolest thing about her is that she keeps backyard chickens.",
  },
  {
    img: kendra_losee,
    name: "Kendra Losee",
    title: "",
    event_names: ["VIBES Check"],
    description:
      "Kendra Losee delivers smarter marketing strategies to entrepreneurs and business owners seeking peace of mind in their ventures. With over 25 years of digital marketing expertise, an MBA, and experience as a social media marketing professor, Kendra guides her clients toward boosting their revenue with confidence and clarity. Her approach is built around three key areas: People Strategies, Communication Strategies, and Tactical Strategies. These pillars are critical for business owners to effectively connect with their audience, communicate their unique value, and execute their marketing plans with precision. As a certified NLP master practitioner, Kendra excels at breaking down mental barriers. Her strategic insight and straightforward teaching method equip clients with the tools for real success. Discover the possibilities at kendralosee.com.",
  },
  {
    img: kirk_nugent,
    name: "Kirk Nugent",
    title: "",
    event_names: ["3 Unorthodox Approaches "],
    description:
      "The “Geek Speaker Preacher,” Kirk R. Nugent, uses his expertise in the ever-changing, technological world to empower businesses and leaders to gain visibility and authority through live video marketing. As an accomplished speaker, content creator, and live stream coach, he continues to be the go-to consultant for everyone’s brand needs.",
  },
  {
    img: matt_haas,
    name: "Matt Haas",
    title: "",
    event_names: ["Effective File Management"],
    description: "Matt is a web developer, graphic designer, woodworker, YouTuber, father, husband & all around geek!",
  },
  {
    img: cat_mulvihill,
    name: "Cat Mulvihill",
    title: "",
    event_names: ["Seamless Content Creation"],
    description:
      "Cat Mulvihill is a skilled trainer & speaker who teaches people how to create more engaging and professional online presentations. She has over 15 years of experience leading workshops and programs, and after being forced to transition to the virtual format, Cat learned how to leverage tools and techniques to stand out as an virtual presenter. She is also a Notion Ambassador and loves to help other creators and solopreneurs stay organized with Notion.",
  },
  {
    img: tanya_smith,
    name: "Tanya Smith",
    title: "",
    event_names: ["From Chaos to Content Genius"],
    description:
      "Tanya Smith, founder of Get Noticed with Video, is a premier video coach, savvy business strategist, and the quietly charismatic host of two video podcasts: Stream Like a Boss® TV and App Therapy Club.  Tanya is among some of the most sought-after coaches in content marketing, known for her soothing voice, magnetic stage presence and cutting-edge engagement strategies and software recommendations.",
  },
  {
    img: laura_davidson,
    name: "Laura Davidson",
    title: "",
    event_names: ["Choosing the Right Tech"],
    description:
      "Laura Clapp Davidson heads up the market development team for Shure where she brings passion and knowledge of gear that comes from over 20 years in the music industry. When she isn’t talking about microphones, she’s singing or speaking into them as a professional singer/songwriter and podcaster. Learn more at www.lauraclapp.com and listen to her podcast, Song 43 at www.song43.com.",
  },
  {
    img: fuljens_henry,
    name: "Fuljens Henry",
    title: "",
    event_names: ["Let's Practice! Coworking time"],
    description:
      "Fuljens Henry is a video producer and assistant photographer at ana gil studios, known for his expertise in video production and community development. As a Brooklyn native with Haitian roots, Fuljens brings a unique perspective to his work in the community service sector. Holding the designation of a Certified Nonprofit Executive (CNE), he leverages over 12 years of nonprofit management experience. His leadership and vision, informed by his personal journey and cultural background, have directly contributed to the betterment of various communities, particularly in improving access to essential health and social services for communities of color. Fuljens produced live video and hybrid events for a range of renowned organizations in NYC, including the NYC Mayor's Office, Brooklyn Public Library, and the West Indian American Day Carnival Association (WIADCA). Currently, under his role with ana gil studios, he extends his expertise to working with leading companies in Central New York, demonstrating his versatile ability to engage and impact diverse audiences through the power of video.",
  },
  {
    img: david_cruwys,
    name: "David Cruwys",
    title: "",
    event_names: ["Instant YouTube Automation"],
    description:
      "David, aka AppyDave, has been a technologist for  30 years, contributing to numerous tech startups. He specializes in transforming business operations with ChatGPT, focusing on prompt engineering, ChatGPT & GPT automation. Through AppyCast, David empowers entrepreneurs by sharing advanced AI strategies for content creation and workflow automation. He is committed to educating business owners on effective GPT utilization for their growth and team efficiency.",
  },
  {
    img: martin_mcKenna,
    name: "Martin McKenna",
    title: "",
    event_names: ["Say Goodbye to Boring Presentations"],
    description:
      "Martin started out on an IT Help Desk and almost by accident this became his career. He has been a Team Leader, Trainer, Project Manager and award winning Sales guy, implementing very large and complex business solutions world wide. He then went on to run his own hugely successful face to face IT events company using only LInkedIn for all promotion and marketing. When Covid hit it was time to pivot and that's when Ecamm became the primary delivery mechanism for all B2B activities. He has used Ecamm creatively to showcase products,  record video proposals, engage prospects and colleagues with on-line meetings that look different and attract attention plus he is a super active Ecamm community member who loves to share new ideas and push the traditional boundaries.",
  },
  {
    img: liz_wilcox,
    name: "Liz Wilcox",
    title: "",
    event_names: ["Getting People to Open Your Emails"],
    description:
      "The Fresh Princess of Email Marketing, Liz Wilcox is an Email Strategist and Keynote Speaker showing small businesses how to build online relationships + make real money with emails. She’s best known for selling a blog, turning a $9 offer into multiple six-figures (without ads), and helping you untangle the email “knot” with her simple framework, the Email Staircase. She loves the 90s, headbands, and the beach. \n",
  },
  {
    img: jeff_sieh,
    name: "Jeff Sieh",
    title: "",
    event_names: ["Beyond the Stream"],
    description:
      "Jeff is an international speaker and visual marketing consultant.  He hosts the Social Media News Live show and podcast and is also “Head Beard” at Manly Pinterest Tips.  Jeff has worked with and produced a wide range of content for various companies.",
  },
  {
    img: rob_balasabas,
    name: "Rob Balasabas",
    title: "",
    event_names: ["The Minimalist Content Creator"],
    description:
      'Rob Balasabas serves as the Head of Creator Partnerships & Community at Uscreen, overseeing all things from Influencer Marketing, Sponsorships, and Content Collabs, as well as overseeing the Uscreen Community Team. As a passionate content creator himself, Rob shares tutorial videos and live streams on his personal YouTube channel (with over 1 Million Views to date) and social profiles and hosts the "Coffee with Content Creators" podcast. An advocate for content creators, Rob frequently speaks at conferences on monetization, revenue stream development, and creator economy trends. He speaks at various conferences, including VidSummit, FinCon, Podfest, Social Media Marketing World, The Brief by The Marketing Brew, and more. Rob is based in Vancouver, Canada where he lives with his family. He enjoys exploring new places with his wife & kids and savouring a good cup of coffee.',
  },
  {
    img: michelle_williams,
    name: "Michelle Williams",
    title: "",
    event_names: ["Creating an Engaging Show Flow"],
    description:
      "In the ever-evolving landscape of education, where the quest for effective professional development (PD) seems endless, one visionary has been steadily making waves — Michelle. With 24 years of teaching experience under her belt, Michelle has transformed the traditional PD narrative for teachers worldwide. Leveraging her deep understanding of educators' needs and the challenges they face, she has become a beacon of innovation and support in the community. At the heart of Michelle's mission is a profound commitment to helping teachers monetize their expertise through virtual workshops.",
  },
  {
    img: liza_kraff,
    name: "Liza Kraff",
    title: "",
    event_names: ["How to Use Scenery with Ecamm"],
    description:
      "Liza is the Head of Customer Success at Scenery.Video. Liza helps Scenery customers make the best use of Scenery's web-based collaborative video editor which is designed to speed up and simplify the video workflow for teams. Liza is a video editor through and through, formerly working as a Video Producer and Editor at NBC Sports. She is excited to show the Ecamm Fam Scenery and dive into the magic of fast, collaborative editing.",
  },
  {
    img: erik_fisher,
    name: "Erik Fisher",
    title: "",
    event_names: ["Most Out of Podcast Interviews"],
    description:
      "Erik Fisher is producer and host of the long-running Beyond The To-Do List Podcast. For over 11 years, Erik has talked with productivity experts as they share how they implement productivity strategies in their personal and professional lives. His mission is to explore all aspects of productivity as a means towards the true end goal: living a meaningful life.",
  },
  {
    img: alec_johnson,
    name: "Alec Johnson",
    title: "",
    event_names: ["Mastering Stream Deck"],
    description:
      "As the founder of Take One Tech, Alec's mission is to help you become more productive, impactful, and professional with tech, so you can confidently present yourself and your content online.",
  },
  {
    img: joe_casabona,
    name: "Joe Casabona",
    title: "",
    event_names: ["Turn Your Video into a Podcast "],
    description:
      "Joe Casabona is podcast systems coach who helps busy solopreneurs take back their time. Some even say he perfectly blend content creation and technology like it’s the best cup of coffee you’ve ever had (he says that). Joe's strategies come from his many years of experience: over 10 years creating podcasts, more than 15 years teaching, and over 20 years as a web developer.",
  },
  {
    img: india_delgado,
    name: "India Delgado",
    title: "",
    event_names: ["Optimizing Studio Spaces"],
    description:
      "India has always had a passion for anything tech, from cameras to minidiscs, to palm pilots, and anything else in between. Born and raised in Brooklyn, NY eventually moving her way to the next best city, Philadelphia! India started her tech gear journey working at RadioShack. She loved being amongst the first to test out the new gear and getting to know the ins and outs of the product so that she could help her clients take full advantage of their purchases! An instructor, coach and motivator at heart, India transitioned her career to banking where she taught financial literacy courses to First Time Homebuyers to help them achieve the dream of homeownership. In 2020 the pandemic hit, and she had to pivot her approach on delivering the seminars. She found the Ecamm Live community and that is how she officially began her content creating and video production agency.",
  },
  {
    img: yvonne_heimann,
    name: "Yvonne Heimann",
    title: "",
    event_names: ["Repeatable is Unbeatable"],
    description:
      "Yvonne Heimann is the CEO & founder of AskYvi.com and the leading ClickUp evangelist, as well as a passionate Business Efficiency & Scalability Consultant, Mindset Coach, NLP Master Practitioner, & Speaker. Using her knowledge of over 15 years running multiple businesses, she helps her clients organize, strengthen, and streamline their businesses into profitability and success. Her work is driven by a vision of a world where digital entrepreneurs not only make a living doing what they are passionate about but thrive and wear their passions as badges of honor.",
  },
  {
    img: phil_pallen,
    name: "Phil Pallen",
    title: "",
    event_names: ["You Should Be Using These AI Tools!"],
    description:
      "Phil Pallen is a personal branding expert and keynote speaker. His non-conventional approach to digital marketing and talent for social media has built him a global audience. As a brand strategist, Phil has advised hundreds of brands from over 30 countries, including a Shark on Shark Tank, a Nobel Peace Prize winner, politicians, and some of the most important names in entertainment. A digital nomad and globetrotter, Phil has delivered speeches on five different continents and frequently appears as an expert contributor in media outlets around the world, including CNN, Access Hollywood, and The Daily Mail.",
  },
  {
    img: jeff_dwoskin,
    name: "Jeff Dwoskin",
    title: "",
    event_names: ["How to Sell on Instagram"],
    description:
      "Jeff Dwoskin is a tech entrepreneur, podcaster, and professional stand-up comedian. Jeff started his journey in the tech industry back in 1997 when he founded his own website development company, which was one of the pioneering companies in the country at the time. While working in technology, Jeff's comedic talents couldn't be contained, and he eventually took to the stage as a stand-up comedian. Jeff's passion for comedy led him to create Hashtag Roundup (@hashtagroundup), a platform that contributed over 8000 top 10 USA trends on Twitter. With the growth of social media, particularly Instagram, Jeff founded Stampede Social, a company aimed at helping creators drive traffic to their content without relying solely on a link in their bio.",
  },
  {
    img: greg_wasserman,
    name: "Greg Wasserman",
    title: "",
    event_names: ["Turn Conversations into Content"],
    description:
      "Greg lives a life fueled by curiosity, trust, love, fitness, and adventure. Following a motto, Life is about Time & Relationships, you never know where a conversation today will take you in the future, he has a superpower of connecting with people to foster growth and connections. With 15+ years as a successful revenue generator in advertising sales and partnerships, he’s experienced high success working with brands, agencies, and companies. His expertise also extends into the podcasting space, where he sold podcast ads, ran the operations for three listening platforms, and is currently the Head of Partnerships and Community at Castmagic—an AI platform transforming conversations into content like magic for podcasters, coaches, speakers, marketers, sales teams and more. As a sought-after consultant, he guides professionals in building themselves into brands. You can connect with him on LinkedIn, where he consistently posts about podcasting, partnerships, mental health, and business.",
  },
  {
    img: alex_gizis,
    name: "Alex Gizis",
    title: "",
    event_names: ["Internet Connectivity"],
    description:
      "Alex Gizis is the CEO of Speedify, the only app that can combine multiple internet sources into one bonded super-connection for more stable and secure live streaming, video calling, and web browsing.",
  },
  {
    img: paul_gowder,
    name: "Paul Gowder",
    title: "",
    event_names: ["Live to Inbox"],
    description:
      "Paul Gowder is the owner and founder of PowWows.com which has been the leading online community celebrating Native American arts and culture for the past 25+ years. Having visited Pows Wows around North America, Paul has been able to capture those events and publish them to millions of viewers on his website and social media channels. Paul helps other entrepreneurs level up their business with email marketing and building community.",
  },
  {
    img: andrew_moon,
    name: "Andrew Moon",
    title: "",
    event_names: ["Building Calm"],
    description:
      "Andrew Moon is a lifelong entrepreneur who has launched and run several successful businesses of his own since age 8. After running a successful technology company (aka MSP) for 10 years, which he sold in 2014, he founded Orange Nomad to help MSPs, small businesses and entrepreneurs bridge the gap between strategy and execution.",
  },
];
