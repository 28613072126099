import classnames from "classnames";
import React from "react";
import MaskedInput, { maskArray } from "react-text-mask";

import "./styles.scss";

const defaultTextMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export interface TextMaskInputProps {
  textMask?: maskArray;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  name: string;
  error?: string;
}

const StyledTextMaskInput: React.FC<TextMaskInputProps> = props => {
  const { textMask = defaultTextMask, error, ...rest } = props;
  return (
    <div className={classnames("mask-input", { empty: !rest.value.length, error })}>
      <MaskedInput {...rest} mask={textMask} showMask />
      {error && <div className="mask-input-error">{error}</div>}
    </div>
  );
};

export default StyledTextMaskInput;
