import { createAsyncAction, createStandardAction, action } from "typesafe-actions";
import { Socket } from "socket.io-client";

import { LandingSchedule } from "../interfaces/LandingSchedule";
import { LandingStateType } from "../interfaces";
import { LandingActionTypes } from "./constants";

import { IEvent } from "containers/Sermons";
import { ICommunityLandingSettings } from "containers/Community";

export const loadLandingSettings = createAsyncAction(
  LandingActionTypes.LOAD_LANDING_SETTINGS,
  LandingActionTypes.LOAD_LANDING_SETTINGS_SUCCESS,
  LandingActionTypes.LOAD_LANDING_SETTINGS_FAILURE,
)<{ subdomain: string; redirectTo?: string }, ICommunityLandingSettings, undefined>();

export const loadLandingEvents = createAsyncAction(
  LandingActionTypes.LOAD_EVENTS,
  LandingActionTypes.LOAD_EVENTS_SUCCESS,
  LandingActionTypes.LOAD_EVENTS_FAILURE,
)<{ community_id: number; params: LandingStateType["params"] }, LandingSchedule[], undefined>();

export const updateParams = createStandardAction(LandingActionTypes.UPDATE_PARAMS)<LandingStateType["params"]>();

export const setPastEvents = createStandardAction(LandingActionTypes.SET_PAST_EVENTS)<
  LandingStateType["archiveEvents"]
>();

export const setEventsCount = createStandardAction(LandingActionTypes.SET_EVENTS_COUNT)<
  LandingStateType["eventsCount"]
>();

export const setPastEventsCount = createStandardAction(LandingActionTypes.SET_PAST_EVENTS_COUNT)<
  LandingStateType["pastEventsCount"]
>();

export const clearSettings = createStandardAction(LandingActionTypes.CLEAR_LANDING_SETTINGS)();

export const connectSocket = createStandardAction(LandingActionTypes.CONNECT_SOCKET)();

export const openSocket = (communityId: number, callback?: () => void) =>
  action(LandingActionTypes.OPEN_SOCKET, { communityId, callback });

export const setSocketConnected = createStandardAction(LandingActionTypes.SET_SOCKET_CONNECTED)<
  LandingStateType["socketConnected"]
>();

export const closeSocket = createStandardAction(LandingActionTypes.CLOSE_SOCKET)();

export const socketInitialized = (socket: Socket) => action(LandingActionTypes.SOCKET_INITIALIZED, socket);
export const socketInitializedFailure = (error: Error) => action(LandingActionTypes.SOCKET_INITIALIZED_FAILURE, error);

export const wsSetEventStatus = createStandardAction(LandingActionTypes.WS_SET_EVENT_STATUS)<IEvent>();

export const wsSetEventArchived = createStandardAction(LandingActionTypes.WS_SET_EVENT_ARCHIVED)<IEvent>();

export const wsEventCreated = createStandardAction(LandingActionTypes.WS_EVENT_CREATED)<IEvent>();
