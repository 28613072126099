import { API, METHODS } from "../../../shared/constants";
import { request } from "../../../shared/utils";
import { IContentShape } from "../../App/interfaces";
import {
  ICreateSermon,
  IGenerateSignatureParams,
  ICheckSlotQuery,
  EEventChangeScope,
  ICreateAnnouncementShape,
  IUpdateAnnouncementShape,
  IReadAnnouncementsShape,
  IPoll,
  IPollVote,
  IPollRead,
  IChangeEventStatusOptions,
  IPollTemplate,
  IAnnouncementTemplate,
  ICreateSermonData,
} from "../interfaces";

export default {
  fetchSermonsList: (community_id: number, params: IContentShape) =>
    request(METHODS.GET, API.FETCH_SERMONS(community_id))({ params }),
  fetchSermon: (eventCode: string, relations?: string[]) =>
    request(METHODS.GET, API.FETCH_SERMON(eventCode, relations))(),
  uploadMeetingSlidesFiles: (community_id: number, body: Record<string, any>) =>
    request(METHODS.POST, API.UPLOAD_MEETING_SLIDES_FILES(community_id))(body),
  createSermon: (body: ICreateSermonData) => request(METHODS.POST, API.CREATE_SERMON)(body),
  updateSermon: (
    id: number,
    body: ICreateSermon & { changeStatusOptions?: IChangeEventStatusOptions },
    scope?: EEventChangeScope,
  ) => request(METHODS.PUT, API.UPDATE_SERMON(id, scope))(body),
  startEvent: (id: number) => request(METHODS.PATCH, API.START_EVENT(id))(),
  removeSermon: (sermonId: number, scope?: EEventChangeScope) =>
    request(METHODS.DELETE, API.REMOVE_SERMON(sermonId, scope))(),
  duplicateSermon: (body: { id: number; scope?: EEventChangeScope }) =>
    request(METHODS.POST, API.DUPLICATE_SERMON)(body),
  generateSignature: (params: IGenerateSignatureParams) => request(METHODS.POST, API.GENERATE_SIGNATURE)(params),
  checkAvailableEventSlot: (params: ICheckSlotQuery) =>
    request(METHODS.GET, API.CHECK_AVAILABLE_EVENT_SLOT)({ params }),
  fetchEventIntegrations: (eventCode: string) => request(METHODS.GET, API.FETCH_EVENT_INTEGRATIONS(eventCode))(),
  fetchEventShortLink: (eventCode: string, avoidInstApp: boolean) =>
    request(METHODS.GET, API.FETCH_EVENT_SHORT_LINK(eventCode, avoidInstApp))(),
  getRecurringEvent: (eventIdentifier: number | string) =>
    request(METHODS.GET, API.GET_RECURRING_EVENT(eventIdentifier))(),
  fetchAnnouncements: (meeting_id: number) => request(METHODS.GET, API.FETCH_ANNOUNCEMENTS)({ params: { meeting_id } }),
  createAnnouncement: (body: ICreateAnnouncementShape) => request(METHODS.POST, API.CREATE_ANNOUNCEMENT)(body),
  updateAnnouncement: (body: IUpdateAnnouncementShape) => request(METHODS.PUT, API.UPDATE_ANNOUNCEMENT(body.id))(body),
  deleteAnnouncement: (id: number) => request(METHODS.DELETE, API.DELETE_ANNOUNCEMENT(id))(),
  readAnnouncements: (body: IReadAnnouncementsShape) => request(METHODS.POST, API.READ_ANNOUNCEMENTS)(body),
  fetchPolls: (meeting_id: number) => request(METHODS.GET, API.FETCH_POLLS)({ params: { meeting_id } }),
  createPoll: (body: IPoll) => request(METHODS.POST, API.CREATE_POLL)(body),
  updatePoll: (body: IPoll) => request(METHODS.PATCH, API.UPDATE_POLL)(body),
  deletePoll: (poll_id: number) => request(METHODS.DELETE, API.DELETE_POLL(poll_id))(),
  pollVote: (body: IPollVote[]) => request(METHODS.POST, API.POLL_VOTE)(body),
  readPolls: (body: IPollRead) => request(METHODS.POST, API.POLL_READ)(body),
  fetchPollTemplates: (community_id: number) =>
    request(METHODS.GET, API.FETCH_POLL_TEMPLATES)({ params: { community_id } }),
  createPollTemplate: (body: IPollTemplate) => request(METHODS.POST, API.CREATE_POLL_TEMPLATE)(body),
  updatePollTemplate: (id: number, body: IPollTemplate) => request(METHODS.PATCH, API.UPDATE_POLL_TEMPLATE(id))(body),
  deletePollTemplate: (pollTemplate_id: number) => request(METHODS.DELETE, API.DELETE_POLL_TEMPLATE(pollTemplate_id))(),
  checkVimeoConnection: (token: string) => request(METHODS.GET, API.CHECK_VIMEO_TOKEN(token))(),
  deleteFile: (id: number) => request(METHODS.DELETE, API.DELETE_FILE(id))(),
  fetchAnnouncementTemplates: (community_id: number) =>
    request(METHODS.GET, API.FETCH_ANNOUNCEMENT_TEMPLATES)({ params: { community_id } }),
  createAnnouncementTemplate: (body: IAnnouncementTemplate) =>
    request(METHODS.POST, API.CREATE_ANNOUNCEMENT_TEMPLATE)(body),
  updateAnnouncementTemplate: (id: number, body: IAnnouncementTemplate) =>
    request(METHODS.PATCH, API.UPDATE_ANNOUNCEMENT_TEMPLATE(id))(body),
  deleteAnnouncementTemplate: (id: number) => request(METHODS.DELETE, API.DELETE_ANNOUNCEMENT_TEMPLATE(id))(),
};
