import React, { useEffect, FunctionComponent } from "react";
import { createPortal } from "react-dom";
import { Grid } from "@material-ui/core";
import classnames from "classnames";

import "./StyledDialog.scss";

import { IStyledDialog } from "./interfaces/styledDialog";
import { MODAL_ROOT_ID } from "../../../constants";

const ESCAPE_KEYS = ["27", "Escape"];

const modalNode = document.getElementById(MODAL_ROOT_ID);

const StyledDialog: FunctionComponent<IStyledDialog> = props => {
  const {
    open,
    onClose,
    title,
    message,
    buttons,
    children,
    hasForm,
    className,
    variant,
    hiddenOverlay,
    onClickContent,
  } = props;

  useEffect(() => {
    const escFunction = (e: any) => {
      if (ESCAPE_KEYS.includes(String(e.keyCode))) {
        onClose && onClose();
      }
    };

    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [onClose]);

  return (
    modalNode &&
    createPortal(
      open && (
        <div
          className={classnames("styledDialog", {
            [`variant-${variant}`]: !!variant,
            "hidden-overlay": !!hiddenOverlay,
          })}
          onClick={e => {
            if (!hasForm) {
              e.preventDefault();
              e.stopPropagation();
              onClose && onClose();
            }
          }}
        >
          <div
            className={classnames("styledDialog-body", className)}
            style={props.style}
            onClick={e => e.stopPropagation()}
          >
            <Grid
              container
              className="styledDialog-container"
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              <Grid
                item
                xs
                onClick={e => {
                  if (onClickContent) {
                    e.stopPropagation();
                    onClickContent();
                  }
                }}
                className={classnames("styledDialog-text", children ? "dialog-children" : "")}
              >
                <div className="styledDialog-text-title">{title}</div>
                <div className="styledDialog-text-divider" />
                <div className="styledDialog-text-content">{message}</div>
              </Grid>
              {children}
              <Grid item className="defaultButtons">
                {buttons &&
                  buttons.map((button, id) => (
                    <Grid key={id} item className="defaultButtons-button">
                      {button}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </div>
        </div>
      ),
      modalNode,
    )
  );
};
export default StyledDialog;
