import React, { useEffect, useState, useCallback, useRef } from "react";
import { useClickOutside } from "react-click-outside-hook";
import classNames from "classnames";

import { IDropDown, IDropDownItem } from "./interfaces";

import open_arrow from "assets/icons/open_arrow.svg";
import closed_arrow from "assets/icons/closed_arrow.svg";

import "./dropDown.scss";

const DropDown: React.FunctionComponent<IDropDown> = props => {
  const {
    value,
    items,
    onChange,
    spacer,
    size,
    editable,
    placeholder,
    isMenu,
    variant,
    width,
    height,
    skipScroll,
    error,
    placeholderImg,
    classname,
    disabled,
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string>("");
  const [searchItems, setSearchItems] = useState<IDropDownItem[]>(items || []);
  const [ref, hasClickedOutside] = useClickOutside();

  const optionsRef = useRef<HTMLDivElement>(null);

  const getCurrentText = useCallback(
    (testValue?: string | number) => {
      const found = items?.find(({ value }) => value === testValue);
      return (editable ? testValue?.toString() : found?.text) || "";
    },
    [items, editable],
  );

  const onSelect = (value: string | number) => {
    setOpen(false);
    onChange(value);
    if (!isMenu) {
      setSelected(getCurrentText(value));
    }
  };
  useEffect(() => {
    if (open && optionsRef.current && !skipScroll) {
      optionsRef.current?.scrollIntoView?.();
    }
  }, [open, skipScroll]);

  useEffect(() => {
    if (!editable) {
      setSearchItems(items);
    }
    setSelected(getCurrentText(value));
  }, [value, items, getCurrentText, editable]);

  useEffect(() => {
    if (hasClickedOutside) {
      setOpen(false);
    }
  }, [hasClickedOutside]);

  const search = (val: string) => {
    setSelected(val);
    const filtered = val
      ? items.filter(item => item.text.toString().toLowerCase().includes(val.toString().toLowerCase()))
      : items;
    setSearchItems(filtered);
  };

  const onTypeChange = (text: string) => {
    editable && onSelect(text);
    search(text);
    setOpen(text.length > 2);
  };

  return (
    <div
      ref={ref}
      style={{
        width,
        height,
      }}
      className={classNames(`dropDown dropDown-size-${size}`, classname, {
        "dropDown-open": open,
        "dropDown-spacer": spacer,
        [`variant-${variant}`]: variant && variant !== "default",
      })}
    >
      <div
        className={`dropDown-selected ${disabled ? "disabled" : editable ? "" : "pointer"}`}
        onClick={() => !disabled && setOpen(!open)}
      >
        {editable ? (
          <>
            <input
              placeholder={placeholder}
              className="dropDown-search"
              onClick={() => search("")}
              onChange={event => onTypeChange(event.currentTarget.value)}
              type="text"
              value={value}
            />
            {error && <p className="helper-text">{error}</p>}
          </>
        ) : (
          <div className={"dropDown-selectedName"}>
            {placeholderImg && <img src={placeholderImg} alt="img" />}
            {selected || placeholder}
          </div>
        )}
        <div className={`arrow-container ${editable && "pointer"}`}>
          {open ? <img src={open_arrow} alt="open_arrow" /> : <img src={closed_arrow} alt="closed_arrow" />}
        </div>
      </div>
      {open ? (
        <div ref={optionsRef} className={"dropDown-optionsList"}>
          {searchItems?.map((item, idx) => (
            <div
              className={classNames("dropDown-option pointer", {
                "dropDown-selectedOpt": item.value === value,
                [item.className || ""]: item.className,
              })}
              key={`option-${idx}`}
              onClick={() => item.value !== undefined && onSelect(item.value)}
            >
              {item.text}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default DropDown;
