import React from "react";

import StyledButton from "../StyledButton/StyledButton";

import { ICreateButton } from "shared";

import "./outlinedBlueButton.scss";

const OutlinedBlueButton: React.FunctionComponent<ICreateButton> = ({ children, className, ...rest }) => {
  return (
    <StyledButton className={`outlinedBlueButton ${className || ""}`} {...rest}>
      <span className="outlinedBlueButton-text">{children}</span>
    </StyledButton>
  );
};
export default OutlinedBlueButton;
