import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { ICustomAvatar } from "../../../../containers/Discussions/interfaces";

import { getInitials } from "shared/utils/userAvatarhelpers";
import star_icon from "assets/icons/star_icon.svg";

import "./customAvatar.scss";

const CustomAvatar: React.FC<ICustomAvatar> = props => {
  const {
    size = 32,
    name = "",
    shape = "circle",
    image,
    onMouseOver,
    color = "#ECA3A3",
    handleClick,
    isOnline,
    member,
    classNames,
    mark = false,
  } = props;

  const [profileImageError, setProfileImageError] = useState(false);

  const initialsArray = name.toUpperCase().split(" ").filter(Boolean);
  const [firstName = " ", lastName = " "] = initialsArray;
  const initials = member ? getInitials(member) : firstName[0] + lastName[0];

  useEffect(() => {
    setProfileImageError(false);
  }, [image]);

  const handleAvatarClick = () => {
    handleClick && handleClick();
  };

  return (
    <div
      className={classnames("custom-avatar", classNames, { online: isOnline })}
      title={name}
      style={{
        width: size,
        height: size,
        flexBasis: size,
        lineHeight: `${size}px`,
        fontSize: size && size / 2,
      }}
      onClick={handleAvatarClick}
      onMouseOver={onMouseOver}
    >
      {image && !profileImageError ? (
        <img
          src={image}
          alt={initials}
          className={classnames("custom-avatar-image", `custom-avatar--${shape}`)}
          style={{
            width: size,
            height: size,
            flexBasis: size,
            objectFit: "cover",
          }}
          onError={() => setProfileImageError(true)}
        />
      ) : (
        <div
          className={classnames("custom-avatar-fallback", `custom-avatar--${shape}`)}
          style={{
            backgroundColor: color,
          }}
        >
          {initials}
        </div>
      )}
      {mark && <img className="mark" src={star_icon} alt="star" />}
    </div>
  );
};

export default CustomAvatar;
