import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { PrivateEventBadge } from "../PrivateEventBadge";
import { SocialButtons } from "../SocialButtons";

import { LandingSchedule } from "containers/LandingPage/interfaces/LandingSchedule";
import { LandingStateType } from "containers/LandingPage/interfaces";
import { getEventTimeRange, getFormattedDate, IEvent, SermonRestriction, eventIsCurrent } from "containers/Sermons";
import { isLoading } from "containers/App/store/selectors";
import default_image_landing from "assets/images/default_image_landing.png";

import "./index.scss";

interface EventScheduleProps {
  schedule: LandingSchedule[];
  upComingEvent: IEvent | null;
  params: LandingStateType["params"];
  count: number;
  changeParams: (params: LandingStateType["params"]) => void;
  navigateToEvent: (code: string) => void;
  customLandingPage: boolean;
}

const options = {
  root: null,
  rootMargin: "30px",
  threshold: 1.0,
};

function EventSchedule({
  schedule,
  upComingEvent,
  params,
  changeParams,
  count,
  navigateToEvent,
  customLandingPage,
}: EventScheduleProps) {
  const loading = useSelector(isLoading());
  const loader = useRef(null);
  const filteredSchedule = useMemo(
    () =>
      schedule
        .map(s => {
          const data = { ...s };
          data.events = [...data.events.filter(e => e.id !== upComingEvent?.id)];
          return data;
        })
        .filter(s => s.events.length)
        .reduce((acc: LandingSchedule[], cv) => {
          const index = acc.findIndex(a => a.date === cv.date);
          if (index !== -1) {
            acc[index].events = [...acc[index].events, ...cv.events];
            acc[index].events = acc[index].events.filter(
              (elem, i) => acc[index].events.findIndex(obj => obj.id === elem.id) === i,
            );
          } else {
            acc.push({ ...cv });
          }

          return acc;
        }, []),
    [schedule, upComingEvent],
  );

  const currentCount = useMemo(
    () =>
      schedule.reduce((acc: IEvent[], cv) => {
        acc.push(...cv.events);
        return acc;
      }, []).length,
    [schedule],
  );

  const handleObserver = useCallback(
    (entities: any[]) => {
      const target = entities[0];
      if (target.isIntersecting && currentCount < count) {
        changeParams({
          ...params,
          page: params.page + 1,
        });
      }
    },
    [params, currentCount, count, changeParams],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current as any);
    }
  }, [handleObserver, count, loader]);

  return (
    <div className="event-schedule-component-wrapper">
      <div className="event-schedule-title-wrapper">
        <div className="tabs-list">
          <div
            className={`tab-item ${params.schedule_direction === "upcoming" ? "active" : ""}`}
            onClick={() =>
              changeParams({
                ...params,
                schedule_direction: "upcoming",
                page: 1,
              })
            }
          >
            {!customLandingPage ? "Upcoming Events" : ""}
          </div>
          {!customLandingPage ? (
            <div
              className={`tab-item ${params.schedule_direction !== "upcoming" ? "active" : ""}`}
              onClick={() =>
                changeParams({
                  ...params,
                  schedule_direction: "past",
                  page: 1,
                })
              }
            >
              Past Events
            </div>
          ) : null}
        </div>
      </div>
      <div className="event-schedule-list">
        {filteredSchedule.map(schedule => {
          const firstScheduledEvent = schedule.events?.[0];
          return firstScheduledEvent ? (
            <div className="event-group" key={schedule.date}>
              <div className="date-title">{getFormattedDate(new Date(firstScheduledEvent.starting_at))}</div>
              {schedule.events.map(event => (
                <div className="event-item" key={event.id} onClick={() => navigateToEvent(event.code || "")}>
                  <div className="image-wrapper">
                    {event.is_featured ? <div className="badge featured">featured</div> : null}
                    {eventIsCurrent(event) && params.schedule_direction === "upcoming" && (
                      <div className={classNames("badge live", { moved: event.is_featured })}>live</div>
                    )}
                    {event.image_url ? (
                      <img src={event.image_url} alt={event.name} />
                    ) : (
                      <img className="default-image" src={default_image_landing} alt={event.name} />
                    )}
                  </div>

                  <div className="event-information-wrapper">
                    <div className="event-date-wrapper">
                      <div className="date-start">
                        {getEventTimeRange(event)}
                        {event.restriction !== "all" ? (
                          <PrivateEventBadge
                            text={event.restriction === SermonRestriction.invited ? "INVITE ONLY" : "MEMBERS ONLY"}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="event-title-wrapper">{event.name}</div>
                    <div className="event-description-wrapper">
                      <div className="event-description-text">{event.subject}</div>
                    </div>
                    <div className="event-footer">
                      <SocialButtons event={event} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null;
        })}

        {filteredSchedule.length === 0 ? (
          <div className="empty-schedule-events-content">
            <div className="empty-title">
              Sorry, seems like this community does not have any {params.schedule_direction} events yet...
            </div>
          </div>
        ) : null}

        {!loading && filteredSchedule.length > 0 ? <div className="loader-wrapper" ref={loader} /> : null}
      </div>
    </div>
  );
}

export default React.memo(EventSchedule);
