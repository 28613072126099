import React from "react";

import { IconBase } from "../IconBase";

import { IStyledSVG } from "shared/interfaces/StyledSVG";

export const StyledArrow: React.FC<IStyledSVG> = ({
  style = {},
  stroke = "#FFF",
  width = "8",
  height = "14",
  className = "",
  opacity = 1,
}) => (
  <IconBase
    stroke={stroke}
    fill="none"
    width={width}
    style={style}
    height={height}
    className={className}
    opacity={opacity}
    viewBox="0 0 8 14"
  >
    <path d="M1 13L6 7L1 1" stroke={stroke} strokeWidth="2" />
  </IconBase>
);

export default StyledArrow;
