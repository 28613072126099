export const copyTextHandler = (value: string, callback?: () => void) => {
  if (navigator?.clipboard?.writeText) {
    return navigator?.clipboard?.writeText(value).then(() => callback?.());
  }
  const textField = document.createElement("textarea");
  textField.innerText = value;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

export const getCopiedText = async () => {
  return (await navigator?.clipboard?.readText()) || "";
};

export default copyTextHandler;
