import * as Yup from "yup";

import { IEmailFormShape } from "../interfaces";
import { passwordValidator } from "../../../shared/constants/validations";

import { EEventRepeat, EventStatus, EventType, SermonRestriction } from "containers/Sermons/interfaces";

export const emailValidationSchema = Yup.object<IEmailFormShape>().shape({
  email: Yup.string()
    .email("Incorrect Email format")
    .max(150, "Must be 150 characters or less")
    .required("Please enter your email"),
});

export const createProfileValidationSchema = Yup.object({
  first_name: Yup.string().max(35, "Name field is too long").required("Name is a required field"),
  last_name: Yup.string().max(35, "Last Name field is too long").required("Last Name is a required field"),
  password: Yup.string().required("Password is a required field").concat(passwordValidator),
  password_confirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "The passwords you entered do not match")
    .required("Confirm Password is a required field"),
});

export enum OnboardingFlow {
  invite = "invite",
  register = "register",
  onboarding = "onboarding",
}

export enum ECommunityCreationSteps {
  setUp,
  managerCreation,
  communityCreation,
  registrationEmailSent,
  welcomeBack,
  communityEventKinds,
  eventSelection,
  eventCreation,
  emailSent,
}

export const DEFAULT_MEETING_DATA = {
  attendees: [],
  class_room_settings: { room_size: 12, is_main: true },
  start_with_audio_muted: false,
  classrooms: [],
  greeter_message: "",
  greeters: [],
  image_base64: null,
  integrations: [],
  is_featured: false,
  name: "First Test Meeting",
  post_event_time: 0,
  pre_images: [],
  pre_images_base64: [],
  pre_images_interval: null,
  presenters: [],
  relays: [],
  repeat: EEventRepeat.never,
  restriction: SermonRestriction.all,
  auditorium_rooms_count: 0,
  lobby_rooms_count: 3,
  schedule: null,
  seats_per_table: null,
  setup_time: 0,
  speaker_name: "",
  status: EventStatus.scheduled,
  stream_settings: null,
  stream_type: null,
  stream_url: null,
  subject:
    "Trying out a video-conference meeting on Altar Live. Simple to set up and easy to join - and it’s browser based! Replaces Zoom, Google Meet, and more.",
  type: EventType.meeting,
  video_type: null,
  is_default: false,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  restricted_generalChat: false,
};
