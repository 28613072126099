import React, { useMemo } from "react";
import { FormControlLabel, Grid } from "@material-ui/core";
import { useFormikContext } from "formik";

import { IMember, IMemberDialogValues } from "../../../interfaces";
import { ERoles } from "../../../../Auth";

import { checkRoles } from "utils/ACL";
import { ImageUpload, StyledCheckBox, CustomFormikField } from "shared";
import { numberValidator } from "shared/constants/validations";
import empty_person_2 from "assets/icons/empty_person_2.svg";

import "./styles.scss";

type Props = {
  member?: IMember;
  handleBlurEmail: (e: React.FocusEvent<HTMLInputElement>) => void;
  canEdit: boolean;
  isOwnMember: boolean;
};

const MemberDialogEdit: React.FC<Props> = ({ member, handleBlurEmail, canEdit, isOwnMember }) => {
  const { values, handleChange, errors, setFieldValue, validateField } = useFormikContext<IMemberDialogValues>();

  const isAdmin = useMemo(() => checkRoles([ERoles.admin]), []);

  const onEmailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFieldValue("email", e.target.value.trim(), !!errors.email);

  const onPhoneChangeHandler = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === "" || numberValidator.test(e.target.value)) {
      await setFieldValue("phone_number", e.target.value);
      validateField("phone_number");
    }
  };

  const changeFieldHandler =
    (field: string) => async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      await setFieldValue(field, e.target.value);
      validateField(field);
    };

  return (
    <div className="member-dialog-edit">
      <Grid container className="member-dialog-edit-main">
        <Grid item className="item uploader">
          <ImageUpload
            showOptions
            maxMb={2}
            value={values.image_url}
            onChange={handleChange("image_url")}
            backgroundImage={`url(${empty_person_2})`}
            variant="circle"
          />
        </Grid>
        <Grid item className="item">
          <CustomFormikField
            fullWidth
            label="First Name"
            placeholder=""
            name="first_name"
            errors={errors}
            value={values.first_name}
            onChange={changeFieldHandler("first_name")}
            onBlur={() => validateField("first_name")}
          />
        </Grid>
        <Grid item className="item">
          <CustomFormikField
            fullWidth
            label="Last Name"
            placeholder=""
            name="last_name"
            errors={errors}
            value={values.last_name}
            onChange={changeFieldHandler("last_name")}
            onBlur={() => validateField("last_name")}
          />
        </Grid>
        <Grid item className="item">
          <CustomFormikField
            fullWidth
            label="Email address"
            placeholder=""
            name="email"
            errors={errors}
            value={values.email}
            onChange={onEmailChangeHandler}
            onBlur={handleBlurEmail}
          />
        </Grid>
        <Grid item className="item">
          <CustomFormikField
            fullWidth
            label="Phone"
            placeholder=""
            name="phone_number"
            errors={errors}
            value={values.phone_number}
            onChange={onPhoneChangeHandler}
            onBlur={() => validateField("phone_number")}
          />
        </Grid>
      </Grid>
      <Grid container className="member-dialog-edit-admin">
        {canEdit && (!isOwnMember || isAdmin) && (
          <>
            <FormControlLabel
              control={
                <StyledCheckBox
                  checked={values.should_create_as_manager}
                  onChange={handleChange}
                  name="should_create_as_manager"
                />
              }
              label="Admin Privilege"
            />
            <p className="grey-text">
              Admins can create, edit, and delete all public and private events and meetings, as well as manage members
              and permissions.
            </p>
          </>
        )}
        {!member && (
          <FormControlLabel
            control={<StyledCheckBox checked={values.send_invitation} onChange={handleChange} name="send_invitation" />}
            label="Send Invitation immediately"
          />
        )}
      </Grid>
    </div>
  );
};

export default MemberDialogEdit;
