import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getCommunitySubscriptions } from "containers/Community/store/selectors";

const useSubscriptions = () => {
  const subscriptions = useSelector(getCommunitySubscriptions());

  return useMemo(() => {
    const [currentProduct] = subscriptions?.currentSubscriptions || [];
    return {
      currentProduct,
      hasPaidSubscription: !!currentProduct?.price,
    };
  }, [subscriptions]);
};

export default useSubscriptions;
