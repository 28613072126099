import React from "react";

import lobby_mock1 from "assets/images/lobby_mock1.png";
import lobby_mock2 from "assets/images/lobby_mock2.png";

import "./styles.scss";

interface IMobileWrapperLobby {
  onClick: () => void;
}

const MobileWrapperLobby: React.FC<IMobileWrapperLobby> = ({ onClick }) => {
  const benches = new Array(5).fill(0).map((_, idx) => (
    <div key={idx} className="mobileWrapper-lobby-benchItem" onClick={onClick}>
      <img src={idx ? lobby_mock2 : lobby_mock1} alt="bench item" />
    </div>
  ));
  return (
    <div className="mobileWrapper-lobby">
      <div className="mobileWrapper-lobby-benches">{benches}</div>
      <div className="mobileWrapper-gradient" />
    </div>
  );
};

export default MobileWrapperLobby;
