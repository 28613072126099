import "./index.scss";

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import AnnouncementCard from "./AnnouncementCard";

import { AnnouncementStatus, IAnnouncement } from "containers/Sermons/interfaces";
import { Button } from "shared";
import { selectors } from "containers/Sermons/store";
import { EInteractionTab } from "shared/components/Event/EventSideDialog/EventSideDialogHeader";

interface IEventAnnouncementsListProps {
  announcements: IAnnouncement[];
  onCreate: () => void;
  onUseTemplate?: () => void;
  onEdit: (announcement: IAnnouncement) => void;
  onDelete: (announcement: IAnnouncement) => void;
  onChangeStatus: (announcement: IAnnouncement, status: AnnouncementStatus) => void;
  onView: (announcement: IAnnouncement) => void;
  canManageAnnouncements: boolean;
}

const EventAnnouncementsList: React.FC<IEventAnnouncementsListProps> = props => {
  const {
    announcements = [],
    onCreate,
    onEdit,
    onDelete,
    onChangeStatus,
    onView,
    onUseTemplate,
    canManageAnnouncements,
  } = props;
  const { interactionTab } = useSelector(selectors.getEventSettings());

  const filteredAnnouncements = useMemo(() => {
    return announcements.filter(a =>
      !canManageAnnouncements || interactionTab === EInteractionTab.published
        ? a.status === AnnouncementStatus.published
        : a.status !== AnnouncementStatus.published,
    );
  }, [announcements, canManageAnnouncements, interactionTab]);

  const renderAddButton = useMemo(() => {
    if (!canManageAnnouncements) {
      return null;
    }
    return (
      <div className="announcements-add-button">
        <Button variant="orange" onClick={onCreate}>
          Add New Announcement
        </Button>
        <Button variant="white-text" onClick={onUseTemplate}>
          Use Templates
        </Button>
      </div>
    );
  }, [onCreate, canManageAnnouncements, onUseTemplate]);

  const listIsEmpty = useMemo(() => {
    return !filteredAnnouncements.length;
  }, [filteredAnnouncements]);

  const renderList = useMemo(() => {
    if (listIsEmpty) {
      return (
        <div className="announcements-empty-screen">
          <div className="announcements-empty-icon" />
          <div className="announcements-empty-title">No Announcements yet</div>
        </div>
      );
    } else {
      return filteredAnnouncements.map((item, index) => (
        <div className="announcements-list-item" key={index}>
          <AnnouncementCard
            announcement={item}
            onEdit={onEdit}
            onDelete={onDelete}
            onChangeStatus={onChangeStatus}
            onView={onView}
            canManageAnnouncements={canManageAnnouncements}
          />
        </div>
      ));
    }
  }, [filteredAnnouncements, canManageAnnouncements, listIsEmpty, onDelete, onEdit, onChangeStatus, onView]);

  return (
    <div className="announcements-list-wrapper">
      <div className={classNames("announcements-list", { "full-height": !canManageAnnouncements })}>{renderList}</div>
      {renderAddButton}
    </div>
  );
};

export default EventAnnouncementsList;
