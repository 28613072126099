import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import classnames from "classnames";

import RecordingLabel from "../../components/RecordingLabel/RecordingLabel";
import { getRecordingStatus } from "../../store/selectors";
import { ERecordingStatus } from "../../interfaces";
import PermissionsAlert from "../../../BreakoutRooms/components/EventSeats/PermissionsAlert/PermissionsAlert";

import { EventPermissions, selectors as userSelectors } from "containers/Auth";
import { EightToEight } from "containers/Meeting/components";
import { loadClassRooms, setFullClassroom, setRecordingStatus } from "containers/Meeting/store/actions";
import { selectors } from "containers/Meeting/store";
import { checkMeetingRole, EventStatus, EventType, SermonRoles } from "containers/Sermons";
import { EventEnded } from "containers/Sermons/components/EventContainer/EventEnded";
import { updateEventSettings } from "containers/Sermons/store/actions";
import { getAllowEnded, getEvent } from "containers/Sermons/store/selectors";
import { EmptyText, IRouteProps, usePermissions } from "shared";
import { useClassroomHook } from "shared/hooks/ClassroomFullHook";
import { getUser } from "containers/Auth/store/selectors";

import "./index.scss";

function EightToEightContainer() {
  const { roomCode } = useParams<IRouteProps>();
  const dispatch = useDispatch();

  const [mediaPermissionAlert, setMediaPermissionAlert] = useState(false);

  const event = useSelector(getEvent());
  const editPermissions = useMemo(() => [EventPermissions.edit], []);
  const canEdit = usePermissions(editPermissions, event?.community_id);
  const member = useSelector(userSelectors.getMember());
  const classrooms = useSelector(selectors.selectClassrooms());
  const classroom_is_full = useSelector(selectors.selectFoolClassroom());
  const allowEnded = useSelector(getAllowEnded());
  const currentUser = useSelector(getUser());
  const recordingStatus = useSelector(getRecordingStatus());

  const { isClassroomFull } = useClassroomHook();

  useEffect(() => {
    if (!classrooms.length && event && currentUser) {
      dispatch(loadClassRooms.request(event.id));
    }
  }, [dispatch, classrooms, event, currentUser]);

  const showAdminMenu = useMemo(
    () =>
      event &&
      event.status !== EventStatus.ended &&
      (canEdit || (event && member && checkMeetingRole(event, member, [SermonRoles.greeterHost]))),
    [canEdit, member, event],
  );

  useEffect(() => {
    if (classroom_is_full && event?.type === EventType.meeting) {
      dispatch(updateEventSettings({ activeDashboardTab: null }));
    }
  }, [dispatch, classroom_is_full, event]);

  useEffect(
    () => () => {
      dispatch(setFullClassroom(false));
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      event &&
      recordingStatus === ERecordingStatus.recording &&
      [EventStatus.lobby, EventStatus.ended].includes(event.status)
    ) {
      dispatch(setRecordingStatus(ERecordingStatus.hasStopped));
    }
  }, [event, dispatch, recordingStatus]);

  useEffect(() => {
    dispatch(updateEventSettings({ activeDashboardTab: null }));
  }, [dispatch]);

  if (isClassroomFull && event?.type === EventType.meeting) {
    return <EmptyText message="The meeting is full" show classes="full-meeting" />;
  }
  return (
    <div className={classnames("stream-container")}>
      <div className="stream-content eight-to-eight">
        {event &&
          member &&
          (event.status !== EventStatus.ended || allowEnded ? (
            <>
              {mediaPermissionAlert && (
                <PermissionsAlert joinToRoom={() => {}} setMediaPermissionsAlert={setMediaPermissionAlert} />
              )}
              {recordingStatus === ERecordingStatus.recording && <RecordingLabel canStop={Boolean(showAdminMenu)} />}
              <EightToEight
                member={member}
                roomCode={roomCode || event.code || ""}
                subject={event.name}
                isHost={Boolean(showAdminMenu)}
                event={event}
                setMediaPermissionsAlert={setMediaPermissionAlert}
              />
            </>
          ) : (
            <EventEnded event={event} />
          ))}
      </div>
    </div>
  );
}

export default EightToEightContainer;
