import { IUserUpdate } from "../interfaces";

import { API, METHODS, request } from "shared";

export default {
  createUserByEmail: (payload: { email: string }) => request(METHODS.POST, API.CREATE_USER_BY_EMAIL)(payload),
  updateInactiveUser: (id: number, body: { email: string }) => request(METHODS.PUT, API.UPDATE_USER_BY_EMAIL(id))(body),

  updateUser: (payload: IUserUpdate) => payload.id && request(METHODS.PUT, API.UPDATE_USER(payload.id))(payload),
  confirmEmail: (hash: string) => request(METHODS.PATCH, API.CONFIRM_USER_EMAIL(hash))(),
  deleteUser: (id: number) => request(METHODS.DELETE, API.DELETE_USER(id))(),
};
