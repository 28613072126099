import React from "react";
import classnames from "classnames";

import StyledButton from "../StyledButton/StyledButton";

import { ICreateButton } from "shared";

import "./containedBlueButton.scss";

const ContainedBlueButton: React.FunctionComponent<ICreateButton> = ({ children, className, ...rest }) => {
  return (
    <StyledButton
      className={classnames(
        "containedBlueButton",
        rest.disabled ? "containedBlueButton-disabled" : "",
        className || "",
      )}
      {...rest}
    >
      <span className="containedBlueButton-text">{children}</span>
    </StyledButton>
  );
};
export default ContainedBlueButton;
