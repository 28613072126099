import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel } from "@material-ui/core";

import { Button, CustomTextField, generateBackground, getInitials, StyledCheckBox } from "../../../../shared";
import { selectors as userSelectors } from "../../../Auth";
import { IMember } from "../../../Member";
import { getEventMembers } from "../../store/selectors";
import { actions, selectors } from "../../store";
import { generateName } from "../../../../shared/utils/randomNames";
import { useMemberCID } from "../../../../shared/hooks/MemberCIDHook";
import StreamEventPlayer from "../StreamEventPlayer";

import gif_1 from "assets/videos/tour/GIF_1.mp4";
import gif_2 from "assets/videos/tour/GIF_2.mp4";
import gif_3 from "assets/videos/tour/GIF_3.mp4";
import gif_4 from "assets/videos/tour/GIF_4.mp4";

import "./styles.scss";

const Step1: FC<{ title?: string; description?: string }> = ({ title, description }) => (
  <SimpleContent
    title={title || "Welcome to our church!"}
    description={
      description ||
      "We’re so glad you’re joining service with us today from wherever you are located. This is our online church\n" +
        "      platform, and we’d like to show you around."
    }
  />
);

const Step2: FC = () => (
  <SimpleContent
    title={"Chat with others"}
    description={
      "Say hello! You can send messages to the entire online group or send private, direct messages to other online attendees."
    }
  />
);

const Step3: FC = () => (
  <SimpleContent
    title={"Select a seat to talk with other people over video"}
    description={
      "Click on an empty seat button to join other participants in a private video-conference session. " +
      "You’ll only be prompted to give permissions for your camera and microphone when you take a seat."
    }
  />
);

const Step4ShowMe: FC = () => (
  <SimpleContent
    title={"That’s it! Ready to engage?"}
    description={
      <>
        <p>Click Got It to return to the event. </p>
        Click the Show Me Around button if you want to take this tour again.
      </>
    }
  />
);

const SimpleContent: FC<{ title: string; description: JSX.Element | string }> = ({ title, description }) => (
  <>
    <div className={"title"}>{title}</div>
    <div>{description}</div>
  </>
);

const Step4: FC<{
  stayAnonymous?: () => void;
  isAnonymous?: boolean;
  anonymousName?: string;
  changeAnonymousName?: (name: string) => void;
}> = ({ stayAnonymous, isAnonymous, anonymousName, changeAnonymousName }) => {
  const user = useSelector(userSelectors.getUser());
  const member = useSelector(userSelectors.getMember());
  const eventMembers = useSelector(getEventMembers());

  const [background, setBackground] = useState<undefined | string>();
  const [profileImageError, setProfileImageError] = useState(false);

  const profileImage = member?.image_url || user?.image_url;

  const name =
    member || user ? `${member?.first_name || user?.first_name} ${member?.last_name || user?.last_name} (You)` : "";

  useEffect(() => {
    if (eventMembers.length > 0 && (member || user)) {
      const participant = eventMembers.find(
        ({ memberId }) => memberId === member?.id || memberId === user?.members[0]?.id,
      );
      setBackground(participant?.color || generateBackground());
    }
  }, [eventMembers, member, user]);

  const initials = member?.id
    ? getInitials(member)
    : user
    ? getInitials({ first_name: user.first_name, last_name: user.last_name } as IMember)
    : "";

  return (
    <>
      {member || user ? (
        <>
          <div className={"title"}>Ready to join?</div>

          <div className={"profile-block"}>
            <div className="profile-block-user">
              {profileImage && !profileImageError ? (
                <img
                  src={profileImage}
                  alt={member?.first_name || "member"}
                  onError={() => setProfileImageError(true)}
                />
              ) : (
                <div className="initials" style={{ backgroundColor: background }}>
                  <div className="fallback">{initials}</div>
                </div>
              )}
            </div>
            {name && <div className="profile-block-name">{name}</div>}
          </div>
          <div>Click Join to enter the event.</div>
        </>
      ) : (
        <>
          <div className={"title"}>Let people know you’re here!</div>
          <div className={"anonymous-block"}>
            <CustomTextField
              fullWidth
              label="Enter your display name:"
              placeholder="Type here"
              name="title"
              errors={[]}
              value={anonymousName}
              onChange={(e: React.ChangeEvent<any>) => {
                if (changeAnonymousName) {
                  changeAnonymousName(e.target.value);
                }
              }}
            />
            <FormControlLabel
              control={<StyledCheckBox checked={isAnonymous} onChange={stayAnonymous} name="stay_anonymous" />}
              label="I would like to stay anonymous"
            />
          </div>
        </>
      )}
    </>
  );
};

const infoBlocks = [
  {
    video: gif_1,
    description: "Welcome First step",
  },
  {
    video: gif_2,
    description: "Chat with others",
  },
  {
    video: gif_3,
    description: "Select seats",
  },
  {
    video: gif_4,
    description: "Members",
  },
];

type Props = {
  closeTour: () => void;
  skipTour: () => void;
  countSteps: number;
  showMeAround: boolean;
  tourTitle?: string | null;
  tourDescription?: string | null;
};

const EventTour: FC<Props> = ({ skipTour, countSteps, closeTour, showMeAround, tourTitle, tourDescription }) => {
  const user = useSelector(userSelectors.getUser());
  const member = useSelector(userSelectors.getMember());
  const event = useSelector(selectors.getEvent());

  const cid = useMemberCID();

  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [anonymousName, setAnonymousName] = useState("");

  const [videoContainer, setVideoContainer] = useState<HTMLDivElement | null>(null);

  const changeAnonymousName = useCallback((name: string) => {
    setAnonymousName(name);
  }, []);

  const getStepContent = () => {
    switch (step) {
      case 0:
        return (
          <Step1 title={tourTitle || event?.tour_title} description={tourDescription || event?.tour_description} />
        );
      case 1:
        return <Step2 />;
      case 2:
        return <Step3 />;
      case 3:
        return showMeAround ? (
          <Step4ShowMe />
        ) : (
          <Step4
            isAnonymous={isAnonymous}
            stayAnonymous={() => setIsAnonymous(!isAnonymous)}
            anonymousName={anonymousName}
            changeAnonymousName={changeAnonymousName}
          />
        );
      default:
        return <Step1 />;
    }
  };

  const getStepDotList = () => {
    const steps = [];
    for (let i = 0; i < countSteps; i += 1) {
      steps.push(
        <button
          key={i}
          className={`step ${step === i ? "active" : ""}`}
          onClick={() => {
            setStep(i);
          }}
        />,
      );
    }

    return steps;
  };

  const nextBtnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (step + 1 === countSteps) {
      if (isAnonymous || anonymousName.length > 0) {
        let name: string = anonymousName;
        if (isAnonymous) {
          const savedName = localStorage.getItem("custom_name");
          name = savedName || generateName();
        }

        if (cid) {
          dispatch(actions.changeMemberUserName(cid, name));
        }

        localStorage.setItem("custom_name", name);
      }
      localStorage.setItem("showEventTour", "false");
      closeTour();
    } else {
      setStep(step + 1);
    }
  };

  const isDisabledNextButton = useMemo(() => {
    return !user && !member && step === countSteps - 1 && !isAnonymous && anonymousName.length === 0 && !showMeAround;
  }, [user, member, countSteps, step, isAnonymous, anonymousName, showMeAround]);

  const streamVideoElements = useMemo(
    () =>
      infoBlocks.map((elem, i) => (
        <StreamEventPlayer
          key={i}
          streamType={null}
          streamUrl={elem.video}
          videoContainer={videoContainer}
          muted
          playing
          loop
          width={452}
          height={452}
        />
      )),
    [videoContainer],
  );

  return (
    <div className={"container-global"}>
      <div className="container">
        <div className={"step1"}>
          <div className={"info-block"} ref={setVideoContainer}>
            <div className={"overflow-block"}>{streamVideoElements[step]}</div>
          </div>
          <div className={"content-block"}>
            <div className={"switchers"}>{getStepDotList()}</div>
            <div className={"text-block"}>{getStepContent()}</div>
            <div className={"actions"}>
              {step > 0 && (
                <Button
                  width={112}
                  variant={"orange-outline"}
                  onClick={() => {
                    setStep(step - 1);
                  }}
                  className={"back-btn"}
                >
                  Back
                </Button>
              )}

              <Button
                width={112}
                variant={isDisabledNextButton ? "disabled" : "orange"}
                disabled={isDisabledNextButton}
                onClick={nextBtnClick}
              >
                {showMeAround && step === countSteps - 1 ? "Got it" : step === countSteps - 1 ? "Join!" : "Next"}
              </Button>
              {step !== countSteps - 1 && (user || member || showMeAround) && (
                <Button
                  width={112}
                  variant={"purple-text"}
                  onClick={() => {
                    skipTour();
                    if (user || member) {
                      localStorage.setItem("showEventTour", "false");
                    }
                  }}
                >
                  Skip Tour
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventTour;
