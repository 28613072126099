import { createSelector } from "reselect";

import { AltarStore } from "../../../shared/types";

const selectAuth = (state: AltarStore) => state.auth;

export const getUser = () => createSelector(selectAuth, state => state.user);

export const authentificated = () => createSelector(selectAuth, state => state.authentificated);
export const loginLoading = () => createSelector(selectAuth, state => state.loginLoading);
export const authError = () => createSelector(selectAuth, state => state.error);
export const getUserId = () => createSelector(selectAuth, state => state.user?.id);
export const getOnboardingData = () => createSelector(selectAuth, state => state.onboardingData);
export const getUnverifiedUserId = () => createSelector(selectAuth, state => state.userId);
export const forgotEmail = () => createSelector(selectAuth, state => state.forgotEmail);
export const getMember = () => createSelector(selectAuth, state => state.member);
export const getMemberOf = () =>
  createSelector(selectAuth, state => state.user?.members.map(member => member.community_id) || []);
export const isMagicLinkSent = () => createSelector(selectAuth, state => state.magicLinkSent);
export const isEmailResent = () => createSelector(selectAuth, state => state.emailResent);
export const getRegistrationData = () => createSelector(selectAuth, state => state.registrationData);
export const isUserNew = () => createSelector(selectAuth, state => state.isNew);
export const getConfirmationError = () => createSelector(selectAuth, state => state.conformationError);
export const getToken = () => createSelector(selectAuth, state => state.token);
