enum UserActionTypes {
  UPDATE_USER_REQUEST = "@@USER/UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS = "@@USER/UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE = "@@USER/UPDATE_USER_FAILURE",

  CONFIRM_EMAIL_REQUEST = "@@USER/CONFIRM_EMAIL_REQUEST",
  CONFIRM_EMAIL_SUCCESS = "@@USER/CONFIRM_EMAIL_SUCCESS",
  CONFIRM_EMAIL_FAILURE = "@@USER/CONFIRM_EMAIL_FAILURE",

  DELETE_USER_REQUEST = "@@USER/DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS = "@@USER/DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE = "@@USER/DELETE_USER_FAILURE",
}

export default UserActionTypes;
