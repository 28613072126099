import React from "react";
import classnames from "classnames";

import { EEventScheduleDay } from "../../../../interfaces";

import "./styles.scss";

type Props = {
  value: EEventScheduleDay;
  checked: boolean;
  onClick: (value: EEventScheduleDay) => void;
};

const DayIcon: React.FC<Props> = ({ value, checked, onClick }) => {
  return (
    <div className={classnames("day-icon", { checked })} onClick={() => onClick(value)}>
      {value[0]}
    </div>
  );
};

export default DayIcon;
