import React, { useCallback, useEffect, useState } from "react";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core";

import "./index.scss";

const UserSlider = withStyles({
  root: {
    color: "#7B9EB9",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    display: "none",
  },
  markLabel: {
    marginTop: "6px",
  },
  markActive: {
    display: "none",
  },
})(Slider);

interface IMarkLabel {
  value: number;
  label: number | string;
  mau: number;
}

interface MauSliderProps {
  sliderMarks: IMarkLabel[];
  currentMAUCount: number;
  onSliderCountChanged: (value: number) => void;
  title: string;
  description?: string;
}

function MauSlider({ sliderMarks, currentMAUCount, onSliderCountChanged, title, description }: MauSliderProps) {
  const [selectedProductMarkIndex, setSelectedProductMarkIndex] = useState(0);

  const handleChange = useCallback(
    (productIndex: number) => {
      onSliderCountChanged(Number(sliderMarks[productIndex].mau));
      setSelectedProductMarkIndex(productIndex);
    },
    [onSliderCountChanged, sliderMarks],
  );

  useEffect(() => {
    const index = sliderMarks.findIndex(mark => mark.mau === currentMAUCount);
    handleChange(index === -1 ? sliderMarks.length - 1 : index);
  }, [currentMAUCount, handleChange, sliderMarks]);

  return (
    <div className="mau-slider-wrapper">
      <div className="mau-title">{title}</div>
      {description ? <div className="mau-description">{description}</div> : null}
      <UserSlider
        aria-labelledby="discrete-slider-always"
        valueLabelDisplay="off"
        min={0}
        max={sliderMarks.length - 1}
        marks={sliderMarks}
        onChange={(e, value) => handleChange(Number(value))}
        value={selectedProductMarkIndex}
      />
      <div className="mau-additional-information">
        Up to {sliderMarks[selectedProductMarkIndex]?.label || ""} Monthly Active Users (MAU)
        <br />
        No limits on Anonymous Visitors
      </div>
    </div>
  );
}

export default MauSlider;
