import DateFnsUtils from "@date-io/date-fns";
import { Popover } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FieldAttributes } from "formik";
import React, { SyntheticEvent, useCallback, useEffect, useState, useMemo } from "react";
import moment from "moment";

import CustomTimePickerInput from "../CustomTimePickerInput/CustomTimePickerInput";

import { Button, StyledInput, StyledArrow, StyledToggleButtonGroup } from "shared";

const arrowColor = "#7B9EB9";
const arrowSize = 15;

const maxValues = {
  hours: 12,
  minutes: 59,
};

const minValues = {
  hours: 1,
  minutes: 0,
};

const StyledTimePicker: React.FC<FieldAttributes<any>> = props => {
  const { value, label, classes, setFieldValue, name } = props;
  const convertedValue = useMemo(() => moment(value).format("hh:mm A").split(" "), [value]);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [hours, setHours] = useState(String(+convertedValue[0].split(":")[0]));
  const [minutes, setMinutes] = useState(convertedValue[0].split(":")[1]);
  const [meridiem, setMeridiem] = useState(convertedValue[1]);
  const handleUp = useCallback((currentValue, key: "hours" | "minutes", done: (v: string) => void) => {
    let result = currentValue + 1 > maxValues[key] ? String(minValues[key]) : String(currentValue + 1);
    if (key === "minutes" && String(result).length < 2) {
      result = `0${result}`;
    }
    done(result);
  }, []);

  useEffect(() => {
    setHours(String(+convertedValue[0].split(":")[0]));
    setMinutes(convertedValue[0].split(":")[1]);
    setMeridiem(convertedValue[1]);
  }, [convertedValue]);

  const handleDown = useCallback((currentValue, key: "hours" | "minutes", done: (v: string) => void) => {
    let result = currentValue - 1 < minValues[key] ? String(maxValues[key]) : String(currentValue - 1);
    if (key === "minutes" && String(result).length < 2) {
      result = `0${result}`;
    }
    done(result);
  }, []);

  const handleSubmit = useCallback(() => {
    const currentDate = moment(value);
    const convertedTimeString = moment(`${hours}:${minutes} ${meridiem}`, ["h:mm A"]).format("HH:mm");
    currentDate.set("minute", Number(convertedTimeString.split(":")[1]));
    currentDate.set("hours", Number(convertedTimeString.split(":")[0]));
    setFieldValue(name, currentDate);
    if (minutes.length < 2) {
      setMinutes(`0${minutes}`);
    }
    setAnchorEl(null);
  }, [value, setFieldValue, name, hours, minutes, meridiem]);

  const handleCancel = useCallback(() => {
    setHours(String(+convertedValue[0].split(":")[0]));
    setMinutes(convertedValue[0].split(":")[1]);
    setMeridiem(convertedValue[1]);
    setAnchorEl(null);
  }, [convertedValue]);

  const handleInputChangeHours = useCallback((value: string) => {
    const result = Number(value);
    if (result > maxValues.hours) {
      setHours(String(maxValues.hours));
    } else if (result < 0) {
      setHours(String("1"));
    } else {
      setHours(value);
    }
  }, []);

  const handleInputChangeMinutes = useCallback((value: string) => {
    const result = Number(value);
    if (result > maxValues.minutes) {
      setMinutes(String(maxValues.minutes));
    } else if (result < 0) {
      setMinutes(String("00"));
    } else {
      setMinutes(value);
    }
  }, []);

  const meridiemItems = useMemo(() => {
    return [
      { value: "AM", label: "AM" },
      { value: "PM", label: "PM" },
    ];
  }, []);

  const hoursIsValid = useMemo(() => {
    return Number(hours) >= minValues.hours && Number(hours) <= maxValues.hours;
  }, [hours]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CustomTimePickerInput
        value={value}
        onClick={(event: SyntheticEvent) => setAnchorEl(event.currentTarget)}
        label={label}
        classes={classes}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 34,
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: 250,
          horizontal: 0,
        }}
        onClose={handleCancel}
        className="styled-time-picker-popover"
      >
        <>
          <div className="styled-timepicker-wrapper">
            <div className="styled-timepicker-column">
              <div onClick={() => handleUp(+hours, "hours", setHours)}>
                <StyledArrow
                  className="back-button"
                  width={arrowSize}
                  height={arrowSize}
                  stroke={arrowColor}
                  style={{ transform: "rotate(-90deg)" }}
                />
              </div>
              <div className="styled-timepicker-field">
                <StyledInput
                  className="field"
                  name="hours"
                  value={hours}
                  maxLength={2}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    handleInputChangeHours(event.currentTarget.value)
                  }
                  type="number"
                />
              </div>
              <div onClick={() => handleDown(+hours, "hours", setHours)}>
                <StyledArrow
                  className="back-button"
                  width={arrowSize}
                  height={arrowSize}
                  stroke={arrowColor}
                  style={{ transform: "rotate(-270deg)" }}
                />
              </div>
            </div>
            <div className="hours-divider">:</div>
            <div className="styled-timepicker-column">
              <div onClick={() => handleUp(+minutes, "minutes", setMinutes)}>
                <StyledArrow
                  className="back-button"
                  width={arrowSize}
                  height={arrowSize}
                  stroke={arrowColor}
                  style={{ transform: "rotate(-90deg)" }}
                />
              </div>
              <div className="styled-timepicker-field">
                <StyledInput
                  className="field"
                  name="minutes"
                  type="number"
                  maxLength={2}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    handleInputChangeMinutes(event.currentTarget.value)
                  }
                  value={minutes}
                />
              </div>
              <div onClick={() => handleDown(+minutes, "minutes", setMinutes)}>
                <StyledArrow
                  className="back-button"
                  width={arrowSize}
                  height={arrowSize}
                  stroke={arrowColor}
                  style={{ transform: "rotate(-270deg)" }}
                />
              </div>
            </div>
            <div>
              <StyledToggleButtonGroup
                items={meridiemItems}
                value={meridiem}
                handleChange={(newValue: string) => setMeridiem(newValue || meridiem)}
              />
            </div>
          </div>
          <div className="timepicker-divider" />
          <div className="timepicker-buttons">
            <Button radius={9} width={318} variant="gray-text" type="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              radius={9}
              width={318}
              disabled={!hoursIsValid}
              variant={hoursIsValid ? "blue-text" : "gray-text"}
              type="button"
              onClick={handleSubmit}
            >
              Ok
            </Button>
          </div>
        </>
      </Popover>
    </MuiPickersUtilsProvider>
  );
};

export default StyledTimePicker;
