import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Divider from "../../../../Sermons/components/Sermon/EventWizardSteps/Divider";
import CreatePollTemplate from "../CreatePollTemplate/CreatePollTemplate";
import { getCommunity } from "../../../store/selectors";
import { getAnnouncementTemplates, getPollTemplates } from "../../../../Sermons/store/selectors";
import {
  deleteAnnouncementTemplate,
  deletePollTemplate,
  fetchAnnouncementTemplates,
  fetchPollTemplates,
} from "../../../../Sermons/store/actions";
import { PollTemplate } from "../PollTemplate";
import { IAnnouncementTemplate, IPollTemplate } from "../../../../Sermons";
import { CreateAnnouncementTemplate } from "../CreateAnnouncementTemplate";
import { AnnouncementTemplate } from "../AnnouncementTemplate";
import { ETemplatesType } from "../../../interfaces";

import { StyledLink, SwitchControl } from "shared";
import { LINKS } from "shared/constants/links";

import "./index.scss";

const Templates: React.FC = () => {
  const dispatch = useDispatch();

  const community = useSelector(getCommunity());
  const pollTemplates = useSelector(getPollTemplates());
  const announcementTemplates = useSelector(getAnnouncementTemplates());

  const [templatesType, setTemplatesType] = useState<ETemplatesType>(ETemplatesType.announcements);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<IPollTemplate | IAnnouncementTemplate | null>(null);

  const editTemplateHandler = useCallback((template: IAnnouncementTemplate | IPollTemplate) => {
    setSelectedTemplate(template);
    setOpenTemplate(true);
  }, []);

  const deleteTemplateHandler = useCallback(
    (templateType: ETemplatesType, template_id: number) => {
      if (templateType === ETemplatesType.polls) {
        dispatch(deletePollTemplate.request(template_id));
      } else {
        dispatch(deleteAnnouncementTemplate.request(template_id));
      }
    },
    [dispatch],
  );

  const addNewTemplateHandler = useCallback(() => {
    if (selectedTemplate) {
      setSelectedTemplate(null);
    }
    setOpenTemplate(true);
  }, [selectedTemplate]);

  useEffect(() => {
    if (community) {
      dispatch(fetchPollTemplates.request(community.id));
      dispatch(fetchAnnouncementTemplates.request(community.id));
    }
  }, [community, dispatch]);

  return (
    <div className="templates">
      <div className="templates-title">Templates</div>
      <div className="templates-description">
        Create reusable announcements and polls with templates. Saved templates can be accessed inside of Events and
        Meetings when a Host or Greeter creates an announcement or poll.
      </div>
      <StyledLink className="templates-learnMore" href={LINKS.templates}>
        Learn more about Templates
      </StyledLink>
      <Divider />
      <SwitchControl
        checked={templatesType === ETemplatesType.announcements}
        textOn="Announcements"
        textOff="Polls"
        width={624}
        onChange={() =>
          setTemplatesType(prev =>
            prev === ETemplatesType.polls ? ETemplatesType.announcements : ETemplatesType.polls,
          )
        }
      />
      <div className="templates-type-description">
        {`Create templates of frequently used ${templatesType} that can be accessed inside of an Event or Meeting.`}
      </div>
      <div className="templates-add" onClick={addNewTemplateHandler}>
        + Add New Template
      </div>
      {community &&
        openTemplate &&
        (templatesType === ETemplatesType.polls ? (
          <CreatePollTemplate
            open={openTemplate}
            setOpen={setOpenTemplate}
            community_id={community.id}
            pollTemplate={selectedTemplate as IPollTemplate}
          />
        ) : (
          <CreateAnnouncementTemplate
            open={openTemplate}
            setOpen={setOpenTemplate}
            community_id={community.id}
            announcementTemplate={selectedTemplate as IAnnouncementTemplate}
          />
        ))}
      <div className="templates-content">
        {templatesType === ETemplatesType.polls
          ? pollTemplates.map(pollTemplate => (
              <PollTemplate
                key={pollTemplate.id}
                pollTemplate={pollTemplate}
                editHandler={editTemplateHandler}
                deleteHandler={deleteTemplateHandler}
              />
            ))
          : announcementTemplates.map(announcementTemplate => (
              <AnnouncementTemplate
                key={announcementTemplate.id}
                deleteHandler={deleteTemplateHandler}
                editHandler={editTemplateHandler}
                announcementTemplate={announcementTemplate}
              />
            ))}
      </div>
    </div>
  );
};

export default Templates;
