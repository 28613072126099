import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NamesOfParentRoutes } from "../../../../constants";
import { ECAMM_COMMUNITY_ID } from "../../components/EcammLandingPage/constants";

import { getCommunity } from "containers/Community/store/selectors";
import {
  LandingPageHeader,
  LandingFooter,
  UpcomingEvent,
  FeaturedEvents,
  EventSchedule,
  LandingThemeProvider,
  EcammLandingPage,
} from "containers/LandingPage/components";
import { LandingStateType } from "containers/LandingPage/interfaces";
import {
  loadLandingSettings,
  loadLandingEvents,
  updateParams,
  clearSettings,
  openSocket,
  closeSocket,
} from "containers/LandingPage/store/actions";
import {
  selectLandingData,
  selectSchedule,
  selectUpcomingEvent,
  selectFeaturedEvents,
  selectEventParams,
  selectArchive,
  selectEventsCount,
  selectPastEventsCount,
  selectSocketConnected,
} from "containers/LandingPage/store/selectors";
import { getUserData } from "containers/Auth/store/actions";
import { authentificated, getUser } from "containers/Auth/store/selectors";
import { ERoles } from "containers/Auth";
import { getSubdomain, USER_ROLES } from "shared";
import { checkRoles } from "utils/ACL";
import { hideWidget } from "utils/hubspot";
import { isHubspotInited } from "containers/App/store/selectors";

import "./index.scss";

const { STREAM, EVENTS, COMMUNITIES, DASHBOARD } = NamesOfParentRoutes;

export default function LandingPageContainer() {
  const dispatch = useDispatch();
  const settings = useSelector(selectLandingData());
  const community = useSelector(getCommunity());
  const schedule = useSelector(selectSchedule());
  const archive = useSelector(selectArchive());
  const upComingEvent = useSelector(selectUpcomingEvent());
  const featuredEvents = useSelector(selectFeaturedEvents());
  const params = useSelector(selectEventParams());
  const isAuthenticated = useSelector(authentificated());
  const currentUser = useSelector(getUser());
  const isSocketConnected = useSelector(selectSocketConnected());
  const hubspotInited = useSelector(isHubspotInited());

  const eventsCount = useSelector(selectEventsCount());
  const pastEventCount = useSelector(selectPastEventsCount());

  const { subdomain } = getSubdomain();
  const isEcammLanding = community?.id === ECAMM_COMMUNITY_ID;

  useEffect(() => {
    if (hubspotInited) {
      hideWidget();
    }
  }, [hubspotInited]);

  useEffect(() => {
    if (community && !isSocketConnected) {
      dispatch(openSocket(community.id));
    }
    return () => {
      dispatch(closeSocket());
    };
  }, [dispatch, community, isSocketConnected]);

  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      dispatch(getUserData.request());
    }
  }, [dispatch, isAuthenticated, currentUser]);

  const isCommunityManager = useMemo(() => {
    return (
      currentUser &&
      community &&
      currentUser.user_roles.find(role => role.community_id === community.id && role.role.name === ERoles.manager)
    );
  }, [currentUser, community]);

  const isAdmin = currentUser?.user_roles?.some(r => r.role.name === USER_ROLES.ADMIN.name);

  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      return;
    }
    const firstCommunityCode = currentUser?.members?.[0]?.community?.code;
    const isManager =
      currentUser?.members?.[0]?.community &&
      checkRoles([ERoles.admin, ERoles.manager], currentUser.members[0].community.id);
    const redirectTo =
      !currentUser || currentUser.members.length > 1 || !firstCommunityCode
        ? COMMUNITIES
        : `${firstCommunityCode}${isManager ? DASHBOARD : EVENTS}`;
    dispatch(loadLandingSettings.request({ subdomain, redirectTo }));
    return () => {
      dispatch(clearSettings());
    };
  }, [dispatch, subdomain, currentUser, isAuthenticated]);

  useEffect(() => {
    if (community) {
      dispatch(loadLandingEvents.request({ community_id: community.id, params }));
    }
  }, [community, params, dispatch]);

  const changeParams = (payload: LandingStateType["params"]) => {
    dispatch(updateParams(payload));
  };

  const navigateToEvent = useCallback((code: string) => {
    window.open(`${STREAM}/${code}`);
  }, []);

  const navigateToAdmin = useCallback(() => {
    if (community) {
      window.open(`${community.code}${EVENTS}`);
    }
  }, [community]);

  return (
    settings && (
      <LandingThemeProvider settings={settings}>
        <div className="landing-page-container-wrapper">
          <LandingPageHeader
            settings={settings}
            communityName={community && !isEcammLanding ? community?.name : ""}
            navigateToAdmin={navigateToAdmin}
            isAdmin={!!isCommunityManager || !!isAdmin}
          />
          {isEcammLanding ? <EcammLandingPage /> : null}
          <div className={`landing-page-content-wrapper ${isEcammLanding ? "ecamm" : ""}`}>
            <div className="landing-page-content-width-reducer">
              <div className="top-section-wrapper">
                {upComingEvent && (
                  <div className="upcoming-event-container-wrapper">
                    <UpcomingEvent
                      event={upComingEvent}
                      navigateToEvent={navigateToEvent}
                      isEcammLanding={isEcammLanding}
                    />
                  </div>
                )}
                <div className="featured-events-wrapper">
                  <FeaturedEvents
                    events={featuredEvents}
                    navigateToEvent={navigateToEvent}
                    customLandingPage={isEcammLanding}
                  />
                </div>
              </div>
              <div className="event-schedule-wrapper">
                <EventSchedule
                  schedule={params.schedule_direction === "upcoming" ? schedule : archive}
                  upComingEvent={upComingEvent}
                  params={params}
                  changeParams={changeParams}
                  count={params.schedule_direction === "upcoming" ? eventsCount : pastEventCount}
                  navigateToEvent={navigateToEvent}
                  customLandingPage={isEcammLanding}
                />
              </div>
            </div>
          </div>
          <LandingFooter settings={settings} community={community} />
        </div>
      </LandingThemeProvider>
    )
  );
}
