import { IMember } from "containers/Member";

export interface RoomMember extends IMember {
  is_admin: boolean;
}

export enum BreakoutRoomTypes {
  auditorium = "auditorium",
  lobby = "lobby",
}

export interface RoomInterface {
  id: number;
  code: string;
  name: string | null;
  meeting_id: number;
  members: RoomMember[];
  type: BreakoutRoomTypes;
}

export interface IRoomUpdateData extends Pick<RoomInterface, "name"> {
  memberId?: number;
}
export interface IRoomUpdate extends IRoomUpdateData {
  id: number;
}

export type RoomAttendees = (RoomMember | undefined)[];
