import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "./channelListHeader.scss";
import { Button } from "../../../../shared/components/Button";
import { CanView } from "../../../../shared/components/CanView";
import { SearchInput } from "../../../../shared/components/SearchInput";

import ic_search from "assets/icons/ic_search.svg";

interface ICustomChannelListHeader {
  onSearch: (values: string) => void;
  onAddNewChannel: () => void;
}

export const ChannelListHeader: React.FC<ICustomChannelListHeader> = props => {
  const { onSearch, onAddNewChannel } = props;
  const { t } = useTranslation();

  const [showSearch, setShowSearch] = useState<boolean>(false);

  return (
    <div className="channelListHeader">
      <div className="channelListHeader-heading">{t("Chats")}</div>
      <div className="channelListHeader-actions">
        <div className={classNames("channelListHeader-search", showSearch ? "open" : "")}>
          <CanView condition={showSearch}>
            <SearchInput onChange={onSearch} />
          </CanView>
          <div className="channelListHeader-search_button">
            <Button variant="gray-text" onClick={() => setShowSearch(!showSearch)}>
              <img src={ic_search} alt="" />
            </Button>
          </div>
        </div>
        <div className="channelListHeader-addNew">
          <Button
            width="40"
            radius={30}
            variant="orange"
            onClick={onAddNewChannel}
            style={{ letterSpacing: 0, fontSize: "24px" }}
          >
            {t("+")}
          </Button>
        </div>
      </div>
    </div>
  );
};
