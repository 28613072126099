import "./customMessage.scss";

import moment from "moment";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { MessageUIComponentProps, useChatContext, useMessageContext } from "stream-chat-react";

import { DiscussionTheme } from "../../containers/DiscussionsContainer/DiscussionsContainer";
import { EditMessageObj } from "../../interfaces";
import { SimpleMessage } from "./SimpleMessage";
import { SystemMessage } from "./SystemMessage";
import { defineRestrictionIconSrc } from "../../../Sermons";

import { NOT_EXISTING_MEMBER_NAME } from "shared/constants/defaultNames";
import { getEventMembers, getEvent } from "containers/Sermons/store/selectors";
import { getMember, getRestrictedMembers } from "containers/Member/store/selectors";
import { getSelectedChannelDetails } from "containers/Discussions/store/selectors";

interface CustomMessageProps extends MessageUIComponentProps {
  setEditMessageDetails: (obj: EditMessageObj) => void;
  theme?: DiscussionTheme;
  generalChannel?: boolean;
  isModerator?: boolean;
}

const CustomMessage: React.FC<CustomMessageProps> = props => {
  const { channel, client } = useChatContext();
  const { message } = useMessageContext();
  const { theme, isModerator, generalChannel } = props;
  const event = useSelector(getEvent());
  const eventMembers = useSelector(getEventMembers());
  const selectedChannel = useSelector(getSelectedChannelDetails());
  const communityMembers = useSelector(getMember());
  const restrictedMembers = useSelector(getRestrictedMembers());

  const currentMember = useMemo(() => {
    return communityMembers?.find(m => String(m.id) === message?.user?.id);
  }, [communityMembers, message]);

  const color = useMemo(() => {
    const participant = eventMembers.find(({ memberId }) => memberId === (currentMember?.id || message?.user?.id));
    return participant?.color || selectedChannel?.color;
  }, [currentMember, eventMembers, message, selectedChannel]);

  const userName = useMemo(() => {
    if (currentMember) {
      const { first_name, last_name, email } = currentMember;
      const name = `${first_name} ${last_name}`;
      return name === NOT_EXISTING_MEMBER_NAME ? email : name;
    }

    const ownerMessage = eventMembers.find(member => member.memberId.toString() === message?.user?.id);
    return ownerMessage && ownerMessage.guestName !== message?.user?.name
      ? ownerMessage.guestName
      : message?.user?.name || "";
  }, [message, currentMember, eventMembers]);

  const messageWithoutHeader = useMemo(() => {
    if (channel && message) {
      const { messages } = channel.state;
      const currentMessageIdx = messages.findIndex(m => m.id === message.id);
      const prevMessage = messages[currentMessageIdx - 1];
      if (prevMessage && prevMessage.type === "regular") {
        return (
          prevMessage?.user?.id === message?.user?.id &&
          moment(message.created_at).diff(prevMessage.created_at, "minutes") <= 1
        );
      }
    }
    return false;
  }, [channel, message]);

  const currentMemberGreeter = useMemo(() => {
    return event?.greeters.find(greeter => currentMember && greeter.id === currentMember.id);
  }, [currentMember, event]);

  const restrictionIconSrc = useMemo(() => {
    const currentRestrictedMember = restrictedMembers.find(m => m.member_id === currentMember?.id);
    return defineRestrictionIconSrc(currentRestrictedMember?.restriction_type);
  }, [restrictedMembers, currentMember]);

  if (!message || !client || !channel) {
    return <></>;
  }

  if (message.type === "deleted") {
    return null;
  }

  if (message.system) {
    return <SystemMessage message={message} userName={userName} />;
  }

  return (
    <SimpleMessage
      {...props}
      theme={theme}
      message={message}
      client={client}
      userName={userName}
      handleUserClick={() => {}}
      member={currentMember}
      color={color}
      channelGreeter={currentMemberGreeter}
      messageWithoutHeader={messageWithoutHeader}
      isModerator={isModerator}
      restrictionIconSrc={restrictionIconSrc}
      generalChannel={generalChannel}
    />
  );
};

export default React.memo(CustomMessage);
