import React from "react";
import classnames from "classnames";

import { ECommunityIntroStepNames, ICommunityIntroStep } from "../../../interfaces";

import { Overlay, StyledCheckBox } from "shared";

import "./progressMenu.scss";

interface IProgressMenu {
  closeProgressMenu: () => void;
  openProgressMenu: boolean;
  introductionSteps: ICommunityIntroStep[];
  communityCompleteIntro: () => void;
  stepClickHandler: (name: ECommunityIntroStepNames, step_id: number) => void;
}

const ProgressMenu: React.FC<IProgressMenu> = ({
  closeProgressMenu,
  openProgressMenu,
  introductionSteps,
  communityCompleteIntro,
  stepClickHandler,
}) => {
  return (
    <Overlay show={openProgressMenu} onClose={closeProgressMenu}>
      <div className="progressMenu">
        {introductionSteps.map((item, index) => (
          <div key={index} className="progressMenu-item">
            <StyledCheckBox checked={item.checked} />
            <span
              className={classnames("progressMenu-item-label", { complete: item.checked })}
              onClick={() => !item.checked && stepClickHandler(item.name, item.id)}
            >
              {item.title}
            </span>
          </div>
        ))}
        <div className="progressMenu-divider" />
        <div className="progressMenu-skip" onClick={communityCompleteIntro}>
          Skip. I already know how it works
        </div>
      </div>
    </Overlay>
  );
};

export default ProgressMenu;
