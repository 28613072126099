import React from "react";
import moment from "moment";

import { EventType } from "../../../../Sermons";
import { ITopEventsAnalyticsData } from "../../../interfaces";

import "./index.scss";

interface IProps {
  tableData: ITopEventsAnalyticsData[];
}

const tableHeader: ITopEventsAnalyticsData = {
  attenders: "Unique Attenders",
  name: "Name",
  type: "Type",
  starting_at: "Date",
  ending_at: "",
};

const TopListTable: React.FC<IProps> = ({ tableData }) => {
  return (
    <div className="table">
      {[tableHeader, ...tableData].map((item, index) => {
        const isHeader = index === 0;
        const isMeeting = item.type === EventType.meeting;
        const date = isHeader ? item.starting_at : moment(item.starting_at).format("MMMM D, YYYY");
        const time = isHeader
          ? "Time"
          : `${moment(item.starting_at).format("h:mm A")} - ${moment(item.ending_at).format("h:mm A")}`;

        return (
          <div className="table-data" key={index}>
            <div className="table-data-number">{isHeader ? "#" : `${index}.`}</div>
            <div className="table-data-date">{date}</div>
            <div className="table-data-time">{time}</div>
            <div className="table-data-name">{item.name}</div>
            <div className={`table-data-type ${!isHeader && (isMeeting ? "meeting" : "event")}`}>
              {isHeader ? item.type : isMeeting ? "Meeting" : "Event"}
            </div>
            <div className="table-data-attenders">
              {isHeader ? item.attenders : `${item.attenders} attendee${item.attenders !== 1 ? "s" : ""}`}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TopListTable;
