import { DiscussionTheme } from "../../../../containers/Discussions/containers/DiscussionsContainer/DiscussionsContainer";
import { AppTheme } from "../../../../containers/App/interfaces/themeType";

import { IMember } from "containers/Member";

export interface OnSelectWithMembers {
  (members: IMember[]): void;
}

export enum EEmptyStateTextType {
  chat = "chat",
  event = "event",
}

export interface TSelectMembersModal {
  open: boolean;
  onClose: () => void;
  onSelect: OnSelectWithMembers;
  onSelectWithName?: ({ name, members }: { name: string; members: IMember[] }) => void;
  onSearch?: (search: string) => void;
  multiselect?: boolean;
  title: string;
  selectText?: string;
  notAllowedMemberIds?: number[];
  withName?: boolean;
  notFoundText?: string;
  useFilters?: boolean;
  allowSelectQty?: number;
  theme?: DiscussionTheme;
  emptyStateTextType: EEmptyStateTextType;
  managerView?: boolean;
  styleTheme?: AppTheme;
}
