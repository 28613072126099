export const MESSAGE_UPDATE_EVENTS = [
  "notification.added_to_channel",
  "notification.removed_from_channel",
  "notification.message_new",
  "notification.mark_read",
  "message.new",
  "message.read",
  "added_to_channel",
  "removed_from_channel",
  "message.deleted",
];
export const EMPTY_CHANNELS_FILTER = { $exists: false };
export const MAX_QUERY_CHANNELS_LIMIT = 30;
export const MESSAGES_LIMIT = { messagesCount: 3, timeInterval: 10000 };
export const CHAT_DRAFTS_KEY = "chat_drafts";
