import { ERoles, IUser, Permissions } from "../containers/Auth";
import store from "..";
import { USER_ROLES } from "../shared";

export const checkPermissions = (
  allowedPermissions: Permissions[],
  communityId?: number,
  user?: IUser | null,
): boolean => {
  if (store) {
    const { ADMIN } = USER_ROLES;

    if (!user) {
      return false;
    }

    return user.user_roles.some(({ community_id, role }) =>
      role.name === ADMIN.name || !communityId || community_id === communityId
        ? role.permissions?.find(({ name }) => allowedPermissions.includes(name))
        : false,
    );
  } else {
    return false;
  }
};

export const checkRoles = (roles: ERoles[], communityId?: number): boolean => {
  if (store) {
    const { ADMIN } = USER_ROLES;
    const {
      auth: { user },
    } = store.getState();

    if (!user) {
      return false;
    }

    return user.user_roles.some(
      ({ role, community_id }) =>
        (community_id === communityId || role.name === ADMIN.name) && roles.some(roleName => roleName === role.name),
    );
  } else {
    return false;
  }
};
