import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { getMember, getUser } from "../../../Auth/store/selectors";
import { actions } from "../../../Member";

import { ICommunityItem } from "containers/Community";
import { AlertDialog, Button } from "shared";
import { ButtonVariants } from "shared/components/Button/Button";
import defaultCommunityImage from "assets/images/community_default_image.svg";

import "./communitySwitch.scss";

interface ICommunitySwitch {
  isCollapsed: boolean;
  isUpgradeEnabled: boolean;
  editLabel: string;
  upgradeLabel: string;
  items: ICommunityItem[];
  selected: ICommunityItem | null;
  onClick: (...args: any[]) => any;
  onSwitch?: () => void;
  showSwitcher: boolean;
  upgradeButtonVariant: ButtonVariants;
  activateHandler: () => void;
}

const CommunitySwitch: React.FC<ICommunitySwitch> = ({
  isUpgradeEnabled,
  isCollapsed,
  selected,
  upgradeLabel,
  onClick,
  onSwitch,
  showSwitcher,
  upgradeButtonVariant,
  activateHandler,
}) => {
  const [imageError, setImageError] = useState(false);
  const [showLeaveCommunityPopup, setShowLeaveCommunityPopup] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentMember = useSelector(getMember());
  const currentUser = useSelector(getUser());
  const community = selected;

  const leaveCommunity = () => {
    if (community && currentMember)
      dispatch(
        actions.leaveMember.request({
          id: currentMember?.id,
          community_id: community.id,
          callback: () => {
            setShowLeaveCommunityPopup(false);
            if (onSwitch) {
              onSwitch();
            }
          },
        }),
      );
  };

  return (
    <>
      <AlertDialog
        mode="confirm"
        open={showLeaveCommunityPopup}
        confirmText={t("Leave")}
        title={t("Leave Community")}
        message={t("Are you sure you want to leave this Community?")}
        onCancel={() => setShowLeaveCommunityPopup(false)}
        onConfirm={leaveCommunity}
        confirmClassName={"leave-community-popup-confirm"}
      />

      <div className={classnames("communitySwitch", { collapsed: isCollapsed })}>
        <img
          className={classnames("communitySwitch-img", { collapsed: isCollapsed, "no-image": !community?.image_url })}
          src={imageError ? defaultCommunityImage : community?.image_url || defaultCommunityImage}
          onError={() => setImageError(true)}
          onClick={() => onClick()}
          alt="community"
        />
        {!isCollapsed && (
          <div onClick={() => onClick()} className={classnames("communitySwitch-text", { collapsed: isCollapsed })}>
            {community?.name ? community?.name : ""}
          </div>
        )}
        {community && showSwitcher && (
          <>
            <div
              className={classnames("communitySwitch-switch", { collapsed: isCollapsed, disabled: !onSwitch })}
              onClick={onSwitch}
            >
              {isCollapsed ? t("Switch") : t("Switch community")}
              <div className={classnames("communitySwitch-switch-arrow", { collapsed: isCollapsed })} />
            </div>
            <div className={classnames("communitySwitch-counts", { collapsed: isCollapsed })}>
              <div className="communitySwitch-counter">
                <div className="communitySwitch-counter-label">{t("Members")}</div>
                <div className="communitySwitch-counter-number">{community.members_count}</div>
              </div>
              <div className="communitySwitch-counter">
                <div className="communitySwitch-counter-label">{t("Events")}</div>
                <div className="communitySwitch-counter-number">{community.meetings_count}</div>
              </div>
            </div>
          </>
        )}
        {isUpgradeEnabled && (
          <div className={classnames("communitySwitch-activate", { collapsed: isCollapsed })}>
            <Button variant={upgradeButtonVariant} width={isCollapsed ? 72 : 176} onClick={activateHandler}>
              <span>{upgradeLabel}</span>
            </Button>
          </div>
        )}

        {community && showSwitcher && currentUser && currentUser.id !== community.main_contact_id && (
          <div className={classnames("communitySwitch-activities", { collapsed: isCollapsed })}>
            <Button variant={"dark-blue"} onClick={() => setShowLeaveCommunityPopup(true)}>
              {isCollapsed ? t("Leave") : t("Leave community")}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default CommunitySwitch;
