import React, { useState } from "react";
import { useFormikContext } from "formik";

import { ICommunityLandingSettings } from "../../../interfaces";

import { AlertDialog, CustomFormikField } from "shared";

import "./index.scss";

const AppearanceLinks: React.FC = () => {
  const { values, handleChange, errors, handleBlur, setFieldValue } = useFormikContext<ICommunityLandingSettings>();

  const [linkIndexToDelete, setLinkIndexToDelete] = useState<number | null>(null);
  const [openDeleteLinkDialog, setOpenDeleteLinkDialog] = useState(false);

  return (
    <div className="appearance-links">
      <AlertDialog
        open={openDeleteLinkDialog}
        title="Delete Navigation Link"
        message="Are you sure that you want to delete this navigation link?"
        onConfirm={() => {
          setFieldValue("links", [...values.links.filter((item, ind) => ind !== linkIndexToDelete)]);
          setOpenDeleteLinkDialog(false);
          setLinkIndexToDelete(null);
        }}
        onCancel={() => {
          setOpenDeleteLinkDialog(false);
          setLinkIndexToDelete(null);
        }}
        mode="confirm"
        confirmText="Delete"
        confirmClassName="delete-link-button"
      />
      <div className="left-side">
        <div className="links-list">
          {values.links.map((link, index) => (
            <div className="link-item" key={index}>
              <div className="link-number">{index + 1}.</div>
              <div className="link-item-input">
                <CustomFormikField
                  fullWidth
                  required
                  placeholder="Link Title"
                  label="Your Community Subdomain"
                  name={`links[${index}].name`}
                  errors={errors}
                  value={values.links[index].name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.links && errors.links[index] && (errors.links[index] as any).name && (
                  <div className="label-error">{(errors.links[index] as any).name}</div>
                )}
              </div>
            </div>
          ))}
        </div>
        {values.links.length < 5 && (
          <div className="links-button-wrapper">
            <button onClick={() => setFieldValue("links", [...values.links, { name: "", value: "" }])}>
              <span>Add Navigation Link</span>
            </button>
          </div>
        )}
      </div>
      <div className="right-side">
        <div className="links-list">
          {values.links.map((link, index) => (
            <div className="link-item" key={index}>
              <div className="link-item-input">
                <CustomFormikField
                  fullWidth
                  required
                  placeholder="Link Url"
                  label="Your Community Subdomain"
                  name={`links[${index}].value`}
                  errors={errors}
                  value={values.links[index].value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.links && errors.links[index] && (errors.links[index] as any).value && (
                  <div className="label-error">{(errors.links[index] as any).value}</div>
                )}
              </div>
              <button
                onClick={() => {
                  if (!!link.value || !!link.name) {
                    setLinkIndexToDelete(index);
                    setOpenDeleteLinkDialog(true);
                  } else {
                    setFieldValue("links", [...values.links.filter((item, ind) => ind !== index)]);
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppearanceLinks;
