import { Stripe } from "@stripe/stripe-js";

export interface IStripeState {
  stripe: Stripe | null;
}

export interface RedirectToCheckoutOptions {
  customerEmail?: string;
  stripePriceCode: string;
  communityId: string;
  userId: string;
  successPath: string;
  cancelPath: string;
}

export interface AddPaymentMethodOptions {
  communityId: string;
  userId: string;
  successPath: string;
  cancelPath: string;
}

export enum EStripeResult {
  success = "1",
  fail = "0",
  paymentMethod = "paymentMethod",
  billing = "billing",
}
