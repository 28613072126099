import config from "../config";
import { FIREBASE_EVENTS } from "../shared/interfaces/Firebase";

let isLoaded = false;

export const init = () => {
  const { googleAdv } = config;
  if (!googleAdv?.conversionId) {
    return;
  }
  if (isLoaded) {
    return;
  }

  isLoaded = true;

  if (typeof window !== "undefined" && typeof document !== "undefined") {
    addGlobalTag(googleAdv.conversionId);
    window.dataLayer = window.dataLayer || [];
    const gtag = function (...args: any[]) {
      window?.dataLayer?.push(...args);
    } as any;
    gtag("js", new Date());
    gtag("config", googleAdv.conversionId);
    gtag("config", googleAdv.measurementId);
    window.gtag = gtag;
  }
};

const addGlobalTag = (id: string) => {
  const script = document.createElement("script") as any;
  script.async = 1;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  const initialScript = document.getElementsByTagName("script")[0];
  if (initialScript && initialScript.parentNode) {
    initialScript.parentNode.insertBefore(script, initialScript);
  }
};
export const sendConversionEvent = () => {
  const { googleAdv } = config;
  if (typeof window.gtag === "function" && !!googleAdv) {
    window.gtag("event", "conversion", {
      send_to: `${googleAdv.conversionId}/${googleAdv.conversionLabel}`,
    });
    window.gtag("event", FIREBASE_EVENTS.NEW_COMMUNITY, {
      send_to: googleAdv.measurementId,
    });
  }
};
