import React, { useState, useRef, useEffect } from "react";
import Timer from "react-compound-timer";

import { formatValue } from "containers/Sermons/utils";

import "./styles.scss";

type Props = {
  initTime: number;
  reinitOnChange?: boolean;
  onTimeHasCome: () => void;
  showHours?: boolean;
  showMinutes?: boolean;
  showSeconds?: boolean;
  onTimeChange?: (time: number) => void;
  inTwoLines?: boolean;
};

const CountdownTimer: React.FC<Props> = ({
  initTime,
  reinitOnChange,
  onTimeHasCome,
  showHours = true,
  showMinutes = true,
  showSeconds = true,
  onTimeChange,
  inTwoLines = false,
}) => {
  const [hasHours, setHasHours] = useState(true);
  const [hasMinutes, setHasMinutes] = useState(true);
  const resetFunction = useRef<any>(null);

  useEffect(() => {
    if (reinitOnChange && resetFunction.current?.setTime) {
      resetFunction.current.setTime(initTime);
    }
  }, [initTime, reinitOnChange]);

  const formatValueDays = (v: number) => {
    return `${v} Day${v === 1 ? "" : "s"}${hasHours || hasMinutes ? "," : ""}`;
  };

  const formatValueHours = (v: number) => {
    if (v) {
      setHasHours(true);
      return ` ${v} Hour${v === 1 ? "" : "s"}${hasMinutes ? "," : ""}`;
    } else {
      setHasHours(false);
      return "";
    }
  };
  const formatValueMinutes = (v: number) => {
    if (v) {
      setHasMinutes(true);
      return ` ${v} Minute${v === 1 ? "" : "s"}`;
    } else {
      setHasMinutes(false);
      return "";
    }
  };
  return (
    <div className="countdown-timer">
      <Timer initialTime={initTime > 0 ? initTime : 0} direction="backward" formatValue={formatValue}>
        {({ getTime, setTime }: { [key: string]: (...args: any[]) => any }) => {
          const countdownTime = getTime();
          if (!resetFunction.current) {
            resetFunction.current = { setTime };
          }
          if (countdownTime < 500) {
            onTimeHasCome();
          }
          onTimeChange && onTimeChange(countdownTime);
          return countdownTime < 24 * 60 * 60 * 1000 ? (
            <>
              {showHours && <Timer.Hours />}
              {showHours && showMinutes && ":"}
              {showMinutes && <Timer.Minutes />}
              {showMinutes && showSeconds && ":"}
              {showSeconds && <Timer.Seconds />}
            </>
          ) : (
            <>
              <Timer.Days formatValue={formatValueDays} />
              <Timer.Hours formatValue={formatValueHours} />
              {hasHours && hasMinutes && inTwoLines && <br />}
              <Timer.Minutes formatValue={formatValueMinutes} />
            </>
          );
        }}
      </Timer>
    </div>
  );
};

export default CountdownTimer;
