import React from "react";

import { Button } from "shared";
import search from "assets/icons/search_64x64.svg";

import "./styles.scss";

export interface IProps {
  showUpgrade: boolean;
  leaveEvent: (shouldRedirect?: boolean, toBilling?: boolean) => void;
}

const EventBlocker: React.FC<IProps> = ({ showUpgrade, leaveEvent }) => {
  return (
    <div className="event-blocker">
      <div className="event-blocker__image">
        <img src={search} alt="search" />
      </div>
      <div className="event-blocker__title">Looks like this Meeting no longer exists</div>
      <div className="event-blocker__description">
        Unfortunately, this meeting no longer exists.
        {showUpgrade
          ? " Your Community may no longer be on a subscription that supports video conference. You can upgrade your subscription to use Meetings on Altar Live."
          : " Please try contacting the Community Admin or navigating back to the the Community Home page."}
      </div>
      <div className="event-blocker__actions">
        <Button onClick={() => leaveEvent()} variant="blue" width={240}>
          Back To Community Home
        </Button>
        {showUpgrade && (
          <Button onClick={() => leaveEvent(true, true)} variant="orange" width={240}>
            Upgrade Account
          </Button>
        )}
      </div>
    </div>
  );
};
export default EventBlocker;
