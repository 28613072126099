import ReactPixel from "react-facebook-pixel";

import config from "../config";
import { FB_PIXEL_EVENTS } from "../shared/interfaces/fbPixel";
import { ICommunity, ICommunityItem, ICommunitySubscriptions } from "../containers/Community";
import { IUser } from "../containers/Auth";

export const init = () => {
  ReactPixel.init(config.facebook.appId, undefined, {
    autoConfig: true,
    debug: config.env !== "production",
  });
};

const trackEvent = (event: FB_PIXEL_EVENTS, data?: Record<string, any>, useCustomEvent = false) => {
  if (useCustomEvent) {
    return ReactPixel.trackCustom(event, data);
  }
  ReactPixel.track(event, data);
};

export const trackPurchaseEvent = (
  currentCommunity: ICommunity,
  currentUser: IUser,
  subscriptions: ICommunitySubscriptions,
) => {
  const {
    currentSubscriptions: [subscription],
  } = subscriptions;

  const data = {
    currency: "USD",
    value: subscription.price,
    product: subscription.title,
    stripe_status: "active",
    country: currentCommunity.country,
    state: currentCommunity.state,
    city: currentCommunity.city,
    user_id: currentUser.id,
    community_id: currentCommunity.id,
  };
  trackEvent(FB_PIXEL_EVENTS.PURCHASE, data);
};

export const trackNewCommunityEvent = (community: ICommunityItem, user?: IUser | null) => {
  let data: Record<string, any> = {
    community_id: community.id,
    country: community.country,
    state: community.state,
    city: community.city,
  };

  if (user) {
    const userFullName = `${user.first_name} ${user.last_name}`;
    data = { ...data, user_id: user.id, user_name: userFullName, user_email: user.email };
  }

  trackEvent(FB_PIXEL_EVENTS.NEW_COMMUNITY, data, true);
};

export const trackLogInEvent = (user?: IUser) => {
  if (!user) {
    return;
  }

  const data = {
    user_id: user.id,
  };
  trackEvent(FB_PIXEL_EVENTS.LOG_IN, data, true);
};
