import React from "react";
import classnames from "classnames";

import "./styles.scss";

interface IMobileWrapperWarning {
  title?: string;
  text?: string;
  classNames?: string;
}

const MobileWrapperWarning: React.FC<IMobileWrapperWarning> = ({ title = "Sorry", text, classNames, children }) => {
  return (
    <div className={classnames("mobileWrapper-warning", classNames, { withChildren: children })}>
      {title && <h3 className="mobileWrapper-warning-title">{title}</h3>}
      {text && <div className="mobileWrapper-warning-text">{text}</div>}
      {children && <div className="mobileWrapper-warning-content">{children}</div>}
    </div>
  );
};

export default MobileWrapperWarning;
