import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";

import { connectSocket, exitRoom, getUserMediaDevices, pageReload } from "../store/actions";
import { selectIsEnded, selectStream, selectIsConnecting, selectRooms, getUserDevices } from "../store/selectors";
import { MediaConstraints } from "../constants";

import { changeMediaConstraints, storageMediaDevicesInfo } from "shared/utils/mediaStreamHelpers";
import { getAllowedRoomMembersCount } from "shared/utils/rooms";
import { IRouteProps } from "shared";
import { getEvent, getEventHostOrAdmin } from "containers/Sermons/store/selectors";
import { notificationActions } from "containers/Notifications/store/actions";
import { authentificated, getMember } from "containers/Auth/store/selectors";

export function useRoomConnect() {
  const { roomCode, eventCode, communityCode } = useParams<IRouteProps>();
  const isEnded = useSelector(selectIsEnded());
  const stream = useSelector(selectStream());
  const isConnecting = useSelector(selectIsConnecting());
  const rooms = useSelector(selectRooms());
  const isAuthenticated = useSelector(authentificated());
  const event = useSelector(getEvent());
  const member = useSelector(getMember());
  const hasPrivilegeToJoinRoom = useSelector(getEventHostOrAdmin());
  const dispatch = useDispatch();
  const history = useHistory();
  const userDevices = useSelector(getUserDevices());

  const constraints = useMemo(() => {
    const storageDevices = storageMediaDevicesInfo.get();
    return changeMediaConstraints(
      [storageDevices?.audio?.label, storageDevices?.video?.label],
      userDevices,
      MediaConstraints,
    );
  }, [userDevices]);

  useEffect(() => {
    if (!userDevices) {
      dispatch(getUserMediaDevices.request());
    }
  }, [dispatch, userDevices]);

  useEffect(() => {
    if (!rooms || !member) return;
    const current_room = rooms.find(r => r.code === roomCode);
    if (current_room) {
      const { members } = current_room;
      let is_current_member_in_this_room = false;
      let isOccupiedByAdmin = false;
      members.forEach(m => {
        is_current_member_in_this_room = m.id === member?.id || is_current_member_in_this_room;
        isOccupiedByAdmin = m?.is_admin || isOccupiedByAdmin;
      });
      const allowedRoomMembersCount = getAllowedRoomMembersCount(hasPrivilegeToJoinRoom, isOccupiedByAdmin);
      if (members.length >= allowedRoomMembersCount && !is_current_member_in_this_room) {
        // eslint-disable-next-line no-console
        console.log(new Date(), "frontend-Room is full", {
          members,
          is_current_member_in_this_room: is_current_member_in_this_room,
          member,
        });
        history.push(`${window.location.pathname.replace(roomCode || "", "").slice(0, -1)}`);
        dispatch(exitRoom.request());
        dispatch(notificationActions.error("Room is full", "Please, choose another one."));
        return;
      }
    } else {
      return;
    }

    if (!isConnecting && stream && roomCode && event && isAuthenticated && member) {
      dispatch(connectSocket.request({ room_code: roomCode, meeting_id: event.id, member }));
      window.addEventListener("beforeunload", () => dispatch(pageReload.request()));
    }
  }, [
    isConnecting,
    stream,
    roomCode,
    dispatch,
    event,
    isAuthenticated,
    member,
    history,
    rooms,
    hasPrivilegeToJoinRoom,
  ]);

  return {
    roomCode,
    isEnded,
    stream,
    isConnecting,
    rooms,
    isAuthenticated,
    eventCode,
    communityCode,
    event,
    constraints,
  };
}
