import React from "react";

import { IEvent, IPoll, PollStatus } from "../../../../interfaces";
import PollResults from "../PollResults/PollResults";
import PollVoting from "../PollVoting/PollVoting";
import PollControl from "../PollControll/PollControl";

import { timeToAMPM } from "shared/utils/getStream";
import no_polls_icon from "assets/icons/no_polls_icon.svg";

interface IProps {
  polls: IPoll[];
  canEditPoll: boolean;
  event: IEvent;
  memberVoteStatus: (poll: IPoll) => boolean;
  onEdit: (poll: IPoll) => void;
  onOpen: (poll: IPoll) => void;
}

const PollsList: React.FC<IProps> = ({ polls, canEditPoll, event, memberVoteStatus, onOpen, onEdit }) => {
  return (
    <div className="pollsDialog-content">
      {polls?.length ? (
        polls.map(poll => (
          <div className="pollsDialog-content-poll" key={poll.id}>
            <div className="pollDescription" onClick={() => onOpen(poll)}>
              <div className="pollDescription-title">
                {poll.title}
                <span>{poll.show_results && " (Live results)"}</span>
              </div>
              <div className="pollDescription-createInfo">
                {poll.status === PollStatus.scheduled && poll.scheduled_at
                  ? `Will be published at ${timeToAMPM(poll.scheduled_at, { skipDay: true })}`
                  : `${poll.updated_by || poll.created_by} ${timeToAMPM(poll.updated_at || poll.created_at)}`}
              </div>
              <div className="pollDescription-question">{poll.poll_question}</div>
            </div>
            {memberVoteStatus(poll) || poll.status !== PollStatus.published ? (
              <PollResults poll={poll} />
            ) : (
              <PollVoting poll={poll} />
            )}
            {canEditPoll && <PollControl poll={poll} event={event} openEditPoll={() => onEdit(poll)} />}
          </div>
        ))
      ) : (
        <div className="pollsDialog-content-noPolls">
          <div className="pollsDialog-content-noPolls-img">
            <img src={no_polls_icon} alt="No polls" />
          </div>
          <div className="pollsDialog-content-noPolls-text">No Polls yet</div>
        </div>
      )}
    </div>
  );
};

export default PollsList;
