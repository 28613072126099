import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMember } from "../../../../Auth/store/selectors";
import PollCreation from "./PollCreation/PollCreation";
import { getEventSettings, getPolls, getPollTemplates } from "../../../store/selectors";
import { checkMeetingRole } from "../../../utils";
import { EventPermissions } from "../../../../Auth";
import PollDetails from "./PollDetails/PollDetails";
import { PollsList } from "./PollsList";
import { PollTemplatesList } from "./PollTemplatesList";
import { selectors } from "../../../store";

import { createPoll, updateEventSettings, updatePoll } from "containers/Sermons/store/actions";
import { usePermissions } from "shared";
import {
  EInteractionTab,
  EventSideDialogHeaderTypes,
} from "shared/components/Event/EventSideDialog/EventSideDialogHeader";
import { IEvent, IPoll, IPollToSubmit, PollStatus, SermonRoles } from "containers/Sermons/interfaces";
import { Button, EventSideDialog } from "shared/components";
import poll_icon_grey from "assets/icons/poll_icon_grey.svg";

import "./eventPollsDialog.scss";

interface IEventPollsDialog {
  open: boolean;
  event: IEvent;
  onClose?: () => void;
  className?: string;
}

const EventPollsDialog: React.FC<IEventPollsDialog> = props => {
  const { onClose, open, event } = props;

  const dispatch = useDispatch();

  const [openPollCreation, setOpenPollCreation] = useState(false);
  const [openPollDetails, setOpenPollDetails] = useState(false);
  const [openPollTemplates, setOpenPollTemplates] = useState(false);
  const [checkUnsavedData, setCheckUnsavedData] = useState(false);
  const [selectedPoll, setSelectedPoll] = useState<IPoll | null>(null);

  const canEdit = usePermissions([EventPermissions.edit], event?.community_id);

  const currentMember = useSelector(getMember());
  const polls = useSelector(getPolls());
  const pollTemplates = useSelector(getPollTemplates());
  const { pullToFind } = useSelector(getEventSettings());
  const { interactionTab } = useSelector(selectors.getEventSettings());

  const canEditPoll = useMemo(
    () =>
      Boolean(
        canEdit ||
          (event && currentMember && checkMeetingRole(event, currentMember, [SermonRoles.greeter, SermonRoles.host])),
      ),
    [canEdit, currentMember, event],
  );
  const sortedPolls = useMemo(() => [...polls].sort((a, b) => a.position - b.position), [polls]);
  const filteredPolls = useMemo(() => {
    return sortedPolls.filter(poll =>
      !canEditPoll || interactionTab !== EInteractionTab.draft
        ? [PollStatus.closed, PollStatus.published].includes(poll.status)
        : [PollStatus.draft, PollStatus.scheduled].includes(poll.status),
    );
  }, [sortedPolls, canEditPoll, interactionTab]);

  const pollForDetails = useMemo(() => {
    return polls.find(poll => poll.id === selectedPoll?.id) || null;
  }, [polls, selectedPoll]);

  const memberVoteStatus = useCallback(
    (poll: IPoll | null) => {
      return (
        poll?.options.some(option => {
          return option.vote_results.some(result => result.member_id === currentMember?.id);
        }) ?? false
      );
    },
    [currentMember],
  );

  const setInteractionTab = useCallback(
    (interactionTab: EInteractionTab) => {
      dispatch(updateEventSettings({ interactionTab }));
    },
    [dispatch],
  );

  const onEditPoll = useCallback((poll: IPoll) => {
    setOpenPollCreation(true);
    setSelectedPoll(poll);
  }, []);

  const onOpenPoll = useCallback((poll: IPoll) => {
    setOpenPollDetails(true);
    setSelectedPoll(poll);
  }, []);

  const onSubmit = useCallback(
    (data: IPollToSubmit) => {
      const { poll, callback } = data;
      const preparedData = {
        poll: {
          id: poll.id,
          title: poll.title,
          poll_question: poll.poll_question,
          multiple_answers: poll.multiple_answers,
          options: [...poll.options],
          meeting_id: event.id,
          show_results: poll.status === PollStatus.closed ? true : poll.show_results,
          status: poll.status,
          position: poll.id ? poll.position : sortedPolls[sortedPolls.length - 1]?.position + 1 || 0,
        },
        callback,
      };
      if (poll?.id) {
        dispatch(updatePoll.request(preparedData));
      } else {
        dispatch(createPoll.request(preparedData));
      }
    },
    [dispatch, event.id, sortedPolls],
  );

  useEffect(() => {
    if (pullToFind) {
      const foundPoll = polls.find(poll => poll.id === pullToFind);
      if (foundPoll) {
        setOpenPollDetails(true);
        setSelectedPoll(foundPoll);
      }
      dispatch(
        updateEventSettings({
          pullToFind: null,
        }),
      );
    }
  }, [pullToFind, polls, dispatch]);

  useEffect(() => {
    if (openPollDetails && !polls.some(poll => poll.id === selectedPoll?.id)) {
      setOpenPollDetails(false);
    }
  }, [polls, openPollDetails, selectedPoll]);

  return openPollCreation || openPollDetails ? (
    <EventSideDialog
      title={(openPollDetails ? selectedPoll?.title : "New Poll") || ""}
      open={open}
      onClose={onClose}
      headerType={EventSideDialogHeaderTypes.poll_creation}
      onBack={() => {
        if (openPollDetails) {
          setOpenPollCreation(false);
          setOpenPollDetails(false);
          setOpenPollTemplates(false);
        } else {
          setCheckUnsavedData(true);
        }
      }}
      image={openPollDetails ? poll_icon_grey : ""}
    >
      {openPollDetails ? (
        <PollDetails
          poll={pollForDetails}
          memberVoted={selectedPoll ? memberVoteStatus(pollForDetails) : false}
          canEditPoll={canEditPoll}
        />
      ) : (
        <PollCreation
          closeCreation={() => {
            setOpenPollCreation(false);
            setCheckUnsavedData(false);
          }}
          poll={selectedPoll}
          checkUnsavedData={checkUnsavedData}
          stopCheckUnsavedData={() => setCheckUnsavedData(false)}
          onSubmit={onSubmit}
        />
      )}
    </EventSideDialog>
  ) : (
    <EventSideDialog
      headerType={
        !canEditPoll || openPollTemplates
          ? EventSideDialogHeaderTypes.default
          : EventSideDialogHeaderTypes.interaction_tabs
      }
      title={openPollTemplates ? "Templates" : "Polls"}
      open={open}
      onClose={onClose}
      onBack={openPollTemplates ? () => setOpenPollTemplates(false) : undefined}
      setInteractionTab={setInteractionTab}
      interactionTab={interactionTab}
    >
      <div className="pollsDialog">
        {openPollTemplates ? (
          <PollTemplatesList templates={pollTemplates} onEdit={onEditPoll} meeting_id={event.id} onPublish={onSubmit} />
        ) : (
          <>
            <PollsList
              polls={filteredPolls}
              canEditPoll={canEditPoll}
              event={event}
              memberVoteStatus={memberVoteStatus}
              onEdit={onEditPoll}
              onOpen={onOpenPoll}
            />
            {canEditPoll && (
              <>
                <Button
                  className="pollsDialog-addBtn"
                  variant="orange"
                  onClick={() => {
                    setOpenPollCreation(true);
                    setSelectedPoll(null);
                  }}
                >
                  Add New Poll
                </Button>
                <Button variant="white-text" onClick={() => setOpenPollTemplates(true)}>
                  Use Templates
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </EventSideDialog>
  );
};

export default EventPollsDialog;
