import produce from "immer";
import { Reducer } from "redux";

import { SermonActionTypes } from "./constants";
import { EventStatus, IAnnouncement, IEventsStateType, IPollReadResponse, IPollVote } from "../interfaces";

import { DiscussionTab } from "shared/components/Event/EventSideDialog/EventSideDialogHeader/EventSideDialogDiscussionTabs";
import { EInteractionTab } from "shared/components/Event/EventSideDialog/EventSideDialogHeader";

const initialState: IEventsStateType = {
  sermonList: [],
  sermon: null,
  sermonMembers: [],
  eventMembers: [],
  blockedEventMembers: [],
  signature: null,
  state: {
    spotlight: -1,
    mute: false,
    unmuted: [],
    activeSlideId: 0,
    reaction: null,
  },
  error: null,
  slotIsAvailable: true,
  eventIntegrations: null,
  eventShortLink: null,
  eventSettings: {
    activeDashboardTab: null,
    isLoading: false,
    memberToFind: null,
    announcementToFind: null,
    pullToFind: null,
    selectedTab: DiscussionTab.general,
    showTables: true,
    interactionTab: EInteractionTab.published,
  },
  socketConnected: false,
  goingLiveTime: 0,
  allowEnded: false,
  changingStatus: false,
  leavingEvent: false,
  recurringEvent: null,
  participantsLimitExceeded: false,
  updatedFields: [],
  announcements: [],
  announcementTemplates: [],
  selectedAnnouncement: null,
  polls: [],
  pollTemplates: [],
  isEventHostOrAdmin: false,
  isVimeoTokenValid: false,
  knockRejected: false,
  waitingToJoinMembers: [],
  changeStatusOptions: null,
};

const reducer: Reducer<IEventsStateType> = (state = initialState, action) => {
  switch (action.type) {
    case SermonActionTypes.FETCH_SERMONS_LIST_SUCCESS: {
      return produce(state, nextState => {
        nextState.sermonList = action.payload;
      });
    }
    case SermonActionTypes.FETCH_SERMON_BY_ID_SUCCESS: {
      return produce(state, nextState => {
        nextState.sermon = action.payload;
        nextState.sermonMembers = [];
        nextState.state = { ...initialState.state };
        nextState.error = null;
        nextState.updatedFields = [];
      });
    }
    case SermonActionTypes.FETCH_SERMON_BY_ID_FAILURE: {
      return produce(state, nextState => {
        nextState.error = action.payload;
        nextState.sermonMembers = [];
        nextState.sermon = null;
        nextState.updatedFields = [];
      });
    }
    case SermonActionTypes.CREATE_SERMON_SUCCESS: {
      return produce(state, nextState => {
        nextState.sermon = action.payload;
        nextState.updatedFields = [];
      });
    }
    case SermonActionTypes.UPDATE_SERMON_SUCCESS: {
      return produce(state, nextState => {
        nextState.sermon = action.payload;
        nextState.updatedFields = [];
      });
    }
    case SermonActionTypes.REMOVE_EVENT_SUCCESS: {
      return produce(state, nextState => {
        nextState.sermonList = state.sermonList.filter(sermon => sermon.id !== action.payload);
      });
    }
    case SermonActionTypes.START_EVENT_SUCCESS: {
      return produce(state, nextState => {
        nextState.sermon = state.sermon ? { ...state.sermon, status: EventStatus.started } : null;
      });
    }
    case SermonActionTypes.CLEAR_CURRENT_SERMON: {
      return produce(state, nextState => {
        nextState.sermon = null;
        nextState.signature = null;
        nextState.allowEnded = false;
        nextState.changingStatus = false;
        nextState.participantsLimitExceeded = false;
        nextState.updatedFields = [];
      });
    }
    case SermonActionTypes.CLEAR_EVENT_ERROR: {
      return produce(state, nextState => {
        nextState.error = null;
      });
    }
    case SermonActionTypes.CHECK_SLOT_SUCCESS: {
      return produce(state, nextState => {
        nextState.slotIsAvailable = action.payload;
      });
    }
    case SermonActionTypes.WS_MEMBERS_MAP: {
      return produce(state, nextState => {
        nextState.sermonMembers = action.map;
      });
    }
    case SermonActionTypes.WS_EVENT_MEMBERS_SUCCESS: {
      return produce(state, nextState => {
        nextState.eventMembers = action.payload;
      });
    }
    case SermonActionTypes.WS_STATE_SPOTLIGHT: {
      return produce(state, nextState => {
        nextState.state.spotlight = action.zoomId;
      });
    }
    case SermonActionTypes.WS_STATE_MUTE: {
      return produce(state, nextState => {
        nextState.state.mute = action.mute;
      });
    }
    case SermonActionTypes.WS_STATE_UNMUTED: {
      return produce(state, nextState => {
        nextState.state.unmuted = action.unmuted;
      });
    }
    case SermonActionTypes.WS_EVENT_UPDATED: {
      return produce(state, nextState => {
        nextState.sermon = state.sermon ? { ...state.sermon, ...action.data } : null;
        nextState.updatedFields = Object.keys(action.data);
        if (state.eventIntegrations && action.data.status) {
          nextState.eventIntegrations = { ...state.eventIntegrations, status: action.data.status };
        }
      });
    }

    case SermonActionTypes.WS_SLIDE_CHANGED: {
      return produce(state, nextState => {
        nextState.state.activeSlideId = action.data.id;
      });
    }
    case SermonActionTypes.WS_REACTION_RECEIVED: {
      return produce(state, nextState => {
        nextState.state.reaction = action.data;
      });
    }
    case SermonActionTypes.WS_BLOCKED_EVENT_MEMBERS: {
      return produce(state, nextState => {
        nextState.blockedEventMembers = action.payload;
      });
    }
    case SermonActionTypes.FETCH_SERMON_SIGNATURE_SUCCESS: {
      return produce(state, nextState => {
        nextState.signature = action.payload.signature;
      });
    }
    case SermonActionTypes.EVENT_INTEGRATIONS_SUCCESS: {
      return produce(state, nextState => {
        nextState.eventIntegrations = action.payload;
      });
    }
    case SermonActionTypes.EVENT_INTEGRATIONS_FAILURE: {
      return produce(state, nextState => {
        nextState.eventIntegrations = null;
      });
    }
    case SermonActionTypes.EVENT_SHORT_LINK_SUCCESS: {
      return produce(state, nextState => {
        nextState.eventShortLink = action.payload;
      });
    }
    case SermonActionTypes.EVENT_SHORT_LINK_FAILURE:
    case SermonActionTypes.EVENT_SHORT_LINK_CLEAR: {
      return produce(state, nextState => {
        nextState.eventShortLink = null;
      });
    }
    case SermonActionTypes.UPDATE_EVENT_SETTINGS: {
      return produce(state, nextState => {
        nextState.eventSettings = { ...state.eventSettings, ...action.payload };
      });
    }
    case SermonActionTypes.CLEAR_EVENT_SETTINGS: {
      return produce(state, nextState => {
        nextState.eventSettings = initialState.eventSettings;
      });
    }
    case SermonActionTypes.CONNECT_SOCKET: {
      return produce(state, nextState => {
        nextState.socketConnected = true;
      });
    }
    case SermonActionTypes.CLOSE_SOCKET: {
      return produce(state, nextState => {
        nextState.socketConnected = false;
      });
    }
    case SermonActionTypes.SET_SOCKET_CONNECTED: {
      return produce(state, nextState => {
        nextState.socketConnected = action.payload;
      });
    }
    case SermonActionTypes.SET_GOING_LIVE_TIME: {
      return produce(state, nextState => {
        nextState.goingLiveTime = action.payload;
      });
    }
    case SermonActionTypes.SET_ALLOW_ENDED: {
      return produce(state, nextState => {
        nextState.allowEnded = action.payload;
      });
    }

    case SermonActionTypes.SET_CHANGING_STATUS: {
      return produce(state, nextState => {
        nextState.changingStatus = action.payload;
      });
    }

    case SermonActionTypes.SET_LEAVING_EVENT: {
      return produce(state, nextState => {
        nextState.leavingEvent = !!action.payload;
      });
    }

    case SermonActionTypes.GET_RECURRING_EVENT_SUCCESS: {
      return produce(state, nextState => {
        nextState.recurringEvent = action.payload;
      });
    }
    case SermonActionTypes.GET_RECURRING_EVENT_FAILURE:
    case SermonActionTypes.GET_RECURRING_EVENT_CLEAR: {
      return produce(state, nextState => {
        nextState.recurringEvent = null;
      });
    }
    case SermonActionTypes.PARTICIPANTS_LIMIT_EXCEEDED: {
      return produce(state, nextState => {
        nextState.participantsLimitExceeded = action.payload;
      });
    }
    case SermonActionTypes.FETCH_ANNOUNCEMENTS_LIST_SUCCESS: {
      return produce(state, nextState => {
        nextState.announcements = action.payload;
      });
    }
    case SermonActionTypes.WS_ANNOUNCEMENT_PUBLISHED: {
      return produce(state, nextState => {
        const { payload } = action;
        updateAnnouncementsState(nextState, payload);
      });
    }
    case SermonActionTypes.WS_ANNOUNCEMENT_DRAFT: {
      return produce(state, nextState => {
        const { payload } = action;
        updateAnnouncementsState(nextState, payload);
      });
    }
    case SermonActionTypes.WS_ANNOUNCEMENT_DELETED: {
      return produce(state, nextState => {
        const { payload } = action;
        removeAnnouncementFromState(nextState, payload);
      });
    }
    case SermonActionTypes.RESET_NEW_ANNOUNCEMENTS: {
      return produce(state, nextState => {
        nextState.announcements.forEach(item => (item.is_read = true));
        updateAnnouncementsObject(nextState);
      });
    }
    case SermonActionTypes.SET_SELECTED_ANNOUNCEMENT: {
      return produce(state, nextState => {
        nextState.selectedAnnouncement = action.payload;
      });
    }
    case SermonActionTypes.READ_ANNOUNCEMENTS_SUCCESS: {
      return produce(state, nextState => {
        nextState.announcements = action.payload;
      });
    }
    case SermonActionTypes.FETCH_POLLS_SUCCESS: {
      return produce(state, nextState => {
        nextState.polls = action.payload;
      });
    }
    case SermonActionTypes.CREATE_POLL_SUCCESS: {
      return produce(state, nextState => {
        nextState.polls.unshift(action.payload);
      });
    }
    case SermonActionTypes.UPDATE_POLL_SUCCESS: {
      return produce(state, nextState => {
        const pollIndex = state.polls.findIndex(poll => poll.id === action.payload.id);
        nextState.polls.splice(pollIndex, 1, action.payload);
      });
    }
    case SermonActionTypes.DELETE_POLL_SUCCESS: {
      return produce(state, nextState => {
        const pollIndex = state.polls.findIndex(poll => poll.id === action.payload.poll_id);
        nextState.polls.splice(pollIndex, 1);
      });
    }
    case SermonActionTypes.POLL_VOTE_SUCCESS: {
      return produce(state, nextState => {
        const votesByOptionId: { [id: number]: IPollVote } = {};
        action.payload.forEach((vote: IPollVote) => {
          votesByOptionId[vote.option_id] = vote;
        });

        nextState.polls.forEach(poll => {
          poll.options.forEach(option => {
            if (option.id && votesByOptionId[option.id]) {
              option.vote_results.push(votesByOptionId[option.id]);
            }
          });
        });
      });
    }
    case SermonActionTypes.READ_POLLS_SUCCESS: {
      return produce(state, nextState => {
        const pollReadIds = action.payload.map((readPoll: IPollReadResponse) => readPoll.poll_id);
        nextState.polls.forEach(poll => {
          poll.is_read = pollReadIds.includes(poll.id);
        });
      });
    }
    case SermonActionTypes.FETCH_POLL_TEMPLATES_SUCCESS: {
      return produce(state, nextState => {
        nextState.pollTemplates = action.payload;
      });
    }
    case SermonActionTypes.CREATE_POLL_TEMPLATE_SUCCESS: {
      return produce(state, nextState => {
        nextState.pollTemplates.unshift(action.payload);
      });
    }
    case SermonActionTypes.UPDATE_POLL_TEMPLATE_SUCCESS: {
      return produce(state, nextState => {
        const pollTemplateIndex = state.pollTemplates.findIndex(pollTemplate => pollTemplate.id === action.payload.id);
        nextState.pollTemplates.splice(pollTemplateIndex, 1, action.payload);
      });
    }
    case SermonActionTypes.DELETE_POLL_TEMPLATE_SUCCESS: {
      return produce(state, nextState => {
        const pollTemplateIndex = state.pollTemplates.findIndex(pollTemplate => pollTemplate.id === action.payload);
        nextState.pollTemplates.splice(pollTemplateIndex, 1);
      });
    }
    case SermonActionTypes.SET_EVENT_HOST_OR_ADMIN: {
      return produce(state, nextState => {
        nextState.isEventHostOrAdmin = action.payload;
      });
    }
    case SermonActionTypes.CHECK_VIMEO_CONNECTION_SUCCESS: {
      return produce(state, nextState => {
        nextState.isVimeoTokenValid = action.payload;
      });
    }
    case SermonActionTypes.KNOCK_JOIN_MEETING_SUCCESS: {
      return produce(state, nextState => {
        nextState.waitingToJoinMembers = action.payload;
      });
    }
    case SermonActionTypes.KNOCK_JOIN_MEETING_CANCEL: {
      return produce(state, nextState => {
        nextState.waitingToJoinMembers = [];
      });
    }
    case SermonActionTypes.KNOCK_REJECTED: {
      return produce(state, nextState => {
        nextState.knockRejected = action.payload;
      });
    }
    case SermonActionTypes.WS_CHANGE_EVENT_STATUS_OPTIONS: {
      return produce(state, nextState => {
        nextState.changeStatusOptions = action.payload;
      });
    }
    case SermonActionTypes.FETCH_ANNOUNCEMENT_TEMPLATES_SUCCESS: {
      return produce(state, nextState => {
        nextState.announcementTemplates = action.payload;
      });
    }
    case SermonActionTypes.CREATE_ANNOUNCEMENT_TEMPLATE_SUCCESS: {
      return produce(state, nextState => {
        nextState.announcementTemplates.unshift(action.payload);
      });
    }
    case SermonActionTypes.UPDATE_ANNOUNCEMENT_TEMPLATE_SUCCESS: {
      return produce(state, nextState => {
        const templateIndex = state.announcementTemplates.findIndex(({ id }) => id === action.payload.id);
        nextState.announcementTemplates.splice(templateIndex, 1, action.payload);
      });
    }
    case SermonActionTypes.DELETE_ANNOUNCEMENT_TEMPLATE_SUCCESS: {
      return produce(state, nextState => {
        const templateIndex = state.announcementTemplates.findIndex(({ id }) => id === action.payload);
        nextState.announcementTemplates.splice(templateIndex, 1);
      });
    }
    default:
      return state;
  }
};

function removeAnnouncementFromState(nextState: IEventsStateType, id?: number) {
  if (!id) {
    return;
  }
  if (nextState.selectedAnnouncement && nextState.selectedAnnouncement.id === id) {
    nextState.selectedAnnouncement = null;
  }
  const existsIndex = findIndexOfAnnouncementById(nextState, id);
  if (existsIndex >= 0) {
    nextState.announcements.splice(existsIndex, 1);
    updateAnnouncementsObject(nextState);
  }
}

function updateAnnouncementsState(nextState: IEventsStateType, announcement: IAnnouncement) {
  const existsIndex = findIndexOfAnnouncementById(nextState, announcement.id);
  const announcementIsExists = existsIndex >= 0;
  if (announcementIsExists) {
    updateExistsAnnouncement(nextState, announcement, existsIndex);
  } else {
    addNewAnnouncement(nextState, announcement);
  }
  sortAnnouncements(nextState);
  updateAnnouncementsObject(nextState);
}

function findIndexOfAnnouncementById(nextState: IEventsStateType, id: number) {
  return nextState.announcements.findIndex(item => item.id === id);
}

function addNewAnnouncement(nextState: IEventsStateType, announcement: IAnnouncement) {
  nextState.announcements.push(announcement);
}

function updateExistsAnnouncement(nextState: IEventsStateType, newAnnouncementData: IAnnouncement, index: number) {
  if (nextState.announcements[index]) {
    nextState.announcements[index] = newAnnouncementData;
  }
}

function updateAnnouncementsObject(nextState: IEventsStateType) {
  nextState.announcements = [...nextState.announcements];
}

function sortAnnouncements(nextState: IEventsStateType) {
  nextState.announcements.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
}

export { reducer as SermonsReducer };
