import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useHistory, useLocation } from "react-router";

import { actions, selectors } from "../../store";
import { ForgotShape } from "../../interfaces";
import Forgot from "../../components/Forgot/Forgot";
import { forgotValidationSchema } from "../../constants";
import AuthWrapper from "../../components/AuthWrapper";
import EmailSent from "../../components/EmailSent";
import { isEmailResent } from "../../store/selectors";
import { clearForgotEmail, setEmailResent } from "../../store/actions";
import { NamesOfParentRoutes, NameOfRoutes } from "../../../../constants";

import { objectToSearchParams, searchParamsToObject } from "shared/utils/searchParams";
import { setOnboardingUser } from "containers/Onboarding/store/actions";
import { getOnboardingUser } from "containers/Onboarding/store/selectors";

const { AUTH } = NamesOfParentRoutes;
const { FORGOT } = NameOfRoutes;

const ForgotContainer: React.FC = () => {
  const [email, setEmail] = useState("");
  const [showBack, setShowBack] = useState(false);
  const [redirectToUrl, setRedirectToUrl] = useState<string>();
  const [communityCreation, setCommunityCreation] = useState<boolean>(false);

  const forgotEmail = useSelector(selectors.forgotEmail());
  const user = useSelector(getOnboardingUser());
  const emailResent = useSelector(isEmailResent());

  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const { back, email, ...restParams } = searchParamsToObject(search);
    history.replace({ search: objectToSearchParams(restParams) });
    if (back) {
      setShowBack(true);
    }
    if (email) {
      setEmail(email);
    }
    if (restParams.redirectTo) {
      setRedirectToUrl(restParams.redirectTo);
    }
    if (restParams.communityCreation) {
      setCommunityCreation(true);
    }
  }, [history, search]);

  useEffect(() => {
    if (user?.email) {
      setEmail(user.email);
    }
  }, [dispatch, user]);

  const handleSubmit = (values: ForgotShape) => {
    dispatch(actions.forgot.request({ email: values.email, redirectTo: redirectToUrl, communityCreation }));
  };

  const handleChangeEmail = () => {
    dispatch(clearForgotEmail());
    dispatch(setEmailResent(false));
    dispatch(setOnboardingUser(null));
    history.push(`${AUTH}${FORGOT}`);
  };

  return (
    <AuthWrapper hideMobileLogo={Boolean(forgotEmail)}>
      <Formik
        validationSchema={forgotValidationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        initialValues={{ email }}
        enableReinitialize
      >
        {({ values: { email } }) => {
          return forgotEmail ? (
            <EmailSent
              title={"Check your Inbox"}
              subTitle={
                <>
                  Recovery email has been {emailResent ? "resent" : "sent"} to{" "}
                  <span className="email-value">{email}</span>.
                  <br />
                  We have sent you the necessary instructions to recover your password.
                </>
              }
              onChangeEmail={handleChangeEmail}
              onResendEmail={() => handleSubmit({ email })}
            />
          ) : (
            <Forgot onBack={showBack ? () => history.goBack() : undefined} />
          );
        }}
      </Formik>
    </AuthWrapper>
  );
};

export default ForgotContainer;
