import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import EditProfile from "../EditProfile";

import { getUser } from "containers/Auth/store/selectors";
import { Button, Overlay } from "shared/components";
import { AppTheme } from "containers/App/interfaces/themeType";

import "./styles.scss";

type Props = {
  show: boolean;
  onClose: () => void;
  onLogout: () => void;
  onEdit?: () => void;
  theme?: AppTheme;
  className?: string;
};
const Profile: React.FC<Props> = ({ show, onClose, onLogout, onEdit, className, theme = "light" }) => {
  const currentUser = useSelector(getUser());

  const [snowEditProfile, setShowEditProfile] = useState(false);

  const editProfileHandler = useCallback(() => {
    onClose();
    onEdit ? onEdit() : setShowEditProfile(true);
  }, [onClose, onEdit]);

  return currentUser ? (
    <>
      <Overlay show={show} onClose={onClose} theme={theme}>
        <div className={classnames("user-profile", theme, className)}>
          <div className="user-profile-header">
            {`${currentUser.first_name} ${currentUser.last_name}`}
            <span className="close" onClick={onClose} />
          </div>
          <div className="user-profile-body">
            <div className="user-profile-body-data">
              <div className="user-profile-body-data-email">{currentUser.email}</div>
              {currentUser.phone && <div className="user-profile-body-data-phone">{currentUser.phone}</div>}
            </div>
            <div className="user-profile-body-links">
              <Button variant="blue-text" className="link" onClick={editProfileHandler}>
                edit profile
              </Button>
              <Button variant="blue-text" className="link" onClick={editProfileHandler}>
                change password
              </Button>
            </div>
            <div className="user-profile-body-logout">
              <span className="logout-icon" onClick={onLogout} />
              <Button variant="gray-text" className="link" onClick={onLogout}>
                log out
              </Button>
            </div>
          </div>
        </div>
      </Overlay>
      {snowEditProfile && <EditProfile onClose={() => setShowEditProfile(false)} />}
    </>
  ) : null;
};

export default Profile;
