import "./index.scss";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { SyntheticEvent } from "react";

interface StyledToggleButtonGroupProp {
  items: {
    value: string;
    label: string;
  }[];
  value: string;
  handleChange: (newValue: string) => void;
}

const StyledToggleButtonGroup: React.FC<StyledToggleButtonGroupProp> = props => {
  const { items, value, handleChange } = props;
  return (
    <div className="styled-toggle-button-group">
      <ToggleButtonGroup
        orientation="horizontal"
        value={value}
        exclusive
        onChange={(event: SyntheticEvent, newValue) => handleChange(newValue)}
      >
        {items.map((item, index) => (
          <ToggleButton key={index} value={item.value} aria-label={item.value}>
            {item.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default StyledToggleButtonGroup;
