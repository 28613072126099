import { IPerson, IPersonValues } from "../interfaces";

export const preparePersonForm = (person?: IPerson | null): IPersonValues => {
  return {
    first_name: person ? person.first_name : "",
    last_name: person ? person.last_name : "",
    phone_number: person?.phone_number || "",
    email: person?.email || "",
    password: person?.password || "",
    password_confirm: person?.password_confirm || "",
    role: person?.role || "",
    image_url: person?.image_url || null,
  };
};
