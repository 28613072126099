import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { CustomAvatar } from "../../../../../containers/Discussions";

import { getMember } from "containers/Auth/store/selectors";
import { getSelectedChannelDetails } from "containers/Discussions/store/selectors";
import { useGreetingChannel } from "shared/hooks";
import { IMember } from "containers/Member/interfaces";
import { usePersonalChatName } from "containers/Discussions/hooks/usePersonalChatName";

export interface EventSideDialogDiscussionHeaderProps {
  onBack?: () => void;
  title: string;
  image?: string;
  imageColor?: string;
  showImage?: boolean;
}

const EventSideDialogDiscussionHeader: React.FC<EventSideDialogDiscussionHeaderProps> = props => {
  const { onBack, image, title, imageColor, showImage } = props;
  const selectedChannel = useSelector(getSelectedChannelDetails());
  const member = useSelector(getMember());

  const { channelGreeter } = useGreetingChannel(selectedChannel);

  const personalChatName = usePersonalChatName(selectedChannel);

  const discussionTitle = useMemo(() => {
    const isGroupChat = !!selectedChannel?.is_group;
    if (selectedChannel?.name) {
      return <span className="eventSideDialog-title-name">{selectedChannel.name}</span>;
    }
    if (isGroupChat && selectedChannel) {
      const members = selectedChannel.members.filter(
        ({ id, is_active }) => String(id) !== String(member?.id) && is_active,
      );
      if (members.length) {
        const { first_name, last_name } = members[0];
        return (
          <div className="eventSideDialog-title noWrap group">
            <span className="eventSideDialog-title-name">{`${first_name} ${last_name}`}</span>
            <span className="postfix">{`+${members.length} member${members.length > 1 ? "s" : ""}`}</span>
          </div>
        );
      }
      return <span className="eventSideDialog-title-name">Chat</span>;
    }
    if (channelGreeter) {
      return (
        <>
          <div className="eventSideDialog-title-ellipsis">
            {personalChatName ? (
              <>
                <span className="eventSideDialog-title-name">{personalChatName?.first_name}</span>
                <span className="eventSideDialog-title-name">{personalChatName?.last_name}</span>
              </>
            ) : (
              <span className="eventSideDialog-title-name">{title}</span>
            )}
          </div>
          <span className="eventSideDialog-title-greeter">
            {channelGreeter?.title ? ` (${channelGreeter.title})` : ""}
          </span>
        </>
      );
    }
    return (
      <div className="eventSideDialog-title-ellipsis">
        {personalChatName ? (
          <>
            <span className="eventSideDialog-title-name">{personalChatName?.first_name}</span>
            <span className="eventSideDialog-title-name">{personalChatName?.last_name}</span>
          </>
        ) : (
          <span className="eventSideDialog-title-name">{title}</span>
        )}
      </div>
    );
  }, [selectedChannel, channelGreeter, member, personalChatName, title]);

  return (
    <div className="eventSideDialog-header">
      <div className="eventSideDialog-header-title-chat">
        {onBack && (
          <div onClick={onBack} className="eventSideDialog-header-back">
            Back
          </div>
        )}
        <div className="eventSideDialog-header-text">
          {showImage && (
            <CustomAvatar
              image={image}
              size={32}
              name={title}
              color={imageColor}
              member={personalChatName as IMember}
            />
          )}
          <h2 className="eventSideDialog-title">{discussionTitle}</h2>
        </div>
      </div>
    </div>
  );
};

export default EventSideDialogDiscussionHeader;
