import React from "react";
import PropTypes from "prop-types";

type InputProps = React.HTMLProps<HTMLInputElement>;

const Input: React.FunctionComponent<InputProps> = (props: InputProps) => {
  return <input {...props} />;
};
Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
};

Input.defaultProps = {
  label: "",
  type: "text",
  name: "",
};

export default Input;
