import React, { useState, useMemo, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { MemberDialog } from "../../components";
import { actions, selectors } from "../../store";
import { IMemberDialogValuesRequest, MemberDialogMode, IMember } from "../../interfaces";
import { getSubscriptionAttention, getCommunity } from "../../../Community/store/selectors";
import { MembersTable } from "./MembersTable";
import { MembersMenu } from "./MembersMenu";

import { checkRoles } from "utils/ACL";
import { getMember } from "containers/Auth/store/selectors";
import { ERoles, MemberPermissions } from "containers/Auth/interfaces";
import { usePermissions } from "shared";
import { Header, EmptyText, SearchInput, Button, AlertDialog } from "shared/components";
import no_matches_bg from "assets/icons/no_matches_bg.svg";

import "./membersList.scss";

interface IMemberList {
  match: { params: { communityCode: string } };
}

const MemberList: React.FunctionComponent<IMemberList> = props => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const communityMember = useSelector(selectors.getMember());
  const selectedCommunity = useSelector(getCommunity());
  const subscriptionAttention = useSelector(getSubscriptionAttention());
  const currentMember = useSelector(getMember());

  const [editMember, setEditMember] = useState<IMember>();
  const [memberDialogVisible, setMemberDialogVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedMembers, setSelectedMembers] = useState<IMember[]>([]);
  const [isRemove, setIsRemove] = useState(false);
  const [isInvite, setIsInvite] = useState(false);
  const [cannotRemoveOpened, setCannotRemoveOpened] = useState(false);
  const [cannotRemoveTexts, setCannotRemoveTexts] = useState({ title: "", message: "" });

  const isManager = selectedCommunity && checkRoles([ERoles.admin, ERoles.manager], selectedCommunity.id);

  const {
    match: {
      params: { communityCode },
    },
  } = props;

  const onCloseDialog = () => {
    setEditMember(undefined);
    setMemberDialogVisible(false);
    setIsEditMode(false);
  };

  const onSelectMember = useCallback(
    (member: IMember) => {
      if (selectedCommunity && member.code) {
        dispatch(
          actions.fetchMemberByCode.request({
            communityCode: selectedCommunity.code,
            memberCode: member.code,
            callback: (member?: IMember) => {
              if (member) {
                setEditMember(member);
                setMemberDialogVisible(true);
              }
            },
          }),
        );
      }
    },
    [dispatch, selectedCommunity],
  );

  const onSubmitMember = (values: IMemberDialogValuesRequest) => {
    setMemberDialogVisible(false);
    setIsEditMode(false);
    editMember
      ? dispatch(actions.updateMember.request({ ...values, id: editMember.id, communityCode }))
      : dispatch(actions.createMember.request({ ...values, communityCode }));
    setEditMember(undefined);
  };

  const onEditExistMember = (values: IMemberDialogValuesRequest, id?: number) => {
    setMemberDialogVisible(false);
    setIsEditMode(false);
    dispatch(actions.updateMember.request({ ...values, id: id, communityCode }));
    setEditMember(undefined);
  };

  const search = useCallback(
    (members: IMember[], searchValue: string) => {
      if (searchValue.length === 0) {
        return members;
      }
      const searchValueLC = searchValue.toLocaleLowerCase();
      return members.filter(member => {
        const fullName = `${member.first_name} ${member.last_name}`;
        return fullName.toLowerCase().includes(searchValueLC) || (isManager && member.email.includes(searchValueLC));
      });
    },
    [isManager],
  );

  const filteredMember = useMemo(
    () =>
      (
        (...filters: Array<(...args: any[]) => any>) =>
        (member: IMember[]) =>
          filters.reduce((prevRes, func) => func(prevRes), member)
      )((member: IMember[]) => search(member, searchValue))(communityMember),
    [communityMember, search, searchValue],
  );

  const createPermissions = useMemo(() => [MemberPermissions.create], []);
  const canCreate = usePermissions(createPermissions, selectedCommunity?.id);

  const showEmptyText = () => (!communityMember.length ? "No Members Found" : "No Matches Found");

  const getDialogTitle = () => {
    const defaultTitle = "member";
    if (!editMember && !isEditMode) return `${MemberDialogMode.add} ${defaultTitle}`;
    if (!!editMember && isEditMode) return `${MemberDialogMode.edit} ${defaultTitle}`;
    return defaultTitle;
  };

  const selectMemberHandler = useCallback((member: IMember, isSelected: boolean) => {
    setSelectedMembers(prev => (isSelected ? prev.filter(m => m.id !== member.id) : [...prev, member]));
  }, []);

  const handleSelectAll = () => {
    setSelectedMembers(filteredMember);
  };

  const handleUnselectAll = () => {
    setSelectedMembers([]);
  };

  const handleRemoveMembers = () => {
    if (selectedMembers.length && selectedCommunity) {
      const memberIds = selectedMembers.map(m => m.id);
      dispatch(
        actions.removeMembers.request({
          memberIds,
          community_id: selectedCommunity.id,
          callback: () => {
            setIsRemove(false);
            handleUnselectAll();
          },
        }),
      );
    }
  };

  const handleSendInvitation = () => {
    if (selectedMembers.length) {
      dispatch(
        actions.sendPrivateInvitations.request({
          memberIds: selectedMembers.map(m => m.id),
        }),
      );
    }
    setIsInvite(false);
    handleUnselectAll();
  };

  const handleEditMember = () => {
    selectedMembers.length && onSelectMember(selectedMembers[0]);
    setIsEditMode(true);
  };

  const onRemove = useCallback(() => {
    if (selectedMembers.some(({ id }) => id === currentMember?.id)) {
      setCannotRemoveTexts({
        title: "Cannot Remove Yourself",
        message:
          "Please unselect your own member account to continue. To remove yourself from this Community, please contact your system administrator or the Altar Live support team.",
      });
      setCannotRemoveOpened(true);
    } else {
      setIsRemove(true);
    }
  }, [currentMember, selectedMembers]);

  return (
    <>
      {memberDialogVisible && (
        <MemberDialog
          communityId={selectedCommunity ? selectedCommunity.id : undefined}
          communityCode={communityCode}
          member={editMember}
          open={memberDialogVisible}
          onClose={onCloseDialog}
          onSave={onSubmitMember}
          onEditExistMember={onEditExistMember}
          title={getDialogTitle()}
          isEditMode={isEditMode}
          onChangeMode={() => setIsEditMode(true)}
        />
      )}
      <AlertDialog
        open={isRemove}
        title={`Remove ${selectedMembers.length > 1 ? "these members" : "this member"}?`}
        message={`${
          selectedMembers.length > 1 ? "These Members " : "This Member "
        }will be removed from your community. If they join any Events or Meetings in the future they will join as an anonymous user.`}
        onConfirm={handleRemoveMembers}
        onCancel={() => setIsRemove(false)}
        mode="cancel"
        confirmText="Remove"
        confirmClassName="defaultButtons-remove"
      />
      <AlertDialog
        open={isInvite}
        title="Invite Again"
        message={`Would you like to send ${
          selectedMembers.length > 1 ? "these Members" : "this Member"
        } an email invitation to your community again?`}
        onConfirm={handleSendInvitation}
        onCancel={() => setIsInvite(false)}
        mode="confirm"
        confirmText="Yes"
      />
      <AlertDialog
        open={cannotRemoveOpened}
        title={cannotRemoveTexts.title}
        message={cannotRemoveTexts.message}
        onConfirm={() => setCannotRemoveOpened(false)}
        mode="info"
        confirmText="Got it"
        dialogClassName="cannot-remove-popup"
      />
      <MembersMenu
        onUnselectAll={handleUnselectAll}
        onSelectAll={handleSelectAll}
        onRemove={selectedMembers.length === 1 && selectedMembers[0].id === currentMember?.id ? undefined : onRemove}
        onInvite={!selectedMembers.some(m => m.has_accepted_invitation) ? () => setIsInvite(true) : void 0}
        onEdit={selectedMembers.length === 1 ? handleEditMember : void 0}
        show={!!selectedMembers.length && canCreate}
        members={selectedMembers}
      />
      <Header title={t("Members")} subscriptionAttention={subscriptionAttention} isLightBlue>
        <SearchInput className="defaultSearchInput" onChange={setSearchValue} />
        {canCreate && (
          <Button width="200" variant="orange" onClick={() => setMemberDialogVisible(true)}>
            {t("Add New Member")}
          </Button>
        )}
      </Header>
      <Grid
        container
        direction="row"
        justify="flex-start"
        className={classnames("membersList", { subscriptionAttention })}
        spacing={2}
      >
        <MembersTable
          selectedMembers={selectedMembers}
          members={filteredMember}
          onMemberClick={onSelectMember}
          onSelectMember={selectMemberHandler}
          canEdit={canCreate}
        />
        <EmptyText
          icon={no_matches_bg}
          show={!communityMember.length || !filteredMember.length}
          message={t(showEmptyText())}
        />
      </Grid>
    </>
  );
};

export default MemberList;
