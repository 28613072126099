import React, { useEffect, ReactNode } from "react";
import { Grid } from "@material-ui/core";
import "./SideDialog.scss";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/styles";
import classnames from "classnames";

import { dialogTheme } from "../../../theme";

import { ESCAPE_KEYS } from "shared";
import { hideWidget, showWidget } from "utils/hubspot";

interface ISideDialog {
  open: boolean;
  onClose?: () => void;
  className?: string;
  title: string;
  removeText?: string;
  onRemove?: () => void;
  editText?: string;
  onEdit?: () => void;
  children?: ReactNode;
  onSave?: () => void;
  saveText?: string;
  cancelText?: string;
  reference?: (node?: Element | null | undefined) => void;
  size?: "xs" | "sm" | "md" | "lg";
  headerClassName?: string;
  footerClassName?: string;
  headerButtons?: JSX.Element[];
  footerButtons?: JSX.Element[];
}

function SideDialog(props: ISideDialog) {
  const { t } = useTranslation();
  const {
    onClose,
    title,
    removeText,
    onRemove,
    editText,
    onEdit,
    children,
    onSave,
    saveText = t("save"),
    open,
    className,
    reference,
    size,
    cancelText = t("cancel"),
    headerClassName,
    footerClassName,
    headerButtons,
    footerButtons,
  } = props;

  useEffect(() => {
    if (open) {
      document.body.classList.add("hideOverflow-sideDialog");
      hideWidget();
    }
    return () => {
      showWidget();
      document.body.classList.remove("hideOverflow-sideDialog");
    };
  }, [open]);

  useEffect(() => {
    const escFunction = (e: KeyboardEvent) => {
      if (ESCAPE_KEYS.includes(String(e.keyCode))) {
        onClose && onClose();
      }
    };

    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [onClose]);

  const headerActions = headerButtons || (
    <>
      {removeText && (
        <button className="sideDialog-header-actions-remove" onClick={onRemove}>
          {removeText || "remove"}
        </button>
      )}
      {editText && (
        <button className="sideDialog-header-actions-edit" onClick={onEdit}>
          {editText}
        </button>
      )}
    </>
  );

  const footerActions = footerButtons || (
    <>
      <button type="button" onClick={onClose} className="sideDialog-footer-actions-cancel">
        {cancelText}
      </button>
      {onSave && saveText && (
        <button type="submit" className="sideDialog-footer-actions-confirm" onClick={onSave}>
          {saveText}
        </button>
      )}
    </>
  );

  return (
    <>
      {open && (
        <div className={`sideDialog ${className || ""}`} onClick={onClose}>
          <div
            className={classnames("sideDialog-body", size ? `sideDialog-body-${size}` : "")}
            onClick={e => e.stopPropagation()}
            ref={reference}
          >
            <div className="sideDialog-container">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classnames("sideDialog-header", headerClassName)}
              >
                <Grid item xs={7} className="sideDialog-title">
                  {title}
                </Grid>
                <Grid item className="sideDialog-header-actions">
                  {headerActions}
                </Grid>
              </Grid>
              <ThemeProvider theme={dialogTheme}>{children}</ThemeProvider>
            </div>
            <div className={classnames("sideDialog-footer-actions", footerClassName)}>{footerActions}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default SideDialog;
