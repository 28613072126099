export default {
  get: () => {
    try {
      return localStorage.getItem("memberCID");
    } catch (err: any) {
      return false;
    }
  },

  set: (cid: string) => {
    localStorage.setItem("memberCID", cid);
  },

  remove: () => {
    localStorage.removeItem("memberCID");
  },
};
