import "./index.scss";

import React, { useMemo } from "react";

import { IAnnouncement } from "containers/Sermons/interfaces";
import { Button } from "shared";

interface IEventAnouncementsListProps {
  announcement: IAnnouncement;
}

const EventAnnouncementsList: React.FC<IEventAnouncementsListProps> = props => {
  const { announcement } = props;

  const renderButton = useMemo(() => {
    if (!announcement.button_text || !announcement.button_link) {
      return null;
    }
    return (
      <Button
        className="announcement-main-button"
        variant="orange"
        onClick={() => {
          window.open(announcement.button_link);
        }}
      >
        {announcement.button_text}
      </Button>
    );
  }, [announcement]);

  return (
    <div className="announcements-view-wrapper">
      <div className="announcement-info">
        <div className="announcement-created">Test test 07:10 PM</div>
        <div className="announcement-description">{announcement.description}</div>
      </div>
      <div className="announcement-button">{renderButton}</div>
    </div>
  );
};

export default EventAnnouncementsList;
