import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { getUnreadMessagesCount } from "containers/Discussions/store/selectors";
import PushNotificationSettings from "containers/Sermons/components/EventContainer/PushNotificationSettings/PushNotificationSettings";
import { checkMeetingRole } from "containers/Sermons";
import { getMember } from "containers/Auth/store/selectors";
import { EventDashboardTabs, EventType, IEvent, PollStatus, SermonRoles } from "containers/Sermons/interfaces";
import { getEventSettings, getPolls, getUnreadAnnouncementsCount } from "containers/Sermons/store/selectors";
import { EventTooltip } from "shared/components";
import LeaveEvent from "containers/Sermons/components/EventContainer/LeaveEvent";
import discussion_icon from "assets/icons/discussion_icon.svg";
import discussion_icon_white from "assets/icons/discussion_icon_white.svg";
import member_tab_icon from "assets/icons/member_tab_icon.svg";
import member_tab_icon_white from "assets/icons/member_tab_icon_white.svg";
import announcement_tab_icon from "assets/icons/announcement_tab_icon.svg";
import announcement_tab_icon_white from "assets/icons/announcement_tab_icon_white.svg";
import poll_icon from "assets/icons/poll_icon.svg";
import poll_icon_white from "assets/icons/poll_icon_white.svg";
import guide_icon from "assets/icons/guide_icon.svg";
import guide_icon_white from "assets/icons/guide_icon_white.svg";
import host_notes_icon from "assets/icons/host_notes_icon.svg";
import host_notes_icon_white from "assets/icons/host_notes_icon_white.svg";

import "./eventDashboard.scss";

interface IEventDashboard {
  activeTab: EventDashboardTabs | null;
  showActions: boolean;
  onChange: (tab: EventDashboardTabs | null) => void;
  onCloseEvent: (shouldRedirect: boolean, leaveEventRedirect: boolean) => void;
  event: IEvent;
}

const EventDashboard: React.FC<IEventDashboard> = ({ activeTab, onChange, onCloseEvent, showActions, event }) => {
  const unreadMessages = useSelector(getUnreadMessagesCount());
  const unreadAnnouncements = useSelector(getUnreadAnnouncementsCount());
  const currentMember = useSelector(getMember());
  const polls = useSelector(getPolls());
  const { activeDashboardTab } = useSelector(getEventSettings());

  const eventType = event?.type;

  const unreadPollsCount = useMemo(() => {
    if (activeDashboardTab !== EventDashboardTabs.polls) {
      return polls.filter(poll => !poll.is_read && [PollStatus.published, PollStatus.closed].includes(poll.status))
        .length;
    }
    return 0;
  }, [polls, activeDashboardTab]);

  const canManageHostNotes = useMemo(() => {
    const hasAccessToManage =
      event &&
      currentMember?.id &&
      checkMeetingRole(event, currentMember, [SermonRoles.greeter, SermonRoles.host, SermonRoles.greeterHost]);

    return Boolean(hasAccessToManage);
  }, [event, currentMember]);

  const tabIconsSrc = useMemo(
    () => [
      {
        activeIcon: discussion_icon_white,
        defaultIcon: discussion_icon,
        type: [EventDashboardTabs.chat],
        showChatNotification: true,
        tooltipTitle: "Chat",
        canShow: true,
      },
      {
        activeIcon: member_tab_icon_white,
        defaultIcon: member_tab_icon,
        type: [EventDashboardTabs.members],
        tooltipTitle: "Members Online",
        canShow: true,
      },
      {
        activeIcon: announcement_tab_icon_white,
        defaultIcon: announcement_tab_icon,
        type: [EventDashboardTabs.announcements],
        tooltipTitle: "Announcements",
        showAnnouncementNotification: true,
        canShow: true,
      },
      {
        activeIcon: poll_icon_white,
        defaultIcon: poll_icon,
        type: [EventDashboardTabs.polls],
        tooltipTitle: "Polls",
        showPollNotification: true,
        canShow: true,
        withDivider: eventType == EventType.meeting,
      },
      {
        activeIcon: guide_icon_white,
        defaultIcon: guide_icon,
        type: [EventDashboardTabs.welcome, EventDashboardTabs.auth],
        tooltipTitle: "Welcome & Navigation",
        canShow: eventType !== EventType.meeting,
        withDivider: eventType !== EventType.meeting,
      },
      {
        activeIcon: host_notes_icon_white,
        defaultIcon: host_notes_icon,
        type: [EventDashboardTabs.hostNotes],
        tooltipTitle: "Host Notes",
        canShow: eventType !== EventType.meeting && canManageHostNotes,
      },
    ],
    [canManageHostNotes, eventType],
  );

  const tabs = useMemo(() => {
    const unreadCountPersonal = unreadMessages.personalUnread;
    const unreadCountGeneral = unreadMessages.eventGeneralUnread;
    return tabIconsSrc.map(
      (
        {
          activeIcon,
          type,
          defaultIcon,
          showChatNotification,
          tooltipTitle,
          showAnnouncementNotification,
          showPollNotification,
          canShow,
          withDivider,
        },
        idx,
      ) =>
        canShow ? (
          <React.Fragment key={idx}>
            <EventTooltip title={tooltipTitle} placement="left">
              <div
                className={classnames("eventDashboard-tabs-item", { active: activeTab && type.includes(activeTab) })}
                onClick={() => onChange(type[0])}
              >
                <img
                  src={activeTab && type.includes(activeTab) ? activeIcon : defaultIcon}
                  alt={`${activeTab || "tab"} icon`}
                />
                {showChatNotification && (!!unreadCountPersonal || !!unreadCountGeneral) && (
                  <div className="notification">{!!unreadCountPersonal && unreadCountPersonal}</div>
                )}
                {showAnnouncementNotification && Boolean(unreadAnnouncements) && (
                  <div className="notification">{unreadAnnouncements}</div>
                )}
                {showPollNotification && unreadPollsCount > 0 && <div className="notification">{unreadPollsCount}</div>}
              </div>
            </EventTooltip>
            {withDivider && <div className="eventDashboard-tabs-divider" />}
          </React.Fragment>
        ) : null,
    );
  }, [activeTab, onChange, unreadMessages, unreadAnnouncements, unreadPollsCount, tabIconsSrc]);

  return (
    <div className={classnames("eventDashboard")}>
      {showActions && <div className="eventDashboard-tabs">{tabs}</div>}
      <div className="eventDashboard-bottom">
        {showActions && <PushNotificationSettings />}
        <LeaveEvent onLeaveEvent={onCloseEvent} />
      </div>
    </div>
  );
};
export default EventDashboard;
