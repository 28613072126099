import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getEvent } from "../../../containers/Sermons/store/selectors";
import { selectors } from "../../../containers/Auth";
import { DiscussionTab } from "../../components/Event/EventSideDialog/EventSideDialogHeader/EventSideDialogDiscussionTabs";
import { authentificated } from "../../../containers/Auth/store/selectors";
import { memberCIDHandler } from "../../utils";

import { getMember } from "containers/Member/store/selectors";
import { createAnonymousChannelWithGreeter, setCreateChatWithMember } from "containers/Discussions/store/actions";
import { EventDashboardTabs } from "containers/Sermons";
import { actions } from "containers/Sermons/store";

export default function useStartChatHook() {
  const dispatch = useDispatch();
  const communityMembers = useSelector(getMember());
  const currentMember = useSelector(selectors.getMember());
  const event = useSelector(getEvent());
  const isAuthenticated = useSelector(authentificated());
  const memberCID = memberCIDHandler.get();

  const startChatHandler = useCallback(
    (memberId: number | string) => {
      const member = communityMembers.find(({ id }) => id === Number(memberId));

      if ((!member || !isAuthenticated) && event) {
        dispatch(
          createAnonymousChannelWithGreeter.request({
            meeting_id: event.id,
            greeter_id: isAuthenticated ? currentMember?.id || event.greeters[0].id : Number(memberId),
            anonymous_id: isAuthenticated ? memberId.toString() : String(memberCID),
          }),
        );
      }

      member && dispatch(setCreateChatWithMember(member));
      dispatch(
        actions.updateEventSettings({
          activeDashboardTab: EventDashboardTabs.chat,
          selectedTab: DiscussionTab.personal,
        }),
      );
    },
    [communityMembers, dispatch, event, currentMember, isAuthenticated, memberCID],
  );

  return { startChatHandler };
}
