import Ajv from "ajv";
import _ from "lodash";

import authSchemas from "../../containers/Auth/api/schemas";
import appSchemas from "../../containers/App/api/schemas";

const ajv = new Ajv({ removeAdditional: true });

const allSchemas = {
  ...authSchemas,
  ...appSchemas,
};
const compiledSchemas = _.mapValues(allSchemas, (value: Record<string, any>) => ajv.compile(value));

export default compiledSchemas;
