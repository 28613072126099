const isNotificationsSupported = () => {
  if ("Notification" in window && typeof Notification.requestPermission === "function") {
    return true;
  } else {
    // eslint-disable-next-line no-console
    console.log("This browser does not support notifications.");
    return false;
  }
};

export const askNotificationPermission = async () => {
  if (isNotificationsSupported()) {
    await Notification.requestPermission();
  }
};

export const pushNotification = async (title: string, options: { body: string; icon: string }) => {
  if (isNotificationsSupported()) {
    if (Notification.permission === "denied") {
      return;
    }
    if (Notification.permission === "default") {
      await askNotificationPermission();
    }
    return new Notification(title, options);
  }
};
