import { AnyAction } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { notificationActions } from "../../Notifications/store/actions";
import api from "../api";
import { IChannel, ICreateNewChat, IGetChannels } from "../interfaces";
import history from "../../../shared/history/history";

import { actions, constants } from "./";

import { searchParamsToObject } from "shared/utils/searchParams";
import tokenHandler from "shared/utils/tokenHandler";
import { getEvent } from "containers/Sermons/store/selectors";
import { IEvent } from "containers/Sermons/interfaces";

function* getChannelsSaga({ payload }: { payload: IGetChannels }) {
  try {
    const { community_id, params, callback } = payload;
    const channels: IChannel[] = yield call(api.getChannels, { community_id, params });
    yield put(actions.getChannels.success(channels));
    callback && callback();
  } catch (error: any) {
    const { subdomain_part, provider } = searchParamsToObject(history.location.search);
    yield put(actions.getChannels.failure());
    if (!subdomain_part && !provider) {
      yield put(notificationActions.error("Cannot fetch channels", (error && error.message) || ""));
    }
  }
}

function* getAnonymousChannelsSaga({ payload }: { payload: IGetChannels }) {
  try {
    const { community_id, params, callback } = payload;
    const setOfChannelsIds: Set<string> = new Set();
    const event: IEvent | null = yield select(getEvent());
    const token = tokenHandler.get();
    if (!token) {
      const channels: IChannel[] = yield call(api.getAnonymousChannels, { community_id, params });
      yield put(actions.getChannels.success(channels));
      if (event) {
        channels.forEach(channel => {
          channel.meetings.some(meeting => meeting.id === event.id) && setOfChannelsIds.add(channel.id);
        });
      } else {
        channels.forEach(channel => {
          setOfChannelsIds.add(channel.id);
        });
      }
      yield put(actions.setEventChannelsIds(setOfChannelsIds));
      callback && callback();
    }
  } catch (error: any) {
    yield put(actions.getChannels.failure());
    yield put(notificationActions.error("Cannot fetch channels", (error && error.message) || ""));
  }
}

function* getSelectedChannelDetails({ payload }: { payload: string }) {
  try {
    const channelDetails: IChannel = yield call(api.getChannelDetails, payload);
    yield put(actions.getSelectedChannelDetails.success(channelDetails));
  } catch (error: any) {
    yield put(notificationActions.error("Cannot fetch channel", (error && error.message) || ""));
  }
}

function* createNewChatSaga({ payload }: { payload: ICreateNewChat }) {
  try {
    const chatId: string = yield call(api.createNewChat, payload);
    yield put(actions.createNewChat.success(chatId));
    yield put(actions.getChannels.request({ community_id: payload.community_id }));
  } catch (error: any) {
    yield put(notificationActions.error("Cannot create chat", (error && error.message) || ""));
    yield put(actions.createNewChat.failure());
  }
}

function* createAnonymousChannelWithGreeterSaga({
  payload,
}: {
  payload: { meeting_id: number; greeter_id: number; anonymous_id: string };
}) {
  try {
    const chatId: string = yield call(api.createAnonymousChannelWithGreeter, payload);
    yield put(actions.createAnonymousChannelWithGreeter.success(chatId));
  } catch (error: any) {
    yield put(notificationActions.error("Cannot create chat", (error && error.message) || ""));
    yield put(actions.createAnonymousChannelWithGreeter.failure());
  }
}

function* createGeneralChatSaga({ payload }: ReturnType<typeof actions.createGeneralChat.request>) {
  try {
    const chat: { id: string } = yield call(api.createGeneralChat, payload);
    yield put(actions.createGeneralChat.success(chat?.id || null));
  } catch (error: any) {
    yield put(actions.createGeneralChat.failure());
  }
}

function* updateChannelSaga({ payload }: AnyAction) {
  try {
    const updatedChannel: { id: string } = yield call(api.updateChannel, payload);

    yield put(actions.getChannels.request({ community_id: payload.community_id }));
    yield put(actions.getSelectedChannelDetails.request(updatedChannel?.id));
    yield put(notificationActions.success("Discussion Updated", "The discussion was successfully updated"));
  } catch (error: any) {
    yield put(notificationActions.error("Cannot update discussion", (error && error.message) || ""));
  }
}

function* deleteChannelSaga({ payload: { community_id, channel_id, callback } }: AnyAction) {
  try {
    yield call(api.deleteChannel, community_id, channel_id);
    yield put(notificationActions.success("Discussion Deleted", "The discussion was deleted"));
    yield put(actions.getChannels.request({ community_id }));
    callback?.();
  } catch (error: any) {
    yield put(notificationActions.error("Cannot delete discussion", (error && error.message) || ""));
  }
}

function* leaveFromChannelSaga({ payload: { channel_id, community_id } }: AnyAction) {
  try {
    yield call(api.leaveFromChat, channel_id);
    yield put(actions.leaveChannel.success());
    yield put(actions.getChannels.request({ community_id }));
  } catch (error: any) {
    yield put(actions.leaveChannel.failure());
    yield put(notificationActions.error("Cannot leave chat", (error && error.message) || ""));
  }
}

function* addModeratorsSaga({ payload }: ReturnType<typeof actions.addChannelModerators.request>) {
  try {
    const { callback, ...data } = payload;
    const { id }: { id: string } = yield call(api.addChannelModerators, data);
    yield put(actions.addChannelModerators.success(id));
    callback?.();
  } catch (error: any) {
    yield put(actions.addChannelModerators.failure());
  }
}

export default function* discussionsSaga() {
  yield takeLatest(actions.getChannels.request, getChannelsSaga);
  yield takeLatest(actions.getAnonymousChannels.request, getAnonymousChannelsSaga);
  yield takeLatest(actions.getSelectedChannelDetails.request, getSelectedChannelDetails);
  yield takeLatest(actions.createNewChat.request, createNewChatSaga);
  yield takeLatest(actions.createAnonymousChannelWithGreeter.request, createAnonymousChannelWithGreeterSaga);
  yield takeLatest(actions.createGeneralChat.request, createGeneralChatSaga);
  yield takeLatest(constants.DiscussionsActionTypes.UPDATE_CHANNEL, updateChannelSaga);
  yield takeLatest(constants.DiscussionsActionTypes.DELETE_CHANNEL, deleteChannelSaga);
  yield takeLatest(constants.DiscussionsActionTypes.LEAVE_CHANNEL, leaveFromChannelSaga);
  yield takeLatest(actions.addChannelModerators.request, addModeratorsSaga);
}
