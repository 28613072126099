import React, { useCallback, useState } from "react";

import { IPollTemplate } from "../../../../Sermons";
import { ETemplatesType } from "../../../interfaces";

import ic_remove from "assets/icons/ic_remove.svg";
import edit_icon_blue from "assets/icons/edit_icon_blue.svg";
import { AlertDialog } from "shared";

import "./index.scss";

interface IProps {
  pollTemplate: IPollTemplate;
  deleteHandler: (templateType: ETemplatesType, template_id: number) => void;
  editHandler: (pollTemplate: IPollTemplate) => void;
}

const PollTemplate: React.FC<IProps> = ({ pollTemplate, deleteHandler, editHandler }) => {
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const confirmDelete = useCallback(() => {
    pollTemplate.id && deleteHandler(ETemplatesType.polls, pollTemplate.id);
    setOpenDeleteAlert(false);
  }, [pollTemplate.id, deleteHandler]);

  return (
    <div className="pollTemplate">
      <AlertDialog
        open={openDeleteAlert}
        title="Delete Template"
        message="Are you sure that you want to delete this template? It will no longer be available inside of Events or Meetings."
        onConfirm={confirmDelete}
        onCancel={() => setOpenDeleteAlert(false)}
        mode="confirm"
        confirmText="Yes, Delete"
        confirmClassName="confirmDeletePollTemplateBtn"
      />
      <div className="pollTemplate-title">
        <div className="pollTemplate-title-label">
          <div className="pollTemplate-title-label-text">Title</div>
          <div className="pollTemplate-title-label-action">
            <img src={edit_icon_blue} alt="edit" onClick={() => editHandler(pollTemplate)} />
            <img src={ic_remove} alt="remove" onClick={() => setOpenDeleteAlert(true)} />
          </div>
        </div>
        <div className="pollTemplate-title-text">{pollTemplate.title}</div>
      </div>
      <div className="pollTemplate-question">
        <div className="pollTemplate-question-label">Poll Text</div>
        <div className="pollTemplate-question-text">{pollTemplate.poll_question}</div>
      </div>
      <div className="pollTemplate-settings">
        {pollTemplate.show_results && <div className="pollTemplate-settings-showResults">Show results to everyone</div>}
        {pollTemplate.multiple_answers && (
          <div className="pollTemplate-settings-multipleAnswers">Allow multiple answers</div>
        )}
      </div>
      <div className="pollTemplate-options">
        {pollTemplate.options.map((option, index) => (
          <div key={index} className="pollTemplate-options-item">
            <div className="pollTemplate-options-item-label">{`Option ${index + 1}`}</div>
            <div className="pollTemplate-options-item-text">{option.option_text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PollTemplate;
