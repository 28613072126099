import produce from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { IAwsState } from "../../interfaces/Aws";
import * as actions from "./actions";

const initialState: IAwsState = {
  s3: {
    upload: {
      progress: null,
      error: null,
    },
  },
};

type Action = ActionType<typeof actions>;

const reducer = createReducer<IAwsState, Action>(initialState)
  .handleAction(actions.uploadToS3.success, (state, action) =>
    produce(state, nextState => {
      nextState.s3.upload.progress = action.payload;
    }),
  )
  .handleAction(actions.uploadToS3.failure, (state, action) =>
    produce(state, nextState => {
      nextState.s3.upload.error = action.payload;
      nextState.s3.upload.progress = null;
    }),
  )
  .handleAction(actions.uploadToS3.cancel, state =>
    produce(state, nextState => {
      nextState.s3.upload = {
        progress: null,
        error: null,
      };
    }),
  );

export default reducer;
