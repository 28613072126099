import React, { useEffect, useState } from "react";

import { IMemberToMembersModal, IMember } from "../../../../containers/Member/interfaces";
import { CommunityMemberCard } from "./CommunityMemberCard";
import { DiscussionTheme } from "../../../../containers/Discussions/containers/DiscussionsContainer/DiscussionsContainer";

import "./communityMembersList.scss";

interface ICommunityMembersList {
  selectedIds: number[];
  communityMembersList?: IMember[];
  multiSelect?: boolean;
  prepareMemberToView?: (member: IMember | null) => Partial<IMemberToMembersModal> | null;
  allowSelectQty?: number;
  theme?: DiscussionTheme;
  onlineMemberIds?: number[];
  managerView?: boolean;
  onSelect(member: IMemberToMembersModal): void;
}

const CommunityMembersList: React.FC<ICommunityMembersList> = props => {
  const {
    communityMembersList,
    onSelect,
    multiSelect,
    prepareMemberToView,
    selectedIds,
    allowSelectQty = Infinity,
    theme,
    onlineMemberIds,
    managerView = false,
  } = props;
  const [selectedMemberIds, setSelectedMemberIds] = useState<number[]>([]);

  const selectMember = (item: IMember) => {
    if (multiSelect) {
      const alreadySelected = selectedMemberIds.includes(item.id as number);
      if (selectedMemberIds.length + 1 > allowSelectQty && !alreadySelected) {
        return;
      }
      alreadySelected
        ? setSelectedMemberIds(selectedMemberIds.filter(i => i !== item.id))
        : setSelectedMemberIds([...selectedMemberIds, item.id as number]);
    } else {
      setSelectedMemberIds([item.id as number]);
    }
    onSelect(item);
  };

  useEffect(() => {
    setSelectedMemberIds(selectedIds);
  }, [selectedIds]);

  const getCardList = () => {
    if (communityMembersList) {
      return communityMembersList.map((member, idx) => (
        <CommunityMemberCard
          className={theme}
          onClick={() => selectMember(member)}
          key={member.id || idx}
          member={prepareMemberToView && prepareMemberToView(member)}
          selected={!!(member.id && selectedMemberIds.includes(member.id))}
          isOnline={onlineMemberIds?.includes(member.id)}
          managerView={managerView}
        />
      ));
    }
  };

  return <div className="communityMembersContainer-members">{getCardList()}</div>;
};

export default CommunityMembersList;
