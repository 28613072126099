import { useMemo } from "react";
import { useSelector } from "react-redux";

import { checkPermissions } from "../../../utils/ACL";

import { Permissions } from "containers/Auth/interfaces";
import { getUser } from "containers/Auth/store/selectors";

const usePermissions = (allowedPermissions: Permissions[], communityId?: number): boolean => {
  const currentUser = useSelector(getUser());

  return useMemo(
    () => checkPermissions(allowedPermissions, communityId, currentUser),
    [allowedPermissions, communityId, currentUser],
  );
};

export default usePermissions;
