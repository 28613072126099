import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

import { selectors } from "../../store";
import { IChannel } from "../../interfaces";
import { IMember } from "../../../Member/interfaces";

import { AlertDialog } from "shared/components/AlertDialog";
import { SideDialog } from "shared/components/SideDialog";
import { CommunityMemberCard } from "shared/components/SelectMembersModal/CommunityMembersList/CommunityMemberCard";
import { SelectMembersModal } from "shared/components/SelectMembersModal";
import { StyledTextField } from "shared/components/StyledTextField";
import { Button } from "shared/components/Button";
import { getChannelName } from "shared/utils/getStream";
import { CanView } from "shared/components/CanView";
import { notEmptyArray } from "utils/notEmptyArray";
import { DiscussionPermissions } from "containers/Auth/interfaces";
import { usePermissions } from "shared";
import { EEmptyStateTextType } from "shared/components/SelectMembersModal/interfaces";

import "./channelEditDialog.scss";

interface ChannelEditDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (channel: IChannel) => void;
  onRemove: (channel: IChannel) => void;
  onLeave: (channel: IChannel) => void;
  member?: IMember;
}

const channelValidationSchema = Yup.object({
  name: Yup.string().max(50, "Must be 50 characters or less").nullable().required("Please add a chat name"),
  members: Yup.array(),
});

export const ChannelEditDialog: React.FC<ChannelEditDialogProps> = props => {
  const { onClose, open, onSave, member, onRemove, onLeave } = props;

  const channel = useSelector(selectors.getSelectedChannelDetails());

  const { t } = useTranslation();

  const [memberToRemove, setMemberToRemove] = useState<IMember>();
  const [showLeaveChannel, setShowLeaveChannel] = useState<boolean>(false);
  const [showRemoveChannel, setShowRemoveChannel] = useState<boolean>(false);
  const [showAddMembers, setShowAddMembers] = useState<boolean>(false);

  const removeChannel = useCallback(() => {
    channel && onRemove(channel);
    setShowRemoveChannel(false);
  }, [channel, onRemove]);

  const leaveChannel = useCallback(() => {
    channel && onLeave(channel);
    setShowRemoveChannel(false);
  }, [channel, onLeave]);

  const isChannelOwner = useCallback((id?: number) => channel?.created_by === id, [channel]);

  const editPermissions = useMemo(() => [DiscussionPermissions.edit], []);
  const canEdit = usePermissions(editPermissions);
  const canEditChannel = useMemo(() => isChannelOwner(member?.id) || canEdit, [canEdit, isChannelOwner, member]);

  if (!member) {
    return null;
  }

  return channel ? (
    <Formik
      initialValues={channel}
      validationSchema={channelValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onSave(values);
      }}
      enableReinitialize
      validateOnChange={false}
    >
      {({ errors, handleChange, values, handleBlur, setFieldValue, handleSubmit }) => {
        const removeMember = () => {
          const { members } = values;
          const newMembers = members.filter(member => member.id !== memberToRemove?.id);
          setFieldValue("members", newMembers);
          setMemberToRemove(undefined);
        };

        const addMembers = (members: IMember[]) => {
          const newMembers = [...values.members, ...members];
          setFieldValue("members", newMembers);
          setShowAddMembers(false);
        };

        const currentMemberIds = values.members.map(({ id }) => id);

        const headerButtons = [
          <Button key={1} variant="purple-text" width={64} onClick={onClose} type="button">
            close
          </Button>,
          canEditChannel ? (
            <Button type="submit" key={2} variant="blue-text" width={48} onClick={() => handleSubmit()}>
              save
            </Button>
          ) : null,
        ].filter(notEmptyArray);

        const footerButtons = [
          <Button
            key={1}
            variant="red-text"
            width={256}
            type="button"
            onClick={canEditChannel ? () => setShowRemoveChannel(true) : () => setShowLeaveChannel(true)}
          >
            {`${canEditChannel ? "remove" : "leave"} chat`}
          </Button>,
        ].filter(notEmptyArray);

        return (
          <>
            <AlertDialog
              mode="confirm"
              open={!!memberToRemove}
              confirmText={t("Remove")}
              title={t("Remove Participant")}
              message={t("Are you sure that you want to remove the participant?")}
              onCancel={() => setMemberToRemove(undefined)}
              onConfirm={removeMember}
              dialogClassName="channelEditDialog-alertDialog"
              confirmClassName="channelEditDialog-confirmBtn"
            />
            <AlertDialog
              mode="confirm"
              open={showRemoveChannel}
              confirmText={t("remove")}
              title={t("Remove Chat")}
              message={t("Are you sure you want to remove this chat?")}
              onCancel={() => setShowRemoveChannel(false)}
              onConfirm={removeChannel}
              dialogClassName="channelEditDialog-alertDialog"
              confirmClassName="channelEditDialog-confirmBtn"
            />
            <AlertDialog
              mode="confirm"
              open={showLeaveChannel}
              confirmText={t("leave")}
              title={t("Leave Chat")}
              message={t("Are you sure you want to leave this chat?")}
              onCancel={() => setShowLeaveChannel(false)}
              onConfirm={leaveChannel}
              dialogClassName="channelEditDialog-alertDialog"
              confirmClassName="channelEditDialog-confirmBtn"
            />
            <SelectMembersModal
              selectText={t("Add")}
              open={showAddMembers}
              onClose={() => setShowAddMembers(false)}
              onSelect={addMembers}
              multiselect={true}
              title={t("Add members")}
              notAllowedMemberIds={currentMemberIds}
              emptyStateTextType={EEmptyStateTextType.chat}
              styleTheme={"light"}
            />
            <SideDialog
              size="sm"
              open={open}
              title={t(canEditChannel ? "Edit Chat" : "Chat Details")}
              onClose={onClose}
              headerButtons={headerButtons}
              footerButtons={footerButtons}
              headerClassName="channelEditDialog-header"
              footerClassName="channelEditDialog-footer"
            >
              <div className="channelEditDialog">
                <CanView condition={canEditChannel}>
                  <StyledTextField
                    className="channelEditDialog-channel_name"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="name"
                    onChange={handleChange}
                    label={t("Chat Name")}
                    errors={errors}
                    value={values.name === null ? getChannelName(channel, member) : values.name || ""}
                    onBlur={handleBlur}
                  />
                </CanView>
                <CanView condition={!canEditChannel}>
                  <div className="channelEditDialog-channel_name">
                    <p className="channelEditDialog-channel_name_label">Conversation Name</p>
                    <p>{getChannelName(channel, member)}</p>
                  </div>
                </CanView>
                <div className="channelEditDialog-members_header">
                  <h4 className="channelEditDialog-members_header_title">{t("Participants")}</h4>
                  <CanView condition={canEditChannel}>
                    <Button
                      variant="blue-text"
                      width={58}
                      onClick={() => setShowAddMembers(true)}
                      className="channelEditDialog-members_header_add"
                    >
                      + Add
                    </Button>
                  </CanView>
                </div>
                <div className="channelEditDialog-members">
                  {values.members &&
                    values.members
                      .filter(member => !!member.is_active)
                      .map(displayMember => (
                        <div key={displayMember.id} className="channelEditDialog-member">
                          <CommunityMemberCard
                            member={displayMember}
                            fullWidth
                            className="channelEditDialog-member_card"
                          />
                          <p className="channelEditDialog-member_status">
                            {isChannelOwner(displayMember.id) ? "Owner" : ""}
                          </p>
                          <CanView condition={canEditChannel && !isChannelOwner(displayMember.id)}>
                            <Button
                              variant="red-text"
                              width={88}
                              className={classNames("channelEditDialog-member_remove", {
                                owner: isChannelOwner(displayMember.id),
                              })}
                              onClick={() => setMemberToRemove(displayMember)}
                            >
                              Remove
                            </Button>
                          </CanView>
                        </div>
                      ))}
                </div>
              </div>
            </SideDialog>
          </>
        );
      }}
    </Formik>
  ) : null;
};
