import React from "react";
import { FieldAttributes } from "formik";

import { StyledTimePicker } from "../StyledTimePicker";

import "./styles.scss";

const SquareTimePicker: React.FC<FieldAttributes<any>> = props => {
  return <StyledTimePicker {...props} classes={`${props.classes || ""} square-time-picker`} />;
};

export default SquareTimePicker;
