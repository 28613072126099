import React from "react";

import { IconBase } from "../IconBase";

import { IStyledSVG } from "shared/interfaces/StyledSVG";

export const StyledFBIcon: React.FC<IStyledSVG> = ({
  style = {},
  fill = "#fff",
  width = "10",
  height = "18",
  className = "",
  opacity = 1,
}) => (
  <IconBase
    fill="none"
    width={width}
    style={style}
    height={height}
    className={className}
    opacity={opacity}
    viewBox="0 0 10 18"
  >
    <path
      d="M6.065 17.9972V9.80117H8.83L9.241 6.59217H6.065V4.54817C6.065 3.62217 6.323 2.98817 7.652 2.98817H9.336V0.127169C8.51664 0.0393602 7.69305 -0.00303802 6.869 0.000169166C4.425 0.000169166 2.747 1.49217 2.747 4.23117V6.58617H0V9.79517H2.753V17.9972H6.065Z"
      fill={fill}
    />
  </IconBase>
);

export default StyledFBIcon;
