import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import _ from "lodash";

import { IPoll, IPollToSubmit, PollStatus } from "../../../../interfaces";
import { pollSchema } from "../formValidators";
import { PollCreationContent } from "../PollCreationContent";

import { AlertDialog, Button } from "shared";

import "./pollCreation.scss";

interface IEventPollCreation {
  closeCreation: () => void;
  poll?: IPoll | null;
  stopCheckUnsavedData: () => void;
  checkUnsavedData: boolean;
  onSubmit: (data: IPollToSubmit) => void;
}

export const preparePollForm = (poll?: IPoll | null): IPoll => {
  return {
    id: poll?.id || undefined,
    title: poll?.title || "",
    poll_question: poll?.poll_question || "",
    multiple_answers: poll?.multiple_answers || false,
    show_results: poll?.show_results || false,
    options: poll?.options || [
      { option_text: "", vote_results: [] },
      { option_text: "", vote_results: [] },
    ],
    status: poll?.status || PollStatus.draft,
    meeting_id: poll?.meeting_id || undefined,
    position: poll?.position || 0,
  };
};

const PollCreation: React.FC<IEventPollCreation> = props => {
  const { closeCreation, poll, checkUnsavedData, stopCheckUnsavedData, onSubmit } = props;

  const [formValues, setFormValues] = useState<IPoll>(preparePollForm());
  const [pollStatus, setPollStatus] = useState(PollStatus.draft);
  const [disabledCreateBtn, setDisabledCreateBtn] = useState(false);
  const [openUnsavedAlert, setOpenUnsavedAlert] = useState(false);

  const formikRef = useRef<any>();

  const handleSubmitPoll = (data: IPoll) => {
    setDisabledCreateBtn(true);
    const callback = (close: boolean, disabled: boolean) => {
      setDisabledCreateBtn(disabled);
      if (close) {
        closeCreation();
      }
    };
    onSubmit({ poll: { ...data, status: pollStatus }, callback });
  };

  useEffect(() => {
    poll && setFormValues(preparePollForm(poll));
    return () => {
      setFormValues(preparePollForm(null));
    };
  }, [poll]);

  useEffect(() => {
    if (checkUnsavedData) {
      if (_.isEqual(formikRef.current.values, formValues)) {
        closeCreation();
      } else {
        setOpenUnsavedAlert(true);
      }
    }
  }, [checkUnsavedData, closeCreation, formValues]);

  return (
    <div className="eventPollCreation">
      <Formik
        validationSchema={pollSchema}
        initialValues={formValues}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => handleSubmitPoll(values)}
        enableReinitialize
        innerRef={formikRef as any}
      >
        {({ handleSubmit }) => {
          return (
            <>
              <AlertDialog
                open={openUnsavedAlert}
                title="Leave Page"
                message="You have unsaved changes if you will leave the changes will be lost."
                onConfirm={() => closeCreation()}
                onCancel={() => {
                  setOpenUnsavedAlert(false);
                  stopCheckUnsavedData();
                }}
                mode="cancel"
                confirmText="Leave"
                variant="brown"
              />
              <div className="pollCreation">
                <PollCreationContent />
              </div>
              <div className="eventPollCreation-set">
                <Button
                  disabled={disabledCreateBtn}
                  className="eventPollCreation-set-publishBtn"
                  variant="orange"
                  type="submit"
                  onClick={() => {
                    setPollStatus(PollStatus.published);
                    handleSubmit();
                  }}
                >
                  Publish
                </Button>
                <Button
                  disabled={disabledCreateBtn}
                  className="eventPollCreation-set-draftBtn"
                  variant="white-text"
                  type="submit"
                  onClick={() => {
                    setPollStatus(poll?.status === PollStatus.scheduled ? PollStatus.scheduled : PollStatus.draft);
                    handleSubmit();
                  }}
                >
                  Save as {poll?.status === PollStatus.scheduled ? "scheduled" : "draft"}
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default PollCreation;
