import React, { useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { PermissionsAlert } from "../PermissionsAlert";
import { authentificated } from "../../../../Auth/store/selectors";
import { BenchAttendee } from "../BenchAttendee";
import { NamesOfParentRoutes } from "../../../../../constants";

import { RoomInterface } from "containers/BreakoutRooms/interfaces";
import { exitRoom, joinRoom } from "containers/BreakoutRooms/store/actions";
import { AlertDialog, EventAuthAlert } from "shared/components";
import { useFeatures } from "shared/hooks/FeaturesHook";
import { EFeature } from "containers/Community/interfaces";
import { IRouteProps } from "shared";
import { useRoomsAttendees } from "shared/hooks/RoomHooks";

import "./index.scss";

interface IBench {
  bench: RoomInterface;
  onBenchFull: (payload: boolean) => void;
}
const { WATCH_PARTY, STREAM } = NamesOfParentRoutes;

const Bench: React.FC<IBench> = ({ bench, onBenchFull }) => {
  const { eventCode, communityCode } = useParams<IRouteProps>();

  const [selectSeatAlertOpened, setSelectSeatAlertOpened] = useState(false);
  const [mediaPermissionsAlert, setMediaPermissionsAlert] = useState(false);
  const [disabledFeatureAlertOpened, setDisabledFeatureAlertOpened] = useState(false);

  const isAuthenticated = useSelector(authentificated());

  const dispatch = useDispatch();

  const history = useHistory();

  const features = useFeatures();

  const [benchState] = useRoomsAttendees(bench);

  const attendees = useMemo(
    () => benchState.attendees.map((attendee, idx) => <BenchAttendee key={attendee?.id || idx} attendee={attendee} />),
    [benchState.attendees],
  );
  const joinToRoom = useCallback(() => {
    dispatch(
      exitRoom.request({
        callback: () => {
          dispatch(joinRoom());
          history.push(`${communityCode ? `/${communityCode}` : ""}${STREAM}/${eventCode}${WATCH_PARTY}/${bench.code}`);
        },
      }),
    );
  }, [dispatch, history, communityCode, eventCode, bench.code]);

  const navigateToRoom = useCallback(() => {
    if (!isAuthenticated) {
      setSelectSeatAlertOpened(true);
      return;
    }
    if (benchState.filled) {
      onBenchFull(true);
      return;
    }
    if (!features.includes(EFeature.auditorium)) {
      setDisabledFeatureAlertOpened(true);
      return;
    }

    setMediaPermissionsAlert(true);
  }, [features, isAuthenticated, benchState.filled, onBenchFull]);

  return (
    <>
      <EventAuthAlert
        title="Watch Party"
        message="You need to be logged in to watch event with other people.
      Please sign up or login."
        open={selectSeatAlertOpened}
        onClose={() => setSelectSeatAlertOpened(false)}
      />
      <AlertDialog
        open={disabledFeatureAlertOpened}
        title="Watch Party"
        message="Watch together features have not been enabled by the Host."
        onConfirm={() => setDisabledFeatureAlertOpened(false)}
        mode="info"
        confirmText="Got it"
        variant="brown"
      />
      <div className="bench" onClick={navigateToRoom}>
        {mediaPermissionsAlert && (
          <PermissionsAlert setMediaPermissionsAlert={setMediaPermissionsAlert} joinToRoom={joinToRoom} />
        )}
        {attendees}
      </div>
    </>
  );
};

export default Bench;
