import { useEffect, useState } from "react";
import { v4 as uuidv1 } from "uuid";

import { memberCIDHandler } from "../../utils";

export const useMemberCID = () => {
  const [memberCID, setMemberCID] = useState<string>();
  const cid = memberCIDHandler.get();

  useEffect(() => {
    if (!cid) {
      memberCIDHandler.set(uuidv1());
    } else {
      setMemberCID(cid);
    }
  }, [cid]);

  return memberCID;
};
