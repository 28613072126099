import React from "react";
import { Formik } from "formik";

import { PollCreationContent } from "../../../EventContainer/EventPollsDialog/PollCreationContent";
import { pollSchema } from "../../../EventContainer/EventPollsDialog/formValidators";

import { IPoll } from "containers/Sermons";
import { AlertDialog } from "shared";

import "./styles.scss";

interface IProps {
  interaction: IPoll;
  openInteraction: boolean;
  onCancel: () => void;
  submitInteraction: (values: IPoll) => void;
}

const CreatePollInteraction: React.FC<IProps> = ({ interaction, openInteraction, onCancel, submitInteraction }) => {
  return (
    <Formik
      initialValues={interaction}
      enableReinitialize
      onSubmit={submitInteraction}
      validationSchema={pollSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit }) => (
        <AlertDialog
          open={openInteraction}
          title={interaction.id ? "Edit Poll" : "New Poll"}
          onConfirm={handleSubmit}
          onCancel={onCancel}
          mode="confirm"
          confirmText="Save"
          confirmClassName="Cancel"
          dialogClassName="createInteraction"
          hasForm
        >
          <div className="pollCreation">
            <PollCreationContent />
          </div>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default CreatePollInteraction;
