export enum EFileType {
  eventVideo = "event_video",
  meeting_record = "meeting_record",
  preEventVideo = "pre_event_video",
  preEventImage = "pre_event_image",
}
export enum EFileStatus {
  init = "init",
  uploading = "uploading",
  uploaded = "uploaded",
  transcoding = "transcoding",
  ready = "ready",
}

export interface ICreateFile {
  type: EFileType;
  name: string;
  meeting_id?: number;
}

export interface IUpdateFile {
  id: number;
  status: EFileStatus;
}

export interface IFile {
  id: number;
  status: EFileStatus;
  type: EFileType;
  name: string;
  path: string | null;
  origin_path: string;
  duration: number;
  created_at: string;
}

export interface IFileState {
  file: IFile | null;
}
