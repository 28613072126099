import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { getEventSettings } from "../../../store/selectors";

import { selectStream } from "containers/BreakoutRooms/store/selectors";

import "./styles.scss";

const BenchesGradient: React.FC = () => {
  const stream = useSelector(selectStream());
  const { activeDashboardTab } = useSelector(getEventSettings());

  return (
    <div
      className={classnames("benches-gradient", { "with-stream": stream, "with-side-dialog": activeDashboardTab })}
    />
  );
};

export default BenchesGradient;
