export enum CommunityActionsTypes {
  GET_COMMUNITIES_LIST = "@@COMMUNITY/GET_COMMUNITIES_LIST",
  GET_COMMUNITIES_LIST_SUCCESS = "@@COMMUNITY/GET_COMMUNITIES_LIST_SUCCESS",
  GET_COMMUNITIES_LIST_FAILURE = "@@COMMUNITY/GET_COMMUNITIES_LIST_FAILURE",

  GET_USER_COMMUNITIES_LIST = "@@COMMUNITY/GET_USER_COMMUNITIES_LIST",
  GET_USER_COMMUNITIES_LIST_SUCCESS = "@@COMMUNITY/GET_USER_COMMUNITIES_LIST_SUCCESS",
  GET_USER_COMMUNITIES_LIST_FAILURE = "@@COMMUNITY/GET_USER_COMMUNITIES_LIST_FAILURE",

  CREATE_COMMUNITY = "@@COMMUNITY/CREATE_COMMUNITY",
  CREATE_COMMUNITY_SUCCESS = "@@COMMUNITY/CREATE_COMMUNITY_SUCCESS",
  CREATE_COMMUNITY_FAILURE = "@@COMMUNITY/CREATE_COMMUNITY_FAILURE",

  GET_COMMUNITY = "@@COMMUNITY/GET_COMMUNITY",
  GET_COMMUNITY_SUCCESS = "@@COMMUNITY/GET_COMMUNITY_SUCCESS",
  GET_COMMUNITY_FAILURE = "@@COMMUNITY/GET_COMMUNITY_FAILURE",

  GET_COMMUNITY_WITH_VIMEO_TOKEN = "@@COMMUNITY/GET_COMMUNITY_WITH_VIMEO_TOKEN",
  GET_COMMUNITY_WITH_VIMEO_TOKEN_SUCCESS = "@@COMMUNITY/GET_COMMUNITY_WITH_VIMEO_TOKEN_SUCCESS",
  GET_COMMUNITY_WITH_VIMEO_TOKEN_FAILURE = "@@COMMUNITY/GET_COMMUNITY_WITH_VIMEO_TOKEN_FAILURE",

  UPDATE_COMMUNITY = "@@COMMUNITY/UPDATE_COMMUNITY",
  UPDATE_COMMUNITY_SUCCESS = "@@COMMUNITY/UPDATE_COMMUNITY_SUCCESS",
  UPDATE_COMMUNITY_FAILURE = "@@COMMUNITY/UPDATE_COMMUNITY_FAILURE",

  DELETE_COMMUNITY = "@@COMMUNITY/DELETE_COMMUNITY",
  DELETE_COMMUNITY_SUCCESS = "@@COMMUNITY/DELETE_COMMUNITY_SUCCESS",
  DELETE_COMMUNITY_FAILURE = "@@COMMUNITY/DELETE_COMMUNITY_FAILURE",

  GET_ALL_USERS_REQUEST = "@@COMMUNITY/GET_ALL_USERS_REQUEST",
  GET_ALL_USERS_SUCCESS = "@@COMMUNITY/GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAILURE = "@@COMMUNITY/GET_ALL_USERS_FAILURE",

  JOIN_TO_NEW_COMMUNITY_REQUEST = "@@COMMUNITY/JOIN_TO_NEW_COMMUNITY_REQUEST",
  JOIN_TO_NEW_COMMUNITY_SUCCESS = "@@COMMUNITY/JOIN_TO_NEW_COMMUNITY_SUCCESS",
  JOIN_TO_NEW_COMMUNITY_FAILURE = "@@COMMUNITY/JOIN_TO_NEW_COMMUNITY_FAILURE",

  COMMUNITY_INVITATION_HASH_REQUEST = "@@COMMUNITY/COMMUNITY_INVITATION_HASH_REQUEST",
  COMMUNITY_INVITATION_HASH_SUCCESS = "@@COMMUNITY/COMMUNITY_INVITATION_HASH_SUCCESS",
  COMMUNITY_INVITATION_HASH_FAILURE = "@@COMMUNITY/COMMUNITY_INVITATION_HASH_FAILURE",
  COMMUNITY_INVITATION_HASH_CLEAR = "@@COMMUNITY/COMMUNITY_INVITATION_HASH_CLEAR",

  COMMUNITY_SUBSCRIPTIONS_REQUEST = "@@COMMUNITY/COMMUNITY_SUBSCRIPTIONS_REQUEST",
  COMMUNITY_SUBSCRIPTIONS_SUCCESS = "@@COMMUNITY/COMMUNITY_SUBSCRIPTIONS_SUCCESS",
  COMMUNITY_SUBSCRIPTIONS_FAILURE = "@@COMMUNITY/COMMUNITY_SUBSCRIPTIONS_FAILURE",

  COMMUNITY_UPGRADE_SUBSCRIPTION_REQUEST = "@@COMMUNITY/COMMUNITY_UPGRADE_SUBSCRIPTION_REQUEST",
  COMMUNITY_UPGRADE_SUBSCRIPTION_SUCCESS = "@@COMMUNITY/COMMUNITY_UPGRADE_SUBSCRIPTION_SUCCESS",
  COMMUNITY_UPGRADE_SUBSCRIPTION_FAILURE = "@@COMMUNITY/COMMUNITY_UPGRADE_SUBSCRIPTION_FAILURE",

  COMMUNITY_STRIPE_ACCOUNT_LINK_REQUEST = "@@COMMUNITY/COMMUNITY_STRIPE_ACCOUNT_LINK_REQUEST",
  COMMUNITY_STRIPE_ACCOUNT_LINK_SUCCESS = "@@COMMUNITY/COMMUNITY_STRIPE_ACCOUNT_LINK_SUCCESS",
  COMMUNITY_STRIPE_ACCOUNT_LINK_FAILURE = "@@COMMUNITY/COMMUNITY_STRIPE_ACCOUNT_LINK_FAILURE",
  COMMUNITY_STRIPE_ACCOUNT_LINK_CLEAR = "@@COMMUNITY/COMMUNITY_STRIPE_ACCOUNT_LINK_CLEAR",

  GO_TO_STRIPE_ACCOUNT = "@@COMMUNITY/GO_TO_STRIPE_ACCOUNT",

  UPDATE_ANALYTICS_COMMUNITY = "@@COMMUNITY/UPDATE_ANALYTICS_COMMUNITY",
  CLEAR_CURRENT_COMMUNITY = "@@COMMUNITY/CLEAR_CURRENT_COMMUNITY",

  COMMUNITY_USERS_REQUEST = "@@COMMUNITY/COMMUNITY_USERS_REQUEST",
  COMMUNITY_USERS_SUCCESS = "@@COMMUNITY/COMMUNITY_USERS_SUCCESS",
  COMMUNITY_USERS_FAILURE = "@@COMMUNITY/COMMUNITY_USERS_FAILURE",

  CHANGE_COMMUNITY_SETTINGS_FORM = "@@COMMUNITY/CHANGE_COMMUNITY_SETTINGS_FORM",

  GET_SUBDOMAIN_LIST = "@@COMMUNITY/GET_SUBDOMAIN_LIST",
  GET_SUBDOMAIN_LIST_SUCCESS = "@@COMMUNITY/GET_SUBDOMAIN_LIST_SUCCESS",
  GET_SUBDOMAIN_LIST_FAILURE = "@@COMMUNITY/GET_SUBDOMAIN_LIST_FAILURE",

  GET_COMMUNITY_LANDING_SETTINGS = "@@COMMUNITY/GET_COMMUNITY_LANDING_SETTINGS",
  GET_COMMUNITY_LANDING_SETTINGS_SUCCESS = "@@COMMUNITY/GET_COMMUNITY_LANDING_SETTINGS_SUCCESS",
  GET_COMMUNITY_LANDING_SETTINGS_FAILURE = "@@COMMUNITY/GET_COMMUNITY_LANDING_SETTINGS_FAILURE",

  CLEAR_CURRENT_LANDING_SETTINGS = "@@COMMUNITY/CLEAR_CURRENT_LANDING_SETTINGS",

  CREATE_COMMUNITY_LANDING_SETTINGS = "@@COMMUNITY/CREATE_COMMUNITY_LANDING_SETTINGS",
  CREATE_COMMUNITY_LANDING_SETTINGS_SUCCESS = "@@COMMUNITY/CREATE_COMMUNITY_LANDING_SETTINGS_SUCCESS",
  CREATE_COMMUNITY_LANDING_SETTINGS_FAILURE = "@@COMMUNITY/CREATE_COMMUNITY_LANDING_SETTINGS_FAILURE",

  UPDATE_COMMUNITY_LANDING_SETTINGS = "@@COMMUNITY/UPDATE_COMMUNITY_LANDING_SETTINGS",
  UPDATE_COMMUNITY_LANDING_SETTINGS_SUCCESS = "@@COMMUNITY/UPDATE_COMMUNITY_LANDING_SETTINGS_SUCCESS",
  UPDATE_COMMUNITY_LANDING_SETTINGS_FAILURE = "@@COMMUNITY/UPDATE_COMMUNITY_LANDING_SETTINGS_FAILURE",
  GET_COMMUNITIES_COUNT_REQUEST = "@@COMMUNITY/GET_COMMUNITIES_COUNT_REQUEST",
  GET_COMMUNITIES_COUNT_SUCCESS = "@@COMMUNITY/GET_COMMUNITIES_COUNT_SUCCESS",
  GET_COMMUNITIES_COUNT_FAILURE = "@@COMMUNITY/GET_COMMUNITIES_COUNT_FAILURE",

  GET_COMMUNITIES_STREAMING_STATISTICS_REQUEST = "@@COMMUNITY/GET_COMMUNITIES_STREAMING_STATISTICS_REQUEST",
  GET_COMMUNITIES_STREAMING_STATISTICS_SUCCESS = "@@COMMUNITY/GET_COMMUNITIES_STREAMING_STATISTICS_SUCCESS",
  GET_COMMUNITIES_STREAMING_STATISTICS_FAILURE = "@@COMMUNITY/GET_COMMUNITIES_STREAMING_STATISTICS_FAILURE",

  GET_COMMUNITY_INTRODUCTION_STEPS_REQUEST = "@@COMMUNITY/GET_COMMUNITY_INTRODUCTION_STEPS_REQUEST",
  GET_COMMUNITY_INTRODUCTION_STEPS_SUCCESS = "@@COMMUNITY/GET_COMMUNITY_INTRODUCTION_STEPS_SUCCESS",
  GET_COMMUNITY_INTRODUCTION_STEPS_FAILURE = "@@COMMUNITY/GET_COMMUNITY_INTRODUCTION_STEPS_FAILURE",

  COMPLETE_COMMUNITY_INTRODUCTION_STEP = "@@COMMUNITY/COMPLETE_COMMUNITY_INTRODUCTION_STEP",

  SET_SUBSCRIPTION_ATTENTION = "@@COMMUNITY/SET_SUBSCRIPTION_ATTENTION",

  CREATE_TRIAL_FOR_COMMUNITY_WITHOUT_SUBSCRIPTION = "@@COMMUNITY/CREATE_TRIAL_FOR_COMMUNITY_WITHOUT_SUBSCRIPTION",

  SEND_HUB_SPOT_DATA = "@@COMMUNITY/SEND_HUB_SPOT_DATA",
  SEND_HUB_SPOT_DATA_SUCCESS = "@@COMMUNITY/SEND_HUB_SPOT_DATA_SUCCESS",
  SEND_HUB_SPOT_DATA_FAILED = "@@COMMUNITY/SEND_HUB_SPOT_DATA_FAILED",

  GET_MAU_DATA_REQUEST = "@@COMMUNITY/GET_MAU_DATA_REQUEST",
  GET_MAU_DATA_SUCCESS = "@@COMMUNITY/GET_MAU_DATA_SUCCESS",
  GET_MAU_DATA_FAILURE = "@@COMMUNITY/GET_MAU_DATA_FAILURE",

  SET_MAU_BANNER_HIDDEN = "@@COMMUNITY/SET_MAU_BANNER_HIDDEN",

  FETCH_ANALYTICS_REQUEST = "@@COMMUNITY/FETCH_ANALYTICS_REQUEST",
  FETCH_ANALYTICS_SUCCESS = "@@COMMUNITY/FETCH_ANALYTICS_SUCCESS",
  FETCH_ANALYTICS_FAILURE = "@@COMMUNITY/FETCH_ANALYTICS_FAILURE",
}
