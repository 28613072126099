import React, { useState } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { VideoList } from "../VideoList";
import { TableRenamePopup } from "../TableRenamePopup";
import useEditTable from "../../../hooks/useEditTable";

import useElementSize from "shared/hooks/ElementSizeHook/ElementSizeHook";
import { getEvent } from "containers/Sermons/store/selectors";
import { selectRoomCode, selectRooms } from "containers/BreakoutRooms/store/selectors";
import { useCurrentUserMember } from "shared";

import "./styles.scss";

interface VideWrapperProps {
  containerData: {
    width: number;
    height: number;
  };
}

export const VideoWrapper: React.FC<VideWrapperProps> = (props: VideWrapperProps) => {
  const { containerData } = props;

  const [changeNameOpened, setChangeNameOpened] = useState(false);

  const rooms = useSelector(selectRooms());
  const currentRoomCode = useSelector(selectRoomCode());
  const event = useSelector(getEvent());

  const videoListRef = React.useRef<HTMLDivElement>(null);

  useElementSize(videoListRef.current, 300);

  const videoListWidth = videoListRef.current?.offsetWidth ?? 0;
  const videoListHeight = videoListRef.current?.offsetHeight ?? 0;
  const scale = Math.min(containerData.width / videoListWidth, containerData.height / videoListHeight) - 0.15;

  const currentUserMember = useCurrentUserMember();
  const { tableName, canEditName } = useEditTable(event);

  const currentRoom = rooms.find(({ code }) => code === currentRoomCode);

  return (
    <>
      <TableRenamePopup
        room={currentRoom || null}
        opened={changeNameOpened}
        onClose={() => {
          setChangeNameOpened(false);
        }}
        memberId={currentUserMember?.id}
      />
      <div className="video-list-table-name">
        <div className="table-title">You joined the table:</div>
        <div
          className={classnames("table-name", { "can-edit": canEditName })}
          onClick={() => {
            if (canEditName && currentRoom) {
              setChangeNameOpened(true);
            }
          }}
        >
          {tableName}
        </div>
      </div>
      <div
        className="video-list-container"
        ref={videoListRef}
        style={{ transform: `translate(-50%, -50%) scale(${scale})` }}
      >
        <VideoList />
      </div>
    </>
  );
};
