import { API, METHODS, SCHEMAS } from "../../../shared/constants";

export const commonLoginSchema = {
  type: "object",
  required: ["token"],
  properties: {
    token: { type: "string" },
  },
  additionalProperties: false,
};

export default {
  [`${API.LOGIN}${SCHEMAS.REQUEST}${METHODS.POST}`]: {
    type: "object",
    required: ["email", "password"],
    properties: {
      email: { type: "string" },
      password: { type: "string" },
    },
    additionalProperties: false,
  },
  [`${API.LOGIN}${SCHEMAS.RESPONSE}${METHODS.POST}`]: commonLoginSchema,
  [`${API.REGISTRATION}${SCHEMAS.REQUEST}${METHODS.POST}`]: {
    type: "object",
    required: ["first_name", "last_name", "email", "password", "password_confirm"],
    properties: {
      first_name: { type: "string" },
      last_name: { type: "string" },
      email: { type: "string" },
      password: { type: "string" },
      password_confirm: { type: "string" },
    },
    additionalProperties: true,
  },
  [`${API.REGISTRATION}${SCHEMAS.RESPONSE}${METHODS.POST}`]: {
    type: "object",
    required: ["id"],
    properties: {
      id: { type: "number" },
    },
    additionalProperties: false,
  },
};
