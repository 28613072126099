import React from "react";
import { Checkbox, RadioProps } from "@material-ui/core";
import classnames from "classnames";

import "./styledCheckBox.scss";

interface StyledCheckBoxProps extends RadioProps {
  withBorder?: boolean;
  theme?: "orange" | "light";
}

const StyledCheckBox: React.FC<StyledCheckBoxProps> = ({ withBorder = true, theme = "orange", ...props }) => {
  return (
    <Checkbox
      className={classnames("styledCheckBox", theme)}
      disableRipple
      color="default"
      checkedIcon={<span className={classnames("styledCheckBox-icon", "styledCheckBox-checkedIcon")} />}
      icon={<span className={classnames("styledCheckBox-icon", { withBorder })} />}
      {...props}
    />
  );
};

export default StyledCheckBox;
