import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import * as Sentry from "@sentry/react";

import configureStore from "./store";
import "./styles/index.scss";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import history from "./shared/history/history";
import en from "./translations/en";
import "./momentLocales";
import "./utils/firebase";
import { APP_ROOT_ID } from "./constants";
import config from "./config";

if (process.env.REACT_APP_ENV === "dev") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const ReactRedux = require("react-redux/lib");
  whyDidYouRender(React, { trackExtraHooks: [[ReactRedux, "useSelector"]], trackHooks: true });
}

const { store } = configureStore();

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
  },
  lng: "en",

  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

if (["production", "qa"].includes(process.env.REACT_APP_ENV || "")) {
  Sentry.init({
    dsn: config.sentryDSN,
  });
}

export default store;

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById(APP_ROOT_ID),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
