import React from "react";

import { IEvent } from "containers/Sermons";
import meeting_anonymous_page from "assets/images/meeting-anonymous-page.svg";

import "./index.scss";

interface AnonymousPageProps {
  event: IEvent;
}

export default function AnonymousPage({ event }: AnonymousPageProps) {
  return (
    <div className="anonymous-page-wrapper">
      <div className="information-side">
        <div className="meeting-title">{event.name}</div>
        {event.subject ? <div className="meeting-description">{event.subject}</div> : null}
        <div className="information-tip">
          Please create an account or log in on the right panel to join this meeting.
        </div>
      </div>
      <div className="wallpaper-wrapper">
        {event.image_url ? (
          <img src={event.image_url} alt={event.name} />
        ) : (
          <img className="default-image" src={meeting_anonymous_page} alt={event.name} />
        )}
      </div>
    </div>
  );
}
