import React from "react";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import classnames from "classnames";

import { ThemeType } from "shared/interfaces";

import "./index.scss";

interface IEventTooltip {
  classNames?: string;
  theme?: ThemeType;
}

const compileTextProps = (theme: ThemeType, classNames?: string) => ({
  classes: {
    popper: classnames("eventTooltip", `eventTooltip-${theme}`, classNames),
  },
});
const EventTooltip: React.FC<IEventTooltip & TooltipProps> = ({
  children,
  title = "",
  theme = "light",
  classNames,
  arrow = true,
  ...props
}) => {
  return (
    <Tooltip title={title} arrow={arrow} {...compileTextProps(theme, classNames)} {...props}>
      {children}
    </Tooltip>
  );
};

export default EventTooltip;
