import React, { ReactNode } from "react";
import classnames from "classnames";

import { StyledLink, StyledTextMaskInput } from "shared";
import { TextMaskInputProps } from "shared/components/StyledTextMaskInput/StyledTextMaskInput";
import gmail from "assets/icons/gmail.svg";
import outlook from "assets/icons/outlook.svg";
import email_icon_big from "assets/icons/email_icon_big.png";

import "./styles.scss";

type Props = {
  title: string;
  subTitle: ReactNode;
  onResendEmail: () => void;
  onChangeEmail: () => void;
  codeInputData?: TextMaskInputProps;
  className?: string;
  eventPage?: boolean;
};
const EmailSent: React.FC<Props> = ({
  title,
  subTitle,
  onResendEmail,
  onChangeEmail,
  codeInputData,
  className,
  eventPage,
}) => {
  return (
    <div className={classnames("auth-root", "email-sent", className || "", { event: eventPage })}>
      <div className="email-sent-img">
        <img src={email_icon_big} alt="email icon" />
      </div>
      <h2 className="email-sent-title">{title}</h2>
      <div className="email-sent-subtitle">{subTitle}</div>
      <div className="email-sent-divider" />
      {codeInputData ? (
        <div className="email-sent-input">
          <StyledTextMaskInput {...codeInputData} />
        </div>
      ) : null}
      <div className="email-sent-help-text">
        If you didn't get the email please click
        <span className="email-sent-help-text-link" onClick={onResendEmail}>
          here to send again
        </span>
      </div>
      <div className="email-sent-help-text">
        Wrong email address? You can
        <span className="email-sent-help-text-link" onClick={onChangeEmail}>
          change it here
        </span>
      </div>
      <div className="email-sent-buttons">
        <StyledLink href="https://mail.google.com/mail/u/0/#inbox">
          <img src={gmail} alt="gmail" />
          Open Gmail
        </StyledLink>
        <StyledLink href="https://outlook.live.com/mail/0/inbox">
          <img src={outlook} alt="outlook" />
          Open Outlook
        </StyledLink>
      </div>
    </div>
  );
};

export default EmailSent;
