import React, { useCallback, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import StreamRelayInput from "../StreamRelayInput";
import Divider from "../Divider";
import { checkVimeoConnection } from "../../../../store/actions";
import { actions, selectors } from "../../../../../Community";
import { getIsVimeoTokenValid } from "../../../../store/selectors";
import config from "../../../../../../config";
import { startLoading, stopLoading } from "../../../../../App/store/actions";

import { copyTextHandler } from "shared/utils";
import { notificationActions } from "containers/Notifications/store/actions";
import { IEventForm, StreamRelayFields } from "containers/Sermons/interfaces";

import "./styles.scss";

const generateAuthorizationLink = (communityId: number) => {
  const { client_id, redirect_url } = config.vimeo;
  const encodedRedirectURL = encodeURIComponent(redirect_url);
  return `https://api.vimeo.com/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${encodedRedirectURL}&state=${communityId}&scope=public private edit create delete`;
};

const StreamVimeoRelay: React.FC = () => {
  const dispatch = useDispatch();
  const currentCommunity = useSelector(selectors.getCommunity());
  const isTokenValid = useSelector(getIsVimeoTokenValid());

  const [waitingForToken, setWaitingForToken] = useState(false);
  const [intervalCount, setIntervalCount] = useState(0);
  const [interval, saveInterval] = useState(0);

  const {
    values: { stream_settings, vimeo_token },
    handleChange,
    setFieldValue,
  } = useFormikContext<IEventForm>();

  useEffect(() => {
    if (isTokenValid || intervalCount > 10) {
      setWaitingForToken(false);
      clearInterval(interval);
      dispatch(stopLoading());
    }
  }, [dispatch, interval, intervalCount, isTokenValid]);

  useEffect(() => {
    if (waitingForToken && intervalCount < 10 && currentCommunity && !isTokenValid && !interval) {
      const interval = setInterval(() => {
        dispatch(
          actions.getCommunityWithVimeoToken.request({
            code: currentCommunity?.code,
            type: "create_event",
            callback: setFieldValue,
          }),
        );
        setIntervalCount(s => s + 1);
      }, 3000);
      saveInterval(interval);
    }
  }, [currentCommunity, dispatch, interval, intervalCount, isTokenValid, setFieldValue, waitingForToken]);

  useEffect(() => {
    if (currentCommunity && !currentCommunity?.vimeo_token)
      dispatch(
        actions.getCommunityWithVimeoToken.request({
          code: currentCommunity?.code,
          type: "create_event",
          callback: setFieldValue,
        }),
      );
  }, [currentCommunity, dispatch, setFieldValue]);

  useEffect(() => {
    if (vimeo_token && !isTokenValid) {
      dispatch(checkVimeoConnection.request({ vimeo_token, showNotification: false }));
    }
  }, [vimeo_token, isTokenValid, dispatch]);

  const copyInputHandler = useCallback(
    (field: StreamRelayFields) => () => {
      copyTextHandler(stream_settings?.[field] || "");
      dispatch(notificationActions.success("Copied to the clipboard"));
    },
    [dispatch, stream_settings],
  );

  const checkVimeoConnectionHandler = useCallback(() => {
    if (vimeo_token) {
      dispatch(checkVimeoConnection.request({ vimeo_token, showNotification: true }));
    } else {
      dispatch(notificationActions.error("Please, provide Vimeo token"));
    }
  }, [dispatch, vimeo_token]);

  const connectToVimeo = () => {
    if (currentCommunity?.id) {
      const link = generateAuthorizationLink(currentCommunity.id);
      window.open(link);
      setWaitingForToken(true);
      dispatch(startLoading());
    }
  };

  return stream_settings ? (
    <div className="stream-relay">
      <div className="stream-relay-section common">
        <StreamRelayInput
          disabled
          name="stream_settings.stream_url"
          value={stream_settings.stream_url}
          label="Stream RTMP URL"
          handleButtonClick={copyInputHandler("stream_url")}
          buttonText="Copy"
        />
        <StreamRelayInput
          disabled
          name="stream_settings.stream_key"
          value={stream_settings.stream_key}
          inputType="password"
          label="Stream Key"
          handleButtonClick={copyInputHandler("stream_key")}
          buttonText="Copy"
        />
      </div>
      <div className="stream-relay-section">
        <div className="stream-relay-section-header">Use Vimeo to multistream to your Social Media channels!</div>
        <Divider />
        <div className="vimeo-token-section">
          {isTokenValid && vimeo_token ? (
            <StreamRelayInput
              name="vimeo_token"
              value={vimeo_token || ""}
              label="Vimeo Personal Access Tokens"
              handleButtonClick={checkVimeoConnectionHandler}
              inputType="password"
              buttonText="Test Connection"
              handleChange={handleChange("vimeo_token")}
            />
          ) : (
            <div className="vimeo-connect" onClick={() => connectToVimeo()}>
              Connect your Vimeo Account
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default StreamVimeoRelay;
