import React from "react";
import { Line, LineChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";

import { IMemberAnalyticsData } from "../../../interfaces";

interface IProps {
  data?: IMemberAnalyticsData[];
  stroke: string;
  name: string;
}

const MembersChart: React.FC<IProps> = ({ data, stroke, name }) => {
  return (
    <LineChart width={557} height={230} data={data} margin={{ right: 34, bottom: 10 }}>
      <CartesianGrid strokeDasharray="3 3" vertical={false} />
      <XAxis dataKey="date" />
      <YAxis />
      <Legend
        iconType="plainline"
        verticalAlign="top"
        align="right"
        wrapperStyle={{ position: "absolute", top: "-20px", textTransform: "capitalize" }}
      />
      <Tooltip wrapperStyle={{ textTransform: "capitalize" }} />
      <Line type="monotone" dataKey="members" strokeWidth={2} stroke={stroke} dot={false} name={name} />
    </LineChart>
  );
};

export default MembersChart;
