import React from "react";

import "./EventSideDialogPollHeader.scss";

export interface EventSideDialogPollHeaderProps {
  onBack?: () => void;
  title?: string;
  image?: string;
}

const EventSideDialogPollHeader: React.FC<EventSideDialogPollHeaderProps> = props => {
  const { onBack, title, image } = props;
  return (
    <div className="eventSideDialog-header">
      <div className="eventSideDialog-header-title-chat">
        {onBack && (
          <div onClick={onBack} className="eventSideDialog-header-back">
            Back
          </div>
        )}
        <div className="eventSideDialogPollHeader-title">
          {image && (
            <div className="eventSideDialogPollHeader-title-img">
              <img src={image} alt="poll" />
            </div>
          )}
          <div className="eventSideDialogPollHeader-title-text">{title}</div>
        </div>
      </div>
    </div>
  );
};

export default EventSideDialogPollHeader;
