import React from "react";
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";

import { ILoginForm } from "../../interfaces";

import { SquareButton, StyledTextField } from "shared";

import "./styles.scss";

type Props = {
  onSendMagicLink: () => void;
  onSubmit: () => void;
  onForgot: () => void;
};

const LoginOptions: React.FC<Props> = ({ onSendMagicLink, onSubmit, onForgot }) => {
  const { values, errors, handleChange, handleBlur } = useFormikContext<ILoginForm>();
  return (
    <div className="login-options">
      <p className="block-description">
        Get a one-time sign in link sent to your email instead of typing your password
      </p>
      <SquareButton onClick={onSendMagicLink} variant="orange" className="send-magic-link">
        Send Magic Link
      </SquareButton>
      <div className="divider">
        <div className="text">OR</div>
        <div className="line" />
      </div>
      <p className="block-description">Log in with password</p>
      <Grid container justify="center">
        <StyledTextField
          fullWidth
          label="Password"
          name="password"
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors}
        />
      </Grid>
      <Grid container justify="flex-end">
        <SquareButton className={"auth-forgotBtn"} onClick={onForgot} variant="blue-text" width={130}>
          Forgot password?
        </SquareButton>
      </Grid>
      <Grid container justify="center">
        <Grid item className="auth-submitBtn">
          <SquareButton onClick={() => onSubmit()} variant="blue" className="submit-button">
            Log In
          </SquareButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginOptions;
