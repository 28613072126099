import React from "react";

import { EventType } from "../../../../Sermons";

import Button from "shared/components/Button/Button";

import "./createEventCard.scss";

interface ICreateEventCard {
  info: {
    imgSrc: string;
    title: string;
    description: string;
    badge: string;
  };
  onAddNewEvent: (type: EventType, isDefault: boolean) => void;
  disabledCard: boolean;
  disabledMeeting: boolean;
  type: EventType;
}

const CreateEventCard: React.FC<ICreateEventCard> = ({
  info: { imgSrc, description, title, badge },
  onAddNewEvent,
  disabledCard,
  disabledMeeting,
  type,
}) => {
  return (
    <div
      className={`createEventCard ${
        disabledCard ? "createEventCard--disabledCard" : disabledMeeting ? "createEventCard--disabledMeeting" : ""
      }`}
    >
      <div className="createEventCard__frontCard">
        <img src={imgSrc} alt="create event img" />
        <div className="cardTitle">{title}</div>
      </div>
      <div className="createEventCard__backCard">
        {disabledMeeting && badge ? <div className="cardBadge">{badge}</div> : null}
        <div className="cardTitle">{title}</div>
        <div className="cardDescription">{description}</div>
        <div className="cardAction">
          <Button width={200} variant={disabledMeeting ? "orange" : "blue"} onClick={() => onAddNewEvent(type, false)}>
            {disabledMeeting ? "Upgrade subscription" : "Get Started!"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateEventCard;
