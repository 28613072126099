import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Video } from "../Video";
import AudioAnalyser from "./AudioAnalyser";

import { MediaConstraints } from "containers/BreakoutRooms/constants";
import { getUserMediaDevices } from "containers/BreakoutRooms/store/actions";
import { getUserDevices } from "containers/BreakoutRooms/store/selectors";
import { AlertDialog, DropDown } from "shared";
import {
  changeMediaConstraints,
  getStreamDevicesValue,
  prepareDropDownMediaItems,
} from "shared/utils/mediaStreamHelpers";

import "./index.scss";

interface StreamSettingsModalInterface {
  open: boolean;
  onCancel: () => void;
  onConfirm?: (payload: MediaStream) => void;
  hasAudio: boolean;
  hasVideo: boolean;
}

function StreamSettingsModal({ open, onCancel, onConfirm, hasAudio, hasVideo }: StreamSettingsModalInterface) {
  const [stream, updateStream] = useState<null | MediaStream>(null);
  const [constraints, updateConstraints] = useState<MediaStreamConstraints>(MediaConstraints);
  const userDevices = useSelector(getUserDevices());
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserMediaDevices.request());
  }, [dispatch]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => updateStream(stream))
      .catch(() => updateConstraints({ ...constraints, video: false }));

    return () => {
      stream?.getTracks()?.forEach((track: MediaStreamTrack) => {
        track.stop();
      });
      updateStream(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constraints]);

  const handleStreamSave = useCallback(() => {
    if (stream) {
      stream?.getAudioTracks().forEach(function (track) {
        track.enabled = hasAudio;
      });
      stream?.getVideoTracks().forEach(function (track) {
        track.enabled = hasVideo;
      });
      onConfirm && onConfirm(stream);
    }
    updateStream(null);
  }, [onConfirm, stream, hasAudio, hasVideo]);

  const onConstrainsChange = useCallback(
    value => {
      updateConstraints(changeMediaConstraints([value.toString()], userDevices, constraints));
    },
    [constraints, userDevices],
  );

  const { audio, video } = useMemo(() => getStreamDevicesValue(stream), [stream]);
  const dropDownMediaItems = useMemo(() => prepareDropDownMediaItems(userDevices), [userDevices]);

  return (
    <AlertDialog
      open={open}
      mode="confirm"
      title="Settings"
      variant="brown"
      confirmText="Save"
      onCancel={() => onCancel()}
      onConfirm={handleStreamSave}
    >
      <div className="settings-wrapper">
        <div className="inner-wrapper">
          <div className="video-wrapper mirrored">{stream && <Video srcObject={stream} muted={true} />}</div>
          <div className="camera-settings">
            <div className="option">
              {userDevices && stream && video?.label && (
                <>
                  <div className="label">Camera</div>
                  <DropDown
                    items={dropDownMediaItems["videoinput"]}
                    onChange={onConstrainsChange}
                    size="full"
                    spacer={false}
                    variant="brown"
                    value={video.label}
                  />
                </>
              )}
            </div>
          </div>
          <div className="audio-settings">
            <div className="option">
              {userDevices && stream && audio?.label && (
                <>
                  <div className="label">Microphone</div>
                  <DropDown
                    items={dropDownMediaItems["audioinput"]}
                    onChange={onConstrainsChange}
                    size="full"
                    spacer={false}
                    variant="brown"
                    value={audio.label}
                  />
                  <AudioAnalyser audio={stream} />
                </>
              )}
            </div>
            {/* <div className="option">
              {userDevices && stream && (
                <>
                  <div className="label">Speakers</div>
                  <DropDown
                    items={prepareItems(userDevices, "audiooutput")}
                    onChange={value => console.log(value)}
                    size="sm"
                    spacer={false}
                    variant="brown"
                    value={getStreamOutputValue(stream, userDevices)}
                  />
                </>
              )}
              </div>*/}
          </div>
        </div>
      </div>
    </AlertDialog>
  );
}

export default React.memo(StreamSettingsModal);
