import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { NamesOfParentRoutes, NameOfRoutes } from "../../../../../constants";

import { StyledTextField, SquareButton } from "shared";
import { actions, ILoginForm, IUser, RedirectLink } from "containers/Auth";
import { setError } from "containers/Auth/store/actions";
import { authError } from "containers/Auth/store/selectors";

const { AUTH, STREAM } = NamesOfParentRoutes;
const { FORGOT, EVENT_AUTH_SUCCESS } = NameOfRoutes;

type Props = {
  user: IUser;
  onSendMagicLink: () => void;
};
const Login: React.FC<Props> = ({ onSendMagicLink }) => {
  const dispatch = useDispatch();
  const loginError = useSelector(authError());

  const { values, errors, handleChange, handleBlur, setFieldError } = useFormikContext<ILoginForm>();

  useEffect(() => {
    dispatch(actions.clearForgotEmail());
  }, [dispatch]);

  useEffect(() => {
    if (loginError) {
      setFieldError(
        "password",
        loginError.meta?.isActive === false
          ? "User is inactive. Check your email."
          : "Password you entered is incorrect.",
      );
      dispatch(setError(null));
    }
  }, [dispatch, loginError, setFieldError]);

  return (
    <div className="event-auth-login">
      <div className="event-auth-login-magic">
        <p className="eventAuthDialog-description">
          Get a one-time log in link sent to your email instead of typing your password
        </p>
        <SquareButton type="button" onClick={onSendMagicLink} variant="orange">
          Send Magic Link
        </SquareButton>
      </div>

      <div className="divider">
        <div className="text">
          <span>OR</span>
        </div>
        <div className="line" />
      </div>
      <div className="eventAuthDialog-password">
        <p className="eventAuthDialog-description">Log in with password</p>
        <StyledTextField
          fullWidth
          label={"Password"}
          placeholder=""
          name="password"
          errors={errors}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          theme="dark"
        />
      </div>
      <div className="eventAuthDialog-forgot">
        <RedirectLink
          className="eventAuthDialog-forgotBtn"
          to={`${AUTH}${FORGOT}?email=${encodeURIComponent(values.email)}&redirectTo=${encodeURIComponent(
            `${STREAM}${EVENT_AUTH_SUCCESS}?variant=reset`,
          )}`}
          target="_blank"
        >
          Forgot password?
        </RedirectLink>
      </div>
      <div className="eventAuthDialog-buttons">
        <SquareButton type="submit" variant="blue">
          Log In
        </SquareButton>
      </div>
    </div>
  );
};

export default Login;
