import React, { useMemo, useCallback } from "react";
import { FieldAttributes } from "formik";

import SquareTextField from "../SquareTextField";

import "./index.scss";

const CustomTextField: React.FunctionComponent<FieldAttributes<any>> = props => {
  const { value, max, showMaxCounter, ...rest } = props;
  const currentLength = useMemo(() => (value ? value.length : 0), [value]);

  const checkTextSize = useCallback(
    (target: any) => {
      if (max && String(target.value || "").length > max) {
        target.value = String(target.value).slice(0, max);
      }
    },
    [max],
  );

  const onInput = useCallback(
    e => {
      checkTextSize(e.target);
    },
    [checkTextSize],
  );

  return (
    <div className="custom-text-field">
      {showMaxCounter && max && (
        <div className="custom-text-field-counter">
          {currentLength}/{max}
        </div>
      )}
      <SquareTextField {...rest} value={value} onInput={onInput} />
    </div>
  );
};

export default CustomTextField;
