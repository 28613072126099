import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateRange, Range } from "react-date-range";
import moment from "moment";

import { EAnalyticsRange } from "../../interfaces";
import { fetchAnalytics } from "../../store/actions";
import { getAnalytics, getCommunity } from "../../store/selectors";
import { EngagementChart, MembersChart } from "../../components/CommunityAnalytics/Charts";
import { AnalyticsTitle } from "../../components/CommunityAnalytics/AnalyticsTitle";
import { TopListTable } from "../../components/CommunityAnalytics/TopListTable";
import { EngagementReports } from "../../components/CommunityAnalytics/EngagementReports";
import { defineAnalyticsDateRange } from "../../utils";

import { Button, ClickOutsideHook, DropDown, IDropDownItem } from "shared";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./index.scss";

const initialDropDownItems: IDropDownItem[] = [
  { text: "Custom", value: EAnalyticsRange.custom },
  { text: "Last Week", value: EAnalyticsRange.last_week },
  { text: "Last Month", value: EAnalyticsRange.last_month },
  { text: "Last Year", value: EAnalyticsRange.last_year },
];

const CommunityAnalyticsContainer: React.FC = () => {
  const dispatch = useDispatch();

  const community = useSelector(getCommunity());
  const analytics = useSelector(getAnalytics());

  const pickerRef = useRef(null);
  const { isOutside } = ClickOutsideHook(pickerRef);

  const [analyticsRange, setAnalyticsRange] = useState(analytics?.range || EAnalyticsRange.last_week);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dropDownItems, setDropDownItems] = useState<IDropDownItem[]>(initialDropDownItems);
  const [customRange, setCustomRange] = useState<Range[]>([
    {
      startDate: moment().subtract(15, "d").toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ]);

  const onChangeHandler = useCallback(value => {
    setAnalyticsRange(value);
    if (value === EAnalyticsRange.custom) {
      setOpenDatePicker(true);
    }
  }, []);

  const updateAnalyticsHandler = useCallback(() => {
    if (community) {
      const { startDate, endDate } = customRange[0];
      const dateRange = defineAnalyticsDateRange(analyticsRange, startDate, endDate);
      dispatch(fetchAnalytics.request({ id: community.id, dateRange, range: analyticsRange }));
    }
  }, [dispatch, analyticsRange, community, customRange]);

  const saveCustomRangeHandler = useCallback(() => {
    const newDropDownItems = initialDropDownItems.map(item => {
      if (item.value === EAnalyticsRange.custom) {
        item.text = `${moment(customRange[0].startDate).format("MM/DD/YYYY")} - ${moment(customRange[0].endDate).format(
          "MM/DD/YYYY",
        )}`;
      }
      return item;
    });
    setDropDownItems(newDropDownItems);
    setOpenDatePicker(false);
  }, [customRange]);

  const cancelCustomRangeHandler = useCallback(() => {
    setAnalyticsRange(analytics?.range || EAnalyticsRange.last_week);
    setOpenDatePicker(false);
  }, [analytics]);

  useEffect(() => {
    if (!analytics) {
      updateAnalyticsHandler();
    }
  }, [dispatch, analytics, updateAnalyticsHandler]);

  useEffect(() => {
    if (isOutside) {
      setOpenDatePicker(false);
    }
  }, [isOutside]);

  return (
    <div className="analytics">
      <div className="analytics-welcomeCard">
        <div className="analytics-welcomeCard-title">Welcome to Altar Live Analytics Dashboard!</div>
        <div className="analytics-welcomeCard-description">
          Real-time analytics and engagement metrics of your community's online events & meetings. Search by date range
          and download reports.
        </div>
        <div className="analytics-welcomeCard-range">
          <DropDown
            items={dropDownItems}
            onChange={onChangeHandler}
            size="full"
            spacer={false}
            value={analyticsRange}
            width={344}
            skipScroll
          />
          <Button variant="orange" onClick={updateAnalyticsHandler} width={104}>
            Update
          </Button>
          {openDatePicker ? (
            <div className="analytics-welcomeCard-range-picker" ref={pickerRef}>
              <DateRange
                onChange={item => setCustomRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={customRange}
                showDateDisplay={false}
                months={2}
                showMonthAndYearPickers={false}
                direction="horizontal"
                rangeColors={["#7B9EB9"]}
              />
              <div className="analytics-welcomeCard-range-picker-control">
                <Button variant="blue-text" onClick={cancelCustomRangeHandler} width={112}>
                  Cancel
                </Button>
                <Button variant="blue" onClick={saveCustomRangeHandler} width={112}>
                  Save
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="analytics-engagement">
        <AnalyticsTitle
          title="Engagement Overview"
          info="The sum of attendees across all events and meetings during selected data range"
        />
        <div className="analytics-engagement-note">
          <span>Note:</span> Anonymous users were not counted by the system until June, 2022. <br /> Unfortunately,
          Altar's analytics will not include data on anonymous visitors before this date.
        </div>
        <div className="analytics-engagement-chart">
          <EngagementChart data={analytics?.engagementData} />
        </div>
      </div>
      <div className="analytics-members">
        <div className="analytics-members-new">
          <AnalyticsTitle title="New Members" info="Number of people who created a new account" />
          <div className="analytics-members-new-chart">
            <MembersChart data={analytics?.newMembersData} stroke="#7B9EB9" name="New Members" />
          </div>
        </div>
        <div className="analytics-members-returning">
          <AnalyticsTitle
            title="Returning Visitors"
            info="Number of returning anonymous visitors who have come to more than one event"
          />
          <div className="analytics-members-returning-chart">
            <MembersChart data={analytics?.returningMembersData} stroke="#E1A077" name="Returning Visitors" />
          </div>
        </div>
      </div>
      <div className="analytics-topList">
        <AnalyticsTitle
          title="Events & Meetings Top List"
          info="Top list of events and meetings attended during selected data range"
        />
        <TopListTable tableData={analytics?.topEventsData || []} />
      </div>
      <div className="analytics-reports">
        <AnalyticsTitle title="Engagement Reports" info="Download reports from the selected data range" />
        {analytics && community ? (
          <EngagementReports analyticsRange={analytics.range} customRange={customRange} communityId={community?.id} />
        ) : null}
      </div>
    </div>
  );
};

export default CommunityAnalyticsContainer;
