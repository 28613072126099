import * as Yup from "yup";

import { passwordValidator, phoneValidator } from "../../../shared/constants/validations";

export const emailValidationSchema = Yup.object().shape({
  email: Yup.string().max(150).email("Incorrect Email format").required("This field is required"),
});
export const passwordValidationSchema = Yup.object().shape({
  password: Yup.string().required("This field is required"),
});

export const loginValidationSchema = emailValidationSchema.concat(passwordValidationSchema);

export const forgotValidationSchema = emailValidationSchema;

export const registrationValidationSchema = Yup.object({
  first_name: Yup.string().max(35, "Name field is too long").required("This field is required"),
  last_name: Yup.string().max(35, "Last Name field is too long").required("This field is required"),
  email: Yup.string().max(150).email("Incorrect Email format").required("This field is required"),
  phone: phoneValidator,
  password: Yup.string().required("This field is required").concat(passwordValidator),
  password_confirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "The passwords you entered do not match")
    .required("This field is required"),
});
