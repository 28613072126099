import React from "react";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";

import { Button } from "shared";

import "./EventHostNotesContent.scss";

type Props = {
  editedBy: string;
  hostNotes: string;
  onEdit: () => void;
  onDelete: () => void;
};

const EventHostNotesContent: React.FC<Props> = ({ editedBy, hostNotes, onEdit, onDelete }) => {
  return (
    <div className="hostNotes-content">
      <div className="hostNotes-content-block">
        <div
          className="hostNotes-content-block-text"
          dangerouslySetInnerHTML={{
            __html: stateToHTML(convertFromRaw(JSON.parse(hostNotes))),
          }}
        />
        <div className="hostNotes-content-block-divider" />
        <div className="hostNotes-content-block-actions">
          <Button variant="white-text" onClick={onEdit} width={30} height={25}>
            Edit
          </Button>
          <div className="hostNotes-content-block-actions-delete" onClick={onDelete} />
        </div>
      </div>
      <div className="hostNotes-content-lastUpdates">Last edited by {editedBy}</div>
    </div>
  );
};

export default EventHostNotesContent;
