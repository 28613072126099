import { createMuiTheme } from "@material-ui/core";

import manifestTheme from "../styles/variables.module.scss";

export const styledTextFieldTheme = createMuiTheme({
  typography: {
    fontFamily: manifestTheme.fontHeebo,
  },
  palette: {
    primary: {
      main: manifestTheme.blackColor,
    },
    error: {
      main: manifestTheme.redColor,
    },
    background: {
      default: manifestTheme.whiteColor,
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        padding: "26px 16px 40px 16px",
        boxSizing: "border-box",
        border: "1px solid",
        borderTop: "6px solid",
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 24px) scale(1)",
      },
      formControl: {
        left: "unset",
      },
    },
    MuiFormLabel: {
      root: {
        textTransform: "capitalize",
        color: manifestTheme.blackColor,
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px",
        letterSpacing: "0.02em",
      },
    },
    MuiInputBase: {
      root: {
        fontStyle: "normal",
        fontSize: "14px",
        letterSpacing: "0.02em",
      },
      multiline: {
        paddingTop: "14px",
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "rgb(122, 122, 122)",
        },
        "&:before": {
          borderBottom: `1px solid ${manifestTheme.lightGreyColor}`,
        },
        "&&&&:hover:before": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
        },
      },
    },
  },
});
