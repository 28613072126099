import { createMuiTheme } from "@material-ui/core";

export const dashboardPanelTheme = createMuiTheme({
  overrides: {
    MuiListItem: {
      gutters: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    MuiGrid: {
      item: {
        width: "100%",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "transparent",
        boxShadow: "none",
        paddingTop: "24px",
        paddingBottom: "16px",
        height: "calc(100% - 24px)",
        overflowY: "auto",
        overflowX: "hidden",
        // eslint-disable-next-line
        ["@media (max-width:768px)"]: {
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
          alignItems: "flex-start",
        },
      },
      elevation1: {
        boxShadow: "none",
      },
    },
  },
});
