import React, { useCallback, useState } from "react";

import { IAnnouncementTemplate } from "../../../../Sermons";
import { ETemplatesType } from "../../../interfaces";

import { AlertDialog } from "shared";
import ic_remove from "assets/icons/ic_remove.svg";
import edit_icon_blue from "assets/icons/edit_icon_blue.svg";

import "./index.scss";

interface IProps {
  announcementTemplate: IAnnouncementTemplate;
  deleteHandler: (templateType: ETemplatesType, template_id: number) => void;
  editHandler: (announcementTemplate: IAnnouncementTemplate) => void;
}

const AnnouncementTemplate: React.FC<IProps> = ({ announcementTemplate, deleteHandler, editHandler }) => {
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const confirmDelete = useCallback(() => {
    announcementTemplate.id && deleteHandler(ETemplatesType.announcements, announcementTemplate.id);
    setOpenDeleteAlert(false);
  }, [announcementTemplate.id, deleteHandler]);

  return (
    <div className="announcementTemplate">
      <AlertDialog
        open={openDeleteAlert}
        title="Delete Template"
        message="Are you sure that you want to delete this template? It will no longer be available inside of Events or Meetings."
        onConfirm={confirmDelete}
        onCancel={() => setOpenDeleteAlert(false)}
        mode="confirm"
        confirmText="Yes, Delete"
        confirmClassName="confirmDeleteAnnouncementTemplateBtn"
      />
      <div className="announcementTemplate-title">
        <div className="announcementTemplate-title-label">
          <div className="announcementTemplate-title-label-text">Title</div>
          <div className="announcementTemplate-title-label-action">
            <img src={edit_icon_blue} alt="edit" onClick={() => editHandler(announcementTemplate)} />
            <img src={ic_remove} alt="remove" onClick={() => setOpenDeleteAlert(true)} />
          </div>
        </div>
        <div className="announcementTemplate-title-text">{announcementTemplate.title}</div>
      </div>
      <div className="announcementTemplate-description">
        <div className="announcementTemplate-description-label">Announcement Text</div>
        <div className="announcementTemplate-description-text">{announcementTemplate.description}</div>
      </div>
      <div className="announcementTemplate-type">
        <div className="announcementTemplate-type-content">Show as {announcementTemplate.type}</div>
      </div>
      {announcementTemplate.button_link && (
        <div className="announcementTemplate-button">
          <div className="announcementTemplate-button-text">
            <div className="announcementTemplate-button-text-label">Button Text</div>
            <div className="announcementTemplate-button-text-content">{announcementTemplate.button_text}</div>
          </div>
          <div className="announcementTemplate-button-link">
            <div className="announcementTemplate-button-link-label">Button Link</div>
            <div className="announcementTemplate-button-link-content">{announcementTemplate.button_link}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnouncementTemplate;
