import React from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { Video, VideoMemberLabel } from "..";
import { AttendeePic } from "../AttendeePic";

import { selectIsImutted, selectStream, selectVideoState } from "containers/BreakoutRooms/store/selectors";
import { IMember } from "containers/Member";
import { IEventMemberResponse } from "containers/Sermons";

interface SelfVideoProps {
  hasStreams: boolean;
  member: IMember | undefined;
  streamMembers: IEventMemberResponse[];
}

export default function SelfVideo({ hasStreams, member, streamMembers }: SelfVideoProps) {
  const stream = useSelector(selectStream());
  const isMutted = useSelector(selectIsImutted());
  const isVideoDisabled = useSelector(selectVideoState());

  return (
    <div className={classnames("video-wrapper", { center: !hasStreams })}>
      <div className="video-container">
        <div className="videobox mirrored">
          <Video srcObject={stream} muted={true} />
        </div>
        {member && <VideoMemberLabel muted={isMutted} memberName="You" />}
        {member && isVideoDisabled && <AttendeePic member={member} streamMembers={streamMembers} />}
      </div>
    </div>
  );
}
