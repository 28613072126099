export const JOIN = "join";
export const CALL = "call";
export const EXIT = "exit";
export const OFFER = "offer";
export const ANSWER = "answer";
export const CANDIDATE = "candidate";
export const ROOM_NOT_FOUND = "room_not_found";
export const LEAVE = "leave";
export const CREATE = "create";
export const ROOMS = "rooms";
export const JOIN_MEETING = "join_meeting";
export const MUTE = "mute";
export const DISABLE_VIDEO = "disable_video";
export const ROOM_FULL = "room_full";
export const CONNECT = "connect";
export const DISCONNECT = "disconnect";
export const GET_IDS_OF_ROOM_MEMBERS = "get_ids_of_room_members";
export const ANOTHER_ROOM_JOIN = "another_room_join";
export const RECONNECT_ATTEMPT = "reconnect_attempt";
