import React, { FC } from "react";
import { useHistory } from "react-router";

import { DialogCard } from "../DialogCard";

import { Button } from "shared/components";

import "./index.scss";

const StartTourCard: FC<{ startTour?: () => void }> = ({ startTour }) => {
  const history = useHistory();
  const handleClickButton = () => {
    if (startTour) {
      startTour();
    }

    history.replace({ search: "?stream_tour=1" });
  };

  return (
    <DialogCard>
      <div className="productTour">
        <h3 className="productTour-title">Show me around</h3>
        <Button onClick={handleClickButton} width={96} variant="blue" className="productTour-btn">
          Start
        </Button>
      </div>
    </DialogCard>
  );
};
export default StartTourCard;
