import React from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import { FormControlLabel } from "@material-ui/core";

import { IPoll, IPollTemplate } from "../../../../interfaces";

import { Button, CustomFormikField, CustomTextField, StyledCheckBox } from "shared";
import poll_delete_icon from "assets/icons/poll_delete_icon.svg";

import "./styles.scss";

const PollCreationContent: React.FC = () => {
  const { values, errors, handleChange, validateField } = useFormikContext<IPoll | IPollTemplate>();
  return (
    <div className="pollCreation-content">
      <div className="pollCreation-content-titleField">
        <Field name="title">
          {() => (
            <CustomTextField
              fullWidth
              label="Title"
              name="title"
              errors={errors}
              value={values.title}
              onChange={handleChange("title")}
              onBlur={() => validateField("title")}
              showMaxCounter
              max={30}
            />
          )}
        </Field>
      </div>
      <div className="pollCreation-content-textField">
        <Field name="poll_question">
          {() => (
            <CustomTextField
              fullWidth
              multiline
              rowsMax="3"
              label="Poll Text"
              name="poll_question"
              errors={errors}
              value={values.poll_question}
              onChange={handleChange("poll_question")}
              onBlur={() => validateField("poll_question")}
              showMaxCounter
              max={150}
            />
          )}
        </Field>
      </div>
      <div className="pollCreation-content-checkControl">
        <FormControlLabel
          control={<StyledCheckBox checked={values.multiple_answers} onChange={handleChange} name="multiple_answers" />}
          label="Allow multiple answers"
        />
        <FormControlLabel
          control={<StyledCheckBox checked={values.show_results} onChange={handleChange} name="show_results" />}
          label="Show results to everyone"
        />
      </div>
      <div className="optionsList">
        <FieldArray name="options">
          {({ remove, push }) => (
            <>
              {values.options.map((option, index) => (
                <div className="optionsList-item" key={index}>
                  <CustomFormikField
                    fullWidth
                    label={`Option ${index + 1}`}
                    name={`options[${index}].option_text`}
                    value={values.options[index].option_text}
                    onChange={handleChange}
                    onBlur={() => validateField(`options[${index}].option_text`)}
                    errors={errors}
                    className="option-item-input"
                  />
                  {errors.options && errors.options[index] && (errors.options[index] as any).option_text && (
                    <div className="label-error">{(errors.options[index] as any).option_text}</div>
                  )}
                  {values.options.length > 2 ? (
                    <button onClick={() => remove(index)}>
                      <img src={poll_delete_icon} alt="delete field" />
                    </button>
                  ) : null}
                </div>
              ))}
              {values.options.length < 5 && (
                <Button
                  className="optionsList-addOption"
                  variant="orange-text"
                  onClick={() => push({ option_text: "" })}
                  width={100}
                >
                  + Add option
                </Button>
              )}
            </>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default PollCreationContent;
