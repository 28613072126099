import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { EMemberRestriction } from "../../../Sermons";
import { getRestrictedMembers } from "../../../Member/store/selectors";
import { IMember } from "../../../Member";

import { ClickOutsideHook, MemberRestrictionAlert } from "shared";

import "./styles.scss";

interface Props {
  member: IMember;
}

const MessageOptionMenu: React.FC<Props> = ({ member }) => {
  const ref = useRef(null);

  const { isOutside } = ClickOutsideHook(ref);

  const restrictedMembers = useSelector(getRestrictedMembers());

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [unbanChatAlertOpen, setUnbanChatAlertOpen] = useState(false);
  const [restrictionType, setRestrictionType] = useState<EMemberRestriction | null>(null);

  const isRestrictedMember = useMemo(() => {
    return restrictedMembers.some(m => m.member_id === member.id);
  }, [member, restrictedMembers]);

  useEffect(() => {
    if (isOutside) {
      setIsOptionsOpen(false);
    }
  }, [isOutside]);

  return (
    <>
      <MemberRestrictionAlert
        unbanChatAlertOpen={unbanChatAlertOpen}
        restrictionType={restrictionType}
        setRestrictionType={type => setRestrictionType(type)}
        setUnbanChatAlertOpen={val => setUnbanChatAlertOpen(val)}
        member_id={member.id}
      />
      <div
        className={classnames("messageMenu", { opened: isOptionsOpen })}
        ref={ref}
        onClick={() => setIsOptionsOpen(prev => !prev)}
      >
        {isOptionsOpen ? (
          <div className="messageMenu-options">
            {isRestrictedMember ? (
              <div onClick={() => setUnbanChatAlertOpen(true)} className="messageMenu-options-item">
                Unban from Chat
              </div>
            ) : (
              <>
                <div
                  onClick={() => setRestrictionType(EMemberRestriction.chat_timeout)}
                  className="messageMenu-options-item"
                >
                  Timeout from Chat
                </div>
                <div
                  onClick={() => setRestrictionType(EMemberRestriction.chat_ban)}
                  className="messageMenu-options-item"
                >
                  Ban from Chat
                </div>
              </>
            )}
          </div>
        ) : null}
        <hr />
        <hr />
        <hr />
      </div>
    </>
  );
};

export default MessageOptionMenu;
