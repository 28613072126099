import React from "react";

import "./progressCircle.scss";

interface IProgressCircle {
  radius: number;
  colour: string;
  strokeWidth: string;
  percentage: number;
  baseColour: string;
  onClick?: () => void;
}

const cleanPercentage = (percentage: number) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0;
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

const Circle = ({
  colour,
  percentage = 100,
  radius,
  strokeWidth,
}: {
  colour: string;
  percentage?: number;
  radius: number;
  strokeWidth: string;
}) => {
  const circ = 2 * Math.PI * radius;
  const strokePct = ((100 - percentage) * circ) / 100;
  return (
    <circle
      r={radius}
      cx="50%"
      cy="50%"
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""}
      strokeWidth={strokeWidth}
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    />
  );
};

const ProgressCircle: React.FC<IProgressCircle> = ({
  radius,
  colour,
  strokeWidth,
  percentage,
  baseColour,
  onClick,
}) => {
  const pct = cleanPercentage(percentage);
  return (
    <div className="progressCircle" onClick={onClick}>
      <svg>
        <Circle colour={baseColour} radius={radius} strokeWidth={strokeWidth} />
        <Circle colour={colour} percentage={pct} radius={radius} strokeWidth={strokeWidth} />
      </svg>
      <div>{percentage.toFixed(0)}%</div>
    </div>
  );
};

export default ProgressCircle;
