import { Reducer } from "redux";
import produce from "immer";

import { INotifications } from "../interfaces";
import { NotificationActionTypes } from "./constants";

import { chatNotificationSettingsHandler } from "shared";

const initialState: INotifications = {
  snackbars: [],
  popup: null,
  notification: null,
  chatNotificationSettings: chatNotificationSettingsHandler.get() || { notifyBanner: true, notifySound: true },
};

const reducer: Reducer = (state: INotifications = initialState, action) => {
  switch (action.type) {
    case NotificationActionTypes.ENQUEUE_SNACKBAR:
      return produce(state, nextState => {
        nextState.snackbars = [...state.snackbars, { ...action.payload }];
      });
    case NotificationActionTypes.REMOVE_SNACKBAR:
      return produce(state, nextState => {
        nextState.snackbars = [...state.snackbars].filter(snackbar => snackbar.key !== action.payload);
      });
    case NotificationActionTypes.CHAT_NOTIFICATIONS:
      return produce(state, nextState => {
        nextState.chatNotificationSettings = action.payload;
      });
    case NotificationActionTypes.ENABLE_POPUP:
      return produce(state, nextState => {
        nextState.popup = action.payload;
      });
    case NotificationActionTypes.REMOVE_POPUP:
      return produce(state, nextState => {
        nextState.popup = null;
      });
    case NotificationActionTypes.ENABLE_NOTIFICATION:
      return produce(state, nextState => {
        nextState.notification = action.payload;
      });
    case NotificationActionTypes.REMOVE_NOTIFICATION:
      return produce(state, nextState => {
        nextState.notification = null;
      });
    default:
      return state;
  }
};

export { reducer as NotificationReducer };
