import { all, fork } from "redux-saga/effects";

import authSaga from "../containers/Auth/store/saga";
import { saga as sermonSaga } from "../containers/Sermons/store";
import { communitySaga } from "../containers/Community/store";
import { memberSaga } from "../containers/Member/store";
import { onboardingSaga } from "../containers/Onboarding/store";
import { discussionsSaga } from "../containers/Discussions/store";
import { breakoutRoomSaga } from "../containers/BreakoutRooms/store";

import generalSagas from "shared/stores/general/saga";
import meetingSaga from "containers/Meeting/store/saga";
import awsSaga from "shared/stores/aws/saga";
import { landingSaga } from "containers/LandingPage/store";
import { stripeSaga } from "shared/stores/stripe";
import userSaga from "containers/User/store/saga";

const allSagas = [
  authSaga,
  communitySaga,
  memberSaga,
  onboardingSaga,
  sermonSaga,
  userSaga,
  discussionsSaga,
  breakoutRoomSaga,
  stripeSaga,
  landingSaga,
  awsSaga,
  meetingSaga,
  generalSagas,
];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
