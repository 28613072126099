import React from "react";

import { IconBase } from "../IconBase";

import { IStyledSVG } from "shared/interfaces/StyledSVG";

export const StyledEmailIcon: React.FC<IStyledSVG> = ({
  style = {},
  fill = "#fff",
  width = "17",
  height = "13",
  className = "",
  opacity = 1,
}) => (
  <IconBase
    fill="none"
    width={width}
    style={style}
    height={height}
    className={className}
    opacity={opacity}
    viewBox="0 0 17 13"
  >
    <path
      d="M8.50147 6.5L0.00976562 2.11589V1.08333C0.00976562 0.778646 0.114806 0.521918 0.324888 0.313151C0.534969 0.104384 0.783749 0 1.07123 0H15.9317C16.2192 0 16.468 0.104384 16.6781 0.313151C16.8881 0.521918 16.9932 0.778646 16.9932 1.08333V2.11589L8.50147 6.5ZM7.97074 7.58333H9.03221L16.9932 3.36849V11.9167C16.9932 12.2101 16.8881 12.464 16.6781 12.6784C16.468 12.8928 16.2192 13 15.9317 13H1.07123C0.783749 13 0.534969 12.8928 0.324888 12.6784C0.114806 12.464 0.00976562 12.2101 0.00976562 11.9167V3.36849L7.97074 7.58333Z"
      fill={fill}
    />
  </IconBase>
);

export default StyledEmailIcon;
