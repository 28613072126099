import React, { memo, useEffect, useRef } from "react";
import { areEqual, VariableSizeList } from "react-window";
import { useSelector } from "react-redux";

import { EventMemberListItem } from "../EventMemberListItem";
import WaitingToJoinMemberListItem from "../WaitingToJoinMemberListItem/WaitingToJoinMemberListItem";
import { getWaitingToJoinMembers } from "../../../store/selectors";

import { IStreamEventMember } from "containers/Sermons/interfaces";

import "./styles.scss";

interface EventMemberListProps {
  members: Array<IStreamEventMember & { isWaiting?: boolean; knockRequestedDate?: number }>;
  title?: string;
  startChat?: (member: number) => void;
  startChatAnonymous?: (member: number) => void;
  openRestrictionAlert: () => void;
}

// @ts-ignore
const Row = memo((props: any) => {
  const { data, index, style } = props;
  const { members, ...rest } = data;

  const member = members[index];

  return (
    <div style={{ ...style, marginBottom: 16 }}>
      {member.isWaiting ? (
        <WaitingToJoinMemberListItem
          key={member.memberId}
          waitingMember={member.member}
          knockRequestedDate={member.knockRequestedDate}
        />
      ) : (
        <EventMemberListItem key={member.memberId} member={member} {...rest} />
      )}
    </div>
  );
}, areEqual);

const EventMemberList: React.FC<EventMemberListProps> = ({ title, members, ...props }) => {
  const waitingMembers = useSelector(getWaitingToJoinMembers());
  const listRef = useRef<VariableSizeList>(null);

  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [waitingMembers]);

  return (
    <div className="event-member-list ">
      {title && <div className="event-member-list-title">{title}</div>}
      <div className="event-member-list-list">
        <VariableSizeList
          ref={listRef}
          useIsScrolling
          itemCount={members.length}
          itemData={{
            members,
            ...props,
          }}
          height={window.innerHeight - 350}
          width={"100%"}
          itemSize={index => (members[index].isWaiting ? 190 : 56)}
          {...props}
        >
          {Row}
        </VariableSizeList>
      </div>
    </div>
  );
};

export default EventMemberList;
