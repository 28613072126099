import React, { useCallback, useState } from "react";
import moment from "moment";

import RecordPopupItem from "./RecordsPopupItem";
import { EFileDownloadState } from "../../../interfaces";
import { downloadRecordHandler } from "../../../utils";

import { IFile } from "shared/interfaces/File";
import { AlertDialog } from "shared";

import "./recordsListPopup.scss";

type Props = {
  opened: boolean;
  onCancel: () => void;
  files: IFile[];
};

const RecordsListPopup: React.FC<Props> = ({ opened, onCancel, files }) => {
  const [downloadAllProgress, setDownloadAllProgress] = useState<EFileDownloadState | null>(null);

  const downloadAllFiles = useCallback(() => {
    setDownloadAllProgress(EFileDownloadState.processing);
    files.forEach(file => {
      const fileType = file.origin_path.match(/.\w+$/)?.[0];
      if (fileType) {
        downloadRecordHandler(file.path || file.origin_path, `${file.name}${fileType}`, setDownloadAllProgress);
      }
    });
  }, [files]);

  return (
    <AlertDialog
      open={opened}
      title="Video Recordings"
      onConfirm={downloadAllProgress === EFileDownloadState.complete ? onCancel : downloadAllFiles}
      onCancel={onCancel}
      mode="confirm"
      confirmText={downloadAllProgress === EFileDownloadState.complete ? "Done" : "Download All"}
      cancelText="Close"
    >
      <div className="meeting-records">
        {files.map((file, index, filesArr) => {
          return (
            <div key={file.id}>
              {index === 0 || !moment(file.created_at).isSame(filesArr[index - 1].created_at, "day") ? (
                <div className="meeting-records-date">{moment(file.created_at).format("MMMM Do, YYYY")}</div>
              ) : null}
              <RecordPopupItem
                file={file}
                downloadHandler={downloadRecordHandler}
                index={index}
                downloadAllProgress={downloadAllProgress}
              />
            </div>
          );
        })}
      </div>
    </AlertDialog>
  );
};

export default RecordsListPopup;
