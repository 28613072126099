export enum AnnouncementStatus {
  published = "published",
  draft = "draft",
  scheduled = "scheduled",
}

export const AnnouncementStatusMap = {
  [AnnouncementStatus.published]: "Published",
  [AnnouncementStatus.draft]: "Draft",
  [AnnouncementStatus.scheduled]: "Scheduled",
};

export enum AnnouncementType {
  panel = "panel",
  popup = "popup",
  notification = "notification",
}

export interface IFetchAnnouncementsShape {
  meeting_id: number;
}

interface IBaseAnnouncement {
  title: string;
  description: string;
  button_text?: string;
  button_link?: string;
  type: AnnouncementType;
  status: AnnouncementStatus;
}

export interface IAnnouncement extends IBaseAnnouncement {
  id: number;
  title: string;
  published_at?: string;
  created_at: string;
  created_by: string;
  updated_by?: string;
  meeting_id: number;
  creator: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    code: string;
  };
  is_read?: boolean;
  scheduled_at?: string | null;
  position: number;
  use_button?: boolean;
}

export interface ICreateAnnouncementShape extends IBaseAnnouncement {
  meeting_id: number;
  position: number;
}

export interface IUpdateAnnouncementShape extends IBaseAnnouncement {
  id: number;
  meeting_id: number;
  scheduled_at?: string | null;
}

export interface IAnnouncementDialogValues extends IBaseAnnouncement {
  use_button: boolean;
}

export interface IReadAnnouncementsShape {
  meeting_id: number;
  ids: number[];
}

export interface IAnnouncementTemplate extends Omit<IBaseAnnouncement, "status"> {
  id?: number;
  community_id: number;
  status?: AnnouncementStatus;
  use_button?: boolean;
}

export interface IAnnouncementTemplateForm extends IAnnouncementTemplate {
  use_button: boolean;
}

export interface IAnnouncementInteraction extends IBaseAnnouncement {
  id?: number;
  use_button?: boolean;
  position: number;
  temporary_key?: number;
}
