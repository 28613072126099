import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { selectors as userSelectors } from "../../../containers/Auth/store";
import { generateName } from "../../utils/randomNames";

export function useCustomName(isUpdated = false) {
  const currentUser = useSelector(userSelectors.getUser());
  const [customName, setCustomName] = useState("");

  useEffect(() => {
    let newName: string;
    if (currentUser) {
      newName = `${currentUser.first_name} ${currentUser.last_name}`.trim();
    } else if (!customName || !isUpdated) {
      const savedName = localStorage.getItem("custom_name");
      newName = savedName || generateName();
      localStorage.setItem("custom_name", newName);
    } else {
      return;
    }
    setCustomName(newName);
  }, [currentUser, customName, isUpdated]);

  return customName;
}
