import React, { useState } from "react";
import Timer from "react-compound-timer";

import "./index.scss";

type Props = {
  initTime: number;
};

type UnitType = "day" | "hour" | "minute";

const getTitle = (unit: UnitType, value: number) => `${unit}${value === 1 ? "" : "s"}`;

const NextEventCountDown: React.FC<Props> = ({ initTime }) => {
  const [daysTitle, setDaysTitle] = useState("");
  const [hoursTitle, setHoursTitle] = useState("");
  const [minutesTitle, setMinutesTitle] = useState("");

  const formatValue = (unit: UnitType) => (value: number) => {
    switch (unit) {
      case "day":
        setDaysTitle(getTitle(unit, value));
        break;
      case "hour":
        setHoursTitle(getTitle(unit, value));
        break;
      case "minute":
        setMinutesTitle(getTitle(unit, value));
        break;
    }
    return `${value}`;
  };

  return (
    <div className="next-eventCard-countDown">
      <Timer initialTime={initTime > 0 ? initTime : 0} direction="backward">
        <div className="countDown-card">
          <div className="countDown-card-value">
            <Timer.Days formatValue={formatValue("day")} />
          </div>
          <div className="countDown-card-description">{daysTitle}</div>
        </div>
        <div className="countDown-card">
          <div className="countDown-card-value">
            <Timer.Hours formatValue={formatValue("hour")} />
          </div>
          <div className="countDown-card-description">{hoursTitle}</div>
        </div>
        <div className="countDown-card">
          <div className="countDown-card-value">
            <Timer.Minutes formatValue={formatValue("minute")} />
          </div>
          <div className="countDown-card-description">{minutesTitle}</div>
        </div>
      </Timer>
    </div>
  );
};

export default NextEventCountDown;
