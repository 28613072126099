import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { NamesOfParentRoutes } from "../../../../constants";

import { BreakOutRoomScreenTypes } from "containers/BreakoutRooms/interfaces";
import { changeCurrentBreakoutRoomScreen } from "containers/BreakoutRooms/store/actions";
import { getCurrentBreakOutRoomScreen } from "containers/BreakoutRooms/store/selectors";
import { IRouteProps } from "shared";

import "./index.scss";

const { CLASSROOMS, BREAKOUTROOMS } = NamesOfParentRoutes;

interface TablesClassroomSwitcherProps {
  breakOutRoomJoined?: boolean;
}

export default function TablesClassroomSwitcher(props: TablesClassroomSwitcherProps) {
  const dispatch = useDispatch();
  const breakOutRoomScreen = useSelector(getCurrentBreakOutRoomScreen());
  const history = useHistory();
  const { roomCode } = useParams<IRouteProps>();

  const navigateToTab = (from: string, to: string) => {
    if (props.breakOutRoomJoined) {
      dispatch(
        changeCurrentBreakoutRoomScreen(
          to === CLASSROOMS ? BreakOutRoomScreenTypes.classrooms : BreakOutRoomScreenTypes.tables,
        ),
      );
    } else {
      let url = history.location.pathname.replace(from, to);
      if (roomCode) {
        url = url.replace(`/${roomCode}`, "");
      }

      history.push(url);
    }
  };

  const isTabActive = useCallback(
    (type: string, screen: string) => {
      if (props.breakOutRoomJoined) {
        return breakOutRoomScreen === screen;
      } else {
        return history.location.pathname.includes(type);
      }
    },
    [breakOutRoomScreen, history, props.breakOutRoomJoined],
  );

  return (
    <div className="table-classroom-switcher-wrapper">
      <div
        className={`tab-item ${isTabActive(BREAKOUTROOMS, BreakOutRoomScreenTypes.tables) ? "active" : ""}`}
        onClick={() => navigateToTab(CLASSROOMS, BREAKOUTROOMS)}
      >
        Tables
      </div>
      <div
        className={`tab-item ${isTabActive(CLASSROOMS, BreakOutRoomScreenTypes.classrooms) ? "active" : ""}`}
        onClick={() => navigateToTab(BREAKOUTROOMS, CLASSROOMS)}
      >
        Rooms
      </div>
    </div>
  );
}
