import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FieldArray } from "formik";
import { FormControlLabel, RadioGroup } from "@material-ui/core";

import { pollVote } from "../../../../store/actions";
import { authentificated, getMember } from "../../../../../Auth/store/selectors";

import { Button, StyledCheckBox, StyledRadio } from "shared/components";
import { IPoll } from "containers/Sermons/interfaces";
import EventAuthAlert from "shared/components/Event/EventAuthAlert/EventAuthAlert";

import "./pollVoting.scss";

interface IEventPollVote {
  poll: IPoll;
}

const initialOptionValue = {
  voteResults: [],
};

const PollVoting: React.FC<IEventPollVote> = props => {
  const { poll } = props;

  const [isMemberVoted, setIsMemberVoted] = useState(false);
  const [openJoinAlert, setOpenJoinAlert] = useState(false);

  const dispatch = useDispatch();
  const currentMember = useSelector(getMember());
  const isAuthenticated = useSelector(authentificated());

  const handleSubmitVote = (data: any) => {
    if (currentMember) {
      const preparedData = Array.isArray(data?.voteResults)
        ? data.voteResults.map((option_id: number) => ({ option_id: Number(option_id), member_id: currentMember.id }))
        : [{ option_id: Number(data.voteResults), member_id: currentMember.id }];

      dispatch(
        pollVote.request({
          data: preparedData,
          callback: setIsMemberVoted,
        }),
      );
    }
    setIsMemberVoted(true);
  };

  return (
    <>
      <EventAuthAlert
        title="Polls"
        message="You need to be logged in to vote. Please sign up or login."
        open={openJoinAlert}
        onClose={() => setOpenJoinAlert(false)}
      />
      <Formik initialValues={initialOptionValue} onSubmit={handleSubmitVote}>
        {({ values, handleChange, handleSubmit }) => {
          return (
            <div className="pollVoting">
              {poll.multiple_answers ? (
                <FieldArray name="voteResults">
                  {() =>
                    poll.options.map((option, index) => (
                      <div key={index}>
                        <FormControlLabel
                          control={<StyledCheckBox onChange={handleChange} name={`voteResults`} value={option.id} />}
                          label={option.option_text}
                        />
                      </div>
                    ))
                  }
                </FieldArray>
              ) : (
                <RadioGroup
                  name="voteResults"
                  value={values.voteResults}
                  onChange={handleChange}
                  className="radio-options"
                >
                  {poll.options.map((option, index) => (
                    <div key={index}>
                      <FormControlLabel value={`${option.id}`} control={<StyledRadio />} label={option.option_text} />
                    </div>
                  ))}
                </RadioGroup>
              )}
              <Button
                disabled={values.voteResults.length <= 0 || isMemberVoted}
                className="pollVoting-voteBtn"
                variant="orange"
                onClick={() => (isAuthenticated ? handleSubmit() : setOpenJoinAlert(true))}
                type="submit"
              >
                Vote
              </Button>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default PollVoting;
