import { IEventSection } from "./EventView";

export enum MeetingMode {
  "full-video",
  "normal",
  "equally",
  "full-aside",
}

export type MeetingModeType = keyof typeof MeetingMode;

export interface IMeetingDialogState {
  layoutMode: MeetingModeType;
  slideFontSize: number;
  hideReactions: boolean;
}

export interface IMeetingSlide extends IEventSection {
  slideScale?: number;
}

export interface IEmoji {
  src: string;
  svgPath: string;
}

export type EmojisType = "smile" | "heart" | "pray" | "clap";

export interface IFetchFileUrl {
  community_id: number;
  image_base64: string;
  callback: (fileUrl: string) => void;
}
