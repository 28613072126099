import * as Client from "stream-chat";
import { ChannelMemberResponse, Channel } from "stream-chat";
import { CSSProperties } from "react";
import moment from "moment";

import { IChannel } from "../../containers/Discussions";
import { IMember } from "../../containers/Member";

export const getChannelName = (channel?: IChannel, currentMember?: IMember): string => {
  if (!channel) {
    return "";
  }
  if (channel.name) {
    return channel.name;
  }

  if (channel.is_group) {
    const members = channel.members.filter(({ id, is_active }) => currentMember?.id !== id && is_active);
    if (members.length) {
      const { first_name, last_name } = members[0];
      return `${first_name} ${last_name} + ${members.length} others`;
    }
  }

  const member = get2ndMember(channel, currentMember?.id);

  if (!member) {
    return "";
  }

  return getMemberName(member);
};

export const getChannelShortName = (channel: IChannel, currentMember: IMember): string => {
  if (channel.is_group) {
    return "";
  }

  const member = get2ndMember(channel, currentMember?.id);

  return member ? `${member.first_name[0]}${member.last_name[0]}` : "";
};

const getMemberName = ({ email, first_name, last_name }: IMember) => {
  const name = `${first_name} ${last_name}`.trim();
  return first_name || last_name ? name : email;
};

export const getChannelImageBg = (channel: IChannel, currentMember: IMember): CSSProperties => {
  if (channel.is_group) {
    return {
      backgroundColor: channel.color,
    };
  }

  const member = get2ndMember(channel, currentMember?.id);

  return member && member.image_url
    ? {
        backgroundImage: `url(${member.image_url})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }
    : {
        backgroundColor: channel.color,
      };
};

export const get2ndMember = (channel: IChannel, currentMemberId?: string | number): IMember | undefined => {
  return channel.members.find(m => String(m.id) !== String(currentMemberId));
};

export const timeToAMPM = (date?: Date | string, options: { swapDay?: boolean; skipDay?: boolean } = {}) => {
  const { swapDay, skipDay } = options;
  if (!date) return;
  const current = moment();
  const messageDate = moment(date);
  if (!messageDate.isValid()) return;
  if (!current.isSame(messageDate, "year")) {
    return messageDate.format("MMM D, YYYY");
  } else if (!current.isSame(messageDate, "day") && !skipDay) {
    return messageDate.format(swapDay ? "D MMM" : "MMM D");
  } else {
    return messageDate.format("h:mm  A");
  }
};

const getGroupParticipantsStatus = (members: ChannelMemberResponse[]): string => {
  const membersOnline = members.filter(({ user }) => user && user.online);
  return `${members.length} participants${membersOnline.length ? `, ${membersOnline.length} online` : ""}`;
};

const getInterlocutorLastSeen = (date: Date): string => {
  const timeToDisplay = () => {
    const minutes = moment().diff(date, "minutes");
    if (minutes < 1) {
      return "less than minute";
    } else if (minutes <= 59) {
      return `${minutes} min`;
    } else {
      const hours = moment().diff(date, "hours");
      if (hours <= 24) {
        return `${hours}h`;
      } else {
        const days = moment().diff(date, "days");
        if (days <= 7) {
          return `${days}d`;
        } else {
          const months = moment().diff(date, "months");
          if (months <= 12) {
            return `${months}m`;
          } else {
            const years = moment().diff(date, "years");
            return `${years}y`;
          }
        }
      }
    }
  };
  return `Last seen ${timeToDisplay()} ago`;
};

export const customizeGetstreamChannel = function (
  channel: Client.Channel,
  currentChannel?: IChannel,
  displayImage?: string,
) {
  const channelMembers = (channel.state && Object.values(channel.state.members)) || [];
  const currentUserId = channel.getClient().userID;
  const interlocutor = channelMembers.find(({ user }) => currentUserId && user && user.id !== currentUserId);
  const isChannelOwner = getMemberChatId(currentChannel?.created_by || 0) === currentUserId;
  const channelIsGroup = currentChannel?.is_group;

  const getChannelImage = () => {
    if (channelIsGroup) {
      return displayImage;
    }
    const memberToImage = currentChannel?.members?.find(({ id }) => getMemberChatId(id) !== currentUserId);
    return memberToImage?.image_url ?? undefined;
  };

  const isOnlineInPersonal = (): boolean => {
    if (channelIsGroup) {
      return false;
    }
    return interlocutor?.user?.online ?? false;
  };

  const getMembersStatus = () => {
    if (channelIsGroup) {
      return getGroupParticipantsStatus(channelMembers);
    }
    if (interlocutor?.user?.last_active && !isOnlineInPersonal()) {
      return getInterlocutorLastSeen(new Date(interlocutor.user.last_active));
    }
  };

  const getChannelName = (): string | undefined => {
    if (currentChannel?.name) {
      return currentChannel.name;
    } else if (channelIsGroup) {
      if (currentChannel) {
        const members = currentChannel.members.filter(
          ({ id, is_active }) => getMemberChatId(id) !== currentUserId && is_active,
        );
        if (members.length) {
          const { first_name, last_name } = members[0];
          return `${first_name} ${last_name} + ${members.length} others`;
        }
      }
    } else {
      if (interlocutor) {
        return interlocutor?.user?.name;
      }
    }
  };

  const isInterlocutorLeftChannel = (): string | undefined => {
    if (!channelIsGroup) {
      if (!interlocutor) {
        return getChannelName();
      }
    }
  };

  return {
    getChannelImage,
    isOnlineInPersonal,
    getMembersStatus,
    channelIsGroup,
    isChannelOwner,
    getChannelName,
    currentChannel,
    isInterlocutorLeftChannel,
    interlocutor,
  };
};

export const getMemberChatId = (member_id: number) => String(member_id);

export const getChannelUnreadMessagesCount = (channel?: Channel) => {
  let count = 0;
  if (!channel) {
    return count;
  }
  const lastRead = channel.lastRead();
  const messages = channel?.state?.messages || [];
  const clientId = channel?.getClient().userID;

  messages.forEach(message => {
    const createdAt = message.created_at;
    if (clientId === message?.user?.id || message.silent || message.type === "deleted") {
      return;
    }
    if (!lastRead || createdAt > lastRead) {
      count++;
    }
  });
  return count;
};
