import { EStreamSettings } from "../../Sermons";

export interface ICommunity {
  id: number;
  name: string;
  image_url: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  visibility: boolean;
  main_contact_id: number;
  main_contact: IMainContact;
  hash: string;
  code: string;
  timezone: string;
  stripeAccountLink?: string | null;
  meetings_count?: number;
  members_count?: number;
  website: string;
  stream_settings: EStreamSettings | null;
  complete_intro?: boolean;
  is_verified: boolean;
  vimeo_token?: string;
  ga_id: string | null;
}

export interface IMainContact {
  id: number;
  first_name: string;
  last_name: string;
  birthday?: Date;
  phone?: string;
  email?: string;
  address?: string;
  image_url?: string;
  visibility?: number;
}
export interface ICommunityItem {
  id: number;
  code: string;
  name: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  visibility: boolean;
  image_url?: string;
  main_contact_id: number;
  main_contact: IMainContact;
  contact_name?: string;
  meetings_count?: number;
  members_count?: number;
  website?: string;
  ga_id: string | null;
}
export interface ICreateCommunity {
  name: string;
  main_contact_id?: number;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  image_base64?: string | null | undefined;
  visibility?: boolean;
  timezone?: string;
  event_kinds?: ECommunityEventsKinds[];
  complete_intro?: boolean;
  referral?: string;
  website?: string;
}

export interface ICreateCommunityRequest {
  community: ICreateCommunity;
  callback: (data: ICommunityItem) => void;
}

export interface IUpdateCommunity {
  community: Partial<ICreateCommunity>;
  communityCode: string;
  communityId: number;
  silent?: boolean;
}

export interface IDeleteCommunity {
  community_id: number;
}

export enum EFeature {
  auditorium = "auditorium",
  lobby = "lobby",
  meeting = "meeting",
  host_and_greeters = "host_and_greeters",
  pre_event_slides = "pre_event_slides",
  rtmp = "rtmp",
  // announcements = "announcements",
  // polls = "polls",
  // analytics = "analytics",
  // rows = "rows",
  // tables = "tables",
  // classrooms = "classrooms",
  // pre_recorded_video = "pre_recorded_video",
  platform_tour = "platform_tour",
  // landing_page = "landing_page",
  // rtmp_relay = "rtmp_relay",
  // general_chat = "general_chat",
  // direct_chat = "direct_chat",
  // set_up_your_space = "set_up_your_space",
  // live_customer_support = "live_customer_support",
  // event_navigation = "event_navigation",
  // scheduled_interactions = "scheduled_interactions",
  // interaction_templates = "interaction_templates",
  // customizable_favicon = "customizable_favicon",
  // customizable_subdomain = "customizable_subdomain",
  // member_management = "member_management",
  // security_functions = "security_functions",
  // single_event_reports = "single_event_reports",
  // multiple_event_reports = "multiple_event_reports",
}

interface IFeature {
  description: string;
  name: EFeature;
  title: string;
}

export interface IProduct {
  product_id?: number;
  id?: number;
  title: string;
  name: string | null;
  description: string;
  licensed_member_count: number;
  stripe_price_code: string;
  price: string;
  features: IFeature[];
  next_payment_date: Date;
  last_payment_date: Date;
  stripe_status_code: EStripeStatusCode;
  next_tier_product_id: number | null;
  is_active: boolean;
  is_yearly: boolean;
  is_recommended: boolean;
}

export interface ICommunitySubscriptions {
  currentMembersCount: number;
  canceledSubscription: IProduct;
  currentSubscriptions: IProduct[];
  possibleProducts: IProduct[];
  publishableKey: string;
  hasPaymentMethod: boolean;
}

export interface ISubscriptionItem {
  price: string;
  quantity: number;
}

export interface ISubscriptionMetadata {
  clientReferenceId: string;
}

export interface IUpgradeCommunitySubscription {
  communityCode: string;
  items: ISubscriptionItem[];
  prorationBehavior: "create_prorations" | "none" | "always_invoice";
  metadata: ISubscriptionMetadata;
}

export interface ICommunitySubscriptionRequest {
  code: string;
  hideLoading?: boolean;
  callBack?: (subscriptions?: ICommunitySubscriptions) => void;
}

export interface ICommunityLandingSettingsLink {
  id?: number;
  community_landing_settings_id?: number;
  name: string;
  value: string;
}

export interface ICommunityLandingSettings {
  id?: number;
  community_id: number;
  subdomain: string;
  theme_color: "dark" | "light";
  accent_color: string;
  text_color: string;
  text_case: string;
  logo: string | null | undefined;
  links: ICommunityLandingSettingsLink[];
  support_link?: string;
  favicon_url?: string | null;
}

export interface ICreateCommunityLandingSettings extends Omit<ICommunityLandingSettings, "logo" | "favicon_url"> {
  logo_base64?: string | null;
  favicon_base64?: string | null;
}

export interface ICommunityStreamingStatistics {
  allowedHours: number;
  usedSeconds: number;
  from: string;
  to: string;
}

export interface ICommunityIntroStep {
  id: number;
  name: ECommunityIntroStepNames;
  checked: boolean;
  title: string;
}

export interface SendHubSpotFormDataRequest {
  message: string;
  community_id: number;
}

export enum ECommunityEventsKinds {
  small_group = "small_group",
  webinars_panel_discussions = "webinars_and_panel_discussions",
  weekly_services = "weekly_services",
  large_events_and_conferences = "large_events_and_conferences",
}

export enum EStripeStatusCode {
  trialing = "trialing",
  active = "active",
  canceled = "canceled",
  past_due = "past_due",
  incomplete = "incomplete",
  paused = "paused",
}

export enum ECommunityIntroStepNames {
  create_stream = "create_stream",
  create_meeting = "create_meeting",
  connect_stream_feed = "connect_stream_feed",
  customize_community = "customize_community",
  link_website = "link_website",
  invite_team = "invite_team",
}

export interface IMAUData {
  allowed: number;
  active: number;
}

export enum EAnalyticsRange {
  custom = "custom",
  last_week = "last_week",
  last_month = "last_month",
  last_year = "last_year",
}

export enum EAnalyticsReport {
  attendees = "attendees",
  newMembers = "newMembers",
  topList = "topList",
}

export interface IEngagementAnalyticsData {
  date: string;
  members: number;
  anonymous: number;
  total: number;
}

export interface IMemberAnalyticsData {
  date: string;
  members: number;
}
export interface ITopEventsAnalyticsData {
  attenders: number | string;
  name: string;
  type: string;
  starting_at: Date | string;
  ending_at: Date | string;
}

export interface IAnalytics {
  range: EAnalyticsRange;
  engagementData: IEngagementAnalyticsData[];
  newMembersData: IMemberAnalyticsData[];
  returningMembersData: IMemberAnalyticsData[];
  topEventsData: ITopEventsAnalyticsData[];
}

export enum ETemplatesType {
  polls = "polls",
  announcements = "announcements",
}
