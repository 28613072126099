import { ICommunityItem } from "../../Community";

import { IMember } from "containers/Member";

export enum CommunityPermissions {
  view = "view_communities",
  create = "create_a_new_community",
  edit = "edit_community",
  remove = "remove_community",
  view_all = "view_all_community_in_the_system",
  manage_zoom_license = "manage_zoom_license",
}

export enum MemberPermissions {
  view = "view_member_in_the_community",
  create = "create_member_account",
  edit = "edit_member_account",
  edit_own = "edit_own_profile",
  delete = "delete_member_account",
  invite = "invite_member",
  view_invitations = "view_invitations",
}

export enum EventPermissions {
  view = "view_events_in_the_community",
  create = "create_event",
  edit = "edit_event",
  duplicate = "duplicate_event",
  delete = "delete_event",
}

export enum DiscussionPermissions {
  create = "create_discussion",
  edit_own = "edit_own_discussion",
  edit = "edit_discussion",
  view = "view_discussions",
  delete_own = "delete_own_discussion",
  delete = "delete_discussion",
}

export enum AccountPermissions {
  create = "create_person_account",
  edit = "edit_person_account",
  delete = "delete_person_account",
}

export type Permissions =
  | CommunityPermissions
  | MemberPermissions
  | EventPermissions
  | DiscussionPermissions
  | AccountPermissions;

export enum ERoles {
  admin = "super_admin",
  manager = "community_manager",
  member = "community_member",
}

export interface IPermission {
  name: Permissions;
  description: string | null;
}

export interface IRole {
  name: ERoles;
  description: string | null;
  permissions: IPermission[];
}
export interface IUserCommunity extends ICommunityItem {
  roles: IRole[];
}

export interface IUserRole {
  role_id: number;
  user_id: number;
  community_id: number | null;
  role: IRole;
}

export interface IUser {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  birthday: string | null;
  phone: string | null;
  address: string | null;
  image_url: string | null;
  user_roles: IUserRole[];
  created_at?: number;
  members: IMember[];
  token?: string;
  is_active?: boolean;
}

export type IRegistrationData = Partial<Pick<IUser, "first_name" | "last_name" | "email" | "phone" | "image_url">>;
