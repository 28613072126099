import { TextField } from "@material-ui/core";
import { EmojiData, Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import insertTextAtCursor from "insert-text-at-cursor";
import React, {
  FormEvent,
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  FileLike,
  FilePreviewer,
  FileUpload,
  FileUploadButton,
  ImageDropzone,
  ImagePreviewer,
  ImageUpload,
} from "react-file-utils";
import { useChannelStateContext, useMessageInputContext } from "stream-chat-react";
import ReactTooltip from "react-tooltip";

import { DiscussionTheme } from "../../containers/DiscussionsContainer/DiscussionsContainer";
import { checkRoles } from "../../../../utils/ACL";
import { EditMessageObj } from "../../interfaces";
import { notificationActions } from "../../../Notifications/store/actions";
import { getDraftMsg, removeDraftMsg, updateDraftMsg } from "../../utils";
import { MESSAGES_LIMIT } from "../../constants";

import { handleFirebaseEvent } from "utils/firebase";
import { FirebaseChatPayload, FIREBASE_EVENTS } from "shared/interfaces/Firebase";
import { ClickOutsideHook } from "shared/hooks";
import { getCommunity } from "containers/Community/store/selectors";
import { ERoles } from "containers/Auth";
import { getSentMessagesDates } from "containers/Discussions/store/selectors";
import { setSentMessagesDates } from "containers/Discussions/store/actions";
import attach_files from "assets/icons/attach_files.svg";
import send_message_light from "assets/icons/send_message_light.svg";
import cross_icon from "assets/icons/cross_icon.svg";
import emoji from "assets/icons/emoji.svg";
import emoji_orange from "assets/icons/emoji-orange.svg";
import attach_files_orange from "assets/icons/attach_files_orange.svg";

import "./customMessageInput.scss";

interface CustomMessageInputProp {
  handleStopEditing?: () => void;
  onInputClick?: () => void;
  editMessageDetails: EditMessageObj;
  theme?: DiscussionTheme;
  disabled?: boolean;
  uniqueTooltipId: string;
  handleDelete: () => void;
  cursorPositionRef: MutableRefObject<number>;
  hasChatRestriction: boolean;
  openRestrictionAlert: () => void;
}

let inputErrorTimeoutId: number;
let draftTextTimeOut: number;

const CustomMessageInput: React.FC<CustomMessageInputProp> = props => {
  const {
    handleDelete,
    handleStopEditing,
    editMessageDetails,
    theme,
    disabled,
    onInputClick,
    uniqueTooltipId,
    cursorPositionRef,
    hasChatRestriction,
    openRestrictionAlert,
  } = props;

  const dispatch = useDispatch();

  const community = useSelector(getCommunity());
  const sentMessagesDates = useSelector(getSentMessagesDates());

  const emojiButtonRef = useRef(null);

  const { isOutside } = ClickOutsideHook(emojiButtonRef);

  const {
    handleSubmit,
    handleChange,
    openEmojiPicker,
    uploadNewFiles,
    textareaRef,
    imageUploads,
    fileUploads,
    removeFile,
    removeImage,
    uploadImage,
    text,
    setText,
  } = useMessageInputContext("MessageInputFlat");

  const { channel } = useChannelStateContext();

  const [inputError, setInputError] = useState<{ errorText: string; delay?: number } | null>(null);

  const imageUploadsToPreview = useMemo(
    () => (imageUploads ? Object.values(imageUploads).filter(i => i && i.id) : []),
    [imageUploads],
  );

  const fileUploadsToPreview = useMemo(
    () => (fileUploads ? Object.values(fileUploads).filter(i => i && i.id) : []),
    [fileUploads],
  );

  const isStreamTheme = useMemo(() => theme === "stream", [theme]);
  const handleChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.persist();
    handleChange?.(event);
    cursorPositionRef.current = event.target.selectionStart;
    if (event.target.value) {
      clearTimeout(draftTextTimeOut);
      draftTextTimeOut = setTimeout(() => {
        updateDraftMsg(channel.cid, event.target.value);
      }, 1000);
    } else {
      removeDraftMsg(channel.cid);
    }
  };

  const isSendBtnDisabled = useMemo(() => {
    return (
      (!imageUploadsToPreview.length &&
        !fileUploadsToPreview.length &&
        (!text || !text.length) &&
        !editMessageDetails?.message) ||
      disabled
    );
  }, [imageUploadsToPreview, fileUploadsToPreview, text, disabled, editMessageDetails]);

  const canSendMessage = useCallback(() => {
    const currentTime = Date.now();
    if (sentMessagesDates.length < MESSAGES_LIMIT.messagesCount) {
      const newDates = sentMessagesDates.reduce(
        (msgDates: number[], msgDate) =>
          currentTime - msgDate < MESSAGES_LIMIT.timeInterval ? [...msgDates, msgDate] : msgDates,
        [],
      );
      dispatch(setSentMessagesDates([...newDates, currentTime]));
      return true;
    }
    const lastMsgTimeIsValid =
      currentTime - sentMessagesDates[sentMessagesDates.length - 1] > MESSAGES_LIMIT.timeInterval;
    if (lastMsgTimeIsValid) {
      dispatch(setSentMessagesDates([currentTime]));
      return true;
    }
    return false;
  }, [dispatch, sentMessagesDates]);

  const handleSubmitInput = async (event: React.FormEvent) => {
    handleSubmit?.(event);
    cursorPositionRef.current = 0;
  };

  const focusInput = () => {
    textareaRef?.current?.focus();
    textareaRef?.current?.setSelectionRange?.(text.length, text.length);
  };

  const handleMessageSubmit = (e: FormEvent) => {
    if (!text && editMessageDetails?.message) {
      return handleDelete();
    }
    if (imageUploadsToPreview.length + fileUploadsToPreview.length > 10) {
      return dispatch(notificationActions.error("Files", "You can attach only 10 files"));
    }
    if (isSendBtnDisabled) {
      return setInputError({ errorText: "Please specify the message", delay: 5000 });
    }
    if (!canSendMessage()) {
      return setInputError({ errorText: "Please wait 10 seconds before sending another message", delay: 8000 });
    }
    if (hasChatRestriction) {
      return openRestrictionAlert();
    }
    editMessageDetails?.clearEditingState?.();
    handleStopEditing?.();

    const firebase_data: FirebaseChatPayload = {
      community_id: community?.id,
      community_name: community?.name,
      is_admin: Number(checkRoles([ERoles.admin, ERoles.manager], community?.id)),
    };
    handleFirebaseEvent(FIREBASE_EVENTS.CHAT_EVENT, firebase_data);
    setInputError(null);
    clearTimeout(inputErrorTimeoutId);
    handleSubmitInput(e);
    focusInput();
    removeDraftMsg(channel.cid);
  };

  useEffect(() => {
    if (inputError?.errorText && inputError?.delay) {
      clearTimeout(inputErrorTimeoutId);
      inputErrorTimeoutId = setTimeout(() => {
        setInputError(null);
      }, inputError.delay);
    }
  }, [inputError]);

  useEffect(
    () => () => {
      clearTimeout(inputErrorTimeoutId);
    },
    [],
  );

  useEffect(() => {
    if (document.activeElement?.nodeName !== "INPUT") {
      focusInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!text) {
      const draftMsg = getDraftMsg()[channel.cid];
      if (draftMsg) {
        setText(draftMsg);
      }
    }
  }, [channel.cid, setText, text]);
  const handleTextFieldKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (!e.shiftKey && (e.keyCode === 13 || e.keyCode === 10)) {
      e.preventDefault();
      handleMessageSubmit(e);
    }
  };

  const filterEmoji = (emoji: EmojiData) => {
    return !(emoji.name === "White Smiling Face" || emoji.name === "White Frowning Face");
  };

  const handleEmojiSelect = (emoji: EmojiData) => {
    if ("native" in emoji && textareaRef?.current) {
      insertTextAtCursor(textareaRef.current, emoji.native);
    }
  };

  const handleRetry = (id: string) => {
    // @ts-ignore
    const fileToReload: FileUpload | undefined = fileUploads && fileUploads[id];
    removeFile && removeFile(id);
    uploadNewFiles && fileToReload && uploadNewFiles([fileToReload.file]);
  };

  const handleUploadNewFiles = (files: File[]) => {
    const filesToUpload: File[] = [];
    const tooBigFileNames: string[] = [];
    for (const file of files) {
      if (file.size <= 20 * 1024 * 1024) {
        filesToUpload.push(file);
        continue;
      }
      tooBigFileNames.push(file.name);
    }
    if (tooBigFileNames.length) {
      dispatch(
        notificationActions.error(
          `Exceeded maximum file size`,
          `Uploaded file${tooBigFileNames.length > 1 ? "s" : ""} \n
         ${tooBigFileNames.length > 1 ? tooBigFileNames.join(",\n") : tooBigFileNames.join()}\n
         ${tooBigFileNames.length > 1 ? "are" : "is"} to big`,
        ),
      );
    }
    uploadNewFiles && filesToUpload.length && uploadNewFiles(filesToUpload);
  };

  useEffect(() => {
    if (isOutside) {
      // @ts-ignore
      ReactTooltip.hide(emojiButtonRef.current);
    }
  }, [isOutside]);

  return (
    <ImageDropzone disabled={disabled} handleFiles={handleUploadNewFiles as (files: ReadonlyArray<FileLike>) => void}>
      <div className={classnames("customMessageInputWrapper", { withError: !!inputError })} onClick={onInputClick}>
        {!!imageUploadsToPreview.length && (
          <ImagePreviewer
            handleRetry={uploadImage}
            handleRemove={removeImage}
            handleFiles={uploadNewFiles}
            imageUploads={imageUploadsToPreview as unknown as ImageUpload[]}
          />
        )}
        <div
          className={classnames("customMessageInputWrapper__preview-file", {
            "customMessageInputWrapper__preview-file--stream": isStreamTheme,
          })}
        >
          {!!fileUploadsToPreview.length && (
            <FilePreviewer
              uploads={fileUploadsToPreview as unknown as FileUpload[]}
              handleRemove={removeFile}
              handleRetry={handleRetry}
              handleFiles={uploadNewFiles}
            />
          )}
        </div>
        {editMessageDetails && editMessageDetails.message && (
          <div className="customMessageInputWrapper-edit">
            <div className="customMessageInputWrapper-edit-label">Edit message</div>
            <div className="customMessageInputWrapper-icon">
              <img onClick={handleStopEditing} src={cross_icon} alt="close editing" />
            </div>
          </div>
        )}
        <div className="customMessageInput">
          <ReactTooltip
            id={uniqueTooltipId}
            effect="solid"
            event="click"
            clickable={true}
            insecure={false}
            backgroundColor="transparent"
            borderColor="transparent"
            border={false}
          >
            <div className="customMessageInput-emojiPicker">
              <Picker
                native
                emojisToShowFilter={filterEmoji}
                useButton
                showPreview={false}
                title="Pick your emoji…"
                emoji="point_up"
                onSelect={handleEmojiSelect}
              />
            </div>
          </ReactTooltip>
          <div className="customMessageInput-actions">
            {isStreamTheme && (
              <div className="customMessageInput-actions__stream">
                <img
                  data-tip=""
                  data-for={!disabled ? uniqueTooltipId : void 0}
                  ref={emojiButtonRef}
                  src={emoji_orange}
                  alt="plus icon"
                />
                <FileUploadButton
                  handleFiles={handleUploadNewFiles as (files: Blob[]) => void}
                  multiple={true}
                  disabled={disabled}
                >
                  <img src={attach_files_orange} alt="attach_files" />
                </FileUploadButton>
              </div>
            )}
          </div>
          <TextField
            inputRef={textareaRef}
            value={text}
            onChange={handleChangeInput}
            className="customMessageInput-input"
            fullWidth
            multiline
            rowsMax="5"
            InputLabelProps={{ shrink: true }}
            id="customMessageInput"
            onKeyDown={handleTextFieldKeyDown}
            placeholder="Type something Here"
            disabled={disabled}
          />
          <div className="customMessageInput-actions">
            {!isStreamTheme && (
              <FileUploadButton
                handleFiles={handleUploadNewFiles as (files: Blob[]) => void}
                multiple={true}
                disabled={disabled}
              >
                <img src={attach_files} alt="attach_files" />
              </FileUploadButton>
            )}
            {!isStreamTheme && (
              <img
                data-tip=""
                data-for={!disabled ? uniqueTooltipId : void 0}
                ref={emojiButtonRef}
                onClick={openEmojiPicker}
                src={emoji}
                alt="emoji"
              />
            )}
            <button className="sendBtn" type="submit" onClick={handleMessageSubmit} disabled={isSendBtnDisabled}>
              <img src={send_message_light} alt="send_message" />
            </button>
          </div>
        </div>
        {inputError && <div className="helper-text">{inputError.errorText}</div>}
      </div>
    </ImageDropzone>
  );
};

export default CustomMessageInput;
