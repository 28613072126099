import React, { useCallback, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import JoinOptions from "../JoinOptions";
import { generateDataFromSocialProvider } from "../../utils";

import { actions, ILoginForm } from "containers/Auth";
import { LastFlowEnum, ECreationKind } from "shared/interfaces";

import "../styles/auth.scss";

type Props = {
  onSubmit: () => void;
};
const Join: React.FC<Props> = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { values, errors, touched, handleBlur, validateForm, setFieldTouched, setFieldValue } =
    useFormikContext<ILoginForm>();

  useEffect(() => {
    dispatch(actions.clearForgotEmail());
  }, [dispatch]);

  useEffect(() => {
    window.localStorage.setItem("lastFlow", LastFlowEnum.joinus);
  }, []);

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (["NumpadEnter", "Enter"].includes(e.code)) {
        validateForm().then(() => {
          setFieldTouched("email", true);
          onSubmit();
        });
      }
    },
    [onSubmit, setFieldTouched, validateForm],
  );

  useEffect(() => {
    document.addEventListener("keypress", onKeyPress);

    return () => document.removeEventListener("keypress", onKeyPress);
  }, [onKeyPress]);

  const onSuccess = (data: any) => {
    const payload = generateDataFromSocialProvider(data);
    dispatch(
      actions.socialLogin.request(
        payload ? { ...payload, kind: ECreationKind.community, callback: () => history.push("/") } : null,
      ),
    );
  };

  return (
    <Grid container className="auth-root join">
      <Grid container>
        <div className="auth-description">
          <div className="auth-description-title">Join Us</div>
          <div className="auth-description-subtitle">
            Welcome to Altar Live! Continue with your Google or Facebook account or type in your email address.
          </div>
        </div>
        <div className="auth-description-mobile">
          <div className="auth-description-title">Join Us</div>
          <div className="auth-description-subtitle">
            Enter your info and create your first event. No credit card required.
          </div>
        </div>
      </Grid>
      <JoinOptions
        onSocialSuccess={onSuccess}
        onSubmit={onSubmit}
        submitText="Proceed"
        email={values.email}
        emailError={touched.email ? errors.email : null}
        handleEmailChange={e => setFieldValue("email", e.target.value.trim())}
        handleEmailBlur={handleBlur}
      />
    </Grid>
  );
};

export default Join;
