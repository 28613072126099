import * as Yup from "yup";
import { Browser, OperatingSystem } from "detect-browser";

import { ERoles } from "containers/Auth/interfaces/user";

export { default as API } from "./api";
export { default as SCHEMAS } from "./schemas";
export { default as METHODS } from "./methods";

export const FORM = {
  SPRAY_SUGGETION: {
    SCHEME: {
      date: new Date(),
      location_id: null,
      suggestion_sprays: [],
    },
    VALIDATION: Yup.object({
      date: Yup.date().required("Please enter the date"),
      location_id: Yup.number().required().typeError("Please select vineyard location"),
      suggestion_sprays: Yup.array()
        .of(
          Yup.object<any>().shape({
            uid: Yup.string(),
            chemicals: Yup.array()
              .required("Please select the chemical")
              .min(1, "Minimum of 1 chemical")
              .typeError("Please select the chemical"),
            diseases: Yup.array()
              .required("Please select the disease")
              .min(1, "Minimum of 1 disease")
              .typeError("Please select the disease"),
          }),
        )
        .required("Please add the option")
        .min(1, "Minimum of 1 option")
        .typeError("Please add the option"),
    }),
  },
  LOCATION: {
    SCHEME: {
      name: "",
      lat: 35,
      long: -78,
      acreage: "",
      usage: "",
      ranges: {
        cost: 33,
        effectiveness: 33,
        impact: 34,
      },
      managers: [],
      image_url: "",
    },
    VALIDATION: Yup.object({
      name: Yup.string().required("Please enter the company name (50 chars max)"),
      lat: Yup.number().required("Please enter the lattitude"),
      long: Yup.number().required("Please enter the longitude"),
      usage: Yup.string().required("Please select the usage"),
      acreage: Yup.number().required("Please enter the acreage").typeError("This field should be only number"),
      managers: Yup.array()
        .required("Please select the manager")
        .min(1, "Minimum of 1 manager")
        .typeError("Please select the manager"),
    }),
  },
};

export const USAGES = [
  { value: "wine", text: "Winemaking" },
  { value: "grape", text: "Grape Growing" },
  { value: "wine,grape", text: "Grape Growing & Winemaking" },
];

export const DISEASE_RISK_TYPES = {
  HIEGHT: { id: 0, color: "#E14D71" },
  LOW: { id: 1, color: "#F6B15E" },
  DIFF: {
    id: 2,
    color: "linear-gradient(269.78deg, #E14D70 12.46%, #FFDA57 49.43%, #4B9F22 87.3%)",
  },
};

export const USER_ROLES = {
  ADMIN: { description: "Super Admin", name: ERoles.admin },
  MANAGER: { description: "Community Manager", name: ERoles.manager },
  MEMBER: { description: "Community Member", name: ERoles.member },
};

export const ESCAPE_KEYS = ["27", "Escape"];

export const TIME_PICKER_FORMAT = "h:mm A";

export const MOBILE_OS_LIST: OperatingSystem[] = ["Android OS", "iOS"];

export const PERMISSIONS_FLOW_BROWSERS: Browser[] = ["chrome", "edge-chromium", "firefox", "safari"];

export const mobileWidth = 767;
