import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { AuthReducer } from "../containers/Auth/store/reducer";
import { AppReducer } from "../containers/App/store/reducer";
import { logout } from "../containers/Auth/store/actions";
import { NotificationReducer } from "../containers/Notifications/store/reducer";
import { CommunityReducer } from "../containers/Community/store/reducer";
import { MemberReducer } from "../containers/Member/store/reducer";
import { OnboardingReducer } from "../containers/Onboarding/store/reducer";
import { SermonsReducer } from "../containers/Sermons/store/reducer";
import history from "../shared/history/history";
import { DiscussionsReducer } from "../containers/Discussions/store";
import { breakoutRoomReducer } from "../containers/BreakoutRooms/store";

import { meetingReducer } from "containers/Meeting/store";
import fileReducer from "shared/stores/file/reducer";
import awsReducer from "shared/stores/aws/reducer";
import landingReducer from "containers/LandingPage/store/reducer";
import { stripeReducer } from "shared/stores/stripe";
import BrowserReducer from "shared/stores/browser/reducer";

const appReducer = combineReducers({
  router: connectRouter(history),
  onboarding: OnboardingReducer,
  auth: AuthReducer,
  app: AppReducer,
  sermons: SermonsReducer,
  community: CommunityReducer,
  notifications: NotificationReducer,
  member: MemberReducer,
  browser: BrowserReducer,
  discussions: DiscussionsReducer,
  breakoutRooms: breakoutRoomReducer,
  stripe: stripeReducer,
  landing: landingReducer,
  aws: awsReducer,
  file: fileReducer,
  meetings: meetingReducer,
});

// @ts-ignore
export default (state, action) => {
  if (action.type === logout.success().type) {
    state = undefined;
  }
  return appReducer(state, action);
};
