import { EEventStreamType, EEventVideoType, EventType } from "../containers/Sermons/interfaces";

export default {
  DATE_FORMAT: "MM.DD.YYYY",
  S_DATE_FORMAT: "MM/DD/YYYY",
  "User not found": "The username or password you entered is incorrect",
  event: {
    type: {
      [EventType.liveService]: "Live event",
      [EventType.meeting]: "Meeting",
      [EventType.stream]: "Event",
    },
    edit: {
      streamDescription: {
        [EEventStreamType.FB]:
          "To connect a stream feed from Facebook, copy and paste the Stream URL. You can connect Live or Premiered Videos.<br /><i>Note: Live or Premiered videos from Facebook can only be connected once the feed is live.</i>",
        [EEventStreamType.YOUTUBE]: {
          [EEventVideoType.live]:
            "Copy and paste the Stream URL of a live YouTube video.<br /><br /><b>Important reminders:</b><ul><li>Make sure the video is set to Public visibility in YouTube</li><li>Make sure the video has been set to Allow Embedding in YouTube</li></ul>",
          [EEventVideoType.pre_recorded]:
            "Copy and paste the Stream URL of a pre-recorded YouTube feed. The feed will be synced for everyone no matter when they join. The sync with calculated from the time that the Altar event is started.",
        },
        [EEventStreamType.VIMEO]:
          "To connect a stream feed from Vimeo, copy and paste the Stream URL. You can connect Live or Premiered Videos.",
        // [EEventStreamType.TWITCH]: "To connect a stream feed from Twitch, copy and paste the Stream URL.",
        [EEventStreamType.RESI]: "To connect a stream feed from Resi, copy and paste the Stream URL.",
        [EEventStreamType.CASTR]: "To connect a stream feed from Castr, copy and paste the Stream URL.",
        [EEventStreamType.CUSTOM]:
          "You can connect Embed code from other custom stream platform. Copy and paste the Embed code below.",
        [EEventStreamType.ALTAR]: "Upload here the video you would like to stream.",
        [EEventStreamType.ALTAR_LIVE]:
          "Copy and paste these credentials into your streaming software to begin streaming.",
        [EEventStreamType.VIMEO_LIVE]:
          "Copy and paste these credentials into your streaming software to begin streaming.",
        [EEventStreamType.m3u8]:
          "Copy and paste the m3u8 link. You can connect either a Live  or Pre-recorded video link.",
        [EEventStreamType.dacast]:
          "To connect a stream feed from DaCast, copy and paste the m3u8 link. You can connect either a Live or Pre-recorded video link.",
        [EEventStreamType.churchstreaming]:
          "To connect a stream feed from Churchstreaming.TV, copy and paste the m3u8 link. You can connect either a Live or Pre-recorded video link.",
        [EEventStreamType.STREAMING_CHURCH_TV]:
          "To connect a stream feed from StreamingChurch.TV, copy and paste the m3u8 link. You can connect with a Live or Pre-recorded video link.",
      },
      streamLearnMore: {
        [EEventStreamType.FB]: "Learn how to connect your feed from Facebook",
        [EEventStreamType.YOUTUBE]: "Learn how to connect your feed from YouTube",
        [EEventStreamType.VIMEO]: "Learn how to connect your feed from Vimeo",
        // [EEventStreamType.TWITCH]: "Learn how to connect your feed from Twitch",
        [EEventStreamType.RESI]: "Learn how to connect your feed from Resi",
        [EEventStreamType.CASTR]: "Learn how to connect your feed from Castr",
        [EEventStreamType.CUSTOM]: "Learn how to connect from a custom stream provider",
        [EEventStreamType.ALTAR]: "Learn how you can stream pre-recorded videos with Altar",
        [EEventStreamType.ALTAR_LIVE]: "Learn how to connect your streaming studio to Altar",
        [EEventStreamType.VIMEO_LIVE]: "Learn how to connect your feed from Vimeo to livestream to Altar",
        [EEventStreamType.m3u8]: "Learn how to connect from a custom stream provider",
        [EEventStreamType.dacast]: "Learn how to connect your feed from DaCast",
        [EEventStreamType.churchstreaming]: "Learn how to connect your feed from Churchstreaming.TV",
        [EEventStreamType.STREAMING_CHURCH_TV]: "Learn how to connect your feed from StreamingChurch.TV",
      },
    },
  },
  errors: {
    event: {
      noAccess: "This {{eventType}} is for invited members only",
      noAccessTitle: "Permission denied",
      notFound: "It seems this online {{eventType}} has been cancelled",
      notFoundTitle: "Event not found",
      streamingLimitReached: "Please switch to another Streaming Provider.",
      streamingLimitReachedTitle: "Streaming limit reached",
    },
  },
};
