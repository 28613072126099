import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

import manifestTheme from "../styles/variables.module.scss";

export const bigRoundInputTheme = createMuiTheme({
  palette: {
    primary: {
      main: manifestTheme.greyColor,
    },
    error: {
      main: manifestTheme.redColor,
    },
    background: {
      default: manifestTheme.whiteColor,
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: "352px",
      },
    },
    MuiFormLabel: {
      asterisk: {
        display: "none",
      },
    },
  },
});
