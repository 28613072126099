import { Channel } from "stream-chat";

import { CHAT_DRAFTS_KEY } from "../constants";

import { IMember } from "containers/Member/interfaces";

export const canShowChannel = (channel: Channel, member?: IMember, activeChannel?: string, cid?: string) => {
  const hasMessages = channel.state?.messages?.length > 0;
  const isCreator = String(channel.data?.created_by_id) === String(member?.id);
  const isAnonymous = !member && cid;
  return hasMessages || isCreator || isAnonymous || channel.id === activeChannel;
};

export const getDraftMsg = () => JSON.parse(localStorage.getItem(CHAT_DRAFTS_KEY) || "{}");

export const updateDraftMsg = (key: string, value: string) => {
  const drafts = getDraftMsg();
  drafts[key] = value;
  localStorage.setItem(CHAT_DRAFTS_KEY, JSON.stringify(drafts));
};

export const removeDraftMsg = (key: string) => {
  const drafts = getDraftMsg();
  if (drafts[key]) {
    delete drafts[key];
    localStorage.setItem(CHAT_DRAFTS_KEY, JSON.stringify(drafts));
  }
};
