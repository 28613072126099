import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";

import { deletePoll, updatePoll } from "../../../../store/actions";

import { AlertDialog, Button } from "shared/components";
import { IEvent, IPoll, PollStatus } from "containers/Sermons/interfaces";
import { ClickOutsideHook } from "shared";
import poll_delete_icon from "assets/icons/poll_delete_icon.svg";

import "./pollControl.scss";

interface IPollControl {
  poll: IPoll;
  event: IEvent;
  openEditPoll: () => void;
}

const PollControl: React.FC<IPollControl> = props => {
  const { poll, event, openEditPoll } = props;
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { isOutside } = ClickOutsideHook(ref);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [closePollAlert, setClosePollAlert] = useState(false);

  useEffect(() => {
    if (isOutside) {
      setIsOptionsOpen(false);
    }
  }, [isOutside]);

  const handlePollCardControl = (poll: IPoll, newStatus?: PollStatus) => {
    if (event) {
      const status =
        newStatus ||
        ([PollStatus.draft, PollStatus.scheduled].includes(poll.status)
          ? PollStatus.published
          : poll.status === PollStatus.published
          ? PollStatus.closed
          : poll.status);
      dispatch(
        updatePoll.request({
          poll: {
            id: poll.id,
            title: poll.title,
            poll_question: poll.poll_question,
            multiple_answers: poll.multiple_answers,
            options: [...poll.options],
            meeting_id: event.id,
            status,
            scheduled_at: status === PollStatus.scheduled ? poll.scheduled_at : null,
            show_results: poll.status === PollStatus.closed ? true : poll.show_results,
            position: poll.position,
          },
        }),
      );
    }
    setClosePollAlert(false);
  };

  const handleDeletePoll = () => {
    poll.id && dispatch(deletePoll.request({ poll_id: poll.id }));
    setOpenDeleteAlert(false);
  };

  return (
    <div className="pollControl">
      <AlertDialog
        open={closePollAlert}
        title="Close the poll"
        message="Are you sure that you want to close the poll? Other users will not be able to vote."
        onConfirm={() => handlePollCardControl(poll)}
        onCancel={() => setClosePollAlert(false)}
        mode="cancel"
        confirmText="Close"
        variant="brown"
        confirmClassName="confirmClosePollBtn"
        dialogClassName="pollControl-closePoll"
      />
      <AlertDialog
        open={openDeleteAlert}
        title="Delete the poll"
        message="Are you sure that you want to delete this poll?"
        onConfirm={() => handleDeletePoll()}
        onCancel={() => setOpenDeleteAlert(false)}
        mode="cancel"
        confirmText="Delete"
        variant="brown"
        confirmClassName="confirmDeletePollBtn"
        dialogClassName="pollControl-deletePoll"
      />
      <div className="divider" />
      <div className="pollControl-state">
        <div className={`pollControl-state-statusShow ${poll.status}`}>{poll.status}</div>
        <div className="pollControl-state-btn">
          {poll.status !== PollStatus.closed || !poll.show_results ? (
            <Button
              width={122}
              className="stateBtn"
              variant="white-text"
              onClick={() =>
                poll.status === PollStatus.published ? setClosePollAlert(true) : handlePollCardControl(poll)
              }
            >
              {[PollStatus.draft, PollStatus.scheduled].includes(poll.status)
                ? "Publish"
                : poll.status === PollStatus.published
                ? "Close Poll"
                : "Publish Results"}
            </Button>
          ) : null}
          {[PollStatus.draft, PollStatus.scheduled].includes(poll.status) ? (
            <div
              className={classnames("pollControlList-btn", { opened: isOptionsOpen })}
              ref={ref}
              onClick={() => setIsOptionsOpen(prev => !prev)}
            >
              {isOptionsOpen && (
                <div className="pollControlList-options">
                  <div onClick={() => openEditPoll()} className="pollControlList-options-item">
                    Edit
                  </div>
                  {poll.status === PollStatus.scheduled ? (
                    <div
                      onClick={() => handlePollCardControl(poll, PollStatus.draft)}
                      className="pollControlList-options-item"
                    >
                      Unschedule
                    </div>
                  ) : null}
                  <div onClick={() => setOpenDeleteAlert(true)} className="pollControlList-options-item delete">
                    Delete
                  </div>
                </div>
              )}
              <hr />
              <hr />
              <hr />
            </div>
          ) : (
            <button onClick={() => setOpenDeleteAlert(true)} className="pollControl-state-deleteBtn">
              <img src={poll_delete_icon} alt="delete poll" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PollControl;
