import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";

import animationData from "./AltarLoader.json";
import Spinner from "./Spinner";

import { getSubdomain } from "shared";

import "./loader.scss";

export const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function Loader() {
  const [show, setShow] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  useEffect(() => {
    const { subdomain, isApp } = getSubdomain();
    if (subdomain && !isApp) {
      setIsLanding(true);
    }
    setShow(true);
  }, []);

  return (
    <div className="load-container">
      {show && (isLanding ? <Spinner /> : <Lottie options={loaderOptions} height={200} width={200} />)}
    </div>
  );
}

export default Loader;
