export enum LandingActionTypes {
  LOAD_LANDING_SETTINGS = "@@LANDING/LOAD_LANDING_SETTINGS",
  LOAD_LANDING_SETTINGS_SUCCESS = "@@LANDING/LOAD_LANDING_SETTINGS_SUCCESS",
  LOAD_LANDING_SETTINGS_FAILURE = "@@LANDING/LOAD_LANDING_SETTINGS_FAILURE",

  LOAD_EVENTS = "@@LANDING/LOAD_EVENTS",
  LOAD_EVENTS_SUCCESS = "@@LANDING/LOAD_EVENTS_SUCCESS",
  LOAD_EVENTS_FAILURE = "@@LANDING/LOAD_EVENTS_FAILURE",

  UPDATE_PARAMS = `@@LANDING/UPDATE_PARAMS`,

  SET_PAST_EVENTS = `@@LANDING/SET_PAST_EVENTS`,

  SET_EVENTS_COUNT = `@@LANDING/SET_EVENTS_COUNT`,
  SET_PAST_EVENTS_COUNT = `@@LANDING/SET_PAST_EVENTS_COUNT`,

  CLEAR_LANDING_SETTINGS = `@@LANDING/CLEAR_LANDING_SETTINGS`,
  WS_SET_EVENT_STATUS = `@@LANDING/WS_SET_EVENT_STATUS`,
  WS_SET_EVENT_ARCHIVED = `@@LANDING/WS_SET_EVENT_ARCHIVED`,
  WS_EVENT_CREATED = `@@LANDING/WS_EVENT_CREATED`,

  OPEN_SOCKET = "@@LANDING/OPEN_SOCKET",
  CLOSE_SOCKET = "@@LANDING/CLOSE_SOCKET",

  SOCKET_INITIALIZED = "@@LANDING/SOCKET_INITIALIZED",
  SET_SOCKET_CONNECTED = "@@LANDING/SET_SOCKET_CONNECTED",
  SOCKET_INITIALIZED_FAILURE = "@@LANDING/SOCKET_INITIALIZED_FAILURE",
  CONNECT_SOCKET = "@@LANDING/CONNECT_SOCKET",
}
