import DateFnsUtils from "@date-io/date-fns";
import { Popover } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FieldAttributes } from "formik";
import React, { SyntheticEvent, useCallback, useState, useMemo } from "react";
import moment from "moment/moment";
import classnames from "classnames";

import { Button, StyledInput, StyledArrow, TIME_PICKER_FORMAT } from "shared";
import blue_clock from "assets/icons/blue_clock.svg";

const arrowColor = "#7B9EB9";
const arrowSize = 15;
const maxValues = { hours: 12, minutes: 59 };
const minValues = { hours: 0, minutes: 1 };

const TimerPicker: React.FC<FieldAttributes<any>> = props => {
  const { value, label, classes, popoverClass, onSubmit, start_time } = props;

  const convertedValue = useMemo(() => {
    const valueDiff = moment(value).diff(start_time, "minutes");
    return value ? { hours: Math.floor(valueDiff / 60), minutes: valueDiff % 60 } : { hours: 0, minutes: 0 };
  }, [start_time, value]);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [hours, setHours] = useState(convertedValue.hours);
  const [minutes, setMinutes] = useState(convertedValue.minutes);

  const handleUp = useCallback((currentValue, key: "hours" | "minutes", done: (v: number) => void) => {
    const result = currentValue + 1 > maxValues[key] ? minValues[key] : currentValue + 1;
    done(result);
  }, []);

  const handleDown = useCallback((currentValue, key: "hours" | "minutes", done: (v: number) => void) => {
    const result = currentValue - 1 < minValues[key] ? maxValues[key] : currentValue - 1;
    done(result);
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(hours * 60 + minutes);
    setAnchorEl(null);
  }, [onSubmit, hours, minutes]);

  const handleCancel = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleInputChangeHours = useCallback((value: string) => {
    const result = Number(value);
    if (result > maxValues.hours) {
      setHours(maxValues.hours);
    } else if (result < 0) {
      setHours(1);
    } else {
      setHours(result);
    }
  }, []);

  const handleInputChangeMinutes = useCallback((value: string) => {
    const result = Number(value);
    if (result > maxValues.minutes) {
      setMinutes(maxValues.minutes);
    } else if (result < 0) {
      setMinutes(0);
    } else {
      setMinutes(result);
    }
  }, []);

  const hoursIsValid = useMemo(() => {
    return Number(hours) >= minValues.hours && Number(hours) <= maxValues.hours;
  }, [hours]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={`timePicker-wrapper ${classes || ""}`}>
        {label && <span className="pickerLabel">{label}</span>}
        <div
          className={classnames("timePicker", label && "timePicker-label")}
          onClick={(event: SyntheticEvent) => setAnchorEl(event.currentTarget)}
        >
          <span>{(hours > 9 ? hours : `0${hours}`) + ":" + (minutes > 9 ? minutes : `0${minutes}`)}</span>
          <span>({moment(start_time).add(minutes, "minutes").add(hours, "hours").format(TIME_PICKER_FORMAT)})</span>
          <img src={blue_clock} alt="clock icon" />
        </div>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 34, horizontal: "left" }}
        transformOrigin={{ vertical: 250, horizontal: 0 }}
        onClose={handleCancel}
        className={`styled-time-picker-popover ${popoverClass || ""}`}
      >
        <>
          <div className="styled-timepicker-wrapper">
            <div className="styled-timepicker-column">
              <div onClick={() => handleUp(+hours, "hours", setHours)}>
                <StyledArrow
                  className="back-button"
                  width={arrowSize}
                  height={arrowSize}
                  stroke={arrowColor}
                  style={{ transform: "rotate(-90deg)" }}
                />
              </div>
              <div className="styled-timepicker-field">
                <StyledInput
                  className="field"
                  name="hours"
                  value={hours}
                  maxLength={2}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    handleInputChangeHours(event.currentTarget.value)
                  }
                  type="number"
                />
              </div>
              <div onClick={() => handleDown(+hours, "hours", setHours)}>
                <StyledArrow
                  className="back-button"
                  width={arrowSize}
                  height={arrowSize}
                  stroke={arrowColor}
                  style={{ transform: "rotate(-270deg)" }}
                />
              </div>
            </div>
            <div className="hours-divider">:</div>
            <div className="styled-timepicker-column">
              <div onClick={() => handleUp(+minutes, "minutes", setMinutes)}>
                <StyledArrow
                  className="back-button"
                  width={arrowSize}
                  height={arrowSize}
                  stroke={arrowColor}
                  style={{ transform: "rotate(-90deg)" }}
                />
              </div>
              <div className="styled-timepicker-field">
                <StyledInput
                  className="field"
                  name="minutes"
                  type="number"
                  maxLength={2}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    handleInputChangeMinutes(event.currentTarget.value)
                  }
                  value={minutes}
                />
              </div>
              <div onClick={() => handleDown(+minutes, "minutes", setMinutes)}>
                <StyledArrow
                  className="back-button"
                  width={arrowSize}
                  height={arrowSize}
                  stroke={arrowColor}
                  style={{ transform: "rotate(-270deg)" }}
                />
              </div>
            </div>
          </div>
          <div className="timepicker-divider" />
          <div className="timepicker-buttons">
            <Button radius={9} width={318} variant="gray-text" type="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              radius={9}
              width={318}
              disabled={!hoursIsValid}
              variant={hoursIsValid ? "blue-text" : "gray-text"}
              type="button"
              onClick={handleSubmit}
            >
              Ok
            </Button>
          </div>
        </>
      </Popover>
    </MuiPickersUtilsProvider>
  );
};

export default TimerPicker;
