import "./index.scss";

import React from "react";
import classnames from "classnames";

interface ShortLogoProp {
  className?: string;
}

const ShortLogo = (props: ShortLogoProp) => {
  const { className } = props;
  return <div className={classnames("short-logo", className)}>&nbsp;</div>;
};

export default ShortLogo;
