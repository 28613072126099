import * as Yup from "yup";

export const urlValidator = new RegExp('^(ftp|http|https):\\/\\/[^ "]+$');
export const defaultUrlValidate = Yup.string().matches(urlValidator, "Please enter a valid URL");

export const defaultYupStringValidate = Yup.string().max(200, "Must be 200 characters or less");

export const numberValidator = new RegExp("^[\\d()+-]+$");
export const eventCountMembersValidate = new RegExp("^[1-9]\\d*$");

export const phoneValidator = Yup.string()
  .matches(numberValidator, "Invalid phone number format")
  .max(20, "Must be 20 characters or less")
  .transform(value => (!value ? null : value))
  .nullable(true);

export const passwordValidator = Yup.string().matches(
  /^((?=.*\d)|(?=.*[!@#$%^&*()\-_+=]))((?=.*[a-z])|(?=.*[A-Z]))(?=.*[A-Z])(?!.* ).{8,}$/,
  "Password must contain at least 8 characters, at least 1 digit and at least one Capital letter",
);

export const colorValidator = Yup.string().matches(
  new RegExp("^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"),
  "Wrong colour format",
);

export const urlProtocolRegExp = new RegExp("^(ftp|http|https):\\/\\/");
export const urlRegExp = new RegExp(
  "[((www\\\\.)?a-zA-Z0-9@:%._\\\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\\\+.~#?&//=]*)",
);

export const communityWebsiteValidator = Yup.string()
  .matches(urlProtocolRegExp, "Web URL must include http(s)://")
  .matches(urlRegExp, "Wrong website URL format")
  .nullable();
