import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { ChannelPreviewUIComponentProps } from "stream-chat-react";
import { FormatMessageResponse } from "stream-chat";
import { useDispatch, useSelector } from "react-redux";

import { CustomAvatar } from "../CustomAvatar";
import { IMember } from "../../../Member";
import { IChannel } from "../../interfaces";
import { EventMemberTypes } from "../../../Sermons/constants";

import {
  customizeGetstreamChannel,
  getChannelName,
  getChannelUnreadMessagesCount,
  timeToAMPM,
} from "shared/utils/getStream";
import { CanView } from "shared/components/CanView";
import { useGreetingChannel } from "shared";
import { getEvent, getEventMembers, getSermonMembers } from "containers/Sermons/store/selectors";
import { usePersonalChatName } from "containers/Discussions/hooks/usePersonalChatName";
import { getMember } from "containers/Auth/store/selectors";
import { clearSelectedChannel, setActiveChannel as setActiveChannelState } from "containers/Discussions/store/actions";

import "./channelItem.scss";

interface ChannelItemProps {
  channels: IChannel[];
  member?: IMember;
  onSelectChannel?: () => void;
  showUnreadForActive?: boolean;
  showUnreadCount?: boolean;
}

enum MessageTypes {
  regular = "regular",
  deleted = "deleted",
}

export const ChannelItem: React.FC<ChannelItemProps & ChannelPreviewUIComponentProps> = props => {
  const {
    channels,
    displayImage,
    channel,
    setActiveChannel,
    active,
    onSelectChannel,
    latestMessage,
    showUnreadForActive,
  } = props;
  const [currentChannel, setCurrentChannel] = useState<IChannel>();
  const [lastMessage, setLastMessage] = useState<FormatMessageResponse>();

  const liveEventMembers = useSelector(getSermonMembers());
  const streamMembers = useSelector(getEventMembers());
  const event = useSelector(getEvent());
  const currentMember = useSelector(getMember());

  const { getChannelImage, isOnlineInPersonal, interlocutor } = customizeGetstreamChannel(
    channel,
    currentChannel,
    displayImage,
  );

  const personalChatName = usePersonalChatName(currentChannel);
  const dispatch = useDispatch();
  const messages = channel?.state?.messages;

  useEffect(() => {
    setCurrentChannel(channels.find(ch => ch.id === channel.id));
  }, [channels, channel]);
  useEffect(() => {
    setLastMessage(channel.lastMessage());
  }, [channel, latestMessage]);

  const unreadMessages = useMemo(() => {
    return (!active || (active && showUnreadForActive)) && messages ? getChannelUnreadMessagesCount(channel) : 0;
  }, [channel, active, showUnreadForActive, messages]);

  const lastMessageText = useCallback(
    () => (lastMessage?.type === MessageTypes.deleted ? "This message was deleted." : lastMessage?.text),
    [lastMessage],
  );

  const { channelGreeter } = useGreetingChannel(currentChannel);
  if (!currentChannel) {
    return null;
  }

  const eventMemberIds = [...liveEventMembers, ...streamMembers]
    .map(member => member.memberId?.toString())
    .filter(Boolean);
  const greeters = event?.greeters.map(({ id }) => id.toString());
  const isMemberOnline = interlocutor?.user && eventMemberIds.includes(interlocutor.user?.id);
  const isInterlocutorGreeter = interlocutor?.user && greeters?.includes(interlocutor.user?.id);

  if (channelGreeter && !isMemberOnline && !isInterlocutorGreeter) {
    return null;
  }

  const streamMember = streamMembers.find(({ memberId }) => String(memberId) === interlocutor?.user?.id);
  const [first_name = " ", last_name = " "] = interlocutor?.user?.name?.split(/\s+/) || [];

  const selectChannelHandler = () => {
    dispatch(clearSelectedChannel());
    if (setActiveChannel) {
      setActiveChannel(channel);
    }
    !!event && channel.id && dispatch(setActiveChannelState(channel.id));
    onSelectChannel && onSelectChannel();
  };
  return (
    <div
      className={classNames("channelItem", {
        active: active,
        "channelItem-greeter": channelGreeter,
      })}
      onClick={selectChannelHandler}
    >
      <div className="channelItem-container">
        <CustomAvatar
          image={getChannelImage()}
          size={40}
          name={getChannelName(currentChannel, currentMember) || streamMember?.guestName}
          color={channelGreeter ? "#F0B48F" : streamMember?.color || currentChannel?.color}
          isOnline={isOnlineInPersonal()}
          member={
            streamMember && streamMember.type === EventMemberTypes.guest
              ? undefined
              : !currentChannel?.is_group
              ? ((personalChatName || { first_name, last_name }) as IMember)
              : void 0
          }
        />
        <div className="channelItem-content">
          <div className="channelItem-content_top">
            <div className={classNames("channelItem-name", { greeter: channelGreeter })}>
              <div className="channelItem-name-value">
                {streamMember && streamMember.type === EventMemberTypes.guest
                  ? streamMember.guestName
                  : getChannelName(currentChannel, currentMember) || interlocutor?.user?.name || ""}
              </div>
              {channelGreeter?.title ? <div className="greeter-title">{channelGreeter.title}</div> : ""}
            </div>
            <div className="channelItem-additional">
              <CanView condition={!!unreadMessages}>
                <div className="channelItem-new_message">{unreadMessages}</div>
              </CanView>
              <div className="channelItem-date">{timeToAMPM(lastMessage?.created_at, { swapDay: true })}</div>
            </div>
          </div>
          <div className="channelItem-content_bottom">
            <p className="channelItem-content_bottom_text">{lastMessageText()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
