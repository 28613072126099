import * as Yup from "yup";

import { communityWebsiteValidator } from "shared/constants/validations";

export const communityValidationSchema = Yup.object({
  main_contact: Yup.object().nullable().required("Field is required"),
  address_1: Yup.string().max(250, "Address line 1 field is max 250 characters").nullable(),
  address_2: Yup.string().max(250, "Address line 2 field is max 250 characters").nullable(),
  city: Yup.string().max(250, "City field is max 250 characters").nullable(),
  state: Yup.string().max(250, "State field is max 250 characters").nullable(),
  country: Yup.string().max(250, "Country field is max 250 characters").nullable(),
  zipcode: Yup.string().max(250, "Zip / Postal Code field is max 250 characters").nullable(),
  website: communityWebsiteValidator,
  ga_id: Yup.string()
    .matches(/^(UA|G)-(\w|-)+$/, "Wrong Google Analytics id")
    .nullable(),
});
