import { call, put, select, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import api from "../api/api";
import { MemberActionTypes } from "./constants";
import { notificationActions } from "../../Notifications/store/actions";
import { startLoading, stopLoading } from "../../App/store/actions";
import config from "../../../config";
import { NamesOfParentRoutes } from "../../../constants";
import {
  MemberInvitationLinkAction,
  IFetchMember,
  IMember,
  IMembersInCommunityRequest,
  IExistMemberRequest,
  IPrivateInvitation,
  BaseMember,
} from "../interfaces";
import { EMemberRestriction, IRestrictMember } from "../../Sermons";

import { actions } from "./index";

import { generateQueryStringFromArray } from "shared/utils/searchParams";
import { selectors as authSelectors, actions as authActions, IUser } from "containers/Auth";
import { ECookieNames, getCookieByName } from "shared";

function* fetchMembersInCommunity({ payload }: { payload: IMembersInCommunityRequest }) {
  const { communityCode, callback, params } = payload;
  try {
    yield put(startLoading());
    const data: IMember[] = yield call(api.fetchMembersInCommunity, communityCode, params);
    yield put(actions.fetchMembersInCommunity.success(data));
    callback && callback();
  } catch (err: any) {
    yield put(actions.fetchMembersInCommunity.failure(err));
    yield put(notificationActions.error("Cannot fetch people", (err && err.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* fetchMembersInCommunityToModal({ payload }: { payload: IMembersInCommunityRequest }) {
  const { communityCode, callback, params } = payload;
  try {
    const data: IMember[] = yield call(api.fetchMembersInCommunity, communityCode, params);
    yield put(actions.fetchMembersInCommunityToModal.success(data));
    callback && callback();
  } catch (err: any) {
    yield put(actions.fetchMembersInCommunityToModal.failure(err));
    yield put(notificationActions.error("Cannot fetch people", (err && err.message) || ""));
  }
}

function* fetchExistMemberSaga({ payload }: { payload: IExistMemberRequest }) {
  const { email, callback } = payload;
  let member: IMember | undefined;
  try {
    member = yield call(api.fetchExistMember, { email });
    if (member) {
      yield put(actions.fetchExistMember.success(member));
    }
  } catch (err: any) {
    yield put(actions.fetchExistMember.failure(err));
  } finally {
    callback && callback(member);
  }
}

function* fetchMemberByCodeSaga({ payload }: { payload: IFetchMember }) {
  try {
    yield put(startLoading());
    const { communityCode, memberCode, callback } = payload;
    const member: IMember = yield call(api.fetchMemberByCode, communityCode, memberCode);
    callback && callback(member);
  } catch (error: any) {
    yield put(actions.fetchMemberByCode.failure(error));
    yield put(notificationActions.error("Cannot fetch member", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* createNewMember({ payload }: AnyAction) {
  try {
    yield put(startLoading());

    const { communityCode, ...rest } = payload;
    const data: BaseMember = yield call(api.createMember, { ...rest });
    yield put(actions.createMember.success(data));

    yield put(actions.fetchMembersInCommunity.request({ communityCode }));
    yield put(notificationActions.success("Member was successfully created"));
  } catch (err: any) {
    yield put(actions.createMember.failure(err));
    yield put(notificationActions.error("Cannot create member", (err && err.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* updateMemberById({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const { send_invitation, communityCode, ...rest } = payload;
    const hubSpotUserToken = getCookieByName(ECookieNames.hubspotutk);
    const data: BaseMember = yield call(api.updateMember, { ...rest, hubSpotUserToken });
    yield put(actions.updateMember.success(data));
    if (send_invitation && data.id) {
      yield put(actions.sendPrivateInvitations.request({ memberIds: [data.id] }));
    }
    const user: IUser | null = yield select(authSelectors.getUser());
    if (user && user.members.some(({ id }) => id === rest.id)) {
      yield put(authActions.getUserData.request());
    }

    yield put(actions.fetchMembersInCommunity.request({ communityCode }));
    yield put(notificationActions.success("Member was successfully updated"));
  } catch (err: any) {
    yield put(actions.updateMember.failure(err));
    yield put(notificationActions.error("Cannot update member", (err && err.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* connectExistMember({ payload }: AnyAction) {
  try {
    const { ...member } = payload;
    yield put(startLoading());
    const data: BaseMember = yield call(api.connectMember, member);
    yield put(actions.connectToCommunity.success(data));
    yield put(notificationActions.success("Member was successfully connected"));
  } catch (err: any) {
    yield put(actions.connectToCommunity.failure(err));
    yield put(notificationActions.error("Cannot connect member to community", (err && err.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* checkUserIsVerifiedSaga({ payload: { userId, communityId, callback } }: AnyAction) {
  try {
    yield put(startLoading());
    const user: { status: "Found" | "Not found" } = yield call(api.checkIsUserClaimed, userId, communityId);
    if (user.status === "Found") {
      callback && callback();
    }
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* memberLeaveCommunity({ payload }: AnyAction) {
  try {
    const { id, community_id, callback } = payload;
    yield put(startLoading());
    yield call(api.leaveMember, { id, community_id });
    yield put(actions.leaveMember.success(id));
    yield put(notificationActions.success("You successfully left a community"));
    callback && callback();
  } catch (err: any) {
    yield put(actions.leaveMember.failure(err));
    yield put(notificationActions.error("You can not leave this community", (err && err.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* removeMemberById({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    yield call(api.removeMember, payload);
    yield put(actions.removeMember.success(payload.id));
    yield put(notificationActions.success("Member was successfully removed"));
  } catch (err: any) {
    yield put(actions.removeMember.failure(err));
    yield put(notificationActions.error("Cannot delete member", (err && err.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* removeMembersSaga({ payload }: ReturnType<typeof actions.removeMembers.request>) {
  const { callback, community_id, memberIds } = payload;
  try {
    yield put(startLoading());
    const queryString = generateQueryStringFromArray(memberIds, "memberIds");
    const removedMembers: number[] = yield call(api.removeMembers, { community_id, queryString });
    yield put(actions.removeMembers.success(removedMembers));
    yield put(notificationActions.success("Members was successfully removed"));
    yield call(callback);
  } catch (err: any) {
    yield put(actions.removeMembers.failure(err));
    yield put(notificationActions.error("Cannot remove members", (err && err.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* getMemberInvitationLink({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const { data, history } = payload;
    const { communityCode, memberCode } = data as MemberInvitationLinkAction;
    const link: IPrivateInvitation = yield call(api.fetchPersonalInvitation(communityCode, memberCode));
    const { INVITE } = NamesOfParentRoutes;
    if (history) {
      return history.push(`${INVITE}/${link.hash}`);
    }
    const textField = document.createElement("textarea");
    textField.innerText = `${config.applicationUrl}${INVITE}/${link.hash}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    yield put(notificationActions.success("Invitation link copied to the clipboard"));
  } catch (err: any) {
    yield put(notificationActions.error((err && err.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* fetchMeetingMembers({
  payload: { code, customErrorHandling, withLoader = true },
}: {
  payload: { code: string; customErrorHandling: boolean; withLoader?: boolean };
}) {
  try {
    if (withLoader) yield put(startLoading());
    const data: IMember[] = yield call(api.fetchMeetingMembers, code);
    yield put(actions.fetchMembersInCommunity.success(data));
  } catch (err: any) {
    if (customErrorHandling) {
      yield put(actions.fetchMembersInCommunity.failure(err));
    } else {
      yield put(notificationActions.error("Cannot fetch members", (err && err.message) || ""));
    }
  } finally {
    if (withLoader) yield put(stopLoading());
  }
}

function* sendPrivateInvitationSaga({
  payload: { memberIds, callback },
}: ReturnType<typeof actions.sendPrivateInvitations.request>) {
  try {
    yield put(startLoading());
    const data: IPrivateInvitation[] = yield call(api.sendPrivateInvitations, { memberIds });

    callback && callback(data[0]?.created_at);
    yield put(actions.sendPrivateInvitations.success(data));
    yield put(notificationActions.success("Invitation was successfully sent"));
  } catch (err: any) {
    yield put(actions.sendPrivateInvitations.failure(err));
    yield put(notificationActions.error("Cannot send invitation", (err && err.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* fetchInvitationStatusSaga({ payload: { community_id, member_id, callback } }: AnyAction) {
  try {
    yield put(startLoading());
    const { created_at }: IPrivateInvitation = yield call(api.fetchInvitationDate, community_id, member_id);
    callback && callback(created_at);
  } catch (error: any) {
    if (error.code === 404) {
      callback && callback(null);
    } else {
      yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
    }
  } finally {
    yield put(stopLoading());
  }
}

function* getDiscussionToken({ payload }: AnyAction) {
  try {
    const { token } = yield call(api.fetchDiscussionToken, payload);
    yield put(actions.fetchChatToken.success(token));
  } catch (error) {
    // skip errors
  }
}

function* getAnonymousDiscussionToken({ payload }: AnyAction) {
  try {
    const { token } = yield call(api.fetchAnonymousDiscussionToken, payload);
    yield put(actions.fetchChatToken.success(token));
  } catch (error) {
    // skip errors
  }
}

function* restrictMemberSaga({ payload }: ReturnType<typeof actions.restrictMember>) {
  try {
    yield call(api.restrictMember, payload);
    yield put(
      notificationActions.info(
        payload.restriction_type === EMemberRestriction.chat_timeout
          ? "Member Temporarily Banned from the Chat"
          : "Member Banned from the Chat",
      ),
    );
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* fetchRestrictedMembersSaga({ payload }: ReturnType<typeof actions.fetchRestrictedMembers.request>) {
  try {
    const restrictedMembers: IRestrictMember[] = yield call(api.fetchRestrictedMembers, payload.event_id);
    yield put(actions.fetchRestrictedMembers.success(restrictedMembers));
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

function* deleteMemberRestrictionSaga({ payload }: ReturnType<typeof actions.deleteMemberRestriction>) {
  try {
    const { member_id, event_id } = payload;
    yield call(api.deleteMemberRestriction, member_id, event_id);
    yield put(notificationActions.info("Member Unbanned from the Chat"));
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  }
}

export default function* memberSaga() {
  yield takeLatest(actions.fetchMembersInCommunityToModal.request, fetchMembersInCommunityToModal);
  yield takeLatest(actions.fetchMembersInCommunity.request, fetchMembersInCommunity);
  yield takeLatest(actions.fetchMemberByCode.request, fetchMemberByCodeSaga);
  yield takeLatest(actions.fetchExistMember.request, fetchExistMemberSaga);
  yield takeLatest(MemberActionTypes.CREATE_MEMBER, createNewMember);
  yield takeLatest(MemberActionTypes.UPDATE_MEMBER, updateMemberById);
  yield takeLatest(MemberActionTypes.CONNECT_MEMBER_TO_COMMUNITY, connectExistMember);
  yield takeLatest(actions.leaveMember.request, memberLeaveCommunity);
  yield takeLatest(MemberActionTypes.REMOVE_MEMBER, removeMemberById);
  yield takeLatest(MemberActionTypes.GET_MEMBER_INVITATION_LINK, getMemberInvitationLink);
  yield takeLatest(MemberActionTypes.CHECK_USER_IS_VERIFIED_REQUEST, checkUserIsVerifiedSaga);
  yield takeLatest(actions.fetchMeetingMembers.request, fetchMeetingMembers);
  yield takeLatest(actions.sendPrivateInvitations.request, sendPrivateInvitationSaga);
  yield takeLatest(MemberActionTypes.FETCH_INVITATION_DATE_REQUEST, fetchInvitationStatusSaga);
  yield takeLatest(MemberActionTypes.FETCH_CHAT_TOKEN, getDiscussionToken);
  yield takeLatest(MemberActionTypes.FETCH_ANONYMOUS_CHAT_TOKEN, getAnonymousDiscussionToken);
  yield takeLatest(actions.removeMembers.request, removeMembersSaga);
  yield takeLatest(MemberActionTypes.RESTRICT_MEMBER, restrictMemberSaga);
  yield takeLatest(actions.fetchRestrictedMembers.request, fetchRestrictedMembersSaga);
  yield takeLatest(MemberActionTypes.DELETE_MEMBER_RESTRICTION, deleteMemberRestrictionSaga);
}
