import React from "react";
import classnames from "classnames";

import Divider from "../Divider";

import { Button, SquareButton, StyledLink } from "shared/components";

import "./styles.scss";

type Props = {
  header: React.ReactNode;
  text: React.ReactNode;
  imageSrc: string;
  className?: string;
  onSkip?: () => void;
  onUpgrade: () => void;
  learnMoreLink?: string;
};

const BlockedStep: React.FC<Props> = ({ header, text, imageSrc, className, onUpgrade, onSkip, learnMoreLink }) => {
  return (
    <div className={classnames("blocked-step", className)}>
      <div className="image">
        <img src={imageSrc} alt="step blocked" />
      </div>
      <h2>{header}</h2>
      <Divider />
      <p>{text}</p>
      {learnMoreLink && (
        <StyledLink className="learn-more" href={learnMoreLink}>
          Learn More About This Feature
        </StyledLink>
      )}
      <SquareButton type="button" variant="orange" onClick={onUpgrade}>
        Upgrade Subscription
      </SquareButton>
      {onSkip && (
        <Button type="button" variant="orange-text" onClick={onSkip}>
          Maybe Later
        </Button>
      )}
    </div>
  );
};

export default BlockedStep;
