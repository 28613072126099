import React from "react";
import classnames from "classnames";

import StyledButton from "../StyledButton/StyledButton";

import { ICreateButton } from "shared";

import "./orangeButton.scss";

const OrangeButton: React.FunctionComponent<ICreateButton> = ({ children, className, ...rest }) => {
  return (
    <StyledButton
      className={classnames("orangeButton", rest.disabled ? "orangeButton-disabled" : "", className || "")}
      {...rest}
    >
      <span className="orangeButton-text">{children}</span>
    </StyledButton>
  );
};
export default OrangeButton;
