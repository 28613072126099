import React from "react";
import moment from "moment";

import NextEventCountDown from "../NextEventCard/NextEventCountDown";

import { getRemainingTime } from "containers/Sermons/utils";
import { IEvent } from "containers/Sermons/interfaces";

import "./styles.scss";

interface IMobileWrapperCountDown {
  event: IEvent;
  isNextEvent?: boolean;
}

const MobileWrapperCountDown: React.FC<IMobileWrapperCountDown> = ({ event, isNextEvent }) => {
  const { name, starting_at } = event;

  const remainingTime = getRemainingTime(event);
  return (
    <div className="mobileWrapper-countDown">
      <div className="mobileWrapper-countDown-description">
        {isNextEvent && <div className="mobileWrapper-countDown-description-title">Next Event</div>}
        <div className="mobileWrapper-countDown-description-name">{name}</div>
        <div className="mobileWrapper-countDown-description-date">
          {moment(starting_at).format("dddd, MMMM D, h:mm A")}
        </div>
        <div className="mobileWrapper-countDown-description-countDown">
          <NextEventCountDown initTime={remainingTime} />
        </div>
      </div>
    </div>
  );
};

export default MobileWrapperCountDown;
