import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { setChatNotificationSettings } from "../../../../Notifications/store/actions";
import { getChatNotificationSettings } from "../../../../Notifications/store/selectors";
import { IChatNotificationSettings } from "../../../../Notifications";

import { chatNotificationSettingsHandler, EventTooltip } from "shared";
import CustomSwitchControl from "shared/components/CustomSwitchControl/CustomSwitchControl";
import notification_settings_bell from "assets/icons/notification_settings_bell.svg";
import notification_settings_bell_red from "assets/icons/notification_settings_bell_red.svg";
import notification_settings_bell_white from "assets/icons/notification_settings_bell_white.svg";

import "./styles.scss";

const PushNotificationSettings: React.FC = () => {
  const dispatch = useDispatch();
  const [activeSettings, setActiveSettings] = useState(false);
  const [disabledBell, setDisabledBell] = useState(false);
  const [titleText, setTitleText] = useState("Notification sound and banners are ON");

  const { notifySound, notifyBanner } = useSelector(getChatNotificationSettings());

  useEffect(() => {
    if (!notifySound && notifyBanner) {
      setDisabledBell(true);
      setTitleText("Notification sound is OFF");
    } else if (notifySound && !notifyBanner) {
      setDisabledBell(true);
      setTitleText("Notification banners are OFF");
    } else if (!notifySound && !notifyBanner) {
      setDisabledBell(true);
      setTitleText("Notification sound and banners are OFF");
    } else {
      setDisabledBell(false);
      setTitleText("Notification sound and banners are ON");
    }
  }, [setDisabledBell, notifySound, notifyBanner]);

  const settingsHandler = (notifySettings: IChatNotificationSettings) => {
    dispatch(setChatNotificationSettings(notifySettings));
    chatNotificationSettingsHandler.set(notifySettings);
  };

  return (
    <>
      <EventTooltip title={titleText} placement="left">
        <div
          className={classnames("notification-setting-item", { active: activeSettings })}
          onClick={() => setActiveSettings(prevState => !prevState)}
        >
          {disabledBell ? (
            <img src={notification_settings_bell_red} alt="settings bell" />
          ) : (
            <img
              src={activeSettings ? notification_settings_bell_white : notification_settings_bell}
              alt="settings bell"
            />
          )}
        </div>
      </EventTooltip>
      {activeSettings ? (
        <div className="notification-setting-tab" onClick={() => setActiveSettings(false)}>
          <div className="notification-setting-tab-body" onClick={e => e.stopPropagation()}>
            <div className="notification-setting-tab-body-title">Notification Settings</div>
            <div className="notification-setting-tab-body-content">
              Notification sound
              <CustomSwitchControl
                checked={notifySound}
                onChange={() => settingsHandler({ notifySound: !notifySound, notifyBanner })}
              />
              Chat banners
              <CustomSwitchControl
                checked={notifyBanner}
                onChange={() => settingsHandler({ notifySound, notifyBanner: !notifyBanner })}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PushNotificationSettings;
