import React from "react";
import classnames from "classnames";

import { StyledAltarLogo } from "../StyledAltarLogo";

import "./authAside.scss";

interface IAuthAside {
  header?: boolean;
}

const AuthAside: React.FC<IAuthAside> = ({ header, children }) => {
  return (
    <div className="authAside no-mobile">
      {header && (
        <header className="authAside-header">
          <StyledAltarLogo fill="#403E3E" />
        </header>
      )}
      <div className={classnames("authAside-content", header ? "authAside-content-header" : "")}>{children}</div>
    </div>
  );
};

export default AuthAside;
