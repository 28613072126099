import React, { useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getNotificationAnnouncementPopup } from "../../store/selectors";
import { INotificationAnnouncement } from "../../interfaces";
import { removePopup } from "../../store/actions";

import { AlertDialog } from "shared";

import "./index.scss";

interface NotificationPopupProps {
  onClick?: (data: INotificationAnnouncement) => void;
}

let timeoutId: number | null = null;

const clearAnnouncementTimeout = () => {
  timeoutId && clearTimeout(timeoutId);
  timeoutId = null;
};

const NotificationPopup: React.FC<NotificationPopupProps> = props => {
  const { onClick } = props;
  const dispatch = useDispatch();
  const announcement = useSelector(getNotificationAnnouncementPopup());

  const removePopupFromState = useCallback(() => {
    dispatch(removePopup());
    clearAnnouncementTimeout();
  }, [dispatch]);

  const onConfirm = useCallback(() => {
    if (announcement?.button_link) {
      window.open(announcement.button_link);
    } else {
      removePopupFromState();
    }
  }, [announcement, removePopupFromState]);

  const onClickLocal = useCallback(() => {
    if (onClick && announcement) {
      onClick(announcement);
      removePopupFromState();
    }
  }, [onClick, announcement, removePopupFromState]);

  useEffect(() => {
    clearAnnouncementTimeout();
    if (announcement) {
      timeoutId = setTimeout(() => removePopupFromState(), 10000);
    }
  }, [announcement, removePopupFromState]);

  useEffect(() => () => removePopupFromState(), [removePopupFromState]);

  const popupContent = useMemo(() => {
    return (
      <AlertDialog
        open={!!announcement}
        title={announcement?.title}
        message={announcement?.message}
        onConfirm={onConfirm}
        onCancel={removePopupFromState}
        mode={announcement?.button_text ? "confirm" : "info"}
        confirmText={announcement?.button_text || "Got it!"}
        cancelText="Dismiss"
        variant="brown"
        confirmClassName="defaultButtons-info"
        onClickContent={onClickLocal}
        dialogClassName={onClick ? "clickable-content-dialog" : ""}
      />
    );
  }, [announcement, removePopupFromState, onConfirm, onClickLocal, onClick]);

  return popupContent;
};

export default NotificationPopup;
