import React, { ReactNode } from "react";
import classnames from "classnames";

import { LINKS } from "../../../../shared/constants/links";

import { StyledAltarLogo, StyledArch, StyledCircle, StyledPolygon, StyledSquarePolygon, ShortLogo } from "shared";

import "./styles.scss";

const AuthWrapper: React.FC<
  React.PropsWithChildren<{
    children?: ReactNode;
    withPadding?: boolean;
    withHomePage?: boolean;
    hideMobileLogo?: boolean;
  }>
> = ({ children, withPadding, withHomePage, hideMobileLogo }) => {
  return (
    <div className={classnames("auth-wrapper", { "with-padding": withPadding })}>
      <header className="auth-wrapper-header">
        <a href={(withHomePage && LINKS.website) || undefined}>
          <StyledAltarLogo className="no-mobile" fill="#403E3E" width={140} />
          {!hideMobileLogo && <ShortLogo className="only-mobile" />}
        </a>
      </header>
      <div className="auth-wrapper-figures no-mobile">
        <StyledCircle className="auth-wrapper-figures-circle" />
        <StyledPolygon className="auth-wrapper-figures-polygon" width={125} height={283} />
        <StyledArch className="auth-wrapper-figures-arch" />
        <StyledSquarePolygon className="auth-wrapper-figures-square-polygon" />
      </div>
      {children}
    </div>
  );
};

export default AuthWrapper;
