import { BrowserInfo, detect } from "detect-browser";
import { Reducer } from "redux";

import { BrowserActionTypes } from "./constants";

const reducer: Reducer = (state: BrowserInfo | null = null, action) => {
  switch (action.type) {
    case BrowserActionTypes.STORE_INFO:
      return detect();
    default:
      return state;
  }
};

export default reducer;
