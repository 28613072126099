export enum ECookieNames {
  hubspotutk = "hubspotutk",
}

export const getCookieByName = (name: ECookieNames) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts?.pop()?.split(";").shift();
  }
};
