export enum MeetingActionTypes {
  GENERATE_EIGHT_TO_EIGHT = "@@MEETING/GENERATE_EIGHT_TO_EIGHT",
  GENERATE_EIGHT_TO_EIGHT_SUCCESS = "@@MEETING/GENERATE_EIGHT_TO_EIGHT_SUCCESS",
  GENERATE_EIGHT_TO_EIGHT_FAILURE = "@@MEETING/GENERATE_EIGHT_TO_EIGHT_FAILURE",

  SET_EIGHT_TO_EIGHT_USER = "@@MEETING/SET_EIGHT_TO_EIGHT_USER",

  LOAD_CLASSROOMS = "@@MEETING/LOAD_CLASSROOMS",
  LOAD_CLASSROOMS_SUCCESS = "@@MEETING/LOAD_CLASSROOMS_SUCCESS",
  LOAD_CLASSROOMS_FAILURE = "@@MEETING/LOAD_CLASSROOMS_FAILURE",

  WS_EVENT_CLASSROOMS_REQUEST = "@@EVENT/WS_EVENT_CLASSROOMS_REQUEST",
  WS_EVENT_CLASSROOMS_SUCCESS = "@@EVENT/WS_EVENT_CLASSROOMS_SUCCESS",
  WS_EVENT_CLASSROOMS_FAILURE = "@@EVENT/WS_EVENT_CLASSROOMS_FAILURE",

  SET_CURRENT_CLASSROOM = "@@MEETING/SET_CURRENT_CLASSROOM",

  JOIN_TO_CLASSROOM = "@@MEETING/JOIN_TO_CLASSROOM",

  LEAVE_CLASSROOM = "@@MEETING/LEAVE_CLASSROOM",

  CLEAR_MEETING_STATE = "@@MEETING/CLEAR_MEETING_STATE",

  SET_FULL_CLASSROOM = "@@MEETING/SET_FULL_CLASSROOM",

  SET_RECORDING_STATUS = "@@MEETING/SET_RECORDING_STATUS",

  SET_PRE_JOIN_PAGE = "@@MEETING/SET_PRE_JOIN_PAGE",

  SET_FOLLOW_ME = "@@MEETING/SET_FOLLOW_ME",
}
