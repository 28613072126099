import React from "react";
import classNames from "classnames";

import { IMember, IMemberToMembersModal } from "../../../../../containers/Member/interfaces";

import { CustomAvatar } from "containers/Discussions/components";

import "./communityMemberCard.scss";

interface ICommunityMemberCard {
  member?: IMemberToMembersModal | null;
  email?: string;
  selected?: boolean;
  onClick?: () => void;
  className?: string;
  fullWidth?: boolean;
  isOnline?: boolean;
  managerView?: boolean;
}

const CommunityMemberCard: React.FC<ICommunityMemberCard> = props => {
  const { fullWidth, member, selected, className, onClick, isOnline, managerView = false } = props;

  const { image_url, first_name, last_name, name, email, should_create_as_manager } = member || {};

  return (
    <div
      onClick={onClick}
      className={classNames(
        `communityMember`,
        {
          "communityMember-selected": !!selected,
          "communityMember-fullWidth": !!fullWidth,
          "communityMember-online": !!isOnline,
        },
        className && {
          [className]: !!className,
        },
      )}
    >
      <div className="communityMember-img-container">
        <CustomAvatar
          image={image_url || undefined}
          name={`${first_name} ${last_name}`}
          size={40}
          member={member as IMember}
          mark={managerView && should_create_as_manager}
        />
      </div>
      <div className="communityMember-data">
        <p className="communityMember-data-name">
          {first_name && last_name ? `${first_name} ${last_name}` : `${name}`}
        </p>
        {managerView && <p className="communityMember-data-email">{email}</p>}
      </div>
    </div>
  );
};

export default CommunityMemberCard;
