import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { IMember } from "../../../containers/Member";

import { IChannel } from "containers/Discussions/interfaces";
import { getEvent } from "containers/Sermons/store/selectors";
import { getMember } from "containers/Auth/store/selectors";
import { getChannels, getEventChannelIds } from "containers/Discussions/store/selectors";

const useGreetingChannel = (currentChannel?: IChannel) => {
  const event = useSelector(getEvent());
  const currentUserMember = useSelector(getMember());
  const channels = useSelector(getChannels());
  const channelIds = useSelector(getEventChannelIds());

  const greeterChannel = useMemo(
    () => channels?.find(ch => !ch.is_group && ch.members.some(m => m.id === event?.greeters[0]?.id)),
    [event, channels],
  );

  const currentUserIsGreeter = useMemo(() => {
    return !!event?.greeters.some(greeter => greeter.id === currentUserMember?.id);
  }, [event, currentUserMember]);

  const eventHasGreeter = useMemo(() => {
    return event ? event?.greeters?.length > 0 : false;
  }, [event]);

  const findMembersChannel = useCallback(
    (members: IMember[]) => {
      return channels?.find(ch => ch.members.some(member => members.some(m => m.id === member.id)));
    },
    [channels],
  );

  const isGreeterChannelExist = useMemo(
    () => greeterChannel && channelIds?.has(greeterChannel?.id),
    [greeterChannel, channelIds],
  );

  const channelGreeter = useMemo(() => {
    return (
      (currentChannel &&
        !currentChannel.is_group &&
        event?.greeters.find(greeter =>
          currentChannel.members.some(
            channelMember => channelMember.id === greeter.id && currentUserMember?.id !== greeter.id,
          ),
        )) ||
      undefined
    );
  }, [event, currentChannel, currentUserMember]);

  return {
    greeterChannel,
    currentUserIsGreeter,
    eventHasGreeter,
    isGreeterChannelExist,
    findMembersChannel,
    channelGreeter,
  };
};

export default useGreetingChannel;
