import React, { useCallback } from "react";

import { IRecording, EZoomMeetingRecordingFileStatus, EZoomMeetingFileType } from "containers/Sermons/interfaces";
import { ContainedBlueButton, StyledLink } from "shared/components";

import "./recordingsItem.scss";

interface IRecordingsItem {
  recording: IRecording;
  name: string;
  showDownload?: boolean;
}

const RecordingsItem: React.FC<IRecordingsItem> = ({ recording, showDownload = true }) => {
  const { download_url, play_url, status, file_type } = recording;

  const watchRecordingHandler = useCallback((): void => {
    window.open(play_url, "_blank");
  }, [play_url]);

  return (
    <tr className="recordingsItem">
      <td className="recordingsItem-actions" colSpan={status === EZoomMeetingRecordingFileStatus.COMPLETED ? 1 : 2}>
        {status !== EZoomMeetingRecordingFileStatus.COMPLETED ? (
          <div className="recordingsItem-actions-processing">recording is processing</div>
        ) : [EZoomMeetingFileType.MP4, EZoomMeetingFileType.M4A].includes(file_type) ? (
          <ContainedBlueButton width={300} onClick={watchRecordingHandler}>
            watch recording
          </ContainedBlueButton>
        ) : null}
      </td>
      {status === EZoomMeetingRecordingFileStatus.COMPLETED && showDownload && (
        <td className="recordingsItem-actions">
          <StyledLink href={download_url} className="recordingsItem-actions-link" download target="_self">
            <span className="recordingsItem-actions-download">download</span>
          </StyledLink>
        </td>
      )}
    </tr>
  );
};
export default RecordingsItem;
