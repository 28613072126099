import React from "react";

import { CustomAvatar } from "containers/Discussions/components/CustomAvatar";
import { IMember, IMemberToMembersModal } from "containers/Member/interfaces";
import { AppTheme } from "containers/App/interfaces/themeType";

import "./selectedMembers.scss";

interface ISelectedMembers {
  members: (IMemberToMembersModal | null)[];
  theme?: AppTheme;
  showUnits?: boolean;
  avatarSize?: number;
}

const SelectedMembers: React.FC<ISelectedMembers> = ({ members, showUnits = true, theme = "light", avatarSize }) => {
  return (
    <>
      {!!members.length && (
        <div
          className={`selectedMembers 
              ${members.length > 1 && "selectedMembers-multiply"}
              selectedMembers-${members.length > 3 ? 3 : members.length}`}
          style={{ height: avatarSize || 48 }}
        >
          {members
            .map((member, index) => {
              if (!member) return null;
              const { image_url, first_name, last_name, name } = member;
              return (
                <div
                  key={index}
                  className={`selectedMembers-imgContainer 
                          ${members.length > 1 && "selectedMembers-imgContainer-multiply"}`}
                >
                  <CustomAvatar
                    image={image_url || undefined}
                    name={`${first_name} ${last_name}`}
                    size={avatarSize || 48}
                    member={member as IMember}
                  />
                  {members.length < 2 && showUnits && (
                    <div className="selectedMembers-data">
                      <p className={`selectedMembers-data-name ${theme === "light" ? "light-theme" : "dark-theme"}`}>
                        {first_name && last_name ? `${first_name} ${last_name}` : `${name}`}
                      </p>
                    </div>
                  )}
                </div>
              );
            })
            .slice(0, 2)}
          {members.length > 2 && (
            <div className="selectedMembers-imgCount selectedMembers-imgContainer-multiply">
              <span className={`selectedMembers-imgCount-count ${theme === "light" ? "light-theme" : "dark-theme"}`}>
                {`+${members.length - 2}`}
              </span>
            </div>
          )}
          {members.length >= 2 && showUnits && (
            <div className="selectedMembers-info-container">
              <p className={`selectedMembers-info-count ${theme === "light" ? "light-theme" : "dark-theme"}`}>
                {`${members.length} participants`}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SelectedMembers;
