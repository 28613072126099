import React from "react";

import { Overlay, SquareButton } from "shared";
import clapping_hands from "assets/icons/clapping_hands.svg";

import "./completeIntroSuccessPopup.scss";

const CompleteIntroSuccessPopup: React.FC<{ show: boolean; onClose: () => void }> = ({ show, onClose }) => {
  return (
    <Overlay onClose={onClose} show={show}>
      <div className="completeIntroSuccessPopup">
        <img src={clapping_hands} alt="clapping_hands" />
        <div className="completeIntroSuccessPopup-title">Congratulations!</div>
        <div className="completeIntroSuccessPopup-description">
          You have successfully set up your Altar Live community account! Your online events just got more engaging -
          ready to take it to your people?
        </div>
        <div className="completeIntroSuccessPopup-footer">
          <SquareButton type="button" variant="blue" onClick={onClose}>
            I'm Ready to Go!
          </SquareButton>
        </div>
      </div>
    </Overlay>
  );
};

export default CompleteIntroSuccessPopup;
