import { createAsyncAction } from "typesafe-actions";

import actionTypes from "./constants";
import { IUserUpdateRequest } from "../interfaces";

import { ResponseError } from "shared/interfaces";
import { IUser } from "containers/Auth/interfaces";

export const updateUser = createAsyncAction(
  actionTypes.UPDATE_USER_REQUEST,
  actionTypes.UPDATE_USER_SUCCESS,
  actionTypes.UPDATE_USER_FAILURE,
)<IUserUpdateRequest, IUser, ResponseError>();

export const confirmEmail = createAsyncAction(
  actionTypes.CONFIRM_EMAIL_REQUEST,
  actionTypes.CONFIRM_EMAIL_SUCCESS,
  actionTypes.CONFIRM_EMAIL_FAILURE,
)<string, undefined, undefined>();

export const deleteUser = createAsyncAction(
  actionTypes.DELETE_USER_REQUEST,
  actionTypes.DELETE_USER_SUCCESS,
  actionTypes.DELETE_USER_FAILURE,
)<{ user_id: number; isSeatSamePage: boolean }, undefined, undefined>();
