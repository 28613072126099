import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import classnames from "classnames";

import { getCommunity, getCommunitySubscriptions, getMAUData } from "../../../store/selectors";
import { EStripeStatusCode, IProduct } from "../../../interfaces";
import { getUser } from "../../../../Auth/store/selectors";
import { actions } from "../../../store";
import { MauSlider } from "../MauSlider";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../../../constants";
import { getMAUSliderMarks } from "../../../utils";
import { scrollToTop } from "../../../../../utils/functions";

import { objectToSearchParams, searchParamsToObject } from "shared/utils/searchParams";
import { EStripeResult } from "shared/interfaces/Stripe";
import { redirectToCheckout } from "shared/stores/stripe/actions";
import { CustomSwitchControl, StyledRadio } from "shared";
import SwitchControl from "shared/components/SwitchControl/SwitchControl";

import "./index.scss";

const { COMMUNITY_SETTINGS } = NamesOfParentRoutes;
const { BILLING_SETTINGS } = NameOfRoutes;

enum EAvailableProductType {
  basic = "basic",
  plus = "plus",
}

const getSuitableProduct = (neededMembersQty: number, selectedProduct: IProduct, allProducts: IProduct[]) => {
  const productsById: { [id: number]: IProduct } = {};
  const productsByNextTierProductId: { [id: number]: IProduct } = {};
  allProducts.forEach(p => {
    const id = p.product_id || p.id;
    if (p.is_active && id) {
      productsById[id] = p;
    }
    if (p.next_tier_product_id) {
      productsByNextTierProductId[p.next_tier_product_id] = p;
    }
  });
  const productQty = Object.keys(allProducts).length;
  let product: IProduct | null = selectedProduct;
  let i = 0;
  while (product && product.licensed_member_count !== neededMembersQty && i < productQty) {
    if (selectedProduct.licensed_member_count < neededMembersQty) {
      if (product?.next_tier_product_id) {
        product = productsById[product.next_tier_product_id];
      } else {
        product = null;
      }
    } else {
      // @ts-ignore
      const id = product.product_id || product.id;
      if (id) {
        product = productsByNextTierProductId[id];
      } else {
        product = null;
      }
    }
    i++;
  }
  return product;
};

const EditSubscription = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const community = useSelector(getCommunity());
  const communitySubscriptions = useSelector(getCommunitySubscriptions());
  const currentUser = useSelector(getUser());
  const mauData = useSelector(getMAUData());

  const { stripeSuccess, communityCreation, ...restParams } = useMemo(
    () => searchParamsToObject(location.search),
    [location],
  );

  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const [availableProducts, setAvailableProducts] = useState<IProduct[]>([]);
  const [isAnnual, setAnnual] = useState(false);
  const [shouldContactUs, setShouldContactUs] = useState(false);
  const [currentSelectedUsers, setCurrentSelectedUsers] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentProduct, setCurrentProduct] = useState<IProduct | null>(null);
  const [canceledSubscription, setCanceledSubscription] = useState(false);
  const [availableProductsType, setAvailableProductsType] = useState<EAvailableProductType | null>(null);

  const possibleProducts = useMemo(() => {
    if (availableProductsType) {
      const products = [...(communitySubscriptions?.possibleProducts || [])];
      if (communitySubscriptions?.currentSubscriptions?.[0]) {
        products.push(communitySubscriptions.currentSubscriptions[0]);
      }
      return products.filter(p => p.name?.split("_").includes(availableProductsType)) || [];
    }
    return [];
  }, [availableProductsType, communitySubscriptions]);

  useEffect(() => {
    if (communitySubscriptions?.currentSubscriptions[0]) {
      setCurrentProduct(communitySubscriptions?.currentSubscriptions[0]);
      setAnnual(communitySubscriptions?.currentSubscriptions[0].is_yearly);
      setAvailableProductsType(
        communitySubscriptions?.currentSubscriptions[0].name?.split("_").includes(EAvailableProductType.basic)
          ? EAvailableProductType.basic
          : EAvailableProductType.plus,
      );
    } else {
      if (communitySubscriptions?.canceledSubscription) {
        setCanceledSubscription(true);
        setCurrentProduct(communitySubscriptions.canceledSubscription);
        setAnnual(communitySubscriptions.canceledSubscription.is_yearly);
      }
    }
  }, [communitySubscriptions]);

  const currentMembersCount = mauData?.active || 0;
  const hasPaymentMethod = !!communitySubscriptions?.hasPaymentMethod;
  const activeProducts = useMemo(() => {
    return (
      possibleProducts
        .filter(
          (thing, index, self) =>
            thing.is_active && index === self.findIndex(t => t.licensed_member_count === thing.licensed_member_count),
        )
        .sort((a, b) => a.licensed_member_count - b.licensed_member_count) || []
    );
  }, [possibleProducts]);

  const maxUserCount = useMemo(() => activeProducts.slice(-1)[0]?.licensed_member_count || 0, [activeProducts]);
  const stripeRedirectPath = `/${community?.code}${COMMUNITY_SETTINGS}${BILLING_SETTINGS}`;

  const sliderMarks = useMemo(() => getMAUSliderMarks(activeProducts, maxUserCount), [activeProducts, maxUserCount]);

  useEffect(() => {
    if (currentSelectedUsers === 0) {
      const withoutProduct =
        communitySubscriptions &&
        !communitySubscriptions.currentSubscriptions.length &&
        !communitySubscriptions.canceledSubscription;
      if (withoutProduct) {
        setCurrentSelectedUsers(35);
      } else if (currentProduct) {
        setCurrentSelectedUsers(
          currentProduct.licensed_member_count < maxUserCount ? currentProduct.licensed_member_count : 35,
        );
      }
    }
  }, [communitySubscriptions, currentProduct, currentSelectedUsers, maxUserCount]);

  useEffect(() => {
    if (possibleProducts.length) {
      setErrorMessage(
        !canceledSubscription &&
          currentProduct &&
          selectedProduct &&
          Number(selectedProduct.price) < Number(currentProduct.price) &&
          selectedProduct.licensed_member_count === currentProduct.licensed_member_count
          ? "Your account will be credited the difference between your last billing and your current selected tier."
          : "",
      );

      if (currentSelectedUsers > maxUserCount) {
        setShouldContactUs(true);
        setErrorMessage(
          `You can buy the first ${maxUserCount} MAUs right now. We have different plans for large churches.`,
        );
      } else {
        setShouldContactUs(false);
      }

      const productsMausUnique = new Set<number>();
      if (communitySubscriptions) {
        possibleProducts.forEach(({ is_active, licensed_member_count, is_yearly }) => {
          if (is_active && licensed_member_count >= currentSelectedUsers && is_yearly === isAnnual) {
            productsMausUnique.add(licensed_member_count);
          }
        });
      }

      const productsMaus = Array.from(productsMausUnique);
      let closest = currentSelectedUsers;
      if (closest && productsMaus.length) {
        closest = productsMaus.reduce((a, b) => Math.min(a, b));
        setCurrentSelectedUsers(closest);
      }
      const products = possibleProducts.filter(
        p =>
          p.is_active &&
          p.is_yearly === isAnnual &&
          p.licensed_member_count === closest &&
          p.id !== currentProduct?.product_id,
      );

      if (!selectedProduct && products.length > 0 && currentSelectedUsers) {
        const product =
          currentProduct &&
          products.find(p => (p.product_id || p.id) === (currentProduct.product_id || currentProduct.id));

        setSelectedProduct(product || products[0]);
      }
      if (
        selectedProduct &&
        (selectedProduct.licensed_member_count !== closest ||
          !products.find(p => (p.product_id || p.id) === (selectedProduct.product_id || selectedProduct.id)))
      ) {
        const allProducts = [...possibleProducts];

        const product = getSuitableProduct(closest, selectedProduct, allProducts);
        if (product && products.some(p => (p.product_id || p.id) === (product.product_id || product.id))) {
          setSelectedProduct(product);
        } else if (products[0]) {
          setSelectedProduct(products[0]);
        }
      }
      products.sort((a, b) => Number(a.price) - Number(b.price));
      setAvailableProducts(products);
    }
  }, [
    currentProduct,
    currentMembersCount,
    currentSelectedUsers,
    communitySubscriptions,
    isAnnual,
    selectedProduct,
    canceledSubscription,
    maxUserCount,
    possibleProducts,
  ]);

  const createCheckoutSession = useCallback(() => {
    if (community && currentUser && selectedProduct) {
      dispatch(
        redirectToCheckout.request({
          customerEmail: community.main_contact.email,
          stripePriceCode: selectedProduct.stripe_price_code,
          successPath: `${stripeRedirectPath}?current_is_free=0&next_tier_product_id=${
            selectedProduct.product_id || selectedProduct.id
          }&stripeSuccess=${EStripeResult.success}`,
          cancelPath: `${stripeRedirectPath}?stripeSuccess=${EStripeResult.fail}`,
          userId: String(currentUser.id),
          communityId: String(community.id),
        }),
      );
    }
  }, [dispatch, currentUser, community, selectedProduct, stripeRedirectPath]);

  const upgradeSubscription = useCallback(() => {
    if (community && currentUser && selectedProduct) {
      dispatch(
        actions.communityUpgradeSubscription.request({
          data: {
            communityCode: community.code,
            items: [{ price: selectedProduct.stripe_price_code, quantity: 1 }],
            prorationBehavior: "create_prorations",
            metadata: { clientReferenceId: `${community.id}/${currentUser?.id}/${Date.now()}` },
          },
          cb: () =>
            history.replace({
              pathname: stripeRedirectPath,
              search: objectToSearchParams({
                ...restParams,
                stripeSuccess: EStripeResult.success,
                next_tier_product_id: String(selectedProduct?.product_id || selectedProduct?.id || ""),
              }),
            }),
        }),
      );
    }
  }, [community, currentUser, selectedProduct, dispatch, history, stripeRedirectPath, restParams]);

  const handleSubscriptionAction = () => {
    if (shouldContactUs) {
      const win = window.open("https://www.altarlive.com/contact-us", "_blank");
      if (win) {
        win.focus();
      }
      return;
    }
    if (!hasPaymentMethod) {
      return createCheckoutSession();
    }
    upgradeSubscription();
  };

  const blockUpgrading =
    currentProduct &&
    (!selectedProduct ||
      ((selectedProduct.product_id || selectedProduct.id) === (currentProduct.product_id || currentProduct.id) &&
        currentProduct.stripe_status_code === EStripeStatusCode.active) ||
      selectedProduct.licensed_member_count < currentProduct.licensed_member_count ||
      currentSelectedUsers < currentMembersCount);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="edit-subscription-wrapper">
      <div className="edit-subscription-title">Let’s start growing your online engagement!</div>
      <div className="annual-settings-wrapper">
        <div className="annual-title">Choose your plan</div>
        <div className="switcher-wrapper">
          <span className="switcher-customLabel">Monthly</span>
          <CustomSwitchControl checked={isAnnual} theme="orange" label="" onChange={() => setAnnual(s => !s)} />
          <span className="switcher-customLabel">Annually</span>
        </div>
      </div>
      <div className="subscription-description">
        Give your entire team access to your account and grow your online engagement with full-featured meetings &
        events. Only pay for what you use.
      </div>
      <SwitchControl
        textOn="Basic"
        textOff="Plus"
        checked={availableProductsType === EAvailableProductType.basic}
        onChange={() =>
          setAvailableProductsType(prev =>
            prev === EAvailableProductType.basic ? EAvailableProductType.plus : EAvailableProductType.basic,
          )
        }
      />
      {selectedProduct && availableProducts.length > 0 && (
        <div className="plans-wrapper">
          <RadioGroup
            name="theme_color"
            value={selectedProduct?.id}
            onChange={(event, value) => {
              const product = availableProducts.find(p => (p.product_id || p.id) === Number(value));
              if (product) {
                setSelectedProduct(product);
              }
              return value;
            }}
            className="radio-options"
          >
            {availableProducts.map(p => {
              const selected = (p.product_id || p.id) === (selectedProduct?.product_id || selectedProduct?.id);
              const current = (p.product_id || p.id) === (currentProduct?.product_id || currentProduct?.id);
              return (
                <FormControlLabel
                  value={"gey"}
                  key={p.id}
                  checked={selected}
                  control={
                    <div className="plan-wrapper">
                      <div className="plan-title-wrapper">
                        <StyledRadio checked={selected} value={p.product_id || p.id} />
                        <div className="plan-title">
                          {p.title}
                          {(current || p.is_recommended) && (
                            <div className={classnames("label", current ? "current" : "recommended")}>
                              {current ? "current plan" : "recommended"}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="plan-description-wrapper">
                        <div className="plan-description">
                          <div className="description">{p.description}</div>
                          <a className="pricing-page-link" href="https://www.altarlive.com/pricing" target="blank">
                            Compare Plans
                          </a>
                        </div>
                        <div className="plan-pricing-wrapper">
                          <div className="plan-price">${p.price}</div>
                          <div className="plan-price-details">
                            {isAnnual ? <div className="discount-badge">SAVE 2 months</div> : null}
                            <div className={`month ${isAnnual ? "discount" : ""} `}>
                              /{!isAnnual ? "month" : "yearly"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  label=""
                />
              );
            })}
          </RadioGroup>
        </div>
      )}

      <div className="mau-wrapper">
        {selectedProduct ? (
          <MauSlider
            title="Choose Number of Monthly Active Users"
            sliderMarks={sliderMarks}
            currentMAUCount={selectedProduct.licensed_member_count}
            onSliderCountChanged={setCurrentSelectedUsers}
          />
        ) : null}
      </div>

      <div className="pricing-summary">
        <div className="total-price-wrapper">
          {currentSelectedUsers > maxUserCount ? (
            <div className="pricing">
              <div className="total-price-title overLimit">Contact Us</div>
            </div>
          ) : (
            <>
              <div className="pricing">
                <div className="total-price-title">Total:</div>
                <div className="total-price-value">${selectedProduct?.price}</div>
              </div>

              <div className="payment-details">
                {/*<div className="payment-item">*/}
                {/*    <span>Pro-ration credit:</span>*/}
                {/*    <span> 3 Days</span>*/}
                {/*</div>*/}
                <div className="payment-item">
                  <span>Due today:</span>
                  <span>
                    {!canceledSubscription && currentProduct && currentProduct.id === selectedProduct?.id
                      ? "$0.00"
                      : `$${selectedProduct?.price}`}{" "}
                  </span>
                </div>
                {currentProduct?.stripe_status_code === EStripeStatusCode.trialing ? (
                  <div className="payment-item">
                    <span>Next Invoice:</span>
                    <span>
                      {(canceledSubscription || !currentProduct
                        ? new Date()
                        : new Date(currentProduct?.next_payment_date || "")
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </span>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
      {community && (
        <div className="submit-button-wrapper">
          <button
            className={classnames("get-started-button", {
              "contact-us": shouldContactUs,
              disabled: blockUpgrading,
            })}
            onClick={handleSubscriptionAction}
          >
            {shouldContactUs ? "Contact Us" : "Get Started"}
          </button>
          {errorMessage ? <div className="error-message">{errorMessage}</div> : null}
        </div>
      )}
    </div>
  );
};

export default EditSubscription;
