import { createMuiTheme } from "@material-ui/core";

export const mainTheme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h3: {
        fontWeight: 500,
      },
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {},
      },
    },
  },
  palette: {
    primary: {
      main: "#9F9F9F",
    },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#303030",
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: "2.7rem",
    },
    h2: {
      fontWeight: 500,
    },
  },
});
