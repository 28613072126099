import React from "react";

const Spinner = () => (
  <div className="loading-spinner-spinner">
    <div className="loader">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Spinner;
