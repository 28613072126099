import { createStore, applyMiddleware, compose, Middleware, StoreEnhancer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import freeze from "redux-freeze";
import createSagaMiddleware from "redux-saga";

import appSagas from "./saga";
import rootReducer from "./reducer";

const sagaMiddleware = createSagaMiddleware();
let middleware: Array<Middleware>;
let composer: (m: any) => StoreEnhancer;

if (process.env.REACT_APP_ENV === "dev") {
  middleware = [freeze, sagaMiddleware];
  composer = composeWithDevTools({ trace: true, traceLimit: 25 });
} else {
  middleware = [sagaMiddleware];
  composer = compose;
}

export default function configureStore() {
  const store = createStore(rootReducer, undefined, composer(applyMiddleware(...middleware)));

  sagaMiddleware.run(appSagas);

  if (module.hot) {
    module.hot.accept(() => store.replaceReducer(rootReducer));
  }

  return { store };
}
