import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormikContext } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";

import {
  EEventStreamType,
  EEventVideoType,
  EPreEventType,
  EventStatus,
  EventType,
  IEvent,
  IEventForm,
} from "../../../interfaces";
import VerticalWizardSteps, { WizardStep } from "../VerticalWizardSteps";
import GeneralStep from "../EventWizardSteps/GeneralStep";
import TeamStep from "../EventWizardSteps/TeamStep";
import PermissionsStep from "../EventWizardSteps/PermissionsStep";
import StreamStep from "../EventWizardSteps/StreamStep";
import PreEventStep from "../EventWizardSteps/PreEventStep";
import {
  eventGeneralStepValidationSchema,
  eventPermissionsStepValidationSchema,
  eventPreEventStepValidationSchema,
  eventStreamStepValidationSchema,
  eventTeamStepValidationSchema,
  navigationStepValidationSchema,
  sermonValidationSchema,
} from "../../../validators";
import { getStreamUrlValidationBuilder } from "../../../utils";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../../../constants";
import BlockedStep from "../EventWizardSteps/BlockedStep";
import { RoomsStep } from "../EventWizardSteps/RoomsStep";
import { InteractionsStep } from "../EventWizardSteps/InteractionsStep";
import { NavigationStep } from "../EventWizardSteps/NavigationStep";
import { setDashBoardDisabled } from "../../../../App/store/actions";

import { isMatchedPath } from "containers/Community/utils";
import { getCommunityStreamingStatistics } from "containers/Community/store/selectors";
import { notificationActions } from "containers/Notifications/store/actions";
import useSubscriptions from "shared/hooks/SubscriptionsHook";
import { LINKS } from "shared/constants/links";
import { EFeature } from "containers/Community/interfaces";
import { actions, selectors } from "containers/Community/store";
import { SquareButton } from "shared";
import event_team_step from "assets/images/event_team_step.png";
import relay_block from "assets/images/relay_block.png";
import event_pre_step from "assets/images/event_pre_step.png";
import event_post_step from "assets/images/event_post_step.png";

import "./styles.scss";

const { COMMUNITIES, CREATION, COMMUNITY_SETTINGS } = NamesOfParentRoutes;
const { BILLING_SETTINGS } = NameOfRoutes;

type Props = {
  event: IEvent | null;
  onCloseSermon: (values: IEventForm) => void;
  handlePublish: (values: IEventForm) => void;
  handleSaveDraft: (values: IEventForm, redirectOnCreate?: boolean, saveInitial?: boolean) => void;
  showRemoveAlert: () => void;
  setValidationSchema: (schema: Yup.ObjectSchema | Yup.ArraySchema<any>) => void;
  isMeetingEvent?: boolean;
  isDefaultEvent: boolean;
};

const streamSteps = [
  "General Details",
  "Team",
  "Who can Join",
  "Stream",
  "Pre Event",
  "Interactions",
  "Set up your space",
  "Navigation",
];
const meetingSteps = ["General Details", "Hosts", "Who can Join", "Interactions", "Set up your space"];

const StreamEvent: React.FC<Props> = ({
  event,
  onCloseSermon,
  handlePublish,
  handleSaveDraft,
  showRemoveAlert,
  setValidationSchema,
  isMeetingEvent,
  isDefaultEvent,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<WizardStep[]>(
    isMeetingEvent
      ? meetingSteps.map(title => ({ title, completed: !!event }))
      : streamSteps.map(title => ({ title, completed: !!event })),
  );
  const [saveAction, setSaveAction] = useState<"saveInitial" | "saveDraft" | "publish" | null>(null);
  const [blockedHeader, setBlockedHeader] = useState("");
  const [blockedText, setBlockedText] = useState("");
  const [blockedImg, setBlockedImg] = useState("");
  const [blockedLearnMoreLink, setBlockedLearnMoreLink] = useState("");
  const wrapperRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  const { hasPaidSubscription } = useSubscriptions();

  const eventIsPublished = useMemo(
    () => !!event && ![EventStatus.initial, EventStatus.draft].includes(event.status),
    [event],
  );

  const { values, validateForm, setTouched, setValues, setFieldValue } = useFormikContext<IEventForm>();

  const communityCreation = isMatchedPath(history.location.pathname, `${COMMUNITIES}${CREATION}`);
  const currentCommunity = useSelector(selectors.getCommunity());
  const communitySubscriptions = useSelector(selectors.getCommunitySubscriptions());
  const streamingStatistics = useSelector(getCommunityStreamingStatistics());

  useEffect(() => {
    if (
      currentCommunity &&
      !currentCommunity?.vimeo_token &&
      event?.id &&
      event?.stream_type === EEventStreamType.VIMEO_LIVE
    ) {
      dispatch(
        actions.getCommunityWithVimeoToken.request({
          code: currentCommunity?.code,
          callback: setFieldValue,
          type: "update_event",
        }),
      );
    }
  }, [currentCommunity, dispatch, event, setFieldValue]);

  const features = useMemo(() => {
    if (communitySubscriptions?.currentSubscriptions) {
      const [product] = communitySubscriptions.currentSubscriptions;
      if (product) {
        return product.features.map(({ name }) => name);
      }
    }
    return [];
  }, [communitySubscriptions]);

  const setStepCompleted = useCallback(
    (step: number) => {
      if (steps[step]) {
        const updatedSteps = [...steps];
        updatedSteps[step].completed = true;
        setSteps(updatedSteps);
      }
    },
    [steps],
  );
  const validate = useCallback(async () => {
    const errors: any = await validateForm();

    let valid = true;
    const touched: Record<string, any> = {};
    Object.keys(errors).forEach(field => {
      if (!field) return;
      if (!Array.isArray(errors[field])) {
        valid = false;
        touched[field] = true;
      } else {
        valid = false;
        errors[field].forEach((item: any, index: number) => {
          if (!item) return;
          Object.keys(item).forEach(f => {
            if (!touched[field]) touched[field] = [];
            if (!touched[field][index]) touched[field][index] = {};
            touched[field][index][f] = true;
          });
        });
      }
    });

    setTouched(touched);

    return valid;
  }, [setTouched, validateForm]);
  const onStepChange = useCallback(
    async (step: number) => {
      let canProceed = steps[step] && steps.every(({ completed }, i) => completed || step <= i || activeStep === i);
      if (canProceed && step > activeStep) {
        if (
          activeStep === 3 &&
          values.stream_type === EEventStreamType.ALTAR_LIVE &&
          (!streamingStatistics ||
            (streamingStatistics.usedSeconds >= streamingStatistics.allowedHours * 60 * 60 &&
              moment(values.starting_at).isBetween(streamingStatistics.from, streamingStatistics.to)))
        ) {
          dispatch(
            notificationActions.error(
              t("errors.event.streamingLimitReachedTitle"),
              t("errors.event.streamingLimitReached"),
            ),
          );
          canProceed = false;
        }
        if (canProceed) {
          canProceed = await validate();
        }
      }

      if (canProceed) {
        setStepCompleted(activeStep);
        setActiveStep(step);
      }
    },
    [
      activeStep,
      dispatch,
      setStepCompleted,
      steps,
      streamingStatistics,
      t,
      validate,
      values.starting_at,
      values.stream_type,
    ],
  );
  const onNextStep = useCallback(() => onStepChange(activeStep + 1), [activeStep, onStepChange]);

  const onPrevStep = useCallback(() => onStepChange(activeStep - 1), [activeStep, onStepChange]);

  const onClose = useCallback(() => onCloseSermon(values), [onCloseSermon, values]);

  const onRemove = useCallback(() => showRemoveAlert(), [showRemoveAlert]);

  const onPublish = useCallback(async () => {
    setValidationSchema(sermonValidationSchema);
    setSaveAction("publish");
  }, [setValidationSchema]);

  const onSave = useCallback(
    async (saveInitial = false) => {
      if (eventIsPublished) {
        return onPublish();
      }

      const optionalStreamUrl = sermonValidationSchema.clone().shape({
        stream_type: Yup.mixed().when("type", {
          is: EventType.stream,
          then: Yup.mixed().oneOf([...Object.values(EEventStreamType), null]),
        }),
        stream_url: getStreamUrlValidationBuilder(true),
        pre_event_type: Yup.mixed().oneOf([...Object.values(EPreEventType), null]),
        files: Yup.array().nullable(),
        pre_event_video_url: Yup.string().nullable(),
        pre_images: Yup.array().nullable(),
      });
      setValidationSchema(optionalStreamUrl);
      setSaveAction(saveInitial ? "saveInitial" : "saveDraft");
    },
    [eventIsPublished, onPublish, setValidationSchema],
  );

  useEffect(() => {
    if (saveAction === null) {
      let schema: Yup.ObjectSchema | Yup.ArraySchema<any>;
      switch (activeStep) {
        case 0:
          schema = eventGeneralStepValidationSchema;
          break;
        case 1:
          schema = eventTeamStepValidationSchema;
          break;
        case 2:
          schema = eventPermissionsStepValidationSchema;
          break;
        case 3:
          schema = isMeetingEvent ? sermonValidationSchema : eventStreamStepValidationSchema;
          break;
        case 4:
          schema = eventPreEventStepValidationSchema;
          break;
        case 6:
          schema = sermonValidationSchema;
          break;
        case 7:
          schema = navigationStepValidationSchema;
          break;
        default:
          schema = eventGeneralStepValidationSchema;
      }
      setValidationSchema(schema);
    }
  }, [activeStep, saveAction, setValidationSchema, isMeetingEvent]);

  useEffect(() => {
    if (saveAction) {
      (async () => {
        if (await validate()) {
          if (saveAction === "publish") {
            handlePublish(values);
          } else if (saveAction === "saveDraft") {
            handleSaveDraft(values);
          } else if (saveAction === "saveInitial") {
            handleSaveDraft(values, false, true);
          }
        }
      })();
      setSaveAction(null);
    }
  }, [handlePublish, handleSaveDraft, saveAction, validate, values]);

  useEffect(() => {
    return () => {
      setSteps(st =>
        st.map(s => {
          return {
            title: s.title,
            completed: false,
          };
        }),
      );
    };
  }, []);

  useEffect(() => {
    switch (activeStep) {
      case 1:
        setBlockedHeader("Team");
        setBlockedText(
          "Add members to be the Hospitality Team during the event. The Hospitality Team can chat with both general viewers and logged in members, answer questions, and manage permissions and remove people from the event.",
        );
        setBlockedImg(event_team_step);
        setBlockedLearnMoreLink("");
        break;
      case 3:
        setBlockedHeader("Stream");
        setBlockedText(
          values.video_type === EEventVideoType.live
            ? "Copy and paste Altar’s RTMP credentials into your streaming software to begin streaming."
            : "Upload a pre-recorded video file. The video will be played as simulated live and synced across web and mobile devices.",
        );
        setBlockedImg(relay_block);
        setBlockedLearnMoreLink("");
        break;
      case 4:
        setBlockedHeader("Pre Event Slides");
        setBlockedText(
          "Upload slides to your pre-event countdown screen. Slides will play automatically and continually until the event starts.",
        );
        setBlockedImg(event_pre_step);
        setBlockedLearnMoreLink("");
        break;
      case 6:
        setBlockedHeader("Set Up Your Space");
        setBlockedText(
          "Increase engagement during the Event or Meeting by allowing attendees to interact with you and with each other using video conference Rows, Tables and Rooms.",
        );
        setBlockedImg(event_post_step);
        setBlockedLearnMoreLink(LINKS.create_event);
        break;
      default:
        setBlockedHeader("");
        setBlockedText("");
        setBlockedLearnMoreLink("");
    }
    if (wrapperRef.current) {
      wrapperRef.current.focus();
    }
  }, [activeStep, values.video_type]);

  const handleKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      onNextStep();
    }
  };
  const handleSkipUpgrade = useCallback(() => {
    setValues({ ...values, stream_type: null, stream_url: "", stream_settings: null });
  }, [values, setValues]);
  const isRelayBlocker = useMemo(() => {
    const { stream_type } = values;
    return (
      activeStep === 3 &&
      stream_type &&
      [EEventStreamType.ALTAR_LIVE, EEventStreamType.VIMEO_LIVE].includes(stream_type) &&
      (!hasPaidSubscription || !features.includes(EFeature.rtmp))
    );
  }, [values, activeStep, hasPaidSubscription, features]);

  const redirectToSubscription = useCallback(() => {
    if (currentCommunity?.code) {
      history.push(`/${currentCommunity.code}${COMMUNITY_SETTINGS}${BILLING_SETTINGS}`);
      dispatch(setDashBoardDisabled(false));
    }
  }, [currentCommunity?.code, dispatch, history]);

  return (
    <div className="stream-event" ref={wrapperRef} onKeyUp={handleKeyUp} tabIndex={0}>
      <div className="stream-event-right-panel">
        <div className="logo" />
        <VerticalWizardSteps steps={steps} activeStep={activeStep} onChange={onStepChange} />
      </div>
      <div className="stream-event-main">
        <div className="stream-event-main-header">
          {event?.id && event.status !== EventStatus.initial && (
            <SquareButton type="button" variant="orange-text" width={64} className="remove" onClick={onRemove}>
              Delete
            </SquareButton>
          )}
          <SquareButton type="button" variant="purple-text" width={48} className="close" onClick={onClose}>
            Close
          </SquareButton>
          {(!eventIsPublished || (activeStep + 1 < steps.length && eventIsPublished)) && !communityCreation && (
            <SquareButton type="button" variant="blue-text" width={120} className="save" onClick={() => onSave()}>
              {eventIsPublished ? "Save Changes" : "Save As Draft"}
            </SquareButton>
          )}
          {activeStep > 0 && (
            <SquareButton type="button" variant="blue-outline" width={112} onClick={onPrevStep} className="back">
              Back
            </SquareButton>
          )}
          {activeStep + 1 === steps.length && eventIsPublished && !communityCreation && (
            <SquareButton type="button" variant="blue" width={130} onClick={() => onSave()}>
              {eventIsPublished ? "Save Changes" : "Save As Draft"}
            </SquareButton>
          )}
          {activeStep + 1 < steps.length ? (
            <SquareButton type="button" variant="blue" width={112} onClick={onNextStep}>
              Next
            </SquareButton>
          ) : !eventIsPublished ? (
            <SquareButton type="button" variant="blue" width={88} onClick={onPublish}>
              Publish
            </SquareButton>
          ) : null}
        </div>
        <div className="stream-event-main-content">
          <div className="stream-event-main-content-wrapper">
            {(activeStep === 1 && !features.includes(EFeature.host_and_greeters)) ||
            (activeStep === 4 && !features.includes(EFeature.pre_event_slides)) ||
            (activeStep === 6 && !features.includes(EFeature.lobby)) ? (
              <BlockedStep
                header={blockedHeader}
                text={blockedText}
                imageSrc={blockedImg}
                learnMoreLink={blockedLearnMoreLink}
                onUpgrade={redirectToSubscription}
              />
            ) : isRelayBlocker ? (
              <BlockedStep
                header={blockedHeader}
                text={blockedText}
                imageSrc={blockedImg}
                onUpgrade={redirectToSubscription}
                onSkip={handleSkipUpgrade}
                className="relayBlocker"
              />
            ) : activeStep === 0 ? (
              <GeneralStep isMeetingEvent={Boolean(isMeetingEvent)} isDefaultEvent={isDefaultEvent} />
            ) : activeStep === 1 ? (
              <TeamStep isMeetingEvent={Boolean(isMeetingEvent)} />
            ) : activeStep === 2 ? (
              <PermissionsStep />
            ) : activeStep === 3 ? (
              isMeetingEvent ? (
                <InteractionsStep />
              ) : (
                <StreamStep saveDraft={() => onSave(true)} />
              )
            ) : activeStep === 4 ? (
              isMeetingEvent ? (
                <RoomsStep isMeetingEvent />
              ) : (
                <PreEventStep saveDraft={() => onSave(true)} />
              )
            ) : activeStep === 5 ? (
              <InteractionsStep />
            ) : activeStep === 6 ? (
              <RoomsStep />
            ) : activeStep === 7 ? (
              <NavigationStep />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamEvent;
