import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ImageUploading, { ImageListType } from "react-images-uploading";
import Resizer from "react-image-file-resizer";
import classNames from "classnames";

import { AlertDialog } from "../AlertDialog";

import { notificationActions } from "containers/Notifications/store/actions";
import { ThemeType } from "shared/interfaces";

import "./imageUpload.scss";

const getSizeMBFromBase64 = (data: string) => {
  if (!data.length) {
    return 0;
  }
  const sizeInBytes = data.length * (3 / 4) - 2;
  return sizeInBytes / 1024 / 1024;
};

type ImageUpload = {
  value?: string | null;
  onChange?(data: string): void;
  backgroundImage?: string;
  backgroundSize?: string;
  maxMb?: number;
  showOptions?: boolean;
  variant?: string;
  theme?: ThemeType;
  after?: React.ReactNode;
  askReplace?: boolean;
  conditionalConvertFormat?: boolean;
  hideUploadedImg?: boolean;
  imageMaxSize?: number;
  imageMinSize?: number;
};

const ImageUpload: React.FunctionComponent<ImageUpload> = ({
  value,
  onChange,
  backgroundImage,
  backgroundSize,
  maxMb,
  showOptions,
  variant,
  children,
  theme,
  after,
  askReplace,
  conditionalConvertFormat,
  hideUploadedImg,
  imageMaxSize,
  imageMinSize,
}) => {
  const [openRemoveDialog, setRemoveDialog] = useState(false);
  const [openReplaceDialog, setOpenReplaceDialog] = useState(false);
  const [profileImageError, setProfileImageError] = useState(false);

  useEffect(() => {
    setProfileImageError(false);
  }, [value]);

  const dispatch = useDispatch();

  const [images, setImages] = useState<ImageListType>([]);

  const setImageHandler = (imageList: ImageListType) => {
    const image = imageList[0];
    if (!!image && !!image.file) {
      let compressedFormat = "JPEG";
      if (conditionalConvertFormat && (image.file.type === "image/png" || image.file.type === "image/svg+xml")) {
        compressedFormat = "PNG";
      }
      Resizer.imageFileResizer(
        image.file,
        imageMaxSize || 1024,
        imageMaxSize || 1024,
        compressedFormat,
        75,
        0,
        uri => {
          const imageSize = getSizeMBFromBase64(uri as string);
          if (maxMb && imageSize > maxMb) {
            dispatch(notificationActions.error("The size of the uploading image is too large"));
            return;
          }
          onChange && onChange(uri as string);
        },
        "base64",
        imageMinSize || 1024,
        imageMinSize || 1024,
      );
    }
  };

  const handleChange = (imageList: ImageListType) => {
    if (askReplace && value) {
      setOpenReplaceDialog(true);
      setImages(imageList);
    } else {
      setImageHandler(imageList);
    }
  };

  useEffect(() => {
    setImages(value ? [{ dataURL: value }] : []);
  }, [value]);

  return (
    <ImageUploading value={images} onChange={handleChange} maxNumber={1}>
      {({ onImageUpload, onImageUpdate, onImageRemoveAll }) => {
        const onRemoveConfirm = () => {
          onImageRemoveAll();
          setRemoveDialog(false);
          setImages([]);
          onChange?.("");
        };
        return (
          <div className={classNames("imageUpload", { [`variant-${variant}`]: !!variant }, theme)}>
            <AlertDialog
              open={openRemoveDialog}
              title="Remove image"
              message="Do you want to remove image?"
              onConfirm={onRemoveConfirm}
              onCancel={() => setRemoveDialog(false)}
              mode="cancel"
              confirmText="Remove"
            />
            <AlertDialog
              open={openReplaceDialog}
              title="Replace Image"
              message="Are you sure that you want to replace this image with another one? The original image will be deleted."
              onConfirm={() => {
                setImageHandler(images);
                setOpenReplaceDialog(false);
              }}
              onCancel={() => setOpenReplaceDialog(false)}
              mode="confirm"
              confirmText="Replace"
              confirmClassName="replace-image-button"
            />
            {value && !profileImageError && !hideUploadedImg ? (
              <div className={"imageUpload-uploaded"}>
                <img
                  alt="uploaded"
                  className={"imageUpload-uploaded-img"}
                  src={value as string}
                  onClick={() => onImageUpdate(0)}
                  onError={() => setProfileImageError(true)}
                />
                {showOptions && (
                  <div className={"imageUpload-uploaded-options"}>
                    <p className={"editIcons editIcons-edit"} onClick={() => onImageUpdate(0)} />
                    <p className={"editIcons editIcons-delete"} onClick={() => setRemoveDialog(true)} />
                  </div>
                )}
              </div>
            ) : (
              <div
                className={"imageUpload-select"}
                style={{ backgroundImage, backgroundSize }}
                onClick={() => (value ? onImageUpdate(0) : onImageUpload())}
              >
                {children}
                {showOptions && <p className={"imageUpload-select-icon editIcons editIcons-add"} />}
              </div>
            )}
            {after && (
              <div className="imageUpload-after-wrapper" onClick={() => (value ? onImageUpdate(0) : onImageUpload())}>
                {after}
              </div>
            )}
          </div>
        );
      }}
    </ImageUploading>
  );
};

export default ImageUpload;
