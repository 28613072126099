import { createSelector } from "reselect";

import { AltarStore } from "shared";

const selectMeeting = (state: AltarStore) => state.meetings;

export const selectEightToEightToken = () => createSelector(selectMeeting, state => state.token);
export const selectEightToEightUser = () => createSelector(selectMeeting, state => state.eightToEightUser);
export const selectClassrooms = () => createSelector(selectMeeting, state => state.classrooms);
export const selectCurrentClassroom = () => createSelector(selectMeeting, state => state.current_classroom);
export const selectFoolClassroom = () => createSelector(selectMeeting, state => state.classroom_is_full);
export const getRecordingStatus = () => createSelector(selectMeeting, state => state.recordingStatus);
export const getPreJoinPage = () => createSelector(selectMeeting, state => state.preJoinPage);
export const getFollowMe = () => createSelector(selectMeeting, state => state.followMe);
