import React, { useCallback, useEffect, useRef, useState } from "react";

import { GUIDES, KEYNOTE_SPEAKERS, MAIN_SPEAKERS, GRAB_FREE_PASS_LINK, SPONSORS } from "./constants";
import EcammSpeakers from "./EcammSpeakers";

import { Button } from "shared";
import main_header from "assets/images/ecamm/main_header.png";
import arrow from "assets/icons/arrow_upward_purple.svg";

import "./index.scss";

const scroll = (ref: React.RefObject<HTMLElement>) => {
  if (ref.current) {
    const topPos = ref.current.offsetTop;
    window.scrollTo({ top: topPos - 50, behavior: "smooth" });
  }
};

const observerOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0.5,
};

const EcammLandingPage: React.FC = () => {
  const scheduleRef = useRef(null);
  const scheduleRefExist = !!scheduleRef.current;

  const [isIntersecting, setIsIntersecting] = useState(true);
  const [clientRectY, setClientRectY] = useState(0);

  const observerHandler = useCallback(([entry]) => {
    setClientRectY(entry.boundingClientRect.y);
    setIsIntersecting(entry.isIntersecting);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(observerHandler, observerOptions);
    const refCurrent = scheduleRef.current;
    if (refCurrent) {
      observer.observe(refCurrent);
    }
    return () => {
      if (refCurrent) {
        observer.unobserve(refCurrent);
      }
    };
  }, [observerHandler, scheduleRefExist]);
  return (
    <div className="customLanding-wrapper">
      <div className="customLanding-header">
        <img src={main_header} alt="header img" className="customLanding-header-image" />
        <div className="customLanding-header-gradient" />
      </div>
      {!isIntersecting && clientRectY > 0 && (
        <Button className="scrollToSchedule" variant="ecamm-landing" width={200} onClick={() => scroll(scheduleRef)}>
          <img src={arrow} alt="arrow" />
          Go to Live Event
        </Button>
      )}
      <div className="customLanding-content">
        <div className="customLanding-content-welcome">
          <div className="customLanding-content-welcome-title">Let’s Take the LEAP!</div>
          <div className="customLanding-content-welcome-description">
            Welcome to Leap Into Tools & Tactics! Leap began in 2020 as a virtual crash course in all aspects of live
            video and video production. Since its founding, it has evolved into a beloved virtual event that has focused
            on collaborative and community-based learning.
            <br />
            <br />
            This year’s event is focused on the tools and tactics behind video creation so that YOU can create
            efficiencies, save time, and engage your audience base and customers. Grab your notebook and block off your
            calendar because we're bringing you everything you need to know.
          </div>
          <Button
            variant="ecamm-landing"
            width={273}
            type="button"
            onClick={() => window.open(GRAB_FREE_PASS_LINK, "_blank")}
          >
            Get Your FREE Pass Today
          </Button>
        </div>
        <div className="customLanding-content-guide">
          <div className="customLanding-content-guide-title">Grab the Guide & Get Replay Access</div>
          <div className="customLanding-content-guide-description">
            {/*Looking for the perfect companion this event? Grab the Leap into Tools + Tactics digital guidebook. This*/}
            {/*all-encompassing Notion guide includes tips, tricks, and resources from experts. Plus this guide unlocks*/}
            {/*access to all the replay videos from this year and all past Leap Into Live events. That's over 300 hours of*/}
            {/*expert content about live video creation, selling, and podcasting!*/}
          </div>
          <div className="customLanding-content-guide-items">
            {GUIDES.map((g, index) => {
              return (
                <div className="item" key={index}>
                  <img src={g.img} alt="guide" />
                  <div className="item-info">
                    <div className="item-info-text">
                      <div className="item-info-text-title">{g.title}</div>
                      <div className="item-info-text-description">{g.description}</div>
                    </div>
                    <div className="item-info-price">{g.price}</div>
                  </div>
                  <Button
                    variant="ecamm-landing"
                    width={95}
                    type="button"
                    onClick={() => window.open(g.link, "_blank")}
                  >
                    Get it Now
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="customLanding-content-sponsors">
          <div className="customLanding-content-sponsors-title">Thank You to Our Partners</div>
          <div className="customLanding-content-sponsors-items">
            {SPONSORS.map((s, index) => {
              return (
                <div className="sponsor" key={index} onClick={() => window.open(s.link, "_blank")}>
                  <img src={s.img} alt="sponsor" style={s.style} />
                  <div className="sponsor-title">{s.title}</div>
                  <div className="sponsor-description">{s.description}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="customLanding-content-speakers">
          <div className="customLanding-content-speakers-title">Speakers</div>
          <div className="customLanding-content-speakers-keynotes">
            {KEYNOTE_SPEAKERS.map((s, index) => {
              return (
                <div className="keynote" key={index}>
                  <img src={s.img} alt="pic" />
                  <div className="keynote-info">
                    <div className="keynote-info-name">
                      {s.name}
                      <div className="keynote-info-name-badge">KEYNOTE SPEAKER</div>
                    </div>
                    <div className="keynote-info-title">{s.title}</div>
                    <div className="keynote-info-description">{s.description}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="customLanding-content-speakers-main">
            {MAIN_SPEAKERS.map(s => (
              <EcammSpeakers key={s.name} speaker={s} />
            ))}
          </div>
        </div>
        <div className="customLanding-content-agenda" ref={scheduleRef}>
          Schedule
        </div>
      </div>
    </div>
  );
};

export default EcammLandingPage;
