import { ReactGAImplementation } from "react-ga4";

class GA extends ReactGAImplementation {
  private static startTracking(id: string) {
    // @ts-ignore
    window[`ga-disable-${id}`] = false;
  }

  private stopTracking(id: string) {
    // @ts-ignore
    window[`ga-disable-${id}`] = true;
    this.reset();
  }

  stopCurrentTracking() {
    if (this._currentMeasurementId) {
      this.stopTracking(this._currentMeasurementId);
      this._currentMeasurementId = "";
    }
  }

  reInit(id?: string | null) {
    this.stopCurrentTracking();
    if (!id || this._currentMeasurementId !== id) {
      if (id) {
        GA.startTracking(id);
        this.initialize(id, { gtagOptions: { send_page_view: true } });
      }
    }
  }
}

export default new GA();
