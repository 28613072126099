import React, { useMemo } from "react";
import { ChannelHeaderProps, useChannelStateContext, useChatContext } from "stream-chat-react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { CustomAvatar } from "../CustomAvatar";
import { IMember } from "../../../Member";
import { selectors } from "../../store";

import { CanView } from "shared/components/CanView";
import { customizeGetstreamChannel } from "shared/utils/getStream";
import gear_icon from "assets/icons/gear_icon.svg";

import "./customChannelHeader.scss";

interface ICustomChannelHeader extends ChannelHeaderProps {
  openChannelEdit: () => void;
  member?: IMember;
  theme?: string;
}

const CustomChannelHeader: React.FC<ICustomChannelHeader> = props => {
  const { openChannelEdit, member, theme = "" } = props;
  const selectedChannel = useSelector(selectors.getSelectedChannelDetails());

  const { client } = useChatContext();
  const { channel } = useChannelStateContext();

  const canEdit = useMemo(() => {
    return selectedChannel?.creator?.id === member?.id;
  }, [selectedChannel, member]);

  if (selectedChannel && channel && client) {
    const { isOnlineInPersonal, getMembersStatus, getChannelName, getChannelImage } = customizeGetstreamChannel(
      channel,
      selectedChannel,
    );

    return (
      <div className={classnames("customChannelHeader", { [theme]: !!theme })}>
        <div className="customChannelHeader-info">
          <div className="avatar">
            <CustomAvatar
              image={getChannelImage()}
              size={32}
              name={getChannelName()}
              color={selectedChannel?.color}
              isOnline={isOnlineInPersonal()}
            />
          </div>
          <div className="customChannelHeader-data">
            <p className="customChannelHeader-data-name">{getChannelName()}</p>
            <p className="customChannelHeader-data-status">{isOnlineInPersonal() ? "Online" : getMembersStatus()}</p>
          </div>
        </div>
        <CanView condition={canEdit}>
          <div className="customChannelHeader-actions">
            <div className="customChannelHeader-actions-groupActionsContainer">
              <img className="customChannelHeader-actions-edit" onClick={openChannelEdit} src={gear_icon} alt="gear" />
            </div>
          </div>
        </CanView>
      </div>
    );
  } else {
    return null;
  }
};

export default CustomChannelHeader;
