import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { IMember } from "containers/Member/interfaces";
import { Button } from "shared/components";
import { IUser } from "containers/Auth/interfaces";
import { generateBackground, getInitials } from "shared";
import { getEventMembers } from "containers/Sermons/store/selectors";

import "./profileBtn.scss";

interface IProfileBtn {
  member: IMember | null;
  user: IUser | null;
  onClick: () => void;
}

const ProfileBtn: React.FC<IProfileBtn> = ({ onClick, member, user }) => {
  const [background, setBackground] = useState<undefined | string>();

  const eventMembers = useSelector(getEventMembers());

  const [profileImageError, setProfileImageError] = useState(false);

  useEffect(() => {
    setProfileImageError(false);
  }, [member, user]);

  useEffect(() => {
    if (eventMembers.length > 0 && (member || user)) {
      const participant = eventMembers.find(
        ({ memberId }) => memberId === member?.id || memberId === user?.members[0]?.id,
      );
      setBackground(participant?.color || generateBackground());
    }
  }, [eventMembers, member, user]);

  const initials = member?.id
    ? getInitials(member)
    : user
    ? getInitials({ first_name: user.first_name, last_name: user.last_name } as IMember)
    : "";

  const profileImage = member?.image_url || user?.image_url;

  const name = member || user ? `${member?.first_name || user?.first_name}` : "";

  return (
    <div className="profileBtn" onClick={onClick}>
      {name && <div className="profileBtn-name">{name}</div>}
      <Button width={40} height={40} variant="blue-text" className="profileBtn-user">
        {profileImage && !profileImageError ? (
          <img src={profileImage} alt={member?.first_name || "member"} onError={() => setProfileImageError(true)} />
        ) : (
          (member || user) && (
            <div className="initials" style={{ backgroundColor: background }}>
              <div className="fallback">{initials}</div>
            </div>
          )
        )}
      </Button>
    </div>
  );
};
export default ProfileBtn;
