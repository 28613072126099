import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import classNames from "classnames";

import { ICommunityLandingSettings } from "../../../interfaces";
import { selectLandingSettings } from "../../../store/selectors";

import { ColorPicker, CustomFormikField, ImageUpload, StyledRadio } from "shared";
import community_default from "assets/icons/community_default.svg";

import "./index.scss";

const AppearanceDetails: React.FC = () => {
  const { values, handleChange, errors, handleBlur, setFieldValue } = useFormikContext<ICommunityLandingSettings>();

  const landingSettings = useSelector(selectLandingSettings());

  const imageUploadInfo = useMemo(
    () => (
      <div className="image-choose-wrapper">
        <div className={classNames("image-choose-title", { replace: landingSettings?.logo })}>
          {landingSettings?.logo ? "Replace Logo" : "Choose Logo"}
        </div>
      </div>
    ),
    [landingSettings],
  );

  return (
    <div className="appearance-details">
      <div className="left-side">
        <div className="image-selector-wrapper">
          <div className="image-information-wrapper">
            <div className="image-information-title">Community logo</div>
          </div>
          <ImageUpload
            showOptions
            maxMb={3}
            value={values.logo}
            onChange={handleChange("logo")}
            backgroundImage={`url(${community_default})`}
            variant="square"
            after={imageUploadInfo}
            askReplace={true}
            conditionalConvertFormat={true}
          />
          {errors.logo && <div className="label-error">{errors.logo}</div>}
        </div>
      </div>
      <div className="right-side">
        <div className="radio-button-wrapper">
          <div className="radio-button-title">Page Color Theme</div>
          <RadioGroup
            name="theme_color"
            value={values.theme_color}
            onChange={(event, value) => {
              setFieldValue("text_color", value === "light" ? "black" : "white");
              handleChange(event);
            }}
            className="radio-options"
          >
            <FormControlLabel value="dark" control={<StyledRadio />} label="Dark" />
            <FormControlLabel value="light" control={<StyledRadio />} label="Light" />
          </RadioGroup>
        </div>
        <div className="color-picker-wrapper">
          <div className="color-picker-title">Accent Color</div>
          <div className="color-picker">
            <div className="color-picker-input">
              <CustomFormikField
                fullWidth
                required
                label="Accent Color"
                name="accent_color"
                errors={errors}
                value={values.accent_color}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ColorPicker color={values.accent_color} onChange={color => setFieldValue("accent_color", color)} />
            </div>
          </div>
        </div>
        <div className="radio-button-wrapper">
          <div className="radio-button-title">Navigation Link Text Color</div>
          <RadioGroup name="text_color" value={values.text_color} onChange={handleChange} className="radio-options">
            <FormControlLabel value="white" control={<StyledRadio />} label="White" />
            <FormControlLabel value="black" control={<StyledRadio />} label="Black" />
          </RadioGroup>
        </div>
        <div className="radio-button-wrapper">
          <div className="radio-button-title">Navigation Link Text Case</div>
          <RadioGroup name="text_case" value={values.text_case} onChange={handleChange} className="radio-options">
            <FormControlLabel value="capitalize" control={<StyledRadio />} label="Title Case" />
            <FormControlLabel value="uppercase" control={<StyledRadio />} label="Upper Case" />
          </RadioGroup>
        </div>
      </div>
    </div>
  );
};

export default AppearanceDetails;
