import React, { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router";
import classnames from "classnames";
import * as HttpStatus from "http-status-codes";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "lodash";

import { EventPollsDialog } from "../../components/EventContainer/EventPollsDialog";
import NotificationPopup from "../../../Notifications/components/NotificationPopup";
import NotificationAnnouncement from "../../../Notifications/components/NotificationAnnouncement";
import { INotificationAnnouncement } from "../../../Notifications/interfaces";
import { PreJoinPage } from "../../../Meeting/components/PreJoinPage";
import { getPreJoinPage, selectCurrentClassroom } from "../../../Meeting/store/selectors";
import { AnonymousPage } from "../../../Meeting/components";
import { Loader } from "../../../App";
import { KnockJoinEventPopup } from "../../components/KnockJoinEventPopup";
import {
  getAllowEnded,
  getAnnouncements,
  getAnnouncementTemplates,
  getChangeStatusOptions,
  getChangingStatus,
  getKnockRejected,
  getPolls,
  getSocketConnected,
  getWaitingToJoinMembers,
  isParticipantsLimitExceeded,
} from "../../store/selectors";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../../constants";
import { actions as communityActions } from "../../../Community/store";
import { EventHostNotes } from "../../components/EventContainer/EventHostNotes";
import { EventBlocker } from "../../components/EventContainer/EventBlocker";

import { AlertDialog, EmptyText, EventDashboard, EventHeader, TopNotification } from "shared/components";
import {
  actions,
  AnnouncementStatus,
  checkMeetingRole,
  EEventRepeat,
  EMemberRestriction,
  EventAnnouncementsDialog,
  EventAuthDialog,
  EventDashboardTabs,
  EventEditProfileDialog,
  EventMembersDialog,
  EventSignUpDialog,
  EventStatus,
  EventType,
  EventWelcomeDialog,
  NextEventCard,
  PollStatus,
  selectors,
  SermonRoles,
} from "containers/Sermons";
import {
  askNotificationPermission,
  generateBackground,
  getSubdomain,
  IRouteProps,
  memberCIDHandler,
  tokenHandler,
  usePermissions,
} from "shared";
import { CommunityPermissions, ERoles, selectors as userSelectors } from "containers/Auth";
import {
  fetchMeetingMembers,
  fetchRestrictedMembers,
  setCurrentRestrictedMember,
} from "containers/Member/store/actions";
import { EventMemberTypes } from "containers/Sermons/constants";
import { getUserData, login } from "containers/Auth/store/actions";
import PrivateRoute from "containers/App/PrivateRoute";
import { getCurrentRestrictedMember, getMember, getRestrictedMembers } from "containers/Member/store/selectors";
import {
  getCommunity,
  getCommunityInvitationHash,
  getCommunitySubscriptions,
} from "containers/Community/store/selectors";
import { isMatchedPath } from "containers/Community/utils";
import { createAnonymousChannelWithGreeter, setActiveChannel } from "containers/Discussions/store/actions";
import { actions as brActions } from "containers/BreakoutRooms/store";
import { checkUserMediaPermissions, exitRoom } from "containers/BreakoutRooms/store/actions";
import { peerConnection } from "containers/BreakoutRooms/utils/peerConnection";
import { useCustomName } from "shared/hooks/CustomName/CustomName";
import { selectRoomCode } from "containers/BreakoutRooms/store/selectors";
import { usePrevious } from "shared/hooks/PreviousHook";
import { notificationActions } from "containers/Notifications/store/actions";
import { useFeatures } from "shared/hooks/FeaturesHook";
import { EFeature } from "containers/Community/interfaces";
import useStartChatHook from "shared/hooks/StartChatHook/StartChatHook";
import { setDashBoardDisabled } from "containers/App/store/actions";
import { isHubspotInited } from "containers/App/store/selectors";
import { DiscussionTab } from "shared/components/Event/EventSideDialog/EventSideDialogHeader/EventSideDialogDiscussionTabs";
import MobileWrapper from "containers/Sermons/components/EventContainer/MobileWrapper";
import CountdownTimer from "containers/Sermons/components/CountdownTimer";
import { selectLandingData } from "containers/LandingPage/store/selectors";
import { clearSettings, loadLandingSettings } from "containers/LandingPage/store/actions";
import { searchParamsToObject } from "shared/utils/searchParams";
import {
  fetchAnnouncementsList,
  fetchAnnouncementTemplates,
  fetchPolls,
  fetchPollTemplates,
  joinMeetingKnockResponse,
  knockJoinMeeting,
  setEventHostOrAdmin,
  updateEventSettings,
} from "containers/Sermons/store/actions";
import { clearMeetingState, leaveClassroom, setPreJoinPage } from "containers/Meeting/store/actions";
import { useClassroomHook } from "shared/hooks/ClassroomFullHook";
import { getEventTypeName, memberHasEventRoleOrAdmin } from "containers/Sermons/utils";
import { hideWidget, setIconPosition, showWidget } from "utils/hubspot";
import { checkRoles } from "utils/ACL";
import EventTourPreview from "shared/components/EventTourPreview";
import { useIsMobile } from "shared/hooks/IsMobileHook";

import "./eventContainer.scss";

const VideoStreamContainer = lazy(() => import("../../containers/VideoStreamContainer/VideoStreamContainer"));
const EventDiscussions = lazy(() => import("../../components/EventContainer/EventDiscussions/EventDiscussions"));
const ClassroomsContainer = lazy(() => import("../../../Meeting/containers/ClassroomsContainer/ClassroomsContainer"));
const EightToEightContainer = lazy(
  () => import("../../../Meeting/containers/EightToEightContainer/EightToEightContainer"),
);
const BreakoutRoom = lazy(() => import("../../../BreakoutRooms/containers/BreakoutRoom/BreakoutRoom"));
const TableListContainer = lazy(
  () => import("../../../BreakoutRooms/containers/TableListContainer/TableListContainer"),
);

const { EVENTS, STREAM, BREAKOUTROOMS, WATCH_PARTY, CLASSROOMS, DASHBOARD, COMMUNITY_SETTINGS } = NamesOfParentRoutes;
const { BILLING_SETTINGS } = NameOfRoutes;

const EventContainer: React.FC = () => {
  const { eventCode, communityCode } = useParams<IRouteProps>();

  const { t } = useTranslation();

  const event = useSelector(selectors.getEvent());
  const eventMembers = useSelector(selectors.getEventMembers());
  const communityMembers = useSelector(getMember());
  const currentUser = useSelector(userSelectors.getUser());
  const member = useSelector(userSelectors.getMember());
  const isAuthenticated = useSelector(userSelectors.authentificated());
  const eventError = useSelector(selectors.getError());
  const communityInvitationHash = useSelector(getCommunityInvitationHash());
  const { activeDashboardTab, memberToFind } = useSelector(selectors.getEventSettings());
  const blockedMembers = useSelector(selectors.getBlockedMembers());
  const isSocketConnected = useSelector(getSocketConnected());
  const roomCode = useSelector(selectRoomCode());
  const community = useSelector(getCommunity());
  const communitySubscriptions = useSelector(getCommunitySubscriptions());
  const allowEnded = useSelector(getAllowEnded());
  const changingStatus = useSelector(getChangingStatus());
  const participantsLimitExceeded = useSelector(isParticipantsLimitExceeded());
  const hubspotInited = useSelector(isHubspotInited());
  const announcements = useSelector(getAnnouncements());
  const announcementTemplates = useSelector(getAnnouncementTemplates());
  const eventIsLive = useRef(false);
  const unreadAnnouncementsCount = useSelector(selectors.getUnreadAnnouncementsCount());
  const polls = useSelector(getPolls());
  const preJoinPage = useSelector(getPreJoinPage());
  const currentClassroom = useSelector(selectCurrentClassroom());
  const waitingToJoinMembers = useSelector(getWaitingToJoinMembers());
  const knockRejected = useSelector(getKnockRejected());
  const changeStatusOptions = useSelector(getChangeStatusOptions());
  const restrictedMembers = useSelector(getRestrictedMembers());
  const currentRestrictedMember = useSelector(getCurrentRestrictedMember());

  const [showTour, setShowTour] = useState(false);
  const [tourWasShown, setTourWasShown] = useState(false);
  const [showMeAround, setShowMeAround] = useState(false);
  const [showStatusChangingCounter, setShowStatusChangingCounter] = useState(false);
  const [showEndingCounter, setShowEndingCounter] = useState(false);
  const [openRestrictionAlert, setOpenRestrictionAlert] = useState(false);

  const customName = useCustomName(showTour);

  const eventTypeMeeting = event?.type === "meeting";

  const prevMemberId = useRef<number | string>(0);

  const alreadyConnectedMembersIds = useRef<(number | string)[]>([]);

  const { search } = useLocation();

  const features = useFeatures();

  const history = useHistory();

  const { subdomain_part, token } = searchParamsToObject(history.location.search);

  const { isClassroomFull } = useClassroomHook();

  const pathName = history.location.pathname;

  const dispatch = useDispatch();

  const { startChatHandler } = useStartChatHook();

  const [notification, setNotification] = useState("");
  const [showWaitingMembersToJoin, setShowWaitingMembersToJoin] = useState(false);
  const [knockRejectedEmptyState, setKnockRejectedEmptyState] = useState(false);
  const [waitingMembersCount, setWaitingMembersCount] = useState(0);

  const memberCID = memberCIDHandler.get();

  const { subdomain, isApp } = getSubdomain();

  const settings = useSelector(selectLandingData());

  const canEdit = usePermissions([CommunityPermissions.edit], community?.id);

  const changeShowMeAroundTour = useCallback(() => {
    setShowMeAround(!showMeAround);
  }, [showMeAround]);

  useEffect(() => {
    dispatch(setDashBoardDisabled(true));
  }, [dispatch]);

  useEffect(() => {
    if (event && communitySubscriptions && !tourWasShown) {
      setTimeout(() => {
        setShowTour(true);
        setTourWasShown(true);
      }, 3000);
    }
  }, [event, communitySubscriptions, tourWasShown]);

  // set properties into separate variables to avoid dependencies on the `event`
  // and unnecessary re-runs of `useEffect`s and `useCallback`s
  const eventId = event?.id;
  const eventStatus = event?.status;
  const eventType = event?.type;
  const eventCommunityCode = event?.community?.code;
  const eventCommunityId = event?.community_id;
  const eventRepeat = event?.repeat;
  const eventIsDefault = event?.is_default;
  const eventLobbyRoomsCount = event?.lobby_rooms_count;
  const leaveEventURL = event?.leave_event_url;

  const isCurrentMemberRestricted = !!currentRestrictedMember;

  useEffect(() => {
    if (Boolean(unreadAnnouncementsCount) && activeDashboardTab === EventDashboardTabs.announcements && eventId) {
      if (isAuthenticated) {
        const newAnnouncementsIds = announcements.reduce((ids: number[], current) => {
          if (!current.is_read && current.status !== AnnouncementStatus.draft) {
            ids.push(current.id);
          }
          return ids;
        }, []);
        if (newAnnouncementsIds.length) {
          dispatch(actions.readAnnoucements.request({ meeting_id: eventId, ids: newAnnouncementsIds }));
        }
      } else {
        dispatch(actions.resetNewAnnouncements());
      }
    }
  }, [unreadAnnouncementsCount, activeDashboardTab, dispatch, announcements, eventId, isAuthenticated]);

  useEffect(() => {
    if (activeDashboardTab === EventDashboardTabs.polls) {
      const newPollIds = polls.reduce((pollIds: number[], poll) => {
        if (poll.is_read !== true && poll.status !== PollStatus.draft && poll?.id) {
          pollIds.push(poll.id);
        }
        return pollIds;
      }, []);
      if (newPollIds.length && eventId) {
        dispatch(actions.readPolls.request({ poll_ids: newPollIds, meeting_id: eventId }));
      }
    }
  }, [activeDashboardTab, dispatch, polls, eventId]);

  useEffect(() => {
    dispatch(loadLandingSettings.request({ subdomain }));
    return () => {
      dispatch(clearSettings());
    };
  }, [dispatch, subdomain]);

  useEffect(() => {
    if (token && !isAuthenticated) {
      tokenHandler.set(token);
      dispatch(login.success());
      dispatch(getUserData.request());
      history.replace({ search: "" });
    }
  }, [token, history, dispatch, isAuthenticated]);

  useEffect(() => {
    if (!subdomain_part) {
      dispatch(
        actions.updateEventSettings({ activeDashboardTab: !eventTypeMeeting ? EventDashboardTabs.welcome : null }),
      );
    }
    return () => {
      dispatch(exitRoom.request());
      dispatch(actions.getRecurringEvent.cancel());
      dispatch(actions.closeSocket());
    };
  }, [dispatch, subdomain_part, eventTypeMeeting]);

  useEffect(() => {
    if (isAuthenticated && subdomain_part) {
      const token = tokenHandler.get();
      const url = window.location.href.replace("app", subdomain_part).replace(window.location.search, "");
      window.location.replace(url + "?token=" + token);
    }
    if (!isAuthenticated && (subdomain_part || eventTypeMeeting)) {
      dispatch(actions.updateEventSettings({ activeDashboardTab: EventDashboardTabs.auth }));
    }
  }, [dispatch, isAuthenticated, eventTypeMeeting, subdomain_part]);

  useEffect(() => {
    if (eventId && eventCommunityId) {
      dispatch(fetchAnnouncementsList.request({ meeting_id: eventId }));
      dispatch(fetchPolls.request({ meeting_id: eventId }));
      dispatch(fetchAnnouncementTemplates.request(eventCommunityId));
      dispatch(fetchPollTemplates.request(eventCommunityId));
      dispatch(fetchRestrictedMembers.request({ event_id: eventId }));
    }
  }, [eventId, eventCommunityId, dispatch]);

  useEffect(() => {
    if (member) {
      const restrictedMember = restrictedMembers.find(m => m.member_id === member.id);
      dispatch(setCurrentRestrictedMember(restrictedMember || null));
    }
  }, [dispatch, member, restrictedMembers]);

  useEffect(() => {
    if (isCurrentMemberRestricted) {
      setOpenRestrictionAlert(true);
    }
  }, [isCurrentMemberRestricted]);

  const prevStatus = usePrevious(eventStatus);
  const isBlocked = useMemo(() => {
    const memberId = member?.id || memberCID || "";
    return (Boolean(memberId) && blockedMembers.includes(String(member?.id || memberCID))) || participantsLimitExceeded;
  }, [blockedMembers, member, memberCID, participantsLimitExceeded]);

  const eventTypeName = useMemo(() => getEventTypeName(event?.type), [event]);

  const canShowKnockPopup = useMemo(
    () =>
      (event &&
        member &&
        checkMeetingRole(event, member, [SermonRoles.greeter, SermonRoles.host, SermonRoles.greeterHost])) ||
      false,
    [member, event],
  );

  const showDashboardActions = useMemo(() => {
    return !eventError && !isBlocked && !isClassroomFull && !(event?.type === EventType.meeting && preJoinPage);
  }, [event, eventError, isBlocked, isClassroomFull, preJoinPage]);

  const topNotificationText = useMemo(() => {
    switch (eventStatus) {
      case EventStatus.lobby: {
        return `The Host will move everyone to Lobby ${
          changeStatusOptions?.moveTo === CLASSROOMS
            ? changeStatusOptions?.roomCode
              ? "Specific Room"
              : "Room"
            : "Table"
        } view in`;
      }
      case EventStatus.live:
      case EventStatus.scheduled: {
        return "The Host will move everyone to Auditorium view in";
      }
    }
  }, [changeStatusOptions?.moveTo, changeStatusOptions?.roomCode, eventStatus]);

  const lobbyPathInfo = useMemo(() => {
    const isBreakoutRoomPath = isMatchedPath(history.location.pathname, [
      `${STREAM}/:eventCode${BREAKOUTROOMS}`,
      `${STREAM}/:eventCode${BREAKOUTROOMS}/:roomCode`,
      `/:communityCode${STREAM}/:eventCode${BREAKOUTROOMS}`,
      `/:communityCode${STREAM}/:eventCode${BREAKOUTROOMS}/:roomCode`,
    ]);
    const isClassRoomPath = isMatchedPath(history.location.pathname, [
      `${STREAM}/:eventCode${CLASSROOMS}`,
      `/:communityCode${STREAM}/:eventCode${CLASSROOMS}`,
    ]);
    const isClassRoomCodePath = isMatchedPath(history.location.pathname, [
      `${STREAM}/:eventCode${CLASSROOMS}/:roomCode`,
      `/:communityCode${STREAM}/:eventCode${CLASSROOMS}/:roomCode`,
    ]);
    return {
      isBreakoutRoomPath,
      isClassRoomPath,
      isClassRoomCodePath,
      isLobbyPath: isBreakoutRoomPath || isClassRoomPath || isClassRoomCodePath,
    };
  }, [history.location.pathname]);

  const hasMeetingFeature = useMemo(() => features.includes(EFeature.meeting), [features]);

  const isCommunityManager = useMemo(
    () => community && checkRoles([ERoles.manager, ERoles.admin], community.id),
    [community],
  );

  const signUpHandler = useCallback((): void => {
    dispatch(actions.updateEventSettings({ activeDashboardTab: EventDashboardTabs.signUp }));
  }, [dispatch]);

  const connectUserToEvent = useCallback(() => {
    if (!isSocketConnected) {
      return;
    }
    if (isAuthenticated && !member) {
      return;
    }
    if (eventType === EventType.meeting && preJoinPage) {
      return;
    }
    const memberId = member?.id || memberCID || 0;
    const type = member?.id ? EventMemberTypes.member : EventMemberTypes.guest;
    const color = generateBackground();
    const guestName = customName ? customName : void 0;
    const guestId = prevMemberId.current;
    prevMemberId.current = memberId;

    dispatch(
      actions.connectToEvent(
        memberId,
        type,
        color,
        guestName,
        guestId ? String(guestId) : void 0,
        member ? { ...member, chat_token: undefined } : undefined,
      ),
    );
  }, [dispatch, memberCID, customName, member, isSocketConnected, isAuthenticated, preJoinPage, eventType]);

  const showStatusNotification = useCallback(
    (status: EventStatus) => {
      let text = "";
      if (changingStatus) {
        return;
      }
      if (!eventIsLive.current && status === EventStatus.live) {
        return;
      }
      switch (status) {
        case EventStatus.live:
        case EventStatus.scheduled:
          text = "The Host has moved everyone to the Auditorium";
          break;
        case EventStatus.lobby: {
          const { moveTo, roomCode } = changeStatusOptions || {};
          text = `The Host has moved everyone to the Lobby ${
            moveTo === CLASSROOMS ? (roomCode ? "Specific Room" : "Room") : "Table"
          } view`;
          break;
        }
      }
      setNotification(text);
      if (text) {
        setTimeout(() => setNotification(""), 5000);
      }
    },
    [changeStatusOptions, changingStatus],
  );

  useEffect(() => {
    askNotificationPermission();
  }, []);

  useEffect(() => {
    if (eventMembers.length && communityMembers.length) {
      let newMembersCame = false;
      eventMembers.forEach(eventMember => {
        const memberIsNotInConnectedList =
          eventMember.type === EventMemberTypes.member &&
          !alreadyConnectedMembersIds.current.includes(eventMember.memberId);
        if (memberIsNotInConnectedList) {
          alreadyConnectedMembersIds.current.push(eventMember.memberId);
          newMembersCame = true;
        }
      });

      if (newMembersCame) {
        const eventMemberNotInCommunityList = eventMembers.find(
          eventMember =>
            eventMember.type === EventMemberTypes.member &&
            !communityMembers.some(member => String(member.id) === String(eventMember.memberId)),
        );
        !!eventMemberNotInCommunityList &&
          eventCode &&
          dispatch(fetchMeetingMembers.request({ code: eventCode, customErrorHandling: true, withLoader: false }));
      }
    }
  }, [eventMembers, communityMembers, eventCode, dispatch]);

  useEffect(() => {
    if (event && member) {
      dispatch(setEventHostOrAdmin(memberHasEventRoleOrAdmin(event, member)));
    }
    return () => {
      dispatch(setEventHostOrAdmin(false));
    };
  }, [event, member, dispatch]);

  useEffect(() => {
    if (
      !!prevStatus &&
      !!eventStatus &&
      !changeStatusOptions?.doNotNotify &&
      (prevStatus !== EventStatus.scheduled || !eventTypeMeeting) &&
      changeStatusOptions?.moveTo
    ) {
      showStatusNotification(eventStatus);
    }
    // eslint-disable-next-line
  }, [eventStatus, showStatusNotification, changeStatusOptions]);

  useEffect(() => {
    if (isAuthenticated && !activeDashboardTab && !changingStatus && !currentClassroom) {
      dispatch(
        updateEventSettings({
          activeDashboardTab: EventDashboardTabs.chat,
          selectedTab: DiscussionTab.general,
        }),
      );
    }
    // eslint-disable-next-line
  }, [changingStatus, currentClassroom, isAuthenticated, dispatch]);

  eventIsLive.current = !!event?.was_started;

  useEffect(() => {
    if (isBlocked) {
      dispatch(exitRoom.request());
    }
  }, [isBlocked, dispatch]);

  useEffect(() => {
    if (event && event.is_archived) {
      dispatch(exitRoom.request());
      event.code && dispatch(actions.fetchSermon.request({ code: event.code, customErrorHandling: true }));
    }
  }, [event, dispatch]);

  useEffect(() => {
    if (hubspotInited) {
      hideWidget();
      setIconPosition("event");
    }

    return () => {
      setIconPosition("default");
      showWidget();
    };
  }, [dispatch, hubspotInited]);

  useEffect(() => {
    if (isAuthenticated) {
      peerConnection.updateSocketToken();
    }
  }, [dispatch, eventId, isAuthenticated]);

  useEffect(() => {
    if (eventId) {
      dispatch(brActions.updateRooms.request(eventId));
      dispatch(brActions.joinMeeting.request(eventId));
    }
  }, [dispatch, eventId]);

  useEffect(() => {
    if (eventError && eventCode && !isAuthenticated) {
      dispatch(actions.updateEventSettings({ activeDashboardTab: EventDashboardTabs.auth }));
    }
  }, [eventError, eventCode, isAuthenticated, history, dispatch]);

  useEffect(() => {
    if (eventId && !isSocketConnected) {
      dispatch(actions.startSermon(eventId));
    }
  }, [dispatch, eventId, isSocketConnected]);

  useEffect(() => {
    if (eventId && isSocketConnected) {
      connectUserToEvent();
    }
  }, [eventId, isSocketConnected, connectUserToEvent]);

  useEffect(() => {
    if (eventCode) {
      dispatch(fetchMeetingMembers.request({ code: eventCode, customErrorHandling: true }));
      dispatch(actions.clearCurrentSermon());
      dispatch(actions.fetchSermon.request({ code: eventCode, customErrorHandling: true, relations: ["classrooms"] }));
    }
    return () => {
      dispatch(actions.clearEventError());
      dispatch(actions.clearEventSettings());
      dispatch(communityActions.communityInvitationHash.cancel());
      dispatch(actions.setGoingLiveTime(0));
      dispatch(actions.clearCurrentSermon());
      dispatch(actions.closeSocket());
    };
  }, [dispatch, eventCode]);

  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      dispatch(getUserData.request());
    }
  }, [dispatch, isAuthenticated, currentUser]);

  useEffect(() => {
    if (!communityInvitationHash && eventCommunityCode) {
      dispatch(communityActions.communityInvitationHash.request(eventCommunityCode));
    }
  }, [dispatch, communityInvitationHash, eventCommunityCode]);

  useEffect(() => {
    if (communityInvitationHash) {
      localStorage.setItem("lastCommunityInvitationHash", communityInvitationHash);
    }
  }, [communityInvitationHash]);

  useEffect(() => {
    if (community?.code && !communitySubscriptions) {
      dispatch(communityActions.getCommunitySubscriptions.request({ code: community.code }));
    }
  }, [dispatch, communitySubscriptions, community]);

  useEffect(() => {
    if (
      eventId &&
      eventRepeat &&
      eventRepeat !== EEventRepeat.never &&
      eventStatus === EventStatus.ended &&
      !allowEnded
    ) {
      dispatch(actions.getRecurringEvent.request({ eventIdentifier: eventId, shouldRedirect: false }));
    }
  }, [eventId, dispatch, allowEnded, eventRepeat, eventStatus]);

  useEffect(() => {
    let cameraPermissionStatus: PermissionStatus;
    let micPermissionStatus: PermissionStatus;
    let cameraPermissionListener = () => {};
    let micPermissionListener = () => {};

    if (isAuthenticated) {
      if (navigator.permissions) {
        Promise.all([
          navigator.permissions.query({ name: "camera" as PermissionName }),
          navigator.permissions.query({ name: "microphone" as PermissionName }),
        ])
          .then(([camera, mic]) => {
            cameraPermissionStatus = camera;
            micPermissionStatus = mic;
            cameraPermissionListener = () => {
              dispatch(
                checkUserMediaPermissions.request({
                  cameraPermission: camera.state,
                  micPermission: mic.state,
                }),
              );
              if (roomCode && eventStatus) {
                dispatch(
                  exitRoom.request({
                    callback: () => {
                      history.replace(`${STREAM}/${eventCode}${eventStatus === "lobby" ? BREAKOUTROOMS : ""}`);
                    },
                  }),
                );
              }
            };
            micPermissionListener = () => {
              dispatch(
                checkUserMediaPermissions.request({
                  cameraPermission: camera.state,
                  micPermission: mic.state,
                }),
              );
            };
            cameraPermissionStatus.addEventListener("change", cameraPermissionListener);
            micPermissionStatus.addEventListener("change", micPermissionListener);
            dispatch(
              checkUserMediaPermissions.request({
                cameraPermission: camera.state,
                micPermission: mic.state,
              }),
            );
          })
          .catch(error => {
            dispatch(checkUserMediaPermissions.request({ cameraPermission: null, micPermission: null }));
            // eslint-disable-next-line no-console
            console.log(error);
          });
      } else {
        dispatch(checkUserMediaPermissions.request({ cameraPermission: null, micPermission: null }));
      }
    }
    return () => {
      cameraPermissionStatus?.removeEventListener("change", cameraPermissionListener);
      micPermissionStatus?.removeEventListener("change", micPermissionListener);
    };
  }, [dispatch, isAuthenticated, eventStatus, history, roomCode, eventCode]);

  const getEmptyText = useCallback(() => {
    if (eventError?.code && [HttpStatus.FORBIDDEN, HttpStatus.NOT_FOUND].includes(eventError.code)) {
      const errorKey = eventError.code === HttpStatus.NOT_FOUND ? "notFound" : "noAccess";
      return (
        <div className="event-empty">
          <h3 className="event-empty-title">Sorry!</h3>
          <p className="event-empty-body">
            {t(`errors.event.${errorKey}`, {
              eventType: eventError?.meta?.eventType === EventType.meeting ? "meeting" : "event",
            })}
          </p>
        </div>
      );
    }
  }, [t, eventError]);

  const exitEventHandler = useCallback(
    (shouldRedirect = true, toBilling = false, leaveEventRedirect = true) => {
      if (member && !canShowKnockPopup) {
        dispatch(joinMeetingKnockResponse([], [member.id]));
      }
      isAuthenticated && dispatch(exitRoom.request());
      isAuthenticated && dispatch(actions.clearCurrentSermon());
      dispatch(actions.setAllowEnded(false));
      dispatch(actions.setChangingStatus(false));
      dispatch(clearMeetingState());
      dispatch(leaveClassroom({ method: "leaveClassroom" }));
      dispatch(actions.getRecurringEvent.cancel());
      const mId = member?.id || memberCIDHandler.get();
      if (mId) {
        dispatch(actions.leaveEvent(mId));
      }
      if (shouldRedirect) {
        if (leaveEventURL && leaveEventRedirect) {
          return window.location.replace(leaveEventURL);
        }
        if (isAuthenticated && isApp && (eventCommunityCode || community?.code)) {
          const billingSettingsPath = toBilling ? `${COMMUNITY_SETTINGS}${BILLING_SETTINGS}` : "";
          return history.push(
            `/${eventCommunityCode || community?.code}${
              billingSettingsPath || (eventIsDefault && canEdit ? DASHBOARD : EVENTS)
            }`,
          );
        }
        return history.push(`/`);
      }
    },
    [
      member,
      canShowKnockPopup,
      isAuthenticated,
      dispatch,
      leaveEventURL,
      isApp,
      eventCommunityCode,
      community?.code,
      history,
      eventIsDefault,
      canEdit,
    ],
  );
  const openAuthHandler = useCallback(
    () => dispatch(actions.updateEventSettings({ activeDashboardTab: EventDashboardTabs.auth })),
    [dispatch],
  );

  const handleChangeActiveTab = useCallback(
    (tab: EventDashboardTabs | null) => {
      dispatch(actions.updateEventSettings({ activeDashboardTab: tab }));
    },
    [dispatch],
  );

  const handleCloseTab = useCallback(() => {
    dispatch(actions.updateEventSettings({ activeDashboardTab: null }));
  }, [dispatch]);

  useEffect(() => {
    history.push({ search, pathname: history.location.pathname });
  }, [history, search]);

  useEffect(() => {
    return history.listen((location, action) => {
      if (action === "POP") {
        dispatch(actions.setIsLeavingEvent(true));
        history.push(location);
      }
    });
  }, [history, dispatch]);

  useEffect(() => {
    const { isLobbyPath } = lobbyPathInfo;
    if (communitySubscriptions && eventStatus) {
      const isAuditoriumPaths = isMatchedPath(history.location.pathname, [
        `${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`,
        `/:communityCode${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`,
      ]);
      if (
        (isLobbyPath && !features.includes(EFeature.lobby)) ||
        (isAuditoriumPaths && !features.includes(EFeature.auditorium))
      ) {
        dispatch(notificationActions.error("Not available in current subscription."));
        return exitEventHandler(true, false);
      }
    }
    if (eventStatus) {
      switch (eventStatus) {
        case EventStatus.started:
        case EventStatus.scheduled:
        case EventStatus.live: {
          if (!changingStatus) {
            const isVideoStreamPaths = isMatchedPath(history.location.pathname, [
              `${STREAM}/:eventCode`,
              `${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`,
              `/:communityCode${STREAM}/:eventCode`,
              `/:communityCode${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`,
            ]);
            if (!isVideoStreamPaths) {
              history.push(`${communityCode ? `/${communityCode}` : ""}${STREAM}/${eventCode}`);
              dispatch(actions.changeStatusOptions(null));
              if (roomCode) {
                dispatch(exitRoom.request());
              }
            }
          }
          return;
        }
        case EventStatus.ended: {
          if (!allowEnded) {
            const isVideoStreamPaths = isMatchedPath(history.location.pathname, [
              `${STREAM}/:eventCode`,
              `/:communityCode${STREAM}/:eventCode`,
            ]);
            dispatch(exitRoom.request());
            dispatch(
              actions.updateEventSettings({
                activeDashboardTab: null,
              }),
            );
            !isVideoStreamPaths && history.push(`${communityCode ? `/${communityCode}` : ""}${STREAM}/${eventCode}`);
          }
          return;
        }
        case EventStatus.lobby: {
          if (!changingStatus && (!isLobbyPath || changeStatusOptions?.moveTo)) {
            if (roomCode && (!isLobbyPath || changeStatusOptions?.moveTo !== BREAKOUTROOMS)) {
              dispatch(exitRoom.request());
            }
            const splitStartEventOption = event?.start_event_option?.split(/(?=\/)/g) || [];
            const startClassroomCode = event?.classrooms?.[Number(splitStartEventOption[1]?.slice(1))]?.code;
            const navigateTo = changeStatusOptions?.moveTo
              ? `${changeStatusOptions.moveTo}${
                  changeStatusOptions.roomCode && isAuthenticated ? "/" + changeStatusOptions.roomCode : ""
                }`
              : event?.start_event_option
              ? `${splitStartEventOption[0] || BREAKOUTROOMS}${startClassroomCode ? "/" + startClassroomCode : ""}`
              : eventLobbyRoomsCount
              ? BREAKOUTROOMS
              : CLASSROOMS;
            history.replace(`${communityCode ? `/${communityCode}` : ""}${STREAM}/${eventCode}${navigateTo}`);
            dispatch(actions.changeStatusOptions(null));
          }
          return;
        }
        default:
          return;
      }
    }
  }, [
    eventStatus,
    communityCode,
    eventCode,
    roomCode,
    history,
    dispatch,
    communitySubscriptions,
    features,
    exitEventHandler,
    allowEnded,
    changingStatus,
    changeStatusOptions,
    isAuthenticated,
    eventLobbyRoomsCount,
    lobbyPathInfo,
    eventType,
    event?.start_event_option,
    event?.classrooms,
  ]);

  useEffect(() => {
    const isWatchPartyWithoutRoom = isMatchedPath(history.location.pathname, [`${STREAM}/:eventCode${WATCH_PARTY}`]);
    if (isWatchPartyWithoutRoom && roomCode) {
      dispatch(exitRoom.success());
      history.push(`${communityCode ? `/${communityCode}` : ""}${STREAM}/${eventCode}`);
    }
  }, [pathName, history, roomCode, communityCode, eventCode, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setPreJoinPage(true));
      dispatch(knockJoinMeeting.cancel());
    };
  }, [dispatch]);

  useEffect(() => {
    setWaitingMembersCount(waitingToJoinMembers.length);
    if (waitingToJoinMembers.length > waitingMembersCount && canShowKnockPopup) {
      setShowWaitingMembersToJoin(true);
    } else if (waitingToJoinMembers.length === 0) {
      setShowWaitingMembersToJoin(false);
    }
  }, [waitingToJoinMembers, canShowKnockPopup, waitingMembersCount]);

  useEffect(() => {
    if (eventId && changingStatus && !showStatusChangingCounter) {
      setImmediate(() => {
        setShowStatusChangingCounter(true);
      });
    } else if (!changingStatus && showStatusChangingCounter) {
      setShowStatusChangingCounter(false);
    }
  }, [eventId, changingStatus, showStatusChangingCounter]);

  useEffect(() => {
    if (allowEnded && eventStatus === EventStatus.ended && !showEndingCounter) {
      setImmediate(() => {
        setShowEndingCounter(true);
      });
    } else if (!allowEnded && showEndingCounter) {
      setShowEndingCounter(false);
    }
  }, [eventStatus, allowEnded, showEndingCounter]);

  const replyGreeterMember = useCallback(
    (channelId?: string, showChatTab = true) => {
      if (!event) {
        return;
      }
      if (channelId) {
        dispatch(setActiveChannel(channelId));
      } else {
        const greeter = event.greeters[0];
        if (!greeter) {
          return;
        }
        startChatHandler(greeter.id);
      }
      if (showChatTab) {
        dispatch(
          actions.updateEventSettings({
            activeDashboardTab: EventDashboardTabs.chat,
            selectedTab: DiscussionTab.personal,
          }),
        );
      }
    },
    [dispatch, event, startChatHandler],
  );

  const startChat = useCallback(
    (memberId: string | number) => {
      startChatHandler(memberId);
      dispatch(
        actions.updateEventSettings({
          activeDashboardTab: EventDashboardTabs.chat,
          selectedTab: DiscussionTab.personal,
        }),
      );
    },
    [startChatHandler, dispatch],
  );

  const startChatAnonymous = useCallback(
    (greeter_id: number) => {
      if (!eventId) {
        return;
      }
      if (memberCID) {
        dispatch(
          createAnonymousChannelWithGreeter.request({
            greeter_id,
            meeting_id: eventId,
            anonymous_id: memberCID,
          }),
        );
        dispatch(
          actions.updateEventSettings({
            activeDashboardTab: EventDashboardTabs.chat,
            selectedTab: DiscussionTab.personal,
          }),
        );
      }
    },
    [dispatch, eventId, memberCID],
  );

  const replyGreeterAnonymous = useCallback(() => {
    if (!event) {
      return;
    }
    const greeter = event.greeters[0];
    if (!greeter) {
      dispatch(notificationActions.error("Event has no greeters"));
      return;
    }
    startChatAnonymous(greeter.id);
  }, [event, dispatch, startChatAnonymous]);

  const replyGreeterHandler = useCallback(
    (channelId?: string) => {
      if (isAuthenticated) {
        replyGreeterMember(channelId);
      } else {
        replyGreeterAnonymous();
      }
    },
    [isAuthenticated, replyGreeterMember, replyGreeterAnonymous],
  );

  const endEvent = useCallback(() => dispatch(actions.setAllowEnded(false)), [dispatch]);
  const changeEventStatus = useCallback(() => dispatch(actions.setChangingStatus(false)), [dispatch]);

  const clearMemberToFind = () => {
    if (memberToFind) {
      dispatch(
        actions.updateEventSettings({
          memberToFind: null,
        }),
      );
    }
  };

  const onAuthSuccess = useCallback(() => {
    if (memberCID) {
      dispatch(actions.leaveEvent(memberCID));
    }
    setTimeout(() => {
      window.location.reload();
    }, 50);
  }, [dispatch, memberCID]);

  const onNotificationAnnouncementClick = useCallback(
    (data: INotificationAnnouncement) => {
      dispatch(
        actions.updateEventSettings({
          announcementToFind: data.id,
          activeDashboardTab: EventDashboardTabs.announcements,
        }),
      );
    },
    [dispatch],
  );

  const isMobile = useIsMobile();

  const closeTour = useCallback(() => {
    setShowTour(false);
    if (showMeAround) {
      changeShowMeAroundTour();
    }
  }, [changeShowMeAroundTour, showMeAround]);

  const confirmRequestUnban = useCallback(() => {
    const mainGreeter = event?.greeters.find(g => g.position === 0);
    if (mainGreeter && mainGreeter.id !== member?.id) {
      startChat(mainGreeter.id);
    }
    setOpenRestrictionAlert(false);
  }, [event, startChat, member]);

  return (
    <div className={classnames("eventContainer")} onClick={clearMemberToFind}>
      {notification && <TopNotification onClose={() => setNotification("")}>{notification}</TopNotification>}
      {showEndingCounter && (
        <TopNotification>
          <div className="eventHeader-countdown-notification">
            <p>{`This ${capitalize(eventTypeName)} will end in`}</p>
            <CountdownTimer initTime={60500} onTimeHasCome={endEvent} />
          </div>
        </TopNotification>
      )}

      {showStatusChangingCounter && (
        <TopNotification>
          <div className="eventHeader-countdown-notification">
            <p>{topNotificationText}</p>
            <CountdownTimer initTime={10500} onTimeHasCome={changeEventStatus} showHours={false} />
          </div>
        </TopNotification>
      )}
      {!isMobile ? (
        <EventHeader
          onLogin={openAuthHandler}
          user={currentUser}
          event={event}
          member={member ? member : null}
          hideButtons={isBlocked}
          settings={settings}
          eventTypeMeeting={eventTypeMeeting}
        />
      ) : null}
      <div
        className={classnames("eventContainer-wrapper", {
          empty: !!eventError || isBlocked || knockRejectedEmptyState,
        })}
      >
        {!isMobile ? (
          (isBlocked || knockRejectedEmptyState) && !eventError ? (
            <div className="empty-wrapper">
              <EmptyText
                message={
                  <div className="event-empty">
                    <h3 className="event-empty-title">
                      {knockRejected ? "Your request has not been approved" : "Sorry!"}
                    </h3>
                    <p className="event-empty-body">
                      {participantsLimitExceeded
                        ? `This ${eventTypeName} is full`
                        : knockRejected
                        ? `If you were invited to this ${eventTypeName}, please contact the person who invited you`
                        : `The host has removed you from this ${eventTypeName}`}
                    </p>
                  </div>
                }
                show
              />
            </div>
          ) : (
            event && (
              <div className="eventContainer-body">
                {(showMeAround ||
                  (!!event.has_platform_tour &&
                    event.type !== EventType.meeting &&
                    ((!currentUser && !member && !localStorage.getItem("showEventTour")) ||
                      ((currentUser || member) && !localStorage.getItem("showEventTour"))))) && (
                  <EventTourPreview
                    theme={"dark"}
                    showMeAround={showMeAround}
                    show={showTour || showMeAround}
                    closeTour={closeTour}
                    skipTour={closeTour}
                    countSteps={4}
                  />
                )}
                <div className="eventContainer-content">
                  {member || !eventTypeMeeting ? (
                    eventTypeMeeting && preJoinPage && event.status !== EventStatus.ended ? (
                      hasMeetingFeature ? (
                        <PreJoinPage event={event} canShowKnockPopup={canShowKnockPopup} />
                      ) : (
                        <EventBlocker showUpgrade={!!isCommunityManager} leaveEvent={exitEventHandler} />
                      )
                    ) : (
                      <Suspense
                        fallback={
                          <div className="stream-container">
                            <Loader />
                          </div>
                        }
                      >
                        <Switch>
                          <Route
                            path={`${STREAM}/:eventCode`}
                            exact
                            component={!eventTypeMeeting ? VideoStreamContainer : EightToEightContainer}
                          />
                          <Route
                            path={`${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`}
                            exact
                            component={VideoStreamContainer}
                          />
                          <Route path={`${STREAM}/:eventCode${CLASSROOMS}`} exact component={ClassroomsContainer} />
                          <Route
                            path={`${STREAM}/:eventCode${CLASSROOMS}/:roomCode`}
                            exact
                            component={EightToEightContainer}
                          />

                          <Route path={`${STREAM}/:eventCode${BREAKOUTROOMS}`} exact component={TableListContainer} />
                          <Route
                            path={`${STREAM}/:eventCode${BREAKOUTROOMS}/:roomCode`}
                            exact
                            component={BreakoutRoom}
                          />
                          <Route
                            path={`/:communityCode${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`}
                            exact
                            component={VideoStreamContainer}
                          />
                          <PrivateRoute
                            path={`/:communityCode${STREAM}/:eventCode`}
                            exact
                            component={VideoStreamContainer}
                          />
                          <PrivateRoute
                            path={`/:communityCode${STREAM}/:eventCode${BREAKOUTROOMS}`}
                            exact
                            component={TableListContainer}
                          />
                          <PrivateRoute
                            path={`/:communityCode${STREAM}/:eventCode${BREAKOUTROOMS}/:roomCode`}
                            exact
                            component={BreakoutRoom}
                          />
                          <PrivateRoute
                            path={`/:communityCode${STREAM}/:eventCode${CLASSROOMS}/:roomCode`}
                            exact
                            component={EightToEightContainer}
                          />
                        </Switch>
                      </Suspense>
                    )
                  ) : hasMeetingFeature ? (
                    <AnonymousPage event={event} />
                  ) : (
                    <EventBlocker showUpgrade={false} leaveEvent={exitEventHandler} />
                  )}

                  <Suspense fallback={<></>}>
                    <EventDiscussions
                      onClose={handleCloseTab}
                      open={activeDashboardTab === EventDashboardTabs.chat}
                      onLogin={openAuthHandler}
                      onSignup={signUpHandler}
                      onAuthSuccess={onAuthSuccess}
                      onReplyGreeterCustomCh={replyGreeterHandler}
                      openRestrictionAlert={() => setOpenRestrictionAlert(true)}
                    />
                  </Suspense>
                  {activeDashboardTab === EventDashboardTabs.members && (
                    <EventMembersDialog
                      members={eventMembers}
                      communityMembers={communityMembers}
                      onClose={handleCloseTab}
                      open={activeDashboardTab === EventDashboardTabs.members}
                      startChat={startChat}
                      startChatAnonymous={startChatAnonymous}
                      canShowJoinRequest={canShowKnockPopup}
                      openRestrictionAlert={() => setOpenRestrictionAlert(true)}
                    />
                  )}
                  {activeDashboardTab === EventDashboardTabs.announcements && (
                    <EventAnnouncementsDialog
                      announcements={announcements}
                      announcementTemplates={announcementTemplates}
                      onClose={handleCloseTab}
                      event={event}
                      open={activeDashboardTab === EventDashboardTabs.announcements}
                    />
                  )}
                  {activeDashboardTab === EventDashboardTabs.welcome && (
                    <EventWelcomeDialog
                      event={event}
                      open={activeDashboardTab === EventDashboardTabs.welcome}
                      onClose={handleCloseTab}
                      onGreeterReply={replyGreeterHandler}
                      onShowMeAround={changeShowMeAroundTour}
                    />
                  )}
                  {activeDashboardTab === EventDashboardTabs.hostNotes && (
                    <EventHostNotes
                      open={activeDashboardTab === EventDashboardTabs.hostNotes}
                      onClose={handleCloseTab}
                    />
                  )}
                  {EventDashboardTabs.auth && !isAuthenticated && (
                    <EventAuthDialog
                      onClose={handleCloseTab}
                      onAuthSuccess={onAuthSuccess}
                      open={activeDashboardTab === EventDashboardTabs.auth}
                    />
                  )}
                  {activeDashboardTab === EventDashboardTabs.signUp && !isAuthenticated && (
                    <EventSignUpDialog
                      onClose={handleCloseTab}
                      onSuccess={onAuthSuccess}
                      open={activeDashboardTab === EventDashboardTabs.signUp}
                    />
                  )}
                  {activeDashboardTab === EventDashboardTabs.editProfile && (
                    <EventEditProfileDialog
                      onClose={handleCloseTab}
                      open={activeDashboardTab === EventDashboardTabs.editProfile}
                    />
                  )}
                  <NotificationPopup onClick={onNotificationAnnouncementClick} />
                  <NotificationAnnouncement
                    onClick={onNotificationAnnouncementClick}
                    millisecondsToHide={10000}
                    hideOnClick={true}
                  />
                  {activeDashboardTab === EventDashboardTabs.polls && (
                    <EventPollsDialog
                      open={activeDashboardTab === EventDashboardTabs.polls}
                      event={event}
                      onClose={handleCloseTab}
                    />
                  )}
                  {showWaitingMembersToJoin && member && !preJoinPage && (
                    <KnockJoinEventPopup closePopup={() => setShowWaitingMembersToJoin(false)} />
                  )}
                  <AlertDialog
                    open={knockRejected}
                    title="The Host did not grant you access"
                    message="If you were invited to this meeting, please contact the person who invited you."
                    onConfirm={() => setKnockRejectedEmptyState(true)}
                    mode="info"
                    confirmText="Got It"
                    variant="brown"
                    dialogClassName="knockRejected-alert"
                  />
                  <AlertDialog
                    title={
                      currentRestrictedMember?.restriction_type === EMemberRestriction.chat_timeout
                        ? "You are temporarily banned from Chat"
                        : "You are banned from Chat"
                    }
                    message={
                      currentRestrictedMember?.restriction_type === EMemberRestriction.chat_timeout
                        ? "The Host has banned you from the chat temporarily. You are unable to participate in this event's chat for 10 minutes."
                        : "The Host has banned you from the chat. You are unable to participate in this event's chat until a Host unbans you."
                    }
                    open={openRestrictionAlert}
                    onConfirm={confirmRequestUnban}
                    onCancel={() => setOpenRestrictionAlert(false)}
                    confirmText="Request Unban"
                    cancelText="Got It"
                    mode="cancel"
                    variant="brown"
                  />
                </div>
              </div>
            )
          )
        ) : (
          <MobileWrapper isBlocked={isBlocked} eventError={eventError} />
        )}
        {eventError && !isMobile && (
          <div className="empty-wrapper">
            <EmptyText message={getEmptyText()} show={!!eventError} />
          </div>
        )}
        {(eventError || isBlocked) &&
          !isMobile &&
          !isAuthenticated &&
          (activeDashboardTab === EventDashboardTabs.auth || activeDashboardTab === EventDashboardTabs.signUp) && (
            <>
              <EventAuthDialog onAuthSuccess={onAuthSuccess} open={activeDashboardTab === EventDashboardTabs.auth} />
              <EventSignUpDialog
                onClose={handleCloseTab}
                onSuccess={onAuthSuccess}
                open={activeDashboardTab === EventDashboardTabs.signUp}
              />
            </>
          )}
      </div>
      {!isMobile && <NextEventCard onNextEvent={() => exitEventHandler(false)} />}
      {!isMobile && event && (
        <EventDashboard
          showActions={showDashboardActions}
          activeTab={activeDashboardTab}
          onChange={handleChangeActiveTab}
          onCloseEvent={exitEventHandler}
          event={event}
        />
      )}
    </div>
  );
};

export default EventContainer;
