import React from "react";

import { SermonCard, IEvent } from "containers/Sermons";

export interface IEventsCardList {
  sermons: IEvent[];
}

const SermonsCardList: React.FC<IEventsCardList> = ({ sermons }) => {
  return (
    <>
      {sermons &&
        sermons
          .sort((a, b) => new Date(a.starting_at).getTime() - new Date(b.starting_at).getTime() || a.id - b.id)
          .filter(s => !s.is_default)
          .map((sermon, idx) => <SermonCard sermon={sermon} key={sermon.id || idx} />)}
    </>
  );
};

export default SermonsCardList;
