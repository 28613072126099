import { getUserLocales } from "get-user-locale";
import moment from "moment";

const userLocales = getUserLocales();

const firstUserLocale = userLocales[0] || "";
const lowerFirstUserLocale = firstUserLocale.toLowerCase();
if (lowerFirstUserLocale !== "en-us") {
  let promise: Promise<any> = Promise.reject();
  if (lowerFirstUserLocale.startsWith("de")) {
    // @ts-ignore
    promise = import("moment/locale/de")
      .then(() => {
        moment.locale("de");
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(`Loading moment locale error: ${error}`);
        throw error;
      });
  } else if (lowerFirstUserLocale.startsWith("fr")) {
    // @ts-ignore
    promise = import("moment/locale/fr")
      .then(() => {
        moment.locale("fr");
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(`Loading moment locale error: ${error}`);
        throw error;
      });
  }

  promise.catch(() => {
    // @ts-ignore
    import("moment/locale/en-gb")
      .then(() => {
        moment.locale("en-gb");
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(`Loading default (en-gb) moment locale error: ${error}`);
      });
  });
} else {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "%ds",
      ss: "%ds",
      m: "%dm",
      mm: "%dm",
      h: "%dh",
      hh: "%dh",
      d: "%dd",
      dd: "%dd",
      M: "%dmo",
      MM: "%dmo",
      y: "%dy",
      yy: "%dy",
    },
  });
}
