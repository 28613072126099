import React from "react";

import "./copyItem.scss";

interface ICopyItem {
  copyText: string;
  label?: string;
  onClick: () => void;
}

const CopyItem: React.FC<ICopyItem> = ({ copyText, label, onClick }) => (
  <div className="copy-item">
    {label && <div className="copy-item-label">{label}</div>}
    <div className="copy-item-content" onClick={onClick}>
      {copyText}
    </div>
  </div>
);
export default CopyItem;
