import React from "react";

import adam_graber from "assets/images/testimonials/Adam Graber.png";
import jaclyn_widenmaier from "assets/images/testimonials/Jaclyn Widenmaier.png";
import rob_perry from "assets/images/testimonials/Rob Perry.png";
import deidre_campbell from "assets/images/testimonials/Deidre Campbell.png";
import bryan_katz from "assets/images/testimonials/Bryan Katz.png";
import rev_sharon_moore from "assets/images/testimonials/Rev. Sharon Moore.png";
import bradley_thompson from "assets/images/testimonials/Bradley Thompson.png";

import "./index.scss";

const testimonials = [
  {
    name: "Adam Graber",
    company: "FaithTech",
    text: "Previously, we used to see a 50% drop off when the “offical” portion of our online event ended. But with Altar Live, 80% stuck around afterward to chat for 15+ minutes. Altar’s features afforded us an ability not possible with Zoom or other platforms.",
    avatar: adam_graber,
  },
  {
    name: "Jaclyn Widenmaier",
    company: "Elim Church",
    text: "We used to use Church Online. We switched to Altar Live because our people wanted a community experience online. Altar Live allowed us to have that interactive, engaging platform without having to lower the standards of our service.",
    avatar: jaclyn_widenmaier,
  },
  {
    name: "Rob Perry",
    company: "LifePointe Church",
    text: "As a pastor, I missed being able to go see a person who is maybe just hanging off to the side. I want to be able to talk to just them, not the whole group. Having that kind of capability is so important.",
    avatar: rob_perry,
  },
  {
    name: "Deidre Campbell",
    company: "House of His Glory",
    text: "Having been a fully online only congregation for 5 1/2 years already, engagement has been the primary and most difficult challenge, Altar immediately struck me as such an intuitive, thoughtful product.",
    avatar: deidre_campbell,
  },
  {
    name: "Bryan Katz",
    company: "Peace & Hope",
    text: "We’re really excited to bring to our poeple this new worship experience at Peace and Hope. With Altar Live, we can provide personal, face-to-face experience with others who are worshiping with us.",
    avatar: bryan_katz,
  },
  {
    name: "Rev. Sharon Moore",
    company: "St. Andrew’s Church",
    text: "People use Zoom for work, for meetings, family reunions etc. We need something different that is a departure from what everyone is using daily.",
    avatar: rev_sharon_moore,
  },
  {
    name: "Bradley Thompson",
    company: "New Life Church",
    text: "Honestly, I have not had this kind of support with any of our other programs. Altar is on its own level for customer service. You keep us in the loop with bugs we report, no other company is doing that for us.",
    avatar: bradley_thompson,
  },
];

function Testimonials() {
  return (
    <div className="testimonials-wrapper">
      {testimonials.map(t => (
        <div className="testimonial" key={t.name}>
          <img src={t.avatar} alt={t.name} />
          <div className="person">
            <div className="person-name">{t.name}</div>
            <div className="person-company">{t.company}</div>
            <div className="person-quote">{t.text}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Testimonials;
