import { call, put, select, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import { getMember, login, logout, registration } from "../../Auth/store/actions";
import { NamesOfParentRoutes } from "../../../constants";
import history from "../../../shared/history/history";
import {
  ICheckUserRequest,
  ICheckVerifyUserData,
  ICommunityData,
  IConfirmationEmailData,
  ICreateOnboardingUser,
  IRegisterMemberRequest,
  IUpdateOnboardingUser,
} from "../interfaces";
import { handleFirebaseEvent } from "../../../utils/firebase";
import { getCommunityData, getOnboardingUser } from "./selectors";
import { setOnboardingUser } from "./actions";
import { startLoading, stopLoading } from "../../App/store/actions";
import { notificationActions } from "../../Notifications/store/actions";
import userApi from "../../User/api";
import communityApi from "../../Community/api";
import api from "../api";

import { actions, constants } from "./";

import { ECookieNames, getCookieByName } from "shared";
import { sendConversionEvent } from "utils/googleAdv";
import { getUser } from "containers/Auth/store/selectors";
import { trackNewCommunityEvent } from "utils/fbPixel";
import {
  EFirebaseSignUpMethod,
  FIREBASE_EVENTS,
  FirebaseNewCommunityPayload,
  FirebaseSignUpPayload,
} from "shared/interfaces/Firebase";
import { IMember, IPrivateInvitation } from "containers/Member/interfaces";
import { IUser } from "containers/Auth/interfaces";
import { ICommunityItem, ICreateCommunityRequest, IUpdateCommunity } from "containers/Community/interfaces";

const { ONBOARDING } = NamesOfParentRoutes;

function* checkInvitationSaga({ payload }: { payload: string }) {
  try {
    yield put(startLoading());
    const community: ICommunityData = yield call(api.checkLinkByHash, payload);
    yield put(actions.checkInvitation.success(community));
  } catch (error: any) {
    yield put(notificationActions.error("Invitation link has expired", "The invitation link has expired please login"));
    yield put(logout.request({ reload: true }));
  } finally {
    yield put(stopLoading());
  }
}

function* checkEmailSaga({ payload: { email, callback, allowInactive } }: { payload: ICheckUserRequest }) {
  try {
    yield put(startLoading());
    const { isActive, user, token }: { isActive: boolean; user: IUser | null; token: string | null } = yield call(
      api.checkUserByEmail,
      email,
    );
    yield put(setOnboardingUser(user));

    if (isActive || (!allowInactive && user)) {
      yield put(actions.checkEmail.success(true));
    } else {
      yield put(actions.checkEmail.success(false));
      callback && typeof callback === "function" && callback(user, token);
    }
  } catch (error: any) {
    if (error.code === 409) {
      yield put(actions.checkEmail.success(true));
    } else {
      yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
    }
  } finally {
    yield put(stopLoading());
  }
}

function* sendUserConfirmationEmailSaga({
  payload: { email, hash, withCode, redirectTo, callback, communityCreation, showNotification = true },
}: {
  payload: IConfirmationEmailData;
}) {
  try {
    yield put(startLoading());
    yield call(api.sendUserConfirmationEmail, { email, hash, withCode, redirectTo, communityCreation });
    if (showNotification) {
      yield put(notificationActions.success("The email was successfully sent"));
    }
    callback && callback();
  } catch (error: any) {
    yield put(notificationActions.error("Cannot send the Email", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* checkEmailIsConfirmedSaga({ payload: { token, callback } }: AnyAction) {
  try {
    yield put(startLoading());
    const invitation: IPrivateInvitation = yield call(api.checkLinkByHash, token);

    if (invitation && invitation.user_confirmed) {
      callback && callback();
    } else {
      yield put(notificationActions.error("Please confirm your email address first"));
    }
  } catch (error: any) {
    yield put(notificationActions.error("Cannot check confirmation", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* createProfileSaga({ payload }: ReturnType<typeof actions.createProfile.request>) {
  const { data, token, shouldLogin, callback, kind } = payload;
  try {
    yield put(startLoading());
    const hubSpotUserToken = getCookieByName(ECookieNames.hubspotutk);
    const userData: { user_id: number; user_confirmed: boolean } = yield call(
      api.createProfile,
      token,
      { ...data, hubSpotUserToken },
      kind,
    );
    const { user_id, user_confirmed } = userData;
    yield put(actions.createProfile.success(user_id));
    yield put(registration.success(user_id));
    const { email, password } = data;
    if (user_confirmed) {
      const firebaseData: FirebaseSignUpPayload = {
        method: EFirebaseSignUpMethod.email,
        name: `${data.first_name} ${data.last_name}`,
        email: data.email,
        user_id,
        is_admin: 0,
      };
      const communityData: ICommunityData | null = yield select(getCommunityData());
      if (communityData) {
        const { community, member } = communityData;
        firebaseData.community_name = community.name;
        firebaseData.community_id = community.id;
        if (member) {
          firebaseData.is_admin = member.should_create_as_manager ? 1 : 0;
        }
      }
      handleFirebaseEvent(FIREBASE_EVENTS.SIGN_UP, firebaseData);

      if (shouldLogin) {
        yield put(login.request({ email, password, redirectTo: ONBOARDING }));
        history.push(ONBOARDING);
      }
    } else {
      callback && callback();
    }
  } catch (error: any) {
    yield put(notificationActions.error("Cannot create Profile", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* registerMemberSaga({ payload: { data, callback } }: { payload: IRegisterMemberRequest }) {
  try {
    yield put(startLoading());
    const member: IMember = yield call(api.registerMemberForNewUser, data);
    yield put(actions.registerMember.success(member));
    yield put(getMember.success(member));
    callback && callback();
  } catch (error: any) {
    yield put(notificationActions.error("Cannot create Profile", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* checkUserIsVerifiedSaga({
  payload: { data, withoutRedirect, callback, skipActions },
}: {
  payload: { data: ICheckVerifyUserData; withoutRedirect?: boolean; callback?: () => void; skipActions?: boolean };
}) {
  try {
    yield put(startLoading());
    const { userId, email, password } = data;
    const user: { status: "Found" | "Not found" } = yield call(api.checkUserVerification, userId);
    if (user.status === "Found") {
      yield put(
        login.request({
          email,
          password,
          redirectTo: withoutRedirect ? "" : ONBOARDING,
          callback,
          skipActions,
        }),
      );
      if (!withoutRedirect) {
        history.push(ONBOARDING);
      }
    } else {
      yield put(
        notificationActions.error(
          "Activation Required",
          `Please open your ${email} inbox and look for activation email from Altar.`,
        ),
      );
    }
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* createUserByEmail({ payload: { email, callback } }: { payload: ICreateOnboardingUser }) {
  try {
    yield put(startLoading());
    const user: IUser = yield call(userApi.createUserByEmail, { email });
    const { token } = user;
    yield put(actions.createUserByEmail.success(user));
    callback && callback(token);
  } catch (error: any) {
    yield put(notificationActions.error("Cannot create User", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* updateOnboardingUser({ payload: { user, callback } }: { payload: IUpdateOnboardingUser }) {
  try {
    yield put(startLoading());
    const { id, email } = user;
    yield call(userApi.updateInactiveUser, id, { email });
    yield put(actions.updateOnboardingUser.success(user));
    callback && callback();
  } catch (error: any) {
    yield put(notificationActions.error("Cannot update User", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* createCommunitySaga({ payload: { community, callback } }: { payload: ICreateCommunityRequest }) {
  try {
    yield put(startLoading());
    const hubSpotUserToken = getCookieByName(ECookieNames.hubspotutk);
    const newCommunity: ICommunityItem = yield call(communityApi.createCommunity, { ...community, hubSpotUserToken });
    const onboardingUser: IUser | null = yield select(getOnboardingUser());
    const currentUser: IUser | null = yield select(getUser());
    const firebaseData: FirebaseNewCommunityPayload = {
      community_name: newCommunity.name,
      community_id: newCommunity.id,
      country: newCommunity.country,
      state: newCommunity.state,
      city: newCommunity.city,
      is_admin: 1,
    };
    if (onboardingUser) {
      firebaseData.user_id = onboardingUser.id;
      firebaseData.email = onboardingUser.email;
    }

    handleFirebaseEvent(FIREBASE_EVENTS.NEW_COMMUNITY, firebaseData);
    trackNewCommunityEvent(newCommunity, onboardingUser || currentUser);
    sendConversionEvent();
    yield put(actions.createCommunity.success(newCommunity));
    callback && callback(newCommunity);
  } catch (error: any) {
    yield put(notificationActions.error("Cannot create community", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* updateCommunitySaga({
  payload: { community, callback },
}: {
  payload: { community: IUpdateCommunity; callback?: (community: ICommunityItem) => void };
}) {
  try {
    yield put(startLoading());
    const updatedCommunity: ICommunityItem = yield call(
      communityApi.updateCommunity,
      community.communityId,
      community.community,
    );
    yield put(actions.updateCommunity.success(updatedCommunity));
    callback && callback(updatedCommunity);
  } catch (error: any) {
    yield put(notificationActions.error("Cannot update community", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* openCommunityInAppSaga({ payload }: { payload: string }) {
  try {
    yield put(startLoading());
    const response: { deepLink: string } = yield call(communityApi.getCommunityAppLink, payload);
    yield put(actions.openCommunityInApp.success(response));
  } catch (error: any) {
    yield put(notificationActions.error("Cannot open app", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* getOnboardingCommunitySaga({ payload }: { payload: string }) {
  try {
    yield put(startLoading());
    const community: ICommunityItem = yield call(communityApi.getCommunity, payload);
    if (community) {
      yield put(actions.getOnboardingCommunity.success(community));
    } else {
      yield put(notificationActions.error("Cannot fetch community"));
    }
  } catch (error: any) {
    yield put(actions.getOnboardingCommunity.failure());
  } finally {
    yield put(stopLoading());
  }
}

export default function* onboardingSagas() {
  yield takeLatest(actions.checkInvitation.request, checkInvitationSaga);
  yield takeLatest(actions.checkEmail.request, checkEmailSaga);
  yield takeLatest(actions.sendUserConfirmationEmail.request, sendUserConfirmationEmailSaga);
  yield takeLatest(constants.OnboardingActionTypes.CHECK_EMAIL_IS_CONFIRMED_REQUEST, checkEmailIsConfirmedSaga);
  yield takeLatest(actions.checkUserIsVerified.request, checkUserIsVerifiedSaga);
  yield takeLatest(actions.createProfile.request, createProfileSaga);
  yield takeLatest(actions.registerMember.request, registerMemberSaga);
  yield takeLatest(actions.createUserByEmail.request, createUserByEmail);
  yield takeLatest(actions.updateOnboardingUser.request, updateOnboardingUser);
  yield takeLatest(actions.createCommunity.request, createCommunitySaga);
  yield takeLatest(actions.updateCommunity.request, updateCommunitySaga);
  yield takeLatest(actions.openCommunityInApp.request, openCommunityInAppSaga);
  yield takeLatest(actions.getOnboardingCommunity.request, getOnboardingCommunitySaga);
}
