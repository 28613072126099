import { useState, useEffect } from "react";

interface WindowHookState {
  width?: number;
  height?: number;
}

const useElementSize = (element: HTMLDivElement | null, debounceDelay = 0) => {
  const [elementSize, setElementSize] = useState<WindowHookState>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    let observer: any;
    let timer: number;
    let animationFrame: any;
    const updateSize = (entries?: ResizeObserverEntry[]) => {
      animationFrame = window.requestAnimationFrame(() => {
        const container = entries ? (entries[0]?.target as HTMLElement) : element;
        if (container) {
          timer = setTimeout(() => {
            setElementSize({
              width: container.offsetWidth,
              height: container.offsetHeight,
            });
          }, debounceDelay);
        }
      });
    };
    if (element) {
      observer = new ResizeObserver(updateSize);
      observer.observe(element);
      updateSize();
    }
    return () => {
      if (animationFrame) {
        window.cancelAnimationFrame(animationFrame);
      }
      clearTimeout(timer);
      observer?.unobserve(element);
    };
  }, [element, debounceDelay]);
  return elementSize;
};

export default useElementSize;
