import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import classnames from "classnames";

import { IEventForm } from "containers/Sermons/interfaces";
import { AlertDialog, SquareButton, UploadDropzone } from "shared";
import { uploadToS3 } from "shared/stores/aws/actions";
import { EFileType } from "shared/interfaces/File";
import { getUploadProgress } from "shared/stores/aws/selectors";
import upload2 from "assets/icons/upload2.svg";
import success_mark from "assets/icons/success_mark.svg";
import loader from "assets/icons/loader.gif";

import "./styles.scss";

type Props = {
  saveDraft: () => void;
};

const AltarVideoManager: React.FC<Props> = ({ saveDraft }) => {
  const [uploadFileAfterSave, setUploadFileAfterSave] = useState<File | null>(null);
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [openFilesDialog, setOpenFilesDialog] = useState(false);
  const [showReplaceAlert, setShowReplaceAlert] = useState(false);

  const {
    values: { id, stream_url },
    errors,
    setFieldValue,
    setFieldError,
  } = useFormikContext<IEventForm>();

  const uploadProgress = useSelector(getUploadProgress());

  const dispatch = useDispatch();

  const upload = useCallback(
    (file?: File) => {
      setShowReplaceAlert(false);

      const finalFile = file || uploadFile;
      if (finalFile) {
        setFieldError("stream_url", "");
        if (id) {
          dispatch(uploadToS3.success(0));
          dispatch(
            uploadToS3.request({
              file: finalFile,
              name: finalFile.name,
              type: EFileType.eventVideo,
              meeting_id: id,
            }),
          );
        } else {
          setUploadFileAfterSave(finalFile);
          saveDraft();
        }
      }
      setUploadFile(null);
    },
    [dispatch, id, saveDraft, setFieldError, uploadFile],
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const [file] = acceptedFiles;
      if (stream_url) {
        setUploadFile(file);
        setOpenFilesDialog(false);
        setShowReplaceAlert(true);
      } else {
        upload(file);
      }
    },
    [upload, stream_url],
  );

  useEffect(() => {
    if (id && uploadFileAfterSave) {
      onDrop([uploadFileAfterSave]);
      setUploadFileAfterSave(null);
    }
  }, [id, onDrop, uploadFileAfterSave]);

  const videoTranscoded = stream_url && /.*\.m3u8$/.test(stream_url);

  return (
    <div className="altar-video-manager">
      <AlertDialog
        mode="confirm"
        open={showReplaceAlert}
        confirmText="Replace"
        title="Replace Video"
        message="Are you sure that you want to replace this video with another one? The original video will be deleted."
        onCancel={() => setShowReplaceAlert(false)}
        onConfirm={() => {
          setFieldValue("stream_url", "");
          setImmediate(() => upload());
        }}
        dialogClassName="delete-video-alert"
        confirmClassName="delete-button"
      />
      {uploadProgress !== null ? (
        <div className={classnames("video-in-progress", { error: errors.stream_url })}>
          Please wait{errors.stream_url ? " for the upload to finish" : "..."}
        </div>
      ) : (
        <>
          <UploadDropzone
            onDrop={onDrop}
            accept={[".mp4", ".mov"]}
            maxSize={5 * 1024 * 1024 * 1024} // 5GB
            multiple={false}
            className={classnames("altar-video-upload", { hidden: stream_url })}
            error={errors.stream_url}
            openDialog={openFilesDialog}
            onFileDialogCancel={() => setOpenFilesDialog(false)}
          >
            <div className="altar-video-upload-inner">
              <img src={upload2} alt="upload" />
              <span>Choose a File or Drag It Here</span>
            </div>
          </UploadDropzone>
          {stream_url && (
            <>
              <SquareButton
                variant="blue-outline"
                type="button"
                className="replace-video"
                onClick={() => setOpenFilesDialog(true)}
              >
                Replace Video
              </SquareButton>
              <div className="video-status">
                <img src={videoTranscoded ? success_mark : loader} className="mark" alt="mark" />
                <div>
                  {videoTranscoded
                    ? "The video was optimized for attendees on mobile devices and slower internet connections."
                    : "We're optimizing your video for attendees on mobile devices and slow internet connections. The process will run in background and may take up to 1 hour. You can continue and proceed forward."}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AltarVideoManager;
