import React, { useState } from "react";
import classnames from "classnames";

import StreamPreview from "../StreamPreview";
import { EEventPreview } from "../../../../interfaces";

import { CustomSwitchControl, SquareButton } from "shared";

import "./index.scss";

interface Props {
  enabled: boolean;
  switchLabel: string;
  description: string;
  withSwitcher?: boolean;
  onToggle: () => void;
  previewImg: string;
  enabledPreviewBtn: boolean;
  previewType: EEventPreview;
  previewItemCount: number;
}

const RoomsSection: React.FC<Props> = ({
  children,
  enabled,
  onToggle,
  switchLabel,
  description,
  withSwitcher = true,
  previewImg,
  enabledPreviewBtn,
  previewType,
  previewItemCount,
}) => {
  const [openPreview, setOpenPreview] = useState(false);

  return (
    <>
      {openPreview ? (
        <StreamPreview onClose={() => setOpenPreview(false)} previewType={previewType} itemCount={previewItemCount} />
      ) : null}
      <div className={classnames("roomsSection", { open: enabled })}>
        {withSwitcher && (
          <CustomSwitchControl checked={enabled} theme="orange" label={switchLabel} onChange={onToggle} />
        )}
        {!withSwitcher && <p className="roomsSection-label">{switchLabel}</p>}
        <p className="roomsSection-description">{description}</p>
        <div className="roomsSection-content">
          {children}
          <div className="roomsSection-content-preview">
            <img src={previewImg} alt="preview" />
            <SquareButton
              width={272}
              type="button"
              variant={enabledPreviewBtn ? "blue" : "disabled"}
              cursor={enabledPreviewBtn ? "pointer" : "not-allowed"}
              onClick={() => enabledPreviewBtn && setOpenPreview(true)}
            >
              {switchLabel} Preview
            </SquareButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomsSection;
