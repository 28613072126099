import React from "react";

import { IconBase } from "../IconBase";

import { IStyledSVG } from "shared/interfaces/StyledSVG";

export const StyledCircle: React.FC<IStyledSVG> = ({
  style = {},
  fill = "#7D745A",
  width = "140",
  height = "140",
  className = "",
  opacity = 0.1,
}) => (
  <IconBase
    fill="none"
    width={width}
    style={style}
    height={height}
    className={className}
    opacity={opacity}
    viewBox="0 0 140 140"
  >
    <path
      d="M70 140C31.3401 140 0 108.66 0 70C0 31.3401 31.3401 0 70 0C108.66 0 140 31.3401 140 70C140 108.66 108.66 140 70 140Z"
      fill={fill}
    />
  </IconBase>
);

export default StyledCircle;
