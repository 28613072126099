import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { EventMemberTypes } from "../../../constants";
import { updateSermon } from "../../../store/actions";
import { getCurrentRestrictedMember, getRestrictedMembers } from "../../../../Member/store/selectors";
import { AnonymousUserRenamePopup } from "../AnonymousUserRenamePopup";

import { EMemberRestriction, EventStatus, IStreamEventMember, SermonRoles } from "containers/Sermons/interfaces";
import { AlertDialog, ClickOutsideHook, EventAuthAlert, MemberRestrictionAlert, usePermissions } from "shared";
import { actions, selectors } from "containers/Sermons/store";
import { checkMeetingRole, defineRestrictionIconSrc, getEventTypeName } from "containers/Sermons/utils";
import { EventPermissions } from "containers/Auth/interfaces";
import { authentificated, getMember } from "containers/Auth/store/selectors";
import { CustomAvatar } from "containers/Discussions";
import { IMember } from "containers/Member/interfaces/member";
import GreeterTitleAlert from "shared/components/GreeterTitleAlert/GreeterTitleAlert";
import pencilIcon from "assets/icons/pencil.svg";

import "./eventMemberListItem.scss";

interface IEventMemberListItem {
  member: IStreamEventMember;
  own?: boolean;
  startChat?: (member: number) => void;
  startChatAnonymous?: (member: number) => void;
  openRestrictionAlert: () => void;
}

const EventMemberListItem: React.FC<IEventMemberListItem> = ({
  member,
  startChat,
  startChatAnonymous,
  openRestrictionAlert,
  own,
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [removeMemberAlertOpened, setRemoveMemberAlertOpened] = useState(false);
  const [directMessageAlertOpened, setDirectMessageAlertOpened] = useState(false);
  const [messageToAnonAlertOpened, setMessageToAnonAlertOpened] = useState(false);
  const [greeterTitleAlertOpen, setGreeterTitleAlertOpen] = useState(false);
  const [unbanChatAlertOpen, setUnbanChatAlertOpen] = useState(false);
  const [restrictionType, setRestrictionType] = useState<EMemberRestriction | null>(null);
  const [openAnonymousNameAlert, setOpenAnonymousNameAlert] = useState(false);

  const currentEvent = useSelector(selectors.getEvent());
  const currentMember = useSelector(getMember());
  const isAuthenticated = useSelector(authentificated());
  const restrictedMembers = useSelector(getRestrictedMembers());
  const currentRestrictedMember = useSelector(getCurrentRestrictedMember());

  const dispatch = useDispatch();

  const ref = useRef(null);
  const { isOutside } = ClickOutsideHook(ref);

  useEffect(() => {
    if (!imageUrl) {
      if (member) {
        if (member.image_url) {
          setImageUrl(member.image_url);
        }
        if (member?.member?.image_url) {
          setImageUrl(member?.member?.image_url);
        }
      }
    }
  }, [member, imageUrl, setImageUrl]);

  useEffect(() => {
    if (isOutside) {
      setIsOptionsOpen(false);
    }
  }, [isOutside]);

  const fullName = useMemo(
    () => (member.first_name ? `${member.first_name} ${member.last_name}` : member.guestName),
    [member],
  );

  const editPermissions = useMemo(() => [EventPermissions.edit], []);

  const canEdit = usePermissions(editPermissions, currentEvent?.community_id);

  const canRemoveMembers = useMemo(
    () =>
      currentEvent?.status !== EventStatus.ended &&
      (canEdit ||
        (currentEvent && currentMember?.id && checkMeetingRole(currentEvent, currentMember, [SermonRoles.greeter]))),
    [canEdit, currentMember, currentEvent],
  );
  const greeter = useMemo(() => {
    return currentEvent?.greeters.find(greeter => greeter.id === member.memberId);
  }, [currentEvent, member]);
  const isCurrentMemberGreeter = useMemo(() => {
    return currentEvent?.greeters.some(greeter => greeter.id === currentMember?.id);
  }, [currentEvent, currentMember]);

  const restrictedMember = useMemo(() => {
    return restrictedMembers.find(m => m.member_id === member.memberId);
  }, [member, restrictedMembers]);

  const restrictionIconSrc = useMemo(() => {
    return defineRestrictionIconSrc(restrictedMember?.restriction_type);
  }, [restrictedMember]);

  const editAnonymousName = useMemo(() => {
    return member?.type === EventMemberTypes.guest ? (
      <>
        <AnonymousUserRenamePopup
          opened={openAnonymousNameAlert}
          onClose={() => setOpenAnonymousNameAlert(!openAnonymousNameAlert)}
        />
        <div
          className="eventMemberListItem-data-edit"
          onClick={() => setOpenAnonymousNameAlert(!openAnonymousNameAlert)}
        >
          <img src={pencilIcon} alt="edit name" />
        </div>
      </>
    ) : null;
  }, [member?.type, openAnonymousNameAlert]);

  const memberTitle = greeter?.title ? greeter.title : own ? "(You)" : "";

  const removeMemberHandler = useCallback(() => {
    dispatch(actions.removeMemberFromEvent(member.memberId));
  }, [dispatch, member]);

  const startChatHandler = useCallback(() => {
    if (!member) {
      return;
    }
    if (currentRestrictedMember && !greeter) {
      return openRestrictionAlert();
    }
    if (!isAuthenticated) {
      if (!greeter) {
        return setDirectMessageAlertOpened(true);
      } else {
        return startChatAnonymous && startChatAnonymous(member.memberId);
      }
    }

    if (member.type === EventMemberTypes.guest && !Number.isInteger(member.memberId) && !isCurrentMemberGreeter) {
      setMessageToAnonAlertOpened(true);
    } else {
      startChat && startChat(member.memberId);
    }
  }, [
    member,
    startChat,
    isAuthenticated,
    greeter,
    startChatAnonymous,
    isCurrentMemberGreeter,
    currentRestrictedMember,
    openRestrictionAlert,
  ]);

  const findOnScreenHandler = useCallback(() => {
    if (member.withSeat) {
      dispatch(actions.updateEventSettings({ memberToFind: Number(member.memberId) }));
    }
  }, [dispatch, member]);

  const updateGreeterTitle = useCallback(
    (values: { title: string }) => {
      if (currentEvent && greeter) {
        const updGreeters = currentEvent.greeters.map(updGreeter => {
          return {
            id: updGreeter.id,
            is_admin: updGreeter.is_admin,
            title: updGreeter.id === greeter.id ? values.title : updGreeter.title,
          };
        });
        dispatch(
          updateSermon.request({
            data: {
              greeters: updGreeters,
              id: currentEvent.id,
            },
          }),
        );
      }
      setGreeterTitleAlertOpen(false);
    },
    [dispatch, greeter, currentEvent],
  );

  return (
    <div className="eventMemberListItem">
      <MemberRestrictionAlert
        restrictionType={restrictionType}
        unbanChatAlertOpen={unbanChatAlertOpen}
        member_id={member.memberId}
        setRestrictionType={type => setRestrictionType(type)}
        setUnbanChatAlertOpen={val => setUnbanChatAlertOpen(val)}
      />
      <AlertDialog
        open={removeMemberAlertOpened}
        title="Remove participant"
        message={`Are you sure you want to remove the participant from the ${getEventTypeName(currentEvent?.type)}?`}
        onConfirm={removeMemberHandler}
        onCancel={() => setRemoveMemberAlertOpened(false)}
        mode="confirm"
        confirmText="yes"
        cancelText="cancel"
        variant="brown"
      />
      <GreeterTitleAlert
        opened={greeterTitleAlertOpen}
        onClose={() => setGreeterTitleAlertOpen(false)}
        variant={"brown"}
        initialValue={greeter?.title || ""}
        onConfirm={updateGreeterTitle}
      />
      <AlertDialog
        open={messageToAnonAlertOpened}
        title="Direct Message"
        message={
          <>
            Sorry, you can't direct message anonymous people.
            <br />
            This person will need to log in or sign up for you
            <br />
            to be able to direct message them.
          </>
        }
        onConfirm={() => setMessageToAnonAlertOpened(false)}
        onCancel={() => setMessageToAnonAlertOpened(false)}
        mode="info"
        confirmText="Got it"
        variant="brown"
      />
      <EventAuthAlert
        open={directMessageAlertOpened}
        title="Direct Messages"
        message="You need to be logged in in order to chat with other members. Please sign up or log in."
        onClose={() => setDirectMessageAlertOpened(false)}
      />
      <div className={classnames("eventMemberListItem-data", { own })}>
        <div className="eventMemberListItem-data-image">
          <CustomAvatar
            image={imageUrl || undefined}
            name={fullName}
            color={member.color}
            size={40}
            member={
              member.type !== EventMemberTypes.guest
                ? ({ first_name: member.member?.first_name, last_name: member.member?.last_name } as IMember)
                : void 0
            }
          />
          {restrictionIconSrc ? (
            <div className="eventMemberListItem-image-restriction">
              <img src={restrictionIconSrc} alt="restriction" />
            </div>
          ) : null}
        </div>
        <div className="eventMemberListItem-data-name">{fullName}</div>
        {memberTitle ? <div className="eventMemberListItem-data-title">{memberTitle}</div> : null}
        {own &&
          (greeter ? (
            <div className="eventMemberListItem-data-edit" onClick={() => setGreeterTitleAlertOpen(true)}>
              <img src={pencilIcon} alt="edit title" />
            </div>
          ) : (
            editAnonymousName
          ))}
      </div>
      {!own ? (
        <div
          className={classnames("eventMemberListItem-btn", { opened: isOptionsOpen })}
          ref={ref}
          onClick={() => setIsOptionsOpen(prev => !prev)}
        >
          {isOptionsOpen && (
            <div className="eventMemberListItem-options">
              {canRemoveMembers && !!greeter && (
                <div onClick={() => setGreeterTitleAlertOpen(true)} className="eventMemberListItem-options-item">
                  Edit Host Title
                </div>
              )}
              <div onClick={startChatHandler} className="eventMemberListItem-options-item">
                Direct Message
              </div>
              {canRemoveMembers &&
                member.type === EventMemberTypes.member &&
                (restrictedMember ? (
                  <div onClick={() => setUnbanChatAlertOpen(true)} className="eventMemberListItem-options-item">
                    Unban from Chat
                  </div>
                ) : (
                  <>
                    <div
                      onClick={() => setRestrictionType(EMemberRestriction.chat_timeout)}
                      className="eventMemberListItem-options-item"
                    >
                      Timeout from Chat
                    </div>
                    <div
                      onClick={() => setRestrictionType(EMemberRestriction.chat_ban)}
                      className="eventMemberListItem-options-item"
                    >
                      Ban from Chat
                    </div>
                  </>
                ))}
              {canRemoveMembers && (
                <div className="eventMemberListItem-options-item" onClick={() => setRemoveMemberAlertOpened(true)}>
                  {`Remove from ${getEventTypeName(currentEvent?.type)}`}
                </div>
              )}
              {!!member.withSeat && (
                <div className="eventMemberListItem-options-item" onClick={findOnScreenHandler}>
                  Find on Screen
                </div>
              )}
            </div>
          )}
          <hr />
          <hr />
          <hr />
        </div>
      ) : null}
    </div>
  );
};
export default EventMemberListItem;
