import * as React from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { RoomMemberVideo } from "../RoomMemberVideo";
import { SelfVideo } from "../..";

import { selectStreams } from "containers/BreakoutRooms/store/selectors";
import { StartChatHook } from "shared/hooks";
import { getMember } from "containers/Auth/store/selectors";
import { getEventMembers } from "containers/Sermons/store/selectors";
import { EventDashboardTabs } from "containers/Sermons/interfaces";
import { actions } from "containers/Sermons";
import { DiscussionTab } from "shared/components/Event/EventSideDialog/EventSideDialogHeader/EventSideDialogDiscussionTabs";

import "./index.scss";

const VideoList = () => {
  const streams = useSelector(selectStreams());
  const member = useSelector(getMember());
  const streamMembers = useSelector(getEventMembers());
  const { startChatHandler } = StartChatHook();

  const dispatch = useDispatch();

  const startChat = React.useCallback(
    (memberId: number) => {
      startChatHandler(memberId);
      dispatch(
        actions.updateEventSettings({
          activeDashboardTab: EventDashboardTabs.chat,
          selectedTab: DiscussionTab.personal,
        }),
      );
    },
    [startChatHandler, dispatch],
  );

  return (
    <div className={classnames("video-list-wrapper", { center: !streams.length })}>
      <SelfVideo hasStreams={!!streams.length} member={member} streamMembers={streamMembers} />
      {streams.map(stream => (
        <RoomMemberVideo
          member_stream={stream}
          key={stream.clientId}
          startChatHandler={startChat}
          streamMembers={streamMembers}
        />
      ))}
    </div>
  );
};

export default React.memo(VideoList);
