import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as HttpStatus from "http-status-codes";
import _ from "lodash";

import { actions } from "../../../../Onboarding/store";

import {
  AlertDialog,
  Button,
  CustomFormikField,
  DeleteUserButton,
  EventSideDialog,
  ImageUpload,
} from "shared/components";
import { getImageBase64 } from "shared/utils/dataModifiers";
import { IUserFormWithDuplicates } from "containers/User/interfaces";
import { editProfileSchema } from "containers/User/components/EditProfile/formValidators";
import { getUser } from "containers/Auth/store/selectors";
import { numberValidator } from "shared/constants/validations";
import { ResponseError } from "shared";
import { notificationActions } from "containers/Notifications/store/actions";
import { updateUser } from "containers/User/store/actions";
import { getCommunity } from "containers/Community/store/selectors";
import empty_person_2 from "assets/icons/empty_person_2.svg";

import "./styles.scss";

interface IEventAuthDialog {
  open: boolean;
  onClose?: () => void;
}

const EventEditProfileDialog: React.FC<IEventAuthDialog> = props => {
  const { open, onClose } = props;

  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const currentUser = useSelector(getUser());
  const community = useSelector(getCommunity());

  const dispatch = useDispatch();

  const initValues: IUserFormWithDuplicates = useMemo(
    () => ({
      id: currentUser?.id || 0,
      image_url: currentUser?.image_url || null,
      first_name: currentUser?.first_name || "",
      last_name: currentUser?.last_name || "",
      phone: currentUser?.phone || "",
      email_duplicate: currentUser?.email || "",
      oldPassword: "",
      password_duplicate: "",
      confirmPassword: "",
    }),
    [currentUser],
  );

  const submitHandler = useCallback(
    (data: IUserFormWithDuplicates, { setFieldError }: { setFieldError: (field: string, message: string) => void }) => {
      const { email_duplicate, password_duplicate, image_url, ...rest } = data;
      const isImageChanged = (!!currentUser && currentUser.image_url !== image_url) || (!currentUser && !!image_url);
      const image_base64 = getImageBase64(image_url, isImageChanged);
      dispatch(
        updateUser.request({
          data: { ...rest, image_base64, email: email_duplicate, password: password_duplicate },
          callback: (err: ResponseError) => {
            if (err) {
              if (err.code === HttpStatus.UNPROCESSABLE_ENTITY && err.meta) {
                Object.entries(err.meta).forEach(([field, error]) => {
                  const fieldName = field === "email" ? "email_duplicate" : field;
                  setFieldError(fieldName, error);
                });
              } else {
                dispatch(notificationActions.error("Cannot update member", err.message || ""));
              }
            } else {
              dispatch(notificationActions.success("Your profile information is successfully updated"));
              onClose?.();
            }
          },
          community,
        }),
      );
    },
    [currentUser, dispatch, onClose, community],
  );

  const onCloseConfirmed = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const closeDialogHandler = useCallback(
    (values: IUserFormWithDuplicates) => {
      if (!_.isEqual(values, initValues)) {
        setShowCloseConfirmation(true);
      } else {
        onCloseConfirmed();
      }
    },
    [onCloseConfirmed, initValues],
  );

  useEffect(() => {
    return () => {
      dispatch(actions.checkEmail.success(false));
    };
  }, [dispatch]);
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={editProfileSchema}
      onSubmit={submitHandler}
      initialValues={initValues}
      enableReinitialize
    >
      {({ values, errors, handleChange, handleSubmit, validateField, setFieldValue }) => {
        const onPhoneChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (e.target.value === "" || numberValidator.test(e.target.value)) {
            setFieldValue("phone", e.target.value, true);
          }
        };

        const changeFieldHandler =
          (field: string) => async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            await setFieldValue(field, e.target.value);
            validateField(field);
          };
        return (
          <>
            <AlertDialog
              open={showCloseConfirmation}
              title="Leave without changes"
              message="You have unsaved changes that will be lost if you leave now. Are you sure you want to leave? "
              onConfirm={onCloseConfirmed}
              onCancel={() => setShowCloseConfirmation(false)}
              mode="confirm"
              confirmText="Leave"
              cancelText="Stay"
              variant="brown"
              dialogClassName="eventEditProfileDialog-alert"
            />
            <EventSideDialog title="Edit Profile" open={open} onClose={() => closeDialogHandler(values)}>
              <div className="eventEditProfileDialog">
                <div className="eventEditProfileDialog-container">
                  <Form>
                    <div className="eventEditProfileDialog-row avatar">
                      <ImageUpload
                        showOptions
                        maxMb={2}
                        value={values.image_url}
                        onChange={handleChange("image_url")}
                        backgroundImage={`url(${empty_person_2})`}
                        variant="circle"
                      />
                    </div>
                    <div className="eventEditProfileDialog-row">
                      <CustomFormikField
                        fullWidth
                        label="First Name"
                        name="first_name"
                        theme="dark"
                        errors={errors}
                        value={values.first_name}
                        onChange={changeFieldHandler("first_name")}
                        onBlur={() => validateField("first_name")}
                      />
                    </div>
                    <div className="eventEditProfileDialog-row">
                      <CustomFormikField
                        fullWidth
                        label="Last Name"
                        name="last_name"
                        theme="dark"
                        errors={errors}
                        value={values.last_name}
                        onChange={changeFieldHandler("last_name")}
                        onBlur={() => validateField("last_name")}
                      />
                    </div>
                    <div className="eventEditProfileDialog-row">
                      <CustomFormikField
                        fullWidth
                        label="Email address"
                        name="email_duplicate"
                        theme="dark"
                        errors={errors}
                        value={values.email_duplicate}
                        onChange={handleChange}
                        onBlur={() => validateField("email_duplicate")}
                      />
                    </div>
                    {values.email_duplicate !== initValues.email_duplicate && (
                      <p className="email-help-text">
                        Please check your inbox after saving, the email will be updated only after the confirmation.
                      </p>
                    )}
                    <div className="eventEditProfileDialog-row">
                      <CustomFormikField
                        fullWidth
                        label="Phone"
                        name="phone"
                        theme="dark"
                        errors={errors}
                        value={values.phone}
                        onChange={onPhoneChangeHandler}
                        onBlur={() => validateField("phone")}
                      />
                    </div>
                    {!showPassword && (
                      <div className="eventEditProfileDialog-row password-btn">
                        <Button
                          width={180}
                          type="button"
                          variant="white-text"
                          onClick={() => setShowPassword(prev => !prev)}
                        >
                          Change Password
                        </Button>
                      </div>
                    )}
                    {showPassword && (
                      <>
                        <div className="eventEditProfileDialog-row">
                          <CustomFormikField
                            fullWidth
                            label="Current Password"
                            name="oldPassword"
                            type="password"
                            theme="dark"
                            errors={errors}
                            value={values.oldPassword}
                            autoComplete="new-password"
                            onChange={changeFieldHandler("oldPassword")}
                            onBlur={() => validateField("oldPassword")}
                          />
                        </div>
                        <div className="eventEditProfileDialog-row">
                          <CustomFormikField
                            fullWidth
                            label="New Password"
                            name="password_duplicate"
                            type="password"
                            errors={errors}
                            value={values.password_duplicate}
                            theme="dark"
                            onChange={handleChange}
                            onBlur={() => validateField("password_duplicate")}
                          />
                        </div>
                        <div className="eventEditProfileDialog-row">
                          <CustomFormikField
                            fullWidth
                            label="Confirm New Password"
                            name="confirmPassword"
                            type="password"
                            errors={errors}
                            value={values.confirmPassword}
                            theme="dark"
                            onChange={handleChange}
                            onBlur={() => validateField("confirmPassword")}
                          />
                        </div>
                      </>
                    )}
                    <div className="eventEditProfileDialog-row deleteUser">
                      <div className="eventEditProfileDialog-row deleteUser-divider" />
                      <DeleteUserButton isSeatSamePage={true} alertVariant="brown" closeEdit={onClose} />
                    </div>
                    <div className="eventEditProfileDialog-row submit">
                      <Button radius={9} width={318} variant="purple" type="button" onClick={() => handleSubmit()}>
                        Save Changes
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </EventSideDialog>
          </>
        );
      }}
    </Formik>
  );
};

export default EventEditProfileDialog;
