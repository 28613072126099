import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StreamSettingsModal } from "../StreamSettingsModal";
import { IEvent } from "../../../../Sermons";
import { BreakOutRoomScreenTypes } from "../../../interfaces";

import { actions } from "containers/BreakoutRooms/store";
import { getCurrentBreakOutRoomScreen, selectIsConnecting } from "containers/BreakoutRooms/store/selectors";
import { peerConnection } from "containers/BreakoutRooms/utils/peerConnection";
import {
  changeCurrentBreakoutRoomScreen,
  disableMyVideo,
  muteMe,
  updateStreamSettings,
} from "containers/BreakoutRooms/store/actions";
import { EventTooltip, useMediaDevicesHook, useStreamStatuses } from "shared";
import leave from "assets/icons/leave.svg";
import enable_micro from "assets/icons/enable-micro.svg";
import disable_micro from "assets/icons/disable-micro.svg";
import enable_video from "assets/icons/enable-video.svg";
import disable_video from "assets/icons/disable-video.svg";
import video_settings from "assets/icons/video-settings.svg";

import "./index.scss";

interface VideoMenuInterface {
  stream: MediaStream;
  exitCallback?: () => void;
  watchPartyStarted?: boolean;
  event: IEvent;
}

export const VideoMenu: React.FC<VideoMenuInterface> = (props: VideoMenuInterface) => {
  const dispatch = useDispatch();
  const { setMediaDevices, audioMuted, videoMuted } = useMediaDevicesHook(props.event);
  const [isStream] = useStreamStatuses();

  const isConnecting = useSelector(selectIsConnecting());
  const breakOutRoomScreen = useSelector(getCurrentBreakOutRoomScreen());

  const [isOpenedSettings, setSettingsState] = useState<boolean>(false);

  useEffect(() => {
    if (!props.stream || !props.stream?.getAudioTracks || audioMuted === null) {
      return;
    }

    props.stream?.getAudioTracks()?.forEach(function (track) {
      track.enabled = !audioMuted;
    });
    if (isConnecting) {
      peerConnection.mute(audioMuted);
    }

    dispatch(muteMe(audioMuted));
  }, [props.stream, isConnecting, audioMuted, dispatch]);

  useEffect(() => {
    if (props.watchPartyStarted) {
      setMediaDevices({ audioMuted: props.watchPartyStarted });
    }
  }, [props.watchPartyStarted, setMediaDevices]);

  useEffect(() => {
    if (!props.stream || !props.stream.getVideoTracks || videoMuted === null) {
      return;
    }

    props.stream.getVideoTracks().forEach(function (track) {
      track.enabled = !videoMuted;
    });

    if (isConnecting) {
      peerConnection.disableVideo(videoMuted);
    }

    dispatch(disableMyVideo(videoMuted));
  }, [props.stream, isConnecting, dispatch, videoMuted]);

  const handleToggleAudio = () => {
    setMediaDevices({ audioMuted: !audioMuted });
  };

  const handleToggleVideo = () => {
    setMediaDevices({ videoMuted: !videoMuted });
  };

  const handleClickExit = useCallback(() => {
    dispatch(actions.exitRoom.request());
    if (breakOutRoomScreen !== BreakOutRoomScreenTypes.tables) {
      dispatch(changeCurrentBreakoutRoomScreen(BreakOutRoomScreenTypes.tables));
    }
    props.exitCallback && props.exitCallback();
  }, [dispatch, props, breakOutRoomScreen]);

  const closeModalHandler = useCallback(
    (result?: MediaStream | undefined) => {
      if (!result) {
        return setSettingsState(false);
      }
      dispatch(updateStreamSettings.request(result));
      return setSettingsState(false);
    },
    [dispatch],
  );

  const leaveBtnText = isStream ? "Leave Row" : "Leave Table";

  return (
    <>
      {isOpenedSettings && (
        <StreamSettingsModal
          open={isOpenedSettings}
          onCancel={closeModalHandler}
          onConfirm={closeModalHandler}
          hasAudio={!audioMuted}
          hasVideo={!audioMuted}
        />
      )}
      <div className="video-menu-container">
        <div className="video-menu-control-element">
          <EventTooltip title={`${!audioMuted ? "Mute" : "Unmute"}`} placement="top">
            <div className={`circle-button ${!audioMuted ? "" : "disable"}`} onClick={() => handleToggleAudio()}>
              {!audioMuted ? (
                <img src={enable_micro} alt="enabled micro" />
              ) : (
                <img src={disable_micro} alt="disabled micro" />
              )}
            </div>
          </EventTooltip>
          <span className="label">Mic</span>
        </div>
        <div className="video-menu-control-element">
          <EventTooltip title={`Video ${!videoMuted ? "Off" : "On"}`} placement="top">
            <div className={`circle-button ${!videoMuted ? "" : "disable"}`} onClick={() => handleToggleVideo()}>
              {!videoMuted ? (
                <img src={enable_video} alt="enabled video" />
              ) : (
                <img src={disable_video} alt="disabled video" />
              )}
            </div>
          </EventTooltip>
          <span className="label">Camera</span>
        </div>
        <div className="video-menu-control-element">
          <EventTooltip title="Change Audio & Video settings" placement="top">
            <div className={`circle-button`} onClick={() => setSettingsState(true)}>
              <img src={video_settings} alt="stream-settings" />
            </div>
          </EventTooltip>
          <span className="label">Settings</span>
        </div>
        <div className="video-menu-control-element">
          <EventTooltip title="Leave seat" placement="top">
            <div className="circle-button end-call" onClick={() => handleClickExit()}>
              <img src={leave} alt="end-call" />
            </div>
          </EventTooltip>
          <span className="label">{leaveBtnText}</span>
        </div>
      </div>
    </>
  );
};
