import React, { createRef, useCallback, useEffect } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { Bench } from "../Bench";

import { RoomInterface } from "containers/BreakoutRooms/interfaces";
import { getEventSettings } from "containers/Sermons/store/selectors";

import "./index.scss";

interface IBenchRow {
  orderNumber: number;
  benches: RoomInterface[];
  onBenchFull: (payload: boolean) => void;
}

const BenchRow: React.FC<IBenchRow> = ({ benches, onBenchFull }) => {
  const ref = createRef<HTMLDivElement>();
  const { memberToFind } = useSelector(getEventSettings());
  const isMemberToFindInBench = useCallback(() => {
    return benches.find(b => b.members.find(m => m.id === memberToFind));
  }, [benches, memberToFind]);

  useEffect(() => {
    if (isMemberToFindInBench()) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [ref, isMemberToFindInBench]);

  const renderBenches = useCallback(
    () =>
      benches.map((bench, idx) => (
        <div key={idx} className="benchRow-item">
          <Bench bench={bench} onBenchFull={onBenchFull} />
        </div>
      )),
    [benches, onBenchFull],
  );

  return (
    <div className={classnames("benchRow", { "benchRow-oneInRow": benches.length === 1 })} ref={ref}>
      {renderBenches()}
    </div>
  );
};

export default BenchRow;
