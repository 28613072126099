import React from "react";
import { StreamMessage } from "stream-chat-react";

interface SystemMessageProps {
  message: StreamMessage;
  userName: string;
}

export const SystemMessage: React.FC<SystemMessageProps> = props => {
  const { message, userName } = props;

  return (
    <div className="customMessageWrapper">
      <div className="customMessage system">
        <div className="customMessage-header">
          <div className="customMessage-header-messageInfo">
            {<p className="customMessage-header-userName system">{userName}</p>}
          </div>
        </div>

        <div className="customMessage-text system">{message.text}</div>
      </div>
    </div>
  );
};
