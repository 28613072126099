import classnames from "classnames";
import React from "react";

import { Loader } from "../Loader";

import { DEBOUNCE_OPTIONS } from "shared/constants/searchOptions";
import useDebounce from "shared/hooks/DebounceHook/DebounceHook";

import "./wrappedLoader.scss";

interface WrappedLoaderProps {
  isLoading?: boolean;
  loaderDelay?: number;
  loaderSize?: number;
}

export const WrappedLoader: React.FunctionComponent<WrappedLoaderProps> = ({
  isLoading = false,
  loaderDelay = DEBOUNCE_OPTIONS.LOADER_DELAY,
  loaderSize = 150,
  children,
}) => {
  const debouncedIsLoading = useDebounce(isLoading, loaderDelay);

  return (
    <div className="loaderContainerWrapper">
      <Loader isLoading={debouncedIsLoading && isLoading} loaderSize={loaderSize} />
      <div className={classnames("loaderChildrenWrapper", { loading: debouncedIsLoading && isLoading })}>
        {children}
      </div>
    </div>
  );
};
