import { IEvent, IEventMember, IEventIntegrations, IPoll, IChangeEventStatusOptions, IPollTemplate } from "./Sermon";
import { EmojisType, EventDashboardTabs, IAnnouncementTemplate, IEventMemberResponse } from "..";
import { DiscussionTab } from "../../../shared/components/Event/EventSideDialog/EventSideDialogHeader/EventSideDialogDiscussionTabs";
import { IMember } from "../../Member";

import { IAnnouncement } from "./";

import { ResponseError } from "shared/interfaces";
import { EInteractionTab } from "shared/components/Event/EventSideDialog/EventSideDialogHeader";

export interface IEventState {
  spotlight: number;
  mute: boolean;
  unmuted: number[];
  activeSlideId: number;
  reaction: { name: EmojisType } | null;
}

export interface IEventSettings {
  activeDashboardTab: EventDashboardTabs | null;
  isLoading: boolean;
  memberToFind: number | null;
  announcementToFind: number | null;
  pullToFind: number | null;
  selectedTab: DiscussionTab;
  interactionTab: EInteractionTab;
  showTables: boolean;
}

export interface IWaitingToJoinMember {
  member: IMember;
  knockRequestedDate: number;
}

export interface IEventsStateType {
  sermonList: IEvent[];
  sermon: IEvent | null;
  sermonMembers: IEventMember[];
  eventMembers: IEventMemberResponse[];
  blockedEventMembers: string[];
  signature: string | null;
  state: IEventState;
  error: ResponseError | null;
  slotIsAvailable: boolean;
  eventIntegrations: IEventIntegrations | null;
  eventShortLink: string | null;
  eventSettings: IEventSettings;
  socketConnected: boolean;
  goingLiveTime: number;
  allowEnded: boolean;
  changingStatus: boolean;
  leavingEvent: boolean;
  recurringEvent: IEvent | null;
  participantsLimitExceeded: boolean;
  updatedFields: string[];
  announcements: IAnnouncement[];
  announcementTemplates: IAnnouncementTemplate[];
  selectedAnnouncement: IAnnouncement | null;
  polls: IPoll[];
  pollTemplates: IPollTemplate[];
  isEventHostOrAdmin: boolean;
  knockRejected: boolean;
  waitingToJoinMembers: IWaitingToJoinMember[];
  changeStatusOptions: IChangeEventStatusOptions | null;
  isVimeoTokenValid: boolean;
}

export enum EFileDownloadState {
  init = "init",
  processing = "processing",
  complete = "complete",
}
