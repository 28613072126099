import { action, Action } from "typesafe-actions";

import { NotificationActionTypes, NotificationVariants } from "./constants";
import { IChatNotificationSettings, INotificationAnnouncement } from "../interfaces";

export type NotificationActions = { [key: string]: (title: string, message?: string) => Action<any> };
export const notificationActions: NotificationActions = {};

NotificationVariants.forEach(variant => {
  notificationActions[variant] = (title: string, message?: string) => {
    const reassignedMessage = message ? message : title;
    const reassignedTitle = !message ? null : title;

    return action(NotificationActionTypes.ENQUEUE_SNACKBAR, {
      key: (new Date().getTime() + Math.random()).toString(),
      message: reassignedMessage,
      title: reassignedTitle,
      variant,
    });
  };
});

export const removeSnackbar = (key: string) => action(NotificationActionTypes.REMOVE_SNACKBAR, key);
export const setChatNotificationSettings = (chatNotifications: IChatNotificationSettings) =>
  action(NotificationActionTypes.CHAT_NOTIFICATIONS, chatNotifications);

export const enableNotification = (announcement: INotificationAnnouncement) =>
  action(NotificationActionTypes.ENABLE_NOTIFICATION, announcement);
export const removeNotification = () => action(NotificationActionTypes.REMOVE_NOTIFICATION);

export const enablePopup = (announcement: INotificationAnnouncement) =>
  action(NotificationActionTypes.ENABLE_POPUP, announcement);
export const removePopup = (id?: number) => action(NotificationActionTypes.REMOVE_POPUP, id);
