import React from "react";
import { Radio, RadioProps } from "@material-ui/core";
import classnames from "classnames";

import "./styledRadio.scss";

const StyledRadio: React.FC<RadioProps> = props => {
  return (
    <Radio
      className="styledRadio"
      disableRipple
      color="default"
      checkedIcon={<span className={classnames("styledRadio-icon", "styledRadio-checkedIcon")} />}
      icon={<span className="styledRadio-icon" />}
      {...props}
    />
  );
};

export default StyledRadio;
