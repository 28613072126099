import React from "react";
import classnames from "classnames";

import disabled_micro from "assets/icons/disabled-micro.svg";
import active_micro from "assets/icons/active-micro.svg";

import "./index.scss";

interface IVideoMemberLabel {
  muted: boolean;
  memberName: string;
  className?: string;
}

const VideoMemberLabel: React.FC<IVideoMemberLabel> = ({ muted, memberName, className }) => {
  return (
    <div className={classnames("videoMemberLabel", className)}>
      <div className="videoMemberLabel-member-name">{memberName}</div>
      <div className="videoMemberLabel-microphone-indicator">
        {muted ? (
          <img src={disabled_micro} alt="muted microphone" />
        ) : (
          <img src={active_micro} alt="unmuted microphone" />
        )}
      </div>
    </div>
  );
};
export default VideoMemberLabel;
