import React from "react";

import { CustomAvatar } from "../../../../../containers/Discussions";
import { Button } from "../../../Button";

import cross_icon_grey from "assets/icons/cross_icon_grey.svg";

export interface EventSideDialogHeaderProps {
  onClose?: () => void;
  onBack?: () => void;
  title: string;
  image?: string;
  imageColor?: string;
  showImage?: boolean;
}

const EventSideDialogHeader: React.FC<EventSideDialogHeaderProps> = props => {
  const { image, title, imageColor, showImage, onClose, onBack } = props;

  return (
    <div className="eventSideDialog-header">
      <div className="eventSideDialog-header-title">
        <div>
          {onBack && (
            <div onClick={onBack} className="eventSideDialog-header-back">
              Back
            </div>
          )}
          <div className="eventSideDialog-header-text">
            {showImage && <CustomAvatar image={image} size={48} name={title} color={imageColor} />}
            <h2 className="eventSideDialog-title">{title}</h2>
          </div>
        </div>
        {onClose && (
          <Button width={24} variant="black-text" className="eventSideDialog-close-btn" onClick={onClose}>
            <img src={cross_icon_grey} alt="cross icon" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default EventSideDialogHeader;
