import { EventStatus, IEventSlide, ISlideContentBlogImageUrlsMatch } from "../interfaces";
import { api } from "../api";

import notification from "assets/sounds/notification.mp3";

function findSlideContentBlobImageUrls(content: string): ISlideContentBlogImageUrlsMatch[] {
  // eslint-disable-next-line
  const findBlobUrls = /src=\"blob:http[s]?:\/\/[\d\w\$\/\-\.\+\!\*\'\(\)\,\:]{10,}\"/gim;

  const matches: ISlideContentBlogImageUrlsMatch[] = [];
  let match;
  while ((match = findBlobUrls.exec(content)) !== null) {
    const start = 'src="'.length;
    matches.push({ startAt: match.index + start, matchStr: match[0].slice(start, -1) });
  }

  return matches;
}

export function replaceSlideContentBlobImageUrls(
  content: string,
  matches: ISlideContentBlogImageUrlsMatch[],
  replacements: string[],
) {
  let result = "";
  let restStartAt = 0;
  matches.forEach(({ startAt, matchStr }, index) => {
    const replacement = replacements[index];

    result += content.slice(restStartAt, startAt);
    result += replacement;
    restStartAt = startAt + matchStr.length;
  });
  result += content.slice(restStartAt);
  return result;
}

function fetchBlobsByUrls(blobsUrls: string[]): Promise<{ blobUrl: string; blob: Blob }[]> {
  const promises = blobsUrls.map((blobUrl: string) => {
    return fetch(blobUrl)
      .then(r => r.blob())
      .then(blob => {
        return { blobUrl, blob };
      });
  });
  return Promise.all(promises);
}

export async function replaceSlidesBlobsUrlsWithUploadedFilesUrls(
  communityId: number,
  slides: IEventSlide[],
): Promise<{ slides: IEventSlide[]; cleanUp: () => void }> {
  const filesBlobsUrls: string[] = [];

  const slidesMatches = slides.map(({ content }) => {
    const matches = findSlideContentBlobImageUrls(content);
    matches.forEach(({ matchStr: blobUrl }: ISlideContentBlogImageUrlsMatch) => {
      filesBlobsUrls.push(blobUrl);
    });
    return matches;
  });
  const filesUrlsToBlobs = await fetchBlobsByUrls(filesBlobsUrls);

  if (!filesUrlsToBlobs.length) {
    return { slides, cleanUp: () => {} };
  }

  const data = new FormData();
  filesUrlsToBlobs.forEach(({ blobUrl, blob }: { blobUrl: string; blob: Blob }) => {
    data.append("files[]", blob, btoa(blobUrl));
  });
  const blobUrlToUrlMap = await api.uploadMeetingSlidesFiles(communityId, data);
  const replacements: string[][] = slidesMatches.map(matches => {
    return matches.map(match => {
      const { matchStr: blobUrl } = match;
      return blobUrlToUrlMap[btoa(blobUrl)];
    });
  });

  const updatedSlides = slides.map(({ content, ...rest }, index) => ({
    ...rest,
    content: replaceSlideContentBlobImageUrls(content, slidesMatches[index], replacements[index]),
  }));

  const cleanUp = () => {
    Object.keys(filesUrlsToBlobs).forEach(blobUrl => URL.revokeObjectURL(blobUrl));
  };

  return { slides: updatedSlides, cleanUp };
}

export const fetchBlobByB64 = (base64: string) => {
  return fetch(base64)
    .then(r => r.blob())
    .then(blob => ({ blob, blobUri: URL.createObjectURL(blob) }));
};

const allowedStatuses = {
  from: [EventStatus.live, EventStatus.lobby, EventStatus.scheduled],
  to: [EventStatus.live, EventStatus.lobby, EventStatus.scheduled],
};

const prohibitStatuses = {
  from: [EventStatus.scheduled],
  to: [EventStatus.live],
};

export const validateEventChangingStatus = (changingStatus: { from: EventStatus; to: EventStatus }): boolean => {
  const { from, to } = allowedStatuses;
  const { from: prohibitFrom, to: prohibitTo } = prohibitStatuses;
  return (
    changingStatus.from !== changingStatus.to &&
    !(prohibitFrom.includes(changingStatus.from) && prohibitTo.includes(changingStatus.to)) &&
    from.includes(changingStatus.from) &&
    to.includes(changingStatus.to)
  );
};

export const playNotificationAudio = () => {
  const audio = new Audio(notification);
  return audio.play();
};
