import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getCommunitySubscriptions } from "containers/Community/store/selectors";
import { EFeature } from "containers/Community";

const useFeatures = (): EFeature[] => {
  const subscriptions = useSelector(getCommunitySubscriptions());

  return useMemo(() => {
    const [currentProduct] = subscriptions?.currentSubscriptions || [];
    const features: EFeature[] = [];
    if (currentProduct) {
      features.push(...currentProduct.features.map(({ name }) => name));
    }

    return features;
  }, [subscriptions]);
};

export default useFeatures;
