import React, { useCallback } from "react";
import { FieldArray, FormikErrors, useFormikContext } from "formik";
import { useDispatch } from "react-redux";

import StreamRelaySocial from "../StreamRelaySocial";
import StreamRelayInput from "../StreamRelayInput";
import Divider from "../Divider";

import { EEventRelayType, IEventForm, IEventRelayItem, StreamRelayFields } from "containers/Sermons/interfaces";
import { copyTextHandler } from "shared/utils";
import { notificationActions } from "containers/Notifications/store/actions";
import { getCopiedText } from "shared/utils/copyTextHandler";

import "./styles.scss";

const StreamRelay: React.FC = () => {
  const dispatch = useDispatch();

  const {
    values: { relays, stream_settings },
    errors,
    setFieldValue,
  } = useFormikContext<IEventForm>();
  const copyInputHandler = useCallback(
    (field: StreamRelayFields) => () => {
      copyTextHandler(stream_settings?.[field] || "");
      dispatch(notificationActions.success("Copied to the clipboard"));
    },
    [dispatch, stream_settings],
  );
  const handlePasteText = useCallback(
    (provider: EEventRelayType) => async (field: StreamRelayFields) => {
      try {
        if (relays) {
          const text = await getCopiedText();
          const newData = relays.map(item => (item.type === provider ? { ...item, [field]: text } : item));
          setFieldValue("relays", newData, false);
        }
      } catch (err: any) {
        dispatch(notificationActions.error("Cannot get data from the clipboard"));
      }
    },
    [dispatch, relays, setFieldValue],
  );

  const toggleSocial = useCallback(
    (provider: EEventRelayType) => () => {
      if (relays) {
        const newData = relays.map(item => (item.type === provider ? { ...item, enabled: !item.enabled } : item));
        setFieldValue("relays", newData, false);
      }
    },
    [relays, setFieldValue],
  );

  const handleChange = useCallback(
    (value: IEventRelayItem) => {
      if (relays) {
        const newData = relays.map(item => (item.type === value.type ? value : item));
        setFieldValue("relays", newData);
      }
    },
    [relays, setFieldValue],
  );

  return stream_settings ? (
    <div className="stream-relay">
      <div className="stream-relay-section common">
        <StreamRelayInput
          disabled
          name="stream_settings.stream_url"
          value={stream_settings.stream_url}
          label="Stream RTMP URL"
          handleButtonClick={copyInputHandler("stream_url")}
          buttonText="Copy"
        />
        <StreamRelayInput
          disabled
          name="stream_settings.stream_key"
          value={stream_settings.stream_key}
          inputType="password"
          label="Stream Key"
          handleButtonClick={copyInputHandler("stream_key")}
          buttonText="Copy"
        />
      </div>
      <div className="stream-relay-section">
        <div className="stream-relay-section-header">Use Altar to multistream to your Social Media channels!</div>
        <Divider />
        <p className="stream-relay-section-description">
          You can multicast to platforms in the list below. Please follow the instructions we provided for each one of
          them.
        </p>
        <FieldArray
          name="relays"
          render={() => {
            const { relays: relayDataErrors = [] } = errors;
            return relays?.map(
              (item, idx) =>
                item.type !== EEventRelayType.AWS && (
                  <StreamRelaySocial
                    key={item.type}
                    onToggle={toggleSocial(item.type)}
                    value={item}
                    onPaste={handlePasteText(item.type)}
                    handleChange={handleChange}
                    errors={relayDataErrors[idx] as FormikErrors<IEventRelayItem>}
                  />
                ),
            );
          }}
        />
      </div>
    </div>
  ) : null;
};

export default StreamRelay;
