import React from "react";
import { useDispatch } from "react-redux";

import { ERecordingStatus } from "../../interfaces";
import { setRecordingStatus } from "../../store/actions";

import record_dot_red from "assets/icons/record_dot_red.svg";
import record_stop from "assets/icons/record_stop.svg";

import "./recordingLabel.scss";

const RecordingLabel: React.FC<{ canStop: boolean }> = ({ canStop }) => {
  const dispatch = useDispatch();
  return (
    <div className="recording">
      <div className="recording-status">
        <img src={record_dot_red} alt="rec" />
        Recording in progress
      </div>
      {canStop && (
        <div className="recording-stop" onClick={() => dispatch(setRecordingStatus(ERecordingStatus.hasStopped))}>
          <img src={record_stop} alt="stop" />
        </div>
      )}
    </div>
  );
};

export default RecordingLabel;
