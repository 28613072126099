import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { IMember } from "../../../containers/Member/interfaces";

import { getInitials } from "shared";

import "./memberImage.scss";

type Props = {
  member: IMember;
  fontSize?: number;
};

const MemberImage: React.FC<Props> = ({ member, fontSize = 12 }) => {
  const [profileImageError, setProfileImageError] = useState(false);

  useEffect(() => {
    setProfileImageError(false);
  }, [member]);

  return (
    <div
      className={classnames("member-image-avatar", { empty: !member.image_url || !!profileImageError })}
      style={{
        fontSize: `${fontSize}px`,
      }}
    >
      {member.image_url && !profileImageError ? (
        <img
          src={member.image_url}
          alt={`${member.first_name} ${member.last_name}`}
          onError={() => setProfileImageError(true)}
        />
      ) : (
        getInitials(member)
      )}
    </div>
  );
};

export default MemberImage;
