import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ArraySchema, ObjectSchema } from "yup";
import { useLocation } from "react-router-dom";
import * as HttpStatus from "http-status-codes";
import { v4 as uuid } from "uuid";
import _ from "underscore";
import { Form, Formik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
  EAuditoriumRoomsType,
  EEventChangeScope,
  EEventReCurringEnds,
  EEventRepeat,
  EEventStreamType,
  EEventVideoType,
  EPreEventType,
  EStreamSettings,
  EventStatus,
  EventType,
  IEvent,
  IEventForm,
  IEventRelayItem,
  NavigationEventAction,
  Notifications,
  SermonRestriction,
} from "../../interfaces";
import { getMeetingType, prepareEventDataToSave, prepareEventSchedule } from "../../utils";
import { getError, getEvent, getUpdatedFields } from "../../store/selectors";
import { actions } from "../../store";
import { actions as communityActions } from "../../../Community/store";
import StreamEvent from "../../components/Sermon/StreamEvent";
import { sermonValidationSchema } from "../../validators";
import { IMember } from "../../../Member/interfaces";
import { getCommunity, getCommunityStreamingStatistics } from "../../../Community/store/selectors";
import { NamesOfParentRoutes } from "../../../../constants";
import ChangeScopePopup from "../../components/Sermon/ChangeScopePopup";
import { uploadToS3 } from "../../../../shared/stores/aws/actions";
import config from "../../../../config";
import { EFeature, ICommunity } from "../../../Community";

import { searchParamsToObject } from "shared/utils/searchParams";
import { getMember } from "containers/Auth/store/selectors";
import { getOnboardingCommunity, getOnboardingMember } from "containers/Onboarding/store/selectors";
import { setDashBoardDisabled } from "containers/App/store/actions";
import { defaultRelayData, initialTourDescription, initialTourTitle } from "containers/Sermons/constants";
import { getUploadProgress } from "shared/stores/aws/selectors";
import { notificationActions } from "containers/Notifications/store/actions";
import { isMatchedPath } from "containers/Community/utils";
import { AlertDialog } from "shared";
import { IRouteProps } from "shared/interfaces";
import { useFeatures } from "shared/hooks/FeaturesHook";

const { EVENTS, COMMUNITIES, CREATION, DASHBOARD, WATCH_PARTY } = NamesOfParentRoutes;

interface SermonDetailsProps {
  onClose?: () => void;
  onPublish?: (values: IEventForm) => void;
}

const getRelayData = (data?: IEventRelayItem[] | null) => {
  if (!data || !data?.length) {
    return defaultRelayData;
  }
  const existingRelays = data.map(({ type }) => type);
  return data.concat(defaultRelayData.filter(relay => !existingRelays.includes(relay.type)));
};

const generateRTMPCredentials = (communityCode: string): EStreamSettings => {
  return {
    stream_url: `${config.rtmpUrl}/${communityCode}`,
    stream_key: uuid(),
  };
};

const prepareSermonForm = (
  event: IEvent | null,
  currentUserMember: IMember | null,
  features: EFeature[],
  eventType?: EventType,
  community?: ICommunity | null,
  setMemberAsGreeter?: boolean,
  isDefaultEvent?: boolean,
): IEventForm => {
  const defaultStartTime = moment().add(1, "h").seconds(0);

  const starting_at = (isDefaultEvent ? false : event?.starting_at) || new Date(defaultStartTime.format()).toString();
  const ending_at =
    (isDefaultEvent ? false : event?.ending_at) || new Date(defaultStartTime.add(1, "h").format()).toString();
  const host = event?.producer || currentUserMember;
  const integrations = event?.integrations ? event.integrations.filter(i => i.enabled).map(i => i.type) : [];

  const mainClassroom = event?.classrooms?.find(r => r.is_main) || null;
  const isMeetingEvent = [eventType, event?.type].includes(EventType.meeting);
  const hasPlatformTourFeature = features.includes(EFeature.platform_tour);
  const hasLobbyFeature = features.includes(EFeature.lobby);

  return {
    id: event?.id || null,
    code: event?.code || null,
    name: event?.name || "",
    subject: event?.subject || "",
    starting_at,
    ending_at,
    setup_time: event?.setup_time || 0,
    post_event_time: event?.post_event_time || 0,
    restriction: event?.restriction || SermonRestriction.all,
    host,
    producer: event?.producer || currentUserMember,
    greeters:
      event?.greeters ||
      (setMemberAsGreeter && currentUserMember ? [{ ...currentUserMember, is_admin: true, position: 0 }] : []),
    greeter_message: event?.greeter_message || "",
    presenters: event?.presenters.length ? event.presenters : [],
    attendees: event?.attendees.length ? event.attendees : [],

    type: event?.type || eventType || EventType.liveService,
    integrations: integrations,
    integrationsData: event?.integrations ? event?.integrations : [],
    status: event?.status || EventStatus.draft,
    speaker_name: event?.speaker_name || "",
    stream_type: event ? event.stream_type : null,
    video_type: event ? event.video_type : EEventVideoType.live,
    stream_url: event ? event.stream_url : eventType === EventType.stream ? "" : null,
    image_url: event ? event.image_url : "",
    pre_images: event ? event.pre_images : [],
    pre_images_interval: event ? event.pre_images_interval : eventType === EventType.stream ? 5 : null,
    seats_per_table: event ? event.seats_per_table : eventType === EventType.stream ? 4 : null,
    repeat: event?.repeat || EEventRepeat.never,
    schedule: prepareEventSchedule(event?.schedule, event?.repeat),
    recurring_ends_on: event?.schedule?.until
      ? EEventReCurringEnds.on
      : event?.schedule?.occurrences
      ? EEventReCurringEnds.after
      : EEventReCurringEnds.never,
    is_featured: event?.is_featured || false,
    restricted_generalChat: event ? event.restricted_generalChat : eventType !== EventType.meeting,
    showTour: event?.showTour || false,
    relays: getRelayData(event?.relays),
    stream_settings: event?.stream_settings
      ? event.stream_settings
      : !community
      ? null
      : community.stream_settings
      ? community.stream_settings
      : generateRTMPCredentials(community.code),
    vimeo_token: community?.vimeo_token?.split("").reverse().join("") ?? "",
    class_room_settings:
      eventType === EventType.meeting || EventType.meeting === event?.type
        ? mainClassroom
          ? {
              ...mainClassroom,
              name: mainClassroom.name || "default name",
              description: mainClassroom.description || "default description",
            }
          : {
              room_size: 50,
              is_main: true,
            }
        : null,
    start_with_audio_muted: event?.start_with_audio_muted ?? isMeetingEvent,
    start_with_video_muted: event?.start_with_video_muted ?? !isMeetingEvent,

    is_default: isDefaultEvent,
    show_on_landing: event?.type === EventType.meeting ? (event ? event.show_on_landing : false) : true,
    has_platform_tour: hasPlatformTourFeature ? event?.has_platform_tour ?? true : false,
    tour_title: event?.has_platform_tour ? event?.tour_title : initialTourTitle,
    tour_description: event?.has_platform_tour ? event?.tour_description : initialTourDescription,
    pre_event_type: event?.pre_event_type ? event?.pre_event_type : EPreEventType.none,
    pre_event_video_url: event?.pre_event_video_url?.trim() || "",
    files: event?.files || [],
    is_pre_event_video_loop: event?.is_pre_event_video_loop ?? true,
    is_pre_event_video_autoplay: event?.is_pre_event_video_autoplay ?? true,
    classrooms: {
      mainClassroom,
      classRoomItems: event?.classrooms?.filter(r => !r.is_main) || [],
      enabled: event?.type === EventType.meeting ? event?.classrooms?.length > 1 : !!event?.classrooms?.length,
    },
    auditorium_rooms: {
      auditoriumRoomsCount: event?.auditorium_rooms_count || 20,
      auditoriumRoomsEnabled: Boolean(
        event?.auditorium_rooms_count || event?.auditorium_rooms_type === EAuditoriumRoomsType.none || !hasLobbyFeature,
      ),
    },
    lobby_rooms: {
      lobbyRoomsCount: event?.lobby_rooms_count || 20,
      lobbyRoomsEnabled: hasLobbyFeature && Boolean(event?.lobby_rooms_count ?? true),
    },
    is_locked: event?.is_locked || false,
    host_notes: event?.host_notes || null,
    last_editor_host_notes: event?.last_editor_host_notes || null,
    auditorium_rooms_type: event?.auditorium_rooms_type || EAuditoriumRoomsType.row,
    polls: event?.polls || [],
    announcements: event?.announcements || [],
    leave_event_url: event?.leave_event_url || null,
    leave_event_action: event?.leave_event_url ? NavigationEventAction.custom : NavigationEventAction.default,
    start_event_option: event?.start_event_option || null,
    was_started: event?.was_started ?? false,
  };
};

export const defineEventStatus = (values: IEventForm, currentEvent: IEvent | null) => {
  const {
    status,
    lobby_rooms: { lobbyRoomsEnabled },
    auditorium_rooms: { auditoriumRoomsEnabled },
    classrooms: { classRoomItems },
    type,
    start_event_option,
  } = values;

  const splitStartEventOption = start_event_option?.split(/(?=\/)/g) || [];
  const isWatchpartyOption = splitStartEventOption.includes(WATCH_PARTY);
  const allowAuditorium =
    (auditoriumRoomsEnabled || type === EventType.meeting) && (!splitStartEventOption.length || isWatchpartyOption);
  const allowLobby =
    (lobbyRoomsEnabled || classRoomItems.length) && (!splitStartEventOption.length || !isWatchpartyOption);

  switch (status) {
    case EventStatus.initial:
    case EventStatus.draft:
      return allowAuditorium ? EventStatus.scheduled : EventStatus.lobby;
    case EventStatus.scheduled:
    case EventStatus.live:
      return allowAuditorium ? status : EventStatus.lobby;
    case EventStatus.lobby:
      return allowLobby ? status : currentEvent?.was_started ? EventStatus.live : EventStatus.scheduled;
    default:
      return status;
  }
};

const { EVENT, EVENT_NEW } = NamesOfParentRoutes;

const SermonDetails: React.FC<SermonDetailsProps> = ({ onClose, onPublish }) => {
  const { eventCode, communityCode, eventType } = useParams<IRouteProps>();

  const history = useHistory();

  const communityCreation = isMatchedPath(history.location.pathname, `${COMMUNITIES}${CREATION}`);

  const currentEvent = useSelector(getEvent());
  const currentUserMember = useSelector(getMember());
  const currentCommunity = useSelector(getCommunity());
  const eventError = useSelector(getError());
  const updatedFields = useSelector(getUpdatedFields());
  const uploadProgress = useSelector(getUploadProgress());
  const streamingStatistics = useSelector(getCommunityStreamingStatistics());
  const onboardingCommunity = useSelector(getOnboardingCommunity());
  const onboardingMember = useSelector(getOnboardingMember());
  const features = useFeatures();

  const [isDefaultEvent, setIsDefaultEvent] = useState(false);
  const [backToDashboard, setBackToDashboard] = useState(false);
  const [initValue, setInitValue] = useState(() =>
    prepareSermonForm(
      currentEvent,
      onboardingMember || currentUserMember || null,
      features,
      communityCreation ? EventType.stream : eventType,
      communityCreation ? onboardingCommunity : currentCommunity,
      communityCreation,
      isDefaultEvent,
    ),
  );
  const [isCancel, setIsCancel] = useState(false);
  const [isLeavingBack, setIsLeavingBack] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [validationSchema, setValidationSchema] = useState<ObjectSchema | ArraySchema<any>>(sermonValidationSchema);
  const [eventSaved, setEventSaved] = useState(false);
  const [saveOptions, setSaveOptions] = useState<"draft" | "publish" | null>(null);
  const [scope, setScope] = useState(EEventChangeScope.current);
  const [redirectToEdit, setRedirectToEdit] = useState(false);

  const formRef = useRef<any>({ values: initValue });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();

  const backToSermonsList = useCallback(() => {
    if (communityCreation) {
      return;
    }
    if (communityCode) {
      dispatch(communityActions.getCommunity.request(communityCode));
      history.push(`/${communityCode}${EVENTS}`);
    }
  }, [communityCode, communityCreation, history, dispatch]);

  const onCloseConfirmed = useCallback(() => {
    dispatch(actions.clearCurrentSermon());
    dispatch(uploadToS3.cancel());
    setIsCancel(false);
    onClose ? onClose() : backToDashboard ? history.push(`/${communityCode}${DASHBOARD}`) : backToSermonsList();
  }, [backToSermonsList, communityCode, dispatch, history, backToDashboard, onClose]);

  const handlePublishSermon = useCallback(
    (values: IEventForm) => {
      const communityId = onboardingCommunity?.id || currentCommunity?.id;
      if (communityId) {
        const callback = () => {
          setEventSaved(true);
          dispatch(actions.clearCurrentSermon());
          dispatch(uploadToS3.cancel());
          if (backToDashboard) {
            history.push(`/${communityCode}${DASHBOARD}`);
          } else {
            backToSermonsList();
          }
        };
        if (values.id) {
          dispatch(
            actions.updateSermon.request({
              data: {
                ...prepareEventDataToSave(values, communityId, currentEvent),
                status: defineEventStatus(values, currentEvent),
                id: values.id as number,
                scope:
                  values.status === EventStatus.initial && values.repeat !== EEventRepeat.never
                    ? EEventChangeScope.following
                    : scope,
              },
              callback,
              options: {
                action: values.status === EventStatus.initial ? Notifications.published : Notifications.updated,
                type: getMeetingType(values.type),
              },
            }),
          );
          return;
        }
        if (communityCreation) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          onPublish?.(values);
          callback();
          return;
        }
        dispatch(
          actions.createSermon.request({
            data: {
              ...prepareEventDataToSave(values, communityId, currentEvent),
              status: defineEventStatus(values, currentEvent),
              is_default: isDefaultEvent,
            },
            callback,
            options: {
              action: Notifications.published,
              type: getMeetingType(values.type),
            },
          }),
        );
      }
    },
    [
      onboardingCommunity,
      currentCommunity,
      communityCreation,
      dispatch,
      currentEvent,
      isDefaultEvent,
      history,
      communityCode,
      backToSermonsList,
      scope,
      onPublish,
      backToDashboard,
    ],
  );
  const handleRemoveEvent = useCallback(
    (eventId: number) => {
      dispatch(
        actions.removeEvent.request({
          eventId,
          scope,
          callback: () => {
            backToDashboard ? history.push(`/${communityCode}${DASHBOARD}`) : backToSermonsList();
          },
          options: {
            type: currentEvent ? getMeetingType(currentEvent.type) : void 0,
          },
        }),
      );
      dispatch(actions.clearCurrentSermon());
      dispatch(uploadToS3.cancel());
    },
    [backToSermonsList, currentEvent, scope, backToDashboard, communityCode, history, dispatch],
  );
  const onBackConfirmed = useCallback(() => {
    setIsLeavingBack(false);
    history.go(-2);
  }, [history]);
  const onLeavingCancelHandler = useCallback(() => {
    if (isCancel) {
      setIsCancel(false);
    }
    if (isLeavingBack) {
      setIsLeavingBack(false);
    }
  }, [isCancel, isLeavingBack]);
  const onCloseSermon = useCallback(
    (values: IEventForm) =>
      !_.isEqual(values, initValue) || uploadProgress !== null ? setIsCancel(true) : onCloseConfirmed(),
    [initValue, onCloseConfirmed, uploadProgress],
  );
  const handleSaveDraft = useCallback(
    (values: IEventForm) => {
      const communityId = onboardingCommunity?.id || currentCommunity?.id;
      if (communityId) {
        const callback = () => {
          setEventSaved(true);

          if (values.id || values.status !== EventStatus.initial) {
            dispatch(actions.clearCurrentSermon());
            dispatch(uploadToS3.cancel());
            if (backToDashboard) {
              history.push(`/${communityCode}${DASHBOARD}`);
            } else {
              backToSermonsList();
            }
          }
        };

        values.id
          ? dispatch(
              actions.updateSermon.request({
                data: {
                  ...prepareEventDataToSave(values, communityId, currentEvent),
                  scope,
                  status: values.status === EventStatus.initial ? EventStatus.draft : values.status,
                  id: values.id as number,
                },
                callback,
                options: {
                  action: values.status === EventStatus.initial ? Notifications.created : Notifications.updated,
                  type: getMeetingType(values.type),
                },
              }),
            )
          : dispatch(
              actions.createSermon.request({
                data: {
                  ...prepareEventDataToSave(values, communityId, currentEvent),
                  status: values.status === EventStatus.initial ? EventStatus.initial : EventStatus.draft,
                  is_default: isDefaultEvent,
                },
                callback,
                options: {
                  action: Notifications.created,
                  type: getMeetingType(values.type),
                },
                silently: values.status === EventStatus.initial,
              }),
            );
      }
    },
    [
      onboardingCommunity,
      currentCommunity,
      dispatch,
      currentEvent,
      scope,
      isDefaultEvent,
      history,
      communityCode,
      backToSermonsList,
      backToDashboard,
    ],
  );

  const onSave = useCallback(
    (saveDraft = false) =>
      (values: IEventForm, redirectOnCreate = false, saveInitial = false) => {
        if (
          values.stream_type === EEventStreamType.ALTAR_LIVE &&
          (!streamingStatistics ||
            (streamingStatistics.usedSeconds >= streamingStatistics.allowedHours * 60 * 60 &&
              moment(values.starting_at).isBetween(streamingStatistics.from, streamingStatistics.to)))
        ) {
          dispatch(
            notificationActions.error(
              t("errors.event.streamingLimitReachedTitle"),
              t("errors.event.streamingLimitReached"),
            ),
          );
          return;
        }
        setRedirectToEdit(redirectOnCreate);
        setScope(EEventChangeScope.current);
        if (values.repeat === EEventRepeat.never || !values.id || currentEvent?.status === EventStatus.initial) {
          if (saveDraft) {
            if (saveInitial && !values.id) {
              values.status = EventStatus.initial;
            }
            handleSaveDraft(values);
          } else {
            handlePublishSermon(values);
          }
        } else {
          if (!saveDraft && currentEvent?.status === EventStatus.draft) {
            setScope(EEventChangeScope.all);
          }
          setSaveOptions(saveDraft ? "draft" : "publish");
        }
      },
    [currentEvent, dispatch, handlePublishSermon, handleSaveDraft, streamingStatistics, t],
  );
  const onSaveOptionsClose = useCallback(() => setSaveOptions(null), []);
  const onScopeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setScope(e.target.value as EEventChangeScope),
    [],
  );
  const onRemove = useCallback(() => {
    setScope(EEventChangeScope.current);
    setIsRemove(true);
  }, []);

  useEffect(() => {
    const { is_default, to_dashboard } = searchParamsToObject(search);
    if (is_default) {
      setIsDefaultEvent(true);
    }
    if (to_dashboard) {
      setBackToDashboard(true);
    }
  }, [history, search]);

  useEffect(() => {
    dispatch(setDashBoardDisabled(true));
  }, [dispatch]);

  useEffect(() => {
    if (eventError && eventError.code === HttpStatus.NOT_FOUND && communityCode) {
      dispatch(notificationActions.error("Cannot fetch event", eventError.message || ""));
      history.push(`/${communityCode}${EVENTS}`);
    }
  }, [eventError, communityCode, history, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.clearEventError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (eventType && !Object.values(EventType).includes(eventType)) {
      history.goBack();
    }
  }, [eventType, history]);
  useEffect(() => {
    if (eventCode) {
      dispatch(
        actions.fetchSermon.request({
          code: eventCode,
          relations: ["schedule", "files", "relays", "classrooms", "polls", "announcements"],
          customErrorHandling: true,
        }),
      );
    } else {
      dispatch(actions.clearCurrentSermon());
    }
  }, [eventCode, dispatch]);
  useEffect(() => {
    if (updatedFields.length === 0) {
      setInitValue(
        prepareSermonForm(
          currentEvent,
          onboardingMember || currentUserMember || null,
          features,
          communityCreation ? EventType.stream : eventType,
          communityCreation ? onboardingCommunity : currentCommunity,
          communityCreation,
          isDefaultEvent,
        ),
      );
    } else {
      updatedFields.forEach(prop => {
        if (
          !currentEvent ||
          (prop === "stream_url" &&
            formRef.current.values.stream_type === EEventStreamType.ALTAR &&
            !formRef.current.values.stream_url &&
            currentEvent.stream_url &&
            /.*\.m3u8$/.test(currentEvent.stream_url))
        ) {
          return;
        }
        formRef.current.setFieldValue(prop, currentEvent[prop as keyof IEvent]);
      });
    }
  }, [
    currentEvent,
    currentUserMember,
    onboardingMember,
    eventType,
    updatedFields,
    currentCommunity,
    onboardingCommunity,
    communityCreation,
    isDefaultEvent,
    features,
  ]);
  useEffect(() => {
    // needs to prevent browser history back()
    history.push({ search, pathname: history.location.pathname });
  }, [history, search]);

  useEffect(() => {
    if (currentEvent) {
      dispatch(actions.startSermon(currentEvent.id));
    }
  }, [currentEvent, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.closeSocket());
    };
  }, [dispatch]);

  useEffect(() => {
    return history.listen((location, action) => {
      if (action === "POP") {
        history.push(location);
        if (!_.isEqual(formRef.current.values, initValue)) {
          setIsLeavingBack(true);
        } else {
          onBackConfirmed();
        }
      }
    });
  }, [history, initValue, onBackConfirmed]);

  useEffect(() => {
    if (eventSaved) {
      setEventSaved(false);
    }
  }, [eventSaved]);

  useEffect(() => {
    if (
      redirectToEdit &&
      currentEvent?.id &&
      currentEvent?.status !== EventStatus.initial &&
      eventSaved &&
      currentCommunity &&
      isMatchedPath(history.location.pathname, [`/:community${EVENT}/:eventType${EVENT_NEW}`])
    ) {
      history.replace(`/${currentCommunity.code}${EVENT}/${currentEvent.code}/edit`);
    }
  }, [currentCommunity, currentEvent, eventSaved, history, redirectToEdit]);

  if (!initValue) {
    return null;
  }

  const allowedEditScopes =
    saveOptions === "publish" && currentEvent?.status === EventStatus.draft
      ? [EEventChangeScope.all]
      : [EEventChangeScope.current, EEventChangeScope.following];

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initValue}
      onSubmit={handlePublishSermon}
      enableReinitialize
      innerRef={formRef as any}
    >
      {({ values }) => {
        return (
          <Form>
            <AlertDialog
              open={isCancel || isLeavingBack}
              title={t(`leaving ${getMeetingType(values.type)}`)}
              message={t("You have unsaved changes that will be lost if you continue")}
              onConfirm={isCancel ? onCloseConfirmed : onBackConfirmed}
              onCancel={onLeavingCancelHandler}
              mode="confirm"
              confirmText={t("leave")}
            />
            <ChangeScopePopup
              variant="edit"
              opened={saveOptions !== null}
              eventType={values.type}
              scope={values.repeat !== EEventRepeat.never ? scope : undefined}
              allowedScopes={allowedEditScopes}
              onConfirm={() => {
                onSaveOptionsClose();
                if (saveOptions === "draft") {
                  handleSaveDraft(values);
                } else {
                  handlePublishSermon(values);
                }
              }}
              onCancel={onSaveOptionsClose}
              onScopeChange={onScopeChange}
            />
            <ChangeScopePopup
              variant="delete"
              opened={isRemove}
              eventType={values.type}
              scope={values.repeat !== EEventRepeat.never ? scope : undefined}
              onConfirm={() => values.id && handleRemoveEvent(values.id)}
              onCancel={() => setIsRemove(false)}
              onScopeChange={onScopeChange}
            />
            {!eventType && !currentEvent && !communityCreation ? null : (
              <StreamEvent
                event={currentEvent}
                onCloseSermon={onCloseSermon}
                showRemoveAlert={onRemove}
                handlePublish={onSave()}
                handleSaveDraft={onSave(true)}
                setValidationSchema={setValidationSchema}
                isMeetingEvent={eventType === EventType.meeting || currentEvent?.type === EventType.meeting}
                isDefaultEvent={isDefaultEvent}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SermonDetails;
