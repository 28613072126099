import React, { useEffect, useRef } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { AlertDialog, StyledTextField } from "shared";

import "./styles.scss";

type Props = {
  opened: boolean;
  onClose: () => void;
  variant?: "brown";
  initialValue: string;
  onConfirm: (values: { title: string }) => void;
};

const GreeterTitleAlert: React.FC<Props> = ({ opened, onClose, variant, initialValue, onConfirm }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (opened) {
      inputRef?.current?.focus();
    }
  }, [opened]);

  return (
    <Formik
      validationSchema={Yup.object({ title: Yup.string().max(20, "Title is too long") })}
      initialValues={{ title: initialValue }}
      enableReinitialize
      onSubmit={onConfirm}
    >
      {({ values, errors, handleChange, handleSubmit }) => {
        return (
          <AlertDialog
            open={opened}
            title="Edit Host Title"
            mode="confirm"
            confirmText="save"
            onConfirm={handleSubmit}
            onCancel={onClose}
            variant={variant}
          >
            <div className="renameGreeterTitle">
              <div className={`renameGreeterTitle-description  ${variant ? "variantBrown" : ""}`}>
                You can edit the title tag next to each Host's name that are visible to all attendees in the Member
                list.
              </div>
              <Form className={`renameGreeterTitle-form ${variant ? "variantBrown" : ""}`}>
                <StyledTextField
                  inputRef={inputRef}
                  fullWidth
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  errors={errors}
                />
              </Form>
            </div>
          </AlertDialog>
        );
      }}
    </Formik>
  );
};

export default GreeterTitleAlert;
