import firebase from "firebase/app";

import "firebase/analytics";
import config from "../config";
import store from "..";
import { checkRoles } from "./ACL";

import { ICommunity, ICommunitySubscriptions } from "containers/Community";
import { FIREBASE_EVENTS, FirebaseJoinEventPayload, FirebasePurchasePayload } from "shared/interfaces/Firebase";
import { ERoles, IUser } from "containers/Auth/interfaces";
import { EventType, IEvent } from "containers/Sermons/interfaces";

if (config.initFirebase) {
  const firebaseConfig = {
    apiKey: "AIzaSyBTxPSkLFlI6-eegAYiik3gjz6t68AQ-zo",
    authDomain: "altar-app.firebaseapp.com",
    databaseURL: "https://altar-app.firebaseio.com",
    projectId: "altar-app",
    storageBucket: "altar-app.appspot.com",
    messagingSenderId: "171630015056",
    appId: "1:171630015056:web:4949e8d09d18929640d755",
    measurementId: "G-XY97EYPXM8",
  };
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

const handleFirebaseEvent = (event_name: FIREBASE_EVENTS, payload: Record<string, any>) => {
  if (config.initFirebase) {
    if (store && !payload.user_id) {
      const {
        auth: { user },
      } = store.getState();

      if (user) {
        payload.user_id = user.id;
        payload.name = `${user.first_name} ${user.last_name}`;
        payload.email = user.email;
      }
    }

    if (!payload.user_id) {
      payload.user_id = null;
    }

    firebase.analytics().logEvent(event_name as string, payload);
  }
};

export const handleFirebasePurchase = (currentCommunity: ICommunity, subscriptions: ICommunitySubscriptions) => {
  const {
    currentSubscriptions: [subscription],
  } = subscriptions;

  const firebaseData: FirebasePurchasePayload = {
    product: subscription.title,
    value: subscription.price || "",
    stripe_status: "active",
    community_name: currentCommunity.name,
    community_id: currentCommunity.id,
  };
  handleFirebaseEvent(FIREBASE_EVENTS.PURCHASE, firebaseData);
};

export const handleFirebaseCreateEvent = (event: IEvent, isPaidSubscription: boolean) => {
  if (!config.initFirebase) {
    return;
  }
  const { community_id, community, starting_at } = event;
  const timeFormatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "America/New_York",
  });
  const firebaseData = {
    community_id: community_id,
    community_name: community?.name,
    is_free: isPaidSubscription ? "no" : "yes",
    event_name: event.name,
    event_date_time: timeFormatter.format(new Date(starting_at)),
  };
  firebase.analytics().logEvent(FIREBASE_EVENTS.CREATE_EVENT, firebaseData);
};

export const handleFirebaseJoinEvent = (currentUser: IUser | null, event: IEvent, customName?: string) => {
  if (!event) {
    return;
  }
  const community = event?.community;
  const isAdmin = checkRoles([ERoles.admin, ERoles.manager], community?.id);

  const firebaseData: FirebaseJoinEventPayload = {
    community_id: community?.id,
    community_name: community?.name,
    event_id: event.id,
    event_name: event.name,
    is_admin: isAdmin ? 1 : 0,
  };
  if (!currentUser) {
    firebaseData.name = customName;
  }
  if (event.type === EventType.meeting) {
    firebaseData.meeting_type = event.is_default ? "personal" : "scheduled";
  }
  handleFirebaseEvent(
    event.type === EventType.meeting ? FIREBASE_EVENTS.JOIN_MEETING : FIREBASE_EVENTS.JOIN_EVENT,
    firebaseData,
  );
};

export { firebase, handleFirebaseEvent };
