import React from "react";

import information_analytics from "assets/icons/information-blue.svg";

import "./index.scss";

interface IProps {
  title: string;
  info: string;
}

const AnalyticsTitle: React.FC<IProps> = ({ title, info }) => {
  return (
    <div className="analyticsTitle">
      {title}
      <div className="analyticsTitle-info">
        <img src={information_analytics} alt="information-analytics" />
        <div className="analyticsTitle-info-card">{info}</div>
      </div>
    </div>
  );
};

export default AnalyticsTitle;
