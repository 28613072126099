import React from "react";

import { EventType } from "containers/Sermons/interfaces";

import "./styles.scss";

type Props = {
  type: EventType;
  isDisabled: boolean;
  onSelect: (type: EventType) => void;
  showPremium: boolean;
  info: {
    imgSrc: string;
    name: string;
    description: string;
  };
};
const EventTypeCard: React.FC<Props> = ({
  isDisabled,
  onSelect,
  type,
  showPremium,
  info: { imgSrc, name, description },
}) => {
  return (
    <div className={`event-type  ${isDisabled ? "event-type--disabled" : ""}`} onClick={() => onSelect(type)}>
      {showPremium ? <div className="event-type__badge">PREMIUM FEATURE</div> : null}
      <img className="event-type__icon" src={imgSrc} alt={type} />
      <div className="event-type__name">{name}</div>
      <div className="event-type__description">{description}</div>
    </div>
  );
};

export default EventTypeCard;
