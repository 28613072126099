import React from "react";
import { FieldAttributes } from "formik";

import { StyledTextField } from "../StyledTextField";

import "./styles.scss";

const SquareTextField: React.FunctionComponent<FieldAttributes<any>> = props => (
  <StyledTextField {...props} className={`${props.className || ""} square-text-field`} />
);

export default SquareTextField;
