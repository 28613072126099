import React from "react";
import { useSelector } from "react-redux";
import { Route, RouteProps, Redirect } from "react-router-dom";

import { authentificated } from "../Auth/store/selectors";
import { LOGIN_REDIRECT_LINK } from "./constants";

const PrivateRoute: React.FunctionComponent<RouteProps> = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(authentificated());

  return (
    <Route
      {...rest}
      render={props =>
        Component && isAuthenticated ? <Component {...props} /> : <Redirect to={LOGIN_REDIRECT_LINK} />
      }
    />
  );
};

export default PrivateRoute;
