import React from "react";
import classnames from "classnames";

import { IHeader } from "../../interfaces";
import { SubscriptionAttentionBanner } from "../AttentionBanner";

import "./header.scss";

const Header = (props: IHeader) => {
  const { title, isLightBlue, children, className, onTitleClick, subscriptionAttention = false } = props;
  return (
    <div
      className={classnames("header", className, {
        "header-isLightBlue": isLightBlue,
        subscriptionAttention,
      })}
    >
      {subscriptionAttention && <SubscriptionAttentionBanner />}
      {title && (
        <div className={classnames("header-title", { "header-title-active": !!onTitleClick })} onClick={onTitleClick}>
          {title}
        </div>
      )}
      {children && <div className="header-right">{children}</div>}
    </div>
  );
};

export default Header;
