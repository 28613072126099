export enum ECreationKind {
  community = "community",
  event = "event",
  invitation = "invitation",
}
export enum ECreationPlatform {
  web = "web",
  android = "android",
  ios = "ios",
}
