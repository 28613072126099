import * as React from "react";

type Props = {
  muted?: HTMLVideoElement["muted"];
  width?: HTMLVideoElement["width"];
  height?: HTMLVideoElement["height"];
  srcObject: any;
};

const Video = ({ width, height, srcObject, ...rest }: Props) => {
  const videoRef = React.createRef<HTMLVideoElement>();

  React.useEffect(() => {
    const video = videoRef?.current;

    if (video && srcObject !== null) {
      video.srcObject = srcObject;
      if (video.readyState > 2) {
        video.play();
      }
    }
  }, [srcObject, videoRef]);

  return srcObject && <video src={srcObject} ref={videoRef} playsInline autoPlay {...rest} />;
};

export default React.memo(Video);
