import React from "react";

import { CustomAvatar } from "../CustomAvatar";
import { DEFAULT_GREETER_MESSAGE } from "../../../Sermons/constants";
import { IEvent } from "../../../Sermons";

import { timeToAMPM } from "shared/utils/getStream";
import { CanView } from "shared/components";

interface ICustomGreeterChannel {
  event: IEvent | null;
  onReplyGreeterCustomCh: () => void;
}

const CustomGreeterChannel: React.FC<ICustomGreeterChannel> = props => {
  const { onReplyGreeterCustomCh, event } = props;
  const [greeter] = event?.greeters || [];

  return greeter ? (
    <div className="channelItem channelItem-greeter" onClick={() => onReplyGreeterCustomCh()}>
      <div className="channelItem-container">
        <CustomAvatar
          image={greeter.image_url || ""}
          name={`${greeter.first_name} ${greeter.last_name}`}
          size={40}
          color="#F0B48F"
          isOnline={greeter.is_active}
        />
        <div className="channelItem-content">
          <div className="channelItem-content_top">
            <div className="channelItem-name greeter">
              <div className="channelItem-name-value">
                {greeter.first_name} {greeter.last_name}
              </div>
              <div className="greeter-title">{greeter?.title || ""}</div>
            </div>
            <div className="channelItem-additional">
              <CanView condition>
                <div className="channelItem-new_message">1</div>
              </CanView>
              <div className="channelItem-date">{timeToAMPM(new Date(), { swapDay: true })}</div>
            </div>
          </div>
          <div className="channelItem-content_bottom">
            <p className="channelItem-content_bottom_text">{event?.greeter_message || DEFAULT_GREETER_MESSAGE}</p>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default React.memo(CustomGreeterChannel);
