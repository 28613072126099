import React from "react";
import { useFormikContext } from "formik";

import { ICommunityLandingSettings } from "../../../interfaces";

import browser_tab_example from "assets/icons/browser_tab_example.svg";
import favicon_placeholder from "assets/icons/favicon_placeholder.svg";
import { ImageUpload } from "shared";

import "./index.scss";

const AppearanceFavicon: React.FC = () => {
  const { values, handleChange } = useFormikContext<ICommunityLandingSettings>();
  return (
    <div className="appearance-favicon">
      <img src={browser_tab_example} alt="browser tab" className="appearance-favicon-tab-example" />
      <img src={values.favicon_url || favicon_placeholder} alt="favicon" className="appearance-favicon-placeholder" />
      <div className="appearance-favicon-select">
        <ImageUpload
          maxMb={1}
          value={values.favicon_url}
          onChange={handleChange("favicon_url")}
          askReplace
          conditionalConvertFormat
          hideUploadedImg
          imageMaxSize={16}
          imageMinSize={16}
        >
          {values.favicon_url ? <div className="appearance-favicon-select-replace">Replace Image</div> : "Choose Image"}
        </ImageUpload>
        <div className="appearance-favicon-select-description">Recommended image size 270px by 270px.</div>
      </div>
    </div>
  );
};

export default AppearanceFavicon;
