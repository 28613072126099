import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EventType, IEvent, SermonRestriction } from "../../../Sermons";
import { getMediaPermissions, getUserDevices } from "../../../BreakoutRooms/store/selectors";
import { MediaConstraints } from "../../../BreakoutRooms/constants";
import { getUserMediaDevices } from "../../../BreakoutRooms/store/actions";
import { Video } from "../../../BreakoutRooms/components";
import { AttendeePic } from "../../../BreakoutRooms/components/BreakoutRoom/AttendeePic";
import { getEventMembers, getWaitingToJoinMembers } from "../../../Sermons/store/selectors";
import { loadClassRooms, setPreJoinPage } from "../../store/actions";
import PermissionsAlert from "../../../BreakoutRooms/components/EventSeats/PermissionsAlert/PermissionsAlert";
import { getMember } from "../../../Auth/store/selectors";
import { selectClassrooms } from "../../store/selectors";
import { knockJoinMeeting } from "../../../Sermons/store/actions";

import {
  changeMediaConstraints,
  getStreamDevicesValue,
  prepareDropDownMediaItems,
  storageMediaDevicesInfo,
} from "shared/utils/mediaStreamHelpers";
import { AlertDialog, Button, DropDown, useMediaDevicesHook } from "shared";
import prejoinPage_mic from "assets/icons/prejoinPage_mic.svg";
import prejoinPage_camera from "assets/icons/prejoinPage_camera.svg";
import disable_micro from "assets/icons/disable-micro.svg";
import enable_micro from "assets/icons/enable-micro.svg";
import disable_video from "assets/icons/disable-video.svg";
import enable_video from "assets/icons/enable-video.svg";
import join_event_processing from "assets/images/join_event_processing.gif";

import "./preJoinPage.scss";

interface Props {
  event: IEvent;
  canShowKnockPopup: boolean;
}

const PreJoinPage: React.FC<Props> = ({ event, canShowKnockPopup }) => {
  const dispatch = useDispatch();
  const { setMediaDevices, audioMuted, videoMuted } = useMediaDevicesHook(event);

  const userDevices = useSelector(getUserDevices());
  const mediaPermissions = useSelector(getMediaPermissions());
  const streamMembers = useSelector(getEventMembers());
  const member = useSelector(getMember());
  const classrooms = useSelector(selectClassrooms());
  const waitingToJoinMembers = useSelector(getWaitingToJoinMembers());

  const initConstraints = useMemo(() => {
    const devicesInfo = storageMediaDevicesInfo.get();
    return changeMediaConstraints(
      [devicesInfo?.audio?.label, devicesInfo?.video?.label],
      userDevices,
      MediaConstraints,
    );
  }, [userDevices]);

  const [stream, setStream] = useState<null | MediaStream>(null);
  const [constraints, setConstraints] = useState<MediaStreamConstraints>(initConstraints);
  const [mediaPermissionAlert, setMediaPermissionAlert] = useState(true);
  const [openAudioBlockedAlert, setOpenAudioBlockedAlert] = useState(false);
  const [requestedJoin, setRequestedJoin] = useState(false);

  const { start_with_audio_muted } = event;

  const { audio, video } = useMemo(() => getStreamDevicesValue(stream), [stream]);

  const shouldMakeRequest = useMemo(() => {
    return (
      event.is_locked &&
      member &&
      !canShowKnockPopup &&
      !(event.restriction === SermonRestriction.invited && event.attendees.some(m => m.id === member.id))
    );
  }, [event, canShowKnockPopup, member]);

  const dropDownMediaItems = useMemo(() => prepareDropDownMediaItems(userDevices), [userDevices]);

  const onMediaDevicesChange = useCallback(
    value => {
      setConstraints(changeMediaConstraints([value.toString()], userDevices, constraints));
    },
    [constraints, userDevices],
  );

  const handleLeavePreJoinPage = useCallback(() => {
    if (requestedJoin) {
      return;
    }
    if (shouldMakeRequest && member) {
      dispatch(knockJoinMeeting.request({ member, method: "knockJoinMeeting" }));
      return;
    }
    dispatch(setPreJoinPage(false));
  }, [dispatch, member, requestedJoin, shouldMakeRequest]);

  const handleMicMuted = () => {
    if (start_with_audio_muted) {
      setOpenAudioBlockedAlert(true);
    } else if (audioMuted !== null) {
      setMediaDevices({ audioMuted: !audioMuted });
      stream?.getAudioTracks().forEach(t => (t.enabled = audioMuted));
    }
  };

  const setStreamHandler = useCallback(() => {
    if (userDevices) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => setStream(stream))
        .catch(() => setConstraints({ ...constraints, video: false }));
    }
  }, [constraints, userDevices]);

  const handleCameraMuted = () => {
    if (videoMuted) {
      if (mediaPermissions === "granted") {
        setStreamHandler();
      }
    } else {
      stream?.getVideoTracks().forEach(t => t.stop());
    }
    setMediaDevices({ videoMuted: !videoMuted });
  };

  useEffect(() => {
    dispatch(getUserMediaDevices.request());
  }, [dispatch, mediaPermissions]);

  useEffect(() => {
    if (mediaPermissions === "granted") {
      setStreamHandler();
    }
  }, [mediaPermissions, setStreamHandler]);

  useEffect(() => {
    return () => {
      stream?.getTracks?.()?.forEach((track: MediaStreamTrack) => {
        track?.stop?.();
      });
    };
  }, [stream]);

  useEffect(() => {
    if (!classrooms.length && event && member && event.type === EventType.meeting) {
      dispatch(loadClassRooms.request(event.id));
    }
  }, [dispatch, classrooms, event, member]);

  useEffect(() => {
    if (start_with_audio_muted && !audioMuted) {
      setMediaDevices({ audioMuted: true });
    }
  }, [dispatch, audioMuted, setMediaDevices, start_with_audio_muted]);

  useEffect(() => {
    setRequestedJoin(waitingToJoinMembers.some(waitingMember => waitingMember.member.id === member?.id));
    return () => {
      setRequestedJoin(false);
    };
  }, [waitingToJoinMembers, member]);

  return (
    <div className="preJoinPage-wrapper">
      <div className="preJoinPage">
        <AlertDialog
          open={openAudioBlockedAlert}
          title="The Host Has Turned Off Everyone's Mic"
          message="The Host has turned off everyone's microphone when they join the meeting. You will be able to turn on your microphone once you are joined to the Meeting."
          onConfirm={() => setOpenAudioBlockedAlert(false)}
          onCancel={() => setOpenAudioBlockedAlert(false)}
          mode="info"
          confirmText="Got It"
          variant="brown"
          dialogClassName="preJoinPage-blocked-audio"
        />
        {mediaPermissionAlert && (
          <PermissionsAlert joinToRoom={() => {}} setMediaPermissionsAlert={setMediaPermissionAlert} />
        )}
        <div className="preJoinPage-settings">
          <div className="preJoinPage-settings-title">Join Meeting:</div>
          <div className="preJoinPage-settings-name">{event.name}</div>
          {userDevices && (
            <div className="preJoinPage-settings-dropDown">
              <DropDown
                items={dropDownMediaItems["audioinput"]}
                onChange={onMediaDevicesChange}
                size="full"
                spacer={false}
                variant="brown"
                value={audio?.label}
                placeholderImg={prejoinPage_mic}
              />
              <DropDown
                items={dropDownMediaItems["videoinput"]}
                onChange={onMediaDevicesChange}
                size="full"
                spacer={false}
                variant="brown"
                value={video?.label}
                placeholderImg={prejoinPage_camera}
              />
            </div>
          )}
          <div className={`preJoinPage-settings-join ${requestedJoin && "requestedJoin"}`}>
            <Button width={420} variant="orange" onClick={handleLeavePreJoinPage}>
              {requestedJoin ? (
                <>
                  <img src={join_event_processing} alt="spin" />
                  Waiting for Host to Let You In
                </>
              ) : shouldMakeRequest ? (
                "Request Permission to Enter"
              ) : (
                "Join Meeting"
              )}
            </Button>
          </div>
        </div>
        <div className="preJoinPage-video">
          <div className="preJoinPage-video-mute">
            <div className={`preJoinPage-video-mute-mic ${audioMuted && "micMuted"}`} onClick={handleMicMuted}>
              <img src={audioMuted ? disable_micro : enable_micro} alt="audio" />
            </div>
            <div className={`preJoinPage-video-mute-camera ${videoMuted && "cameraMuted"}`} onClick={handleCameraMuted}>
              <img src={videoMuted ? disable_video : enable_video} alt="video" />
            </div>
          </div>
          {mediaPermissions === "granted" && !videoMuted ? (
            <Video srcObject={stream} muted={true} width={470} height={264} />
          ) : (
            <div className="preJoinPage-video-avatar">
              {member && <AttendeePic streamMembers={streamMembers} member={member} size={140} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreJoinPage;
