import { useTranslation } from "react-i18next";
import React from "react";

import no_matches_bg from "assets/icons/no_matches_bg.svg";

import "./noMatchesFound.scss";

interface NoMatchesFoundProps {
  text?: string;
}

const NoMatchesFound: React.FC<NoMatchesFoundProps> = props => {
  const { text = "No People Found" } = props;
  const { t } = useTranslation();
  return (
    <div className="noMatchesFound">
      <img className="noMatchesFound-img" src={no_matches_bg} alt="no_matches_bg" />
      <h4 className="noMatchesFound-text">{t(text)}</h4>
    </div>
  );
};

export default NoMatchesFound;
