import React, { useCallback, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { NameOfRoutes, NamesOfParentRoutes } from "../../../../../constants";
import { actions } from "../../../../Onboarding/store";

import { AlertDialog, CustomFormikField, EventSideDialog, ImageUpload, SquareButton } from "shared/components";
import { updateEventSettings } from "containers/Sermons/store/actions";
import { EventDashboardTabs } from "containers/Sermons/interfaces";
import { signUpValidationSchema } from "containers/Sermons/validators";
import { IPersonValues } from "containers/Onboarding/interfaces";
import { preparePersonForm } from "containers/Onboarding/utils";
import { getCommunityInvitationHash } from "containers/Community/store/selectors";
import { getImageBase64 } from "shared/utils/dataModifiers";
import { getEmailIsExist, getUserId } from "containers/Onboarding/store/selectors";
import { getRegistrationData, isEmailResent } from "containers/Auth/store/selectors";
import EmailSent from "containers/Auth/components/EmailSent";
import { clearOnboardingStore, sendUserConfirmationEmail } from "containers/Onboarding/store/actions";
import { getTokenByHash, setEmailResent, setRegistrationData } from "containers/Auth/store/actions";
import loginHashHandler from "shared/utils/loginHashHandler";
import empty_person_purple from "assets/icons/empty_person_purple.svg";
import { ECreationKind } from "shared";

import "./styles.scss";

const { STREAM } = NamesOfParentRoutes;
const { EVENT_AUTH_SUCCESS } = NameOfRoutes;

interface IEventAuthDialog {
  open: boolean;
  onClose?: () => void;
  message?: string;
  onSuccess?: () => void;
}

const EventSignUpDialog: React.FC<IEventAuthDialog> = props => {
  const { open, onClose } = props;

  const communityInvitationHash = useSelector(getCommunityInvitationHash());
  const userId = useSelector(getUserId());
  const emailExists = useSelector(getEmailIsExist());
  const emailResent = useSelector(isEmailResent());
  const registrationData = useSelector(getRegistrationData());

  const [personData, setPersonData] = useState<IPersonValues>(preparePersonForm());
  const [closeOption, setCloseOption] = useState<"close" | "back" | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (registrationData) {
      setPersonData(prevState => ({ ...prevState, ...registrationData }));
    }
  }, [registrationData]);

  const redirectTo = encodeURIComponent(`${STREAM}${EVENT_AUTH_SUCCESS}`);

  const handleCreateProfile = useCallback(
    (values: IPersonValues) => () => {
      if (communityInvitationHash) {
        const { image_url, ...rest } = values;
        const image_base64 = getImageBase64(image_url, personData.image_url !== image_url);
        const hash = loginHashHandler.generate();
        dispatch(
          actions.createProfile.request({
            data: { ...rest, image_base64, redirectTo, hash },
            token: communityInvitationHash,
            shouldLogin: false,
            kind: ECreationKind.event,
            callback: () => {
              setPersonData(values);
            },
          }),
        );
        dispatch(getTokenByHash(hash));
      }
    },
    [communityInvitationHash, dispatch, redirectTo, personData],
  );

  const handleSubmit = (values?: IPersonValues) => {
    if (values) {
      dispatch(
        actions.checkEmail.request({
          email: values.email,
          callback: handleCreateProfile(values),
        }),
      );
    }
  };

  const clearStore = useCallback(() => {
    dispatch(setEmailResent(false));
    dispatch(clearOnboardingStore());
    dispatch(actions.checkEmail.success(false));
    loginHashHandler.remove();
  }, [dispatch]);

  const backHandler = (values?: IPersonValues) => {
    clearStore();
    if (values) {
      const { password, password_confirm, ...rest } = values;
      dispatch(setRegistrationData({ ...rest, image_url: rest.image_url || null }));
    }

    dispatch(updateEventSettings({ activeDashboardTab: EventDashboardTabs.auth }));
    setCloseOption(null);
  };

  const onCloseConfirmed = () => {
    if (closeOption === "back") {
      backHandler();
    } else {
      onClose?.();
      setCloseOption(null);
    }
  };

  const closeDialogHandler = (values: IPersonValues) => {
    if (!_.isEqual(values, personData)) {
      setCloseOption("close");
    } else {
      onCloseConfirmed();
    }
  };

  const backDialogHandler = (values: IPersonValues) => {
    if (!_.isEqual(values, personData)) {
      setCloseOption("back");
    } else {
      backHandler();
    }
  };

  useEffect(() => clearStore, [clearStore]);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={signUpValidationSchema}
      onSubmit={handleSubmit}
      initialValues={personData}
      enableReinitialize
    >
      {({ values, errors, handleChange, handleSubmit, validateField, setFieldValue }) => {
        const { email, password, password_confirm, image_url, first_name, last_name, phone_number } = values;

        const changeFieldHandler =
          (field: string) => async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            await setFieldValue(field, e.target.value);
            validateField(field);
          };

        const handleResendEmail = () => {
          const hash = loginHashHandler.generate();
          dispatch(
            sendUserConfirmationEmail.request({
              email,
              hash,
              redirectTo,
              showNotification: false,
              callback: () => {
                dispatch(setEmailResent(true));
              },
            }),
          );
        };

        return (
          <>
            <AlertDialog
              open={!!closeOption}
              title="Leave without changes"
              message="You have unsaved changes that will be lost if you leave now. Are you sure you want to leave? "
              onConfirm={onCloseConfirmed}
              onCancel={() => setCloseOption(null)}
              mode="confirm"
              confirmText="Leave"
              cancelText="Stay"
              variant="brown"
              dialogClassName="eventSignUpDialog-alert"
            />
            <EventSideDialog
              title={userId ? "" : "Sign Up"}
              open={open}
              onClose={userId ? undefined : () => closeDialogHandler(values)}
              onBack={userId ? undefined : () => backDialogHandler(values)}
            >
              <div className="eventSignUpDialog">
                {userId ? (
                  <EmailSent
                    title={emailResent ? "Email has been resent" : "You’re almost finished!"}
                    subTitle={
                      <>
                        Email has been sent to {values.email}.
                        <br />
                        Please check your inbox to continue.
                      </>
                    }
                    onChangeEmail={() => backHandler(values)}
                    onResendEmail={handleResendEmail}
                    eventPage
                  />
                ) : (
                  <>
                    <div className="eventSignUpDialog-description">
                      <p>Watching with others is very private and we must collect your info. Please enter it below</p>
                    </div>
                    {emailExists && (
                      <p className="eventSignUpDialog-error">User with this email already exists in the system</p>
                    )}
                    <div className="eventSignUpDialog-container">
                      <Form>
                        <div className="eventSignUpDialog-row avatar">
                          <ImageUpload
                            showOptions
                            maxMb={2}
                            value={image_url}
                            onChange={handleChange("image_url")}
                            backgroundImage={`url(${empty_person_purple})`}
                            variant="circle"
                            theme="dark"
                          />
                        </div>
                        <div className="eventSignUpDialog-row">
                          <CustomFormikField
                            fullWidth
                            label="First Name"
                            placeholder=""
                            name="first_name"
                            errors={errors}
                            value={first_name}
                            onChange={changeFieldHandler("first_name")}
                            onBlur={() => validateField("first_name")}
                          />
                          <CustomFormikField
                            fullWidth
                            label="Last Name"
                            placeholder=""
                            name="last_name"
                            errors={errors}
                            value={last_name}
                            onChange={changeFieldHandler("last_name")}
                            onBlur={() => validateField("last_name")}
                          />
                        </div>
                        <div className="eventSignUpDialog-row">
                          <CustomFormikField
                            fullWidth
                            label="Email Address"
                            placeholder=""
                            name="email"
                            errors={errors}
                            value={email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setFieldValue("email", e.target.value.trim())
                            }
                            onBlur={() => validateField("email")}
                          />
                        </div>
                        <div className="eventSignUpDialog-row">
                          <CustomFormikField
                            fullWidth
                            label="Phone Number"
                            name="phone_number"
                            errors={errors}
                            value={phone_number}
                            onChange={handleChange}
                            onBlur={() => validateField("phone_number")}
                          />
                        </div>
                        <div className="eventSignUpDialog-row">
                          <CustomFormikField
                            fullWidth
                            label="Create Password"
                            placeholder=""
                            name="password"
                            errors={errors}
                            value={password}
                            type="password"
                            autoComplete="new-password"
                            theme="dark"
                            onChange={handleChange}
                            onBlur={() => validateField("password")}
                          />
                        </div>
                        <div className="eventSignUpDialog-row">
                          <CustomFormikField
                            fullWidth
                            label="Confirm Password"
                            placeholder=""
                            name="password_confirm"
                            errors={errors}
                            value={password_confirm}
                            type="password"
                            theme="dark"
                            onChange={handleChange}
                            onBlur={() => validateField("password_confirm")}
                          />
                        </div>
                        <div className="eventSignUpDialog-row submit">
                          <SquareButton
                            radius={9}
                            width={96}
                            height={32}
                            variant="blue"
                            type="button"
                            onClick={() => handleSubmit()}
                          >
                            Sign Up
                          </SquareButton>
                        </div>
                      </Form>
                    </div>
                  </>
                )}
              </div>
            </EventSideDialog>
          </>
        );
      }}
    </Formik>
  );
};

export default EventSignUpDialog;
