import React from "react";
import classnames from "classnames";
import moment from "moment";

import { TIME_PICKER_FORMAT } from "../../constants";

import blue_clock from "assets/icons/blue_clock.svg";

import "./customTimePickerInput.scss";

const CustomTimePickerInput: React.FunctionComponent<any> = ({ value, onClick, label, classes }) => {
  return (
    <div className={`timePicker-wrapper ${classes || ""}`}>
      {label && <span className="pickerLabel">{label}</span>}
      <div className={classnames("timePicker", label && "timePicker-label")} onClick={onClick}>
        <span>{moment(value).format(TIME_PICKER_FORMAT)}</span>
        <img src={blue_clock} alt="clock icon" />
      </div>
    </div>
  );
};
export default CustomTimePickerInput;
