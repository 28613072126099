import React, { useCallback } from "react";

import "./switchControl.scss";

type Props = {
  checked: boolean;
  disabled?: boolean;
  width?: number;
  textOn?: string;
  textOff?: string;
  onChange?: () => void;
};

const SwitchControl: React.FC<Props> = ({
  checked,
  textOn = "On",
  textOff = "Off",
  disabled = false,
  width,
  onChange,
}) => {
  const clickHandler = useCallback((): void => {
    if (!disabled && onChange) {
      onChange();
    }
  }, [disabled, onChange]);
  return (
    <div className={`switch-control ${disabled ? "disabled" : ""}`}>
      <span className="switch-control-root" onClick={clickHandler} style={width ? { width } : {}}>
        <span className={`switch-control-base ${checked ? "switch-control-checked" : ""}`}>
          <span className="switch-control-label">
            <span className="switch-control-thumb label">{checked ? textOn : textOff}</span>
          </span>
        </span>
        <span className="switch-control-track">
          <span className="label">{textOn}</span>
          <span className="label">{textOff}</span>
        </span>
      </span>
    </div>
  );
};
export default SwitchControl;
