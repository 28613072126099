import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Chat,
  Channel,
  ChannelList,
  Window,
  InfiniteScrollPaginator,
  Thread,
  useChatContext,
  ChatContextValue,
} from "stream-chat-react";
import { Channel as StreamChatChannel, ChannelFilters } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import classnames from "classnames";
import lodash from "lodash";

import { useGetStream } from "../../../../shared/hooks/GetStreamHook";
import * as components from "../../components";
import * as communitySelectors from "../../../Community/store/selectors";
import * as authSelectors from "../../../Auth/store/selectors";
import {
  clearCreateChatWithMember,
  clearGetStreamChannels,
  clearSelectedChannel,
  createNewChat,
  deleteChannel,
  getAnonymousChannels,
  getChannels,
  leaveChannel,
  setEventChannelsIds,
  showChatList,
  updateChannel,
} from "../../store/actions";
import * as selectors from "../../store/selectors";
import {
  ICreateNewChat,
  IGetChannels,
  EditMessageObj,
  IChannel,
  IUpdateChannel,
  IChannelTypes,
} from "../../interfaces";
import { IMember } from "../../../Member";
import {
  CustomChannelHeader,
  CustomMessageList,
  CustomMessageInputWrapper,
  CustomDateSeparator,
  EmptyChatState,
} from "../../components";
import { ChannelEditDialog } from "../../components/ChannelEditDialog";
import { authentificated } from "../../../Auth/store/selectors";
import { LoginButtons } from "../../components/LoginButtons/LoginButtons";
import { getCreateChatWithMember, getSelectedChannelDetails, showGreeterCustomChannel } from "../../store/selectors";
import { getSubscriptionAttention } from "../../../Community/store/selectors";
import { EMPTY_CHANNELS_FILTER, MAX_QUERY_CHANNELS_LIMIT } from "../../constants";
import { isHubspotInited } from "../../../App/store/selectors";

import { hideWidget, showWidget } from "utils/hubspot";
import { SubscriptionAttentionBanner } from "shared/components/AttentionBanner";
import { useGreetingChannel } from "shared";
import { useMemberCID } from "shared/hooks/MemberCIDHook";
import { getEvent } from "containers/Sermons/store/selectors";
import { CustomChannelList } from "containers/Discussions";
import { Button, Loader } from "shared/components";
import { CanView } from "shared/components/CanView";
import { EEmptyStateTextType, TSelectMembersModal } from "shared/components/SelectMembersModal/interfaces";
import { SelectMembersModal } from "shared/components/SelectMembersModal";
import useDebounce from "shared/hooks/DebounceHook/DebounceHook";
import cross_arrow_white from "assets/icons/cross_arrow_white.svg";

import "./discussions.scss";

interface DiscussionsContainerProps {
  isEventDiscussions: boolean;
  isPartialView: boolean;
  theme?: DiscussionTheme;
  onLogin?: () => void;
  onSignup?: () => void;
  onReplyGreeterCustomCh: () => void;
  openRestrictionAlert: () => void;
}

enum DiscussionMode {
  list = "list",
  chat = "chat",
  all = "all",
}

export type DiscussionTheme = "stream";

const emptyEditMessage = {};

interface ChatContextProxyProps {
  onContextLoaded: (context: ChatContextValue) => void;
}

const ChatContextProxy: React.FC<ChatContextProxyProps> = ({ onContextLoaded }) => {
  const chatContext = useChatContext();

  useEffect(() => {
    onContextLoaded(chatContext);
  }, [onContextLoaded, chatContext]);
  return <></>;
};

const DiscussionsContainer: React.FC<DiscussionsContainerProps> = props => {
  const { isEventDiscussions, isPartialView, theme, onLogin, onSignup, onReplyGreeterCustomCh, openRestrictionAlert } =
    props;

  const dispatch = useDispatch();
  const chatClient = useGetStream();
  const cid = useMemberCID();
  const { findMembersChannel } = useGreetingChannel();

  const isAuthenticated = useSelector(authentificated());
  const createChatWithMember = useSelector(getCreateChatWithMember());
  const community = useSelector(communitySelectors.getCommunity());
  const channels = useSelector(selectors.getChannels());
  const chatListStatus = useSelector(selectors.getShowChatList());
  const member = useSelector(authSelectors.getMember());
  const event = useSelector(getEvent());
  const isGreeterCustomChannel = useSelector(showGreeterCustomChannel());
  const channelIds = useSelector(selectors.getEventChannelIds());
  const selectedChannel = useSelector(getSelectedChannelDetails());
  const subscriptionAttention = useSelector(getSubscriptionAttention());
  const hubspotInited = useSelector(isHubspotInited());

  const [editMessageDetails, setEditMessageDetails] = useState<EditMessageObj>(emptyEditMessage);
  const [showAddNewChannel, setShowAddNewChannel] = useState<boolean>(false);
  const [discussionMode, setDiscussionMode] = useState<DiscussionMode>(
    isEventDiscussions ? DiscussionMode.list : DiscussionMode.all,
  );
  const [streamChatChannels, setStreamChatChannels] = useState<StreamChatChannel[]>([]);
  const [search, setSearch] = useState<string>();
  const [showEditChannel, setShowEditChannel] = useState<boolean>(false);
  const [chatContext, setChatContext] = useState<ChatContextValue>();

  const debounceSearch = useDebounce(search, 500);

  const memberId = member?.id || cid;
  const eventId = event?.id;
  const communityId = community?.id;

  const isStreamTheme = useMemo<boolean>(() => {
    return theme === "stream";
  }, [theme]);

  const selectModalTitle = useMemo<string>(() => {
    switch (theme) {
      case "stream":
        return "New Conversation";
      default:
        return "Select Members";
    }
  }, [theme]);

  const filters = useMemo<ChannelFilters>(() => {
    return memberId
      ? {
          members: {
            $in: [memberId.toString()],
          },
        }
      : {};
  }, [memberId]);

  const reloadChannels = useCallback(() => {
    if (communityId) {
      const params: IGetChannels = {
        community_id: communityId,
        params: {
          search: debounceSearch,
        },
      };
      if (isAuthenticated) {
        dispatch(getChannels.request(params));
      } else {
        dispatch(getAnonymousChannels.request(params));
      }
      return () => {
        dispatch(getChannels.success([]));
      };
    }
  }, [isAuthenticated, communityId, debounceSearch, dispatch]);

  const setChatMode = useCallback(() => {
    setDiscussionMode(DiscussionMode.chat);
  }, []);

  const setListMode = useCallback(() => {
    setDiscussionMode(DiscussionMode.list);
  }, []);

  const channelsSearch = useCallback(
    (value: string) => {
      setSearch(value);
      if (value && selectedChannel && document.activeElement?.nodeName === "INPUT") {
        if (chatContext) {
          chatContext.setActiveChannel();
        }
        dispatch(clearSelectedChannel());
      }
    },
    [dispatch, chatContext, selectedChannel],
  );

  const createChatHandler = useCallback(
    (members: IMember[], threadName?: string) => {
      if (!member || !community) {
        return;
      }
      const membersChannel = findMembersChannel(members);
      const isChannelExist = membersChannel ? channelIds?.has(membersChannel.id) : false;
      const memberEmails = members.map(m => m.email);

      const data: ICreateNewChat = {
        community_id: community.id,
        members: [member.email, ...memberEmails],
        ...(event ? { meeting_id: event.id } : {}),
        sendGreeterMsg: isGreeterCustomChannel || !isChannelExist,
      };
      if (threadName) {
        data.name = threadName;
      }
      dispatch(createNewChat.request(data));
    },
    [member, community, dispatch, event, isGreeterCustomChannel, findMembersChannel, channelIds],
  );

  const updateChatHandler = useCallback(
    (channel: IChannel) => {
      if (!member) {
        return;
      }
      const { members } = channel;
      const memberEmails = members.map(m => m.email);
      const data: IUpdateChannel = {
        id: channel.id,
        community_id: channel.community_id,
        members: [member.email, ...memberEmails],
        ...(event ? { meeting_id: event.id } : {}),
      };

      if (channel.name) data.name = channel.name;
      dispatch(updateChannel.request(data));
      setShowEditChannel(false);
    },
    [member, dispatch, event],
  );

  const deleteChatHandler = useCallback(
    (channel: IChannel) => {
      const data = {
        community_id: channel.community_id,
        channel_id: channel.id,
      };
      dispatch(deleteChannel(data));
      setShowEditChannel(false);
      if (chatContext) {
        chatContext.setActiveChannel();
      }
      dispatch(clearSelectedChannel());
    },
    [dispatch, chatContext],
  );

  const leaveChatHandler = useCallback(
    (channel: IChannel) => {
      if (!community) {
        return;
      }
      dispatch(leaveChannel.request({ channel_id: channel.id, community_id: community.id }));
      setShowEditChannel(false);
    },
    [community, dispatch],
  );

  const handleStopEditing = useCallback(() => {
    setEditMessageDetails(emptyEditMessage);
  }, []);

  const membersSelect: TSelectMembersModal["onSelectWithName"] = useCallback(
    ({ name, members }) => {
      if (isEventDiscussions) {
        dispatch(clearSelectedChannel());
      }
      createChatHandler(members, name);
      setShowAddNewChannel(false);
    },
    [createChatHandler, isEventDiscussions, dispatch],
  );

  const onContextLoaded = useCallback(
    (context: ChatContextValue) => {
      if (!chatContext) {
        setChatContext(context);
      }
    },
    [chatContext],
  );

  useEffect(() => {
    reloadChannels();
  }, [reloadChannels]);

  useEffect(() => {
    if (!isEventDiscussions && hubspotInited) {
      hideWidget();
      return () => {
        showWidget();
      };
    }
  }, [hubspotInited, isEventDiscussions]);

  useEffect(() => {
    if (!createChatWithMember) {
      return;
    }
    createChatHandler([createChatWithMember]);
    dispatch(clearCreateChatWithMember());
  }, [dispatch, createChatWithMember, createChatHandler]);

  useEffect(() => {
    if (chatListStatus) {
      isEventDiscussions && isPartialView && setListMode();
    }
  }, [isEventDiscussions, isPartialView, chatListStatus, setListMode]);

  useEffect(() => {
    if (discussionMode === "chat") {
      dispatch(showChatList(false));
    } else {
      dispatch(showChatList(true));
    }
  }, [dispatch, discussionMode]);

  useEffect(
    () => () => {
      dispatch(clearGetStreamChannels());
      dispatch(clearSelectedChannel());
    },
    [dispatch],
  );

  const channelClasses = classnames("channel", {
    "channel-event": isEventDiscussions,
    "channel-fullWidth": isPartialView,
  });

  const channelListClasses = classnames("channelList", {
    "channelList-event": isEventDiscussions,
    "channelList-fullWidth": isPartialView,
    "channelList-hidden": isPartialView && discussionMode === DiscussionMode.chat,
  });

  useEffect(() => {
    (async () => {
      if (memberId && chatClient && channels?.length) {
        const channelIds = channels.map(({ id }) => id);
        const channelsChunks = lodash.chunk(channelIds, MAX_QUERY_CHANNELS_LIMIT);
        const fetchedChannels = await Promise.all(
          channelsChunks.map(chunk => {
            return chatClient.queryChannels(
              {
                members: {
                  $in: [memberId.toString()],
                },
                id: chunk.length ? { $in: chunk } : EMPTY_CHANNELS_FILTER,
                type: IChannelTypes.messaging,
              },
              {},
              { limit: chunk.length },
            );
          }),
        );
        setStreamChatChannels(fetchedChannels.flat());
      }
    })();
  }, [chatClient, memberId, channels]);

  useEffect(() => {
    const setOfChannelsIds: Set<string> = new Set();
    if (channels?.length && streamChatChannels.length) {
      if (eventId) {
        const streamChannelsObj: Record<string, StreamChatChannel> = {};
        streamChatChannels.forEach(strCh => {
          if (strCh.id) {
            streamChannelsObj[strCh.id] = strCh;
          }
        });
        channels.forEach(channel => {
          const messageLength = streamChannelsObj[channel.id]?.state?.messages?.length || 0;
          channel.meetings.find(meeting => meeting.id === eventId) &&
            messageLength > 0 &&
            setOfChannelsIds.add(channel.id);
        });
      } else {
        channels.forEach(channel => {
          if (channel.members.length > 1) {
            setOfChannelsIds.add(channel.id);
          }
        });
      }
      dispatch(setEventChannelsIds(setOfChannelsIds));
    }
  }, [dispatch, eventId, channels, streamChatChannels]);

  if (!chatClient) {
    return null;
  }
  return (
    <>
      <CanView condition={showAddNewChannel}>
        <SelectMembersModal
          useFilters
          selectText="create"
          open={showAddNewChannel}
          onClose={() => setShowAddNewChannel(false)}
          onSelect={() => {}}
          onSelectWithName={membersSelect}
          multiselect={true}
          withName={true}
          title={selectModalTitle}
          notFoundText="Looks like there is no member with this name in your communities"
          theme={theme}
          notAllowedMemberIds={member ? [member.id] : undefined}
          emptyStateTextType={EEmptyStateTextType.chat}
          styleTheme={"dark"}
        />
      </CanView>
      <CanView condition={showEditChannel}>
        <ChannelEditDialog
          onClose={() => setShowEditChannel(false)}
          member={member}
          onSave={updateChatHandler}
          onRemove={deleteChatHandler}
          onLeave={leaveChatHandler}
          open={showEditChannel}
        />
      </CanView>
      <Chat client={chatClient}>
        <ChatContextProxy onContextLoaded={onContextLoaded} />
        <div
          className={classnames(
            "channelWrap",
            { "channelWrap-event": isEventDiscussions && !isPartialView },
            theme && { [theme]: !!theme },
            { anonymous: !isAuthenticated },
            { subscriptionAttention },
          )}
        >
          {!isEventDiscussions && subscriptionAttention && <SubscriptionAttentionBanner />}
          <div className={channelListClasses}>
            {!isEventDiscussions && (
              <components.ChannelListHeader
                onSearch={channelsSearch}
                onAddNewChannel={() => setShowAddNewChannel(true)}
              />
            )}
            <ChannelList
              setActiveChannelOnMount={false}
              onAddedToChannel={reloadChannels}
              onRemovedFromChannel={reloadChannels}
              onChannelDeleted={reloadChannels}
              onChannelTruncated={reloadChannels}
              onChannelUpdated={reloadChannels}
              LoadingErrorIndicator={() => <Loader />}
              filters={filters}
              Paginator={({ children, ...props }) => <InfiniteScrollPaginator {...props} />}
              Preview={props => (
                <components.ChannelItem
                  {...props}
                  channels={channels}
                  member={member}
                  onSelectChannel={isEventDiscussions && isPartialView ? setChatMode : void 0}
                  showUnreadForActive={isPartialView}
                  showUnreadCount={!isEventDiscussions}
                />
              )}
              List={listProps => (
                <CustomChannelList
                  {...listProps}
                  onChangeChannel={isEventDiscussions && isPartialView ? setChatMode : void 0}
                  theme={theme}
                  onReplyGreeterCustomCh={onReplyGreeterCustomCh}
                  isEventDiscussions={isEventDiscussions}
                  channels={streamChatChannels}
                />
              )}
            />
            {isStreamTheme &&
              (isAuthenticated ? (
                <Button className="channelList-addBtn" variant="orange" onClick={() => setShowAddNewChannel(true)}>
                  Add New Chat
                </Button>
              ) : (
                <LoginButtons onLogin={onLogin} onSignup={onSignup} />
              ))}
            {isEventDiscussions && isPartialView && (
              <Button className="channelWrap-addBtn" width={40} onClick={() => setShowAddNewChannel(true)}>
                <img src={cross_arrow_white} alt="close icon" />
              </Button>
            )}
          </div>
          {(isPartialView && discussionMode === DiscussionMode.list) || !channels.length ? null : (
            <div className={channelClasses}>
              <Channel
                DateSeparator={CustomDateSeparator}
                EmptyStateIndicator={() => <EmptyChatState theme={theme} listType="message" />}
              >
                <Window>
                  {isEventDiscussions && !isPartialView ? null : (
                    <CustomChannelHeader
                      theme={theme}
                      member={member}
                      openChannelEdit={() => setShowEditChannel(true)}
                    />
                  )}
                  <CustomMessageList
                    theme={theme}
                    setEditMessageDetails={setEditMessageDetails}
                    reloadChannel={reloadChannels}
                  />
                  {selectedChannel && (
                    <CustomMessageInputWrapper
                      handleStopEditing={handleStopEditing}
                      editMessageDetails={editMessageDetails}
                      theme={theme}
                      uniqueTooltipId={"discussions-toolotip-id"}
                      openRestrictionAlert={openRestrictionAlert}
                    />
                  )}
                </Window>
                <Thread />
              </Channel>
            </div>
          )}
        </div>
      </Chat>
    </>
  );
};

export default DiscussionsContainer;
