import React, { useMemo } from "react";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";

interface IProps {
  provider: string;
  appId: string;
  onLoginSuccess: (data: any) => void;
  onLoginFailure: (data: any) => void;
  className: string;
}

const SocialButton: React.FC<IProps> = ({ children, provider, appId, onLoginSuccess, onLoginFailure, className }) => {
  const socialButton = useMemo(() => {
    switch (provider) {
      case "google":
        return (
          <LoginSocialGoogle
            client_id={appId}
            scope="openid profile email"
            discoveryDocs="claims_supported"
            access_type="offline"
            onResolve={onLoginSuccess}
            onReject={onLoginFailure}
          >
            {children}
          </LoginSocialGoogle>
        );
      case "facebook":
        return (
          <LoginSocialFacebook
            appId={appId}
            fieldsProfile={"id,first_name,last_name,middle_name,name,picture,email"}
            onResolve={onLoginSuccess}
            onReject={onLoginFailure}
          >
            {children}
          </LoginSocialFacebook>
        );
      default:
        return null;
    }
  }, [appId, children, onLoginFailure, onLoginSuccess, provider]);

  return <div className={className}>{socialButton}</div>;
};

export default SocialButton;
