import { Reducer } from "redux";
import produce from "immer";

import { MemberActionTypes } from "./constants";
import { IPrivateInvitation, MemberState } from "../interfaces";

const initialState: MemberState = {
  error: null,
  communityMember: [],
  memberToConnect: null,
  membersToModal: [],
  restrictedMembers: [],
  currentRestrictedMember: null,
};

const reducer: Reducer<MemberState> = (state: MemberState = initialState, action) => {
  switch (action.type) {
    case MemberActionTypes.FETCH_MEMBERS_TO_MODAL_SUCCESS:
      return produce(state, nextState => {
        nextState.membersToModal = action.payload;
        nextState.error = null;
      });
    case MemberActionTypes.FETCH_MEMBER_IN_COMMUNITY_SUCCESS:
      return produce(state, nextState => {
        nextState.error = null;
        nextState.communityMember = action.payload;
      });
    case MemberActionTypes.FETCH_MEMBER_IN_COMMUNITY_FAILURE:
      return produce(state, nextState => {
        nextState.error = action.payload;
      });
    case MemberActionTypes.CREATE_MEMBER_SUCCESS:
      return produce(state, nextState => {
        nextState.error = null;
        nextState.communityMember = [...state.communityMember, action.payload];
      });
    case MemberActionTypes.CREATE_MEMBER_FAILURE:
      return produce(state, nextState => {
        nextState.error = action.payload;
      });
    case MemberActionTypes.UPDATE_MEMBER_SUCCESS:
      return produce(state, nextState => {
        let isExist = false;
        const filteredMembers = [...(state.communityMember || [])].map(member => {
          if (member.id === action.payload.id) {
            isExist = true;
            return action.payload;
          }
          return member;
        });

        nextState.communityMember = [...filteredMembers, ...(!isExist ? [action.payload] : [])];
        nextState.error = null;
      });
    case MemberActionTypes.UPDATE_MEMBER_FAILURE:
      return produce(state, nextState => {
        nextState.error = action.payload;
      });
    case MemberActionTypes.CONNECT_MEMBER_TO_COMMUNITY_SUCCESS:
      return produce(state, nextState => {
        nextState.error = null;
        nextState.communityMember = [...state.communityMember, action.payload];
      });
    case MemberActionTypes.CONNECT_MEMBER_TO_COMMUNITY_FAILURE:
      return produce(state, nextState => {
        nextState.error = action.payload;
      });
    case MemberActionTypes.REMOVE_MEMBER_SUCCESS:
      return produce(state, nextState => {
        nextState.error = null;
        nextState.communityMember = [...state.communityMember].filter(member => member.id !== action.payload);
      });
    case MemberActionTypes.REMOVE_MEMBERS_SUCCESS:
      return produce(state, nextState => {
        nextState.error = null;
        const removedMemberIds: number[] = action.payload;
        nextState.communityMember = state.communityMember.filter(member => !removedMemberIds.includes(member.id));
      });
    case MemberActionTypes.REMOVE_MEMBER_FAILURE:
      return produce(state, nextState => {
        nextState.error = action.payload;
      });
    case MemberActionTypes.FETCH_EXIST_MEMBER_SUCCESS:
      return produce(state, nextState => {
        nextState.error = null;
        nextState.memberToConnect = action.payload;
      });
    case MemberActionTypes.FETCH_EXIST_MEMBER_FAILURE:
      return produce(state, nextState => {
        nextState.error = action.payload;
        nextState.memberToConnect = null;
      });

    case MemberActionTypes.FETCH_EXIST_MEMBER_CLEAR:
      return produce(state, nextState => {
        nextState.error = null;
        nextState.memberToConnect = null;
      });
    case MemberActionTypes.FETCH_CHAT_TOKEN_SUCCESS:
      return produce(state, nextState => {
        nextState.chat_token = action.payload;
      });
    case MemberActionTypes.FETCH_ANONYMOUS_CHAT_TOKEN_FAILURE:
      return produce(state, nextState => {
        nextState.chat_token = action.payload;
      });
    case MemberActionTypes.SEND_PRIVATE_INVITATION_SUCCESS:
      return produce(state, nextState => {
        const invitations: IPrivateInvitation[] = action.payload;
        const filteredMembers = [...(state.communityMember || [])].map(member => {
          const invitation = invitations.find(i => i.member_id === member.id);
          if (invitation) {
            return { ...member, invitation };
          }
          return member;
        });

        nextState.error = null;
        nextState.communityMember = [...filteredMembers];
      });
    case MemberActionTypes.SEND_PRIVATE_INVITATION_FAILURE:
      return produce(state, nextState => {
        nextState.error = action.payload;
      });
    case MemberActionTypes.FETCH_RESTRICTED_MEMBERS_SUCCESS:
      return produce(state, nextState => {
        nextState.restrictedMembers = action.payload;
      });
    case MemberActionTypes.SET_CURRENT_RESTRICTED_MEMBER: {
      return produce(state, nextState => {
        nextState.currentRestrictedMember = action.payload;
      });
    }
    default:
      return state;
  }
};
export { reducer as MemberReducer };
