import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { EEventStreamType, EventType, IEvent } from "../../../interfaces";
import { downloadRecordHandler } from "../../../utils";
import { fetchSermon } from "../../../store/actions";

import { EFileType } from "shared/interfaces/File";
import { Button } from "shared/components";
import download_record_white from "assets/icons/download_record_white.svg";

import "./eventEnded.scss";

export interface IEventCountDown {
  event: IEvent;
}

const PROVIDERS_TO_HIDE = [EEventStreamType.RESI, EEventStreamType.ALTAR_LIVE];

const EventEnded: React.FC<IEventCountDown> = ({ event }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { image_url, stream_url, stream_type, type, files, code } = event;

  const meetingRecords = useMemo(() => files?.filter(file => file.type === EFileType.meeting_record) || [], [files]);
  const excludeUrl = useMemo(() => stream_type && PROVIDERS_TO_HIDE.includes(stream_type), [stream_type]);

  const downloadAllFiles = useCallback(() => {
    meetingRecords.forEach(file => {
      const fileType = file.origin_path.match(/.\w+$/)?.[0];
      if (fileType) {
        downloadRecordHandler(file.path || file.origin_path, `${file.name}${fileType}`);
      }
    });
  }, [meetingRecords]);

  const openUrlHandler = () => {
    stream_url && window.open(stream_url, "_blank");
  };

  useEffect(() => {
    if (code) {
      dispatch(fetchSermon.request({ code }));
    }
  }, [dispatch, code]);

  return (
    <div className="eventEnded">
      <div className="eventEnded-content">
        {type === EventType.meeting && meetingRecords.length ? (
          <div className="eventEnded-content-records">
            <h1 className="eventEnded-title">{t("Meeting Recording is Finished")}</h1>
            <p className="eventEnded-description">
              {t("You can download the mp4 recording of this meeting by clicking the button below")}
            </p>
            <Button
              variant="orange"
              width={264}
              onClick={downloadAllFiles}
              className="eventEnded-download-btn"
              type="button"
            >
              <img src={download_record_white} alt="download" />
              Download the Recording
            </Button>
          </div>
        ) : (
          <>
            <h1 className="eventEnded-title">{t(`${type === EventType.stream ? "Event" : "Meeting"} has ended`)}</h1>
            {!!stream_url && !excludeUrl && (
              <>
                <p className="eventEnded-description">{t("You can watch recording here")}</p>
                <div className="eventEnded-url">
                  <Button variant="orange-text" onClick={openUrlHandler} className="eventEnded-url-btn" type="button">
                    {stream_url}
                  </Button>
                </div>
              </>
            )}
            {image_url && (
              <div className="eventEnded-image">
                <img src={image_url} alt="event" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default EventEnded;
