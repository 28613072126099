import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RoomAttendees, RoomInterface, TableItemState } from "containers/BreakoutRooms/interfaces";
import { getEventHostOrAdmin } from "containers/Sermons/store/selectors";
import { getAllowedRoomMembersCount } from "shared/utils/rooms";

export default function useRoomsAttendees(room: RoomInterface) {
  const [roomState, setRoomState] = useState<TableItemState>({
    attendees: [undefined, undefined, undefined, undefined],
    filled: false,
  });

  const hasPrivilegeToJoinRoom = useSelector(getEventHostOrAdmin());

  useEffect(() => {
    if (room.members) {
      let { attendees } = roomState;
      let isOccupiedByAdmin = false;
      attendees = attendees.reduce((attendees: RoomAttendees, _, index) => {
        attendees[index] = room.members && room.members[index] ? room.members[index] : undefined;
        isOccupiedByAdmin = !!room?.members?.[index]?.is_admin || isOccupiedByAdmin;
        return attendees;
      }, []);

      const allowedRoomMembersCount = getAllowedRoomMembersCount(hasPrivilegeToJoinRoom, isOccupiedByAdmin);

      const filled = room.members.length >= allowedRoomMembersCount;

      setRoomState({
        filled,
        attendees,
      });
    }
    // eslint-disable-next-line
  }, [room, hasPrivilegeToJoinRoom]);

  useEffect(
    () => () => {
      setRoomState({
        attendees: [undefined, undefined, undefined, undefined],
        filled: false,
      });
    },
    [],
  );
  return [roomState];
}
