import { DialogItem } from "containers/Member/interfaces";

export const getImageBase64 = (
  imageUrl: string | null | undefined,
  isImageChanged: boolean,
): string | null | undefined => {
  if (imageUrl === "") {
    return null;
  }
  if (!isImageChanged) {
    return undefined;
  }
  return imageUrl;
};

export const getArrayFromDialogItem = (dialogItems: DialogItem[]): string[] => {
  return Array.from(new Set(dialogItems.map(item => item.name)));
};
