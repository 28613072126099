import React from "react";

import { CustomAvatar } from "containers/Discussions/components";
import { IMember } from "containers/Member";
import { IEventMemberResponse } from "containers/Sermons";

interface AttendeePicInterface {
  member: IMember;
  streamMembers: IEventMemberResponse[];
  size?: number;
}

function AttendeePic({ member, streamMembers, size }: AttendeePicInterface) {
  const streamMember = streamMembers?.find(({ memberId }) => String(memberId) === String(member?.id));

  return (
    <CustomAvatar
      image={streamMember?.member?.image_url || void 0}
      size={size || 90}
      color={streamMember?.color}
      member={member}
      classNames="attendeePic"
    />
  );
}

export default React.memo(AttendeePic);
