import * as Yup from "yup";

import { passwordValidator, phoneValidator } from "shared/constants/validations";

export const editProfileSchema = Yup.object({
  first_name: Yup.string().max(35, "First Name field is too long").required("First Name is a required field"),
  last_name: Yup.string().max(35, "Last Name field is too long").required("Last Name is a required field").ensure(),
  email_duplicate: Yup.string()
    .email("Incorrect Email format")
    .max(150, "Email should not exceed 150 characters")
    .required("Email is a required field"),
  phone: phoneValidator,
  oldPassword: Yup.string().when("password_duplicate", (val: string) =>
    val ? Yup.string().required("Current password is a required field") : Yup.string(),
  ),
  password_duplicate: passwordValidator,
  confirmPassword: Yup.string()
    .nullable()
    .test("is-equal", "The passwords you entered do not match", function (val: string) {
      return this.parent.password_duplicate === val;
    }),
});
