import React from "react";

import VideoStream from "../../VideoStream/VideoStream";
import { EventCountDown } from "../EventCountDown";

import { IEvent } from "containers/Sermons/interfaces";
import stream_mock_seats from "assets/images/stream_mock_seats.png";
import stream_mock_controls from "assets/images/stream_mock_controls.png";

import "./styles.scss";

interface IMobileWrapperStream {
  onClick: () => void;
  event: IEvent;
  showStream: boolean;
}

const MobileWrapperStream: React.FC<IMobileWrapperStream> = ({ onClick, event, showStream }) => {
  const seats = new Array(2).fill(0).map((_, idx) => (
    <div key={idx} className="mobileWrapper-stream-seatsItem" onClick={onClick}>
      <img src={stream_mock_seats} alt="seats" />
    </div>
  ));
  return (
    <div className="mobileWrapper-stream">
      {showStream ? <VideoStream event={event} /> : <EventCountDown event={event} setTimeHasCome={() => {}} isMobile />}
      <div className="mobileWrapper-stream-controls" onClick={onClick}>
        <img src={stream_mock_controls} alt="controls" />
      </div>
      <div className="mobileWrapper-stream-seats">{seats}</div>
      <div className="mobileWrapper-gradient" />
    </div>
  );
};

export default MobileWrapperStream;
