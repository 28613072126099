import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { EFileStatus, EFileType } from "../../../../../shared/interfaces/File";
import StreamEventPlayer from "../../StreamEventPlayer";
import { Button } from "../../../../../shared";
import { selectIsConnecting, selectIsImutted, selectStreams } from "../../../../BreakoutRooms/store/selectors";
import { defaultVideoSettings } from "../../../constants";

import { EEventStreamType, EPreEventType, getRemainingTime, getS3Url, IEvent } from "containers/Sermons";
import CountdownTimer from "containers/Sermons/components/CountdownTimer";
import { getAllowGeneralChatInput } from "containers/Discussions/store/selectors";
import { setAllowGeneralInput } from "containers/Discussions/store/actions";

import "./eventCountDown.scss";

export interface IEventCountDown {
  event: IEvent;
  setTimeHasCome: () => void;
  isMobile?: boolean;
}

const timeToAllowGeneralChat = 60 * 60 * 1000;

const EventCountDown: React.FC<IEventCountDown> = ({ event, setTimeHasCome, isMobile }) => {
  const dispatch = useDispatch();
  const [preImage, setPreImage] = useState("");
  const [muted, setMuted] = useState(true);
  const [volume, setVolume] = useState<number>(defaultVideoSettings.volume);
  const [videoContainer, setVideoContainer] = useState<HTMLDivElement | null>(null);

  const isImutted = useSelector(selectIsImutted());
  const isConnecting = useSelector(selectIsConnecting());
  const allowGeneralChatInput = useSelector(getAllowGeneralChatInput());
  const userStreams = useSelector(selectStreams());

  const { t } = useTranslation();
  const {
    pre_images_interval,
    pre_event_type,
    files,
    pre_event_video_url,
    is_pre_event_video_loop,
    is_pre_event_video_autoplay,
  } = event;
  const onTimeChange = useCallback(
    time => {
      if (timeToAllowGeneralChat > time && !allowGeneralChatInput) {
        dispatch(setAllowGeneralInput(true));
      }
    },
    [allowGeneralChatInput, dispatch],
  );

  const remainingTime = getRemainingTime(event);

  useEffect(() => {
    let interval = 0;

    const preEventImageFile = files ? files?.filter(file => file.type === EFileType.preEventImage) : [];
    if (preEventImageFile.length > 0 && pre_images_interval && pre_event_type === EPreEventType.images) {
      let i = 0;
      setPreImage(preEventImageFile[i].path || "");

      if (preEventImageFile.length > 1) {
        interval = setInterval(() => {
          i = (i + 1) % preEventImageFile.length;
          setPreImage(preEventImageFile[i].path || "");
        }, pre_images_interval * 1000);
      }
    }
    return () => clearInterval(interval);
  }, [pre_images_interval, pre_event_type, files]);

  useEffect(() => {
    const allMemberMuted = userStreams.every(userStream => userStream.isMutted);
    (isImutted || !isConnecting) && allMemberMuted ? setVolume(1) : setVolume(0.2);
  }, [isImutted, userStreams, isConnecting]);

  const preEventVideo = useMemo(() => {
    const preEventVideoData = files?.filter(
      file => file.type === EFileType.preEventVideo && file.status === EFileStatus.ready,
    );
    return preEventVideoData && preEventVideoData.length > 0 ? preEventVideoData[preEventVideoData.length - 1] : null;
  }, [files]);

  const showHours = useMemo(() => remainingTime > 10 * 60 * 1000, [remainingTime]);

  return (
    <div className={classnames("eventCountDown", { isMobile })}>
      <div
        className={classnames("eventCountDown-content", `${pre_event_type === EPreEventType.none ? "back-img" : ""}`)}
      >
        <div className="eventCountDown-content-timer">
          <p className="eventCountDown-label">{t("Live Event Will Start In")}</p>
          <CountdownTimer
            initTime={remainingTime}
            onTimeHasCome={setTimeHasCome}
            onTimeChange={onTimeChange}
            showHours={showHours}
            inTwoLines
          />
        </div>
        {preImage && (
          <div className="eventCountDown-image">
            <img src={preImage} alt="event" />
          </div>
        )}

        {pre_event_type === EPreEventType.video && (pre_event_video_url || preEventVideo) && (
          <div className="pre-event-video" ref={setVideoContainer}>
            {muted && (
              <Button type="button" variant="white" width={168} onClick={() => setMuted(false)} className="unmute">
                <span>Click to Unmute</span>
              </Button>
            )}
            <StreamEventPlayer
              streamType={preEventVideo ? EEventStreamType.ALTAR : null}
              streamUrl={preEventVideo ? getS3Url(preEventVideo.origin_path) : pre_event_video_url || ""}
              videoContainer={videoContainer}
              playerClassName="player"
              loop={is_pre_event_video_loop}
              playing={is_pre_event_video_autoplay}
              controls={!!preEventVideo || false}
              muted={muted}
              volume={muted ? 0 : volume}
              height={!isMobile ? 412 : 220}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default EventCountDown;
