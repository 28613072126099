import * as Yup from "yup";

import { defaultUrlValidate } from "shared/constants/validations";

const requiredMessage = "This field is required";

export const validateSchema = Yup.object({
  title: Yup.string().trim().required("Title is required"),
  description: Yup.string().trim().required("Announcement Text is required"),
  button_text: Yup.object().when(["use_button"], (use_button: boolean) =>
    use_button ? Yup.string().trim().required(requiredMessage) : Yup.string(),
  ),
  button_link: Yup.object().when(["use_button"], (use_button: boolean) =>
    use_button ? defaultUrlValidate.required(requiredMessage) : Yup.string(),
  ),
});
