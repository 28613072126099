import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import classnames from "classnames";

import { SquareButton } from "shared";
import { getCommunityStreamingStatistics } from "containers/Community/store/selectors";
import { openWidget } from "utils/hubspot";

import "./styles.scss";

type Props = {
  onSwitchProvider: () => void;
};
const StreamingStatistics: React.FC<Props> = ({ onSwitchProvider }) => {
  const statistics = useSelector(getCommunityStreamingStatistics());
  const statisticsData = useMemo(() => {
    const data = {
      usedPercent: 0,
      periodStart: "",
      periodEnd: "",
      periodEndsIn: 0,
      timeLeft: "0h 0mins",
    };
    if (statistics) {
      const usedPercent = Math.floor((statistics.usedSeconds / (statistics.allowedHours * 60 * 60)) * 100);
      data.usedPercent = usedPercent > 100 ? 100 : usedPercent;
      data.periodStart = moment(statistics.from).format("MM/DD/YYYY");
      data.periodEnd = moment(statistics.to).format("MM/DD/YYYY");
      data.periodEndsIn = -Math.ceil(moment.duration(moment().diff(statistics.to)).asDays());
      const timeLeftSeconds = statistics.allowedHours * 60 * 60 - statistics.usedSeconds;
      if (timeLeftSeconds > 0) {
        const timeLeftDuration = moment.duration(timeLeftSeconds, "s");
        data.timeLeft = `${Math.floor(timeLeftDuration.asHours())}h ${timeLeftDuration.minutes()}mins`;
      }
    }
    return data;
  }, [statistics]);

  return statistics ? (
    <div className="streaming-statistics">
      <h3 className="sub-header">RTMP Streaming</h3>
      <p>Your community is currently on a plan with a {statistics.allowedHours} hour/month RTMP stream limit.</p>
      {statisticsData.usedPercent === 100 && <h4 className="limit-reached-header">Streaming Limit Reached</h4>}
      <div className={classnames("used-time-progress-bar", { warning: statisticsData.usedPercent >= 90 })}>
        <div className="total">
          <div
            className={classnames("used", { reached: statisticsData.usedPercent === 100 })}
            style={{ width: `${statisticsData.usedPercent}%` }}
          >
            {statisticsData.usedPercent < 100 && <div className="tooltip">{statisticsData.usedPercent}%</div>}
          </div>
        </div>
      </div>
      <div className="remaining-values">
        <div>
          <span>Streaming limit renews in:</span>&nbsp;{statisticsData.periodEndsIn} day
          {statisticsData.periodEndsIn > 1 ? "s" : ""}
        </div>
        <div className={classnames({ warning: statisticsData.usedPercent >= 90 })}>
          <span>Hours left:</span>&nbsp;{statisticsData.timeLeft}
        </div>
      </div>
      <p>
        Figures apply to the period&nbsp;
        <b>
          {statisticsData.periodStart} – {statisticsData.periodEnd}.
        </b>
      </p>
      <div className="controls">
        {statisticsData.usedPercent === 100 && (
          <SquareButton
            className="switch-provider"
            type="button"
            variant="blue-outline"
            width={368}
            onClick={onSwitchProvider}
          >
            Switch to Another Streaming Provider
          </SquareButton>
        )}
        <SquareButton className="buy-hours" type="button" variant="blue" onClick={openWidget}>
          Need More Hours?
        </SquareButton>
      </div>
      {statisticsData.usedPercent < 100 && <h3 className="sub-header">RTMP Streaming Credentials</h3>}
    </div>
  ) : null;
};

export default StreamingStatistics;
