import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

import { NameOfRoutes, NamesOfParentRoutes } from "../../../../constants";
import { getSubscriptionAttention, getCommunity, getUserCommunities } from "../../../Community/store/selectors";
import { Direction } from "../../../App/interfaces";
import { actions, selectors } from "../../store";

import { AlertDialog, Button, EmptyText, Header, SearchInput } from "shared/components";
import { IRouteComponentProps } from "shared/interfaces";
import useDebounce from "shared/hooks/DebounceHook/DebounceHook";
import { EventType, EventTypes, IEvent, IEventScheduler, SermonsSchedulerList } from "containers/Sermons";
import { usePermissions } from "shared";
import { LINKS } from "shared/constants/links";
import { EventPermissions } from "containers/Auth/interfaces";
import { useFeatures } from "shared/hooks/FeaturesHook";
import { EFeature } from "containers/Community/interfaces";
import { setDashBoardDisabled } from "containers/App/store/actions";
import blank from "assets/icons/blank.svg";

import "./sermons.scss";

const { EVENT, EVENT_NEW, COMMUNITY_SETTINGS } = NamesOfParentRoutes;
const { BILLING_SETTINGS } = NameOfRoutes;

const dateWithoutTime = (date: Date): number => {
  return new Date(date).setHours(0, 0, 0, 0);
};

const prepareSchedulers = (sermons: IEvent[]) => {
  const sermonSchedulers: IEventScheduler[] = [];
  if (sermons) {
    const sermonsDate = Array.from(
      new Set(sermons.map(i => !i.is_default && dateWithoutTime(new Date(i.starting_at)))),
    );
    sermonsDate.forEach(date => {
      if (date) {
        sermonSchedulers.push({
          date,
          sermons: sermons.filter(i => dateWithoutTime(new Date(i.starting_at)) === date),
        });
      }
    });
  }
  return sermonSchedulers;
};

const SermonsContainer: React.FC<IRouteComponentProps> = props => {
  const {
    match: {
      params: { communityCode },
    },
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const currentCommunity = useSelector(getCommunity());
  const sermons: IEvent[] = useSelector(selectors.getSermonList());
  const userCommunities = useSelector(getUserCommunities());
  const subscriptionAttention = useSelector(getSubscriptionAttention());

  const [search, setSearch] = useState("");
  const [direction] = useState<Direction>("asc");
  const [sermonSchedulers, setSermonSchedulers] = useState<IEventScheduler[]>(prepareSchedulers(sermons));
  const [subscribeDialog, setSubscribeDialog] = useState(false);
  const [addEventOpened, setAddEventOpened] = useState(false);
  const debouncedSearch = useDebounce(search, 500, 3);

  useEffect(() => {
    dispatch(setDashBoardDisabled(false));
  }, [dispatch]);

  useEffect(() => {
    if (sermons) {
      setSermonSchedulers(prepareSchedulers(sermons));
    }
  }, [sermons, setSermonSchedulers]);

  const handleAddEvent = useCallback(() => {
    setAddEventOpened(true);
  }, []);

  const onCloseAddEvent = useCallback(() => {
    setAddEventOpened(false);
  }, []);

  useEffect(() => {
    currentCommunity &&
      dispatch(
        actions.fetchSermonsList.request({
          search: debouncedSearch,
          community_id: currentCommunity.id,
          order: "starting_at",
          direction,
        }),
      );
  }, [debouncedSearch, currentCommunity, direction, dispatch]);

  const handleConfirmDialog = useCallback(() => {
    window.open(LINKS.support, "_blank");
    setSubscribeDialog(false);
  }, []);

  const handleCloseDialog = useCallback(() => setSubscribeDialog(false), []);

  const createPermissions = useMemo(() => [EventPermissions.create], []);
  const canCreate = usePermissions(createPermissions, currentCommunity?.id);
  const features = useFeatures();

  const onActivate = useCallback(() => {
    onCloseAddEvent();
    history.push(`/${communityCode}${COMMUNITY_SETTINGS}${BILLING_SETTINGS}`);
  }, [communityCode, history, onCloseAddEvent]);

  const addNewEvent = useCallback(
    (type: EventType) => {
      if (!features.length || (!features.includes(EFeature.meeting) && type === EventType.meeting)) {
        return;
      }
      onCloseAddEvent();
      history.push(`/${communityCode}${EVENT}/${type}${EVENT_NEW}`);
    },
    [features, onCloseAddEvent, history, communityCode],
  );

  return (
    <>
      <AlertDialog
        mode="confirm"
        open={subscribeDialog}
        onCancel={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        cancelText="maybe later"
        confirmText="subscribe now"
        title="Sign up for Altar today!"
        message="It looks like you need to choose a subscription plan before you can start a meeting or event."
      />
      <EventTypes
        opened={addEventOpened}
        onActivate={onActivate}
        onClose={onCloseAddEvent}
        onSelect={addNewEvent}
        features={features}
      />
      <Grid container className={classnames("sermons", { subscriptionAttention })}>
        <Header title="Events & Meetings" subscriptionAttention={subscriptionAttention}>
          <SearchInput className="defaultSearchInput" onChange={val => setSearch(val)} />
          {canCreate && (
            <Button className="sermons-add-event-button" width="176" variant="orange" onClick={() => handleAddEvent()}>
              {t("Add New Event")}
            </Button>
          )}
        </Header>
        <Grid container className="scheduler-container">
          {userCommunities.length > 0 && currentCommunity ? (
            <SermonsSchedulerList schedulers={sermonSchedulers} search={debouncedSearch} />
          ) : (
            <EmptyText
              icon={blank}
              message={
                <p>
                  Join a community
                  <br />
                  to see their events.
                </p>
              }
              classes="no-communities"
              show
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default SermonsContainer;
