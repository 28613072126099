import React from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { FieldAttributes } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { CustomDatePickerInput } from "../CustomDatePickerInput";

const StyledDatePicker: React.FC<FieldAttributes<any>> = props => {
  const { value, label, name, setFieldValue, classes, format } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        disableToolbar
        TextFieldComponent={({ onClick }) => (
          <CustomDatePickerInput onClick={onClick} value={value} label={label} classes={classes} />
        )}
        margin="dense"
        autoOk
        disablePast
        id="date-picker-dialog"
        value={value}
        onChange={(date: MaterialUiPickersDate) => {
          setFieldValue(name, date?.toString());
        }}
        format={format}
      />
    </MuiPickersUtilsProvider>
  );
};

export default StyledDatePicker;
