import React, { useCallback } from "react";

import trash from "assets/icons/trash.svg";
import edit_icon_blue from "assets/icons/edit_icon_blue.svg";

import "./styles.scss";

export interface IListItem {
  id: number;
  name: React.ReactNode;
  image?: string;
  title?: string;
  [key: string]: any;
}
type Props = {
  item: IListItem;
  className?: string;
  onEdit?: (item: IListItem) => void;
  onRemove?: (id: number) => void;
};
const ListItemWithControls: React.FC<Props> = ({ className, item, onEdit, onRemove }) => {
  const onItemRemove = useCallback(() => {
    if (onRemove) {
      onRemove(item.id);
    }
  }, [item.id, onRemove]);
  const onItemEdit = useCallback(() => {
    if (onEdit) {
      onEdit(item);
    }
  }, [item, onEdit]);

  return (
    <div className={`list-item ${className || ""}`}>
      <div className="list-item-img">
        <img src={item.image} alt="icon" />
      </div>
      <div className="list-item-name">{item.name}</div>
      <div className="list-item-controls">
        {onEdit && <img src={edit_icon_blue} onClick={onItemEdit} alt="Edit" />}
        {onRemove && <img src={trash} onClick={onItemRemove} alt="Remove" />}
      </div>
    </div>
  );
};

export default ListItemWithControls;
