import * as Yup from "yup";
import moment from "moment";
import { convertFromRaw } from "draft-js";

import {
  EEventReCurringEnds,
  EEventRepeat,
  EEventScheduleDay,
  EEventStreamType,
  EPreEventType,
  EventType,
  IEventRelayItem,
  IAuditoriumRoomsValue,
  NavigationEventAction,
  SermonRestriction,
  EAuditoriumRoomsType,
} from "../interfaces";
import { getEventTypeName, getStreamUrlValidationBuilder } from "../utils";
import { passwordValidator, phoneValidator, urlProtocolRegExp, urlRegExp } from "../../../shared/constants/validations";
import {
  HOST_NOTES_CHARACTER_LIMIT,
  ROOMS_LIMIT,
  SLIDE_NAME_LENGTH_LIMIT,
  SLIDES_SCHEDULE_NAME_LENGTH_LIMIT,
} from "../constants";
import { IFile } from "../../../shared/interfaces/File";

export const scheduleSlideNameValidationSchema = Yup.string()
  .max(SLIDES_SCHEDULE_NAME_LENGTH_LIMIT)
  .required("Schedule slide name is a required field");

export const slideValidationSchema = Yup.object({
  name: Yup.string()
    .max(SLIDE_NAME_LENGTH_LIMIT, `Max ${SLIDE_NAME_LENGTH_LIMIT} characters`)
    .required("Slide name is a required field"),
  speaker_member_id: Yup.number().nullable(),
  starting_at: Yup.string().required(),
  content: Yup.string(),
});

export const eventGeneralStepValidationSchema = Yup.object({
  name: Yup.string().max(35, "Name field is too long").required("Name is a required field"),
  subject: Yup.string().max(1500, "Max 1500 characters"),
  starting_at: Yup.date().required(),
  ending_at: Yup.object().when(["type"], (type: EventType) => {
    return Yup.date()
      .required()
      .test("not-equal-start", `${getEventTypeName(type, true)} can't last more than 24 hours`, function (val: Date) {
        return this.parent.starting_at.getTime() !== val.getTime();
      });
  }),
  schedule: Yup.object().when(
    ["repeat", "recurring_ends_on", "starting_at", "type"],
    (repeat: EEventRepeat, recurringEndsOn: EEventReCurringEnds, starting_at: Date, type: EventType) => {
      if (repeat !== EEventRepeat.custom) {
        return Yup.object();
      }
      const until =
        recurringEndsOn === EEventReCurringEnds.on
          ? Yup.date()
              .required("This field is required")
              .test(
                "not-earlier-start",
                `Should be more than start ${getEventTypeName(type)} date`,
                function (val: Date) {
                  return moment(starting_at) < moment(val);
                },
              )
          : Yup.date().nullable();
      const occurrences =
        recurringEndsOn === EEventReCurringEnds.after
          ? Yup.number().positive("Should be positive").required("This field is required")
          : Yup.number().nullable();

      return Yup.object({
        until,
        occurrences,
        interval: Yup.number().positive("Should be positive").required("This field is required"),
        day_of_week: Yup.array()
          .of(Yup.string().oneOf(Object.values(EEventScheduleDay)))
          .min(1, "Select at least one day"),
      });
    },
  ),
  class_room_settings: Yup.object({
    room_size: Yup.number().max(500, "The maximum people on the meeting is 500").required("This field is required"),
  }).nullable(),
  tour_title: Yup.object().when(["has_platform_tour"], (has_platform_tour: boolean) => {
    return has_platform_tour
      ? Yup.string().max(70, "Max 70 characters").required("This field is required").nullable()
      : Yup.string().nullable();
  }),
  tour_description: Yup.object().when(["has_platform_tour"], (has_platform_tour: boolean) => {
    return has_platform_tour
      ? Yup.string().max(300, "Max 300 characters").required("This field is required").nullable()
      : Yup.string().nullable();
  }),
});

export const eventTeamStepValidationSchema = Yup.object({
  host_notes: Yup.string()
    .test("host_notes", "Text is too long and cannot be saved. Text must be less than 15,000 characters.", value =>
      value ? convertFromRaw(JSON.parse(value)).getPlainText().length < HOST_NOTES_CHARACTER_LIMIT : true,
    )
    .nullable(),
});

export const eventPermissionsStepValidationSchema = Yup.object({
  attendees: Yup.array().when("restriction", {
    is: SermonRestriction.invited,
    then: Yup.array().min(1, "Please select at least one participant"),
  }),
});

export const eventStreamStepValidationSchema = Yup.object({
  stream_type: Yup.mixed().when(
    ["type", "auditorium_rooms"],
    (type: EventType, auditorium_rooms: IAuditoriumRoomsValue) => {
      if (type === EventType.stream && auditorium_rooms.auditoriumRoomsEnabled) {
        return Yup.mixed().oneOf(Object.values(EEventStreamType));
      }
    },
  ),
  stream_url: getStreamUrlValidationBuilder(false),
  relays: Yup.array().when(["stream_type", "video_type"], (stream_type: EEventStreamType) => {
    if (stream_type === EEventStreamType.ALTAR_LIVE) {
      return Yup.array().of(
        Yup.object().when(
          ["type"],
          (type: EEventStreamType, schema: Yup.ArraySchema<any>, { value }: { value: IEventRelayItem }) => {
            const { enabled } = value;
            return enabled
              ? Yup.object().shape({
                  stream_url: Yup.string().required("Please enter Stream RTMP URL"),
                  stream_key: Yup.string().required("Please enter Stream Key"),
                })
              : Yup.object().nullable();
          },
        ),
      );
    }
    return Yup.object().nullable();
  }),
});

export const eventPreEventStepValidationSchema = Yup.object().shape(
  {
    pre_event_type: Yup.mixed().oneOf(Object.values(EPreEventType)),
    pre_event_video_url: Yup.string().when(["pre_event_type", "files"], {
      is: (pre_event_type: EPreEventType, files: IFile[]) =>
        pre_event_type === EPreEventType.video && (!files || files.length === 0),
      then: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-_%]+&?)?$/,
          "Enter correct url!",
        )
        .required("Please enter video URL or upload a video file to continue."),
      otherwise: Yup.string().nullable(),
    }),
    files: Yup.array().when(["pre_event_type", "pre_event_video_url"], {
      is: (pre_event_type: EPreEventType, pre_event_video_url: string) =>
        pre_event_type === EPreEventType.video && !pre_event_video_url,
      then: Yup.array().min(1, "Please upload at least one file to continue."),
      otherwise: Yup.array().nullable(),
    }),
    pre_images: Yup.array().when("pre_event_type", {
      is: EPreEventType.images,
      then: Yup.array().min(1, "Please upload at least one image to continue."),
    }),
  },
  [["files", "pre_event_video_url"]],
);

export const navigationStepValidationSchema = Yup.object({
  leave_event_url: Yup.string()
    .matches(urlProtocolRegExp, "Web URL must include http(s)://")
    .matches(urlRegExp, "Wrong website URL format")
    .nullable()
    .when("leave_event_action", {
      is: NavigationEventAction.custom,
      then: Yup.string().required("Field is required!"),
    }),
});

export const eventClassRooms = Yup.object({
  classRoomItems: Yup.array().of(
    Yup.object({
      name: Yup.string().max(50, "Name  is too long").required("Name is required").nullable(),
      description: Yup.string().max(100, "Description  is too long").nullable(),
      room_size: Yup.number()
        .min(1, "The minimum is 1 attendee in the room")
        .max(500, "The maximum people in the room is 500")
        .required("This field is required"),
    }),
  ),
}).nullable();

export const sermonValidationSchema = Yup.object({
  producer: Yup.object()
    .when("type", {
      is: EventType.liveService,
      then: Yup.object().required("Please select a Producer").nullable(),
    })
    .nullable(),
  presenters: Yup.array().when("type", {
    is: EventType.liveService,
    then: Yup.array().min(1, "Please select at least one presenter"),
  }),
  slides_bulletin_name: Yup.string().when("type", {
    is: EventType.liveService,
    then: scheduleSlideNameValidationSchema,
  }),
  slides: Yup.array().when("type", {
    is: EventType.liveService,
    then: Yup.array().of(slideValidationSchema),
  }),
  speaker_name: Yup.string().max(50, "Speaker name is too long").nullable(),
  classrooms: eventClassRooms,
  auditorium_rooms: Yup.object().when("auditorium_rooms_type", {
    is: value => value !== EAuditoriumRoomsType.none,
    then: Yup.object({
      auditoriumRoomsCount: Yup.number()
        .min(1, "You must add at least 1 row or table")
        .max(ROOMS_LIMIT, `The maximum number of rows or tables is ${ROOMS_LIMIT}`)
        .required("You must add at least 1 row or table"),
    }),
  }),
  lobby_rooms: Yup.object({
    lobbyRoomsCount: Yup.number()
      .min(1, "You must add at least 1 table")
      .max(ROOMS_LIMIT, `The maximum number of tables is ${ROOMS_LIMIT}`)
      .required("You must add at least 1 table"),
  })
    .nullable()
    .test("at-least-one-option", "At least one option must be selected", function () {
      return (
        this.parent.auditorium_rooms.auditoriumRoomsEnabled ||
        this.parent.lobby_rooms.lobbyRoomsEnabled ||
        !!this.parent.classrooms.classRoomItems.length
      );
    }),
})
  .concat(eventGeneralStepValidationSchema)
  .concat(eventTeamStepValidationSchema)
  .concat(eventPermissionsStepValidationSchema)
  .concat(eventStreamStepValidationSchema)
  .concat(eventPreEventStepValidationSchema)
  .concat(navigationStepValidationSchema);

export const signUpValidationSchema = Yup.object({
  first_name: Yup.string().max(35, "Name field is too long").required("This field is required"),
  last_name: Yup.string().max(35, "Last Name field is too long").required("This field is required"),
  email: Yup.string()
    .email("Incorrect Email format")
    .max(150, "Must be 150 characters or less")
    .required("This field is required"),
  phone_number: phoneValidator,
  password: passwordValidator.required("This field is required"),
  password_confirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "The passwords you entered do not match")
    .required("This field is required"),
});
