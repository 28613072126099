export enum DiscussionsActionTypes {
  FETCH_ANONYMOUS_CHANNELS_REQUEST = "@@DISCUSSIONS/FETCH_ANONYMOUS_CHANNELS_REQUEST",

  FETCH_CHANNELS_REQUEST = "@@DISCUSSIONS/FETCH_CHANNELS_REQUEST",
  FETCH_CHANNELS_SUCCESS = "@@DISCUSSIONS/FETCH_CHANNELS_SUCCESS",
  FETCH_CHANNELS_FAILURE = "@@DISCUSSIONS/FETCH_CHANNELS_FAILURE",

  GET_SELECTED_CHANNEL_DETAILS_REQUEST = "@@DISCUSSIONS/GET_SELECTED_CHANNEL_DETAILS_REQUEST",
  GET_SELECTED_CHANNEL_DETAILS_SUCCESS = "@@DISCUSSIONS/GET_SELECTED_CHANNEL_DETAILS_SUCCESS",
  GET_SELECTED_CHANNEL_DETAILS_FAILURE = "@@DISCUSSIONS/GET_SELECTED_CHANNEL_DETAILS_FAILURE",

  CREATE_NEW_CHAT = "@@DISCUSSIONS/CREATE_NEW_CHAT",
  CREATE_NEW_CHAT_SUCCESS = "@@DISCUSSIONS/CREATE_NEW_CHAT_SUCCESS",
  CREATE_NEW_CHAT_FAILURE = "@@DISCUSSIONS/CREATE_NEW_CHAT_FAILURE",

  CREATE_ANONYMOUS_CHANNEL_WITH_GREETER = "@@DISCUSSIONS/CREATE_ANONYMOUS_CHANNEL_WITH_GREETER",
  CREATE_ANONYMOUS_CHANNEL_WITH_GREETER_SUCCESS = "@@DISCUSSIONS/CREATE_ANONYMOUS_CHANNEL_WITH_GREETER_SUCCESS",
  CREATE_ANONYMOUS_CHANNEL_WITH_GREETER_FAILURE = "@@DISCUSSIONS/CREATE_ANONYMOUS_CHANNEL_WITH_GREETER_FAILURE",

  CREATE_GENERAL_CHAT = "@@DISCUSSIONS/CREATE_GENERAL_CHAT",
  CREATE_GENERAL_CHAT_SUCCESS = "@@DISCUSSIONS/CREATE_GENERAL_CHAT_SUCCESS",
  CREATE_GENERAL_CHAT_FAILURE = "@@DISCUSSIONS/CREATE_GENERAL_CHAT_FAILURE",

  UPDATE_CHANNEL = "@@DISCUSSIONS/UPDATE_CHANNEL",
  UPDATE_CHANNEL_SUCCESS = "@@DISCUSSIONS/UPDATE_CHANNEL_SUCCESS",
  UPDATE_CHANNEL_FAILURE = "@@DISCUSSIONS/UPDATE_CHANNEL_FAILURE",

  LEAVE_CHANNEL = "@@DISCUSSIONS/LEAVE_CHANNEL",
  LEAVE_CHANNEL_SUCCESS = "@@DISCUSSIONS/LEAVE_CHANNEL_SUCCESS",
  LEAVE_CHANNEL_FAILURE = "@@DISCUSSIONS/LEAVE_CHANNEL_FAILURE",

  ADD_CHANNEL_MODERATORS = "@@DISCUSSIONS/ADD_CHANNEL_MODERATORS",
  ADD_CHANNEL_MODERATORS_SUCCESS = "@@DISCUSSIONS/ADD_CHANNEL_MODERATORS_SUCCESS",
  ADD_CHANNEL_MODERATORS_FAILURE = "@@DISCUSSIONS/ADD_CHANNEL_MODERATORS_FAILURE",

  CLEAR_SELECTED_CHANNEL = "@@DISCUSSIONS/CLEAR_SELECTED_CHANNEL",

  SET_ACTIVE_CHANNEL = "@@DISCUSSIONS/SET_ACTIVE_CHANNEL",

  DELETE_CHANNEL = "@@DISCUSSIONS/DELETE_CHANNEL",

  SET_GETSTREAM_CHANNELS = "@@DISCUSSIONS/SET_GETSTREAM_CHANNELS",
  CLEAR_GETSTREAM_CHANNELS = "@@DISCUSSIONS/CLEAR_GETSTREAM_CHANNELS",

  SHOW_CHAT_LIST = "@@DISCUSSIONS/SHOW_CHAT_LIST",

  CREATE_CHAT_WITH_MEMBER = "@@DISCUSSIONS/CREATE_CHAT_WITH_MEMBER",
  CLEAR_CREATE_CHAT_WITH_MEMBER = "@@DISCUSSIONS/CLEAR_CREATE_CHAT_WITH_MEMBER",

  SET_UNREAD_MESSAGES_COUNT = "@@DISCUSSIONS/SET_UNREAD_MESSAGES_COUNT",
  CLEAR_UNREAD_MESSAGES_COUNT = "@@DISCUSSIONS/CLEAR_UNREAD_MESSAGES_COUNT",

  SET_EVENT_CHANNELS_IDS = "@@DISCUSSIONS/SET_EVENT_CHANNELS_IDS",
  CLEAR_EVENT_CHANNELS_IDS = "@@DISCUSSIONS/CLEAR_EVENT_CHANNELS_IDS",

  SET_SENT_MESSAGES_DATES = "@@DISCUSSIONS/SET_SENT_MESSAGES_DATES",

  SET_ALLOW_GENERAL_CHAT_INPUT = "@@DISCUSSIONS/SET_ALLOW_GENERAL_CHAT_INPUT",

  SHOW_GREETER_CUSTOM_CHANNEL = "@@DISCUSSIONS/SHOW_GREETER_CUSTOM_CHANNEL",
}
