import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Field, FieldArray, FieldAttributes, useFormikContext } from "formik";
import classnames from "classnames";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";

import Divider from "../Divider";
import StreamEventPlayer from "../../../StreamEventPlayer";
import { streamUrlValidationRexExp } from "../../../../constants";
import { getS3Url, updateDropdownNames } from "../../../../utils";
import StreamPreview from "../StreamPreview";

import {
  AlertDialog,
  DropDown,
  IDropDownItem,
  ImageUpload,
  SquareButton,
  SquareTextField,
  StyledCheckBox,
  StyledLink,
  StyledRadio,
  UploadDropzone,
} from "shared";
import { getUploadProgress } from "shared/stores/aws/selectors";
import { uploadToS3 } from "shared/stores/aws/actions";
import { EFileStatus, EFileType } from "shared/interfaces/File";
import { setFile } from "shared/stores/file/actions";
import {
  EEventPreview,
  EEventStreamType,
  EPreEventType,
  IEventForm,
  PRE_EVENT_VIDEO,
} from "containers/Sermons/interfaces";
import { LINKS } from "shared/constants/links";
import empty_image2 from "assets/icons/empty_image2.svg";
import ic_remove from "assets/icons/ic_remove.svg";
import upload2 from "assets/icons/upload2.svg";
import event_preview from "assets/images/event_preview.png";
import cross_icon_dark_grey from "assets/icons/cross_icon_dark_grey.svg";
import trash from "assets/icons/trash.svg";

import "./styles.scss";

const maxSlidesCount = 20;

type TProps = {
  saveDraft: () => void;
};

const PreEventStep: FC<TProps> = ({ saveDraft }) => {
  const [showReplaceAlert, setShowReplaceAlert] = useState(false);
  const [uploadFileAfterSave, setUploadFileAfterSave] = useState<File | null>(null);
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [openFilesDialog, setOpenFilesDialog] = useState(false);
  const [videoContainer, setVideoContainer] = useState<HTMLDivElement | null>(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [previewType, setPreviewType] = useState<EEventPreview | null>(null);

  const {
    values: {
      id,
      pre_images,
      files,
      pre_images_interval,
      pre_event_type,
      pre_event_video_url,
      is_pre_event_video_loop,
      is_pre_event_video_autoplay,
    },
    setFieldValue,
    setFieldError,
    handleChange,
    errors,
  } = useFormikContext<IEventForm>();

  const dispatch = useDispatch();
  const uploadProgress = useSelector(getUploadProgress());
  const preEventVideoRegExp = streamUrlValidationRexExp[PRE_EVENT_VIDEO];

  const seconds = useMemo<IDropDownItem[]>(updateDropdownNames, []);

  const preEventVideo = useMemo(() => {
    return files
      ? files.filter(
          file =>
            file.type === EFileType.preEventVideo &&
            (!file.status || [EFileStatus.ready, EFileStatus.uploaded].includes(file.status)),
        )
      : [];
  }, [files]);

  const handlePreEventType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("pre_event_type", e.target.value, false);
  };

  const handleCheckboxLoop = () => {
    setFieldValue("is_pre_event_video_loop", !is_pre_event_video_loop, false);
  };

  const handleCheckboxAutoplay = () => {
    setFieldValue("is_pre_event_video_autoplay", !is_pre_event_video_autoplay, false);
  };

  const onChangeOrder = useCallback(
    (sourceId: string, sourceIndex: number, targetIndex: number) => {
      // Prevent swap for upload template
      if (pre_images.length !== sourceIndex) {
        const newOrder = swap(pre_images, sourceIndex, targetIndex);
        setFieldValue("pre_images", newOrder);
      }
    },
    [pre_images, setFieldValue],
  );

  const cleanErrorForEmptyForm = useCallback(
    (isOnlyFile = true) => {
      setTimeout(() => {
        setFieldError("files", "");
        if (isOnlyFile) {
          setFieldError("pre_event_video_url", "");
        }
      }, 0);
    },
    [setFieldError],
  );

  const upload = useCallback(
    (file?: File) => {
      const finalFile = file || uploadFile;
      if (finalFile) {
        if (id) {
          dispatch(uploadToS3.success(0));
          dispatch(
            uploadToS3.request({
              file: finalFile,
              name: finalFile.name,
              type: EFileType.preEventVideo,
              meeting_id: id,
            }),
          );
        } else {
          setUploadFileAfterSave(finalFile);
          saveDraft();
        }
      }
      setUploadFile(null);
    },
    [dispatch, id, uploadFile, saveDraft],
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const [file] = acceptedFiles;
      upload(file);
    },
    [upload],
  );

  useEffect(() => {
    return () => {
      dispatch(setFile(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (id && uploadFileAfterSave) {
      onDrop([uploadFileAfterSave]);
      setUploadFileAfterSave(null);
    }
  }, [id, onDrop, uploadFileAfterSave]);

  useEffect(() => {
    if (uploadProgress === 100) {
      setTimeout(() => dispatch(uploadToS3.cancel()), 1500);
    }
  }, [dispatch, uploadProgress]);

  const deleteVideo = useCallback(() => {
    if (files) {
      setFieldValue(
        "files",
        files.filter(f => f.type !== EFileType.preEventVideo),
      );
    }
    setUploadFile(null);
    setShowDeleteAlert(false);
    dispatch(uploadToS3.cancel());
    cleanErrorForEmptyForm();
  }, [dispatch, setFieldValue, cleanErrorForEmptyForm, files]);

  const cancelVideo = useCallback(() => {
    if (files) {
      setFieldValue(
        "files",
        files.filter(f => f.type !== EFileType.preEventVideo),
      );
    }
    setShowCancelAlert(false);
    dispatch(uploadToS3.cancel());
    cleanErrorForEmptyForm();
  }, [dispatch, setFieldValue, cleanErrorForEmptyForm, files]);

  const clearVideoUrl = () => {
    setFieldValue("pre_event_video_url", "");
    cleanErrorForEmptyForm();
  };

  const isStartVideoMode = useMemo(() => {
    return (!pre_event_video_url || !pre_event_video_url.match(preEventVideoRegExp)) && preEventVideo.length === 0;
  }, [preEventVideo, preEventVideoRegExp, pre_event_video_url]);

  const isUploading = uploadProgress !== null;

  return (
    <div className="event-pre-event-step">
      {previewType ? (
        <StreamPreview onClose={() => setPreviewType(null)} previewType={previewType} itemCount={20} />
      ) : null}
      <div className="event-pre-event-step-header">
        <p>
          Choose what your attendees sees on the pre-event countdown screen. Slides or a video <br />
          will play automatically and continually until the event starts.
        </p>
      </div>
      <StyledLink className="learn-more" href={LINKS.preEventSlides}>
        Learn more about Pre Event Slides
      </StyledLink>
      <Divider />
      <div className="event-pre-event-step-content">
        <h3>Pre Event Slides</h3>
        <RadioGroup
          name="pre_event_type"
          value={pre_event_type}
          onChange={handlePreEventType}
          className="pre-event-type"
          row
        >
          <FormControlLabel value={EPreEventType.none} control={<StyledRadio />} label="None" className="option" />
          <FormControlLabel value={EPreEventType.video} control={<StyledRadio />} label="Video" className="option" />
          <FormControlLabel value={EPreEventType.images} control={<StyledRadio />} label="Images" className="option" />
        </RadioGroup>
        {pre_event_type === EPreEventType.none && <p>No images or videos will be shown before the event begins.</p>}
        {pre_event_type === EPreEventType.video && (
          <>
            <AlertDialog
              mode="confirm"
              open={showReplaceAlert}
              confirmText="Replace"
              title="Replace Video"
              message="Are you sure that you want to replace this video with another one? The original video will be deleted."
              onCancel={() => setShowReplaceAlert(false)}
              onConfirm={() => {
                setFieldValue("files", []);
                setShowReplaceAlert(false);
                cleanErrorForEmptyForm();
              }}
              dialogClassName="delete-video-alert"
              confirmClassName="delete-button"
            />

            <AlertDialog
              mode="confirm"
              open={showDeleteAlert}
              confirmText="Delete"
              title={"Delete Upload"}
              message={"Are you sure that you want to delete upload of this video from pre event slides?"}
              onCancel={() => setShowDeleteAlert(false)}
              onConfirm={deleteVideo}
              dialogClassName="delete-video-alert"
              confirmClassName="delete-button"
            />

            <AlertDialog
              mode="confirm"
              open={showCancelAlert}
              cancelText="Dismiss"
              confirmText="Cancel"
              title={"Cancel Upload"}
              message={"Are you sure that you want to cancel the upload of this video?"}
              onCancel={() => setShowCancelAlert(false)}
              onConfirm={cancelVideo}
              dialogClassName="delete-video-alert"
              confirmClassName="delete-button"
            />
            <p>
              Copy and paste the URL of a video for your pre-event countdown screen. It will play <br />
              automatically and continually until the event starts.
            </p>
            <div className={"pre-event-settings"}>
              <div className="event-section-label">
                <FormControlLabel
                  control={
                    <StyledCheckBox
                      withBorder={false}
                      checked={!!is_pre_event_video_loop}
                      onChange={handleCheckboxLoop}
                      name="is_pre_event_video_loop"
                    />
                  }
                  label="Loop Video Playback"
                />
              </div>

              <div className="event-section-label">
                <FormControlLabel
                  control={
                    <StyledCheckBox
                      withBorder={false}
                      checked={!!is_pre_event_video_autoplay}
                      onChange={handleCheckboxAutoplay}
                      name="is_pre_event_video_autoplay"
                    />
                  }
                  label="Auto-play Video"
                />
              </div>
            </div>
            {preEventVideo.length === 0 && !isUploading && (
              <div>
                <Field name="pre_event_video_url">
                  {({ field, form }: FieldAttributes<any>) => (
                    <div className={"text-field-with-icon"}>
                      <div className="item">
                        <SquareTextField
                          className="field"
                          fullWidth
                          label="Video URL"
                          name="pre_event_video_url"
                          errors={form.touched.pre_event_video_url && form.errors}
                          value={field.value}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <img src={ic_remove} alt={"Delete url"} onClick={clearVideoUrl} />
                    </div>
                  )}
                </Field>
              </div>
            )}

            {isStartVideoMode && !isUploading && <p>Or upload video from your device.</p>}
            {isStartVideoMode && isUploading && (
              <>
                {!errors.files ? (
                  <p className={"info-message"}>Please wait</p>
                ) : (
                  <p className={"error-message"}>Please wait for the upload to finish</p>
                )}
              </>
            )}
            <div className={"upload-file"}>
              {isStartVideoMode && (
                <>
                  {!isUploading && (
                    <UploadDropzone
                      onDrop={onDrop}
                      accept={[".mp4", ".mov"]}
                      maxSize={10 * 1024 * 1024 * 1024} // 1 GB
                      multiple={false}
                      className={classnames("altar-video-upload")}
                      openDialog={openFilesDialog}
                      onFileDialogCancel={() => setOpenFilesDialog(false)}
                    >
                      <div className="altar-video-upload-inner">
                        <img src={upload2} alt="upload" />
                        <span>Choose a File or Drag It Here</span>
                      </div>
                    </UploadDropzone>
                  )}
                </>
              )}

              {preEventVideo.length > 0 && (
                <>
                  <SquareButton
                    variant="blue-outline"
                    type="button"
                    className="replace-video"
                    onClick={() => setShowReplaceAlert(true)}
                  >
                    Replace Video
                  </SquareButton>
                </>
              )}
            </div>

            {((pre_event_video_url && pre_event_video_url.match(preEventVideoRegExp)) ||
              preEventVideo.length > 0 ||
              isUploading) && (
              <div className="preview-video">
                <div className="preview-video-inline" ref={setVideoContainer}>
                  {isUploading ? (
                    <div className="uploading-progress">
                      Uploading{uploadProgress === 100 && " Completed!"}
                      <div className="total">
                        <div
                          className={classnames("loaded", { completed: uploadProgress === 100 })}
                          style={{ width: `${uploadProgress}%` }}
                        />
                      </div>
                      <span className="percent">{uploadProgress}%</span>
                    </div>
                  ) : (pre_event_video_url && !errors.pre_event_video_url) || preEventVideo.length > 0 ? (
                    <div className={"altar-video"}>
                      <StreamEventPlayer
                        streamType={EEventStreamType.ALTAR}
                        streamUrl={pre_event_video_url || getS3Url(preEventVideo[preEventVideo.length - 1].origin_path)}
                        videoContainer={videoContainer}
                        muted
                        playing
                      />
                    </div>
                  ) : (
                    <>
                      <span className="play-icon-border">
                        <span className="play-icon" />
                      </span>
                    </>
                  )}
                  {(preEventVideo.length > 0 || isUploading) && (
                    <div className="file-actions">
                      <div className="file-name">{preEventVideo[0]?.name}</div>
                      <img
                        className={classnames("delete-file", { uploading: isUploading })}
                        src={isUploading ? cross_icon_dark_grey : trash}
                        alt="delete file"
                        onClick={() => {
                          isUploading ? setShowCancelAlert(true) : setShowDeleteAlert(true);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="preview-video-fullscreen">
                  <img src={event_preview} alt="preview" />
                  <SquareButton
                    type="button"
                    variant={isUploading ? "disabled" : "blue"}
                    disabled={isUploading}
                    onClick={() => setPreviewType(EEventPreview.preEventVideo)}
                  >
                    Full Preview
                  </SquareButton>
                </div>
              </div>
            )}
          </>
        )}
        {pre_event_type === EPreEventType.images && (
          <>
            <p>
              Upload slides to your pre-event countdown screen. Slides will play automatically <br />
              and continually until the event starts.
            </p>
            <div className="preview">
              <div className="preview-inline">
                {pre_images_interval && (
                  <Field name="pre_images_interval">
                    {() => (
                      <div className="pre-images-interval">
                        <DropDown
                          size={"full"}
                          items={seconds}
                          spacer={false}
                          value={pre_images_interval}
                          onChange={sec => {
                            setFieldValue("pre_images_interval", sec);
                          }}
                        />
                      </div>
                    )}
                  </Field>
                )}
                <div className="images">
                  <FieldArray name="pre_images">
                    {({ push, remove, replace, form }) => (
                      <div className="images-formWrapper">
                        <GridContextProvider onChange={onChangeOrder}>
                          <GridDropZone
                            id="preEventImages"
                            boxesPerRow={2}
                            rowHeight={184}
                            className="images-dropzone"
                            style={{ height: (Math.floor(pre_images.length / 2) + 1) * 184 }}
                          >
                            {pre_images.map((image, i) => (
                              <GridItem className="images-dropzone-item" key={image}>
                                <div className="editIcons-drag" />
                                <div className="images-dropzone-item-image">
                                  <ImageUpload
                                    showOptions
                                    maxMb={5}
                                    value={image}
                                    onChange={image => (image ? replace(i, image) : remove(i))}
                                  />
                                </div>
                              </GridItem>
                            ))}
                            {pre_images.length < maxSlidesCount && (
                              <GridItem className="images-dropzone-item">
                                <div
                                  className={classnames("images-dropzone-item-template", {
                                    fullscreen: !pre_images.length,
                                  })}
                                >
                                  <ImageUpload
                                    showOptions
                                    maxMb={5}
                                    onChange={push}
                                    backgroundImage={`url(${empty_image2})`}
                                  />
                                </div>
                              </GridItem>
                            )}
                          </GridDropZone>
                        </GridContextProvider>
                        {form.touched.pre_images && form.errors && (
                          <p className={"error-message"}>{form.errors.pre_images}</p>
                        )}
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>
              <div className="preview-fullscreen">
                <img src={event_preview} alt="preview" />
                <SquareButton
                  type="button"
                  variant={"blue"}
                  onClick={() => setPreviewType(EEventPreview.preEventSlides)}
                >
                  Full Preview
                </SquareButton>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PreEventStep;
