import React from "react";

import { StyledLink } from "shared";

import "./engagementCard.scss";

interface IEngagementCard {
  item: {
    imgSrc: string;
    title: string;
    description: string;
    link: string;
  };
}

const EngagementCard: React.FC<IEngagementCard> = ({ item }) => {
  return (
    <StyledLink href={item.link}>
      <div className="content-cards-item">
        <div className="content-cards-item-img">
          <img src={item.imgSrc} alt="engagement_img" />
        </div>
        <div className="content-cards-item-info">
          <div className="content-cards-item-info-title">{item.title}</div>
          <div className="content-cards-item-info-description">{item.description}</div>
        </div>
      </div>
    </StyledLink>
  );
};

export default EngagementCard;
