import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import { NameOfRoutes, NamesOfParentRoutes } from "../../../../../constants";
import { updateEventSettings } from "../../../store/actions";
import { EventDashboardTabs } from "../../../interfaces";
import EventAuthLogin from "./EventAuthLogin";
import EventAuthJoin from "./EventAuthJoin";

import { AuthShape } from "containers/Auth/interfaces";
import { actions, emailValidationSchema, loginValidationSchema } from "containers/Auth";
import { checkEmail, setOnboardingUser } from "containers/Onboarding/store/actions";
import {
  getTokenByHash,
  sendMagicLink,
  setEmailResent,
  setError,
  setRegistrationData,
} from "containers/Auth/store/actions";
import { getEmailIsExist, getOnboardingUser } from "containers/Onboarding/store/selectors";
import EmailSent from "containers/Auth/components/EmailSent";
import { getRegistrationData, isEmailResent, isMagicLinkSent } from "containers/Auth/store/selectors";
import { generateDataFromSocialProvider } from "containers/Auth/utils";
import loginHashHandler from "shared/utils/loginHashHandler";
import { ECreationKind, IRouteProps } from "shared";

import "./eventAuthDialog.scss";

const { STREAM } = NamesOfParentRoutes;
const { EVENT_AUTH_SUCCESS } = NameOfRoutes;

interface IEventAuthInner {
  onClose?: () => void;
  onAuthSuccess?: () => void;
  message?: string;
  className?: string;
}

const EventAuthInner: React.FC<IEventAuthInner> = props => {
  const { eventCode } = useParams<IRouteProps>();
  const { onClose, onAuthSuccess, className } = props;

  const [initialValues] = useState<AuthShape>({ email: "", password: "" });

  const user = useSelector(getOnboardingUser());
  const emailExists = useSelector(getEmailIsExist());
  const magicLinkSent = useSelector(isMagicLinkSent());
  const emailResent = useSelector(isEmailResent());
  const registrationData = useSelector(getRegistrationData());

  const dispatch = useDispatch();

  useEffect(() => () => loginHashHandler.remove(), []);

  const handleSubmit = (values: AuthShape) => {
    if (emailExists && user) {
      dispatch(
        actions.login.request({
          ...values,
          redirectTo: "",
          callback: onAuthSuccess ? onAuthSuccess : onClose,
          skipActions: true,
        }),
      );
    } else {
      dispatch(
        checkEmail.request({
          email: values.email,
          callback: () => {
            dispatch(setRegistrationData({ ...registrationData, email: values.email }));
            dispatch(updateEventSettings({ activeDashboardTab: EventDashboardTabs.signUp }));
          },
        }),
      );
    }
  };

  const success = (data: any) => {
    const payload = generateDataFromSocialProvider(data);
    dispatch(
      actions.socialLogin.request(
        payload
          ? { eventCode, ...payload, callback: onAuthSuccess ? onAuthSuccess : onClose, kind: ECreationKind.event }
          : null,
      ),
    );
  };

  const handleBack = () => {
    dispatch(setOnboardingUser(null));
    dispatch(setEmailResent(false));
    dispatch(setError(null));
    dispatch(checkEmail.success(false));
  };

  return (
    <div className={classNames("eventAuthDialog", className)}>
      <Formik
        validateOnChange={false}
        validationSchema={emailExists && user ? loginValidationSchema : emailValidationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ values, handleSubmit, resetForm }) => {
          const handleChangeEmail = () => {
            resetForm();
            dispatch(sendMagicLink.cancel());
            handleBack();
          };

          const handleSendMagicLink = () => {
            const hash = loginHashHandler.generate();
            dispatch(
              sendMagicLink.request({
                email: values.email,
                redirectTo: `${STREAM}${EVENT_AUTH_SUCCESS}`,
                hash,
              }),
            );
            dispatch(getTokenByHash(hash));
          };

          return (
            <div className="eventAuthDialog-container">
              <form onSubmit={handleSubmit}>
                {magicLinkSent ? (
                  <EmailSent
                    title={emailResent ? "Magic link has been resent!" : "Magic link was sent!"}
                    subTitle={
                      <>
                        Email has been sent to {values.email}.
                        <br />
                        Please check your inbox to continue.
                      </>
                    }
                    onChangeEmail={handleChangeEmail}
                    onResendEmail={handleSendMagicLink}
                    eventPage
                  />
                ) : emailExists && user ? (
                  <EventAuthLogin user={user} onSendMagicLink={handleSendMagicLink} />
                ) : (
                  <EventAuthJoin onSocialLoginSuccess={success} />
                )}
              </form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default React.memo(EventAuthInner);
