import React from "react";

import { AnnouncementStatus, IAnnouncementDialogValues, IAnnouncementTemplate } from "../../../../interfaces";
import { prepareAnnouncementForm } from "../../../../utils";

import { Button, ThreeDotsPopup } from "shared";

import "./index.scss";

interface IProps {
  announcementTemplates: IAnnouncementTemplate[];
  onPublish: (template: IAnnouncementDialogValues) => void;
  onEdit: (template: IAnnouncementTemplate) => void;
}

const EventAnnouncementTemplatesList: React.FC<IProps> = ({ announcementTemplates, onEdit, onPublish }) => {
  return (
    <div className="eventAnnouncementTemplates">
      {announcementTemplates.map(template => (
        <div key={template.id} className="eventAnnouncementTemplates-card">
          <div className="eventAnnouncementTemplates-card-title">{template.title}</div>
          <div className="eventAnnouncementTemplates-card-description">{template.description}</div>
          {template.button_link && (
            <Button
              className="eventAnnouncementTemplates-button"
              variant="orange-outline"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                window.open(template.button_link);
              }}
            >
              {template.button_text}
            </Button>
          )}
          <div className="eventAnnouncementTemplates-card-divider" />
          <div className="eventAnnouncementTemplates-card-footer">
            <div className="eventAnnouncementTemplates-card-footer-type">Show as {template.type}</div>
            <div className="eventAnnouncementTemplates-card-footer-controls">
              <Button
                className="eventAnnouncementTemplates-card-footer-controls-publish"
                variant="white-text"
                onClick={() =>
                  onPublish(prepareAnnouncementForm({ ...template, status: AnnouncementStatus.published }))
                }
              >
                Publish
              </Button>
              <ThreeDotsPopup>
                <div className="eventAnnouncementTemplates-card-footer-controls-edit" onClick={() => onEdit(template)}>
                  Edit
                </div>
              </ThreeDotsPopup>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventAnnouncementTemplatesList;
