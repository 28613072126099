import React from "react";
import classnames from "classnames";
import { EmptyStateIndicatorProps } from "stream-chat-react";

import { DiscussionTheme } from "containers/Discussions/containers/DiscussionsContainer/DiscussionsContainer";
import chats_icon from "assets/icons/chats_icon.svg";
import chats_icon_light from "assets/icons/chats_icon_light.svg";

import "./styles.scss";

interface IEmptyChatState extends EmptyStateIndicatorProps {
  title?: string;
  theme?: DiscussionTheme;
}

const EmptyChatState: React.FC<IEmptyChatState> = ({ title = "No Messages here yet", theme }) => {
  return (
    <div className={classnames("emptyChatState", theme)}>
      <div className="emptyChatState-image">
        <img src={!theme ? chats_icon_light : chats_icon} alt="empty chat" />
      </div>
      <p className="emptyChatState-title">{title}</p>
    </div>
  );
};

export default EmptyChatState;
