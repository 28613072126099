import "./index.scss";

import { Input } from "@material-ui/core";
import { FieldAttributes } from "formik";
import React from "react";

const compileTextProps = () => ({
  classes: {
    root: `styledInput input-field`,
    multiline: "input-field",
    input: "input",
    disabled: "disabled-text",
  },
  disableUnderline: true,
});

const StyledInput: React.FC<FieldAttributes<any>> = props => {
  const { type } = props;

  return <Input {...props} {...compileTextProps()} type={type} />;
};

export default StyledInput;
