import React from "react";
import { useMessageContext } from "stream-chat-react";

import ReactionItem from "./ReactionItem";

import "./index.scss";

interface CustomReactionsListProp {
  emojiConfig?: any;
  handleReaction?: (type: string, e: React.BaseSyntheticEvent) => void;
}

const CustomReactonsList = (props: CustomReactionsListProp) => {
  const { handleReaction, emojiConfig } = props;
  const { message } = useMessageContext();
  const { reaction_counts } = message;
  if (!reaction_counts) return null;
  return (
    <div className="custom-reactions-list">
      {
        // @ts-ignore
        Object.keys(reaction_counts).map((key, index) => (
          <ReactionItem
            key={index}
            emojiConfig={emojiConfig}
            onClick={(e: React.BaseSyntheticEvent) => handleReaction && handleReaction(key, e)}
            type={key}
            // @ts-ignore
            count={Number(reaction_counts[key])}
            // @ts-ignore
            own_reactions={message.own_reactions}
          />
        ))
      }
    </div>
  );
};

export default React.memo(CustomReactonsList);
