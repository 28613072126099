import React from "react";

import "./index.scss";

interface IDialogCard {
  header?: JSX.Element;
  footer?: JSX.Element;
}

const DialogCard: React.FC<IDialogCard> = ({ header, children, footer }) => {
  return (
    <div className="dialog-card">
      <div className="dialog-card-header">{header}</div>
      <div className="dialog-card-body">{children}</div>
      <div className="dialog-card-footer">{footer}</div>
    </div>
  );
};
export default DialogCard;
