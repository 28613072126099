import { IMemberDialogValuesRequest, IMember } from "./member";

export enum MemberDialogMode {
  edit = "edit",
  add = "add",
}
export interface IMemberDialog {
  onSave: (values: IMemberDialogValuesRequest) => void;
  onClose: () => void;
  onChangeMode: () => void;
  onEditExistMember: (values: IMemberDialogValuesRequest, id?: number) => void;
  member?: IMember;
  communityCode: string;
  communityId?: number;
  isEditMode: boolean;
  open: boolean;
  title: string;
}
