import { createSelector } from "reselect";

import { AltarStore } from "../../../shared/types";

const selectCommunity = (state: AltarStore) => state.onboarding;

export const getCommunityData = () => createSelector(selectCommunity, state => state.communityData);
export const getCommunity = () =>
  createSelector(selectCommunity, state => state.communityData && state.communityData.community);
export const getCommunityUser = () =>
  createSelector(selectCommunity, state => state.communityData && state.communityData.member);
export const getUserId = () => createSelector(selectCommunity, state => state.user_id);
export const getOnboardingUser = () => createSelector(selectCommunity, state => state.user);
export const getOnboardingCommunity = () => createSelector(selectCommunity, state => state.community);
export const getOnboardingMember = () => createSelector(selectCommunity, state => state.member);
export const getEmailIsExist = () => createSelector(selectCommunity, state => state.emailExists);
export const getTargetEmail = () =>
  createSelector(selectCommunity, state => state.communityData && state.communityData.target_email);
