import React, { useCallback, useMemo } from "react";
import { Range } from "react-date-range";

import { EAnalyticsRange, EAnalyticsReport } from "../../../interfaces";
import { defineAnalyticsDateRange } from "../../../utils";

import { download } from "shared/utils/request";
import { API } from "shared";
import fileIcon from "assets/icons/file_icon.svg";

import "./index.scss";

interface IProps {
  analyticsRange: EAnalyticsRange;
  customRange: Range[];
  communityId: number;
}

const reports = [
  {
    fileName: "attendees_list.csv",
    type: EAnalyticsReport.attendees,
  },
  {
    fileName: "new_members_report.csv",
    type: EAnalyticsReport.newMembers,
  },
  {
    fileName: "events_and_meetings_top_list.csv",
    type: EAnalyticsReport.topList,
  },
];

const EngagementReports: React.FC<IProps> = ({ analyticsRange, customRange, communityId }) => {
  const dateRange = useMemo(() => {
    const { startDate, endDate } = customRange[0];
    return defineAnalyticsDateRange(analyticsRange, startDate, endDate);
  }, [customRange, analyticsRange]);

  const fileNamePrefix = useMemo(() => {
    const { from, to } = dateRange;
    return `${from.replaceAll("-", "/")}-${to.replaceAll("-", "/")}`;
  }, [dateRange]);

  const downloadHandler = useCallback(
    (e, fileName: string, type: EAnalyticsReport) => {
      e.stopPropagation();
      const { from, to } = dateRange;
      const offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
      download(API.DOWNLOAD_ANALYTICS_REPORT(communityId), fileName, { type, from, to, offset });
    },
    [communityId, dateRange],
  );

  return (
    <div className="reports">
      {reports.map((r, index) => {
        const fileName = `${fileNamePrefix}_${r.fileName}`;
        return (
          <div className="reports-item" key={index}>
            <div className="reports-item-file">
              <img src={fileIcon} alt="file" />
              {fileName}
            </div>
            <div className="reports-item-action" onClick={e => downloadHandler(e, fileName, r.type)}>
              Download Report
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EngagementReports;
