import { useMemo } from "react";
import { useSelector } from "react-redux";

import { EventPermissions } from "../../Auth";
import { checkMeetingRole, IEvent, SermonRoles } from "../../Sermons";
import { selectRoomCode, selectRooms } from "../store/selectors";
import { BreakoutRoomTypes } from "../interfaces";

import { useCurrentUserMember, usePermissions } from "shared";

const useEditTable = (event: IEvent | null) => {
  const currentRoomCode = useSelector(selectRoomCode());
  const currentUserMember = useCurrentUserMember();
  const rooms = useSelector(selectRooms());

  const tableName = useMemo(() => {
    const tables = rooms.filter(r => r.type === BreakoutRoomTypes.lobby);
    const currentTableIdx = !currentRoomCode ? -1 : tables.findIndex(t => t.code === currentRoomCode);
    return currentTableIdx !== -1 && (tables[currentTableIdx]?.name || `Table ${currentTableIdx + 1}`);
  }, [currentRoomCode, rooms]);

  const editPermissions = useMemo(() => [EventPermissions.edit], []);
  const canEdit = usePermissions(editPermissions, event?.community_id);
  const canEditName = useMemo(
    () =>
      canEdit ||
      Boolean(event && currentUserMember && checkMeetingRole(event, currentUserMember, [SermonRoles.greeterHost])),
    [canEdit, currentUserMember, event],
  );

  return { tableName, canEditName };
};

export default useEditTable;
