import { API, METHODS } from "../../../shared/constants";
import { request } from "../../../shared/utils";
import { IAddModerators, ICreateGeneralChat, ICreateNewChat, IGetChannels, IUpdateChannel } from "../interfaces";

export default {
  getChannels: ({ community_id, params }: IGetChannels) =>
    request(METHODS.GET, API.FETCH_CHANNELS(community_id))({ params }),
  getAnonymousChannels: ({ params }: IGetChannels) => request(METHODS.GET, API.FETCH_ANONYMOUS_CHANNELS)({ params }),
  getChannelDetails: (id: string) => request(METHODS.GET, API.GET_CHANNEL_DETAILS(id))(),
  createNewChat: (payload: ICreateNewChat) => {
    const { community_id, ...data } = payload;
    return request(METHODS.POST, API.CREATE_NEW_CHAT(community_id))(data);
  },
  createAnonymousChannelWithGreeter: ({
    greeter_id,
    meeting_id,
    anonymous_id,
  }: {
    greeter_id: number;
    meeting_id: number;
    anonymous_id: string;
  }) => {
    return request(METHODS.POST, API.CREATE_ANONYMOUS_CHANNEL_WITH_GREETER(meeting_id))({ greeter_id, anonymous_id });
  },
  createGeneralChat: (payload: ICreateGeneralChat) => request(METHODS.POST, API.CREATE_GENERAL_CHANNEL)(payload),
  updateChannel: ({ community_id, id, ...values }: IUpdateChannel) =>
    request(METHODS.PUT, API.UPDATE_CHANNEL(community_id, id))(values),
  deleteChannel: (community_id: number, channel_id: string) =>
    request(METHODS.PUT, API.DELETE_CHANNEL(community_id, channel_id))(),
  leaveFromChat: (id: string) => request(METHODS.PUT, API.LEAVE_FROM_CHAT(id))(),
  addChannelModerators: (payload: IAddModerators) => request(METHODS.POST, API.ADD_MODERATORS)(payload),
};
