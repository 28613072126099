import { createSelector } from "reselect";

import { AltarStore } from "../../../shared/types";

import { IEvent } from "containers/Sermons";

const selectLanding = (state: AltarStore) => state.landing;

export const selectLandingData = () => createSelector(selectLanding, state => state.settings);
export const selectSchedule = () => createSelector(selectLanding, state => state.eventsSchedule);
export const selectArchive = () => createSelector(selectLanding, state => state.archiveEvents);

export const selectUpcomingEvent = () =>
  createSelector(selectLanding, state => {
    const upcoming_day = state.eventsSchedule ? state.eventsSchedule[0] : null;
    if (upcoming_day) return upcoming_day.events[0];
    return null;
  });

export const selectFeaturedEvents = () =>
  createSelector(selectLanding, state => {
    const events = state.eventsSchedule.reduce((acc: IEvent[], cV) => {
      const res = [...acc, ...cV.events];
      return res;
    }, []);

    const upcomingEvent = state.eventsSchedule?.[0]?.events?.[0];

    const featured = events.filter(e => e.is_featured && upcomingEvent?.code !== e.code);

    return featured.slice(0, 3);
  });

export const selectEventParams = () => createSelector(selectLanding, state => state.params);
export const selectEventsCount = () => createSelector(selectLanding, state => state.eventsCount);
export const selectPastEventsCount = () => createSelector(selectLanding, state => state.pastEventsCount);
export const selectSocketConnected = () => createSelector(selectLanding, state => state.socketConnected);
