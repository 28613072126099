import { action, createAsyncAction } from "typesafe-actions";

import {
  ICommunityData,
  ICheckUserRequest,
  ICreateProfileRequest,
  ICheckVerifyUserData,
  IUpdateOnboardingUser,
  ICreateOnboardingUser,
  IRegisterMemberRequest,
  IConfirmationEmailData,
} from "../interfaces";
import { OnboardingActionTypes } from "./constants";

import { IUser } from "containers/Auth/interfaces";
import { ICommunityItem, ICreateCommunityRequest, IUpdateCommunity } from "containers/Community/interfaces";
import { IMember } from "containers/Member";

export const checkInvitation = createAsyncAction(
  OnboardingActionTypes.CHECK_INVITATION_LINK_REQUEST,
  OnboardingActionTypes.CHECK_INVITATION_LINK_SUCCESS,
  OnboardingActionTypes.CHECK_INVITATION_LINK_FAILURE,
)<string, ICommunityData, undefined>();

export const checkEmail = createAsyncAction(
  OnboardingActionTypes.CHECK_EMAIL_REQUEST,
  OnboardingActionTypes.CHECK_EMAIL_SUCCESS,
  OnboardingActionTypes.CHECK_EMAIL_FAILURE,
)<ICheckUserRequest, boolean, undefined>();

export const sendUserConfirmationEmail = createAsyncAction(
  OnboardingActionTypes.SEND_USER_CONFIRMATION_EMAIL_REQUEST,
  OnboardingActionTypes.SEND_USER_CONFIRMATION_EMAIL_SUCCESS,
  OnboardingActionTypes.SEND_USER_CONFIRMATION_EMAIL_FAILURE,
)<IConfirmationEmailData, undefined, undefined>();

export const checkEmailIsConfirmed = createAsyncAction(
  OnboardingActionTypes.CHECK_EMAIL_IS_CONFIRMED_REQUEST,
  OnboardingActionTypes.CHECK_EMAIL_IS_CONFIRMED_SUCCESS,
  OnboardingActionTypes.CHECK_EMAIL_CONFIRMATION_FAILURE,
)<{ token: string; callback: () => void }, undefined, undefined>();

export const checkUserIsVerified = createAsyncAction(
  OnboardingActionTypes.CHECK_USER_IS_VERIFIED_REQUEST,
  OnboardingActionTypes.CHECK_USER_IS_VERIFIED_SUCCESS,
  OnboardingActionTypes.CHECK_USER_IS_VERIFIED_FAILURE,
)<
  {
    data: ICheckVerifyUserData;
    withoutRedirect?: boolean;
    callback?: () => void;
    skipActions?: boolean;
  },
  undefined,
  undefined
>();

export const createProfile = createAsyncAction(
  OnboardingActionTypes.CREATE_PROFILE_REQUEST,
  OnboardingActionTypes.CREATE_PROFILE_SUCCESS,
  OnboardingActionTypes.CREATE_PROFILE_FAILURE,
)<ICreateProfileRequest, number, undefined>();

export const registerMember = createAsyncAction(
  OnboardingActionTypes.REGISTER_MEMBER_REQUEST,
  OnboardingActionTypes.REGISTER_MEMBER_SUCCESS,
  OnboardingActionTypes.REGISTER_MEMBER_FAILURE,
)<IRegisterMemberRequest, IMember, undefined>();

export const createUserByEmail = createAsyncAction(
  OnboardingActionTypes.CREATE_USER_REQUEST,
  OnboardingActionTypes.CREATE_USER_SUCCESS,
  OnboardingActionTypes.CREATE_USER_FAILURE,
)<ICreateOnboardingUser, IUser, undefined>();

export const updateOnboardingUser = createAsyncAction(
  OnboardingActionTypes.UPDATE_USER_REQUEST,
  OnboardingActionTypes.UPDATE_USER_SUCCESS,
  OnboardingActionTypes.UPDATE_USER_FAILURE,
)<IUpdateOnboardingUser, IUser, undefined>();

export const createCommunity = createAsyncAction(
  OnboardingActionTypes.CREATE_COMMUNITY_REQUEST,
  OnboardingActionTypes.CREATE_COMMUNITY_SUCCESS,
  OnboardingActionTypes.CREATE_COMMUNITY_FAILURE,
)<ICreateCommunityRequest, ICommunityItem, undefined>();

export const updateCommunity = createAsyncAction(
  OnboardingActionTypes.UPDATE_COMMUNITY_REQUEST,
  OnboardingActionTypes.UPDATE_COMMUNITY_SUCCESS,
  OnboardingActionTypes.UPDATE_COMMUNITY_FAILURE,
)<{ community: IUpdateCommunity; callback?: (data: ICommunityItem) => void }, ICommunityItem, undefined>();

export const clearOnboardingStore = () => action(OnboardingActionTypes.CLEAR_ONBOARDING);

export const setOnboardingUser = (user: IUser | null) => action(OnboardingActionTypes.SET_USER, user);

export const openCommunityInApp = createAsyncAction(
  OnboardingActionTypes.OPEN_COMMUNITY_IN_APP_REQUEST,
  OnboardingActionTypes.OPEN_COMMUNITY_IN_APP_SUCCESS,
  OnboardingActionTypes.OPEN_COMMUNITY_IN_APP_FAILURE,
)<string, { deepLink: string }, undefined>();

export const getOnboardingCommunity = createAsyncAction(
  OnboardingActionTypes.GET_ONBOARDING_COMMUNITY_REQUEST,
  OnboardingActionTypes.GET_ONBOARDING_COMMUNITY_SUCCESS,
  OnboardingActionTypes.GET_ONBOARDING_COMMUNITY_FAILURE,
)<string, ICommunityItem, undefined>();
