import * as Yup from "yup";

export const pollSchema = Yup.object({
  title: Yup.string().max(30, "Title field is too long").required("Title is a required field").trim(),
  poll_question: Yup.string().max(150, "Poll text field is too long").required("Poll text is a required field").trim(),
  options: Yup.array().of(
    Yup.object().shape({
      option_text: Yup.string().max(30, "Max 30 characters").required("Option field is required").trim(),
    }),
  ),
});
