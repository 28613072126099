import React from "react";
import "./emptyText.scss";

interface IEmptyText {
  message: string | React.ReactNode;
  icon?: string;
  show: boolean;
  classes?: string;
}

const EmptyText: React.FunctionComponent<IEmptyText> = ({ message, show, icon, classes }) => {
  return show ? (
    <div className={`emptyText ${classes || ""}`}>
      {icon && <img src={icon} alt="no matches" />}
      <div className="emptyText-message">{message}</div>
    </div>
  ) : null;
};

export default EmptyText;
