import styled from "styled-components";
interface IStyledButton {
  width?: number;
  height?: number;
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset";
}

export default styled.button`
  width: ${(props: IStyledButton) => (props.width ? `${props.width}px` : "100%")};
  height: ${(props: IStyledButton) => (props.height ? `${props.height}px` : "40px")};
  border: 0;
  text-transform: uppercase;
  display: inline-block;
  outline: none;
  cursor: pointer;
`;
