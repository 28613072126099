import React from "react";
import classnames from "classnames";

import { BlockDivider } from "../BlockDivider";

import "./index.scss";

interface Props {
  title: string;
  description?: string;
  bottomDivider?: boolean;
}

const SettingsBlock: React.FC<Props> = ({ children, bottomDivider = true, title, description }) => {
  return (
    <div className={classnames("settings-block", { "settings-block-bottom-divider": bottomDivider })}>
      <div className="settings-block-title">{title}</div>
      {description && <div className="settings-block-description">{description}</div>}
      <BlockDivider />
      <div className="settings-block-content">{children}</div>
    </div>
  );
};

export default SettingsBlock;
