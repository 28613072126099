import React from "react";
import { useTranslation } from "react-i18next";

import { Overlay, SquareButton } from "shared/components";

import "./stripeCancelPopup.scss";

type Props = {
  opened: boolean;
  onClose: () => void;
};

const StripeCancelPopup: React.FC<Props> = ({ opened, onClose }) => {
  const { t } = useTranslation();
  return (
    <Overlay onClose={onClose} show={opened}>
      <div className="stripeCancelPopup">
        <span className="close" onClick={onClose} />
        <div className="text-header">{t("No worries!")}</div>
        <div className="text">
          {t(
            "You can always subscribe when you're ready, If you have any questions or concerns - please reach us through the chat bubble below or email ",
          )}
          <a href="mailto: contact@altarlive.com">contact@altarlive.com.</a>
        </div>
        <div className="footer">
          <SquareButton type="button" variant="blue" onClick={onClose}>
            {t("Ok, Got it")}
          </SquareButton>
        </div>
      </div>
    </Overlay>
  );
};

export default StripeCancelPopup;
