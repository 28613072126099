import React, { useEffect, useState } from "react";
import { Field, FieldArray, FieldAttributes, useFormikContext } from "formik";
import { FormControlLabel, RadioGroup } from "@material-ui/core";

import { getDatePickerFormat } from "../../../../utils";
import DayIcon from "./DayIcon";

import { EEventReCurringEnds, EEventScheduleDay, IEventForm } from "containers/Sermons/interfaces";
import { SquareDatePicker, SquareTextField, StyledRadio } from "shared";

import "./styles.scss";

const RecurrencePopup: React.FC = () => {
  const {
    values: { schedule, recurring_ends_on },
    errors: { schedule: errors },
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
  } = useFormikContext<IEventForm>();

  const [defaultValues] = useState({
    until: schedule.until,
    occurrences: schedule.occurrences,
  });

  useEffect(() => {
    if (recurring_ends_on !== EEventReCurringEnds.on) {
      setFieldValue("schedule.until", null);
      // @ts-ignore
      setFieldError("schedule.until", undefined);
    }
    if (recurring_ends_on !== EEventReCurringEnds.after) {
      setFieldValue("schedule.occurrences", null);
      // @ts-ignore
      setFieldError("schedule.occurrences", undefined);
    }
  }, [recurring_ends_on, setFieldError, setFieldValue]);

  const onEndsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === EEventReCurringEnds.on && schedule.until === null) {
      setFieldValue("schedule.until", defaultValues.until);
    } else if (e.target.value === EEventReCurringEnds.after && schedule.occurrences === null) {
      setFieldValue("schedule.occurrences", defaultValues.occurrences);
    }
    handleChange(e);
  };

  return (
    <div className="event-schedule">
      <div className="event-schedule-frequency row">
        Repeat every
        <SquareTextField
          type="number"
          className="number"
          name="schedule.interval"
          value={schedule.interval}
          onChange={handleChange}
        />
        week
        {errors?.interval && <p className="error">{errors.interval}</p>}
      </div>
      <div className="event-schedule-days">
        Repeat on
        <div className="row">
          <FieldArray name="schedule.day_of_week">
            {({ push, remove }) => {
              const onClick = (value: EEventScheduleDay) => {
                const idx = schedule.day_of_week.indexOf(value);
                if (idx > -1) {
                  remove(idx);
                } else {
                  push(value);
                }
              };
              return Object.values(EEventScheduleDay).map(day => (
                <DayIcon key={day} value={day} checked={schedule.day_of_week.includes(day)} onClick={onClick} />
              ));
            }}
          </FieldArray>
          {errors?.day_of_week && <p className="error">{errors.day_of_week}</p>}
        </div>
      </div>
      <div className="event-schedule-ends">
        Ends
        <RadioGroup name="recurring_ends_on" value={recurring_ends_on} onChange={onEndsChange} className="ends-options">
          <FormControlLabel
            value={EEventReCurringEnds.never}
            control={<StyledRadio />}
            label="Never"
            className="option"
          />
          <FormControlLabel
            value={EEventReCurringEnds.on}
            control={<StyledRadio />}
            label={
              <div className="row">
                <div className="label">On</div>
                {recurring_ends_on === EEventReCurringEnds.on && (
                  <>
                    <Field name="schedule.until">
                      {({ field, form, meta }: FieldAttributes<any>) => (
                        <SquareDatePicker {...field} {...form} {...meta} format={getDatePickerFormat()} />
                      )}
                    </Field>
                    {errors?.until && <p className="error">{errors.until}</p>}
                  </>
                )}
              </div>
            }
            className="option"
          />
          <FormControlLabel
            value={EEventReCurringEnds.after}
            control={<StyledRadio />}
            label={
              <div className="row">
                <div className="label">After</div>
                {recurring_ends_on === EEventReCurringEnds.after && (
                  <>
                    <SquareTextField
                      type="number"
                      className="number"
                      name="schedule.occurrences"
                      value={schedule.occurrences}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    occurrences
                  </>
                )}
                {errors?.occurrences && <p className="error">{errors.occurrences}</p>}
              </div>
            }
            className="option"
          />
        </RadioGroup>
      </div>
    </div>
  );
};

export default RecurrencePopup;
