import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import classnames from "classnames";

import { exitRoom } from "../../store/actions";
import { getCurrentBreakOutRoomScreen, getMediaPermissions, getUserDevices } from "../../store/selectors";
import { actions as eventActions } from "../../../Sermons/store";
import { actions } from "../../store";
import { TableListContainer } from "../TableListContainer";
import { NamesOfParentRoutes } from "../../../../constants";

import { peerConnection } from "containers/BreakoutRooms/utils/peerConnection";
import { useRoomConnect } from "containers/BreakoutRooms/hooks/useRoomConnect";
import useElementSize from "shared/hooks/ElementSizeHook/ElementSizeHook";
import { getEvent, getEventSettings } from "containers/Sermons/store/selectors";
import { VideoMenu, VideoWrapper } from "containers/BreakoutRooms/components";
import { getBrowserInfo } from "shared/stores/browser/selectors";
import { PERMISSIONS_FLOW_BROWSERS } from "shared";
import ParticipantsPanel from "containers/Sermons/components/EventContainer/ParticipantsPanel";
import { BreakOutRoomScreenTypes } from "containers/BreakoutRooms/interfaces";
import { ClassroomsContainer } from "containers/Meeting";
import { handleFirebaseEvent } from "utils/firebase";
import { FIREBASE_EVENTS } from "shared/interfaces/Firebase";

import "./index.scss";

const { BREAKOUTROOMS, STREAM } = NamesOfParentRoutes;

export const BreakoutRoom = () => {
  const { isEnded, stream, isConnecting, roomCode, isAuthenticated, rooms, constraints } = useRoomConnect();

  const [breakoutRoomWrapper, setBreakoutRoomWrapper] = useState<HTMLDivElement | null>(null);
  const [trackedRoomCode, setTrackedRoomCode] = useState("");

  const { activeDashboardTab, showTables } = useSelector(getEventSettings());
  const event = useSelector(getEvent());
  const browserInfo = useSelector(getBrowserInfo());
  const mediaPermissions = useSelector(getMediaPermissions());
  const breakOutRoomScreen = useSelector(getCurrentBreakOutRoomScreen());
  const userDevices = useSelector(getUserDevices());

  const history = useHistory();

  const dispatch = useDispatch();

  const onToggleTablesView = useCallback(() => {
    dispatch(
      eventActions.updateEventSettings({
        showTables: !showTables,
      }),
    );
  }, [dispatch, showTables]);

  useEffect(() => {
    if (isAuthenticated) {
      peerConnection.updateSocketToken();
    } else if (event) {
      history.replace(`${STREAM}/${event.code}${BREAKOUTROOMS}`);
    }
  }, [isAuthenticated, event, history]);

  useEffect(() => {
    if (roomCode && stream === null && !isEnded && isAuthenticated && userDevices) {
      if (PERMISSIONS_FLOW_BROWSERS.includes(browserInfo.name)) {
        if (event && (mediaPermissions === "denied" || mediaPermissions === "prompt")) {
          dispatch(
            exitRoom.request({
              callback: () => {
                history.replace(`${STREAM}/${event.code}${BREAKOUTROOMS}`);
              },
            }),
          );
        } else if (mediaPermissions === "granted") {
          dispatch(actions.setRoomCode(roomCode));
          dispatch(actions.getUserMedia.request(constraints));
        }
      } else {
        dispatch(actions.setRoomCode(roomCode));
        dispatch(actions.getUserMedia.request(constraints));
      }
    }
  }, [
    dispatch,
    history,
    roomCode,
    isEnded,
    stream,
    isAuthenticated,
    event,
    mediaPermissions,
    browserInfo.name,
    constraints,
    userDevices,
  ]);

  useEffect(() => {
    if (stream && roomCode && roomCode !== trackedRoomCode && rooms) {
      const currentBenchIndex = rooms.findIndex(({ code }) => code === roomCode);
      if (currentBenchIndex > -1) {
        handleFirebaseEvent(FIREBASE_EVENTS.JOIN_EVENT_TABLE, {
          table_id: rooms[currentBenchIndex].id,
          table_name: rooms[currentBenchIndex].name || String(currentBenchIndex + 1).padStart(2, "0"),
          event_id: rooms[currentBenchIndex].meeting_id,
        });
        setTrackedRoomCode(roomCode);
      }
    }
  }, [roomCode, rooms, stream, trackedRoomCode]);

  useElementSize(breakoutRoomWrapper, 300);

  const wrapperWidth = breakoutRoomWrapper?.offsetWidth ?? 0;
  const wrapperHeight = breakoutRoomWrapper?.offsetHeight ?? 0;

  return isEnded ? (
    (() => {
      return <Redirect to={window.location.pathname.replace(roomCode || "", "").slice(0, -1)} />;
    })()
  ) : (
    <>
      {isConnecting && stream && event && showTables && (
        <ParticipantsPanel event={event} onExpand={onToggleTablesView} />
      )}
      <div
        className={classnames("breakout-room-wrapper", {
          "breakout-room-inner-wrapper-sidebar": !!activeDashboardTab,
        })}
      >
        {showTables ? (
          breakOutRoomScreen === BreakOutRoomScreenTypes.tables ? (
            <TableListContainer />
          ) : (
            <ClassroomsContainer />
          )
        ) : (
          <>
            <div className="videos-wrapper" ref={setBreakoutRoomWrapper}>
              <VideoWrapper containerData={{ width: wrapperWidth, height: wrapperHeight }} />
              {stream && (
                <div className="collapse-videos" onClick={onToggleTablesView}>
                  Collapse
                </div>
              )}
            </div>
            {stream && event && (
              <div className="footer">
                <VideoMenu stream={stream} event={event} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(BreakoutRoom);
