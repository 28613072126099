import { call, put, takeLatest, select } from "redux-saga/effects";
import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

import { getStripe } from "./selectors";
import api from "./api";
import { RedirectToCheckoutOptions, AddPaymentMethodOptions } from "../../interfaces/Stripe";

import { actions } from "./";

import { startLoading, stopLoading } from "containers/App/store/actions";

// @TODO: move key retrieving to separate saga/action
function* loadStripeSaga({ payload: key }: { type: string; payload: string }) {
  try {
    const stripe: Stripe | null = yield call(loadStripe, key);
    yield put(actions.loadStripe.success(stripe));
  } catch (error: any) {
    yield put(actions.loadStripe.failure());
  }
}

function* redirectToCheckoutSaga({ payload: options }: { type: string; payload: RedirectToCheckoutOptions }) {
  try {
    yield put(startLoading());
    const stripe: Stripe | null = yield select(getStripe());
    if (stripe) {
      const { checkoutSessionId }: { checkoutSessionId: string } = yield call(api.createCheckoutSession, options);
      yield stripe.redirectToCheckout({ sessionId: checkoutSessionId });
    } else {
      yield put(actions.loadStripe.failure());
    }
  } catch (error: any) {
    yield put(actions.loadStripe.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* addPaymentMethod({ payload: options }: { type: string; payload: AddPaymentMethodOptions }) {
  try {
    yield put(startLoading());
    const stripe: Stripe | null = yield select(getStripe());
    if (stripe) {
      const { checkoutSessionId }: { checkoutSessionId: string } = yield call(api.addPaymentMethod, options);
      yield stripe.redirectToCheckout({ sessionId: checkoutSessionId });
    } else {
      yield put(actions.loadStripe.failure());
    }
  } catch (error: any) {
    yield put(actions.loadStripe.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* stripeSagas() {
  yield takeLatest(actions.loadStripe.request, loadStripeSaga);
  yield takeLatest(actions.redirectToCheckout.request, redirectToCheckoutSaga);
  yield takeLatest(actions.addPaymentMethod.request, addPaymentMethod);
}
export default stripeSagas;
