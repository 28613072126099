import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

import tokenHandler from "./tokenHandler";
import schemas from "../schemas";
import { SCHEMAS } from "../constants";
import memberCIDHandler from "./memberCIDHandler";
import store from "../../";
import { setIpBlocked } from "../../containers/App/store/actions";

axios.defaults.headers.post["Content-Type"] = "application/json";

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
  let token = tokenHandler.get();
  if (!token && store) {
    const {
      auth: { token: storeToken },
    } = store.getState();
    if (storeToken) {
      token = storeToken;
    }
  }
  if (config.headers) {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      const memberCID = memberCIDHandler.get();
      if (memberCID) {
        config.headers.cid = memberCID;
      }
    }
  }

  const deviceFingerprint = store?.getState()?.app?.deviceFingerprint;
  if (deviceFingerprint && config.headers) {
    config.headers["Device-Fingerprint"] = deviceFingerprint;
  }
  return config;
});

const findSchema = (schemaKey: string) => {
  let requestSchema = schemas[schemaKey];

  if (requestSchema) return requestSchema;

  Object.keys(schemas).forEach(key => {
    const regex = new RegExp(key);
    const result = regex.exec(schemaKey);
    if (result && result.length === 1) {
      requestSchema = schemas[key];
    }
  });

  return requestSchema;
};

const makeRequest = (instance: AxiosInstance) => (method: string, url: string, params: any) => {
  const requestSchema = findSchema(`${url}${SCHEMAS.REQUEST}${method}`);

  if (requestSchema) {
    // @ts-ignore
    const valid = requestSchema(...params);
    if (!valid) {
      return Promise.reject({
        error: "Sended transfer object isn't valid",
      });
    }
  }
  // @ts-ignore
  return instance[method](url, ...params);
};

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    const response = res.data || res;
    const { config } = res || {};
    const { url, method } = config || {};
    const responseSchema = findSchema(`${url}${SCHEMAS.RESPONSE}${method}`);
    if (response.error) {
      return Promise.reject(response.error);
    }

    if (responseSchema) {
      const valid = responseSchema(response);
      if (!valid) {
        return Promise.reject({
          error: "Recevied transfer object isn't valid",
        });
      }
    }

    return response;
  },
  (error: AxiosError) => {
    const { response } = error || {};
    const { data } = response || {};
    if (data) {
      if (data.meta?.ipIsBlocked) {
        store.dispatch(setIpBlocked(true));
      }
      return Promise.reject(data);
    }
    return Promise.reject(error);
  },
);
/**
 * Axios wrapper
 *
 * @param  {string} method Method of the request
 * @param  {string} url url of the request
 *
 * @return {object} wrapped axios function that receives params
 */
export default (method: string, url: string) =>
  (...params: any[]) =>
    makeRequest(axiosInstance)(method, url, params);

export const download = async (url: string, fileName: string, params?: any) => {
  const response: any = await axiosInstance({ url, method: "GET", responseType: "blob", params });

  const urlObj = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = urlObj;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
