import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";

import bold_icon_white from "assets/icons/bold_icon_white.svg";
import bold_icon_grey from "assets/icons/bold_icon_grey.svg";
import italic_icon_white from "assets/icons/italic_icon_white.svg";
import italic_icon_grey from "assets/icons/italic_icon_grey.svg";
import underline_icon_white from "assets/icons/underline_icon_white.svg";
import underline_icon_grey from "assets/icons/underline_icon_grey.svg";
import strike_through_icon_white from "assets/icons/strike_through_icon_white.svg";
import strike_through_icon_grey from "assets/icons/strike_through_icon_grey.svg";
import ordered_list_icon_white from "assets/icons/ordered_list_icon_white.svg";
import ordered_list_icon_grey from "assets/icons/ordered_list_icon_grey.svg";
import unordered_list_icon_white from "assets/icons/unordered_list_icon_white.svg";
import unordered_list_icon_grey from "assets/icons/unordered_list_icon_grey.svg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type Props = {
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
  isEvent?: boolean;
};

const TextEditor: React.FC<Props> = ({ editorState, setEditorState, isEvent }) => {
  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      stripPastedStyles={true}
      toolbar={{
        options: ["inline", "list"],
        inline: {
          options: ["bold", "italic", "underline", "strikethrough"],
          bold: {
            icon: isEvent ? bold_icon_white : bold_icon_grey,
          },
          italic: {
            icon: isEvent ? italic_icon_white : italic_icon_grey,
          },
          underline: {
            icon: isEvent ? underline_icon_white : underline_icon_grey,
          },
          strikethrough: {
            icon: isEvent ? strike_through_icon_white : strike_through_icon_grey,
          },
        },
        list: {
          options: ["unordered", "ordered"],
          ordered: {
            icon: isEvent ? ordered_list_icon_white : ordered_list_icon_grey,
          },
          unordered: {
            icon: isEvent ? unordered_list_icon_white : unordered_list_icon_grey,
          },
        },
      }}
    />
  );
};

export default TextEditor;
