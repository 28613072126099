import React, { useEffect, Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { useHistory } from "react-router";
import { SnackbarProvider } from "notistack";

import { mainTheme, GlobalCss, manifestTheme } from "../../theme";
import { getDeviceFingerprint } from "../../utils/functions";
import PrivateRoute from "./PrivateRoute";
import { AltarStore, Blocker, getSubdomain, tokenHandler } from "../../shared";
import { Loader } from "./components/Loader";
import { publicRoutes } from "./routes";
import routeAssessor from "../../utils/routeAssessor";
import { Dashboard } from "../Community";
import { NotistackActionButton } from "../Notifications/components/NotistackActionButton";
import { NotistackContainer } from "../Notifications/components/NotistackContainer";
import { NotFound } from "../../shared/components/NotFound";
import { GetStreamProvider } from "../../shared/hooks/GetStreamHook";
import { init as initFBPixel } from "../../utils/fbPixel";
import { NamesOfParentRoutes } from "../../constants";
import { isHubspotInited, isIpBlocked, selectDeviceFingerprint } from "./store/selectors";
import { setDeviceFingerprint } from "./store/actions";
import { selectLandingData } from "../LandingPage/store/selectors";
import blocker from "../../assets/images/blocker.webp";

import { hideOnClose, initHubspotChat } from "utils/hubspot";
import { getSubdomainList } from "containers/Community/store/actions";
import { login, getUserData } from "containers/Auth/store/actions";
import { init as initRewardful } from "utils/rewardful";
import { init as googleAdvInit } from "utils/googleAdv";
import { initHeapAnalytics } from "utils/heapAnalytics";
import { isMatchedPath } from "containers/Community/utils";
import { getCommunity, getCommunitySubscriptions, selectAllSubdomains } from "containers/Community/store/selectors";
import { objectToSearchParams, searchParamsToObject } from "shared/utils/searchParams";
import { actions as browserActions } from "shared/stores/browser";

import "./app.scss";

const { STREAM, BREAKOUTROOMS, WATCH_PARTY } = NamesOfParentRoutes;

interface AppInterface {
  isLoading: boolean;
  authentificated: boolean;
}

const App: React.FunctionComponent<AppInterface> = ({ isLoading, authentificated }) => {
  const currentCommunity = useSelector(getCommunity());
  const communitySubscriptions = useSelector(getCommunitySubscriptions());
  const hubspotInited = useSelector(isHubspotInited());
  const landingSettings = useSelector(selectLandingData());
  const ipIsBlocked = useSelector(isIpBlocked());
  const deviceFingerprint = useSelector(selectDeviceFingerprint());

  const history = useHistory();

  const { current_is_free, next_tier_product_id, token, vimeo_token_established, ...restParams } = searchParamsToObject(
    window.location.search,
  );

  const { subdomain, isApp } = getSubdomain();
  const subdomains = useSelector(selectAllSubdomains());

  const dispatch = useDispatch();

  useEffect(() => {
    if (vimeo_token_established) {
      window.close();
    }
  }, [vimeo_token_established]);

  useEffect(() => {
    dispatch(getSubdomainList.request());
  }, [dispatch]);

  useEffect(() => {
    if (subdomain && !isApp && subdomains.length > 0) {
      if (!subdomains.includes(subdomain)) {
        const url = window.location.href.replace(subdomain, "app");
        window.location.replace(url);
      } else if (landingSettings?.favicon_url) {
        const favicon = document.getElementById("favicon") as HTMLLinkElement;
        favicon.href = landingSettings.favicon_url;
      }
    }
  }, [subdomain, subdomains, isApp, landingSettings]);

  useEffect(() => {
    initFBPixel();
    googleAdvInit();
    initRewardful();
    initHubspotChat();
    initHeapAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      tokenHandler.set(token);
      dispatch(login.success());
      dispatch(getUserData.request());
      history.replace({ search: "" });
    }
  }, [token, history, dispatch]);

  useEffect(() => {
    if (authentificated) {
      localStorage.removeItem("subdomain_part");
    }
  }, [authentificated]);

  useEffect(() => {
    dispatch(browserActions.setBrowserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (currentCommunity && communitySubscriptions && current_is_free && next_tier_product_id) {
      history.replace({ search: objectToSearchParams(restParams) });
    }
  }, [currentCommunity, communitySubscriptions, restParams, history, current_is_free, next_tier_product_id, dispatch]);

  useEffect(() => {
    if (hubspotInited) {
      const isStream = isMatchedPath(history.location.pathname, [
        `${STREAM}/:eventCode`,
        `${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`,
        `/:communityCode${STREAM}/:eventCode`,
        `/:communityCode${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`,
        `${STREAM}/:eventCode${BREAKOUTROOMS}`,
        `${STREAM}/:eventCode${BREAKOUTROOMS}/:roomCode`,
        `/:communityCode${STREAM}/:eventCode${BREAKOUTROOMS}`,
        `/:communityCode${STREAM}/:eventCode${BREAKOUTROOMS}/:roomCode`,
      ]);
      hideOnClose(isStream);
    }
  }, [history.location.pathname, hubspotInited]);

  useEffect(() => {
    if (!deviceFingerprint) {
      const fingerprint = getDeviceFingerprint();
      dispatch(setDeviceFingerprint(fingerprint));
    }
  }, [deviceFingerprint, dispatch]);

  return (
    <div className="App">
      <ThemeProvider theme={{ ...mainTheme, custom: { ...manifestTheme } }}>
        {isLoading && <Loader />}
        <GlobalCss />
        {ipIsBlocked ? (
          <Blocker
            mainImgSrc={blocker}
            title="Sorry, you are blocked!"
            description="Please contact your administrator."
          />
        ) : (
          <GetStreamProvider>
            <SnackbarProvider
              classes={{
                variantSuccess: "defaultSnackStyle snackStyle-success",
                variantError: "defaultSnackStyle snackStyle-error",
                variantInfo: "defaultSnackStyle snackStyle-info",
              }}
              hideIconVariant
              maxSnack={8}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              preventDuplicate={true}
              action={key => <NotistackActionButton snackKey={key} />}
            >
              <NotistackContainer />

              <Suspense
                fallback={
                  <div className="initial-loader">
                    <Loader />
                  </div>
                }
              >
                <Switch>
                  {publicRoutes.map(route => routeAssessor(null, route))}
                  <PrivateRoute path="/communities" component={Dashboard} />
                  <PrivateRoute path="/:community" component={Dashboard} />
                  <Route component={NotFound} />
                </Switch>
              </Suspense>
            </SnackbarProvider>
          </GetStreamProvider>
        )}
      </ThemeProvider>
    </div>
  );
};

const mapDispatchToProps = () => ({});

const mapStateToProps = (state: AltarStore) => ({
  isLoading: state.app.loading > 0,
  authentificated: state.auth.authentificated,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
