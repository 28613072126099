import React from "react";

import { StyledAltarLogo } from "../StyledAltarLogo";
import { AuthAside } from "../AuthAside";
import { LINKS } from "../../../shared/constants/links";

const AuthAsideLogo: React.FC<{ withHomePage?: boolean }> = ({ withHomePage }) => (
  <AuthAside>
    <a href={(withHomePage && LINKS.website) || undefined} className="auth-aside-logo">
      <StyledAltarLogo width={258} height={72} fill="#403E3E" />
    </a>
  </AuthAside>
);

export default AuthAsideLogo;
