import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { IMember } from "containers/Member/interfaces";
import { getInitials } from "shared/utils";
import { getEventSettings } from "containers/Sermons/store/selectors";
import { getMember } from "containers/Auth/store/selectors";

import "./index.scss";

interface IBenchAttendee {
  attendee?: IMember;
  className?: string;
}

const BenchAttendee: React.FC<IBenchAttendee> = ({ attendee, className }) => {
  const { memberToFind } = useSelector(getEventSettings());
  const currentMember = useSelector(getMember());

  const [profileImageError, setProfileImageError] = useState(false);

  useEffect(() => {
    setImmediate(() => {
      setProfileImageError(false);
    });
  }, [attendee]);

  return (
    <div
      className={classnames("benchAttendee", className, {
        active: attendee,
        currentMember: currentMember && currentMember.id === attendee?.id,
        "benchAttendee-toFind": memberToFind === attendee?.id,
      })}
    >
      {attendee?.first_name && (
        <div className="benchAttendee-name-wrapper">
          <div className="benchAttendee-name">{`${attendee.first_name} ${attendee.last_name}`} </div>
        </div>
      )}
      <div className="benchAttendee-wrapper">
        {attendee ? (
          <>
            {attendee.image_url && !profileImageError ? (
              <img
                src={attendee.image_url}
                alt={`${attendee.first_name} ${attendee.last_name}`}
                onError={() => setProfileImageError(true)}
              />
            ) : (
              <div className="initials">
                <div className="fallback">{getInitials(attendee)}</div>
              </div>
            )}
          </>
        ) : null}
      </div>
      <div className="benchAttendee-half-circle" />
    </div>
  );
};

export default BenchAttendee;
