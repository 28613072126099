import { IUser } from "containers/Auth/interfaces/user";
import { IMember } from "containers/Member";

export const generateBackground = () => {
  return (
    "#" +
    Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")
  );
};

export const getInitials = (member: IMember | IUser) => {
  let initials = "";
  if (member.first_name) {
    initials += `${member?.first_name[0]}`;
  }
  if (member.last_name) {
    initials += `${member?.last_name[0]}`;
  }
  return initials;
};
