import React, { useState } from "react";
import "./styles.scss";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { FieldAttributes } from "formik";

import eye_icon from "assets/icons/eye_icon.svg";
import eye_icon_purple from "assets/icons/eye_icon_purple.svg";
import eye_off_icon from "assets/icons/eye_off_icon.svg";
import eye_off_icon_purple from "assets/icons/eye_off_icon_purple.svg";

const compileTextProps = () => ({
  classes: {
    root: "styledTextArea",
  },
  FormHelperTextProps: {
    classes: {
      root: "helper-text",
    },
  },
  InputLabelProps: {
    classes: {
      root: "text-label",
      error: "label-error",
      required: "text-label-required",
    },
    disableAnimation: true,
    shrink: true,
  },
});

const StyledTextField: React.FC<FieldAttributes<any>> = props => {
  const { name, errors, type, theme, helperText, showAdornment = true } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(prev => !prev);

  return (
    <TextField
      helperText={helperText ? helperText : errors ? errors[name] : null}
      {...props}
      {...compileTextProps()}
      type={showPassword ? "text" : type}
      InputProps={{
        classes: {
          root: `text-field`,
          multiline: "text-field text-area",
          input: "textarea",
          disabled: "disabled-text",
        },
        disableUnderline: true,
        endAdornment: type === "password" && showAdornment && (
          <InputAdornment position="end">
            <IconButton tabIndex={-1} aria-label="toggle password visibility" onClick={handleClickShowPassword}>
              {showPassword ? (
                <img className="password-icon" src={theme === "dark" ? eye_icon_purple : eye_icon} alt="eye icon" />
              ) : (
                <img
                  className="password-icon"
                  src={theme === "dark" ? eye_off_icon_purple : eye_off_icon}
                  alt="eye icon"
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default StyledTextField;
