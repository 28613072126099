import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { EventMemberList } from "../EventMemberList";
import { IMember } from "../../../../Member";
import { EventMemberListItem } from "../EventMemberListItem";
import { getWaitingToJoinMembers } from "../../../store/selectors";
import { EventMemberTypes } from "../../../constants";
import { selectRooms } from "../../../../BreakoutRooms/store/selectors";

import { EventSideDialog, StyledTextField } from "shared/components";
import { IEventMemberResponse, IStreamEventMember } from "containers/Sermons/interfaces";
import { generateBackground, memberCIDHandler } from "shared/utils";
import { getMember } from "containers/Auth/store/selectors";
import { searchRoomMembers } from "containers/Sermons/utils";
import { notEmptyArray } from "utils/notEmptyArray";
import { EventSideDialogHeaderTypes } from "shared/components/Event/EventSideDialog/EventSideDialogHeader";

import "./eventMembersDialog.scss";

interface IEventMembersDialog {
  open: boolean;
  members: IEventMemberResponse[];
  communityMembers: IMember[];
  onClose?: () => void;
  className?: string;
  canCreateChat?: boolean;
  startChat?: (member_id: number) => void;
  startChatAnonymous?: (member_id: number) => void;
  canShowJoinRequest: boolean;
  openRestrictionAlert: () => void;
}

const EventMembersDialog: React.FC<IEventMembersDialog> = props => {
  const {
    onClose,
    open,
    members,
    startChat,
    startChatAnonymous,
    communityMembers,
    canShowJoinRequest,
    openRestrictionAlert,
  } = props;

  const currentMember = useSelector(getMember());
  const waitingToJoinMembers = useSelector(getWaitingToJoinMembers());
  const breakoutRooms = useSelector(selectRooms());

  const [memberList, setMemberList] = useState<IStreamEventMember[]>([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const memberList = members
      .map(eventMember => {
        const memberToList: IStreamEventMember = { ...eventMember };
        const member = communityMembers.find(m => m.id === Number(eventMember.memberId));

        if (member) {
          memberToList.image_url = member.image_url;
          memberToList.first_name = member.first_name;
          memberToList.last_name = member.last_name;
        }
        return memberToList.guestName || memberToList.first_name || memberToList.last_name ? memberToList : null;
      })
      .filter(notEmptyArray);
    setMemberList(memberList);
  }, [members, communityMembers]);

  const memberCID = memberCIDHandler.get();
  const checkOwnMember = useCallback(
    (member: IStreamEventMember) => {
      if (currentMember && currentMember.id) {
        return member.memberId === currentMember.id;
      }
      return String(member.memberId) === memberCID;
    },
    [currentMember, memberCID],
  );

  const ownMember = useMemo(() => members.find(member => checkOwnMember(member)), [checkOwnMember, members]);
  const membersToSearch = useMemo(() => {
    return memberList?.filter(member => !checkOwnMember(member)) || [];
  }, [memberList, checkOwnMember]);

  const prepareWaitingMembersForList: Array<
    IStreamEventMember & {
      isWaiting: boolean;
      knockRequestedDate: number;
    }
  > = useMemo(() => {
    return canShowJoinRequest
      ? waitingToJoinMembers.map(({ member, knockRequestedDate }) => ({
          color: generateBackground(),
          first_name: member.first_name,
          guestName: "",
          image_url: member.image_url,
          last_name: member.last_name,
          member,
          memberId: member.id,
          type: EventMemberTypes.member,
          seat: null,
          isWaiting: true,
          knockRequestedDate,
        }))
      : [];
  }, [waitingToJoinMembers, canShowJoinRequest]);

  const memoMembers = useMemo(() => {
    const searchedMembers = searchRoomMembers(membersToSearch, searchValue, breakoutRooms);
    const membersToList = [...prepareWaitingMembersForList, ...searchedMembers];
    return membersToList.length ? (
      <EventMemberList
        title="Members"
        members={membersToList}
        startChat={startChat}
        startChatAnonymous={startChatAnonymous}
        openRestrictionAlert={openRestrictionAlert}
      />
    ) : null;
  }, [
    membersToSearch,
    searchValue,
    startChat,
    startChatAnonymous,
    prepareWaitingMembersForList,
    breakoutRooms,
    openRestrictionAlert,
  ]);

  return (
    <EventSideDialog title={"Members"} open={open} onClose={onClose} headerType={EventSideDialogHeaderTypes.members}>
      <div className="eventMembersDialog">
        <div className="eventMembersDialog-search">
          <StyledTextField
            fullWidth
            value={searchValue}
            placeholder="Search a Member"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          />
          {searchValue && <div className="clear editIcons editIcons-delete" onClick={() => setSearchValue("")} />}
        </div>
        {ownMember && (
          <div className="eventMembersDialog-list own-member">
            <EventMemberListItem
              member={ownMember}
              startChat={startChat}
              openRestrictionAlert={openRestrictionAlert}
              own
            />
          </div>
        )}
        {memoMembers}
      </div>
    </EventSideDialog>
  );
};

export default EventMembersDialog;
