import { END, eventChannel } from "redux-saga";
import { io } from "socket.io-client";
import { Socket } from "socket.io-client/build/socket";

import store from "../..";
import config from "../../config";

export function createSocket(namespace: string | number, actions: any) {
  return new Promise<void>(resolve => {
    const socket = io(`${config.wsApiUrl}/${namespace}`, {
      path: config.wsPath,
      transports: ["websocket", "polling"],
    });

    socket.on("connect_error", (e: Error) => {
      store.dispatch(actions.socketInitializedFailure(e));
    });

    socket.on("connect", () => {
      store.dispatch(actions.setSocketConnected(true));
      store.dispatch(actions.socketInitialized(socket));
      console.info("[BROWSER] socket.io: Socket was connected");
      resolve();
    });

    socket.on("disconnect", reason => {
      store.dispatch(actions.setSocketConnected(false));
      if (reason !== "io client disconnect") {
        console.info(`[BROWSER] socket.io: Socket was disconnected with reason: ${reason}. Trying to reconnect...`);
        if (reason === "io server disconnect") {
          socket.connect();
        }
      } else {
        console.info("[BROWSER] socket.io: Socket was disconnected");
      }
    });
  });
}

export function createSocketChannel(socket: Socket) {
  return eventChannel(emit => {
    const handler = (method: string, ...args: any) => {
      emit({
        method,
        args,
      });
    };

    socket.on("message", handler);

    socket.on("reconnect_failed", () => {
      emit(END);
    });

    return () => {
      socket.off("message", handler);
      socket.close();
    };
  });
}
