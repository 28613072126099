import config from "../config";

export const initHeapAnalytics = () => {
  // @ts-ignore
  if (config.heapAnalyticsId && !window.heap) {
    // @ts-ignore
    window.heap = [];
    // @ts-ignore
    const { heap } = window;

    heap.load = function (e: any, t: any = {}) {
      heap.appid = e;
      heap.config = t;
      const r = document.createElement("script");
      r.type = "text/javascript";
      r.async = true;
      r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
      const a = document.getElementsByTagName("script")[0];
      a.parentNode?.insertBefore(r, a);
      const n = function (e: any) {
        return function (...args: any[]) {
          heap.push([e].concat(Array.prototype.slice.call(args, 0)));
        };
      };
      const p = [
        "addEventProperties",
        "addUserProperties",
        "clearEventProperties",
        "identify",
        "resetIdentity",
        "removeEventProperty",
        "setEventProperties",
        "track",
        "unsetEventProperty",
      ];
      for (let o = 0; o < p.length; o++) {
        heap[p[o]] = n(p[o]);
      }
    };
    heap.load(config.heapAnalyticsId);
  }
};
