import React from "react";

import "./styles.scss";

export type WizardStep = {
  title: string;
  completed: boolean;
};
type Props = {
  steps: WizardStep[];
  activeStep: number;
  onChange: (step: number) => void;
};
const VerticalWizardSteps: React.FC<Props> = ({ steps, activeStep, onChange }) => {
  return (
    <div className="steps">
      {steps.map(({ title, completed }, i) => (
        <div
          className={`step ${completed ? "completed" : ""} ${activeStep === i ? "active" : ""}`}
          key={i}
          onClick={() => onChange(i)}
        >
          <span className="step-number">{!completed && i + 1}</span>
          <span className="step-title">{title}</span>
        </div>
      ))}
    </div>
  );
};

export default VerticalWizardSteps;
