import produce from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { LandingStateType, LandingSchedule } from "../interfaces";
import { dateWithoutTime } from "../../../utils/functions";
import * as actions from "./actions";

import { EventStatus, IEvent } from "containers/Sermons";

export const initialState: LandingStateType = {
  settings: null,
  eventsSchedule: [],
  archiveEvents: [],
  params: {
    limit: 25,
    page: 1,
    schedule_direction: "upcoming",
  },
  eventsCount: 0,
  pastEventsCount: 0,
  socketConnected: false,
};

interface EventFoundSchedule {
  indexOfEvent: number | null;
  day: LandingSchedule;
  event: IEvent;
}

type Action = ActionType<typeof actions>;

const landingReducer = createReducer<LandingStateType, Action>(initialState)
  .handleAction(actions.loadLandingSettings.success, (state, action) =>
    produce(state, nextState => {
      nextState.settings = action.payload;
    }),
  )
  .handleAction(actions.clearSettings, state =>
    produce(state, nextState => {
      nextState.settings = null;
    }),
  )
  .handleAction(actions.loadLandingEvents.success, (state, action) =>
    produce(state, nextState => {
      nextState.eventsSchedule =
        state.params.page !== 1 ? [...nextState.eventsSchedule, ...action.payload] : [...action.payload];
    }),
  )
  .handleAction(actions.updateParams, (state, action) =>
    produce(state, nextState => {
      nextState.params = action.payload;
    }),
  )
  .handleAction(actions.setEventsCount, (state, action) =>
    produce(state, nextState => {
      nextState.eventsCount = action.payload;
    }),
  )
  .handleAction(actions.setPastEventsCount, (state, action) =>
    produce(state, nextState => {
      nextState.pastEventsCount = action.payload;
    }),
  )
  .handleAction(actions.setSocketConnected, (state, action) =>
    produce(state, nextState => {
      nextState.socketConnected = action.payload;
    }),
  )
  .handleAction(actions.closeSocket, state =>
    produce(state, nextState => {
      nextState.socketConnected = false;
    }),
  )
  .handleAction(actions.connectSocket, state =>
    produce(state, nextState => {
      nextState.socketConnected = true;
    }),
  )
  .handleAction(actions.wsSetEventStatus, (state, action) =>
    produce(state, nextState => {
      const eventData = action.payload;
      const eventFoundSchedule = setEventStatusAndReturnEvent(nextState, eventData);
      if (eventData.status === EventStatus.ended && eventFoundSchedule) {
        replaceEventToArchived(nextState, eventFoundSchedule);
        updateArchivedEventsObject(nextState);
      }
      updateEventsScheduleObject(nextState);
    }),
  )
  .handleAction(actions.wsSetEventArchived, (state, action) =>
    produce(state, nextState => {
      const eventData = action.payload;
      removeEventFromAllArrays(nextState, eventData);
      updateEventsScheduleObject(nextState);
    }),
  )
  .handleAction(actions.setPastEvents, (state, action) =>
    produce(state, nextState => {
      nextState.archiveEvents =
        state.params.page !== 1 ? [...nextState.archiveEvents, ...action.payload] : [...action.payload];
    }),
  )
  .handleAction(actions.wsEventCreated, (state, action) =>
    produce(state, nextState => {
      const event = action.payload;
      const eventScheduleDay = getEventScheduleDay(event, nextState.eventsSchedule);
      if (eventScheduleDay) {
        addEventToScheduleDay(event, eventScheduleDay);
      } else {
        createScheduleDayAndInsertEvent(event, nextState.eventsSchedule);
      }
      updateEventsScheduleObject(nextState);
    }),
  );

function replaceEventToArchived(nextState: LandingStateType, eventFoundSchedule: EventFoundSchedule) {
  addEventToArchived(nextState, eventFoundSchedule.event);
  removeEvent(nextState.eventsSchedule, eventFoundSchedule);
}

function removeEventFromAllArrays(nextState: LandingStateType, event: IEvent) {
  let foundEvent = findEvent(event, nextState.eventsSchedule);
  if (foundEvent) {
    removeEvent(nextState.eventsSchedule, foundEvent);
  } else {
    foundEvent = findEvent(event, nextState.archiveEvents);
    foundEvent && removeEvent(nextState.archiveEvents, foundEvent);
  }
}

function removeEvent(schedule: LandingSchedule[], eventFoundSchedule: EventFoundSchedule) {
  if (eventFoundSchedule.indexOfEvent !== null) {
    eventFoundSchedule.day.events.splice(eventFoundSchedule.indexOfEvent, 1);
    if (!eventFoundSchedule.day.events.length) {
      const dayIndex = schedule.findIndex(day => day.date === eventFoundSchedule.day.date);
      if (dayIndex >= 0) {
        schedule.splice(dayIndex, 1);
      }
    }
  }
}

function updateEventsScheduleObject(nextState: LandingStateType) {
  nextState.eventsSchedule = [...nextState.eventsSchedule];
}

function updateArchivedEventsObject(nextState: LandingStateType) {
  nextState.archiveEvents = [...nextState.archiveEvents];
}

function setEventStatusAndReturnEvent(nextState: LandingStateType, event: IEvent) {
  const eventInSchedule = findEvent(event, nextState.eventsSchedule);
  if (!eventInSchedule) {
    return null;
  }
  eventInSchedule.event.status = event.status;
  eventInSchedule.event.was_started = event.was_started;
  return eventInSchedule;
}

function findEvent(eventData: IEvent, schedule: LandingSchedule[]): EventFoundSchedule | null {
  const eventDay = schedule.find(day => !!day.events.find(e => e.id === eventData.id));
  if (!eventDay) {
    return null;
  }
  let eventIndex: number | null = null;
  const isEqualEvent = (event: IEvent, index: number) => {
    if (event.id === eventData.id) {
      eventIndex = index;
      return true;
    }
    return false;
  };
  const event = eventDay.events.find(isEqualEvent);
  if (!event) {
    return null;
  }
  return {
    event,
    day: eventDay,
    indexOfEvent: eventIndex,
  };
}

function addEventToArchived(nextState: LandingStateType, event: IEvent | null) {
  if (event) {
    const reversedSort = true;
    const eventDay = getEventScheduleDay(event, nextState.archiveEvents);
    if (eventDay) {
      eventDay.events.unshift({ ...event });
    } else {
      createScheduleDayAndInsertEvent(event, nextState.archiveEvents, reversedSort);
    }
  }
}

function getEventScheduleDay(event: IEvent, schedule: LandingSchedule[]) {
  if (!schedule || !schedule.length) {
    return null;
  }
  return schedule.find(day => !!day.events.find(e => e.id === event.id));
}

function addEventToScheduleDay(event: IEvent, scheduleDay: LandingSchedule) {
  scheduleDay.events.unshift(event);
}

function createScheduleDayAndInsertEvent(event: IEvent, schedule: LandingSchedule[], reversedSort?: boolean) {
  const eventDate = dateWithoutTime(event.starting_at);
  const sortCorrection = reversedSort ? -1 : 1;
  schedule.push({
    date: eventDate,
    events: [event],
  });
  schedule.sort((a, b) => (a.date < b.date ? -1 * sortCorrection : 1 * sortCorrection));
}

export default landingReducer;
