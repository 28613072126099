import React from "react";

import { IconBase } from "../IconBase";

import { IStyledSVG } from "shared/interfaces/StyledSVG";

export const StyledArch: React.FC<IStyledSVG> = ({
  style = {},
  fill = "#7B9EB9",
  width = "120",
  height = "150",
  className = "",
  opacity = 0.1,
}) => (
  <IconBase
    fill="none"
    width={width}
    style={style}
    height={height}
    className={className}
    opacity={opacity}
    viewBox="0 0 120 150"
  >
    <path
      d="M120 0C88.5686 0 58.5767 12.2222 36.1257 34.0404C13.6747 55.8586 0.71435 85.5556 0 116.869V150H53.6783V116.869C54.1886 99.697 61.4341 83.4343 73.7822 71.4141C86.1302 59.4949 102.754 52.5093 120 52.5093V0Z"
      fill={fill}
    />
  </IconBase>
);

export default StyledArch;
