import "./index.scss";

import React, { Fragment, useEffect, useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarKey, withSnackbar, WithSnackbarProps } from "notistack";

import { removeNotification } from "../../store/actions";
import { getNotificationAnnouncement } from "../../store/selectors";
import { INotificationAnnouncement } from "../../interfaces";

import { Button } from "shared";

export type SnackbarProps = {
  enqueueSnackbar: (message: string, variant: string) => void;
  closeSnackbar: (key?: SnackbarKey) => void;
};

interface NotificationAnnouncementProps {
  onClick?: (data: INotificationAnnouncement) => void;
  millisecondsToHide?: number;
  hideOnClick?: boolean;
}

const DEFAULT_MILLISECONDS_TO_HIDE = 5000;

const notifications: { announcementId?: number; snackbarKey: SnackbarKey }[] = [];

const NotificationAnnouncement: React.FunctionComponent<
  NotificationAnnouncementProps & SnackbarProps & WithSnackbarProps
> = ({ enqueueSnackbar, onClick, millisecondsToHide = DEFAULT_MILLISECONDS_TO_HIDE, closeSnackbar, hideOnClick }) => {
  const dispatch = useDispatch();
  const announcement = useSelector(getNotificationAnnouncement());
  const [closeNotificationId, setCloseNotificationId] = useState<number | null | undefined>();

  useEffect(() => {
    if (closeNotificationId) {
      const notificationIdx = notifications.findIndex(
        notification => notification.announcementId === closeNotificationId,
      );
      if (notificationIdx >= 0) {
        closeSnackbar(notifications[notificationIdx].snackbarKey);
        notifications.splice(notificationIdx, 1);
        setCloseNotificationId(null);
      }
    }
  }, [closeNotificationId, closeSnackbar]);

  const onActionClick = useCallback(
    (e: React.MouseEvent) => {
      if (announcement?.button_link) {
        window.open(announcement.button_link);
      }
      e.stopPropagation();
    },
    [announcement],
  );

  const shouldRenderButton = useMemo(() => {
    return announcement?.button_text && announcement?.button_link;
  }, [announcement]);

  const renderButton = useMemo(() => {
    if (!shouldRenderButton) {
      return null;
    }
    return (
      <Button variant="orange" onClick={e => onActionClick(e)}>
        {announcement?.button_text}
      </Button>
    );
  }, [shouldRenderButton, onActionClick, announcement]);

  const onContainerClick = useCallback(() => {
    if (hideOnClick) {
      setCloseNotificationId(announcement?.id || undefined);
    }
    if (announcement) {
      onClick && onClick(announcement);
    }
  }, [hideOnClick, onClick, announcement]);

  const notificationContent = useMemo(() => {
    if (!announcement) {
      return null;
    }
    return (
      <div className="notification-announcement" onClick={onContainerClick}>
        <p className="notification-announcement-title">{announcement.title}</p>
        <p className="notification-announcement-message">{announcement.message}</p>
        {renderButton}
      </div>
    );
  }, [announcement, renderButton, onContainerClick]);

  useEffect(() => {
    if (announcement) {
      const snackbarKey = enqueueSnackbar(notificationContent, {
        autoHideDuration: millisecondsToHide,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        className: "notification-announcement-snackbar",
      });
      notifications.push({ snackbarKey, announcementId: announcement.id });
      dispatch(removeNotification());
    }
  }, [announcement, enqueueSnackbar, notificationContent, dispatch, millisecondsToHide]);

  return <Fragment />;
};

export default withSnackbar(NotificationAnnouncement);
