import React from "react";
import { Area, AreaChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";

import { IEngagementAnalyticsData } from "../../../interfaces";

interface IProps {
  data?: IEngagementAnalyticsData[];
}

const EngagementChart: React.FC<IProps> = ({ data }) => {
  return (
    <AreaChart width={1144} height={320} data={data} margin={{ top: 0, right: 24, bottom: 24 }}>
      <defs>
        <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
          <stop offset="10%" stopColor="#7B9EB9" stopOpacity={0.5} />
          <stop offset="90%" stopColor="#7B9EB9" stopOpacity={0.05} />
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="3 3" vertical={false} />
      <XAxis dataKey="date" />
      <YAxis />
      <Legend
        iconType="plainline"
        verticalAlign="top"
        align="right"
        wrapperStyle={{ position: "absolute", top: "-24px", textTransform: "capitalize" }}
      />
      <Tooltip wrapperStyle={{ textTransform: "capitalize" }} />
      <Area type="monotone" dataKey="total" strokeWidth={2} stroke="#7B9EB9" fill="url(#colorTotal)" />
      <Area type="monotone" dataKey="members" strokeWidth={2} stroke="#E1A077" fill="transparent" />
      <Area type="monotone" dataKey="anonymous" strokeWidth={2} stroke="#7E6677" fill="transparent" />
    </AreaChart>
  );
};

export default EngagementChart;
