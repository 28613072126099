// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { match } from "react-router-dom";
import moment from "moment";
import * as Yup from "yup";
import { capitalize } from "lodash";
import { Unit } from "react-compound-timer/build/types";

import { NamesOfParentRoutes } from "../../../constants";
import {
  convertIframeProp,
  emojiPaths,
  emojis,
  EventTypeNames,
  sequenceNumbers,
  streamUrlValidationRexExp,
} from "../constants";
import { download } from "../../../shared/utils/request";
import {
  AnnouncementStatus,
  AnnouncementType,
  EAuditoriumRoomsType,
  EEventReCurringEnds,
  EEventRepeat,
  EEventScheduleDay,
  EEventStreamType,
  EFileDownloadState,
  EFrequency,
  EMemberRestriction,
  EventStatus,
  EventType,
  IAnnouncement,
  IAnnouncementDialogValues,
  IAnnouncementInteraction,
  IAnnouncementTemplate,
  ICreateEventSchedule,
  IEvent,
  IEventForm,
  IEventSchedule,
  IStreamEventMember,
  MeetingMode,
  MeetingModeType,
  Notifications,
  SearchRoom,
  SermonDetailsPageMode,
  SermonRestriction,
  SermonRoles,
} from "..";

import { IProps, TIME_PICKER_FORMAT } from "shared";
import { IMember } from "containers/Member/interfaces";
import config from "config";
import { IListItem } from "shared/components/ListItemWithControls";
import { getImageBase64 } from "shared/utils/dataModifiers";
import { ERoles } from "containers/Auth/interfaces";
import { checkRoles } from "utils/ACL";
import empty_person from "assets/icons/empty_person.svg";
import chat_timeout from "assets/icons/chat_timeout.svg";
import chat_ban from "assets/icons/chat_ban.svg";

const { EVENT } = NamesOfParentRoutes;

export const hoursItems = () => new Array(24).fill(0).map((_, idx) => ({ value: idx, text: String(idx) }));
export const minutesItems = (step?: number) => {
  const minutesItems = new Array(60)
    .fill(0)
    .map((_, idx) => ({ value: idx, text: idx < 10 ? "0" + String(idx) : String(idx) }));
  return step ? minutesItems.filter(item => item.value % step === 0) : minutesItems;
};

export const getHoursValue = (value: number) => Math.floor(value / 3600);
export const getMinutesValue = (value: number) => Math.floor((value - getHoursValue(value) * 3600) / 60);

export const getMeetingRole = (sermon: IEvent | null, member: IMember | null) => {
  if (sermon && member) {
    const memberId = member.id;
    const producerId = sermon && sermon.producer && sermon.producer.id;
    const hostId = sermon && sermon.host && sermon.host.id;
    const presentersIds = sermon.presenters.map(p => Number(p.memberId));
    const membersIds = sermon.attendees.map(m => (m?.id ? m.id : null));

    if (memberId === producerId) {
      return SermonRoles.producer;
    }
    if (memberId === hostId) {
      return SermonRoles.host;
    }
    if (presentersIds.some(p => p === memberId)) {
      return SermonRoles.presenter;
    }
    if (membersIds.some(m => m === memberId)) {
      return SermonRoles.member;
    }
    return null;
  }
  return null;
};

export const getSermonPageMode = (match: match<IProps>) => {
  if (match.params.eventCode === undefined) return SermonDetailsPageMode.create;
  return match.path.includes(SermonDetailsPageMode.edit) ? SermonDetailsPageMode.edit : SermonDetailsPageMode.view;
};

export const isViewMode = (mode: SermonDetailsPageMode) => mode === SermonDetailsPageMode.view;

export const isEditMode = (mode: SermonDetailsPageMode) => mode === SermonDetailsPageMode.edit;

export const toggleValueInArray = (array: string[], value: string) => {
  const index = array.indexOf(value);
  const result = [...array];
  if (index === -1) {
    result.push(value);
  } else {
    result.splice(index, 1);
  }
  return result;
};

export const checkMeetingRole = (sermon: IEvent, member: IMember, checkRoles: SermonRoles | SermonRoles[]): boolean => {
  const roles = Array.isArray(checkRoles) ? checkRoles : [checkRoles];
  return roles.some(role => {
    switch (role) {
      case SermonRoles.presenter:
        return sermon.type === EventType.liveService && sermon.presenters.some(p => Number(p.memberId) === member.id);
      case SermonRoles.producer:
        return sermon.type === EventType.liveService && sermon.producer_id === member.id;
      case SermonRoles.member:
        return sermon.attendees.some(a => a.id === member.id);
      case SermonRoles.host:
        return sermon.host_id === member.id;
      case SermonRoles.greeter:
        return sermon.greeters.some(({ id }) => id === member.id);
      case SermonRoles.greeterHost:
        return sermon.greeters.some(({ id, is_admin }) => id === member.id && is_admin);
      default:
        return false;
    }
  });
};

export const memberHasEventRoleOrAdmin = (event: IEvent, member: IMember) => {
  const hasMeetingRole = checkMeetingRole(event, member, [SermonRoles.host, SermonRoles.greeterHost]);
  if (hasMeetingRole) {
    return true;
  }
  return checkRoles([ERoles.admin, ERoles.admin]);
};

export const getEventTimeRange = (sermon: IEvent, format?: string): string => {
  const start = moment(sermon.starting_at);
  const end = moment(sermon.ending_at);

  let finalFormat = format;
  if (!format) {
    const localeData = moment.localeData();
    finalFormat = localeData.longDateFormat("LT");
  }

  return `${start.format(finalFormat)} - ${end.format(finalFormat)}`;
};

export const getFormattedDate = (date: Date) => {
  const momentDate = moment(date);
  return `${momentDate.format("dddd")}, ${momentDate.format("MMMM")} ${momentDate.format("D")}, ${momentDate.format(
    "YYYY",
  )}`;
};

export const formatMonthDay = (date: string): string => {
  const localeData = moment.localeData();
  const dayMonthYearFormat = localeData.longDateFormat("ll");

  const yearRegexp = /[,]? YYYY/;
  const yearMatch = dayMonthYearFormat.match(yearRegexp);
  let dayMonthFormat = dayMonthYearFormat;
  if (yearMatch) {
    const match = yearMatch as RegExpMatchArray;
    const fondPosition = match.index as number;
    dayMonthFormat =
      dayMonthYearFormat.slice(0, fondPosition) + dayMonthYearFormat.slice(fondPosition + match[0].length);
  }

  return moment(date).format("dddd, " + dayMonthFormat);
};

export const getDatePickerFormat = () => {
  const format = moment
    .localeData()
    .longDateFormat("L")
    .split("")
    .map(char => {
      if (char === "D") {
        return "d";
      } else if (char === "Y") {
        return "y";
      }
      return char;
    })
    .join("");

  return format;
};

export const getAmpm = () => {
  const format = moment.localeData().longDateFormat("LT");
  return format[format.length - 1] === "A";
};

export const generateSlideUid = () => Number(Date.now() + String(Math.random()).slice(2, 5));

export const generateInitSlideName = (number: number) => `Slide ${number}`;

export const formatPin = (pin: string) => {
  return pin.replace(/\d{3}/, "$&-");
};

export const getSlidesStyle = (slideScale: number) => {
  return {
    transform: `scale(${slideScale})`,
    width: `calc(100% / ${slideScale})`,
  };
};

export const getSvgPath = () => {
  const path = emojiPaths[Math.floor(Math.random() * emojiPaths.length)];
  return path;
};

export const getTimeFormat = () => moment.localeData().longDateFormat("LT");

export const isOnlyVisibleElement = (parentEl: HTMLElement, selector: string) => {
  const allElementsBySelector = parentEl.querySelectorAll(selector);
  const allElements: HTMLElement[] = Array.from(parentEl.querySelectorAll("*"));
  if (allElementsBySelector.length > 1 || !allElementsBySelector.length) return false;
  const inspectedEl = allElementsBySelector[0];
  const parentOfInspectedEl = inspectedEl.parentElement;
  for (const el of allElements) {
    if (inspectedEl === el || parentOfInspectedEl === el) continue;
    if (el.dataset.mceBogus) continue;
    if (el.offsetHeight) return false;
  }
  return true;
};

export const getEventFinalTime = (date: Date | string, interval: number): string =>
  interval ? moment(date).add(interval, "s").format(TIME_PICKER_FORMAT) : "--:--";

export const getZoomIntegrationLink = (code: string) => `${config.applicationUrl}${EVENT}/${code}/integration`;

export const truncateString = (text: string, maxLength = 35) =>
  text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

export const getEmojiByName = (name: string) => emojis.find(emoji => name === emoji.name);

export const changeEventViewModeByKeyPress = (e: KeyboardEvent, cb: (mode: MeetingModeType) => void) => {
  let layoutMode: MeetingModeType | null = null;
  if (e.altKey) {
    if (["Digit1", "Numpad1", "Digit2", "Numpad2", "Digit3", "Numpad3", "Digit4", "Numpad4"].includes(e.code)) {
      e.preventDefault();
      switch (e.code) {
        case "Digit1":
        case "Numpad1":
          layoutMode = MeetingMode[0] as MeetingModeType;
          break;
        case "Digit2":
        case "Numpad2":
          layoutMode = MeetingMode[1] as MeetingModeType;
          break;
        case "Digit3":
        case "Numpad3":
          layoutMode = MeetingMode[2] as MeetingModeType;
          break;
        case "Digit4":
        case "Numpad4":
          layoutMode = MeetingMode[3] as MeetingModeType;
          break;
        default:
          break;
      }
    }
  }
  if (layoutMode) {
    cb(layoutMode);
  }
};

export const formatTimeDuration = (duration: number): string => {
  const durationRounded = Math.round(duration);
  const hours = Math.floor(durationRounded / 3600);
  const minutes = Math.floor((durationRounded - hours * 3600) / 60);
  const seconds = durationRounded - hours * 3600 - minutes * 60;

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

export const correctEventEndDate = (startingAt: string, endingAt: string, cb: (endDate: string) => void): void => {
  const startDate = new Date(startingAt);
  const endDate = new Date(endingAt);

  const hoursDiff = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
  let correctDay = 0;

  if (hoursDiff < 0) {
    correctDay = 1;
  } else if (hoursDiff >= 24) {
    correctDay = -1;
  }

  if (correctDay) {
    endDate.setDate(endDate.getDate() + correctDay);
    cb(endDate.toString());
  }
};

export const formListItemFromMembers = <T extends IMember>(
  members: T[],
  mutator?: (member: T, item: IListItem) => void,
): IListItem[] =>
  members.map(member => {
    const item: IListItem = {
      id: member.id,
      name: `${member.first_name} ${member.last_name}`.trim(),
      image: member.image_url || empty_person,
    };
    if (mutator) {
      mutator(member, item);
    }
    return item;
  });

export const getRemainingTime = (event: IEvent | null) => moment(event?.starting_at).diff(moment());
export const getStreamUrlValidationBuilder = (isDraft: boolean): Yup.StringSchema =>
  Yup.string().when(["type", "stream_type"], (type: EventType, streamType: EEventStreamType) => {
    const regex = streamUrlValidationRexExp[streamType] || /.*/;
    let result: Yup.StringSchema<string | null>;
    if (type === EventType.stream && streamType) {
      result = Yup.string();
      if (streamType !== EEventStreamType.CUSTOM) {
        result = result.concat(Yup.string().url("Please enter correct url"));

        if (streamType === EEventStreamType.RESI) {
          result = result.concat(
            Yup.string().test(
              "deny-dash",
              "Please enter a link ending in .m3u8. Links ending in .mpd are not supported",
              val => !/.*\.mpd\s*$/.test(val),
            ),
          );
        }
      }
      if (!isDraft && streamType !== EEventStreamType.ALTAR_LIVE && streamType !== EEventStreamType.VIMEO_LIVE) {
        result = result.concat(
          Yup.string().required(
            streamType === EEventStreamType.ALTAR
              ? "Please upload a video file or change your stream source."
              : "This field is required",
          ),
        );
      }
    } else {
      result = Yup.string().nullable();
    }
    const m3u8Types = [
      EEventStreamType.m3u8,
      EEventStreamType.dacast,
      EEventStreamType.churchstreaming,
      EEventStreamType.STREAMING_CHURCH_TV,
    ];
    return result.concat(
      Yup.string().matches(
        regex,
        streamType === EEventStreamType.CUSTOM
          ? "At the moment we support embed codes that look like <iframe src=https://example.com/... >"
          : `This doesn't look like ${
              m3u8Types.includes(streamType) ? EEventStreamType.m3u8 : `a typical ${streamType}`
            } link`,
      ),
    );
  });

export type PlayerSize = {
  height: number;
  width: number;
};

export const calcPlayerSize = (
  containerWidth: number,
  containerHeight: number,
  iframe?: HTMLIFrameElement | null,
): PlayerSize | null => {
  let playerHeight: number;
  let playerWidth: number;

  if (!iframe) return null;

  let aspectRatio = iframe.offsetWidth / iframe.offsetHeight;
  aspectRatio = isFinite(aspectRatio) ? aspectRatio : 1.76;
  if (containerWidth / aspectRatio > containerHeight) {
    playerHeight = containerHeight;
    playerWidth = containerHeight * aspectRatio;
  } else {
    playerWidth = containerWidth;
    playerHeight = containerWidth / aspectRatio;
  }

  return { height: playerHeight, width: playerWidth };
};

export const parseIframeProps = (iframe: string) => {
  const props: Record<string, any> = {};
  const match = iframe.match(/^\s*<iframe\s*([^>]*)>/);
  if (Array.isArray(match) && match.length > 1) {
    let [, params] = match;
    params = params.replace(/\w*="[^"]*"/g, row => {
      const [key, ...vals] = row.split("=");
      let val = vals.join("=").replaceAll('"', "");
      if (key === "src") {
        // for twitch videos
        val = val.replace("www.example.com", config.applicationUrl.replace(/http(s)?:\/\//, ""));
      }
      props[convertIframeProp(key)] = val;

      return "";
    });
    params
      .trim()
      .split(/\s+/)
      .forEach(key => {
        props[convertIframeProp(key)] = true;
      });
  }
  delete props.style;

  return props;
};

export const getMeetingType = (type: EventType): Notifications => {
  switch (type) {
    case EventType.liveService:
      return Notifications.liveService;
    case EventType.meeting:
      return Notifications.meeting;
    case EventType.stream:
      return Notifications.stream;
    default:
      return Notifications.event;
  }
};

export const getEventRepeatOptions = (startingAt: string, weekDayNthOfMonth: number) => {
  return [
    {
      text: "Does not repeat",
      value: EEventRepeat.never,
    },
    {
      text: "Daily",
      value: EEventRepeat.daily,
    },
    {
      text: `Weekly on ${moment(startingAt).format("dddd")}`,
      value: EEventRepeat.weekly,
    },
    {
      text: `Monthly on the ${sequenceNumbers[weekDayNthOfMonth]} ${moment(startingAt).format("dddd")}`,
      value: EEventRepeat.monthly,
    },
    {
      text: `Monthly on the last ${moment(startingAt).format("dddd")}`,
      value: EEventRepeat.lastOfMonthly,
    },
    {
      text: "Custom...",
      value: EEventRepeat.custom,
    },
  ];
};

export const prepareEventSchedule = (
  schedule?: IEventSchedule | ICreateEventSchedule | null,
  repeat?: EEventRepeat,
  startDate?: string,
): ICreateEventSchedule => {
  let day_of_week = schedule?.day_of_week;
  if (startDate || !day_of_week) {
    day_of_week = [Object.values(EEventScheduleDay)[(startDate ? new Date(startDate) : new Date()).getDay()]];
  }
  return {
    id: schedule?.id,
    day_of_week,
    frequency: schedule?.frequency || EFrequency.weekly,
    interval: schedule?.interval || 1,
    occurrences: schedule?.occurrences || 4,
    until: schedule?.until || schedule?.scheduled_until || moment().add(1, "M").toString(),
    bysetpos: [EEventRepeat.lastOfMonthly, EEventRepeat.monthly].includes(repeat || EEventRepeat.never)
      ? schedule?.bysetpos
      : null,
    ...(schedule?.scheduled_until ? { scheduled_until: schedule.scheduled_until } : {}),
  };
};

export const searchRoomMembers = (members: IStreamEventMember[], searchValue: string, rooms: SearchRoom[]) => {
  return members.filter(member => {
    const fullName = member.first_name ? `${member.first_name} ${member.last_name}` : member.guestName;
    member.withSeat = rooms.some(({ members }) =>
      members?.some(roomMember => String(roomMember.id) === String(member.memberId)),
    );
    return searchValue.length ? fullName.toLowerCase().includes(searchValue.toLocaleLowerCase()) : true;
  });
};

export const prepareEventDataToSave = (values: IEventForm, communityId: number, currentEvent: IEvent | null) => {
  const {
    id,
    name,
    subject,
    starting_at,
    ending_at,
    setup_time,
    post_event_time,
    restriction,
    attendees,
    producer,
    host,
    presenters,
    type,
    integrations,
    speaker_name,
    greeters,
    greeter_message,
    stream_type,
    video_type,
    stream_url,
    image_url,
    pre_images,
    pre_images_interval,
    seats_per_table,
    repeat,
    schedule,
    recurring_ends_on,
    is_featured,
    relays,
    stream_settings,
    class_room_settings,
    classrooms: { classRoomItems: classrooms, enabled, mainClassroom },
    auditorium_rooms: { auditoriumRoomsEnabled, auditoriumRoomsCount },
    lobby_rooms: { lobbyRoomsEnabled, lobbyRoomsCount },
    show_on_landing,
    vimeo_token,
    is_locked,
    has_platform_tour,
    tour_title,
    tour_description,
    pre_event_type,
    pre_event_video_url,
    is_pre_event_video_loop,
    is_pre_event_video_autoplay,
    host_notes,
    last_editor_host_notes,
    restricted_generalChat,
    start_with_audio_muted,
    start_with_video_muted,
    auditorium_rooms_type,
    files,
    polls,
    announcements,
    leave_event_url,
    start_event_option,
  } = values;
  let newPreImages: string[] | undefined;
  const newPreImagesBase64: string[] = [];
  if (pre_images !== currentEvent?.pre_images) {
    newPreImages = [];
    pre_images.forEach(image => {
      if (currentEvent?.pre_images.includes(image)) {
        // @ts-ignore
        newPreImages.push(image);
      } else {
        newPreImagesBase64.push(image);
      }
    });
  }

  const image_base64 = getImageBase64(image_url, currentEvent?.image_url !== image_url);
  const attendeesList = restriction === SermonRestriction.invited ? attendees.map(a => (a.id ? a.id : 0)) : [];

  let scheduleToSave: ICreateEventSchedule | null = schedule;
  delete scheduleToSave.scheduled_until;
  if (repeat === EEventRepeat.never) {
    scheduleToSave = null;
  } else {
    if (recurring_ends_on === EEventReCurringEnds.on || recurring_ends_on === EEventReCurringEnds.never) {
      scheduleToSave.occurrences = null;
    }
    if (recurring_ends_on === EEventReCurringEnds.after || recurring_ends_on === EEventReCurringEnds.never) {
      scheduleToSave.until = null;
    }
  }

  return {
    name,
    subject,
    setup_time,
    post_event_time,
    restriction,
    attendees: attendeesList,
    producer: (id || values.type === EventType.liveService) && producer && producer.id ? producer.id : undefined,
    greeters:
      id || values.type !== EventType.liveService
        ? greeters.map(({ id, is_admin, title }, index) => ({ id, is_admin, title, position: index }))
        : [],
    greeter_message:
      [EventType.liveService, EventType.stream].includes(values.type) && greeters.length > 0 && greeter_message?.trim()
        ? greeter_message
        : "",
    host: host && host.id ? host.id : undefined,
    presenters:
      id || values.type === EventType.liveService
        ? presenters.map(({ pin, type, memberId }) => ({ pin, type, memberId }))
        : [],
    startTime: new Date(starting_at).toISOString(),
    endTime: new Date(ending_at).toISOString(),
    communityId,
    type,
    integrations,
    speaker_name: speaker_name?.trim() ? speaker_name : "",
    stream_type: values.type === EventType.stream ? stream_type : null,
    video_type: values.type === EventType.stream ? video_type : null,
    stream_url: values.type === EventType.stream ? stream_url : null,
    image_base64,
    pre_images: newPreImages,
    pre_images_base64: newPreImagesBase64,
    pre_images_interval,
    seats_per_table,
    repeat,
    schedule: scheduleToSave,
    is_featured,
    restricted_generalChat,
    relays:
      values.stream_type === EEventStreamType.ALTAR_LIVE
        ? relays?.filter(relay => relay.enabled || (relay.stream_url && relay.stream_key))
        : [],
    stream_settings:
      values.stream_type === EEventStreamType.ALTAR_LIVE || values.stream_type === EEventStreamType.VIMEO_LIVE
        ? stream_settings
        : null,
    class_room_settings:
      id && type === EventType.meeting ? { room_size: class_room_settings?.room_size } : class_room_settings,
    classrooms:
      enabled && mainClassroom
        ? [...classrooms, { ...mainClassroom, ...class_room_settings }]
        : mainClassroom
        ? [{ ...mainClassroom, ...class_room_settings }]
        : classrooms,
    auditorium_rooms_count:
      auditoriumRoomsEnabled && auditorium_rooms_type !== EAuditoriumRoomsType.none && type !== EventType.meeting
        ? auditoriumRoomsCount
        : 0,
    lobby_rooms_count: lobbyRoomsEnabled ? lobbyRoomsCount : 0,
    show_on_landing,
    vimeo_token,
    is_locked,
    has_platform_tour,
    tour_description: has_platform_tour ? tour_description : null,
    tour_title: has_platform_tour ? tour_title : null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    pre_event_type,
    pre_event_video_url,
    is_pre_event_video_loop,
    is_pre_event_video_autoplay,
    host_notes,
    last_editor_host_notes,
    start_with_audio_muted,
    start_with_video_muted,
    auditorium_rooms_type,
    files,
    polls: polls.map(({ temporary_key, ...rest }) => rest),
    announcements: announcements.map(({ temporary_key, ...rest }) => convertAnnouncementFormDataToRequest(rest)),
    leave_event_url: leave_event_url || null,
    start_event_option,
  };
};

export const eventIsCurrent = (event: IEvent) => {
  const now = Date.now();
  const ended = new Date(event.ending_at).getTime();
  const start = new Date(event.starting_at).getTime();
  return (
    (event.was_started || (start <= now && ended >= now)) &&
    ![EventStatus.ended, EventStatus.draft].includes(event.status)
  );
};

export const getEventTypeName = (type?: EventType, shouldCapitalize?: boolean) => {
  let result: string = type ? EventTypeNames[type] : "event";
  if (shouldCapitalize) {
    result = capitalize(result);
  }
  return result;
};

export const downloadRecordHandler = async (
  url: string,
  fileName: string,
  setProgress?: (progressStatus: EFileDownloadState) => void,
) => {
  setProgress && setProgress(EFileDownloadState.processing);
  await download(url, fileName);
  setProgress && setProgress(EFileDownloadState.complete);
};

export const formatValue = (value: number) => `${value < 10 ? `0${value}` : value}`;

export const formatValueSeconds = (seconds: number) => formatValue(seconds);

export const getTimeParts = (time: number) => {
  const units: Unit[] = ["ms", "s", "m", "h", "d"];

  const dividers = [1000, 60, 60, 24, 1];
  const dividersAcc = [1, 1000, 60000, 3600000, 86400000];
  const startValue = {
    ms: 0,
    s: 0,
    m: 0,
    h: 0,
    d: 0,
  };
  const output = units.reduce((obj: Record<Unit, number>, unit, index) => {
    obj[unit] = Math.ceil(time / dividersAcc[index]) % dividers[index];

    return obj;
  }, startValue);
  return output;
};

export const updateDropdownNames = () =>
  [3, 5, 10, 15, 20, 30].map(s => ({
    text: `Slide Transition Time ${s} sec`,
    value: s,
  }));

export const getS3Url = (url: string) => {
  return `${config.s3.altarFilesCloudFrontDomain}/${url}`;
};

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const defineRestrictionIconSrc = (restrictionType?: EMemberRestriction) => {
  switch (restrictionType) {
    case EMemberRestriction.chat_timeout:
      return chat_timeout;
    case EMemberRestriction.chat_ban:
      return chat_ban;
    default:
      return undefined;
  }
};

export const prepareAnnouncementForm = (
  announcement?: IAnnouncement | IAnnouncementTemplate | IAnnouncementInteraction | null,
) => {
  return {
    title: announcement?.title || "",
    description: announcement?.description || "",
    button_text: announcement?.button_text,
    button_link: announcement?.button_link,
    use_button: announcement?.use_button ?? Boolean(announcement?.button_text),
    type: announcement?.type || AnnouncementType.panel,
    status: announcement?.status || AnnouncementStatus.draft,
  };
};

export const convertAnnouncementFormDataToRequest = (values: IAnnouncementDialogValues | IAnnouncementInteraction) => {
  const { use_button, ...rest } = values;
  const copyOfRest = { ...rest };
  if (use_button === false) {
    copyOfRest.button_link = "";
    copyOfRest.button_text = "";
  }
  return copyOfRest;
};
