import React from "react";
import { useDispatch } from "react-redux";

import { updateEventSettings } from "containers/Sermons/store/actions";
import { AlertDialog } from "shared/components/AlertDialog";
import { EventDashboardTabs } from "containers/Sermons";
import { Button } from "shared/components";

import "./styles.scss";

interface IEventAuthAlert {
  open: boolean;
  title: string;
  message?: string;
  onClose: () => void;
  onJoin?: () => void;
}

const EventAuthAlert: React.FC<IEventAuthAlert> = ({ open, message, title, onClose, onJoin }) => {
  const dispatch = useDispatch();

  const joinHandler = () => {
    dispatch(updateEventSettings({ activeDashboardTab: EventDashboardTabs.auth }));
    onClose();
  };

  const customButtons = [
    <Button width={100} variant="purple-text" onClick={onClose} key={0} type="button">
      Cancel
    </Button>,
    <Button
      width={112}
      variant="blue"
      onClick={onJoin || joinHandler}
      key={1}
      type="button"
      className="eventAuthAlert-join-btn"
    >
      Join Us
    </Button>,
  ];

  return (
    <AlertDialog
      open={open}
      title={title}
      message={message}
      customButtons={customButtons}
      mode="confirm"
      variant="brown"
    />
  );
};
export default EventAuthAlert;
