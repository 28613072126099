import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useLocation, useHistory } from "react-router";

import { communityStripeAccountLink, createCommunity, updateCommunity } from "../../../store/actions";
import { getAllCommunities } from "../../../store/selectors";
import { ICommunity, ICommunityItem, IMainContact } from "../../../interfaces";
import { actions } from "../../../store";
import { prepareCommunityForm } from "../../../utils";
import { CommunityReadMode } from "../CommunityReadMode";
import { communityValidationSchema } from "./formValidators";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../../../constants";

import { notificationActions } from "containers/Notifications/store/actions";
import { CommunityPermissions } from "containers/Auth/interfaces";
import { copyTextHandler, usePermissions } from "shared";
import { getImageBase64 } from "shared/utils/dataModifiers";
import { AlertDialog, SideDialog } from "shared/components";

import "./communityDialog.scss";

export interface ICommunityDialogValues {
  name: string;
  main_contact: IMainContact | null;
  main_contact_id?: number;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  image_url?: string;
  visibility: boolean;
  invitation_link: string;
  timezone: string;
  website?: string;
  ga_id: string;
  is_verified?: boolean;
}

interface ICommunityDialog {
  open: boolean;
  onClose: () => void;
  community?: ICommunity | null;
  onCommunityCreate?: () => void;
}

const CommunityDialog: React.FC<ICommunityDialog> = props => {
  const { open, onClose, community, onCommunityCreate } = props;
  const { t } = useTranslation();

  const [initValues, setInitValues] = useState<ICommunityDialogValues>(prepareCommunityForm());
  const [isCancel, setIsCancel] = useState(false);
  const [communitiesNames, setCommunitiesNames] = useState<string[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const allCommunities = useSelector(getAllCommunities());

  const validationSchema = useMemo(
    () =>
      communityValidationSchema.shape({
        name: Yup.string()
          .max(70, "Name field is max 70 characters")
          .required("Name is required")
          .test("name-is-taken", "Community name is already taken", (value: string) => {
            const name = value && value.trim();
            if (community) {
              return name !== initValues.name.trim() ? !communitiesNames.includes(name) : true;
            }
            return value ? !communitiesNames.includes(name) : true;
          }),
      }),
    [communitiesNames, community, initValues],
  );

  useEffect(() => {
    if (allCommunities.length) {
      const names = allCommunities.map((community: ICommunityItem) => community.name.trim());
      names.length && setCommunitiesNames(names);
    }
  }, [allCommunities]);

  useEffect(() => {
    return setInitValues(prepareCommunityForm(community));
  }, [open, community]);

  useEffect(() => {
    if (community) {
      dispatch(actions.getCommunityUsers.request(community.id));
    }
  }, [community, dispatch]);

  const closeDialog = useCallback(() => {
    dispatch(communityStripeAccountLink.cancel());
    onClose();
  }, [dispatch, onClose]);

  const onCloseEdit = (values: ICommunityDialogValues) => {
    if (!_.isEqual(values, initValues)) {
      setIsCancel(true);
    } else {
      setIsEditMode(false);
      closeDialog();
    }
  };

  const onCloseConfirmed = () => {
    setIsCancel(false);
    setIsEditMode(false);
    closeDialog();
  };

  const copyInvitationHandler = useCallback(
    (value: string) => {
      if (value) {
        copyTextHandler(value);
        dispatch(notificationActions.success("Invitation link copied to the clipboard"));
      }
    },
    [dispatch],
  );

  const handleSubmit = (data: ICommunityDialogValues) => {
    const { main_contact, invitation_link, image_url, ...rest } = data;

    const main_contact_id = main_contact ? main_contact.id : undefined;
    const isImageChanged = !!community && community.image_url !== image_url;
    const image_base64 = getImageBase64(image_url, isImageChanged);
    const body = {
      ...rest,
      image_base64,
      main_contact_id,
      zoom_license_email: undefined,
    };

    if (community) {
      dispatch(
        updateCommunity.request({
          community: body,
          communityCode: community.code,
          communityId: community.id,
        }),
      );
    } else {
      dispatch(createCommunity.request(body));
      onCommunityCreate && onCommunityCreate();
    }
    closeDialog();
  };

  const onChangeMode = useCallback((): void => setIsEditMode(true), [setIsEditMode]);

  const editPermissions = useMemo(() => [CommunityPermissions.edit], []);
  const canEdit = usePermissions(editPermissions, community?.id);

  useEffect(() => {
    if (community && canEdit && community.stripeAccountLink === undefined) {
      dispatch(communityStripeAccountLink.request({ communityCode: community.code, returnPath: pathname }));
    }
  }, [canEdit, community, dispatch, pathname]);

  const history = useHistory();
  const editPageUrl = useMemo(() => {
    return `${community?.code || ""}${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.GENERAL_SETTINGS}`;
  }, [community]);

  useEffect(() => {
    if (isEditMode && community) {
      closeDialog();
      return history.replace(`/${editPageUrl}`);
    }
  }, [isEditMode, editPageUrl, community, history, closeDialog]);

  return (
    <React.Fragment>
      <AlertDialog
        open={isCancel}
        title={t("Leaving Page")}
        message={t("You have unsaved changes that will be lost if you continue")}
        onConfirm={onCloseConfirmed}
        onCancel={() => setIsCancel(false)}
        mode="confirm"
        confirmText={t("Leave")}
      />
      <Formik
        validationSchema={validationSchema}
        initialValues={initValues}
        onSubmit={values => handleSubmit(values)}
        enableReinitialize
        validateOnChange
      >
        {({ values, handleSubmit }) => {
          return (
            <SideDialog
              onClose={() => onCloseEdit(values)}
              open={open}
              title={community ? `${isEditMode ? "Edit" : "View"} Community` : "New Community"}
              onSave={isEditMode ? handleSubmit : undefined}
              editText={community && canEdit && !isEditMode ? "Edit" : undefined}
              onEdit={onChangeMode}
              className="communityDialog"
              saveText="Save Changes"
              cancelText={isEditMode ? "Cancel" : "Close"}
            >
              <CommunityReadMode
                copyInvitationHandler={copyInvitationHandler}
                canEdit={canEdit}
                stripeLink={community?.stripeAccountLink || undefined}
              />
            </SideDialog>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default CommunityDialog;
