import * as React from "react";

import { IPoll, IPollTemplate, IPollToSubmit, PollStatus } from "../../../../interfaces";

import { ThreeDotsPopup } from "shared";

import "./index.scss";

interface IProps {
  templates: IPollTemplate[];
  onEdit: (poll: IPoll) => void;
  onPublish: (data: IPollToSubmit) => void;
  meeting_id: number;
}

const prepareTemplateToUse = (template: IPollTemplate, meeting_id: number): IPoll => {
  return {
    title: template.title,
    poll_question: template.poll_question,
    multiple_answers: template.multiple_answers,
    show_results: template.show_results,
    options: template.options.map(({ option_text }) => ({ option_text, vote_results: [] })),
    status: PollStatus.published,
    meeting_id,
    position: 0,
  };
};

const PollTemplatesList: React.FC<IProps> = ({ templates, onEdit, onPublish, meeting_id }) => {
  return (
    <div className="eventPollTemplates">
      {templates.map(template => (
        <div key={template.id} className="eventPollTemplates-card">
          <div className="eventPollTemplates-card-title">{template.title}</div>
          <div className="eventPollTemplates-card-question">{template.poll_question}</div>
          <div className="eventPollTemplates-card-options">
            {template.options.map((option, index) => (
              <div className="eventPollTemplates-card-options-item" key={index}>
                <div className="eventPollTemplates-card-options-item-action">
                  {template.multiple_answers ? (
                    <div className="eventPollTemplates-card-options-item-action-box" />
                  ) : (
                    <div className="eventPollTemplates-card-options-item-action-radio" />
                  )}
                </div>
                <div className="eventPollTemplates-card-options-item-text">{option.option_text}</div>
              </div>
            ))}
          </div>
          <div className="eventPollTemplates-card-divider" />
          <div className="eventPollTemplates-card-footer">
            {template.show_results ? (
              <div className="eventPollTemplates-card-footer-results">Live results</div>
            ) : (
              <div />
            )}
            <div className="eventPollTemplates-card-footer-controls">
              <div
                className="eventPollTemplates-card-footer-controls-publish"
                onClick={() => onPublish({ poll: prepareTemplateToUse(template, meeting_id) })}
              >
                Publish
              </div>
              <ThreeDotsPopup>
                <div
                  className="eventPollTemplates-card-footer-controls-edit"
                  onClick={() => onEdit(prepareTemplateToUse(template, meeting_id))}
                >
                  Edit
                </div>
              </ThreeDotsPopup>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PollTemplatesList;
