import React, { useMemo } from "react";
import { FormControlLabel, RadioGroup } from "@material-ui/core";

import { getMeetingType } from "../../../utils";
import { EEventChangeScope, EventType } from "../../../interfaces";

import { AlertDialog, StyledRadio } from "shared";

import "./styles.scss";

type Props = {
  variant: "edit" | "delete" | "duplicate";
  opened: boolean;
  eventType: EventType;
  scope?: EEventChangeScope;
  allowedScopes?: EEventChangeScope[];
  onConfirm: () => void;
  onCancel: () => void;
  onScopeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ChangeScopePopup: React.FC<Props> = ({
  variant,
  opened,
  eventType,
  scope,
  allowedScopes = [EEventChangeScope.current, EEventChangeScope.following, EEventChangeScope.all],
  onConfirm,
  onCancel,
  onScopeChange,
}) => {
  const data = useMemo(() => {
    const eventText = getMeetingType(eventType);
    const data = {
      title: "",
      message: "",
      confirmText: "",
      confirmClassName: "",
    };
    switch (variant) {
      case "edit":
        data.title = "Save Changes";
        data.confirmText = "Save";
        break;
      case "delete":
        data.title = `Delete ${scope ? "Recurring " : ""}${eventText}`;
        data.confirmText = "Delete";
        data.confirmClassName = "defaultButtons-remove";
        if (!scope) {
          data.message = `Are you sure that you want to delete this ${eventText}?`;
        }
        break;
      case "duplicate":
        data.title = `Duplicate ${scope ? "Recurring " : ""}${eventText}`;
        data.confirmText = "Duplicate";
        data.confirmClassName = "defaultButtons-remove";
        data.message = "Do you want to duplicate this event only?";
        if (!scope) {
          data.message = `Are you sure that you want to duplicate this ${eventText}?`;
        }
        break;
    }
    return data;
  }, [eventType, scope, variant]);
  return (
    <AlertDialog
      open={opened}
      title={data.title}
      message={data.message}
      onConfirm={onConfirm}
      onCancel={onCancel}
      mode="confirm"
      confirmText={data.confirmText}
      confirmClassName={data.confirmClassName}
    >
      {scope && (
        <RadioGroup name="scope" value={scope} onChange={onScopeChange} className="event-scope-options">
          {allowedScopes.includes(EEventChangeScope.current) && (
            <FormControlLabel value={EEventChangeScope.current} control={<StyledRadio />} label="This Event" />
          )}
          {allowedScopes.includes(EEventChangeScope.following) && (
            <FormControlLabel
              value={EEventChangeScope.following}
              control={<StyledRadio />}
              label="This and following events"
            />
          )}
          {allowedScopes.includes(EEventChangeScope.all) && (
            <FormControlLabel value={EEventChangeScope.all} control={<StyledRadio />} label="All related events" />
          )}
        </RadioGroup>
      )}
    </AlertDialog>
  );
};

export default ChangeScopePopup;
