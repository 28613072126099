import { createSelector } from "reselect";

import { AltarStore } from "../../../shared/types";

const selectApp = (state: AltarStore) => state.app;

export const isLoading = () => createSelector(selectApp, state => state.loading > 0);
export const isHubspotOpened = () => createSelector(selectApp, state => state.hubspotIsOpened);
export const isHubspotInited = () => createSelector(selectApp, state => state.hubspotInited);
export const getDashboardState = () => createSelector(selectApp, state => state.disableDashboard);
export const isIpBlocked = () => createSelector(selectApp, state => state.ipIsBlocked);
export const selectDeviceFingerprint = () => createSelector(selectApp, state => state.deviceFingerprint);
