import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { getMember } from "../../../../../Auth/store/selectors";

import { IPoll, IPollOption } from "containers/Sermons/interfaces";
import voted_icon from "assets/icons/voted_icon.svg";

import "./pollResults.scss";

interface IEventPollResults {
  poll: IPoll;
}

const PollResults: React.FC<IEventPollResults> = props => {
  const { poll } = props;

  const currentMember = useSelector(getMember());

  const votedParticipantsIds = useMemo(() => {
    return poll.options.reduce((acc: number[], current) => {
      current.vote_results.forEach(vote => {
        if (!acc.includes(vote.member_id)) {
          acc.push(vote.member_id);
        }
      });
      return acc;
    }, []);
  }, [poll]);

  const voteCount = (option: IPollOption) =>
    option.vote_results.length ? ((option.vote_results.length / votedParticipantsIds.length) * 100).toFixed(0) : 0;

  const votedOption = (option: IPollOption) =>
    option.vote_results.some(result => result?.member_id === currentMember?.id);

  return (
    <div className="pollResults">
      {poll?.options?.map((option, index) => {
        return (
          <div key={index} className="pollResults-item">
            <div className="pollResults-item-info">
              <div className="pollResults-item-info-text">{option.option_text}</div>
              <div className="pollResults-item-info-data">
                {votedOption(option) ? <img src={voted_icon} alt="voted" /> : null}
                {poll.show_results ? (
                  <div>{`${voteCount(option)}%, ${option.vote_results?.length} ${
                    option.vote_results?.length > 1 ? "votes" : "vote"
                  }`}</div>
                ) : (
                  votedOption(option) && "you voted"
                )}
              </div>
            </div>
            <div className="pollResults-item-stripe">
              <div className="totalStripe">
                {poll.show_results && <div className="totalStripe-fill" style={{ width: `${voteCount(option)}%` }} />}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PollResults;
