import React from "react";

import { IRoute } from "../containers/App/routes";
import { Page } from "../shared/components/Page";

const routeAssessor = (parentRoutePath: string | null, route: any) => {
  const { children, component, path, exact, pageTitle } = route;
  if (!children || (children && !children.length)) {
    return (
      <Page
        key={path}
        path={parentRoutePath ? `${parentRoutePath}${path}` : path}
        exact={exact}
        title={pageTitle}
        component={component}
      />
    );
  } else {
    return children.map((childRoute: IRoute) => routeAssessor(path, childRoute));
  }
};

export default routeAssessor;
