export enum SermonActionTypes {
  FETCH_SERMONS_LIST = "@@EVENT/FETCH_SERMONS_LIST",
  FETCH_SERMONS_LIST_SUCCESS = "@@EVENT/FETCH_SERMONS_LIST_SUCCESS",
  FETCH_SERMONS_LIST_FAILURE = "@@EVENT/FETCH_SERMONS_LIST_FAILURE",

  FETCH_SERMON_BY_ID_REQUEST = "@@EVENT/FETCH_SERMON_BY_ID_REQUEST",
  FETCH_SERMON_BY_ID_SUCCESS = "@@EVENT/FETCH_SERMON_BY_ID_SUCCESS",
  FETCH_SERMON_BY_ID_FAILURE = "@@EVENT/FETCH_SERMON_BY_ID_FAILURE",

  CREATE_SERMON_REQUEST = "@@EVENT/CREATE_SERMON_REQUEST",
  CREATE_SERMON_SUCCESS = "@@EVENT/CREATE_SERMON_SUCCESS",
  CREATE_SERMON_FAILURE = "@@EVENT/CREATE_SERMON_FAILURE",

  CREATE_MULTIPLE_SERMONS_REQUEST = "@@EVENT/CREATE_MULTIPLE_SERMONS_REQUEST",

  UPDATE_SERMON_REQUEST = "@@EVENT/UPDATE_SERMON_REQUEST",
  UPDATE_SERMON_SUCCESS = "@@EVENT/UPDATE_SERMON_SUCCESS",
  UPDATE_SERMON_FAILURE = "@@EVENT/UPDATE_SERMON_FAILURE",

  FETCH_EVENT_FILE = "@@EVENT/FETCH_EVENT_FILE",
  FETCH_EVENT_FILE_SUCCESS = "@@EVENT/FETCH_EVENT_FILE_SUCCESS",
  FETCH_EVENT_FILE_FAILURE = "@@EVENT/FETCH_EVENT_FILE_FAILURE",

  START_EVENT_REQUEST = "@@EVENT/START_EVENT_REQUEST",
  START_EVENT_SUCCESS = "@@EVENT/START_EVENT_SUCCESS",
  START_EVENT_FAILURE = "@@EVENT/START_EVENT_FAILURE",

  DUPLICATE_EVENT_REQUEST = "@@EVENT/DUPLICATE_EVENT_REQUEST",
  DUPLICATE_EVENT_SUCCESS = "@@EVENT/DUPLICATE_EVENT_SUCCESS",
  DUPLICATE_EVENT_FAILURE = "@@EVENT/DUPLICATE_EVENT_FAILURE",

  REMOVE_EVENT_REQUEST = "@@EVENT/REMOVE_EVENT_REQUEST",
  REMOVE_EVENT_SUCCESS = "@@EVENT/REMOVE_EVENT_SUCCESS",
  REMOVE_EVENT_FAILURE = "@@EVENT/REMOVE_EVENT_FAILURE",

  CLEAR_CURRENT_SERMON = "@@EVENT/CLEAR_CURRENT_SERMON",
  START_SERMON = "@@EVENT/START_SERMON",
  CLOSE_SOCKET = "@@EVENT/CLOSE_SOCKET",
  SEND_SERMON_EVENT = "@@EVENT/SEND_SERMON_EVENT",
  CLEAR_EVENT_ERROR = "@@EVENT/CLEAR_EVENT_ERROR",

  WS_EVENT_MEMBERS_REQUEST = "@@EVENT/WS_EVENT_MEMBERS_REQUEST",
  WS_EVENT_MEMBERS_SUCCESS = "@@EVENT/WS_EVENT_MEMBERS_SUCCESS",
  WS_EVENT_MEMBERS_FAILURE = "@@EVENT/WS_EVENT_MEMBERS_FAILURE",

  WS_MEMBERS_MAP = "@@EVENT/WS_MEMBERS_MAP",
  WS_STATE_MUTE = "@@EVENT/WS_STATE_MUTE",
  WS_STATE_SPOTLIGHT = "@@EVENT/WS_STATE_SPOTLIGHT",
  WS_STATE_UNMUTED = "@@EVENT/WS_STATE_UNMUTED",
  WS_EVENT_UPDATED = "@@EVENT/WS_EVENT_UPDATED",
  WS_SLIDE_CHANGED = "@@EVENT/WS_SLIDE_CHANGED",
  WS_REACTION_RECEIVED = "@@EVENT/WS_REACTION_RECEIVED",
  WS_SEND_REACTION = "@@EVENT/WS_SEND_REACTION",
  WS_UPDATE_MEMBER = "@@EVENT/WS_UPDATE_MEMBER",
  WS_CONNECT_TO_EVENT = "@@EVENT/WS_CONNECT_TO_EVENT",
  WS_REMOVE_MEMBER_FROM_EVENT = "@@EVENT/WS_REMOVE_MEMBER_FROM_EVENT",
  WS_LEAVE_EVENT = "@@EVENT/WS_LEAVE_EVENT",
  WS_BLOCKED_EVENT_MEMBERS = "@@EVENT/WS_BLOCKED_EVENT_MEMBERS",
  WS_CHANGE_EVENT_STATUS_OPTIONS = "@@EVENT/WS_CHANGE_EVENT_STATUS_OPTIONS",

  FETCH_SERMON_SIGNATURE_REQUEST = "@@EVENT/FETCH_SERMON_SIGNATURE_REQUEST",
  FETCH_SERMON_SIGNATURE_SUCCESS = "@@EVENT/FETCH_SERMON_SIGNATURE_SUCCESS",
  FETCH_SERMON_SIGNATURE_FAILURE = "@@EVENT/FETCH_SERMON_SIGNATURE_FAILURE",

  CHECK_SLOT_REQUEST = "@@EVENT/CHECK_SLOT_REQUEST",
  CHECK_SLOT_SUCCESS = "@@EVENT/CHECK_SLOT_SUCCESS",
  CHECK_SLOT_FAILURE = "@@EVENT/CHECK_SLOT_FAILURE",

  EVENT_INTEGRATIONS_REQUEST = "@@EVENT/EVENT_INTEGRATIONS_REQUEST",
  EVENT_INTEGRATIONS_SUCCESS = "@@EVENT/EVENT_INTEGRATIONS_SUCCESS",
  EVENT_INTEGRATIONS_FAILURE = "@@EVENT/EVENT_INTEGRATIONS_FAILURE",

  EVENT_SHORT_LINK_REQUEST = "@@EVENT/EVENT_SHORT_LINK_REQUEST",
  EVENT_SHORT_LINK_SUCCESS = "@@EVENT/EVENT_SHORT_LINK_SUCCESS",
  EVENT_SHORT_LINK_FAILURE = "@@EVENT/EVENT_SHORT_LINK_FAILURE",
  EVENT_SHORT_LINK_CLEAR = "@@EVENT/EVENT_SHORT_LINK_CLEAR",

  UPDATE_EVENT_SETTINGS = "@@EVENT/UPDATE_EVENT_SETTINGS",
  CLEAR_EVENT_SETTINGS = "@@EVENT/CLEAR_EVENT_SETTINGS",

  CONNECT_SOCKET = "@@EVENT/CONNECT_SOCKET",

  SET_GOING_LIVE_TIME = "@@EVENT/SET_GOING_LIVE_TIME",

  SET_ALLOW_ENDED = "@@EVENT/SET_ALLOW_ENDED",

  SET_CHANGING_STATUS = "@@EVENT/SET_CHANGING_STATUS",

  SET_LEAVING_EVENT = "@@EVENT/SET_LEAVING_EVENT",

  SET_SOCKET_CONNECTED = "@@EVENT/SET_SOCKET_CONNECTED",

  SOCKET_INITIALIZED = "@@EVENT/SOCKET_INITIALIZED",
  SOCKET_INITIALIZED_FAILURE = "@@EVENT/SOCKET_INITIALIZED_FAILURE",

  GET_RECURRING_EVENT_REQUEST = "@@EVENT/GET_RECURRING_EVENT_REQUEST",
  GET_RECURRING_EVENT_SUCCESS = "@@EVENT/GET_RECURRING_EVENT_SUCCESS",
  GET_RECURRING_EVENT_FAILURE = "@@EVENT/GET_RECURRING_EVENT_FAILURE",
  GET_RECURRING_EVENT_CLEAR = "@@EVENT/GET_RECURRING_EVENT_CLEAR",

  PARTICIPANTS_LIMIT_EXCEEDED = "@@EVENT/PARTICIPANTS_LIMIT_EXCEEDED",

  FETCH_ANNOUNCEMENTS_LIST = "@@EVENT/FETCH_ANNOUNCEMENTS_LIST",
  FETCH_ANNOUNCEMENTS_LIST_SUCCESS = "@@EVENT/FETCH_ANNOUNCEMENTS_LIST_SUCCESS",
  FETCH_ANNOUNCEMENTS_LIST_FAILURE = "@@EVENT/FETCH_ANNOUNCEMENTS_LIST_FAILURE",

  CREATE_ANNOUNCEMENT = "@@EVENT/CREATE_ANNOUNCEMENT",
  CREATE_ANNOUNCEMENT_SUCCESS = "@@EVENT/CREATE_ANNOUNCEMENT_SUCCESS",
  CREATE_ANNOUNCEMENT_FAILURE = "@@EVENT/CREATE_ANNOUNCEMENT_FAILURE",

  UPDATE_ANNOUNCEMENT = "@@EVENT/UPDATE_ANNOUNCEMENT",
  UPDATE_ANNOUNCEMENT_SUCCESS = "@@EVENT/UPDATE_ANNOUNCEMENT_SUCCESS",
  UPDATE_ANNOUNCEMENT_FAILURE = "@@EVENT/UPDATE_ANNOUNCEMENT_FAILURE",

  DELETE_ANNOUNCEMENT = "@@EVENT/DELETE_ANNOUNCEMENT",
  DELETE_ANNOUNCEMENT_SUCCESS = "@@EVENT/DELETE_ANNOUNCEMENT_SUCCESS",
  DELETE_ANNOUNCEMENT_FAILURE = "@@EVENT/DELETE_ANNOUNCEMENT_FAILURE",

  WS_ANNOUNCEMENT_PUBLISHED = "@@EVENT/WS_ANNOUNCEMENT_PUBLISHED",
  WS_ANNOUNCEMENT_DRAFT = "@@EVENT/WS_ANNOUNCEMENT_DRAFT",
  WS_ANNOUNCEMENT_DELETED = "@@EVENT/WS_ANNOUNCEMENT_DELETED",

  READ_ANNOUNCEMENTS_REQUEST = "@@EVENT/READ_ANNOUNCEMENTS_REQUEST",
  READ_ANNOUNCEMENTS_SUCCESS = "@@EVENT/READ_ANNOUNCEMENTS_SUCCESS",
  READ_ANNOUNCEMENTS_FAILURE = "@@EVENT/READ_ANNOUNCEMENTS_FAILURE",

  RESET_NEW_ANNOUNCEMENTS = "@@EVENT/RESET_NEW_ANNOUNCEMENTS",
  SET_SELECTED_ANNOUNCEMENT = "@@EVENT/SET_SELECTED_ANNOUNCEMENT",

  UPDATE_POLL_REQUEST = "@@EVENT/UPDATE_POLL_REQUEST",
  UPDATE_POLL_SUCCESS = "@@EVENT/UPDATE_POLL_SUCCESS",
  UPDATE_POLL_FAILURE = "@@EVENT/UPDATE_POLL_FAILURE",

  FETCH_POLLS_REQUEST = "@@EVENT/FETCH_POLLS_REQUEST",
  FETCH_POLLS_SUCCESS = "@@EVENT/FETCH_POLLS_SUCCESS",
  FETCH_POLLS_FAILURE = "@@EVENT/FETCH_POLLS_FAILURE",

  CREATE_POLL_REQUEST = "@@EVENT/CREATE_POLLS_REQUEST",
  CREATE_POLL_SUCCESS = "@@EVENT/CREATE_POLLS_SUCCESS",
  CREATE_POLL_FAILURE = "@@EVENT/CREATE_POLLS_FAILURE",

  DELETE_POLL_REQUEST = "@@EVENT/DELETE_POLL_REQUEST",
  DELETE_POLL_SUCCESS = "@@EVENT/DELETE_POLL_SUCCESS",
  DELETE_POLL_FAILURE = "@@EVENT/DELETE_POLL_FAILURE",

  POLL_VOTE_REQUEST = "@@EVENT/POLL_VOTE_REQUEST",
  POLL_VOTE_SUCCESS = "@@EVENT/POLL_VOTE_SUCCESS",
  POLL_VOTE_FAILURE = "@@EVENT/POLL_VOTE_FAILURE",

  READ_POLLS_REQUEST = "@@EVENT/READ_POLLS_REQUEST",
  READ_POLLS_SUCCESS = "@@EVENT/READ_POLLS_SUCCESS",
  READ_POLLS_FAILURE = "@@EVENT/READ_POLLS_FAILURE",

  FETCH_POLL_TEMPLATES_REQUEST = "@@EVENT/FETCH_POLL_TEMPLATES_REQUEST",
  FETCH_POLL_TEMPLATES_SUCCESS = "@@EVENT/FETCH_POLL_TEMPLATES_SUCCESS",
  FETCH_POLL_TEMPLATES_FAILURE = "@@EVENT/FETCH_POLL_TEMPLATES_FAILURE",

  UPDATE_POLL_TEMPLATE_REQUEST = "@@EVENT/UPDATE_POLL_TEMPLATE_REQUEST",
  UPDATE_POLL_TEMPLATE_SUCCESS = "@@EVENT/UPDATE_POLL_TEMPLATE_SUCCESS",
  UPDATE_POLL_TEMPLATE_FAILURE = "@@EVENT/UPDATE_POLL_TEMPLATE_FAILURE",

  CREATE_POLL_TEMPLATE_REQUEST = "@@EVENT/CREATE_POLL_TEMPLATE_REQUEST",
  CREATE_POLL_TEMPLATE_SUCCESS = "@@EVENT/CREATE_POLL_TEMPLATE_SUCCESS",
  CREATE_POLL_TEMPLATE_FAILURE = "@@EVENT/CREATE_POLL_TEMPLATE_FAILURE",

  DELETE_POLL_TEMPLATE_REQUEST = "@@EVENT/DELETE_POLL_TEMPLATE_REQUEST",
  DELETE_POLL_TEMPLATE_SUCCESS = "@@EVENT/DELETE_POLL_TEMPLATE_SUCCESS",
  DELETE_POLL_TEMPLATE_FAILURE = "@@EVENT/DELETE_POLL_TEMPLATE_FAILURE",

  KNOCK_JOIN_MEETING_REQUEST = "@@EVENT/KNOCK_JOIN_MEETING_REQUEST",
  KNOCK_JOIN_MEETING_SUCCESS = "@@EVENT/KNOCK_JOIN_MEETING_SUCCESS",
  KNOCK_JOIN_MEETING_FAILURE = "@@EVENT/KNOCK_JOIN_MEETING_FAILURE",
  KNOCK_JOIN_MEETING_CANCEL = "@@EVENT/KNOCK_JOIN_MEETING_CANCEL",

  JOIN_MEETING_KNOCK_RESPONSE = "@@EVENT/JOIN_MEETING_KNOCK_RESPONSE",

  KNOCK_REJECTED = "@@EVENT/KNOCK_REJECTED",

  RESET_POLL_NOTIFICATION_COUNT = "@@EVENT/RESET_POLL_NOTIFICATION_COUNT",

  SET_EVENT_HOST_OR_ADMIN = "@@EVENT/SET_EVENT_HOST_OR_ADMIN",

  CHECK_VIMEO_CONNECTION = "@@EVENT/CHECK_VIMEO_CONNECTION",
  CHECK_VIMEO_CONNECTION_SUCCESS = "@@EVENT/CHECK_VIMEO_CONNECTION_SUCCESS",
  CHECK_VIMEO_CONNECTION_FAILURE = "@@EVENT/CHECK_VIMEO_CONNECTION_FAILURE",

  DELETE_FILE_REQUEST = "@@EVENT/DELETE_FILE_REQUEST",
  DELETE_FILE_SUCCESS = "@@EVENT/DELETE_FILE_SUCCESS",
  DELETE_FILE_FAILURE = "@@EVENT/DELETE_FILE_FAILURE",

  FETCH_ANNOUNCEMENT_TEMPLATES_REQUEST = "@@EVENT/FETCH_ANNOUNCEMENT_TEMPLATES_REQUEST",
  FETCH_ANNOUNCEMENT_TEMPLATES_SUCCESS = "@@EVENT/FETCH_ANNOUNCEMENT_TEMPLATES_SUCCESS",
  FETCH_ANNOUNCEMENT_TEMPLATES_FAILURE = "@@EVENT/FETCH_ANNOUNCEMENT_TEMPLATES_FAILURE",

  CREATE_ANNOUNCEMENT_TEMPLATE_REQUEST = "@@EVENT/CREATE_ANNOUNCEMENT_TEMPLATE_REQUEST",
  CREATE_ANNOUNCEMENT_TEMPLATE_SUCCESS = "@@EVENT/CREATE_ANNOUNCEMENT_TEMPLATE_SUCCESS",
  CREATE_ANNOUNCEMENT_TEMPLATE_FAILURE = "@@EVENT/CREATE_ANNOUNCEMENT_TEMPLATE_FAILURE",

  UPDATE_ANNOUNCEMENT_TEMPLATE_REQUEST = "@@EVENT/UPDATE_ANNOUNCEMENT_TEMPLATE_REQUEST",
  UPDATE_ANNOUNCEMENT_TEMPLATE_SUCCESS = "@@EVENT/UPDATE_ANNOUNCEMENT_TEMPLATE_SUCCESS",
  UPDATE_ANNOUNCEMENT_TEMPLATE_FAILURE = "@@EVENT/UPDATE_ANNOUNCEMENT_TEMPLATE_FAILURE",

  DELETE_ANNOUNCEMENT_TEMPLATE_REQUEST = "@@EVENT/DELETE_ANNOUNCEMENT_TEMPLATE_REQUEST",
  DELETE_ANNOUNCEMENT_TEMPLATE_SUCCESS = "@@EVENT/DELETE_ANNOUNCEMENT_TEMPLATE_SUCCESS",
  DELETE_ANNOUNCEMENT_TEMPLATE_FAILURE = "@@EVENT/DELETE_ANNOUNCEMENT_TEMPLATE_FAILURE",
}
