import React, { useRef, useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { SermonsCardList } from "..";

import { Loader } from "containers/App/components";
import { IEventsSchedulerList } from "containers/Sermons/interfaces";
import { isLoading } from "containers/App/store/selectors";
import { Button, EmptyText, Scheduler } from "shared/components";
import no_matches_bg from "assets/icons/no_matches_bg.png";
import calendar from "assets/icons/calendar.png";
import arrow from "assets/icons/arrow_upward.svg";

import "./sermonsSchedulerList.scss";

const scroll = (ref: React.RefObject<HTMLElement>, callback: () => void, behavior = "auto" as ScrollBehavior) => {
  if (ref.current) {
    const topPos = ref.current.offsetTop;
    callback();
    return window.scrollTo({
      top: topPos + 5,
      behavior,
    });
  }
  const scrollHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight,
  );
  callback();
  window.scrollTo(0, scrollHeight);
};

const observerOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0.5,
};

const SermonsSchedulerList: React.FC<IEventsSchedulerList> = props => {
  const { schedulers, search } = props;

  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(true);
  const [clientRectY, setClientRectY] = useState(0);

  const schedulerRef = useRef<HTMLElement>(null);
  const schedulerRefExist = !!schedulerRef.current;

  const loading = useSelector(isLoading());

  const observerHandler = useCallback(([entry]) => {
    setClientRectY(entry.boundingClientRect.y);
    setIsIntersecting(entry.isIntersecting);
  }, []);

  useEffect(() => {
    if (schedulers) {
      scroll(schedulerRef, () => {
        setLoaded(true);
      });
    }
  }, [schedulers]);

  useEffect(() => {
    const observer = new IntersectionObserver(observerHandler, observerOptions);
    const refCurrent = schedulerRef.current;
    if (refCurrent) {
      observer.observe(refCurrent);
    }
    return () => {
      if (refCurrent) {
        observer.unobserve(refCurrent);
      }
    };
  }, [observerHandler, schedulerRefExist]);

  const closestDayIdx = useMemo(() => {
    if (schedulers) {
      const today = moment();
      return schedulers.findIndex(scheduler => moment(scheduler.date).isSameOrAfter(today, "day"));
    }
  }, [schedulers]);

  const schedulersList = useMemo(
    () =>
      schedulers &&
      schedulers.map((scheduler, idx) => {
        const { date, sermons } = scheduler;
        const formDate = new Date(date);
        let monthChanged = true;
        if (idx > 0) {
          const previousDate = new Date(schedulers[idx - 1].date);
          monthChanged =
            previousDate.getMonth() !== formDate.getMonth() || previousDate.getFullYear() !== formDate.getFullYear();
        }
        return (
          <Grid
            container
            className={"scheduler-row"}
            key={`${formDate.getMonth()}${formDate.getDate()}${formDate.getFullYear()}`}
            ref={closestDayIdx === idx ? (schedulerRef as React.MutableRefObject<HTMLDivElement>) : null}
          >
            {monthChanged && (
              <Grid item className={"scheduler-row-month"}>
                {moment(date).format("MMMM YYYY")}
              </Grid>
            )}
            <Scheduler date={formDate}>
              <SermonsCardList sermons={sermons} />
            </Scheduler>
          </Grid>
        );
      }),
    [schedulers, closestDayIdx],
  );

  return loaded ? (
    <>
      {!isIntersecting && !!clientRectY && (
        <Button
          className={`scrollBack ${clientRectY > 0 ? "positionTop" : "positionBottom"}`}
          width={204}
          variant="blue"
          onClick={() => scroll(schedulerRef, () => setLoaded(true), "smooth")}
        >
          <img src={arrow} alt="arrow" />
          Go Back to Today
        </Button>
      )}
      {schedulersList}
      {!loading && (
        <EmptyText
          icon={search ? no_matches_bg : calendar}
          show={!schedulers || schedulers.length === 0}
          message={search ? t("No matches found") : t("You don't have any Events or Meetings planned yet")}
        />
      )}
    </>
  ) : (
    <Loader />
  );
};

export default SermonsSchedulerList;
