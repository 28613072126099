import React, { ChangeEvent } from "react";
import { FormControlLabel, FormControlLabelClassKey, RadioGroup } from "@material-ui/core";

import { StyledRadio } from "../StyledRadio";

import "./styles.scss";

export interface IRadioInput {
  value: string | number;
  classes?: Partial<Record<FormControlLabelClassKey, string>> | undefined;
  label?: React.ReactNode;
}

type Props = {
  name: string;
  value: string | number;
  variants: IRadioInput[];
  onChange: (e: ChangeEvent) => void;
  className?: string;
  error?: string;
};

const SquareRadio: React.FC<Props> = ({ name, value, onChange, className, variants, error }) => {
  return (
    <RadioGroup name={name} value={value} onChange={onChange} className={`square-radio ${className || ""}`}>
      {variants.map(({ value, label, classes }) => (
        <FormControlLabel key={value} classes={classes} value={value} control={<StyledRadio />} label={label} />
      ))}
      {error && <p className="square-radio-error">{error}</p>}
    </RadioGroup>
  );
};

export default SquareRadio;
