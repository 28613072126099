import { RedirectToCheckoutOptions, AddPaymentMethodOptions } from "../../interfaces/Stripe";

import { request } from "shared/utils";
import { METHODS, API } from "shared/constants";

export default {
  createCheckoutSession: (params: RedirectToCheckoutOptions) =>
    request(METHODS.POST, API.CREATE_STRIPE_CHECKOUT_SESSION)(params),
  addPaymentMethod: (params: AddPaymentMethodOptions) => request(METHODS.POST, API.ADD_PAYMENT_METHOD)(params),
};
