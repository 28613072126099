import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/styles";
import { Formik, FormikHelpers } from "formik";
import _ from "lodash";
import { useClickOutside } from "react-click-outside-hook";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../../../store";
import { getCommunity, getUserCommunities } from "../../../../Community/store/selectors";
import { getExistMember, getMember } from "../../../store/selectors";
import * as authSelectors from "../../../../Auth/store/selectors";
import { IMember, IMemberDialog, IMemberDialogValues } from "../../../interfaces";
import { validationSchema } from "./formValidators";
import MemberDialogView from "../MemberDialogView";
import MemberDialogEdit from "../MemberDialogEdit";

import { ERoles, MemberPermissions } from "containers/Auth/interfaces";
import { dialogTheme } from "theme";
import { getImageBase64 } from "shared/utils/dataModifiers";
import { AlertDialog, SideDialog, usePermissions } from "shared";

import "./memberDialog.scss";

const prepareMemberForm = (member?: IMember | null, shouldSendInvitation = false) => {
  return {
    id: member?.id,
    first_name: member && member.first_name ? member.first_name : "",
    last_name: member && member.last_name ? member.last_name : "",
    phone_number: member && member.phone_number ? member.phone_number : "",
    email: member && member.email ? member.email : "",
    image_url: member && member.image_url ? member.image_url : null,
    note: member && member.note ? member.note : "",
    should_create_as_manager: member && member.should_create_as_manager ? member.should_create_as_manager : false,
    send_invitation: shouldSendInvitation || !member,
    role: member?.role || "",
  };
};

const MemberDialog: React.FC<IMemberDialog> = props => {
  const { open, onClose, title, member, communityId, isEditMode, onChangeMode, onEditExistMember, onSave } = props;

  const formikRef = useRef<any>();

  const existMember = useSelector(getExistMember());
  const communityMember = useSelector(getMember());
  const currentCommunity = useSelector(getCommunity());
  const userCommunities = useSelector(getUserCommunities());

  const currentMember = useSelector(authSelectors.getMember());
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isExists, setIsExists] = useState(false);
  const [memberEmails, setMemberEmails] = useState<string[]>([]);
  const [initValue, setInitValue] = useState<IMemberDialogValues>(prepareMemberForm());
  const [invitationStatus, setInvitationStatus] = useState<string | null>(null);

  const [ref, hasClickedOutside] = useClickOutside();

  const editPermissions = useMemo(() => [MemberPermissions.edit], []);
  const removePermissions = useMemo(() => [MemberPermissions.delete], []);
  const viewInvitationsPermissions = useMemo(() => [MemberPermissions.view_invitations], []);
  const invitePermissions = useMemo(() => [MemberPermissions.invite], []);
  const isOwnMember = member?.id === currentMember?.id;

  const canEdit = usePermissions(editPermissions, communityId);
  const canRemove = usePermissions(removePermissions, communityId) && !isOwnMember;
  const canViewInvitations = usePermissions(viewInvitationsPermissions, communityId);
  const canInvite = usePermissions(invitePermissions, communityId);

  useEffect(() => {
    const emails: string[] = communityMember.map(cMember => cMember.email);
    setMemberEmails(emails);
  }, [communityMember]);

  useEffect(() => {
    setIsExists(!!existMember);
  }, [existMember]);

  useEffect(() => {
    return setInitValue(prepareMemberForm(member));
  }, [open, member]);

  useEffect(() => {
    if (member && member.id && !member.has_accepted_invitation && canViewInvitations) {
      dispatch(
        actions.fetchInvitationDate.request({
          community_id: member.community_id,
          member_id: member.id,
          callback: data => setInvitationStatus(data),
        }),
      );
    }
  }, [canViewInvitations, dispatch, member]);

  const [isCancel, setIsCancel] = useState(false);
  const [isRemove, setIsRemove] = useState(false);

  const isUsersProfile = useMemo(
    () => !!currentMember && !!member && member.id === currentMember.id,
    [currentMember, member],
  );

  const onCloseConfirmed = useCallback(() => {
    dispatch(actions.fetchExistMember.cancel());
    setIsCancel(false);
    onClose();
  }, [dispatch, onClose]);

  const handleSaveMember = useCallback(
    (values: IMemberDialogValues) => {
      const { image_url, id, ...rest } = values;

      const previousMember = existMember || member;
      const isImageChanged =
        (!!previousMember && previousMember.image_url !== image_url) || (!previousMember && !!image_url);
      const image_base64 = getImageBase64(image_url, isImageChanged);

      const memberRequestValues = {
        ...rest,
        image_base64,
        community_id: communityId,
        role: rest.should_create_as_manager ? ERoles.manager : ERoles.member,
      };
      if (existMember?.id && existMember.email === values.email) {
        onEditExistMember(memberRequestValues, existMember.id);
        onCloseConfirmed();
      } else {
        onSave(memberRequestValues);
      }
    },
    [communityId, existMember, member, onCloseConfirmed, onEditExistMember, onSave],
  );

  const onCloseDialog = (values: IMemberDialogValues) => {
    if (!_.isEqual(values, initValue)) {
      setIsCancel(true);
    } else {
      onCloseConfirmed();
    }
  };

  const onRemoveConfirmed = () => {
    setIsRemove(false);
    if (member && member.id) {
      dispatch(actions.removeMember.request({ id: member.id, community_id: member.community_id }));
    }
    onClose();
  };

  const isValidBeforeConnect = (values: IMemberDialogValues): boolean => {
    const email = values.email;
    const trim = email.trim();
    return !(trim.length && memberEmails.includes(trim));
  };

  const handleSubmit = (values: IMemberDialogValues, formikHelpers: FormikHelpers<IMemberDialogValues>) => {
    const { resetForm, setSubmitting, setFieldTouched, setErrors } = formikHelpers;

    if (!isValidBeforeConnect(values) && values.email !== initValue.email) {
      setFieldTouched("email", true, false);
      setErrors({ email: "The Person already exists in community" });
      return;
    }
    if (values.email !== initValue.email) {
      dispatch(
        actions.fetchExistMember.request({
          email: values.email,
          callback: (member?: IMember) => {
            if (!member) {
              handleSaveMember(values);
              setSubmitting(false);
              resetForm();
            }
          },
        }),
      );

      return;
    }
    handleSaveMember(values);
  };

  const sendPrivateInvitationHandler = () => {
    if (member && member.id) {
      const member_community = communityMember.find(m => m.id === member.id);
      member_community &&
        member_community.id &&
        dispatch(
          actions.sendPrivateInvitations.request({
            memberIds: [member_community.id],
            callback: data => setInvitationStatus(data),
          }),
        );
    }
  };

  const onAddExistMember = (should_create_as_manager: boolean) => {
    setInitValue(
      prepareMemberForm(existMember ? { ...existMember, should_create_as_manager } : undefined, !!existMember),
    );
    setIsExists(false);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      innerRef={formikRef as any}
      initialValues={initValue}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, errors, handleSubmit, setFieldTouched, setErrors }) => {
        const handleBlurEmail = (e: React.FocusEvent<HTMLInputElement>) => {
          if (!hasClickedOutside && userCommunities && canEdit) {
            const relatedTarget = e.relatedTarget as HTMLInputElement;
            if (
              (!relatedTarget ||
                (relatedTarget.innerHTML.toLowerCase() !== "cancel" && relatedTarget.type !== "submit")) &&
              !errors.email &&
              values.email !== initValue.email
            ) {
              if (!isValidBeforeConnect(values)) {
                setErrors({ email: "The Person already exists in community" });
              } else {
                dispatch(actions.fetchExistMember.request({ email: values.email }));
              }
            }
            setFieldTouched("email", true, false);
          }
        };

        return (
          <SideDialog
            onSave={member && !isEditMode ? void 0 : handleSubmit}
            saveText={isEditMode ? "save changes" : "add member"}
            className="memberDialog"
            onClose={() => onCloseDialog(values)}
            open={open}
            removeText={member && canRemove ? "Remove" : void 0}
            onRemove={() => setIsRemove(true)}
            editText={member && (canEdit || isUsersProfile) && !isEditMode ? "edit" : void 0}
            onEdit={onChangeMode}
            title={title}
            reference={ref}
            cancelText={isEditMode || !member ? "cancel" : "close"}
          >
            <AlertDialog
              open={isCancel}
              title={t("Leaving Page")}
              message={t("You have unsaved changes that will be lost if you continue")}
              onConfirm={onCloseConfirmed}
              onCancel={() => setIsCancel(false)}
              mode="confirm"
              confirmText={t("Leave")}
            />
            <AlertDialog
              open={isExists}
              title={t("Existing Member")}
              message={t(
                `This person is already on Altar. Would you like to add this person to ${
                  currentCommunity && currentCommunity.name
                }?`,
              )}
              onConfirm={() => onAddExistMember(!!values.should_create_as_manager)}
              onCancel={() => dispatch(actions.fetchExistMember.cancel())}
              mode="confirm"
              confirmText={t("Add")}
              cancelText={t("Cancel")}
            />
            <AlertDialog
              open={isRemove}
              title="Remove member"
              message="You are about to remove this member. Are you sure?"
              onConfirm={onRemoveConfirmed}
              onCancel={() => setIsRemove(false)}
              mode="cancel"
              confirmText="Remove"
              confirmClassName="defaultButtons-remove"
            />
            <ThemeProvider theme={dialogTheme}>
              {member && !isEditMode ? (
                <MemberDialogView
                  member={member}
                  invitedAt={invitationStatus}
                  showExtraData={canEdit || isUsersProfile}
                  sendPrivateInvitation={canInvite ? sendPrivateInvitationHandler : undefined}
                />
              ) : (
                <MemberDialogEdit
                  member={member}
                  handleBlurEmail={handleBlurEmail}
                  canEdit={canEdit}
                  isOwnMember={isOwnMember}
                />
              )}
            </ThemeProvider>
          </SideDialog>
        );
      }}
    </Formik>
  );
};

export default MemberDialog;
