import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as jwt from "jsonwebtoken";

import { ERoles } from "../../../Auth";
import { getCommunity } from "../../../Community/store/selectors";
import { NamesOfParentRoutes } from "../../../../constants";
import { EventStatus, EventType, IEvent, IMediaDevicesState } from "../../../Sermons";
import { getRecordingStatus, getFollowMe } from "../../store/selectors";
import { ERecordingStatus } from "../../interfaces";
import { getMediaPermissions } from "../../../BreakoutRooms/store/selectors";
import { getChangingStatus } from "../../../Sermons/store/selectors";

import { IMember } from "containers/Member";
import config from "config";
import {
  clearMeetingState,
  generateEightToEightToken,
  joinClassroom,
  leaveClassroom,
  setCurrentClassroom,
  setEightToEightUser,
  setRecordingStatus,
} from "containers/Meeting/store/actions";
import { getSubdomain, useMediaDevicesHook } from "shared";
import { exitRoom } from "containers/BreakoutRooms/store/actions";
import { clearCurrentSermon, leaveEvent } from "containers/Sermons/store/actions";
import { selectors } from "containers/Meeting/store";
import { checkRoles } from "utils/ACL";
import { handleFirebaseEvent } from "utils/firebase";
import { FIREBASE_EVENTS } from "shared/interfaces/Firebase";
import { storageMediaDevicesInfo } from "shared/utils/mediaStreamHelpers";

const { EVENTS, DASHBOARD } = NamesOfParentRoutes;

let initialTileViewIsSet = false;

function startConference(
  roomCode: string,
  subject: string,
  token: string,
  mediaPermissions: PermissionState,
  mediaDevicesStatus: IMediaDevicesState,
) {
  try {
    if (token) {
      const decoded: any = jwt.decode(token);
      const options = {
        roomName: `${config.eightToeight.appId}/${decoded.room}`,
        height: "100%",
        width: "100%",
        jwt: token,
        parentNode: document.getElementById("jitsi-container"),
        interfaceConfigOverwrite: {
          DEFAULT_BACKGROUND: "#191317",
          SHOW_CHROME_EXTENSION_BANNER: false,
          SHOW_BRAND_WATERMARK: false,
          SHOW_JITSI_WATERMARK: false,
          HIDE_DEEP_LINKING_LOGO: true,
          SETTINGS_SECTIONS: ["devices", "language", "moderator", "more"],
        },
        configOverwrite: {
          disableSimulcast: false,
          prejoinPageEnabled: false,
          enableWelcomePage: false,
          subject: subject.slice(0, 50),
          toolbarButtons: config.eightToeight.toolbarButtons,
          startWithAudioMuted:
            mediaPermissions === "granted" && mediaDevicesStatus.audioMuted !== null
              ? mediaDevicesStatus.audioMuted
              : true,
          startWithVideoMuted:
            mediaPermissions === "granted" && mediaDevicesStatus.videoMuted !== null
              ? mediaDevicesStatus.videoMuted
              : true,
          disableProfile: true,
          hideRecordingLabel: true,
          notifications: ["dialog.recording"],
          apiLogLevels: ["info"],
        },
      };

      return new window.JitsiMeetExternalAPI(config.eightToeight.domain, options);
    }
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error("Failed to load Jitsi API", error);
  }
}

function EightToEight({
  member,
  roomCode,
  subject,
  isHost,
  event,
  setMediaPermissionsAlert,
}: {
  member: IMember;
  roomCode: string;
  subject: string;
  isHost: boolean;
  event: IEvent;
  setMediaPermissionsAlert: Dispatch<SetStateAction<boolean>>;
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const mediaDevicesStatus = useMediaDevicesHook(event);

  const [api, setApi] = useState<any>(null);
  const [videoConferenceJoined, setVideoConferenceJoined] = useState(false);

  const token = useSelector(selectors.selectEightToEightToken());
  const eightToEightuser = useSelector(selectors.selectEightToEightUser());
  const classrooms = useSelector(selectors.selectClassrooms());
  const classroomSettings = useSelector(selectors.selectCurrentClassroom());
  const community = useSelector(getCommunity());
  const recordingStatus = useSelector(getRecordingStatus());
  const mediaPermissions = useSelector(getMediaPermissions());
  const changingStatus = useSelector(getChangingStatus());
  const followMe = useSelector(getFollowMe());

  const { isApp } = getSubdomain();

  const classroomSettingsId = classroomSettings?.id;

  const isManager = useMemo(() => checkRoles([ERoles.admin, ERoles.manager], community?.id), [community]);
  const currentRoom = useMemo(
    () =>
      event.type === EventType.meeting && event.status !== EventStatus.lobby
        ? classrooms.find(r => r.is_main)
        : classrooms.find(c => c.code === roomCode),
    [classrooms, event.status, event.type, roomCode],
  );
  const currenMemberInClassroom = useMemo(
    () => !!currentRoom?.members?.find(m => String(m.id) === String(member.id)),
    [currentRoom, member],
  );

  const selectedDevices = useMemo(() => storageMediaDevicesInfo.get(), []);

  const jitsiContainerStyle = {
    display: "block",
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    return () => {
      if (api) {
        api.executeCommand("hangup");
      }
      dispatch(leaveClassroom({ method: "leaveClassroom" }));
      dispatch(setCurrentClassroom(null));
      dispatch(generateEightToEightToken.success({ token: "" }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (member && eightToEightuser) {
      const name = `${member.first_name} ${member.last_name}`;
      if (name !== eightToEightuser.name || member.image_url !== eightToEightuser.avatar) {
        setApi(null);
        dispatch(generateEightToEightToken.success({ token: "" }));
      }
    }
  }, [eightToEightuser, dispatch, member]);

  useEffect(() => {
    if (currentRoom && classroomSettings?.code !== currentRoom.code) {
      dispatch(setCurrentClassroom(currentRoom));
    }
  }, [classroomSettings, currentRoom, dispatch]);

  useEffect(() => {
    if (!currenMemberInClassroom && currentRoom?.code) {
      dispatch(joinClassroom({ code: currentRoom.code, member, method: "joinClassroom" }));
    }
  }, [currenMemberInClassroom, currentRoom?.code, dispatch, member]);

  useEffect(() => {
    if (!token && classroomSettingsId) {
      const payload = {
        id: member.id,
        name: `${member.first_name} ${member.last_name}`,
        email: member.email,
        avatar: member.image_url || "",
        roomCode,
        isHost,
      };
      dispatch(setEightToEightUser(payload));
      dispatch(generateEightToEightToken.request(payload));
    }
  }, [token, dispatch, roomCode, isHost, classroomSettingsId, member]);

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (
      window.JitsiMeetExternalAPI &&
      token &&
      event &&
      !api &&
      classroomSettings &&
      currenMemberInClassroom &&
      mediaPermissions
    ) {
      const api = startConference(
        roomCode,
        classroomSettings.is_main ? subject : classroomSettings.name || subject,
        token,
        mediaPermissions,
        mediaDevicesStatus,
      );

      if (api && !api?._events?.readyToClose) {
        api.addListener("readyToClose", () => {
          setTimeout(() => {
            if (event.type === EventType.meeting && currentRoom?.is_main) {
              dispatch(clearCurrentSermon());
              dispatch(exitRoom.request());

              dispatch(clearMeetingState());

              dispatch(leaveEvent(member.id));
              if (isApp && event?.community?.code) {
                return history.push(`/${event?.community?.code}${event?.is_default && isManager ? DASHBOARD : EVENTS}`);
              }
              return history.push(`/`);
            } else {
              const url = history.location.pathname.replace(`/${roomCode}`, "");
              history.push(url);

              dispatch(clearMeetingState());
            }
            initialTileViewIsSet = false;
          }, 0);
        });
      }
      api?.addListener("videoConferenceJoined", () => {
        if (event.type === EventType.stream) {
          handleFirebaseEvent(FIREBASE_EVENTS.JOIN_EVENT_ROOM, {
            room_id: classroomSettings.id,
            room_name: classroomSettings.name,
            event_id: classroomSettings.meeting_id,
          });
        }

        if (!initialTileViewIsSet) {
          api.executeCommand("setTileView", true);
          initialTileViewIsSet = true;
        }
        setVideoConferenceJoined(true);
      });

      api?.addListener("recordingStatusChanged", (e: any) => {
        dispatch(setRecordingStatus(e.on ? ERecordingStatus.recording : ERecordingStatus.not_recording));
      });

      // For now Jitsi API doesn't have a suitable listener that allow to catch event when video or audio source changes.
      // Listen to deviceListChanged and then call getCurrentDevices - doesn't work cause depend on each other.
      api?.addListener("log", async (e: any) => {
        const isAudioSwitched = e.args[2]?.includes("switched local audio input device");
        const isVideoSwitched = e.args[2]?.includes("Switched local video device");
        if (isAudioSwitched || isVideoSwitched) {
          const devices = await api.getCurrentDevices();
          if (
            devices?.audioInput?.deviceId &&
            devices.audioInput.label &&
            devices.videoInput?.deviceId &&
            devices.videoInput.label
          ) {
            storageMediaDevicesInfo.set({
              audio: { deviceId: devices.audioInput.deviceId, label: devices.audioInput.label },
              video: { deviceId: devices.videoInput?.deviceId, label: devices?.videoInput.label },
            });
          }
        }
      });

      setApi(api);
    }
    return () => {
      if (api) {
        initialTileViewIsSet = false;
      }
    };
  }, [
    api,
    isApp,
    event,
    history,
    dispatch,
    roomCode,
    subject,
    classroomSettings,
    token,
    member,
    currenMemberInClassroom,
    isManager,
    mediaPermissions,
    setMediaPermissionsAlert,
    mediaDevicesStatus,
    currentRoom,
  ]);

  useEffect(() => {
    if (videoConferenceJoined) {
      switch (recordingStatus) {
        case ERecordingStatus.hasStarted:
          api.startRecording({ mode: "file" });
          break;
        case ERecordingStatus.hasStopped:
          api.executeCommand("stopRecording", "file");
          break;
        case ERecordingStatus.not_allowed:
          dispatch(setRecordingStatus(ERecordingStatus.not_recording));
          break;
        default:
          return;
      }
    }
  }, [api, recordingStatus, dispatch, videoConferenceJoined]);

  useEffect(() => {
    return () => {
      dispatch(setRecordingStatus(ERecordingStatus.not_recording));
    };
  }, [dispatch]);

  useEffect(() => {
    if (api && selectedDevices) {
      api.setVideoInputDevice(selectedDevices.video?.label);
      api.setAudioInputDevice(selectedDevices.audio?.label);
    }
  }, [api, selectedDevices]);

  useEffect(() => {
    if (api && videoConferenceJoined) {
      api.addListener("cameraError", () => {
        setMediaPermissionsAlert(true);
      });
      api.addListener("micError", () => {
        setMediaPermissionsAlert(true);
      });
    }
  }, [api, setMediaPermissionsAlert, videoConferenceJoined]);

  useEffect(() => {
    if (
      ((roomCode !== event.code && currentRoom?.is_main) || (roomCode === event.code && !currentRoom?.is_main)) &&
      !changingStatus &&
      api
    ) {
      dispatch(clearMeetingState());
      dispatch(leaveClassroom({ method: "leaveClassroom" }));
      setApi(null);
    }
  }, [dispatch, event, changingStatus, currentRoom, roomCode, api]);

  useEffect(() => {
    if (api) {
      api.executeCommand("setFollowMe", followMe);
    }
  }, [api, followMe]);

  useEffect(() => {
    if (api) {
      api.executeCommand("setVideoQuality", 360);
    }
  }, [api]);

  return token ? <div id="jitsi-container" style={jitsiContainerStyle} /> : null;
}

export default EightToEight;
