import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, FormikErrors } from "formik";
import { useHistory } from "react-router";

import { Login, Join } from "../../components";
import { actions, selectors } from "../../store";
import { AuthShape, ILoginForm } from "../../interfaces";
import { getEmailIsExist, getOnboardingUser } from "../../../Onboarding/store/selectors";
import { authentificated, getRegistrationData, isEmailResent, isMagicLinkSent } from "../../store/selectors";
import { sendMagicLink, setEmailResent, setError, setRegistrationData } from "../../store/actions";
import EmailSent from "../../components/EmailSent";
import { NamesOfParentRoutes, NameOfRoutes } from "../../../../constants";
import AuthWrapper from "../../components/AuthWrapper";

import { getSubdomain } from "shared";
import { selectAllSubdomains } from "containers/Community/store/selectors";
import { getSubdomainList } from "containers/Community/store/actions";
import { searchParamsToObject } from "shared/utils/searchParams";
import { loginValidationSchema } from "containers/Auth/constants";
import { IRouteComponentProps } from "shared/interfaces";
import { checkEmail, setOnboardingUser } from "containers/Onboarding/store/actions";

const { AUTH, EVENTS } = NamesOfParentRoutes;
const { SIGN_UP } = NameOfRoutes;

const LoginContainer: React.FC<IRouteComponentProps> = props => {
  const {
    match: {
      params: { token, hash },
    },
  } = props;

  const dispatch = useDispatch();

  const history = useHistory();

  const {
    location: { state: routerState },
  } = history;

  const [initialValues, setInitialValues] = useState<ILoginForm>({ email: "", password: "" });
  const [emailSentData, setEmailSentData] = useState({ title: "", subTitle: "" });

  const mutableErrors = useRef<FormikErrors<ILoginForm>>();

  const onboardingData = useSelector(selectors.getOnboardingData());
  const emailExists = useSelector(getEmailIsExist());
  const user = useSelector(getOnboardingUser());
  const magicLinkSent = useSelector(isMagicLinkSent());
  const emailResent = useSelector(isEmailResent());
  const registrationData = useSelector(getRegistrationData());
  const subdomains = useSelector(selectAllSubdomains());
  const isAuthentificated = useSelector(authentificated());

  useEffect(() => {
    if (isAuthentificated && !token && !hash) {
      history.push("/");
    }
  }, [history, isAuthentificated, token, hash]);

  const { redirectTo, subdomain_part } = useMemo(
    () => searchParamsToObject(history.location.search),
    [history.location],
  );

  const { subdomain, isApp } = getSubdomain();

  useEffect(() => {
    dispatch(getSubdomainList.request());
  }, [dispatch]);

  useEffect(() => {
    if (subdomain_part) {
      localStorage.setItem("subdomain_part", subdomain_part);
      history.replace({ search: "" });
    }
  }, [subdomain_part, history]);

  useEffect(() => {
    if (subdomain && !isApp && subdomains.length > 0) {
      const url = window.location.href.replace(subdomain, "app");
      if (subdomains.includes(subdomain)) {
        return window.location.replace(`${url}${redirectTo ? "&" : "?"}subdomain_part=${subdomain}`);
      }
      return window.location.replace(url);
    }
  }, [subdomain, subdomains, redirectTo, history, isApp]);

  useEffect(() => {
    token && dispatch(actions.confirmVerifyUser.request({ hash: token, redirectTo }));
  }, [token, dispatch, redirectTo]);

  useEffect(() => {
    if (hash) {
      dispatch(actions.magicLogin({ hash, redirectTo }));
    }
  }, [dispatch, hash, redirectTo]);

  useEffect(() => {
    if (onboardingData) {
      setInitialValues({ email: onboardingData.email, password: "" });
    }
  }, [onboardingData]);

  useEffect(() => {
    const email = registrationData?.email || user?.email || "";
    if (email) {
      setInitialValues(prevState => ({ ...prevState, email }));
    }
  }, [registrationData, user]);

  useEffect(() => {
    const data = {
      title: "",
      subTitle: "",
    };
    if (magicLinkSent) {
      data.title = emailResent ? "Magic link has been resent!" : "Magic link was sent!";
      data.subTitle = "Please check your inbox to continue.";
    }
    setEmailSentData(data);
  }, [emailResent, magicLinkSent]);

  const handleSubmit = (values: AuthShape) => {
    let loginData = { ...values };
    if (onboardingData && onboardingData.email) {
      dispatch(actions.clearOnboardingData());
      if (onboardingData.email === values.email) {
        loginData.redirectTo = onboardingData.communityCode ? `/${onboardingData.communityCode}${EVENTS}` : "/";
      }
    }
    if (routerState) {
      loginData = { ...loginData, ...routerState };
    }
    dispatch(actions.login.request(loginData));
  };

  const handleBack = () => {
    dispatch(setEmailResent(false));
    dispatch(setError(null));
    dispatch(checkEmail.success(false));
  };

  return (
    <Formik
      validateOnChange={false}
      validationSchema={loginValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ values, errors, resetForm }) => {
        const { email } = values;
        mutableErrors.current = errors;

        const handleNext = () => {
          // use last errors object instead of stored on particular render
          if (!mutableErrors.current?.email) {
            dispatch(
              checkEmail.request({
                email,
                callback: () => {
                  dispatch(setRegistrationData({ ...registrationData, email }));
                  history.push(`${AUTH}${SIGN_UP}`);
                },
              }),
            );
          }
        };

        const handleResendEmail = () => {
          let redirectTo: string | undefined;
          if (onboardingData) {
            if (!onboardingData.email || onboardingData.email === values.email) {
              redirectTo = onboardingData.communityCode ? `/${onboardingData.communityCode}${EVENTS}` : void 0;
            }
          }
          dispatch(sendMagicLink.request({ email, redirectTo }));
        };

        const handleChangeEmail = () => {
          resetForm();
          dispatch(sendMagicLink.cancel());
          dispatch(setOnboardingUser(null));
          handleBack();
        };

        return (
          <AuthWrapper withPadding withHomePage>
            {magicLinkSent ? (
              <EmailSent
                title={emailSentData.title}
                subTitle={
                  <>
                    Email has been sent to {email}.
                    <br />
                    {emailSentData.subTitle}
                  </>
                }
                onChangeEmail={handleChangeEmail}
                onResendEmail={handleResendEmail}
              />
            ) : emailExists && user ? (
              <Login user={user} onBack={handleBack} />
            ) : (
              <Join onSubmit={handleNext} />
            )}
          </AuthWrapper>
        );
      }}
    </Formik>
  );
};

export default LoginContainer;
