import React, { Component } from "react";

import AudioVisualiser from "./AudioVisualiser";

interface AudioAnalyserInterface {
  audio: MediaStream;
}

class AudioAnalyser extends Component<AudioAnalyserInterface> {
  audioContext: AudioContext | undefined;
  analyser: any;
  dataArray: Uint8Array | undefined;
  source: any;
  rafId: number | undefined;
  state: { audioData: Uint8Array };
  constructor(props: AudioAnalyserInterface) {
    super(props);
    this.state = { audioData: new Uint8Array(0) };
  }

  componentDidMount() {
    this.audioContext = new AudioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    this.source = this.audioContext.createMediaStreamSource(this.props.audio);
    this.source.connect(this.analyser);
    this.rafId = requestAnimationFrame(this.tick);
  }

  tick = () => {
    this.analyser.getByteTimeDomainData(this.dataArray);
    this.setState({ audioData: this.dataArray });
    this.rafId = requestAnimationFrame(this.tick);
  };

  componentWillUnmount() {
    this.rafId && cancelAnimationFrame(this.rafId);
    this.analyser.disconnect();
    this.source.disconnect();
  }

  render() {
    return <AudioVisualiser audioData={this.state.audioData} />;
  }
}

export default AudioAnalyser;
