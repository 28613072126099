import produce from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { ERecordingStatus, MeetingStateType } from "../interfaces";
import * as actions from "./actions";

const initialState: MeetingStateType = {
  token: null,
  eightToEightUser: null,
  classrooms: [],
  current_classroom: null,
  classroom_is_full: false,
  recordingStatus: ERecordingStatus.not_allowed,
  preJoinPage: true,
  followMe: false,
};

type Action = ActionType<typeof actions>;

const reducer = createReducer<MeetingStateType, Action>(initialState)
  .handleAction(actions.generateEightToEightToken.success, (state, action) =>
    produce(state, nextState => {
      nextState.token = action.payload.token;
    }),
  )
  .handleAction(actions.setEightToEightUser, (state, action) =>
    produce(state, nextState => {
      nextState.eightToEightUser = action.payload;
    }),
  )
  .handleAction([actions.loadClassRooms.success, actions.setEventClassrooms.success], (state, action) =>
    produce(state, nextState => {
      nextState.classrooms = action.payload.classrooms;
    }),
  )
  .handleAction(actions.setCurrentClassroom, (state, action) =>
    produce(state, nextState => {
      nextState.current_classroom = action.payload;
    }),
  )
  .handleAction(actions.clearMeetingState, state =>
    produce(state, nextState => {
      nextState.token = null;
      nextState.eightToEightUser = null;
      nextState.current_classroom = null;
      nextState.classrooms = [];
      nextState.classroom_is_full = false;
    }),
  )
  .handleAction(actions.setFullClassroom, (state, action) =>
    produce(state, nextState => {
      nextState.classroom_is_full = action.payload || false;
    }),
  )
  .handleAction(actions.setRecordingStatus, (state, action) =>
    produce(state, nextState => {
      nextState.recordingStatus = action.payload;
    }),
  )
  .handleAction(actions.setPreJoinPage, (state, action) =>
    produce(state, nextState => {
      nextState.preJoinPage = action.payload;
    }),
  )
  .handleAction(actions.setFollowMe, (state, action) =>
    produce(state, nextState => {
      nextState.followMe = action.payload;
    }),
  );

export default reducer;
