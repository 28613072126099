import "./index.scss";

import { Popover, PopoverProps } from "@material-ui/core";
import React from "react";

interface IActionsTooltip {
  onClose: () => void;
  classNames?: string;
}

const ActionsTooltip: React.FC<IActionsTooltip & PopoverProps> = ({ children, classNames, onClose, ...props }) => {
  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: 60,
        horizontal: 0,
      }}
      onClose={onClose}
      {...props}
    >
      {children}
    </Popover>
  );
};

export default React.memo(ActionsTooltip);
