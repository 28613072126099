import React, { useMemo } from "react";
import { DateSeparatorProps, useChannelStateContext } from "stream-chat-react";
import { useSelector } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import { getEvent } from "../../../Sermons/store/selectors";

import "./customDateSeparator.scss";

const CustomDateSeparator: React.FC<DateSeparatorProps> = ({ date }) => {
  const event = useSelector(getEvent());
  const { messages } = useChannelStateContext();
  const show = useMemo(
    () =>
      !!messages?.find(
        message =>
          date.toDateString() === (message.created_at as unknown as Date).toDateString?.() &&
          message.type !== "deleted",
      ),
    [messages, date],
  );
  const dateToDisplay = useMemo(() => {
    const today = new Date().setHours(0, 0, 0, 0);
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0);
    const messageDate = new Date(date).setHours(0, 0, 0, 0);
    if (messageDate === today) {
      return "Today";
    } else if (messageDate === yesterday) {
      return "Yesterday";
    } else {
      return moment(date).format("dddd, MMMM D");
    }
  }, [date]);

  return show ? (
    <div className="dateSeparator">
      <p className={classNames("dateSeparator-date", { event: !!event })}>{dateToDisplay}</p>
    </div>
  ) : null;
};

export default CustomDateSeparator;
