import { call, put, take, takeLeading, cancel, cancelled, fork } from "redux-saga/effects";
import { S3 } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";

import fileApi from "../file/api";
import { uploadToS3 } from "./actions";
import store from "../../../index";
import { EFileStatus } from "../../interfaces/File";
import { setFile } from "../file/actions";

import { startLoading, stopLoading } from "containers/App/store/actions";

function* upload({ payload }: ReturnType<typeof uploadToS3.request>): Generator {
  let uploadObj: Upload | null = null;
  let isCancelled = false;
  try {
    yield put(startLoading());
    const { file, ...rest } = payload;
    const params: any = yield call(fileApi.createFile, rest);
    const {
      awsParams: { region, credentials, ...s3Params },
      ...fileParams
    } = params;
    yield put(setFile(fileParams));
    const client = new S3({
      region,
      credentials,
    });
    uploadObj = new Upload({
      client,
      params: {
        ACL: "public-read",
        ...s3Params,
        Body: file,
      },
      partSize: 5242881,
    });
    uploadObj.on("httpUploadProgress", ({ loaded, total }) => {
      if (loaded && total && !isCancelled) {
        const progress = Math.round((100 * loaded) / total);
        store.dispatch(uploadToS3.success(progress === 100 ? 99 : progress));
      }
    });
    yield put(uploadToS3.success(0));
    yield put(stopLoading());
    yield call([uploadObj, uploadObj.done]);
    // @ts-ignore
    yield call([client, client.putObjectAcl], {
      ACL: "public-read",
      ...s3Params,
    });
    yield call(fileApi.updateFile, { id: fileParams.id, status: EFileStatus.uploaded });
    yield put(uploadToS3.success(100));
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield put(uploadToS3.failure(error.message));
  } finally {
    if (uploadObj && (yield cancelled())) {
      isCancelled = true;
      yield call([uploadObj, uploadObj.abort]);
    }
    yield put(stopLoading());
  }
}

function* uploadToS3Saga(action: ReturnType<typeof uploadToS3.request>): Generator {
  const uploadTask = yield fork(upload, action);
  yield take(uploadToS3.cancel);
  // @ts-ignore
  yield cancel(uploadTask);
}

function* awsSagas() {
  yield takeLeading(uploadToS3.request, uploadToS3Saga);
}
export default awsSagas;
