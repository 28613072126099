import * as React from "react";

import subscription_banner_background from "assets/images/subscription-banner-background.jpg";

import "./index.scss";

const SubscriptionBanner = () => {
  return (
    <div className="subscription-banner-wrapper">
      <img src={subscription_banner_background} alt="banner" />
      <div className="banner-information-wrapper">
        <div className="badge">SPECIAL OFFER</div>
        <div className="banner-title">
          Starting a new ministry or church? <br /> We offer special starter rates.
        </div>
        <div className="banner-description">Get started with the right tools & grow with Altar Live!</div>
        <a className="orange-button" target="blank" href="https://www.altarlive.com/contact-us">
          Apply Now
        </a>
      </div>
    </div>
  );
};

export default SubscriptionBanner;
