import { Provider } from "../interfaces";

export const generateDataFromSocialProvider = ({
  data,
  provider,
}: {
  data: Record<string, any>;
  provider: Provider;
}) => {
  switch (provider) {
    case "google":
      return {
        provider,
        email: data.email,
        firstName: data.given_name,
        id: data.sub,
        lastName: data.family_name,
        name: data.name,
        profilePicURL: data.picture,
      };
    case "facebook":
      return {
        provider,
        email: data.email,
        firstName: data.first_name,
        id: data.id,
        lastName: data.last_name,
        name: data.name,
        profilePicURL: data.picture.data.url,
      };
    default:
      return null;
  }
};
