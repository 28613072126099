import { Browser } from "detect-browser";

import {
  EEventRelayType,
  EmojisType,
  EventType,
  IEventRelayItem,
  IEventVideoSettings,
  PRE_EVENT_VIDEO,
} from "../interfaces";

import { EEventStreamType } from "containers/Sermons/interfaces";

export const DIRECTIONS = [
  { value: "desc", text: "Show Latest First" },
  { value: "asc", text: "Show Oldest First" },
];

export const CHARACTER_LIMIT = 200;
export const TITLE_CHARACTER_LIMIT = 100;
export const SLIDES_SCHEDULE_NAME_LENGTH_LIMIT = 100;
export const SLIDE_NAME_LENGTH_LIMIT = 100;
export const MAX_SLIDE_IMAGE_FILE_SIZE = 25; //MB
export const HOST_NOTES_CHARACTER_LIMIT = 15000;
export const ROOMS_LIMIT = 100;
export const MAX_GREETERS_COUNT = 30;

export const SermonsTab = {
  details: "Event details",
  slides: "Slides",
  integrations: "Integrations",
};

export const HALF_HOUR_MS = 30 * 60 * 1000;

export enum EventMemberTypes {
  member = "member",
  guest = "guest",
}

export const allowedBrowsers: Browser[] = ["chrome", "edge-chromium", "firefox"];

export const emojis: { name: EmojisType; src: string }[] = [
  { name: "smile", src: "smile_emoji_icon.png" },
  { name: "heart", src: "heart_emoji_icon.png" },
  { name: "pray", src: "pray_emoji_icon.png" },
  { name: "clap", src: "clap_emoji_icon.png" },
];

export const emojiPaths = [
  "M 590 180 C 530 100 550 40 480 40 C 380 40 440 140 340 140 C 280 140 260 50 170 50 C 80 50 90 90 20 90 ",
  "M 590 180 C 530 100 540 90 480 90 C 390 90 450 160 330 160 C 280 160 270 90 180 100 C 80 110 90 90 20 90 ",
  "M 590 180 C 560 110 580 40 470 40 C 350 40 450 160 290 160 C 200 160 270 80 150 80 C 70 90 100 110 20 110",
  "M 590 180 C 460 160 580 40 430 40 C 330 40 380 160 290 160 C 190 160 210 40 140 40 C 70 40 110 50 10 50",
  "M 590 180 C 570 160 580 10 470 10 C 360 10 380 160 290 160 C 190 160 220 70 140 70 C 60 70 110 170 10 180",
];

export const camerasQuantity = {
  initial: 3,
  extended: 10,
};

export const streamUrlValidationRexExp = {
  [EEventStreamType.FB]: /^\s*https:\/\/www\.facebook\.com|fb\.watch\/.*/,
  [EEventStreamType.YOUTUBE]: /^\s*https:\/\/youtu\.be|www\.youtube\.com\/.*/,
  [EEventStreamType.VIMEO]: /^\s*https:\/\/vimeo\.com\/.*/,
  // [EEventStreamType.TWITCH]: /^\s*https:\/\/www\.twitch\.tv\/.*/,
  [EEventStreamType.RESI]: /^\s*https:\/\/.*\.m3u8\s*$/,
  [EEventStreamType.CASTR]: /^\s*https:\/\/.*\.castr\..*m3u8\s*$/,
  [EEventStreamType.CUSTOM]: /^\s*<iframe.*/,
  [EEventStreamType.ALTAR]: /^\s*https:\/\/.*/,
  [EEventStreamType.ALTAR_LIVE]: /.*/,
  [EEventStreamType.VIMEO_LIVE]: /.*/,
  [EEventStreamType.m3u8]: /^\s*https:\/\/.*(\.mpd|\.m3u8).*/,
  [EEventStreamType.dacast]: /^\s*https:\/\/.*(\.mpd|\.m3u8).*/,
  [EEventStreamType.churchstreaming]: /^\s*https:\/\/.*(\.mpd|\.m3u8).*/,
  [EEventStreamType.STREAMING_CHURCH_TV]: /^\s*https:\/\/.*(\.mpd|\.m3u8).*/,
  [PRE_EVENT_VIDEO]: /^\s*https:\/\/.*/,
};

export const convertIframeProp = (key: string): string => {
  const map: Record<string, string> = {
    allowfullscreen: "allowFullScreen",
    allowtransparency: "allowTransparency",
    frameborder: "frameBorder",
    marginheight: "marginHeight",
    marginwidth: "marginWidth",
    referrerpolicy: "referrerPolicy",
    srcdoc: "srcDoc",
  };

  return map[key] || key;
};

export const DEFAULT_GREETER_MESSAGE =
  "Welcome! We’re so glad you’re here. " +
  "Please feel free to ask the Hosts any questions or just say hi by replying here.";

export const daysOfWeek = {
  SU: {
    order: 1,
    short: "SU",
    full: "Sunday",
  },
  MO: {
    order: 2,
    short: "MO",
    full: "Monday",
  },
  TU: {
    order: 3,
    short: "TU",
    full: "Tuesday",
  },
  WE: {
    order: 4,
    short: "WE",
    full: "Wednesday",
  },
  TH: {
    order: 5,
    short: "TH",
    full: "Thursday",
  },
  FR: {
    order: 6,
    short: "FR",
    full: "Friday",
  },
  SA: {
    order: 7,
    short: "SA",
    full: "Saturday",
  },
};

export const defaultVideoSettings: IEventVideoSettings = {
  controls: true,
  muted: true,
  playing: true,
  volume: 1,
  pip: true,
};

export const defaultRelayData: IEventRelayItem[] = [
  {
    type: EEventRelayType.YOUTUBE,
    enabled: false,
    stream_url: "",
    stream_key: "",
  },
  {
    type: EEventRelayType.FB,
    enabled: false,
    stream_url: "",
    stream_key: "",
  },
];

export const EventTypeNames = {
  [EventType.liveService]: "live event",
  [EventType.meeting]: "meeting",
  [EventType.stream]: "event",
};

export const classroomOptions = [
  {
    text: "12",
    value: 12,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "50",
    value: 50,
  },
  {
    text: "100",
    value: 100,
  },
  {
    text: "200",
    value: 200,
  },
  {
    text: "500",
    value: 500,
  },
];

export const initialTourTitle = "Welcome to our church!";
export const initialTourDescription =
  "We’re so glad you’re joining service with us today from wherever you are located. This is our online church platform, and we’d like to show you around. ";

export const sequenceNumbers = ["zero", "first", "second", "third", "fourth"];
