import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { FormControlLabel, RadioGroup } from "@material-ui/core";

import Divider from "../Divider";
import StreamPreview from "../StreamPreview";
import { NamesOfParentRoutes } from "../../../../../../constants";

import { EEventPreview, NavigationEventAction, EventType, IEventForm } from "containers/Sermons";
import { DropDown, IDropDownItem, SquareButton, StyledLink, StyledRadio, InfoIcon } from "shared";
import { LINKS } from "shared/constants/links";
import { CustomFormikField } from "shared/components/CustomFormikField";
import leave_btn_example from "assets/images/leave_btn_example.png";
import auditorium_with_rows from "assets/images/auditorium_with_rows_preview.png";
import lobby_with_tables from "assets/images/lobby_with_tables_preview.png";
import lobby_with_classrooms from "assets/images/lobby_with_classrooms_preview.png";

import "./index.scss";

const { CLASSROOMS, BREAKOUTROOMS, WATCH_PARTY } = NamesOfParentRoutes;
const NavigationStep: React.FC = () => {
  const {
    values: {
      leave_event_url,
      leave_event_action,
      classrooms: { classRoomItems },
      lobby_rooms: { lobbyRoomsEnabled },
      auditorium_rooms: { auditoriumRoomsEnabled },
      start_event_option,
      type,
      was_started,
    },
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormikContext<IEventForm>();

  const [startEventAction, setStartEventAction] = useState(
    start_event_option ? NavigationEventAction.custom : NavigationEventAction.default,
  );
  const [openPreview, setOpenPreview] = useState(false);

  const preViewInfo = useMemo(() => {
    const splitStartEventOption = start_event_option?.split(/(?=\/)/g) || [];
    switch (splitStartEventOption[0]) {
      case WATCH_PARTY: {
        return { preViewImg: auditorium_with_rows, preViewType: EEventPreview.auditorium_rooms };
      }
      case BREAKOUTROOMS: {
        return { preViewImg: lobby_with_tables, preViewType: EEventPreview.lobby_rooms };
      }
      case CLASSROOMS: {
        return { preViewImg: lobby_with_classrooms, preViewType: EEventPreview.classrooms };
      }
      default: {
        return auditoriumRoomsEnabled
          ? { preViewImg: auditorium_with_rows, preViewType: EEventPreview.auditorium_rooms }
          : { preViewImg: lobby_with_tables, preViewType: EEventPreview.lobby_rooms };
      }
    }
  }, [auditoriumRoomsEnabled, start_event_option]);

  const eventStartOptions = useMemo(() => {
    const options: IDropDownItem[] = [];
    if (auditoriumRoomsEnabled || type === EventType.meeting) {
      options.push({ text: "Auditorium", value: WATCH_PARTY });
    }
    if (lobbyRoomsEnabled) {
      options.push({ text: "Lobby Tables", value: BREAKOUTROOMS });
    }
    if (classRoomItems.length) {
      options.push({ text: "Lobby Classrooms", value: CLASSROOMS });
    }
    options.push(
      ...classRoomItems.map((c, index) => ({
        text: `Room ${index + 1}: ${c.name}`,
        value: `${CLASSROOMS}/${index}`,
      })),
    );
    return options;
  }, [classRoomItems, auditoriumRoomsEnabled, lobbyRoomsEnabled, type]);

  const handleChangeStartType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
      if (!was_started) {
        setStartEventAction(value as NavigationEventAction);
        setFieldValue(
          "start_event_option",
          value === NavigationEventAction.default ? null : eventStartOptions[0].value,
        );
      }
    },
    [was_started, setFieldValue, eventStartOptions],
  );

  const handleChangeEventStart = useCallback(
    (value: string | number) => {
      setFieldValue("start_event_option", value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (start_event_option && !eventStartOptions.some(({ value }) => start_event_option === value)) {
      setFieldValue("start_event_option", eventStartOptions[0].value);
    }
  }, [eventStartOptions, setFieldValue, start_event_option]);

  return (
    <div className="navigation-step">
      <div className="navigation-step__info">Control how people enter and leave your Event.</div>
      <StyledLink className="navigation-step__learn" href={LINKS.website}>
        Learn more about Navigation
      </StyledLink>
      <Divider />
      <div className="navigation-step__start">
        {openPreview ? (
          <StreamPreview onClose={() => setOpenPreview(false)} previewType={preViewInfo.preViewType} itemCount={10} />
        ) : null}
        <div className="navigation-step__start-title">Start of the Event</div>
        <div className="navigation-step__start-description">
          Select the default state that people will join when they click on your Event link. If the Host moves everyone
          to another state at any time, the default state will be overridden.
        </div>
        <div className="navigation-step__start-content content">
          <div className="content__action">
            <RadioGroup value={startEventAction} onChange={handleChangeStartType}>
              <FormControlLabel
                value={NavigationEventAction.default}
                control={<StyledRadio />}
                label="Default"
                className="content__action-radio"
              />
              <FormControlLabel
                value={NavigationEventAction.custom}
                control={<StyledRadio />}
                label={
                  <>
                    <div>Custom</div>
                    {was_started ? (
                      <InfoIcon
                        text={
                          "The Event Default State has been updated directly from inside the Event, it can no longer be changed from the Event Editor."
                        }
                      />
                    ) : null}
                  </>
                }
                className={`content__action-radio ${was_started && "content__action-radio--was-started"}`}
              />
            </RadioGroup>
            <div className="content__action-description">
              Select which state people should be joined to when they first click on your Event link:
            </div>
            <div className="content__action-selector">
              <DropDown
                size="full"
                items={eventStartOptions}
                spacer={false}
                value={start_event_option || undefined}
                onChange={handleChangeEventStart}
                isMenu
                skipScroll
                disabled={was_started || !start_event_option}
              />
            </div>
          </div>
          <div className="content__action-preview">
            <img src={preViewInfo.preViewImg} alt="preview" />
            <SquareButton
              width={272}
              type="button"
              variant={"blue"}
              cursor={"pointer"}
              onClick={() => setOpenPreview(true)}
            >
              See Preview
            </SquareButton>
          </div>
        </div>
      </div>
      <div className="navigation-step__end">
        <div className="navigation-step__end-title">End of the Event</div>
        <div className="navigation-step__end-description">
          Select where people should be directed to after the Event ends or when people click the Leave Event button.
        </div>
        <div className="navigation-step__end-setting setting">
          <div className="setting__actions">
            <RadioGroup name="leave_event_action" value={leave_event_action} onChange={handleChange}>
              <FormControlLabel
                value={NavigationEventAction.default}
                control={<StyledRadio />}
                label="Re-direct attendees to the Community Landing page or the next Event in the Series"
                className="setting__actions-radio"
              />
              <FormControlLabel
                value={NavigationEventAction.custom}
                control={<StyledRadio />}
                label="Re-direct attendees to another link"
                className="setting__actions-radio"
              />
            </RadioGroup>
            <div className="setting__actions-description">
              Paste the URL link you want people to be re-directed to when they leave the Event or the Event ends.
            </div>
            <CustomFormikField
              className="setting__actions-urlFiled"
              fullWidth
              label=""
              name="leave_event_url"
              errors={errors}
              value={leave_event_url || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={leave_event_action === NavigationEventAction.default}
            />
          </div>
          <img src={leave_btn_example} alt="leave event" className="setting__exit-btn" />
        </div>
      </div>
    </div>
  );
};

export default NavigationStep;
