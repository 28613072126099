import React from "react";
import { Grid } from "@material-ui/core";
import { Field, FieldAttributes } from "formik";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { ResetForm } from "../../interfaces";
import AuthWrapper from "../AuthWrapper";

import { StyledTextField, Button } from "shared";

import "../styles/auth.scss";

const Reset: React.FunctionComponent<ResetForm> = ({ onSubmit, errorMessage, values }) => {
  const { t } = useTranslation();
  return (
    <AuthWrapper>
      <Grid container className="auth-root reset">
        <Grid item>
          <div className={classnames("auth-description", !errorMessage ? "auth-root-margin" : "")}>
            <div className="auth-description-title">{t("Set New Password")}</div>
            <div className="auth-description-subtitle" />
          </div>
        </Grid>
        {!!errorMessage && (
          <Grid container justify="flex-start" className="auth-error-container">
            <Grid item className={"auth-error"}>
              {t(errorMessage)}
            </Grid>
          </Grid>
        )}
        <form id="loginForm" onSubmit={onSubmit}>
          <Grid container justify="center" className="auth-root-margin">
            <Field name="password">
              {({ field, form }: FieldAttributes<any>) => {
                return (
                  <StyledTextField
                    fullWidth
                    label={t("Set Password")}
                    placeholder=""
                    name="password"
                    type="password"
                    required
                    errors={form.errors}
                    value={field.value}
                    onChange={field.onChange}
                  />
                );
              }}
            </Field>
          </Grid>
          <Grid container justify="center" className="auth-root-margin">
            <Field name="repeatPassword">
              {({ field, form }: FieldAttributes<any>) => {
                return (
                  <StyledTextField
                    fullWidth
                    label={t("Confirm Password")}
                    placeholder=""
                    required
                    name="repeatPassword"
                    type="password"
                    errors={form.errors}
                    value={field.value}
                    onChange={field.onChange}
                  />
                );
              }}
            </Field>
          </Grid>
          <Grid container justify="center">
            <Grid item className="auth-submitBtn">
              <Button
                type="submit"
                variant={!values.password.trim() || !values.repeatPassword.trim() ? "disabled" : "orange"}
                disabled={!values.password.trim() || !values.repeatPassword.trim()}
              >
                {t("Change Password")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </AuthWrapper>
  );
};

export default Reset;
