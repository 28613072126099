import React from "react";

import { PrivateEventBadge } from "../PrivateEventBadge";

import { getEventTimeRange, getFormattedDate, IEvent, SermonRestriction, eventIsCurrent } from "containers/Sermons";
import empty_featured_image from "assets/icons/empty-featured-image.svg";
import default_image_landing_featured from "assets/images/default_image_landing_featured.png";

import "./index.scss";

interface FeaturedEventsProps {
  events: IEvent[];
  navigateToEvent: (code: string) => void;
  customLandingPage: boolean;
}

function FeaturedEvents({ events, navigateToEvent, customLandingPage }: FeaturedEventsProps) {
  return (
    <div className="featured-event-component-wrapper">
      <div className="featured-title-wrapper">
        <div className="section-title">{customLandingPage ? "Keynote - Up Next:" : "Upcoming Featured Events"}</div>
      </div>
      <div className="featured-events-list">
        {events.map(e => (
          <div className="featured-event" key={e.id} onClick={() => navigateToEvent(e.code || "")}>
            <div className="img-wrapper">
              {eventIsCurrent(e) && <div className="badge live">live</div>}
              {e.image_url ? (
                <img src={e.image_url} alt={e.name} />
              ) : (
                <img className="default-image" src={default_image_landing_featured} alt={e.name} />
              )}
            </div>
            <div className="information">
              <div className="event-information-wrapper">
                <div className="event-date-wrapper">
                  <div className="date-start">{getFormattedDate(new Date(e.starting_at))}</div>
                  <div className="date-start">
                    {getEventTimeRange(e)}
                    {e.restriction !== SermonRestriction.all ? <PrivateEventBadge /> : null}
                  </div>
                </div>
                <div className="event-title-wrapper">{e.name}</div>
                <div className="event-description-wrapper">
                  <div className="event-description-text">{e.subject}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {events.length === 0 ? (
          <div className="empty-featured-events-content">
            <div className="empty-featured-image-wrapper">
              <img src={empty_featured_image} alt="empty featured" />
            </div>
            <div className="empty-title">More events coming soon!</div>
            <div className="empty-description">
              There are no upcoming featured events.
              <br /> Check the calendar below for other upcoming events!
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default React.memo(FeaturedEvents);
