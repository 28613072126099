import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getWaitingToJoinMembers } from "../../store/selectors";
import { IMember } from "../../../Member";
import { joinMeetingKnockResponse, updateEventSettings } from "../../store/actions";
import { EventDashboardTabs } from "../../interfaces";

import SelectedMembers from "shared/components/SelectMembersModal/SelectedMembers/SelectedMembers";
import { Button } from "shared";
import cross_icon_grey from "assets/icons/cross_icon_grey.svg";

import "./knockJoinEventPopup.scss";

type Props = {
  closePopup: () => void;
};

const KnockJoinEventPopup: React.FC<Props> = ({ closePopup }) => {
  const dispatch = useDispatch();

  const waitingToJoinMembers = useSelector(getWaitingToJoinMembers());
  const membersCount = waitingToJoinMembers ? waitingToJoinMembers.length : 0;

  const membersToSelect = useMemo(
    () => waitingToJoinMembers?.map(({ member }) => member) || [],
    [waitingToJoinMembers],
  );

  const allowHandler = useCallback(
    (allow: number[]) => {
      dispatch(joinMeetingKnockResponse(allow, []));
      closePopup();
    },
    [dispatch, closePopup],
  );

  const viewHandler = useCallback(() => {
    dispatch(updateEventSettings({ activeDashboardTab: EventDashboardTabs.members }));
    closePopup();
  }, [dispatch, closePopup]);

  const getMemberName = (member: IMember) => {
    if (member) {
      const { first_name, last_name } = member;
      return first_name && last_name ? `${first_name} ${last_name}` : `${first_name}`;
    }
    return "";
  };

  return (
    <div className="knockJoinEventPopup">
      <div className="knockJoinEventPopup-title">
        <div>{`The following ${membersCount > 1 ? "users" : "user"} ${
          membersCount > 1 ? "want" : "wants"
        } to join meeting!`}</div>
        <img src={cross_icon_grey} alt="cross" onClick={closePopup} />
      </div>
      <div className="knockJoinEventPopup-info">
        <div className={`knockJoinEventPopup-info-avatar ${membersCount > 1 ? "multiply" : ""}`}>
          <SelectedMembers members={membersToSelect} showUnits={false} avatarSize={membersCount > 1 ? 34 : 40} />
        </div>
        <div className="knockJoinEventPopup-info-description">
          <div className="knockJoinEventPopup-info-description-name">{getMemberName(membersToSelect[0])}</div>
          {membersToSelect[1] && (
            <>
              <div className="knockJoinEventPopup-info-description-and">
                {membersCount > 2 ? <>,&nbsp;</> : <>&nbsp;and&nbsp;</>}
              </div>
              <div className="knockJoinEventPopup-info-description-name">{getMemberName(membersToSelect[1])}</div>
              {membersCount > 2 && (
                <>
                  <div className="knockJoinEventPopup-info-description-and">&nbsp;and</div>
                  <div className="knockJoinEventPopup-info-description-name">
                    &nbsp;{membersCount - 2} {membersCount - 2 > 1 ? "others" : "other"}
                  </div>
                </>
              )}
            </>
          )}
          &nbsp;
          {membersCount > 1 ? "have" : "has"} sent a request to join the meeting
        </div>
      </div>
      <div className="knockJoinEventPopup-actions">
        <Button variant="white-text" width={156} height={40} onClick={viewHandler}>
          {membersCount > 1 ? "View All" : "View"}
        </Button>
        <Button variant="orange" width={156} height={40} onClick={() => allowHandler(membersToSelect.map(m => m.id))}>
          {membersCount > 1 ? "Allow All" : "Accept"}
        </Button>
      </div>
    </div>
  );
};

export default KnockJoinEventPopup;
