import React from "react";
import classnames from "classnames";

import { StyledLockIcon } from "shared/components/Figures";

import "./index.scss";

interface PrivateBadgeProps {
  text?: string;
  className?: string;
}

const PrivateEventBadge: React.FC<PrivateBadgeProps> = ({ className, text }) => {
  return (
    <div className={classnames("private-badge", className)}>
      <div className="private-badge-icon">
        <StyledLockIcon />
      </div>
      {text && <div className="private-badge-text">{text}</div>}
    </div>
  );
};

export default PrivateEventBadge;
