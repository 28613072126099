import React from "react";
import classnames from "classnames";

import { AppTheme } from "containers/App/interfaces/themeType";

import "./styles.scss";

type Props = {
  show: boolean;
  onClose: () => void;
  theme?: AppTheme;
};

const Overlay: React.FC<React.PropsWithChildren<Props>> = ({ children, show, onClose, theme = "light" }) => {
  return show ? (
    <div className={classnames("overlay", theme)} onClick={onClose}>
      <div className="overlay-content" onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  ) : null;
};

export default Overlay;
