import React from "react";
import { useDispatch, useSelector } from "react-redux";

import EventAuthInner from "./EventAuthInner";

import { EventSideDialog } from "shared/components";
import { getEmailIsExist, getOnboardingUser } from "containers/Onboarding/store/selectors";
import { isMagicLinkSent } from "containers/Auth/store/selectors";
import { checkEmail, setOnboardingUser } from "containers/Onboarding/store/actions";

import "./eventAuthDialog.scss";

interface IEventAuthDialog {
  open: boolean;
  onClose?: () => void;
  onAuthSuccess?: () => void;
  message?: string;
}

const EventAuthDialog: React.FC<IEventAuthDialog> = props => {
  const {
    open,
    onClose,
    onAuthSuccess,
    message = "Please log in or sign up to chat and interact with other online participants.",
  } = props;

  const dispatch = useDispatch();

  const user = useSelector(getOnboardingUser());
  const emailExists = useSelector(getEmailIsExist());
  const magicLinkSent = useSelector(isMagicLinkSent());

  const onBack = () => {
    dispatch(setOnboardingUser(null));
    dispatch(checkEmail.success(false));
  };

  return (
    <EventSideDialog
      title={magicLinkSent ? "" : emailExists && user ? "Welcome back" : "Join"}
      open={open}
      onClose={magicLinkSent ? undefined : onClose}
      onBack={emailExists && user && !magicLinkSent ? onBack : undefined}
    >
      <EventAuthInner onClose={magicLinkSent ? undefined : onClose} onAuthSuccess={onAuthSuccess} message={message} />
    </EventSideDialog>
  );
};

export default EventAuthDialog;
