import React from "react";
import { Field, FieldAttributes, useFormikContext } from "formik";
import { FormControlLabel } from "@material-ui/core";

import { AnnouncementType, IAnnouncementDialogValues, IAnnouncementTemplateForm } from "containers/Sermons/interfaces";
import { StyledCheckBox, SquareTextField, DropDown, CustomTextField } from "shared";

const MAX_POPUP_BUTTON_LENGTH = 10;

interface IProps {
  formOnChange?: () => void;
}

const typeOptions = [
  {
    text: "Panel",
    value: AnnouncementType.panel,
  },
  {
    text: "Modal",
    value: AnnouncementType.popup,
  },
  {
    text: "Notification",
    value: AnnouncementType.notification,
  },
];

const EventAnnouncementMainForm: React.FC<IProps> = ({ formOnChange }) => {
  const { values, handleChange, setFieldValue } = useFormikContext<
    IAnnouncementDialogValues | IAnnouncementTemplateForm
  >();

  return (
    <div className="announcement-form">
      <div className="announcement-form-row">
        <p className="label">Notification type</p>
        <Field name="type">
          {() => (
            <DropDown
              size="full"
              items={typeOptions}
              spacer={false}
              value={values.type}
              onChange={value => {
                setFieldValue("type", value);
                formOnChange?.();
              }}
              isMenu
              skipScroll={true}
            />
          )}
        </Field>
      </div>
      <div className="announcement-form-divider" />
      <Field name="title">
        {({ field, form }: FieldAttributes<any>) => (
          <div className="announcement-form-row announcement-form-row-title">
            <CustomTextField
              fullWidth
              label="Title"
              name="title"
              errors={form.errors}
              value={field.value}
              onChange={(e: React.ChangeEvent<any>) => {
                handleChange(e);
                formOnChange?.();
              }}
              maxLength={10}
              showMaxCounter={true}
              max={30}
            />
          </div>
        )}
      </Field>
      <Field name="description">
        {({ field, form }: FieldAttributes<any>) => (
          <div className="announcement-form-row">
            <CustomTextField
              fullWidth
              label="Announcement text"
              name="description"
              multiline
              rows={3}
              errors={form.errors}
              value={field.value}
              onChange={(e: React.ChangeEvent<any>) => {
                handleChange(e);
                formOnChange?.();
              }}
              showMaxCounter={true}
              max={800}
            />
          </div>
        )}
      </Field>
      <div className="announcement-form-row">
        <FormControlLabel
          control={
            <StyledCheckBox
              checked={values.use_button}
              onChange={e => {
                handleChange(e);
                formOnChange?.();
              }}
              name="use_button"
            />
          }
          label="Add a button for an external link"
        />
      </div>
      {values.use_button && (
        <>
          <Field name="button_text">
            {({ field, form }: FieldAttributes<any>) => (
              <div className="announcement-form-row">
                <CustomTextField
                  fullWidth
                  label="Button text"
                  name="button_text"
                  errors={form.errors}
                  value={field.value}
                  onChange={(e: React.ChangeEvent<any>) => {
                    handleChange(e);
                    formOnChange?.();
                  }}
                  showMaxCounter={true}
                  max={MAX_POPUP_BUTTON_LENGTH}
                />
              </div>
            )}
          </Field>
          <Field name="button_link">
            {({ field, form }: FieldAttributes<any>) => (
              <div className="announcement-form-row">
                <SquareTextField
                  fullWidth
                  label="Button link"
                  name="button_link"
                  errors={form.errors}
                  value={field.value}
                  onChange={(e: React.ChangeEvent<any>) => {
                    handleChange(e);
                    formOnChange?.();
                  }}
                />
              </div>
            )}
          </Field>
        </>
      )}
    </div>
  );
};

export default EventAnnouncementMainForm;
