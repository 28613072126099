import { call, cancel, delay, fork, take, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { Task } from "redux-saga";

import GeneralActionTypes from "./constants";
import { waitForUrlExists } from "./actions";

function* waitForUrlExistsSaga({ payload }: ReturnType<typeof waitForUrlExists>) {
  let urlExists = true;
  let existsOnFirstIteration = true;
  do {
    try {
      yield call([axios, axios.head], payload.url);
      urlExists = true;
    } catch (error: any) {
      urlExists = false;
      existsOnFirstIteration = false;
      yield delay(payload.delay);
    }
  } while (!urlExists);

  if (!existsOnFirstIteration) {
    payload.callback();
  }
}

function* waitForUrlExistsOrCancelSaga(action: ReturnType<typeof waitForUrlExists>) {
  const waitingTask: Task = yield fork(waitForUrlExistsSaga, action);
  yield take(GeneralActionTypes.CANCEL_URL_WAITING);
  yield cancel(waitingTask);
}

function* generalSagas() {
  yield takeLatest(GeneralActionTypes.WAIT_FOR_URL_EXISTS, waitForUrlExistsOrCancelSaga);
}
export default generalSagas;
