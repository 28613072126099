import config from "../config";
import store from "../index";
import { setHubspotInited, setHubspotOpened } from "../containers/App/store/actions";

export const iconPositionConfig = {
  default: { bottom: "0" },
  event: { bottom: "164px" },
};

let widget: HTMLElement | null = null;

const getWidget = async () =>
  new Promise<HTMLElement | null>(resolve => {
    const INTERVAL = 300;
    let COUNT_OF_CHECKS = 50;

    const intervalId = setInterval(() => {
      const widgetElement = document.getElementById("hubspot-messages-iframe-container");
      if (widgetElement || !COUNT_OF_CHECKS--) {
        clearTimeout(intervalId);
        return resolve(widgetElement);
      }
    }, INTERVAL);
  });

export const initHubspotChat = () => {
  if (config.hubspotScriptUrl) {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = config.hubspotScriptUrl;
    document.head.append(s);
    window.hsConversationsOnReady = [
      async () => {
        // eslint-disable-next-line no-console
        console.log("widget is ready");
        widget = await getWidget();
        store.dispatch(setHubspotInited(true));
      },
    ];
  }
};

export const closeWidget = () => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.close();
    store.dispatch(setHubspotOpened(false));
  }
};

export const reloadWidget = () => {
  if (window.HubSpotConversations) {
    // eslint-disable-next-line no-console
    console.log("resetAndReloadWidget");
    store.dispatch(setHubspotInited(false));
    window.HubSpotConversations.resetAndReloadWidget();
  }
};

export const openWidget = () => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.open();
    store.dispatch(setHubspotOpened(true));
  }
};

const applyStyles = async (styles: { [prop: string]: string }) => {
  if (widget) {
    Object.entries(styles).forEach(([prop, val]) => {
      (widget as HTMLElement).style.setProperty(prop, val, "important");
    });
  }
};

export const hideWidget = () => {
  applyStyles({ display: "none" });
};

export const showWidget = () => {
  applyStyles({ display: "initial" });
};

export const setIconPosition = (place: keyof typeof iconPositionConfig) => {
  applyStyles(iconPositionConfig[place]);
};

const observer = new MutationObserver(mutations => {
  mutations.forEach(mutation => {
    if (mutation.attributeName === "class" && mutation.target.nodeType === Node.ELEMENT_NODE) {
      const widgetOpened = (mutation.target as Element).classList.contains("active");
      if (!widgetOpened) {
        hideWidget();
        store.dispatch(setHubspotOpened(false));
      }
    }
  });
});

export const hideOnClose = (enable: boolean) => {
  if (enable) {
    const child = widget?.children[0];
    if (child) {
      observer.observe(child, { attributes: true, attributeFilter: ["class"] });
    }
  } else {
    observer.disconnect();
  }
};

export const identifyVisitor = (token: string, email: string) => {
  if (window.hsConversationsSettings?.identificationEmail !== email) {
    window.hsConversationsSettings = {
      identificationEmail: email,
      identificationToken: token,
    };
    // eslint-disable-next-line no-console
    console.log("email and token are set", email);

    setTimeout(reloadWidget, 1000);
  }
};
