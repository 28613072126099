import React from "react";

import { IconBase } from "../IconBase";

import { IStyledSVG } from "shared/interfaces/StyledSVG";

export const StyledLockIcon: React.FC<IStyledSVG> = ({
  style = {},
  fill = "#fff",
  width = "12",
  height = "15",
  className = "",
  opacity = 1,
}) => (
  <IconBase
    fill="none"
    width={width}
    style={style}
    height={height}
    className={className}
    opacity={opacity}
    viewBox="0 0 12 15"
  >
    <path
      d="M12 7.5C12 6.67275 11.3273 6 10.5 6H9.75V3.75C9.75 1.68225 8.06775 0 6 0C3.93225 0 2.25 1.68225 2.25 3.75V6H1.5C0.67275 6 0 6.67275 0 7.5V13.5C0 14.3273 0.67275 15 1.5 15H10.5C11.3273 15 12 14.3273 12 13.5V7.5ZM3.75 3.75C3.75 2.5095 4.7595 1.5 6 1.5C7.2405 1.5 8.25 2.5095 8.25 3.75V6H3.75V3.75Z"
      fill={fill}
    />
  </IconBase>
);

export default StyledLockIcon;
