import { v4 as uuid } from "uuid";

const loginHashHandler = {
  get: () => {
    try {
      return localStorage.getItem("loginHash");
    } catch (err: any) {
      return null;
    }
  },
  set: (loginHash: string) => {
    localStorage.setItem("loginHash", loginHash);
  },
  remove: () => {
    localStorage.removeItem("loginHash");
  },
  generate: () => {
    let hash = loginHashHandler.get();
    if (!hash) {
      hash = uuid();
      loginHashHandler.set(hash);
    }

    return hash;
  },
};
export default loginHashHandler;
