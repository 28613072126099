import { call, put, select, takeLatest } from "redux-saga/effects";

import { peerConnection } from "../utils/peerConnection";
import { DetectRTCInterface, RtcDetect } from "../utils/detectRtc";
import * as actions from "./actions";
import { selectStream } from "./selectors";
import { default as store } from "../../../index";
import { startLoading, stopLoading } from "../../App/store/actions";
import api from "../api";

import { notificationActions } from "containers/Notifications/store/actions";

function groupUserDevices(devices: MediaDeviceInfo[]) {
  return devices.reduce((acc: any, currValue) => {
    if (!acc[currValue.kind]) acc[currValue.kind] = [];
    if (currValue.deviceId) {
      acc[currValue.kind].push(currValue);
    }
    return acc;
  }, {});
}

async function callGetUserMediaDevices(): Promise<Error | any[]> {
  return (await RtcDetect()).MediaDevices;
}

async function handleUserMediaPermissions(constraints: MediaStreamConstraints, e: Error): Promise<Error | MediaStream> {
  try {
    const { hasMicrophone, isWebsiteHasMicrophonePermissions, isWebsiteHasWebcamPermissions } = await RtcDetect();

    const constraintsCopy = { ...constraints };
    const browserInfo = store.getState().browser;

    constraintsCopy.video = false;

    if (!hasMicrophone) {
      constraintsCopy.audio = false;
    }

    if (
      (constraintsCopy.audio === false && constraintsCopy.video === false) ||
      (!isWebsiteHasMicrophonePermissions && !isWebsiteHasWebcamPermissions)
    ) {
      switch (browserInfo.name) {
        case "edge-chromium":
        case "chrome": {
          store.dispatch(actions.checkUserMediaPermissions.success("denied"));
          const micPermission = (await navigator.permissions.query({ name: "microphone" as PermissionName })).state;
          const cameraPermission = (await navigator.permissions.query({ name: "camera" as PermissionName })).state;
          store.dispatch(actions.checkUserMediaPermissions.request({ cameraPermission, micPermission }));
          break;
        }
        case "safari":
        case "firefox": {
          store.dispatch(actions.checkUserMediaPermissions.success("denied"));
          break;
        }
        default: {
          store.dispatch(
            notificationActions.info(
              "Need permission to access camera or microphone",
              "At least your camera or microphone needs to be connected and permissions granted to join video conference",
            ),
          );
        }
      }
    }
    if (!isWebsiteHasMicrophonePermissions && hasMicrophone) {
      throw e;
    }

    const stream = await navigator.mediaDevices.getUserMedia(constraintsCopy);

    return stream;
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log(new Date(), "handleUserMediaPermissions-error", e);
    throw e;
  }
}

// Can not work getUserMedia in yield call
async function callGetUserMedia(constraints: MediaStreamConstraints): Promise<Error | MediaStream> {
  try {
    const rtcState = await RtcDetect();
    const browserInfo = store.getState().browser;
    if (!rtcState.hasWebcam) {
      constraints.video = false;
    }

    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    if (browserInfo.name === "firefox" || browserInfo.name === "safari") {
      store.dispatch(actions.checkUserMediaPermissions.success("granted"));
    }

    return stream;
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log(new Date(), "callGetUserMedia-error", e);
    return await handleUserMediaPermissions(constraints, e);
  }
}

export function* getUserMedia(action: ReturnType<typeof actions.getUserMedia.request>): Generator {
  try {
    const stream = yield call(callGetUserMedia, action.payload);

    yield put(actions.getUserMedia.success(stream as MediaStream));
  } catch (e: any) {
    yield put(actions.getUserMedia.failure(e));
  }
}

export function* connectSocket(action: ReturnType<typeof actions.connectSocket.request>): Generator {
  try {
    const stream: any = yield select(selectStream());

    if (!stream) {
      throw new Error("Cound not find Stream");
    }

    const roomCode = yield call(
      peerConnection.connectSocket,
      stream,
      action.payload.room_code,
      action.payload.meeting_id,
      action.payload.member,
    );

    if (!roomCode) {
      throw new Error("Cannot create room");
    }

    yield put(actions.connectSocket.success(roomCode as string));
  } catch (e: any) {
    yield put(actions.connectSocket.failure(e));
  }
}

export function* updateRooms(action: ReturnType<typeof actions.updateRooms.request>): Generator {
  try {
    peerConnection.getRooms(action.payload);
  } catch (e: any) {
    yield put(actions.updateRooms.failure(e));
  }
}

export function* joinMeeting(action: ReturnType<typeof actions.joinMeeting.request>): Generator {
  try {
    peerConnection.joinMeeting(action.payload);
  } catch (e: any) {
    yield put(actions.joinMeeting.failure(e));
  }
}

export function* exitRoom({ payload }: ReturnType<typeof actions.exitRoom.request>): Generator {
  try {
    const stream: any = yield select(selectStream());

    if (stream) {
      stream.getTracks?.()?.forEach((track: MediaStreamTrack) => {
        track?.stop?.();
      });
    }

    peerConnection?.exit();
    yield put(actions.exitRoom.success());
    if (payload) {
      payload.callback?.();
    }
  } catch (e: any) {
    yield put(actions.exitRoom.failure(e));
  }
}

export function* pageReload(): Generator {
  try {
    const stream: any = yield select(selectStream());

    if (stream) {
      stream.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    }

    peerConnection.exit();
    yield put(actions.pageReload.success());
  } catch (e: any) {
    yield put(actions.pageReload.failure(e));
  }
}

export function* getUserMediaDevices(): Generator {
  try {
    const devices = yield call(callGetUserMediaDevices);

    const grouppedDevices = groupUserDevices(devices as MediaDeviceInfo[]);

    yield put(actions.getUserMediaDevices.success(grouppedDevices));
  } catch (e: any) {
    yield put(actions.getUserMediaDevices.failure(e));
  }
}

export function* updateStreamSettings({ payload }: ReturnType<typeof actions.updateStreamSettings.request>): Generator {
  try {
    yield put(actions.updateStreamSettings.success(payload));
    peerConnection.changeStreamSettings(payload);
  } catch (e: any) {
    yield put(actions.updateStreamSettings.failure(e));
  }
}

function* updateRoom({ payload }: ReturnType<typeof actions.updateRoom>) {
  try {
    yield put(startLoading());
    const { id, ...rest } = payload;
    yield call(api.updateRoom, id, rest);
  } catch (error: any) {
    yield put(notificationActions.error("Cannot update the room", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* checkUserMediaPermissions(action: ReturnType<typeof actions.checkUserMediaPermissions.request>): Generator {
  try {
    const { hasMicrophone, hasWebcam, isWebsiteHasMicrophonePermissions, isWebsiteHasWebcamPermissions } = (yield call(
      RtcDetect,
    )) as DetectRTCInterface;

    const { cameraPermission, micPermission } = action.payload;

    const hasMicrophonePermissions = micPermission || (isWebsiteHasMicrophonePermissions ? "granted" : "prompt");
    const hasWebcamPermissions = cameraPermission || (isWebsiteHasWebcamPermissions ? "granted" : "prompt");
    if (!hasWebcam && !hasMicrophone) {
      yield put(actions.checkUserMediaPermissions.success("denied"));
      return;
    }

    if (
      (!hasMicrophone || hasMicrophonePermissions === "granted") &&
      (!hasWebcam || hasWebcamPermissions === "granted")
    ) {
      yield put(actions.checkUserMediaPermissions.success("granted"));
      return;
    }

    if (hasMicrophonePermissions === "granted" && hasWebcamPermissions === "denied") {
      yield put(actions.checkUserMediaPermissions.success("granted"));
      return;
    }
    if (hasMicrophonePermissions === "denied" || hasWebcamPermissions === "denied") {
      yield put(actions.checkUserMediaPermissions.success("denied"));
      return;
    }

    yield put(actions.checkUserMediaPermissions.success("prompt"));
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* askUserMediaPermission(action: ReturnType<typeof actions.askUserMediaPermission>): Generator {
  try {
    const stream = (yield call(callGetUserMedia, action.payload)) as MediaStream;
    stream.getTracks().forEach((track: MediaStreamTrack) => {
      track.stop();
    });
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* breakoutRoomsSaga() {
  yield takeLatest(actions.getUserMedia.request, getUserMedia);
  yield takeLatest(actions.connectSocket.request, connectSocket);
  yield takeLatest(actions.exitRoom.request, exitRoom);
  yield takeLatest(actions.updateRooms.request, updateRooms);
  yield takeLatest(actions.joinMeeting.request, joinMeeting);
  yield takeLatest(actions.pageReload.request, pageReload);
  yield takeLatest(actions.getUserMediaDevices.request, getUserMediaDevices);
  yield takeLatest(actions.updateStreamSettings.request, updateStreamSettings);
  yield takeLatest(actions.updateRoom, updateRoom);
  yield takeLatest(actions.checkUserMediaPermissions.request, checkUserMediaPermissions);
  yield takeLatest(actions.askUserMediaPermission, askUserMediaPermission);
}

export default breakoutRoomsSaga;
