import { ClassRoomSettings } from "containers/Sermons";

export interface GenerateTokenPayload {
  id: number;
  name: string;
  email: string;
  roomCode: string;
  isHost: boolean;
  avatar?: string;
}
export enum ERecordingStatus {
  hasStarted = "hasStarted",
  recording = "recording",
  hasStopped = "hasStopped",
  not_recording = "not_recording",
  not_allowed = "not_allowed",
}

export interface MeetingStateType {
  token: string | null;
  eightToEightUser: GenerateTokenPayload | null;
  classrooms: ClassRoomSettings[];
  current_classroom: ClassRoomSettings | null;
  classroom_is_full: boolean;
  recordingStatus: ERecordingStatus;
  preJoinPage: boolean;
  followMe: boolean;
}

export type ISelectedDevices = {
  [key in "audio" | "video"]?: {
    label?: string;
    deviceId?: string;
  };
};
