import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AlertDialog } from "../AlertDialog";

import { EMemberRestriction } from "containers/Sermons";
import { getMember } from "containers/Auth/store/selectors";
import { getEvent } from "containers/Sermons/store/selectors";
import { deleteMemberRestriction, restrictMember } from "containers/Member/store/actions";

interface Props {
  unbanChatAlertOpen: boolean;
  restrictionType: EMemberRestriction | null;
  member_id: number;
  setRestrictionType: (type: EMemberRestriction | null) => void;
  setUnbanChatAlertOpen: (val: boolean) => void;
}

const MemberRestrictionAlert: React.FC<Props> = ({
  unbanChatAlertOpen,
  setRestrictionType,
  setUnbanChatAlertOpen,
  restrictionType,
  member_id,
}) => {
  const dispatch = useDispatch();

  const currentMember = useSelector(getMember());
  const currentEvent = useSelector(getEvent());

  const confirmRestrictMember = useCallback(() => {
    if (currentEvent && restrictionType && currentMember) {
      dispatch(
        restrictMember({
          member_id: Number(member_id),
          restriction_type: restrictionType,
          event_id: currentEvent?.id,
          created_by: `${currentMember.first_name} ${currentMember.last_name}`,
        }),
      );
    }
    setRestrictionType(null);
  }, [dispatch, currentEvent, member_id, restrictionType, currentMember, setRestrictionType]);

  const confirmDeleteRestriction = useCallback(() => {
    if (currentEvent) {
      dispatch(deleteMemberRestriction(member_id, currentEvent.id));
    }
    setUnbanChatAlertOpen(false);
  }, [dispatch, member_id, currentEvent, setUnbanChatAlertOpen]);

  const cancelHandler = useCallback(() => {
    unbanChatAlertOpen ? setUnbanChatAlertOpen(false) : setRestrictionType(null);
  }, [setRestrictionType, setUnbanChatAlertOpen, unbanChatAlertOpen]);

  return (
    <>
      <AlertDialog
        open={!!restrictionType || unbanChatAlertOpen}
        title={
          unbanChatAlertOpen
            ? "Unban this user from Chat?"
            : restrictionType === EMemberRestriction.chat_ban
            ? "Ban this user from chat"
            : "Timeout this user from chat for 10 minutes"
        }
        message={
          unbanChatAlertOpen
            ? "The user will be able to send messages in the General chat and Direct message on the event again."
            : restrictionType === EMemberRestriction.chat_ban
            ? "If you ban this user from chat, they will not be able to participate in this event's General Chat or Direct Message until a Host unbans them. They will still be able to direct message with any Host or Greeter."
            : "If you timeout this user from chat, they will not be able to participate in this event's General Chat or Direct Message for 10 minutes. They will still be able to direct message with any Host or Greeter."
        }
        onConfirm={unbanChatAlertOpen ? confirmDeleteRestriction : confirmRestrictMember}
        onCancel={cancelHandler}
        mode="confirm"
        confirmText="Yes, Continue"
        cancelText="cancel"
        variant="brown"
      />
    </>
  );
};

export default MemberRestrictionAlert;
