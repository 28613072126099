import React, { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { NamesOfParentRoutes } from "../../../../../constants";
import { EventStatus, IEvent } from "../../../interfaces";
import { TableRenamePopup } from "../../../../BreakoutRooms/components/BreakoutRoom/TableRenamePopup";
import useEditTable from "../../../../BreakoutRooms/hooks/useEditTable";

import { VideoList } from "containers/BreakoutRooms/components/BreakoutRoom/VideoList";
import { VideoMenu } from "containers/BreakoutRooms/components";
import { selectRoomCode, selectRooms, selectStream } from "containers/BreakoutRooms/store/selectors";
import { isMatchedPath } from "containers/Community/utils";
import { IRouteProps, useCurrentUserMember } from "shared";

import "./styles.scss";

const { STREAM, WATCH_PARTY } = NamesOfParentRoutes;

type Props = {
  event: IEvent;
  onExpand?: () => void;
};

const ParticipantsPanel: React.FC<Props> = ({ event, onExpand }) => {
  const { communityCode } = useParams<IRouteProps>();

  const [changeNameOpened, setChangeNameOpened] = useState(false);

  const stream = useSelector(selectStream());
  const rooms = useSelector(selectRooms());
  const currentRoomCode = useSelector(selectRoomCode());
  const history = useHistory();
  const currentUserMember = useCurrentUserMember();

  const { tableName, canEditName } = useEditTable(event);

  const exitCallback = useCallback(() => {
    const isWatchParty = isMatchedPath(history.location.pathname, [
      `${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`,
      `/:communityCode${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`,
    ]);
    if (isWatchParty) {
      history.replace(`${communityCode ? `/${communityCode}` : ""}${STREAM}/${event.code}`);
    }
  }, [communityCode, event, history]);

  const currentRoom = rooms.find(({ code }) => code === currentRoomCode);

  return stream ? (
    <div className="stream-participants">
      <TableRenamePopup
        room={currentRoom || null}
        opened={changeNameOpened}
        onClose={() => {
          setChangeNameOpened(false);
        }}
        memberId={currentUserMember?.id}
      />
      {onExpand && (
        <div className="stream-participants-header">
          <div className="stream-participants-header-table">
            <div>You Joined the Table:</div>
            <div className={classnames("table-name", { "can-edit": canEditName })}>
              <span
                onClick={() => {
                  if (canEditName && currentRoom) {
                    setChangeNameOpened(true);
                  }
                }}
              >
                {tableName}
              </span>
            </div>
          </div>
          <div className="expand" onClick={onExpand}>
            <svg width="11" height="19" fill="none">
              <path
                d="M1 1L9.13209 9.12911L1.0001 17.2581"
                stroke="#93808D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      )}
      <div className="stream-participants-streams">
        <VideoList />
      </div>
      <VideoMenu
        stream={stream}
        exitCallback={exitCallback}
        watchPartyStarted={event?.status === EventStatus.live}
        event={event}
      />
    </div>
  ) : null;
};

export default ParticipantsPanel;
