import * as Yup from "yup";

import { phoneValidator } from "shared/constants/validations";

const requiredValues = {
  first_name: Yup.string().max(35, "First Name field is too long").required("First Name is a required field"),
  last_name: Yup.string().max(35, "Last Name field is too long").required("Last Name is a required field"),
  email: Yup.string()
    .email("Incorrect Email format")
    .max(150, "Email should not exceed 150 characters")
    .required("Email is a required field"),
};

export const requiredValuesSchema = Yup.object({
  ...requiredValues,
});

export const validationSchema = Yup.object({
  phone_number: phoneValidator,
  ...requiredValues,
});
