import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import { pollSchema } from "../../../../Sermons/components/EventContainer/EventPollsDialog/formValidators";
import { IPollTemplate } from "../../../../Sermons";
import { createPollTemplate, updatePollTemplate } from "../../../../Sermons/store/actions";
import { PollCreationContent } from "../../../../Sermons/components/EventContainer/EventPollsDialog/PollCreationContent";
import { preparePollTemplateForm } from "../../../utils";

import { AlertDialog } from "shared";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  pollTemplate: IPollTemplate | null;
  community_id: number;
}

const CreatePollTemplate: React.FC<Props> = ({ open, setOpen, pollTemplate, community_id }) => {
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState<IPollTemplate>(preparePollTemplateForm(community_id));

  const onSubmit = useCallback(
    (values: IPollTemplate) => {
      const preparedData = {
        id: values.id,
        title: values.title,
        poll_question: values.poll_question,
        multiple_answers: values.multiple_answers,
        options: [...values.options],
        show_results: values.show_results,
        community_id,
      };
      if (preparedData.id) {
        dispatch(updatePollTemplate.request({ id: preparedData.id, preparedData }));
      } else {
        dispatch(createPollTemplate.request(preparedData));
      }
      setOpen(false);
    },
    [setOpen, community_id, dispatch],
  );

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (pollTemplate) {
      setFormValues(preparePollTemplateForm(community_id, pollTemplate));
    }
    return () => {
      setFormValues(preparePollTemplateForm(community_id, null));
    };
  }, [pollTemplate, community_id]);

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={pollSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit }) => (
        <AlertDialog
          open={open}
          title={pollTemplate ? "Edit Template" : "New Template"}
          onConfirm={handleSubmit}
          onCancel={onCancel}
          mode="confirm"
          confirmText="Save"
          confirmClassName="Cancel"
          dialogClassName="templates-creationDialog"
          hasForm
        >
          <div className="pollCreation">
            <PollCreationContent />
          </div>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default CreatePollTemplate;
