import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import "./searchSelect.scss";
import { FormikErrors } from "formik";

import { ClickOutsideHook } from "../../hooks";

interface IAdditional {
  title: string;
  text: string;
  onClick: () => void;
}

interface ISearchSelect {
  title: string;
  placeholder?: string;
  items: any[];
  required?: boolean;
  error?: boolean;
  errorText?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  value?: any;
  onSelect: (value: any) => void;
  additional?: IAdditional;
  disableSearch?: boolean;
  className?: string;
}

const SearchSelect = (props: ISearchSelect) => {
  const {
    title,
    disableSearch,
    placeholder,
    items,
    required,
    error,
    errorText,
    value,
    onSelect,
    additional,
    className,
  } = props;
  const wrapperRef = useRef(null);

  const [searchItems, setSearchItems] = useState(items || []);
  const [itemsVisible, setItemsVisible] = useState(false);
  const [selected, setSelected] = useState("");
  const { isOutside } = ClickOutsideHook(wrapperRef);

  const currentValue = useMemo(() => {
    return value ? (value.name ? value.name : `${value.first_name} ${value.last_name}`) : "";
  }, [value]);

  const search = useCallback(
    (val: string) => {
      setSelected(val);
      const valLowerCase = val.toLowerCase();
      const filtered = val
        ? items.filter(i => {
            const item = i.name ? i.name : `${i.first_name} ${i.last_name}`;
            return item.toLowerCase().includes(valLowerCase);
          })
        : items;
      setSearchItems(filtered);
    },
    [items],
  );

  const select = (val: any) => {
    setSelected(val.name || `${value.first_name} ${value.last_name}`);
    onSelect(val);
    setItemsVisible(false);
  };

  const onAdditionalClick = () => {
    additional && additional.onClick();
    setItemsVisible(false);
  };

  const close = useCallback(() => {
    setItemsVisible(false);
    setSelected(currentValue);
  }, [currentValue]);

  const open = () => {
    setItemsVisible(true);
    search("");
  };

  useEffect(() => {
    setSelected(currentValue);
  }, [currentValue]);

  useEffect(() => {
    if (isOutside) {
      close();
    }
  }, [close, isOutside, itemsVisible]);

  return (
    <div className={`searchSelect ${className}`} ref={wrapperRef}>
      <p className="searchSelect-title">
        {title} {required && <span className="searchSelect-required">*</span>}
      </p>
      <div className="searchSelect-inputWrap">
        <input
          className={`searchSelect-inputWrap-input ${error && "searchSelect-inputWrap-errorBorder"}`}
          onFocus={open}
          onChange={event => !disableSearch && search(event.target.value)}
          type="text"
          placeholder={placeholder || "Start typing"}
          value={selected || ""}
        />
        {itemsVisible && <span onClick={close} className="searchSelect-inputWrap-arrow arrows arrows-top" />}
        {!itemsVisible && <span onClick={open} className="searchSelect-inputWrap-arrow arrows arrows-bottom" />}
      </div>
      {itemsVisible && (
        <div className="searchSelect-itemsContainer">
          {searchItems &&
            searchItems.map(
              item =>
                item && (
                  <p className="searchSelect-itemsContainer-item" onClick={() => select(item)} key={item.id}>
                    {item.name ? item.name : `${item.first_name} ${item.last_name}`}
                  </p>
                ),
            )}
          {additional && (
            <div className={"searchSelect-additional"}>
              <h3 className={"searchSelect-additional-title"}>{additional.title}</h3>
              <button className={"searchSelect-additional-text"} onClick={onAdditionalClick}>
                {additional.text}
              </button>
            </div>
          )}
        </div>
      )}
      {error && <p className="searchSelect-error">{errorText}</p>}
    </div>
  );
};

export default SearchSelect;
