import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MessageList, MessageListProps, useChatContext, VirtualizedMessageList } from "stream-chat-react";

import { getEvent } from "../../../Sermons/store/selectors";
import { DiscussionTheme } from "../../containers/DiscussionsContainer/DiscussionsContainer";
import { CustomMessage } from "../CustomMessage";
import { CustomTypingIndicator } from "../CustomTypingIndicator";
import { EditMessageObj } from "../../interfaces";

import { customizeGetstreamChannel } from "shared/utils/getStream";
import {
  clearSelectedChannel,
  getSelectedChannelDetails,
  addChannelModerators,
} from "containers/Discussions/store/actions";
import { Loader } from "shared/components/Loader";
import { selectors } from "containers/Discussions/store";
import { authentificated, getMember as userMember } from "containers/Auth/store/selectors";
import { useMemberCID } from "shared/hooks/MemberCIDHook";
import { canShowChannel } from "containers/Discussions/utils";
import { checkRoles } from "utils/ACL";
import { ERoles } from "containers/Auth/interfaces";
import { checkMeetingRole } from "containers/Sermons/utils";
import { SermonRoles } from "containers/Sermons/interfaces";

interface CustomMessageListProp extends MessageListProps {
  setEditMessageDetails: (obj: EditMessageObj) => void;
  theme?: DiscussionTheme;
  generalChannel?: boolean;
  reloadChannel?: () => void;
}

const CustomMessageList: React.FC<CustomMessageListProp> = props => {
  const { setEditMessageDetails, reloadChannel, theme, generalChannel } = props;
  const { channel, client } = useChatContext();
  const dispatch = useDispatch();
  const userChannels = useSelector(selectors.getChannels());
  const cid = useMemberCID();
  const member = useSelector(userMember());
  const activeChannel = useSelector(selectors.getActiveChannel());
  const selectedChannel = useSelector(selectors.getSelectedChannelDetails());
  const showChannelList = useSelector(selectors.getShowChatList());
  const currentEvent = useSelector(getEvent());
  const isAuthenticated = useSelector(authentificated());

  const isModerator = !!(channel?.state?.members[String(member?.id)] as { is_moderator?: boolean })?.is_moderator;
  const canModerate = useMemo(() => {
    const isAdmin = checkRoles([ERoles.admin, ERoles.manager], currentEvent?.community_id);
    const isGreeterHost =
      !!currentEvent &&
      member &&
      checkMeetingRole(currentEvent, member, [SermonRoles.greeter, SermonRoles.host, SermonRoles.greeterHost]);
    return isAdmin || isGreeterHost;
  }, [currentEvent, member]);

  useEffect(() => {
    if (isAuthenticated && currentEvent && channel?.id && member && !isModerator && canModerate) {
      dispatch(
        addChannelModerators.request({
          meeting_id: currentEvent.id,
          channel_id: channel.id,
          memberIds: [String(member.id)],
          callback: reloadChannel,
        }),
      );
    }
  }, [channel, isModerator, dispatch, isAuthenticated, currentEvent, member, canModerate, reloadChannel]);

  useEffect(() => {
    if (!channel?.id || generalChannel) {
      return;
    }
    const activeChatChannel = userChannels.find(ch => ch.id === channel?.id);
    if (activeChatChannel && channel && (!showChannelList || !currentEvent)) {
      canShowChannel(channel, member, activeChannel, cid) && dispatch(getSelectedChannelDetails.request(channel.id));
      return;
    }
    dispatch(clearSelectedChannel());
  }, [dispatch, userChannels, generalChannel, channel, activeChannel, cid, member, showChannelList, currentEvent]);

  if (generalChannel) {
    return (
      <>
        <VirtualizedMessageList
          disableDateSeparator={false}
          hideNewMessageSeparator
          hideDeletedMessages
          Message={props => {
            return (
              <CustomMessage
                theme={theme}
                setEditMessageDetails={setEditMessageDetails}
                isModerator={isModerator}
                generalChannel
                {...props}
              />
            );
          }}
        />
        {!!client && !!channel && <CustomTypingIndicator />}
      </>
    );
  }
  if (channel && selectedChannel) {
    const { isInterlocutorLeftChannel } = customizeGetstreamChannel(channel, selectedChannel);
    if (selectedChannel.id === channel.id) {
      return (
        <>
          <MessageList
            hideDeletedMessages
            hideNewMessageSeparator
            Message={props => {
              return (
                <CustomMessage
                  theme={theme}
                  setEditMessageDetails={setEditMessageDetails}
                  isModerator={isModerator}
                  {...props}
                />
              );
            }}
          />
          {!!client && !!channel && <CustomTypingIndicator isInterlocutorLeftChannel={isInterlocutorLeftChannel()} />}
        </>
      );
    } else {
      return (
        <div className="str-chat__list">
          <Loader />
        </div>
      );
    }
  } else {
    return null;
  }
};

export default CustomMessageList;
