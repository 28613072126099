import React, { useMemo } from "react";
import { Radio, RadioProps } from "@material-ui/core";
import classnames from "classnames";

import { EEventStreamType, EEventVideoType } from "containers/Sermons/interfaces";

import "./styles.scss";

interface Props extends RadioProps {
  streamType: EEventStreamType;
  hasPaidSubscription: boolean;
  included: boolean;
  videoType: EEventVideoType | null;
}

const StreamProviderCard: React.FC<Props> = props => {
  const { streamType, hasPaidSubscription, included, checked, videoType, ...rest } = props;

  const streamProviderTypeClass = useMemo(
    () =>
      streamType === EEventStreamType.YOUTUBE && videoType === EEventVideoType.live ? `${streamType}_live` : streamType,
    [streamType, videoType],
  );

  return (
    <div className={classnames("stream-provider-card", streamProviderTypeClass, { checked })}>
      {streamType === EEventStreamType.CUSTOM && <span>Custom Embed Code</span>}
      {[EEventStreamType.ALTAR, EEventStreamType.ALTAR_LIVE, EEventStreamType.VIMEO_LIVE].includes(streamType) && (
        <>
          <div className="new-label">NEW</div>
          {hasPaidSubscription && streamType !== EEventStreamType.VIMEO_LIVE && (
            <div className="description">
              {included ? "Included with your plan" : "Contact Altar Support to Activate"}
            </div>
          )}
        </>
      )}
      <Radio className="radio-option" {...rest} />
    </div>
  );
};

export default StreamProviderCard;
