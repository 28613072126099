export enum StripeActionTypes {
  LOAD_REQUEST = "@@STRIPE/LOAD",
  LOAD_SUCCESS = "@@STRIPE/LOAD_SUCCESS",
  LOAD_FAIL = "@@STRIPE/LOAD_FAIL",
  LOAD_CLEAR = "@@STRIPE/LOAD_CLEAR",

  REDIRECT_TO_CHECKOUT_REQUEST = "@@STRIPE/REDIRECT_TO_CHECKOUT",
  REDIRECT_TO_CHECKOUT_SUCCESS = "@@STRIPE/REDIRECT_TO_CHECKOUT_SUCCESS",
  REDIRECT_TO_CHECKOUT_FAILURE = "@@STRIPE/REDIRECT_TO_CHECKOUT_FAILURE",

  ADD_PAYMENT_METHOD = "@@STRIPE/ADD_PAYMENT_METHOD",
  ADD_PAYMENT_METHOD_SUCCESS = "@@STRIPE/ADD_PAYMENT_METHOD_SUCCESS",
  ADD_PAYMENT_METHOD_FAILURE = "@@STRIPE/ADD_PAYMENT_METHOD_FAILURE",
}
