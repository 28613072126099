import { createMuiTheme } from "@material-ui/core";

import manifestTheme from "../styles/variables.module.scss";

export const authSubmitButtonTheme = createMuiTheme({
  overrides: {
    MuiButtonBase: {
      root: {
        marginTop: "32px",
        background: "linear-gradient(83.52deg, #0C0C0C 0%, #3A3A3A 100%)",
        color: manifestTheme.whiteColor,
      },
    },
    MuiButton: {
      label: {
        color: manifestTheme.whiteColor,
      },
    },
  },
});
