import { uniqueNamesGenerator, Config, NumberDictionary } from "unique-names-generator";

const numberDictionary = NumberDictionary.generate({ length: 4 }).map(n =>
  n.replace(/66/g, `5${Math.floor(Math.random() * 10)}`),
);

const config: Config = {
  dictionaries: [["Visitor"], numberDictionary],
  separator: " ",
  length: 2,
  style: "capital",
};

export const generateName = () => uniqueNamesGenerator(config);
