import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import classnames from "classnames";

import { NamesOfParentRoutes } from "../../../../constants";
import ParticipantsPanel from "../../components/EventContainer/ParticipantsPanel";
import { actions as eventActions } from "../../store";
import { actions } from "../../../BreakoutRooms/store";

import { EAuditoriumRoomsType, EventCountDown, EventMembersCounter, EventStatus } from "containers/Sermons";
import {
  getAllowEnded,
  getEvent,
  getChangingStatus,
  getEventStatus,
  getEventSettings,
} from "containers/Sermons/store/selectors";
import { EventEnded } from "containers/Sermons/components/EventContainer/EventEnded";
import { VideoStream } from "containers/Sermons/components/VideoStream";
import { EventSeats, TableListContainer } from "containers/BreakoutRooms/containers";
import {
  getMediaPermissions,
  getUserDevices,
  getUserMediaError,
  selectStream,
} from "containers/BreakoutRooms/store/selectors";
import usePrevious from "shared/hooks/PreviousHook/PreviousHook";
import { peerConnection } from "containers/BreakoutRooms/utils/peerConnection";
import { useRoomConnect } from "containers/BreakoutRooms/hooks/useRoomConnect";
import { exitRoom } from "containers/BreakoutRooms/store/actions";
import { isMatchedPath } from "containers/Community/utils";
import { getBrowserInfo } from "shared/stores/browser/selectors";
import { IRouteProps, PERMISSIONS_FLOW_BROWSERS } from "shared";
import { FIREBASE_EVENTS } from "shared/interfaces/Firebase";
import { handleFirebaseEvent } from "utils/firebase";

import "./index.scss";

const { STREAM, WATCH_PARTY } = NamesOfParentRoutes;

const VideoStreamContainer: React.FC = () => {
  const [trackedRoomCode, setTrackedRoomCode] = useState("");

  const event = useSelector(getEvent());
  const eventStatus = useSelector(getEventStatus());
  const allowEnded = useSelector(getAllowEnded());
  const changingStatus = useSelector(getChangingStatus());
  const stream = useSelector(selectStream());
  const userMediaError = useSelector(getUserMediaError());
  const browserInfo = useSelector(getBrowserInfo());
  const mediaPermissions = useSelector(getMediaPermissions());
  const { isAuthenticated, roomCode, isEnded, rooms, constraints } = useRoomConnect();
  const { activeDashboardTab } = useSelector(getEventSettings());
  const userDevices = useSelector(getUserDevices());

  const { eventCode, communityCode } = useParams<IRouteProps>();

  const history = useHistory();
  const dispatch = useDispatch();

  const isWatchPartyPath = isMatchedPath(history.location.pathname, [
    `${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`,
    `/:communityCode${STREAM}/:eventCode${WATCH_PARTY}/:roomCode`,
  ]);

  const auditoriumRoomsList = useMemo(() => {
    switch (event?.auditorium_rooms_type) {
      case EAuditoriumRoomsType.row:
        return <EventSeats />;
      case EAuditoriumRoomsType.table:
        return <TableListContainer isAuditorium />;
      case EAuditoriumRoomsType.none:
        return <EventMembersCounter />;
    }
  }, [event?.auditorium_rooms_type]);

  useEffect(() => {
    if (roomCode) {
      history.push(`${communityCode ? `/${communityCode}` : ""}${STREAM}/${eventCode}${WATCH_PARTY}/${roomCode}`);
    }
  }, [roomCode, history, eventCode, communityCode]);

  useEffect(() => {
    if (isWatchPartyPath && userMediaError && event) {
      dispatch(
        exitRoom.request({
          callback: () => history.replace(`${NamesOfParentRoutes.STREAM}/${event.code}`),
        }),
      );
    }
  }, [userMediaError, history, event, dispatch, isWatchPartyPath]);

  useEffect(() => {
    if (isWatchPartyPath) {
      if (isAuthenticated) {
        peerConnection.updateSocketToken();
      } else if (event) {
        history.push(`${NamesOfParentRoutes.STREAM}/${event.code}`);
      }
    }
  }, [isAuthenticated, history, event, isWatchPartyPath]);

  useEffect(() => {
    if (roomCode && stream === null && !isEnded && isAuthenticated && userDevices) {
      if (PERMISSIONS_FLOW_BROWSERS.includes(browserInfo.name)) {
        if (event && (mediaPermissions === "denied" || mediaPermissions === "prompt")) {
          dispatch(
            exitRoom.request({
              callback: () => {
                history.replace(`${NamesOfParentRoutes.STREAM}/${event.code}`);
              },
            }),
          );
        } else if (mediaPermissions === "granted") {
          dispatch(actions.setRoomCode(roomCode));
          dispatch(actions.getUserMedia.request(constraints));
        }
      } else {
        dispatch(actions.setRoomCode(roomCode));
        dispatch(actions.getUserMedia.request(constraints));
      }
    }
  }, [
    dispatch,
    roomCode,
    isEnded,
    stream,
    isAuthenticated,
    isWatchPartyPath,
    browserInfo,
    event,
    mediaPermissions,
    constraints,
    history,
    userDevices,
  ]);

  const [prevEventStatus, setPrevEventStatus] = useState<EventStatus | null>(null);

  const prev = usePrevious(eventStatus);

  useEffect(() => {
    if (!!prev && !!eventStatus) {
      setPrevEventStatus(prev);
    }
    // eslint-disable-next-line
  }, [eventStatus]);

  useEffect(() => {
    dispatch(
      eventActions.updateEventSettings({
        showTables: true,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (isWatchPartyPath && stream && roomCode && roomCode !== trackedRoomCode && rooms) {
      const currentBenchIndex = rooms.findIndex(({ code }) => code === roomCode);
      if (currentBenchIndex > -1) {
        handleFirebaseEvent(FIREBASE_EVENTS.JOIN_EVENT_ROW, {
          row_id: rooms[currentBenchIndex].id,
          row_name: rooms[currentBenchIndex].name || String(currentBenchIndex + 1).padStart(2, "0"),
          event_id: rooms[currentBenchIndex].meeting_id,
        });
        setTrackedRoomCode(roomCode);
      }
    }
  }, [isWatchPartyPath, roomCode, rooms, stream, trackedRoomCode]);

  const showStream =
    eventStatus === EventStatus.live ||
    (eventStatus === EventStatus.lobby && prevEventStatus !== EventStatus.scheduled && changingStatus) ||
    (eventStatus === EventStatus.ended && prevEventStatus !== EventStatus.scheduled && allowEnded);

  return (
    event && (
      <>
        {stream && <ParticipantsPanel event={event} />}
        <div className={classnames("stream-container")}>
          <div
            className={classnames("stream-content", {
              small: !!activeDashboardTab && !!stream,
              medium: !!activeDashboardTab || !!stream,
              "without-rooms": event?.auditorium_rooms_type === EAuditoriumRoomsType.none,
            })}
          >
            {eventStatus !== EventStatus.ended || allowEnded ? (
              <>
                {showStream ? (
                  <VideoStream event={event} />
                ) : (
                  <EventCountDown event={event} setTimeHasCome={() => {}} />
                )}
                <div className="stream-content-rooms">{auditoriumRoomsList}</div>
              </>
            ) : (
              <EventEnded event={event} />
            )}
          </div>
        </div>
      </>
    )
  );
};
export default VideoStreamContainer;
