import React from "react";
import { Formik } from "formik";

import { validateSchema } from "../../../EventContainer/EventAnnouncementsDialog/EventAnnouncementForm/formValidators";
import { EventAnnouncementMainForm } from "../../../EventContainer/EventAnnouncementsDialog/EventAnnouncementMainForm";

import { IAnnouncementInteraction } from "containers/Sermons";
import { AlertDialog } from "shared";

interface IProps {
  interaction: IAnnouncementInteraction;
  openInteraction: boolean;
  onCancel: () => void;
  submitInteraction: (values: IAnnouncementInteraction) => void;
}

const CreateAnnouncementInteraction: React.FC<IProps> = ({
  interaction,
  openInteraction,
  onCancel,
  submitInteraction,
}) => {
  return (
    <Formik
      initialValues={interaction}
      enableReinitialize
      onSubmit={submitInteraction}
      validationSchema={validateSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit }) => (
        <AlertDialog
          open={openInteraction}
          title={interaction.id ? "Edit Announcement" : "New Announcement"}
          onConfirm={handleSubmit}
          onCancel={onCancel}
          mode="confirm"
          confirmText="Save"
          confirmClassName="Cancel"
          dialogClassName="createInteraction"
          hasForm
        >
          <div className="announcementCreation-template">
            <EventAnnouncementMainForm />
          </div>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default CreateAnnouncementInteraction;
