import React, { useState, useMemo, useEffect } from "react";
import classNames from "classnames";

import { SocialButtons } from "../SocialButtons";
import { PrivateEventBadge } from "../PrivateEventBadge";
import { KEYNOTE_SPEAKERS, MAIN_SPEAKERS } from "../../EcammLandingPage/constants";

import {
  getEventTimeRange,
  getFormattedDate,
  getRemainingTime,
  IEvent,
  IEventVideoSettings,
  SermonRestriction,
} from "containers/Sermons";
import CountdownTimer from "containers/Sermons/components/CountdownTimer";
import { VideoStream } from "containers/Sermons/components/VideoStream";
import { defaultVideoSettings } from "containers/Sermons/constants";
import default_image_landing from "assets/images/default_image_landing.png";
import down_corner_orange from "assets/icons/down_corner_orange.svg";

import "./index.scss";

interface UpcomingEventProps {
  event: IEvent;
  navigateToEvent: (code: string) => void;
  isEcammLanding: boolean;
}

const videoSettings: IEventVideoSettings = { ...defaultVideoSettings, controls: false, volume: 0 };

export default function UpcomingEvent({ event, navigateToEvent, isEcammLanding }: UpcomingEventProps) {
  const [remainingTime, setRemainingTime] = useState(getRemainingTime(event));
  const [initTime, setInitTime] = useState(getRemainingTime(event));
  const [moreInfo, setMoreInfo] = useState(false);
  const remainingHours = useMemo(() => {
    return Math.floor(remainingTime / 1000 / 3600);
  }, [remainingTime]);

  const eventIsStarted = useMemo(() => remainingTime <= 0 || !!event.was_started, [event, remainingTime]);

  const ecammEventSpeakers = useMemo(() => {
    return [...MAIN_SPEAKERS, ...KEYNOTE_SPEAKERS].filter(s => s.event_names.some(name => name.includes(event.name)));
  }, [event.name]);

  useEffect(() => {
    setInitTime(getRemainingTime(event));
    setRemainingTime(getRemainingTime(event));
  }, [event]);

  const renderCountDown = useMemo(() => {
    return (
      <div className="count-down">
        event starts in
        <CountdownTimer
          initTime={initTime}
          reinitOnChange={true}
          onTimeHasCome={() => {}}
          onTimeChange={setRemainingTime}
          showHours={remainingTime > 0 && remainingHours > 0}
          showSeconds={remainingTime > 0 && remainingHours === 0}
        />
        {remainingHours < 24 && remainingHours > 0 ? "hours" : ""}
        {remainingTime > 0 && remainingHours === 0 ? <button>Join event!</button> : null}
      </div>
    );
  }, [initTime, remainingHours, remainingTime]);

  return (
    <div
      className="upcoming-event-component-wrapper"
      onClick={() => {
        navigateToEvent(event.code || "");
      }}
    >
      <div className="upcoming-event-wrapper">
        {eventIsStarted ? (
          <div className="live-block">
            <div className="badge live">Live</div>
            <div className="button-wrapper">
              <button>Watch Now!</button>
            </div>
          </div>
        ) : null}
        {eventIsStarted ? (
          <VideoStream event={event} settings={videoSettings} />
        ) : (
          <>
            <img
              src={event.image_url || default_image_landing}
              className={classNames({ "default-image": !event.image_url })}
              alt={event.name}
            />
            <div className="event-count-down">{renderCountDown}</div>
          </>
        )}
      </div>
      <div className="event-information-wrapper">
        <div className="event-date-wrapper">
          <div className="date-start">
            {`${getFormattedDate(new Date(event.starting_at))}, ${getEventTimeRange(event)}`}
            {event.restriction !== "all" ? (
              <PrivateEventBadge
                text={event.restriction === SermonRestriction.invited ? "INVITE ONLY" : "MEMBERS ONLY"}
              />
            ) : null}
          </div>
        </div>
        <div className="event-title-wrapper">{event.name}</div>
        <div className="event-description-wrapper">
          <div className="event-description-text">{event.subject}</div>
        </div>
        {isEcammLanding ? (
          <div
            className={`event-speakers-wrapper ${moreInfo && "active"}`}
            onClick={e => {
              e.stopPropagation();
              setMoreInfo(false);
            }}
          >
            {ecammEventSpeakers.map(s => (
              <div className="speaker" key={s.name}>
                <div className="speaker-info">
                  <div className="speaker-info-name">
                    {s.name}
                    <div className="speaker-info-name-title">{s.title}</div>
                  </div>
                  <div className="speaker-info-description">{s.description}</div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
        <div className="event-footer" onClick={e => e.stopPropagation()}>
          {isEcammLanding && ecammEventSpeakers.length ? (
            <div
              onClick={() => setMoreInfo(prevState => !prevState)}
              className={`event-footer-moreInfo ${moreInfo ? "active" : ""}`}
            >
              MORE INFO <img src={down_corner_orange} alt="arrow_pic" />
            </div>
          ) : null}
          <SocialButtons event={event} />
        </div>
      </div>
    </div>
  );
}
