import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { Button } from "../Button";
import { CanView } from "../CanView";

import ic_search from "assets/icons/ic_search.svg";

import "./searchInput.scss";

interface ISearchInput {
  onChange(value: string): void;

  defaultValue?: string;
  className?: string;
  placeholder?: string;
  collapsable?: boolean;
}

const SearchInput = (props: ISearchInput) => {
  const { defaultValue, onChange, className = "defaultSearchInput", placeholder, collapsable } = props;
  const [value, setValue] = useState("");
  const [showInput, setShowInput] = useState<boolean>(!collapsable);

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  return (
    <>
      <CanView condition={showInput}>
        <div className={classNames(`searchInput ${className}`, collapsable ? "collapsable" : "")}>
          <input
            placeholder={placeholder || "Search"}
            onChange={e => setValue(e.target.value)}
            value={defaultValue ? defaultValue : value}
            type="text"
            className={`searchInput-input ${className}-input`}
            onKeyPress={e => e.key === "Enter" && e.preventDefault()}
          />
          {value && (
            <p
              className={`searchInput-clear editIcons editIcons-delete ${className}-clear`}
              onClick={() => setValue("")}
            />
          )}
        </div>
      </CanView>
      <CanView condition={!!collapsable}>
        <div className="searchInput-show">
          <Button variant="gray-text" onClick={() => setShowInput(!showInput)}>
            <img src={ic_search} alt="" />
          </Button>
        </div>
      </CanView>
    </>
  );
};

export default SearchInput;
