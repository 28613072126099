import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Yup from "yup";
import { Formik } from "formik";
import { Dispatch } from "redux";
import { withRouter } from "react-router-dom";

import { actions } from "../../store";
import { loginLoading, authError } from "../../store/selectors";
import { ResetContainerType, ResetShape } from "../../interfaces";
import { AltarStore } from "../../../../shared/types";
import { Reset } from "../../components";

import { ResponseError } from "shared";
import { searchParamsToObject } from "shared/utils/searchParams";
import { passwordValidator } from "shared/constants/validations";

const validationSchema = Yup.object<ResetShape>().shape({
  password: passwordValidator.required("Password is a required field"),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "The passwords you entered do not match")
    .required("Confirm Password is a required field"),
});

interface ResetContainerProps {
  loginLoading: boolean;
  error?: ResponseError | null;
}

class ResetContainer extends Component<ResetContainerType, ResetShape> {
  constructor(props: ResetContainerType) {
    super(props);
    this.state = {
      password: "",
      repeatPassword: "",
    };
  }

  componentDidMount() {
    const { resetErrorMessage } = this.props;
    resetErrorMessage();
  }

  handleSubmit = (values: ResetShape) => {
    const { reset } = this.props;
    const { password } = values;
    const { token } = this.props.match.params as { token: string };
    const { redirectTo } = searchParamsToObject(this.props.location.search);
    reset({ token, password, redirectTo });
  };

  render() {
    const { loginLoading, error } = this.props;
    const { password, repeatPassword } = this.state;
    const initValues = { password, repeatPassword };
    return (
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values);
          setSubmitting(false);
        }}
        initialValues={initValues}
        validateOnChange={false}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <Reset
              loading={loginLoading || isSubmitting}
              values={values}
              errors={errors}
              touched={touched}
              onChange={handleChange}
              onBlur={handleBlur}
              onSubmit={handleSubmit}
              errorMessage={error?.message}
            />
          );
        }}
      </Formik>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetErrorMessage: () => dispatch(actions.setError(null)),
  reset: (payload: ResetShape) => dispatch(actions.reset.request(payload)),
});

const mapStateToProps = createStructuredSelector<AltarStore, ResetContainerProps>({
  loginLoading: loginLoading(),
  error: authError(),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetContainer));
