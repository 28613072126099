export enum OnboardingActionTypes {
  CHECK_INVITATION_LINK_REQUEST = "@@ONBOARDING/CHECK_INVITATION_LINK_REQUEST",
  CHECK_INVITATION_LINK_SUCCESS = "@@ONBOARDING/CHECK_INVITATION_LINK_SUCCESS",
  CHECK_INVITATION_LINK_FAILURE = "@@ONBOARDING/CHECK_INVITATION_LINK_FAILURE",

  CHECK_EMAIL_REQUEST = "@@ONBOARDING/CHECK_EMAIL_REQUEST",
  CHECK_EMAIL_SUCCESS = "@@ONBOARDING/CHECK_EMAIL_SUCCESS",
  CHECK_EMAIL_FAILURE = "@@ONBOARDING/CHECK_EMAIL_FAILURE",

  SEND_USER_CONFIRMATION_EMAIL_REQUEST = "@@ONBOARDING/SEND_USER_CONFIRMATION_EMAIL_REQUEST",
  SEND_USER_CONFIRMATION_EMAIL_SUCCESS = "@@ONBOARDING/SEND_USER_CONFIRMATION_EMAIL_SUCCESS",
  SEND_USER_CONFIRMATION_EMAIL_FAILURE = "@@ONBOARDING/SEND_USER_CONFIRMATION_EMAIL_FAILURE",

  CHECK_EMAIL_CONFIRMATION_REQUEST = "@@ONBOARDING/CHECK_EMAIL_CONFIRMATION_REQUEST",
  CHECK_EMAIL_CONFIRMATION_SUCCESS = "@@ONBOARDING/CHECK_EMAIL_CONFIRMATION_SUCCESS",
  CHECK_EMAIL_CONFIRMATION_FAILURE = "@@ONBOARDING/CHECK_EMAIL_CONFIRMATION_FAILURE",

  CHECK_EMAIL_IS_CONFIRMED_REQUEST = "@@ONBOARDING/CHECK_EMAIL_IS_CONFIRMED_REQUEST",
  CHECK_EMAIL_IS_CONFIRMED_SUCCESS = "@@ONBOARDING/CHECK_EMAIL_IS_CONFIRMED_SUCCESS",
  CHECK_EMAIL_IS_CONFIRMED_FAILURE = "@@ONBOARDING/CHECK_EMAIL_IS_CONFIRMED_FAILURE",

  CHECK_USER_IS_VERIFIED_REQUEST = "@@ONBOARDING/CHECK_USER_IS_VERIFIED_REQUEST",
  CHECK_USER_IS_VERIFIED_SUCCESS = "@@ONBOARDING/CHECK_USER_IS_VERIFIED_SUCCESS",
  CHECK_USER_IS_VERIFIED_FAILURE = "@@ONBOARDING/CHECK_USER_IS_VERIFIED_FAILURE",

  CREATE_PROFILE_REQUEST = "@@ONBOARDING/CREATE_PROFILE_REQUEST",
  CREATE_PROFILE_SUCCESS = "@@ONBOARDING/CREATE_PROFILE_SUCCESS",
  CREATE_PROFILE_FAILURE = "@@ONBOARDING/CREATE_PROFILE_FAILURE",

  REGISTER_MEMBER_REQUEST = "@@ONBOARDING/REGISTER_MEMBER_REQUEST",
  REGISTER_MEMBER_SUCCESS = "@@ONBOARDING/REGISTER_MEMBER_SUCCESS",
  REGISTER_MEMBER_FAILURE = "@@ONBOARDING/REGISTER_MEMBER_FAILURE",

  CREATE_USER_REQUEST = "@@ONBOARDING/CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS = "@@ONBOARDING/CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE = "@@ONBOARDING/CREATE_USER_FAILURE",

  UPDATE_USER_REQUEST = "@@ONBOARDING/UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS = "@@ONBOARDING/UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE = "@@ONBOARDING/UPDATE_USER_FAILURE",

  CREATE_COMMUNITY_REQUEST = "@@ONBOARDING/CREATE_COMMUNITY_REQUEST",
  CREATE_COMMUNITY_SUCCESS = "@@ONBOARDING/CREATE_COMMUNITY_SUCCESS",
  CREATE_COMMUNITY_FAILURE = "@@ONBOARDING/CREATE_COMMUNITY_FAILURE",

  UPDATE_COMMUNITY_REQUEST = "@@ONBOARDING/UPDATE_COMMUNITY_REQUEST",
  UPDATE_COMMUNITY_SUCCESS = "@@ONBOARDING/UPDATE_COMMUNITY_SUCCESS",
  UPDATE_COMMUNITY_FAILURE = "@@ONBOARDING/UPDATE_COMMUNITY_FAILURE",

  OPEN_COMMUNITY_IN_APP_REQUEST = "@@ONBOARDING/OPEN_COMMUNITY_IN_APP_REQUEST",
  OPEN_COMMUNITY_IN_APP_SUCCESS = "@@ONBOARDING/OPEN_COMMUNITY_IN_APP_SUCCESS",
  OPEN_COMMUNITY_IN_APP_FAILURE = "@@ONBOARDING/OPEN_COMMUNITY_IN_APP_FAILURE",

  GET_ONBOARDING_COMMUNITY_REQUEST = "@@ONBOARDING/GET_ONBOARDING_COMMUNITY_REQUEST",
  GET_ONBOARDING_COMMUNITY_SUCCESS = "@@ONBOARDING/GET_ONBOARDING_COMMUNITY_SUCCESS",
  GET_ONBOARDING_COMMUNITY_FAILURE = "@@ONBOARDING/GET_ONBOARDING_COMMUNITY_FAILURE",

  CLEAR_ONBOARDING = "@@ONBOARDING/CLEAR_ONBOARDING",

  SET_USER = "@@ONBOARDING/SET_USER",
}
