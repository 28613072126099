import { createSelector } from "reselect";

import { AltarStore } from "shared/types";

const selectNotifications = (state: AltarStore) => state.notifications;

export const getNotification = () => createSelector(selectNotifications, state => state.snackbars);
export const getNotificationAnnouncement = () => createSelector(selectNotifications, state => state.notification);
export const getNotificationAnnouncementPopup = () => createSelector(selectNotifications, state => state.popup);

export const getChatNotificationSettings = () =>
  createSelector(selectNotifications, state => state.chatNotificationSettings);
