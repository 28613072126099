import { useCallback, useEffect, useState } from "react";
import * as Client from "stream-chat";
import { Event, StreamChat } from "stream-chat";
import { useDispatch, useSelector } from "react-redux";

import { MESSAGE_UPDATE_EVENTS } from "../../../containers/Discussions/constants";
import { getEvent, getEventSettings } from "../../../containers/Sermons/store/selectors";
import { useMemberCID } from "../MemberCIDHook";
import { pushNotification } from "../../utils";
import { EventDashboardTabs, EventType } from "../../../containers/Sermons";
import { DiscussionTab } from "../../components/Event/EventSideDialog/EventSideDialogHeader/EventSideDialogDiscussionTabs";
import { updateEventSettings } from "../../../containers/Sermons/store/actions";
import { getChatNotificationSettings } from "../../../containers/Notifications/store/selectors";

import { getPreJoinPage } from "containers/Meeting/store/selectors";
import { getChannelUnreadMessagesCount } from "shared/utils/getStream";
import { authentificated, getMember } from "containers/Auth/store/selectors";
import { getCommunity } from "containers/Community/store/selectors";
import { getActiveChannel, getEventChannelIds, showGreeterCustomChannel } from "containers/Discussions/store/selectors";
import {
  clearSelectedChannel,
  clearUnreadMessagesCount,
  getAnonymousChannels,
  getChannels,
  setActiveChannel,
  setUnreadMessagesCount,
  showChatList,
} from "containers/Discussions/store/actions";
import { IChannelEventTypes, IChannelTypes, IUnreadMessages } from "containers/Discussions/interfaces";
import notification from "assets/sounds/notification.mp3";

const audio = new Audio(notification);

const useChatHandler = (client?: StreamChat) => {
  const [notifications, setNotifications] = useState<IUnreadMessages>({
    personalUnread: 0,
    eventGeneralUnread: 0,
  });
  const [generalChannel, setGeneralChannel] = useState<Client.Channel>();
  const dispatch = useDispatch();

  const cid = useMemberCID();

  const channelsIds = useSelector(getEventChannelIds());
  const community = useSelector(getCommunity());
  const isAuthenticated = useSelector(authentificated());
  const isGreeterCustomChannel = useSelector(showGreeterCustomChannel());
  const currentEvent = useSelector(getEvent());
  const currentMember = useSelector(getMember());
  const activeChannel = useSelector(getActiveChannel());
  const { activeDashboardTab, selectedTab } = useSelector(getEventSettings());
  const { notifyBanner, notifySound } = useSelector(getChatNotificationSettings());
  const preJoinPage = useSelector(getPreJoinPage());

  const setUnreadCount = useCallback(() => {
    const activeChannels = !!client && client.activeChannels;
    const unreadStart = !!currentEvent && isGreeterCustomChannel ? 1 : 0;
    if (client && client.user && !!activeChannels) {
      const userGetStreamChannels: Client.Channel[] = Object.values(activeChannels);

      const personalUnread = userGetStreamChannels.reduce((unreadCount, channel) => {
        if (channelsIds) {
          return channel.id && channelsIds.has(channel.id) && channel.initialized
            ? unreadCount + getChannelUnreadMessagesCount(channel)
            : unreadCount;
        }
        return unreadCount;
      }, unreadStart);
      dispatch(setUnreadMessagesCount({ personalUnread }));
      setNotifications(prev => ({ ...prev, personalUnread }));
    }
  }, [client, dispatch, channelsIds, isGreeterCustomChannel, currentEvent]);

  const eventHandler = useCallback(
    async (event: Event) => {
      if (
        currentEvent &&
        event?.type === IChannelEventTypes.messageNewEvent &&
        event.channel_id &&
        (channelsIds?.has(event.channel_id) || generalChannel?.id === event.channel_id)
      ) {
        try {
          const eventUser = event?.user;
          const greeter = currentEvent.greeters.find(greeter => eventUser?.id === greeter.id.toString());
          const ownMessage = currentMember ? eventUser?.id === String(currentMember?.id) : eventUser?.id === cid;

          const allowGeneralNotify =
            event.channel_type === IChannelTypes.livestream &&
            (activeDashboardTab !== EventDashboardTabs.chat || selectedTab !== DiscussionTab.general);
          const allowDirectNotify =
            event.channel_type === IChannelTypes.messaging && activeChannel !== event.channel_id;
          const isMeetingEvent = currentEvent.type === EventType.meeting;

          if (!ownMessage && !(isMeetingEvent && preJoinPage) && (allowGeneralNotify || allowDirectNotify)) {
            const pushNotify =
              notifyBanner &&
              (await pushNotification(`${event?.user?.name}${greeter?.title ? ` (${greeter.title})` : ""}`, {
                body: event?.message?.text || "",
                icon: eventUser?.image_url as string,
              }));

            if (pushNotify) {
              pushNotify.onclick = () => {
                window.focus();
                if (event.channel_type === IChannelTypes.messaging) {
                  if (event.channel_id) {
                    dispatch(setActiveChannel(event.channel_id));
                  }
                  dispatch(updateEventSettings({ selectedTab: DiscussionTab.personal }));
                }
                if (event.channel_type === IChannelTypes.livestream) {
                  dispatch(showChatList(true));
                  dispatch(clearSelectedChannel());
                  dispatch(updateEventSettings({ selectedTab: DiscussionTab.general }));
                }
                dispatch(updateEventSettings({ activeDashboardTab: EventDashboardTabs.chat }));
              };
            }
            if (notifySound) {
              await audio.play();
            }
          }
        } catch (e: any) {
          // eslint-disable-next-line no-console
          console.log("Notification error: ", e);
        }
      }

      if (MESSAGE_UPDATE_EVENTS.includes(event.type) && event?.channel_type === IChannelTypes.messaging) {
        try {
          if (event && event?.channel_id && channelsIds && !channelsIds.has(event.channel_id) && community?.id) {
            isAuthenticated
              ? dispatch(getChannels.request({ community_id: community.id }))
              : dispatch(getAnonymousChannels.request({ community_id: community.id }));
          } else {
            setUnreadCount();
          }
        } catch (e: any) {
          // eslint-disable-next-line no-console
          console.log(`Channel error: ${e.message}`);
        }
      }
    },
    [
      setUnreadCount,
      isAuthenticated,
      community,
      dispatch,
      channelsIds,
      currentMember,
      cid,
      activeChannel,
      currentEvent,
      notifySound,
      notifyBanner,
      activeDashboardTab,
      selectedTab,
      generalChannel,
      preJoinPage,
    ],
  );

  useEffect(() => {
    if (client && client.user) {
      client.on(eventHandler);
      setUnreadCount();
    }
    return () => {
      client && client.off(eventHandler);
    };
  }, [client, eventHandler, dispatch, setUnreadCount]);

  useEffect(() => {
    return () => {
      dispatch(clearUnreadMessagesCount());
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (currentEvent && client) {
        const channels = await client?.queryChannels(
          {
            type: IChannelTypes.livestream,
            event_id: currentEvent.id,
          },
          {},
          {
            limit: 1,
          },
        );
        if (channels.length > 0) {
          setGeneralChannel(channels[0]);
        }
      }
    })();
  }, [client, currentEvent]);

  return notifications;
};

export default useChatHandler;
