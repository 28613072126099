import * as Client from "stream-chat";
import { action, createAsyncAction } from "typesafe-actions";

import {
  IAddModerators,
  IChannel,
  ICreateGeneralChat,
  ICreateNewChat,
  IGetChannels,
  IUnreadMessages,
  IUpdateChannel,
} from "../interfaces";
import { DiscussionsActionTypes } from "./constants";
import { IMember } from "../../Member";

export const getChannels = createAsyncAction(
  DiscussionsActionTypes.FETCH_CHANNELS_REQUEST,
  DiscussionsActionTypes.FETCH_CHANNELS_SUCCESS,
  DiscussionsActionTypes.FETCH_CHANNELS_FAILURE,
)<IGetChannels, IChannel[], undefined>();

export const getAnonymousChannels = createAsyncAction(
  DiscussionsActionTypes.FETCH_ANONYMOUS_CHANNELS_REQUEST,
  DiscussionsActionTypes.FETCH_CHANNELS_SUCCESS,
  DiscussionsActionTypes.FETCH_CHANNELS_FAILURE,
)<IGetChannels, IChannel[], undefined>();

export const getSelectedChannelDetails = createAsyncAction(
  DiscussionsActionTypes.GET_SELECTED_CHANNEL_DETAILS_REQUEST,
  DiscussionsActionTypes.GET_SELECTED_CHANNEL_DETAILS_SUCCESS,
  DiscussionsActionTypes.GET_SELECTED_CHANNEL_DETAILS_FAILURE,
)<string, IChannel, undefined>();

export const createNewChat = createAsyncAction(
  DiscussionsActionTypes.CREATE_NEW_CHAT,
  DiscussionsActionTypes.CREATE_NEW_CHAT_SUCCESS,
  DiscussionsActionTypes.CREATE_NEW_CHAT_FAILURE,
)<ICreateNewChat, string, undefined>();

export const createAnonymousChannelWithGreeter = createAsyncAction(
  DiscussionsActionTypes.CREATE_ANONYMOUS_CHANNEL_WITH_GREETER,
  DiscussionsActionTypes.CREATE_ANONYMOUS_CHANNEL_WITH_GREETER_SUCCESS,
  DiscussionsActionTypes.CREATE_ANONYMOUS_CHANNEL_WITH_GREETER_FAILURE,
)<{ meeting_id: number; greeter_id: number; anonymous_id: string }, string, undefined>();

export const createGeneralChat = createAsyncAction(
  DiscussionsActionTypes.CREATE_GENERAL_CHAT,
  DiscussionsActionTypes.CREATE_GENERAL_CHAT_SUCCESS,
  DiscussionsActionTypes.CREATE_GENERAL_CHAT_FAILURE,
)<ICreateGeneralChat, string | null, undefined>();

export const updateChannel = createAsyncAction(
  DiscussionsActionTypes.UPDATE_CHANNEL,
  DiscussionsActionTypes.UPDATE_CHANNEL_SUCCESS,
  DiscussionsActionTypes.UPDATE_CHANNEL_FAILURE,
)<IUpdateChannel, { id: string }, undefined>();

export const leaveChannel = createAsyncAction(
  DiscussionsActionTypes.LEAVE_CHANNEL,
  DiscussionsActionTypes.LEAVE_CHANNEL_SUCCESS,
  DiscussionsActionTypes.LEAVE_CHANNEL_FAILURE,
)<{ community_id: number; channel_id: string }, undefined, undefined>();

export const addChannelModerators = createAsyncAction(
  DiscussionsActionTypes.ADD_CHANNEL_MODERATORS,
  DiscussionsActionTypes.ADD_CHANNEL_MODERATORS_SUCCESS,
  DiscussionsActionTypes.ADD_CHANNEL_MODERATORS_FAILURE,
)<IAddModerators & { callback?: () => void }, string, undefined>();

export const clearSelectedChannel = () => action(DiscussionsActionTypes.CLEAR_SELECTED_CHANNEL);

export const setActiveChannel = (id: string) => action(DiscussionsActionTypes.SET_ACTIVE_CHANNEL, id);

export const setGetStreamChannels = (payload: Client.Channel[]) =>
  action(DiscussionsActionTypes.SET_GETSTREAM_CHANNELS, payload);
export const clearGetStreamChannels = () => action(DiscussionsActionTypes.CLEAR_GETSTREAM_CHANNELS);

export const deleteChannel = (channelDetails: { community_id: number; channel_id: string }) =>
  action(DiscussionsActionTypes.DELETE_CHANNEL, channelDetails);

export const showChatList = (val: boolean) => action(DiscussionsActionTypes.SHOW_CHAT_LIST, val);

export const setCreateChatWithMember = (member: IMember) =>
  action(DiscussionsActionTypes.CREATE_CHAT_WITH_MEMBER, member);

export const clearCreateChatWithMember = () => action(DiscussionsActionTypes.CLEAR_CREATE_CHAT_WITH_MEMBER);

export const setUnreadMessagesCount = (userChannels: Partial<IUnreadMessages>) =>
  action(DiscussionsActionTypes.SET_UNREAD_MESSAGES_COUNT, userChannels);
export const clearUnreadMessagesCount = () => action(DiscussionsActionTypes.CLEAR_UNREAD_MESSAGES_COUNT);

export const setEventChannelsIds = (channelsSet: Set<string>) =>
  action(DiscussionsActionTypes.SET_EVENT_CHANNELS_IDS, channelsSet);
export const clearEventChannelsIds = () => action(DiscussionsActionTypes.CLEAR_EVENT_CHANNELS_IDS);

export const setSentMessagesDates = (dates: number[]) => action(DiscussionsActionTypes.SET_SENT_MESSAGES_DATES, dates);

export const setAllowGeneralInput = (value: boolean) =>
  action(DiscussionsActionTypes.SET_ALLOW_GENERAL_CHAT_INPUT, value);

export const showGreeterCustomChannel = (value: boolean) =>
  action(DiscussionsActionTypes.SHOW_GREETER_CUSTOM_CHANNEL, value);
