import React, { useEffect, useState } from "react";
import classnames from "classnames";

import SocialButton from "./SocialButton";
import config from "../../../config";
import { Provider } from "../../../containers/Auth";

import "./styles.scss";

type Props = {
  provider: Provider;
  text: string;
  onSuccess: (data: any) => void;
  onFailure: (err: any) => void;
  className?: string;
  id?: string;
};

const SocialLoginButton: React.FC<Props> = ({ provider, text, onSuccess, onFailure, className, id }) => {
  const clientId = config.socialLoginClientId[provider];

  const [icon, setIcon] = useState<string | null>(null);
  useEffect(() => {
    if (provider) {
      import(`assets/icons/socialLogin/${provider}.svg`).then(path => {
        setIcon(path.default);
      });
    }
  }, [provider]);

  return clientId ? (
    <SocialButton
      provider={provider}
      appId={clientId}
      onLoginSuccess={onSuccess}
      onLoginFailure={onFailure}
      className={classnames("social-button", className)}
    >
      <button className={provider} type="button" id={id}>
        {icon && <img src={icon} alt={provider} />}
        <span>{text}</span>
      </button>
    </SocialButton>
  ) : null;
};

export default SocialLoginButton;
