import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { default as EventAnnouncementForm } from "./EventAnnouncementForm";
import { default as EventAnnouncementsList } from "./EventAnnouncementsList";
import { default as EventAnnouncementView } from "./EventAnnouncementView";
import { EventAnnouncementTemplatesList } from "./EventAnnouncementTemplateList";
import { actions, selectors } from "../../../store";
import { checkMeetingRole, convertAnnouncementFormDataToRequest } from "../../../utils";
import { updateEventSettings } from "../../../store/actions";
import { EventPermissions } from "../../../../Auth";
import { getMember } from "../../../../Auth/store/selectors";

import { AlertDialog, EventSideDialog } from "shared/components";
import {
  EInteractionTab,
  EventSideDialogHeaderTypes,
} from "shared/components/Event/EventSideDialog/EventSideDialogHeader";
import {
  AnnouncementStatus,
  EventStatus,
  IAnnouncement,
  IAnnouncementDialogValues,
  IAnnouncementTemplate,
  ICreateAnnouncementShape,
  IEvent,
  IUpdateAnnouncementShape,
  SermonRoles,
} from "containers/Sermons/interfaces";
import { usePermissions } from "shared";

import "./index.scss";

interface IEventAnnouncementsDialogProps {
  open: boolean;
  announcements: IAnnouncement[];
  announcementTemplates: IAnnouncementTemplate[];
  onClose?: () => void;
  event: IEvent;
}

enum DialogStates {
  list = "list",
  form = "form",
  view = "view",
  template = "template",
}

const EventAnnouncementsDialog: React.FC<IEventAnnouncementsDialogProps> = props => {
  const { onClose, open, event, announcements, announcementTemplates } = props;
  const dispatch = useDispatch();
  const selectedAnnouncement = useSelector(selectors.getSelectedAnnouncement());
  const [announcementToDelete, setAnnouncementToDelete] = useState<IAnnouncement | null>(null);
  const [selectedAnnouncementTemplate, setSelectedAnnouncementTemplate] = useState<IAnnouncementTemplate | null>(null);
  const [currentDialogState, setCurrentDialogState] = useState<DialogStates>(DialogStates.list);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { announcementToFind } = useSelector(selectors.getEventSettings());
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);
  const [formWasChanged, setFormWasChanged] = useState(false);
  const [leaveFunction, setLeaveFunction] = useState(() => onClose);
  const { interactionTab } = useSelector(selectors.getEventSettings());
  const currentEvent = useSelector(selectors.getEvent());
  const currentMember = useSelector(getMember());

  const canEdit = usePermissions([EventPermissions.edit], currentEvent?.community_id);

  const resetDialogState = useCallback(() => {
    dispatch(actions.setSelectedAnnouncement(null));
    setSelectedAnnouncementTemplate(null);
    setCurrentDialogState(DialogStates.list);
    setFormWasChanged(false);
  }, [dispatch]);

  useEffect(() => {
    if (!selectedAnnouncement && currentDialogState === DialogStates.view) {
      setCurrentDialogState(DialogStates.list);
    }
  }, [selectedAnnouncement, currentDialogState]);

  useEffect(() => {
    if (announcementToFind) {
      const foundAnnouncement = announcements.find(item => item.id === announcementToFind);
      if (foundAnnouncement) {
        dispatch(actions.setSelectedAnnouncement(foundAnnouncement));
        setCurrentDialogState(DialogStates.view);
      }
      dispatch(
        actions.updateEventSettings({
          announcementToFind: null,
        }),
      );
    }
  }, [announcementToFind, announcements, dispatch]);

  const createAnnouncement = useCallback(
    (values: ICreateAnnouncementShape) => {
      dispatch(actions.createAnnouncement.request(values));
    },
    [dispatch],
  );

  const updateAnnouncement = useCallback(
    (values: IUpdateAnnouncementShape) => {
      dispatch(actions.updateAnnouncement.request(values));
    },
    [dispatch],
  );
  const sortedAnnouncements = useMemo(
    () => [...announcements].sort((a, b) => a.position - b.position),
    [announcements],
  );

  const onSubmit = useCallback(
    (values: IAnnouncementDialogValues) => {
      if (!selectedAnnouncement) {
        createAnnouncement({
          ...convertAnnouncementFormDataToRequest(values),
          meeting_id: event.id,
          position: sortedAnnouncements[sortedAnnouncements.length - 1]?.position + 1 || 0,
        });
      } else {
        updateAnnouncement({
          ...convertAnnouncementFormDataToRequest(values),
          id: selectedAnnouncement.id,
          meeting_id: selectedAnnouncement.meeting_id,
        });
      }
      resetDialogState();
    },
    [selectedAnnouncement, resetDialogState, createAnnouncement, event.id, sortedAnnouncements, updateAnnouncement],
  );

  const onChangeStatus = useCallback(
    (announcement: IAnnouncement, status: AnnouncementStatus) => {
      updateAnnouncement({
        id: announcement.id,
        type: announcement.type,
        title: announcement.title,
        description: announcement.description,
        button_text: announcement.button_text,
        button_link: announcement.button_link,
        meeting_id: announcement.meeting_id,
        scheduled_at: status === AnnouncementStatus.scheduled ? announcement.scheduled_at : null,
        status,
      });
    },
    [updateAnnouncement],
  );

  const onDelete = useCallback(() => {
    if (announcementToDelete) {
      dispatch(actions.deleteAnnouncement.request(announcementToDelete.id));
      setAnnouncementToDelete(null);
      setShowDeleteConfirmation(false);
    }
  }, [dispatch, announcementToDelete]);

  const onCreate = useCallback(() => {
    dispatch(actions.setSelectedAnnouncement(null));
    setSelectedAnnouncementTemplate(null);
    setCurrentDialogState(DialogStates.form);
  }, [dispatch]);

  const onEditTemplate = useCallback((template: IAnnouncementTemplate) => {
    setSelectedAnnouncementTemplate(template);
    setCurrentDialogState(DialogStates.form);
  }, []);

  const onUseTemplate = useCallback(() => {
    dispatch(actions.setSelectedAnnouncement(null));
    setCurrentDialogState(DialogStates.template);
  }, [dispatch]);

  const onEdit = useCallback(
    (announcement: IAnnouncement) => {
      dispatch(actions.setSelectedAnnouncement(announcement));
      setCurrentDialogState(DialogStates.form);
    },
    [dispatch],
  );

  const onView = useCallback(
    (announcement: IAnnouncement) => {
      dispatch(actions.setSelectedAnnouncement(announcement));
      setCurrentDialogState(DialogStates.view);
    },
    [dispatch],
  );

  const onLeave = useCallback(() => {
    leaveFunction && leaveFunction();
    setShowLeaveConfirmation(false);
  }, [leaveFunction]);

  const onBack = useMemo(() => {
    if (currentDialogState === DialogStates.list) {
      return undefined;
    } else {
      return () => resetDialogState();
    }
  }, [currentDialogState, resetDialogState]);

  const onCloseDialog = useCallback(() => {
    resetDialogState();
    onClose && onClose();
  }, [resetDialogState, onClose]);

  const setInteractionTab = useCallback(
    (interactionTab: EInteractionTab) => {
      dispatch(updateEventSettings({ interactionTab }));
    },
    [dispatch],
  );

  const canManageAnnouncements = useMemo(() => {
    const eventIsEnded = currentEvent?.status === EventStatus.ended;

    const hasAccessToManage =
      currentEvent &&
      currentMember?.id &&
      checkMeetingRole(currentEvent, currentMember, [SermonRoles.greeter, SermonRoles.host, SermonRoles.greeterHost]);

    return Boolean(!eventIsEnded && hasAccessToManage) || canEdit;
  }, [currentEvent, currentMember, canEdit]);

  const deleteAlert = useMemo(() => {
    return (
      <AlertDialog
        open={showDeleteConfirmation}
        title="Delete the announcement"
        message="Are you sure that you want to delete this announcement? "
        onConfirm={onDelete}
        onCancel={() => setShowDeleteConfirmation(false)}
        mode="confirm"
        confirmText="Delete"
        cancelText="Cancel"
        variant="brown"
        confirmClassName="defaultButtons-remove"
        dialogClassName="no-transform-header"
      />
    );
  }, [showDeleteConfirmation, onDelete]);

  const leaveAlert = useMemo(() => {
    return (
      <AlertDialog
        open={showLeaveConfirmation}
        title="Leave page"
        message="You have unsaved changes?"
        onConfirm={onLeave}
        onCancel={() => setShowLeaveConfirmation(false)}
        mode="confirm"
        confirmText="Leave"
        cancelText="Cancel"
        variant="brown"
      />
    );
  }, [showLeaveConfirmation, onLeave]);

  const announcementForm = useMemo(() => {
    return (
      <EventAnnouncementForm
        onSubmit={onSubmit}
        announcement={selectedAnnouncement || selectedAnnouncementTemplate}
        onChange={() => setFormWasChanged(true)}
      />
    );
  }, [selectedAnnouncement, selectedAnnouncementTemplate, onSubmit]);

  const announcementView = useMemo(() => {
    if (!selectedAnnouncement) {
      return null;
    }
    return <EventAnnouncementView announcement={selectedAnnouncement} />;
  }, [selectedAnnouncement]);

  const announcementTemplateList = useMemo(() => {
    return (
      <EventAnnouncementTemplatesList
        onEdit={onEditTemplate}
        announcementTemplates={announcementTemplates}
        onPublish={onSubmit}
      />
    );
  }, [announcementTemplates, onEditTemplate, onSubmit]);

  const announcementsList = useMemo(() => {
    return (
      <EventAnnouncementsList
        onCreate={onCreate}
        onUseTemplate={onUseTemplate}
        onEdit={onEdit}
        onChangeStatus={onChangeStatus}
        onDelete={announcement => {
          setAnnouncementToDelete(announcement);
          setShowDeleteConfirmation(true);
        }}
        onView={onView}
        announcements={sortedAnnouncements}
        canManageAnnouncements={canManageAnnouncements}
      />
    );
  }, [onCreate, onUseTemplate, onEdit, onChangeStatus, onView, sortedAnnouncements, canManageAnnouncements]);

  const renderState = useMemo(() => {
    switch (currentDialogState) {
      case DialogStates.list:
        return announcementsList;
      case DialogStates.form:
        return announcementForm;
      case DialogStates.view:
        return announcementView;
      case DialogStates.template:
        return announcementTemplateList;
      default:
        return announcementsList;
    }
  }, [currentDialogState, announcementForm, announcementsList, announcementView, announcementTemplateList]);

  const dialogTitle = useMemo(() => {
    switch (currentDialogState) {
      case DialogStates.form:
        return "Announcement";
      case DialogStates.list:
        return "Announcements";
      case DialogStates.template:
        return "Templates";
      case DialogStates.view:
        return selectedAnnouncement?.title || "Announcement";
    }
  }, [currentDialogState, selectedAnnouncement]);

  const showTitleImage = useMemo(() => {
    return currentDialogState === DialogStates.view;
  }, [currentDialogState]);

  const showCloseIcon = useMemo(() => {
    return currentDialogState === DialogStates.list;
  }, [currentDialogState]);

  return (
    <EventSideDialog
      title={dialogTitle}
      open={open}
      headerType={
        currentDialogState === DialogStates.list && canManageAnnouncements
          ? EventSideDialogHeaderTypes.interaction_tabs
          : EventSideDialogHeaderTypes.default
      }
      setInteractionTab={setInteractionTab}
      interactionTab={interactionTab}
      onClose={
        showCloseIcon
          ? () => {
              if (formWasChanged) {
                setLeaveFunction(() => onCloseDialog);
                setShowLeaveConfirmation(true);
              } else {
                onCloseDialog();
              }
            }
          : undefined
      }
      onBack={
        onBack
          ? () => {
              if (formWasChanged) {
                setLeaveFunction(() => onBack);
                setShowLeaveConfirmation(true);
              } else {
                onBack();
              }
            }
          : undefined
      }
      showImage={showTitleImage}
    >
      <div className="eventAnnouncementsDialog">
        {leaveAlert}
        {deleteAlert}
        {renderState}
      </div>
    </EventSideDialog>
  );
};

export default EventAnnouncementsDialog;
