import React, { useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import ChangeScopePopup from "../../Sermon/ChangeScopePopup";
import { RecordsListPopup } from "../../Sermon/RecordsListPopup";
import { NamesOfParentRoutes } from "../../../../../constants";
import { getCommunity as getCommunityAction } from "../../../../Community/store/actions";
import { actions } from "../../../store";
import { download } from "../../../../../shared/utils/request";
import { EFeature } from "../../../../Community";

import {
  checkMeetingRole,
  EEventChangeScope,
  EEventRepeat,
  eventIsCurrent,
  EventStatus,
  EventType,
  getEventTimeRange,
  getMeetingType,
  IEventCard,
  SermonRoles,
} from "containers/Sermons";
import { getCommunity } from "containers/Community/store/selectors";
import { clearCurrentSermon, duplicateEvent, fetchEventShortLink } from "containers/Sermons/store/actions";
import { API, Button, copyTextHandler, ThreeDotsPopup, useCurrentUserMember, usePermissions } from "shared";
import { ERoles, EventPermissions } from "containers/Auth/interfaces";
import { notificationActions } from "containers/Notifications/store/actions";
import { checkRoles } from "utils/ACL";
import { EFileType } from "shared/interfaces/File";
import { useFeatures } from "shared/hooks/FeaturesHook";
import producer_role_icon from "assets/icons/producer_role_icon.svg";
import camera_enabled from "assets/icons/camera_enabled.svg";

import "./sermonCard.scss";

const { EVENT, STREAM } = NamesOfParentRoutes;

const SermonCard: React.FC<IEventCard> = props => {
  const { sermon } = props;
  const { code, name, subject, speaker_name, status, join_link, files, id, starting_at, type } = sermon;

  const history = useHistory();

  const dispatch = useDispatch();

  const currentCommunity = useSelector(getCommunity());

  const currentUserMember = useCurrentUserMember();
  const features = useFeatures();

  const canEdit = usePermissions([EventPermissions.edit], currentCommunity?.id);
  const canDuplicate = usePermissions([EventPermissions.duplicate], currentCommunity?.id);
  const canDelete = usePermissions([EventPermissions.delete], currentCommunity?.id);

  const isManager = useMemo(() => checkRoles([ERoles.admin, ERoles.manager], currentCommunity?.id), [currentCommunity]);
  const meetingRecords = useMemo(() => files?.filter(file => file.type === EFileType.meeting_record) || [], [files]);
  const hasMeetingFeature = useMemo(() => features.includes(EFeature.meeting), [features]);
  const disabledCard = useMemo(() => type === EventType.meeting && !hasMeetingFeature, [hasMeetingFeature, type]);

  const [showDelete, setShowDelete] = useState(false);
  const [deleteScope, setDeleteScope] = useState(EEventChangeScope.current);
  const [openRecords, setOpenRecords] = useState(false);

  const [showDuplicate, setShowDuplicate] = useState(false);
  const [duplicateScope, setDuplicateScope] = useState(EEventChangeScope.current);

  const clickCardHandler = () => {
    if (!disabledCard) {
      dispatch(clearCurrentSermon());
      const { type } = sermon;
      let path = `/${currentCommunity?.code}`;
      if (type === EventType.stream || type === EventType.meeting) {
        if (status === EventStatus.draft) {
          return;
        }
        path = `${STREAM}/${code}`;
      }
      history.push(path);
    }
  };

  const onEditHandler = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!disabledCard) {
        history.push(`/${currentCommunity?.code}${EVENT}/${code}/edit`);
      }
    },
    [code, currentCommunity?.code, disabledCard, history],
  );

  const showJoinLink = status !== EventStatus.draft;
  const copyLinkCallback = (shortLink?: string) => {
    if (shortLink) {
      copyTextHandler(shortLink);
      dispatch(notificationActions.success("The link is copied to the clipboard"));
    }
  };

  const onCopyLinkHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (join_link) {
      copyLinkCallback(join_link);
      return;
    }
    if (code) {
      dispatch(
        fetchEventShortLink.request({
          code,
          avoidInstApp: true,
          callback: val => {
            copyLinkCallback(val);
          },
        }),
      );
    }
  };

  const onDuplicateHandler = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDuplicate(true);
  }, []);

  const onDownloadParticipantsHandler = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
      download(API.DOWNLOAD_PARTICIPANTS(id), `${name} (${moment(starting_at).format("MM-DD-YYYY")}).csv`, { offset });
    },
    [id, name, starting_at],
  );

  const handleDuplicateEvent = useCallback(() => {
    if (sermon?.id) {
      setShowDuplicate(false);
      dispatch(
        duplicateEvent.request({
          eventId: sermon.id,
          scope: duplicateScope,
          callback: () => {
            if (currentCommunity) {
              dispatch(getCommunityAction.request(currentCommunity.code));
            }
          },
          options: {
            type: sermon ? getMeetingType(sermon.type) : void 0,
          },
        }),
      );
    }
  }, [sermon, dispatch, duplicateScope, currentCommunity]);

  const onDeleteHandler = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDelete(true);
  }, []);

  const handleDeleteEvent = useCallback(() => {
    if (sermon?.id) {
      dispatch(
        actions.removeEvent.request({
          eventId: sermon.id,
          scope: deleteScope,
          callback: () => {
            if (currentCommunity) {
              dispatch(getCommunityAction.request(currentCommunity.code));
            }
          },
          options: {
            type: sermon ? getMeetingType(sermon.type) : void 0,
          },
        }),
      );
    }
  }, [sermon, dispatch, deleteScope, currentCommunity]);

  const eventTimeRange = useMemo(() => (sermon ? getEventTimeRange(sermon) : ""), [sermon]);

  const eventIsUpcoming = status === EventStatus.scheduled;
  const eventIsEnded = status === EventStatus.ended;

  const isOngoing = useMemo(() => eventIsCurrent(sermon), [sermon]);

  const onCancelDeleting = useCallback(() => setShowDelete(false), []);
  const onCancelDuplicating = useCallback(() => setShowDuplicate(false), []);

  const onScopeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setDeleteScope(e.target.value as EEventChangeScope),
    [],
  );

  const onDuplicateScopeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setDuplicateScope(e.target.value as EEventChangeScope),
    [],
  );
  return (
    <div
      className={classnames("sermon", { "sermon-available": isOngoing || eventIsUpcoming, disabledCard })}
      onClick={clickCardHandler}
    >
      <div className={classnames("sermon-card", { "sermon-card-available": isOngoing || eventIsUpcoming })}>
        <ChangeScopePopup
          variant="delete"
          opened={showDelete}
          eventType={sermon.type}
          scope={sermon.repeat !== EEventRepeat.never ? deleteScope : undefined}
          onConfirm={handleDeleteEvent}
          onCancel={onCancelDeleting}
          onScopeChange={onScopeChange}
        />

        <ChangeScopePopup
          variant="duplicate"
          opened={showDuplicate}
          eventType={sermon.type}
          scope={sermon.repeat !== EEventRepeat.never ? duplicateScope : undefined}
          onConfirm={handleDuplicateEvent}
          onCancel={onCancelDuplicating}
          onScopeChange={onDuplicateScopeChange}
        />
        {meetingRecords.length ? (
          <RecordsListPopup opened={openRecords} onCancel={() => setOpenRecords(false)} files={meetingRecords} />
        ) : null}
        <div className="sermon-card-roles">
          {sermon && currentUserMember && checkMeetingRole(sermon, currentUserMember, SermonRoles.producer) && (
            <div className="sermon-card-roles-item">
              <img src={producer_role_icon} alt="switch icon" />
            </div>
          )}
          {sermon && currentUserMember && checkMeetingRole(sermon, currentUserMember, SermonRoles.presenter) && (
            <div className="sermon-card-roles-item">
              <img src={camera_enabled} alt="switch icon" />
            </div>
          )}
        </div>
        <div className="sermon-card-heading">
          <div className="sermon-card-timing">{eventTimeRange}</div>
        </div>
        <div className="sermon-card-name">{name}</div>
        {subject && <div className={"sermon-card-details"}>{subject}</div>}
        {!!speaker_name && (
          <div className="sermon-card-speaker">
            <div className="sermon-card-speaker-label">Speaker</div>
            <div className="sermon-card-speaker-content">{speaker_name}</div>
          </div>
        )}
        <div className="sermon-card-actions">
          {isOngoing && sermon.type !== EventType.meeting && <div className="sermon-card-ongoing">Ongoing</div>}
          {eventIsEnded && <div className="sermon-card-ended">Ended</div>}
          {status === EventStatus.draft && <div className="sermon-card-draft">draft</div>}
          {eventIsEnded && (
            <>
              {canDelete && (
                <div className="sermon-card-delete" title="Delete Event" onClick={onDeleteHandler}>
                  Delete
                </div>
              )}
              {(meetingRecords.length > 0 || canDuplicate) &&
              currentUserMember &&
              (canEdit || checkMeetingRole(sermon, currentUserMember, [SermonRoles.greeterHost])) ? (
                <ThreeDotsPopup>
                  {meetingRecords.length > 0 && (
                    <div className="sermon-card-popup-item" title="Recording" onClick={() => setOpenRecords(true)}>
                      Recordings
                    </div>
                  )}
                  {canDuplicate && (
                    <>
                      {meetingRecords.length > 0 && <div className="sermon-card-popup-divider" />}
                      <div className="sermon-card-popup-item" title="Duplicate" onClick={onDuplicateHandler}>
                        Duplicate
                      </div>
                    </>
                  )}
                  <div className="sermon-card-popup-divider" />
                  <div
                    className="sermon-card-popup-item"
                    title="Download Attendance"
                    onClick={onDownloadParticipantsHandler}
                  >
                    Download Attendance
                  </div>
                </ThreeDotsPopup>
              ) : null}
            </>
          )}
          {canEdit && status !== EventStatus.ended && (
            <div className="sermon-card-edit" title="Edit Event" onClick={onEditHandler}>
              <Button variant="blue-text" width={38}>
                Edit
              </Button>
            </div>
          )}
          {showJoinLink && !isManager && status !== EventStatus.ended && !disabledCard && (
            <div className="sermon-card-copy-url" title="Copy Event URL" onClick={onCopyLinkHandler}>
              <Button variant="blue-text" width={82}>
                Copy Link
              </Button>
            </div>
          )}
          {!eventIsEnded && isManager && (
            <ThreeDotsPopup>
              {showJoinLink && (
                <div className="sermon-card-popup-item" title="Copy Event URL" onClick={onCopyLinkHandler}>
                  Copy Link
                </div>
              )}
              {canEdit && meetingRecords.length ? (
                <>
                  <div className="sermon-card-popup-divider" />
                  <div className="sermon-card-popup-item" title="Recording" onClick={() => setOpenRecords(true)}>
                    Recordings
                  </div>
                </>
              ) : null}
              {showJoinLink && canDelete && <div className="sermon-card-popup-divider" />}
              {canDuplicate && (
                <div className="sermon-card-popup-item" title="Duplicate" onClick={onDuplicateHandler}>
                  Duplicate
                </div>
              )}
              {canDuplicate && <div className="sermon-card-popup-divider" />}
              {canDelete && (
                <div className="sermon-card-popup-delete" title="Delete Event" onClick={onDeleteHandler}>
                  Delete
                </div>
              )}
            </ThreeDotsPopup>
          )}
        </div>
      </div>
    </div>
  );
};

export default SermonCard;
