import React from "react";

import { IconBase } from "../IconBase";

import { IStyledSVG } from "shared/interfaces/StyledSVG";

export const StyledLinkIcon: React.FC<IStyledSVG> = ({
  style = {},
  stroke = "#fff",
  width = "20",
  height = "20",
  className = "",
  opacity = 1,
}) => (
  <IconBase
    fill="none"
    width={width}
    style={style}
    height={height}
    className={className}
    opacity={opacity}
    viewBox="0 0 20 20"
  >
    <path
      d="M11.4156 8.58622C10.6648 7.83568 9.64658 7.41406 8.58494 7.41406C7.5233 7.41406 6.50512 7.83568 5.75427 8.58622L2.92269 11.4169C2.17183 12.1677 1.75 13.1861 1.75 14.248C1.75 15.3099 2.17183 16.3283 2.92269 17.0791C3.67355 17.83 4.69194 18.2518 5.75381 18.2518C6.81569 18.2518 7.83408 17.83 8.58494 17.0791L10.0003 15.6638"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.58496 11.4156C9.33581 12.1661 10.354 12.5878 11.4156 12.5878C12.4773 12.5878 13.4954 12.1661 14.2463 11.4156L17.0779 8.58494C17.8287 7.83408 18.2506 6.81569 18.2506 5.75381C18.2506 4.69194 17.8287 3.67355 17.0779 2.92269C16.327 2.17183 15.3086 1.75 14.2468 1.75C13.1849 1.75 12.1665 2.17183 11.4156 2.92269L10.0003 4.33802"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconBase>
);

export default StyledLinkIcon;
