import React, { useState } from "react";

import { Button, Overlay, SquareTextField } from "shared/components";
import clapping_hands from "assets/icons/clapping_hands.svg";

import "./stripeSuccessPopup.scss";

type Props = {
  opened: boolean;
  onClose: () => void;
  message?: string;
  onSubmitMessage: (message: string) => void;
};

const errorData = {
  popUpField: "Max 1024 characters",
};

const isValidField = (value: string) => value.length <= 1024;

const StripeSuccessPopup: React.FC<Props> = ({ opened, onClose, message, onSubmitMessage }) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState<{ popUpField: string } | null>(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const handleChange = (e: React.ChangeEvent<any>) => {
    const value: string = e.target.value;
    setError(isValidField(value) ? null : errorData);
    setValue(value);
    setSubmitDisabled(!value.trim().length);
  };

  const handleSubmit = () => {
    if (isValidField(value)) {
      onSubmitMessage(value);
      return;
    }
    setError(errorData);
  };

  return (
    <Overlay onClose={onClose} show={opened}>
      <div className="stripeSuccessPopup">
        <div className="stripeSuccessPopup-content">
          <img src={clapping_hands} alt="clapping hands" />
          <div className="stripeSuccessPopup-title">Congratulations!</div>
          <p className="stripeSuccessPopup-description">
            {message
              ? message
              : "You successfully updated your subscription. Keep growing your online engagement with full-featured meetings & events!"}
          </p>
          <div className="stripeSuccessPopup-divider" />
          <SquareTextField
            className="stripeSuccessPopup-textField"
            name="popUpField"
            fullWidth
            label="What part of your ministry are you hoping to improve the most using Altar live?"
            placeholder="Type here"
            errors={error}
            value={value}
            onChange={handleChange}
            multiline
            rows={6}
          />
        </div>
        <div className="stripeSuccessPopup-footer">
          <Button
            variant="blue-outline"
            className="stripeSuccessPopup-footer-skip"
            width={144}
            height={40}
            onClick={onClose}
          >
            Skip
          </Button>
          <Button
            variant={submitDisabled ? "disabled" : "blue"}
            width={144}
            className="stripeSuccessPopup-footer-submit"
            height={40}
            disabled={submitDisabled}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Overlay>
  );
};

export default StripeSuccessPopup;
