import { action } from "typesafe-actions";

import { AppActionTypes } from "./constants";
import { AuthActionTypes } from "../../Auth/store/constants";
// Eventing
export const setErrorMessage = (message: string) => action(AppActionTypes.ERROR_MESSAGE, message);
export const setLoading = () => action(AppActionTypes.SET_LOADING);
export const startLoading = () => action(AppActionTypes.START_LOAD);
export const stopLoading = () => action(AppActionTypes.STOP_LOAD);
export const doUnathorized = () => action(AuthActionTypes.DO_UNAUTHORIZED);
export const setHubspotOpened = (payload: boolean) => action(AppActionTypes.SET_HUBSPOT_OPENED, payload);
export const setHubspotInited = (inited: boolean) => action(AppActionTypes.SET_HUBSPOT_INITED, inited);
export const setDashBoardDisabled = (payload: boolean) => action(AppActionTypes.SET_DASHBOARD_DISABLED, payload);
export const setIpBlocked = (isBlocked: boolean) => action(AppActionTypes.SET_IP_BLOCKED, isBlocked);
export const setDeviceFingerprint = (deviceFingerprint: string) =>
  action(AppActionTypes.SET_DEVICE_FINGERPRINT, deviceFingerprint);
