import { ClientJS } from "clientjs";

import { IEvent } from "../containers/Sermons";

export function stripTags(html: string): string {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /android/i.test(userAgent) ? "Android" : "IOS";
}

export function dateWithoutTime(date: Date | string) {
  return new Date(date).setHours(0, 0, 0, 0);
}

export const prepareSchedulers = (events: IEvent[]) => {
  const sermonSchedulers: { date: number; events: IEvent[] }[] = [];
  const sermonsDate = Array.from(new Set(events.map(i => dateWithoutTime(new Date(i.starting_at)))));
  sermonsDate.forEach(date => {
    sermonSchedulers.push({
      date,
      events: events.filter(i => dateWithoutTime(new Date(i.starting_at)) === date),
    });
  });

  return sermonSchedulers;
};

export const getDeviceFingerprint = (): string => {
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();

  return String(fingerprint);
};
