import * as detectRTC from "detectrtc";

interface Device {
  deviceId: string;
  groupId: string;
  id: string;
  isCustomLabel?: boolean;
  kind: string;
  label: string;
}

export interface DetectRTCInterface {
  load(callback: () => void): void;
  version: string;
  osName: string;
  osVersion: string;

  hasWebcam: boolean;
  hasMicrophone: boolean; // (has microphone device!)
  hasSpeakers: boolean; // (has speakers!)

  isApplyConstraintsSupported: boolean;
  isAudioContextSupported: boolean;
  isCanvasSupportsStreamCapturing: boolean;
  isCreateMediaStreamSourceSupported: boolean;
  isGetUserMediaSupported: boolean;
  isMobileDevice: boolean;
  isMultiMonitorScreenCapturingSupported: boolean;
  isORTCSupported: boolean;
  sPromisesSupported: boolean;
  isRTPSenderReplaceTracksSupported: boolean;
  isRemoteStreamProcessingSupported: boolean;
  isRtpDataChannelsSupported: boolean;
  isScreenCapturingSupported: boolean; // Chrome, Firefox, Opera, Edge and Android
  isSctpDataChannelsSupported: boolean;
  isSetSinkIdSupported: boolean;
  isVideoSupportsStreamCapturing: boolean;
  isWebRTCSupported: boolean;
  isWebSocketsBlocked: boolean;
  isWebSocketsSupported: boolean;

  isWebsiteHasMicrophonePermissions: boolean; // getUserMedia allowed for HTTPs domain in Chrome?
  isWebsiteHasWebcamPermissions: boolean; // getUserMedia allowed for HTTPs domain in Chrome?

  isDesktopCapturingSupported: boolean;

  checkWebSocketsSupport: (callback: (...args: any[]) => void) => () => void;

  audioInputDevices: Device[]; // microphones
  audioOutputDevices: Device[]; // speakers
  videoInputDevices: Device[]; // cameras

  displayAspectRatio: string;
  displayResolution: string;

  browser: {
    name: "Edge" | "Chrome" | "Firefox" | string;
    version: string | number;
    fullVersion: string;
    isChrome?: boolean;
    isFirefox?: boolean;
    isOpera?: boolean;
    isIE?: boolean;
    isSafari?: boolean;
    isEdge?: boolean;

    isPrivateBrowsing?: boolean; // incognito or private modes
  };

  DetectLocalIPAddress: (callback: (...args: any[]) => any) => (localIpAddress: string) => void;

  MediaDevices: Device[];
  MediaStream: string[];
  MediaStreamTrack: string[];
  RTCPeerConnection: string[];
}

export const RtcDetect = (): Promise<DetectRTCInterface> => {
  return new Promise(rs => {
    detectRTC.load(function () {
      rs(detectRTC as unknown as DetectRTCInterface);
    });
  });
};
