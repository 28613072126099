import { action, createAsyncAction } from "typesafe-actions";

import { AuthActionTypes } from "./constants";
import {
  AuthShape,
  RegisterShape,
  ForgotShape,
  ResetShape,
  IUser,
  CheckUSerToken,
  IOnboardingData,
  SocialAuthShape,
  IRegistrationData,
  ILogout,
  ISendLoginLinkPayload,
  ConfirmVerifyUserData,
} from "../interfaces";
import { IMember } from "../../Member";

import { ECreationKind, ResponseError } from "shared";

export const getUserData = createAsyncAction(
  AuthActionTypes.CHECK_USER,
  AuthActionTypes.CHECK_USER_SUCCESS,
  AuthActionTypes.CHECK_USER_FAILURE,
)<undefined, IUser, undefined>();

export const logout = createAsyncAction(
  AuthActionTypes.LOGOUT,
  AuthActionTypes.LOGOUT_SUCCESS,
  AuthActionTypes.LOGOUT_FAILURE,
)<ILogout, undefined, undefined>();

export const login = createAsyncAction(AuthActionTypes.LOGIN, AuthActionTypes.LOGIN_SUCCESS, AuthActionTypes.SET_ERROR)<
  AuthShape,
  number | void,
  ResponseError
>();

export const socialLogin = createAsyncAction(
  AuthActionTypes.SOCIAL_LOGIN,
  AuthActionTypes.SOCIAL_LOGIN_SUCCESS,
  AuthActionTypes.SOCIAL_LOGIN_FAILURE,
)<SocialAuthShape | null, number | void, ResponseError>();

export const magicLogin = (payload: { hash: string; redirectTo?: string }) =>
  action(AuthActionTypes.MAGIC_LOGIN, payload);

export const confirmVerifyUser = createAsyncAction(
  AuthActionTypes.CONFIRM_VERIFY_USER,
  AuthActionTypes.CONFIRM_VERIFY_USER_SUCCESS,
  AuthActionTypes.CONFIRM_VERIFY_USER_FAILURE,
)<ConfirmVerifyUserData, undefined, string>();

export const forgot = createAsyncAction(
  AuthActionTypes.FORGOT,
  AuthActionTypes.FORGOT_SUCCESS,
  AuthActionTypes.SET_ERROR,
)<ForgotShape, undefined, ResponseError>();

export const registration = createAsyncAction(
  AuthActionTypes.REGISTRATION,
  AuthActionTypes.REGISTRATION_SUCCESS,
  AuthActionTypes.REGISTRATION_FAILURE,
)<RegisterShape, number, ResponseError>();

export const checkResetHash = createAsyncAction(
  AuthActionTypes.CHECK_RESET_HASH,
  AuthActionTypes.CHECK_RESET_HASH_SUCCESS,
  AuthActionTypes.CHECK_RESET_HASH_FAILURE,
)<CheckUSerToken, undefined, string>();

export const reset = createAsyncAction(AuthActionTypes.RESET, AuthActionTypes.RESET_SUCCESS, AuthActionTypes.SET_ERROR)<
  ResetShape,
  undefined,
  ResponseError
>();

export const getMember = createAsyncAction(
  AuthActionTypes.GET_MEMBER,
  AuthActionTypes.GET_MEMBER_SUCCESS,
  AuthActionTypes.GET_MEMBER_FAILURE,
)<{ community_id: number; kind?: ECreationKind }, IMember, undefined>();

export const setOnboardingData = (payload: IOnboardingData) => action(AuthActionTypes.SET_ONBOARDING_DATA, payload);
export const clearOnboardingData = () => action(AuthActionTypes.CLEAR_ONBOARDING_DATA);

export const setForgotEmail = (payload: string) => action(AuthActionTypes.SET_FORGOT_EMAIL, payload);
export const clearForgotEmail = () => action(AuthActionTypes.CLEAR_FORGOT_EMAIL);

export const setLoading = () => action(AuthActionTypes.SET_LOADING);
export const setError = (message: ResponseError | null) => action(AuthActionTypes.SET_ERROR, message);

export const sendMagicLink = createAsyncAction(
  AuthActionTypes.SEND_MAGIC_LINK_REQUEST,
  AuthActionTypes.SEND_MAGIC_LINK_SUCCESS,
  AuthActionTypes.SEND_MAGIC_LINK_FAILURE,
  AuthActionTypes.SEND_MAGIC_LINK_CLEAR,
)<ISendLoginLinkPayload, undefined, undefined, undefined>();

export const setEmailResent = (isSent: boolean) => action(AuthActionTypes.SET_EMAIL_RESENT, isSent);

export const getTokenByHash = (hash: string) => action(AuthActionTypes.GET_TOKEN_BY_HASH, hash);

export const setUnverifiedUserId = (id: number | null) => action(AuthActionTypes.SET_UNVERIFIED_USER_ID, id);

export const setRegistrationData = (data: IRegistrationData | null) =>
  action(AuthActionTypes.SET_REGISTRATION_DATA, data);

export const setUserIsNew = (isNew?: boolean) => action(AuthActionTypes.SET_USER_IS_NEW, isNew);

export const clearCurrentUser = () => action(AuthActionTypes.CLEAR_CURRENT_USER);

export const clearConfirmationError = () => action(AuthActionTypes.CLEAR_CONFIRMATION_ERROR);

export const setToken = (token: string | null) => action(AuthActionTypes.SET_TOKEN, token);
