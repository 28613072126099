import React from "react";

import { IconBase } from "../IconBase";

import { IStyledSVG } from "shared/interfaces/StyledSVG";

export const StyledSquarePolygon: React.FC<IStyledSVG> = ({
  style = {},
  fill = "#93808D",
  width = "120",
  height = "120",
  className = "",
  opacity = 0.1,
}) => (
  <IconBase
    fill="none"
    width={width}
    style={style}
    height={height}
    className={className}
    opacity={opacity}
    viewBox="0 0 120 120"
  >
    <path
      d="M120 7.62939e-06L62.6704 2.64914e-06L62.6704 62.7076L5.04051e-06 62.7076L0 120L119.455 120L120 7.62939e-06Z"
      fill={fill}
    />
  </IconBase>
);

export default StyledSquarePolygon;
