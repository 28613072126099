import "./styles.scss";

import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { SquareButton } from "../../../../shared/components";
import { actions } from "../../store";

import laptop_dashboard from "assets/images/laptop_dashboard.png";

interface CommunityCreationBlockerProp {
  isSelect?: boolean;
  communityCode: string | null;
}

const CommunityCreationBlocker: React.FC<CommunityCreationBlockerProp> = ({ isSelect, communityCode }) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(actions.openCommunityInApp.request(communityCode || ""));
  }, [dispatch, communityCode]);
  return !isSelect ? (
    <div className="community-creation-blocker">
      <div className="logo" />
      <h2>Let’s get started!</h2>
      <h3>Next, sign in to your new community on your desktop browser.</h3>
      <h3>You can continue setting up your community appearance, events & meeting from there.</h3>
      <img src={laptop_dashboard} alt="laptop" />
    </div>
  ) : (
    <div className="community-creation-blocker">
      <div className="logo" />
      <h2>You’re almost there!</h2>
      <h3>Please open the Altar Live App or use the desktop version to see all available events and meetings.</h3>
      <SquareButton onClick={handleClick} variant="orange">
        Open Altar Live App
      </SquareButton>
    </div>
  );
};

export default CommunityCreationBlocker;
