import React, { useState } from "react";
import { useFormikContext } from "formik";
import { Grid } from "@material-ui/core";
import classnames from "classnames";

import { ICommunityDialogValues } from "../CommunityDialog/CommunityDialog";

import EntityMainInfo from "shared/components/EntityMainInfo";
import { CopyItem } from "containers/Sermons";
import { TIMEZONES } from "shared/constants/time";
import { StyledLink } from "shared";
import community_default_image from "assets/images/community_default_image.svg";

import "./communityReadMode.scss";

type Props = {
  stripeLink?: string;
  copyInvitationHandler: (link: string) => void;
  canEdit: boolean;
};
const CommunityReadMode: React.FC<Props> = ({ copyInvitationHandler, canEdit, stripeLink }) => {
  const { values } = useFormikContext<ICommunityDialogValues>();
  const [imageError, setImageError] = useState(false);

  const timezone = TIMEZONES.find(({ value }) => value === values.timezone);

  const addressLine1 = [values.address_1, values.address_2].filter(Boolean).join(", ");
  const addressLine2 = [values.city, values.state].filter(Boolean).join(", ");

  return (
    <div className="community-dialog-view">
      <EntityMainInfo className="community-dialog-view-main">
        <div className="avatar">
          <img
            src={values.image_url && !imageError ? values.image_url : community_default_image}
            alt={values.name}
            onError={() => setImageError(true)}
          />
        </div>
        <div className="item">{values.name}</div>
        <div className="item">
          {addressLine1}
          {addressLine2 && (
            <>
              <br />
              {addressLine2}
            </>
          )}
        </div>
        {stripeLink && canEdit && (
          <StyledLink href={stripeLink} target="_self" className="stripe-link">
            Account Billing
          </StyledLink>
        )}
      </EntityMainInfo>
      <div className="community-dialog-view-additional">
        <Grid container>
          <Grid item xs={7} spacing={0} className="field">
            <div className="label">Country</div>
            <div className="value">{values.country}</div>
          </Grid>
          <Grid item xs={5} spacing={0} className="field">
            <div className="label">Zip or Postal code</div>
            <div className="value">{values.zipcode}</div>
          </Grid>
        </Grid>
        <Grid container className={classnames("last-row", { "with-border": canEdit })}>
          <Grid item xs={7} spacing={0} className="field">
            <div className="label">Time Zone</div>
            <div className="value">{timezone?.text || ""}</div>
          </Grid>
          <Grid item xs={5} spacing={0} className="field">
            <div className="label">Main contact</div>
            <div className="value">
              {values.main_contact ? `${values.main_contact.first_name} ${values.main_contact.last_name}` : ""}
            </div>
          </Grid>
        </Grid>
        {canEdit && (
          <Grid item xs={12} spacing={0} className="field">
            <div className="label">Invitation Link</div>
            <div className="value">
              <CopyItem
                copyText={values.invitation_link}
                onClick={() => copyInvitationHandler(values.invitation_link)}
              />
            </div>
            <p className="community-dialog-view-additional-description">
              Share this link to invite people to self-register
              <br />
              to your community.
            </p>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default CommunityReadMode;
