import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid } from "@material-ui/core";
import { Formik } from "formik";
import * as HttpStatus from "http-status-codes";
import _ from "lodash";

import { editProfileSchema } from "./formValidators";
import { IUserFormWithDuplicates } from "../../interfaces";
import { updateUser } from "../../store/actions";

import { getUser } from "containers/Auth/store/selectors";
import { AlertDialog, CustomFormikField, ImageUpload, ResponseError, SideDialog, DeleteUserButton } from "shared";
import { getImageBase64 } from "shared/utils/dataModifiers";
import { notificationActions } from "containers/Notifications/store/actions";
import { numberValidator } from "shared/constants/validations";
import { getCommunity } from "containers/Community/store/selectors";
import empty_person_2 from "assets/icons/empty_person_2.svg";

import "./styles.scss";

type Props = {
  onClose: () => void;
};

const EditProfile: React.FC<Props> = ({ onClose }) => {
  const currentUser = useSelector(getUser());
  const community = useSelector(getCommunity());

  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const initValues = useMemo<IUserFormWithDuplicates>(
    () => ({
      id: currentUser?.id || 0,
      image_url: currentUser?.image_url || null,
      first_name: currentUser?.first_name || "",
      last_name: currentUser?.last_name || "",
      phone: currentUser?.phone || "",
      email_duplicate: currentUser?.email || "",
      oldPassword: "",
      password_duplicate: "",
      confirmPassword: "",
    }),
    [currentUser],
  );

  const dispatch = useDispatch();

  const saveHandler = useCallback(
    (data: IUserFormWithDuplicates, { setFieldError }: { setFieldError: (field: string, message: string) => void }) => {
      const { email_duplicate, password_duplicate, image_url, ...rest } = data;
      const isImageChanged = (!!currentUser && currentUser.image_url !== image_url) || (!currentUser && !!image_url);
      const image_base64 = getImageBase64(image_url, isImageChanged);
      dispatch(
        updateUser.request({
          data: { ...rest, image_base64, email: email_duplicate, password: password_duplicate },
          callback: (err: ResponseError) => {
            if (err) {
              if (err.code === HttpStatus.UNPROCESSABLE_ENTITY && err.meta) {
                Object.entries(err.meta).forEach(([field, error]) => {
                  const fieldName = field === "email" ? "email_duplicate" : field;
                  setFieldError(fieldName, error);
                });
              } else {
                dispatch(notificationActions.error("Cannot update member", err.message || ""));
              }
            } else {
              dispatch(notificationActions.success("Your profile information is successfully updated"));
              onClose();
            }
          },
          community,
        }),
      );
    },
    [currentUser, dispatch, onClose, community],
  );
  const closeHandler = useCallback(
    (values: IUserFormWithDuplicates) => {
      if (_.isEqual(values, initValues)) {
        onClose();
      } else {
        setShowCloseConfirmation(true);
      }
    },
    [initValues, onClose],
  );

  return (
    <Formik
      validationSchema={editProfileSchema}
      initialValues={initValues}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={saveHandler}
      enableReinitialize
    >
      {({ values, errors, handleChange, handleSubmit, setFieldValue, validateField }) => {
        const onPhoneChangeHandler = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (e.target.value === "" || numberValidator.test(e.target.value)) {
            await setFieldValue("phone", e.target.value);
            validateField("phone");
          }
        };
        const changeFieldHandler =
          (field: string) => async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            await setFieldValue(field, e.target.value);
            validateField(field);
          };
        return (
          <>
            <AlertDialog
              open={showCloseConfirmation}
              title="Leaving Page"
              message="You have unsaved changes that will be lost if you continue"
              onConfirm={onClose}
              onCancel={() => setShowCloseConfirmation(false)}
              mode="confirm"
              confirmText="Leave"
            />
            <SideDialog
              onSave={handleSubmit}
              className="user-profile-dialog"
              onClose={() => closeHandler(values)}
              open={true}
              title="Edit Profile"
              saveText="save changes"
            >
              <Grid container className="user-profile-dialog-content">
                <Grid item className="uploader">
                  <ImageUpload
                    showOptions
                    maxMb={2}
                    value={values.image_url}
                    onChange={handleChange("image_url")}
                    backgroundImage={`url(${empty_person_2})`}
                    variant="circle"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormikField
                    fullWidth
                    label="First Name"
                    name="first_name"
                    errors={errors}
                    value={values.first_name}
                    onChange={changeFieldHandler("first_name")}
                    onBlur={() => validateField("first_name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormikField
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    errors={errors}
                    value={values.last_name}
                    onChange={changeFieldHandler("last_name")}
                    onBlur={() => validateField("last_name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormikField
                    fullWidth
                    label="Email address"
                    name="email_duplicate"
                    errors={errors}
                    value={values.email_duplicate}
                    onChange={handleChange}
                    onBlur={() => validateField("email_duplicate")}
                  />
                </Grid>
                {values.email_duplicate !== initValues.email_duplicate && (
                  <p className="email-help-text">
                    Please check your inbox after saving, the email will be updated only after the confirmation.
                  </p>
                )}
                <Grid item xs={12}>
                  <CustomFormikField
                    fullWidth
                    label="Phone"
                    name="phone"
                    errors={errors}
                    value={values.phone}
                    onChange={onPhoneChangeHandler}
                    onBlur={() => validateField("phone")}
                  />
                </Grid>
                <Divider className="divider" />
                <Grid item xs={12}>
                  <CustomFormikField
                    fullWidth
                    label="Current password"
                    name="oldPassword"
                    type="password"
                    errors={errors}
                    value={values.oldPassword}
                    autoComplete="new-password"
                    onChange={changeFieldHandler("oldPassword")}
                    onBlur={() => validateField("oldPassword")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormikField
                    fullWidth
                    label="New password"
                    name="password_duplicate"
                    type="password"
                    errors={errors}
                    value={values.password_duplicate}
                    onChange={handleChange}
                    onBlur={() => validateField("password_duplicate")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormikField
                    fullWidth
                    label="Confirm password"
                    name="confirmPassword"
                    type="password"
                    errors={errors}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={() => validateField("confirmPassword")}
                  />
                </Grid>
                <Divider className="divider" />
                <Grid item xs={12}>
                  <DeleteUserButton isSeatSamePage={false} closeEdit={onClose} />
                </Grid>
              </Grid>
            </SideDialog>
          </>
        );
      }}
    </Formik>
  );
};

export default EditProfile;
