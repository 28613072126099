import React, { useRef } from "react";

import "./threeDotsPopup.scss";

const ThreeDotsPopup: React.FC<{ skipScroll?: boolean }> = props => {
  const { children, skipScroll } = props;
  const popupRef = useRef<HTMLDivElement>(null);
  const hoverHandler = () => {
    if (popupRef.current && !skipScroll) {
      popupRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };
  return (
    <div className="three-dots-popup" onClick={e => e.stopPropagation()} onMouseOver={() => hoverHandler()}>
      <div ref={popupRef} className="three-dots-popup-wrapper">
        {children}
      </div>
    </div>
  );
};

export default ThreeDotsPopup;
