import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { FieldArray, useFormikContext } from "formik";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { useSelector } from "react-redux";

import { formListItemFromMembers } from "../../../../utils";
import Divider from "../Divider";
import { getMember } from "../../../../../Auth/store/selectors";

import { IListItem } from "shared/components/ListItemWithControls";
import { SelectMembersModal } from "shared/components/SelectMembersModal";
import { IEventForm } from "containers/Sermons/interfaces";
import { IGreeter, IMember } from "containers/Member/interfaces";
import { LINKS } from "shared/constants/links";
import { ListItemWithControls, StyledButton, StyledLink, SquareTextField, Button, StyledCheckBox } from "shared";
import { DEFAULT_GREETER_MESSAGE, MAX_GREETERS_COUNT } from "containers/Sermons/constants";
import { EEmptyStateTextType } from "shared/components/SelectMembersModal/interfaces";
import GreeterTitleAlert from "shared/components/GreeterTitleAlert/GreeterTitleAlert";
import { TextEditor } from "shared/components/TextEditor";

import "./styles.scss";

interface TeamStepInterface {
  isMeetingEvent: boolean;
}

const TeamStep: React.FC<TeamStepInterface> = ({ isMeetingEvent }) => {
  const currentMember = useSelector(getMember());

  const {
    values: { greeters, attendees, greeter_message, host_notes },
    setFieldValue,
    handleChange,
    errors,
  } = useFormikContext<IEventForm>();

  const [selectMembersOpened, setSelectMembersOpened] = useState(false);
  const [greeterErrorMessage, setGreeterErrorMessage] = useState("");
  const [greeterTitleAlertOpen, setGreeterTitleAlertOpen] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState<IListItem>();
  const [editorState, setEditorState] = useState(
    host_notes ? EditorState.createWithContent(convertFromRaw(JSON.parse(host_notes))) : EditorState.createEmpty(),
  );

  const greeterMessageIsDisabled = greeters.length === 0;
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (greeterMessageIsDisabled) {
      setFieldValue("greeter_message", "");
    } else {
      setGreeterErrorMessage("");
    }
  }, [greeterMessageIsDisabled, setFieldValue]);

  useEffect(() => {
    if (currentMember) {
      const contentState = editorState.getCurrentContent();
      const notesToSet = contentState.hasText()
        ? JSON.stringify(convertToRaw(contentState)).replace(/\p{Extended_Pictographic}/gu, "")
        : null;
      setFieldValue("host_notes", notesToSet);
      setFieldValue(
        "last_editor_host_notes",
        notesToSet ? `${currentMember.first_name} ${currentMember.last_name}` : null,
      );
    }
  }, [editorState, setFieldValue, currentMember]);

  const greetersList = useMemo<IListItem[]>(
    () =>
      formListItemFromMembers<IGreeter>(greeters, (greeter, item) => {
        item.is_admin = greeter.is_admin;
        item.title = greeter.title;
      }),
    [greeters],
  );

  const notAllowedMemberIds = useMemo(() => attendees.map(({ id }) => id), [attendees]);

  const closeSelectMembers = useCallback(() => {
    setSelectMembersOpened(false);
    contentRef.current && contentRef.current.focus();
  }, []);
  const openSelectMembers = useCallback(() => setSelectMembersOpened(true), []);
  const onSelectMembers = useCallback(
    (members: IMember[]) => {
      const existingIds = greeters.map(({ id }) => id);
      const newGreeters = [
        ...greeters,
        ...members
          .filter(({ id }) => !existingIds.includes(id))
          .map(member => ({ ...member, is_admin: isMeetingEvent, title: isMeetingEvent ? "Host" : "Greeter" })),
      ];
      setFieldValue("greeters", newGreeters);

      if (!!newGreeters.length && !greeter_message) {
        setFieldValue("greeter_message", DEFAULT_GREETER_MESSAGE);
      }
      closeSelectMembers();
    },
    [greeters, closeSelectMembers, isMeetingEvent, setFieldValue, greeter_message],
  );
  const onRemoveMember = useCallback(
    (idToRemove: number) =>
      setFieldValue(
        "greeters",
        greeters.filter(({ id }) => id !== idToRemove),
      ),
    [greeters, setFieldValue],
  );

  const onClickGreeterMessage = useCallback(() => {
    if (greeterMessageIsDisabled) {
      setGreeterErrorMessage("Please add at least one Greeter to create a greeting message.");
    }
  }, [greeterMessageIsDisabled]);

  const onEditMember = (selectedItem: IListItem) => {
    setSelectedListItem(selectedItem);
    setGreeterTitleAlertOpen(true);
  };

  const confirmGreeterTitle = useCallback(
    (values: { title: string }) => {
      const updGreeters = greeters.map(updGreeter => {
        return { ...updGreeter, title: updGreeter.id === selectedListItem?.id ? values.title : updGreeter.title };
      });
      setFieldValue("greeters", updGreeters);
      setGreeterTitleAlertOpen(false);
    },
    [selectedListItem, greeters, setFieldValue],
  );

  return (
    <div className="event-team-step">
      <GreeterTitleAlert
        initialValue={selectedListItem?.title || ""}
        onClose={() => setGreeterTitleAlertOpen(false)}
        opened={greeterTitleAlertOpen}
        onConfirm={confirmGreeterTitle}
      />
      <SelectMembersModal
        open={selectMembersOpened}
        onClose={closeSelectMembers}
        notAllowedMemberIds={notAllowedMemberIds}
        title="Select Members"
        multiselect
        allowSelectQty={MAX_GREETERS_COUNT - greeters.length}
        onSelect={onSelectMembers}
        emptyStateTextType={EEmptyStateTextType.chat}
        managerView
        styleTheme={"light"}
      />
      <div className="event-team-step-header">
        {!isMeetingEvent ? (
          <p>
            Add members to be the Hospitality Team during the event. The Hospitality Team can chat
            <br />
            with both general viewers and logged in members, answer questions, and manage
            <br />
            permissions and remove people from the event.
          </p>
        ) : (
          <p>
            Assign Hosts for this meeting <br />
            Hosts can end the meeting and remove participants from the meeting
          </p>
        )}
      </div>
      {!isMeetingEvent ? (
        <StyledLink className="learn-more" href={LINKS.hospitalityTeam}>
          Learn about team
        </StyledLink>
      ) : (
        <StyledLink className="learn-more" href={LINKS.hospitalityTeam}>
          Learn how to assign Hosts
        </StyledLink>
      )}
      <Divider />
      <div className="event-team-step-content" ref={contentRef} tabIndex={0}>
        {greeters.length > 0 ? (
          <div className="team">
            <FieldArray name="greeters">
              {() =>
                greetersList.map((greeter, i) => (
                  <ListItemWithControls
                    key={greeter.id}
                    item={{
                      ...greeter,
                      name: (
                        <div className="greeter">
                          <div className="greeter-name">
                            {greeter.name}
                            <div className="greeter-name-title">{greeter.title ? `(${greeter.title})` : ""}</div>
                          </div>

                          {!isMeetingEvent ? (
                            <div className="greeter-is-admin">
                              <label>
                                <StyledCheckBox
                                  checked={greeter.is_admin}
                                  onChange={handleChange}
                                  id={`greeters[${i}].is_admin`}
                                />
                                Host
                              </label>
                            </div>
                          ) : null}
                        </div>
                      ),
                    }}
                    onRemove={onRemoveMember}
                    onEdit={onEditMember}
                  />
                ))
              }
            </FieldArray>
            {greeters.length < MAX_GREETERS_COUNT && (
              <StyledButton type="button" className="add-member" onClick={openSelectMembers}>
                {!isMeetingEvent ? "+ Add member" : "+ Add Hosts"}
              </StyledButton>
            )}
          </div>
        ) : (
          <div className="empty-greeters">
            {!isMeetingEvent ? (
              <p className="message">No Greeters Selected</p>
            ) : (
              <p className="message">No Hosts Selected</p>
            )}
            <Button
              width={136}
              variant="blue-text"
              className="empty-add-member"
              onClick={openSelectMembers}
              type="button"
            >
              {!isMeetingEvent ? "+ Add member" : "+ Add Hosts"}
            </Button>
          </div>
        )}
        {!isMeetingEvent ? (
          <div
            onKeyUp={e => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            <div className="greeter-message">
              <SquareTextField
                fullWidth
                label="Greeting Message"
                name="greeter_message"
                value={greeter_message}
                onChange={handleChange}
                multiline
                rows={4}
                onClick={onClickGreeterMessage}
                disabled={greeterMessageIsDisabled}
              />
              {greeterErrorMessage && <p className="helper-text">{greeterErrorMessage}</p>}
            </div>
            <div className="hostNotes">
              <p className="hostNotes-info">
                Add notes for your Hosts to see during the event. Hosts can edit the notes inside the Event as well.
              </p>
              <StyledLink className="learn-more" href={LINKS.website}>
                Learn how to use Host Notes
              </StyledLink>
              <p className="hostNotes-label">Host Notes Content</p>
              <div className="hostNotes-helperText">{errors.host_notes}</div>
              <TextEditor editorState={editorState} setEditorState={setEditorState} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TeamStep;
