import React, { useMemo } from "react";
import classnames from "classnames";
import { CSVLink } from "react-csv";

import { MembersMenuTooltip } from "./MembersMenuTooltip";

import { Button } from "shared/components";
import SelectedMembers from "shared/components/SelectMembersModal/SelectedMembers/SelectedMembers";
import { IMember } from "containers/Member/interfaces";

import "./styles.scss";

interface Props {
  onSelectAll: () => void;
  onUnselectAll: () => void;
  onRemove?: () => void;
  onEdit?: () => void;
  onInvite?: () => void;
  show: boolean;
  members: IMember[];
}

const MembersMenu: React.FC<Props> = ({ onSelectAll, onUnselectAll, onRemove, onEdit, onInvite, members, show }) => {
  const descriptionStyles = {
    marginLeft: members.length > 1 ? (members.length === 2 ? 76 : 88) : 6,
  };

  const dataToCSV = useMemo(
    () =>
      members.map(({ first_name, last_name, email: Email }) => ({
        "First name": first_name,
        "Last name": last_name,
        Email,
      })),
    [members],
  );

  return (
    <div className={classnames("membersMenu", { show })}>
      <div className="membersMenu-members">
        <SelectedMembers members={members} showUnits={false} />
        <div className="membersMenu-members-description" style={descriptionStyles}>
          <div className="membersMenu-members-description-count">{members.length}</div>
          <p>{`${members.length > 1 ? "members" : "member"} selected`}</p>
        </div>
      </div>
      <div className="membersMenu-actions">
        <Button onClick={onUnselectAll} variant="blue-text" type="button" className="button" width={94} height={24}>
          Unselect all
        </Button>
        <Button onClick={onSelectAll} variant="blue-text" type="button" className="selectAll" width={75} height={24}>
          Select all
        </Button>
        {onRemove && (
          <MembersMenuTooltip title="Remove" classNames="red">
            <Button
              onClick={onRemove}
              variant="blue-text"
              type="button"
              className="remove icon"
              width={40}
              height={40}
            />
          </MembersMenuTooltip>
        )}
        {onEdit && (
          <MembersMenuTooltip title="Edit">
            <Button onClick={onEdit} variant="blue-text" type="button" className="edit icon" width={40} height={40} />
          </MembersMenuTooltip>
        )}

        <MembersMenuTooltip title="Export Member List">
          <CSVLink data={dataToCSV} filename={"members_list.csv"}>
            <Button variant="blue-text" type="button" className="export icon" width={40} height={40} />
          </CSVLink>
        </MembersMenuTooltip>
        {onInvite && (
          <MembersMenuTooltip title="Invite Again">
            <Button onClick={onInvite} variant="blue-text" type="button" className="mail icon" width={40} height={40} />
          </MembersMenuTooltip>
        )}
      </div>
    </div>
  );
};

export default MembersMenu;
