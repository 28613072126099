import React, { useCallback, useMemo, useState } from "react";
import { Field, useFormikContext } from "formik";
import { FormControlLabel } from "@material-ui/core";

import Divider from "../Divider";

import { ListItemWithControls, SquareButton, StyledCheckBox, StyledLink } from "shared";
import { SelectMembersModal } from "shared/components/SelectMembersModal";
import { EventType, IEventForm, PresenterType, SermonRestriction } from "containers/Sermons/interfaces";
import { IMember } from "containers/Member/interfaces";
import SquareRadio, { IRadioInput } from "shared/components/SquareRadio";
import { IListItem } from "shared/components/ListItemWithControls";
import { formListItemFromMembers, getEventTypeName } from "containers/Sermons/utils";
import { LINKS } from "shared/constants/links";
import { EEmptyStateTextType } from "shared/components/SelectMembersModal/interfaces";

import "./styles.scss";

const PermissionsStep: React.FC = () => {
  const [selectMembersOpened, setSelectMembersOpened] = useState(false);

  const {
    values: { attendees, restriction, greeters, producer, presenters, type, is_locked },
    errors: { attendees: attendeesError = "" },
    touched,
    setFieldValue,
    handleChange,
  } = useFormikContext<IEventForm>();

  const notAllowedMemberIds = useMemo<number[]>(() => {
    const usedMemberIds = [
      ...greeters.map(({ id }) => id),
      ...presenters.filter(({ type }) => type === PresenterType.member).map(({ memberId }) => Number(memberId)),
    ];
    if (producer) {
      usedMemberIds.push(producer.id);
    }
    return usedMemberIds;
  }, [greeters, presenters, producer]);

  const attendeesList = useMemo<IListItem[]>(() => formListItemFromMembers(attendees), [attendees]);

  const eventTypeName = useMemo(() => getEventTypeName(type), [type]);

  const closeSelectMembers = useCallback(() => setSelectMembersOpened(false), []);
  const openSelectMembers = useCallback(() => setSelectMembersOpened(true), []);
  const onSelectMembers = useCallback(
    (members: IMember[]) => {
      const existingIds = attendees.map(({ id }) => id);
      setFieldValue("attendees", [...attendees, ...members.filter(({ id }) => !existingIds.includes(id))]);
      closeSelectMembers();
    },
    [attendees, closeSelectMembers, setFieldValue],
  );
  const onRemoveMember = useCallback(
    (idToRemove: number) =>
      setFieldValue(
        "attendees",
        attendees.filter(({ id }) => id !== idToRemove),
      ),
    [attendees, setFieldValue],
  );

  const radioInputs = useMemo<IRadioInput[]>(
    () => [
      {
        value: SermonRestriction.all,
        label: (
          <>
            <div className="label-heading">Anyone</div>
            <p className="label-description">
              {`Public ${eventTypeName} that anyone can join anonymously or logged in`}
            </p>
          </>
        ),
      },
      {
        value: SermonRestriction.invited,
        label: (
          <>
            <div className="label-container">
              <div className="label-heading">Selected Members</div>
              {restriction === SermonRestriction.invited && (
                <SquareButton
                  type="button"
                  variant="blue-text"
                  width={63}
                  height={24}
                  className="close"
                  onClick={openSelectMembers}
                >
                  Select
                </SquareButton>
              )}
            </div>
            <p className="label-description">{`Private ${eventTypeName} that requires invitation`}</p>
          </>
        ),
        classes: {
          root: `invited ${restriction === SermonRestriction.invited && attendees.length > 0 ? "active" : ""}`,
          label: "invited-label",
        },
      },
    ],
    [attendees.length, openSelectMembers, restriction, eventTypeName],
  );

  return (
    <div className="event-permissions-step">
      <SelectMembersModal
        open={selectMembersOpened}
        onClose={closeSelectMembers}
        notAllowedMemberIds={notAllowedMemberIds}
        title="Select Members"
        multiselect
        onSelect={onSelectMembers}
        emptyStateTextType={EEmptyStateTextType.event}
        managerView
        styleTheme={"light"}
      />

      <div className="event-permissions-step-header">Select viewing permissions below.</div>
      <StyledLink className="learn-more" href={LINKS.permissions}>
        Learn how to add Members
      </StyledLink>
      <Divider />
      <div className="event-permissions-step-content">
        {type === EventType.meeting ? (
          <div className="lockEvent">
            <div className="lockEvent-description">
              Choose whether to Lock this Meeting. Guests need to request permission to enter Locked Meetings.
            </div>
            <Field name="is_locked">
              {() => (
                <FormControlLabel
                  control={
                    <StyledCheckBox onChange={handleChange} name="is_locked" checked={is_locked} withBorder={false} />
                  }
                  label="Lock Meeting"
                />
              )}
            </Field>
            <div className="lockEvent-additional-label">Require that participants request permission to enter.</div>
          </div>
        ) : null}
        <p className="label">Who Can Join</p>
        <Field name="restriction">
          {() => (
            <>
              <SquareRadio
                name="restriction"
                value={restriction}
                onChange={handleChange}
                className="restrictions-radio"
                variants={radioInputs}
                error={touched.attendees && (attendeesError as string)}
              />
              {restriction === SermonRestriction.invited && attendees.length > 0 && (
                <Field name="attendees">
                  {() => (
                    <div className="attendees">
                      {attendeesList.map(attendee => (
                        <ListItemWithControls
                          key={attendee.id}
                          item={attendee}
                          onRemove={onRemoveMember}
                          className="attendee"
                          onEdit={() => {}}
                        />
                      ))}
                    </div>
                  )}
                </Field>
              )}
            </>
          )}
        </Field>
      </div>
    </div>
  );
};

export default PermissionsStep;
