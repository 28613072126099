import { request } from "../../../shared/utils";
import { API, METHODS } from "../../../shared/constants";
import {
  AuthShape,
  SocialAuthShape,
  RegisterShape,
  ResetShape,
  CheckUSerToken,
  ISendLoginLinkPayload,
} from "../interfaces";
import { ECreationKind } from "../../../shared";

export default {
  login: (payload: AuthShape) => request(METHODS.POST, API.LOGIN)(payload),
  socialLogin: ({ kind, ...rest }: SocialAuthShape) =>
    request(METHODS.POST, API.SOCIAL_LOGIN)(rest, { params: { kind } }),
  magicLogin: (payload: { hash: string }) => request(METHODS.POST, API.MAGIC_LOGIN)(payload),
  registration: (payload: RegisterShape) => request(METHODS.POST, API.REGISTRATION)(payload),
  getUserInfo: () => request(METHODS.GET, API.USER_INFO)(),
  forgot: (payload: { email: string; redirectTo?: string; communityCreation?: boolean }) =>
    request(METHODS.POST, API.FORGOT)(payload),
  reset: (payload: ResetShape) => request(METHODS.POST, API.RESET)(payload),
  confirmVerifyUser: (payload: CheckUSerToken) => request(METHODS.GET, API.CONFIRM_VERIFY(payload.token))(),
  confirmVerifyUserByCode: (payload: { code: string }) =>
    request(METHODS.GET, API.CONFIRM_VERIFY_BY_CODE(payload.code))(),
  getMember: (community_id: number, kind?: ECreationKind) =>
    request(METHODS.GET, API.FETCH_CURRENT_MEMBER(community_id))({ params: { kind } }),
  sendMagicLink: (payload: ISendLoginLinkPayload) => request(METHODS.PATCH, API.SEND_MAGIC_LINK)(payload),
  getTokenByHash: (hash: string) => request(METHODS.GET, API.GET_TOKEN_BY_HASH(hash))(),
};
