import React, { useState } from "react";
import classnames from "classnames";

import { ICommunityLandingSettings } from "containers/Community";

import "./index.scss";

interface LandingPageHeaderProps {
  settings: ICommunityLandingSettings;
  communityName: string;
  navigateToAdmin: () => void;
  isAdmin: boolean;
}

export default function LandingPageHeader({
  settings,
  communityName,
  navigateToAdmin,
  isAdmin,
}: LandingPageHeaderProps) {
  const [mobileOpened, setMobileOpened] = useState(false);
  return (
    <div className="landing-header">
      <div className="landing-header-wrapper">
        <div className="logo-wrapper">
          {settings.logo ? <img src={settings.logo} alt="community-logo" /> : null}
          <div
            className={classnames(
              "logo-wrapper-community",
              communityName.length > 126 ? "sm" : communityName.length > 62 ? "md" : "lg",
            )}
          >
            {communityName}
          </div>
        </div>
        <div className="mobile-nav">
          {!!settings.links.length && (
            <div
              className={`mobile-menu-button ${mobileOpened ? "opened" : ""}`}
              onClick={() => setMobileOpened(v => !v)}
            />
          )}
        </div>
        <hr />
        <div className={`links-wrapper ${mobileOpened ? "opened" : ""}`}>
          {settings.links.map(l => (
            <a href={l.value} target="_blank" rel="noopener noreferrer" key={l.id}>
              {l.name}
            </a>
          ))}
        </div>
        {isAdmin && (
          <div className="admin-panel-button" onClick={navigateToAdmin}>
            Admin
          </div>
        )}
      </div>
    </div>
  );
}
