import { createMuiTheme } from "@material-ui/core";

import manifestTheme from "../styles/variables.module.scss";

export const dialogTheme = createMuiTheme({
  typography: {
    fontFamily: manifestTheme.fontHeebo,
  },
  palette: {
    primary: {
      main: manifestTheme.greyColor,
    },
    error: {
      main: manifestTheme.redColor,
    },
    background: {
      default: manifestTheme.whiteColor,
    },
  },
  overrides: {
    MuiGrid: {
      item: {
        paddingTop: "24px",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "14px",
      },
      input: {
        "&::placeholder": {
          opacity: 1,
          color: manifestTheme.greyColor,
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 1.5px) scale(1)",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "12px",
        color: manifestTheme.greyColor,
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: `1px solid ${manifestTheme.lightGreyColor}`,
        },
        "&&&&:hover:before": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
        },
      },
    },
  },
});
