import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useClickOutside } from "react-click-outside-hook";

import open_arrow from "assets/icons/open_arrow.svg";
import closed_arrow from "assets/icons/closed_arrow.svg";

import "./colorPicker.scss";

interface ColorPickerProps {
  onChange: (value: string) => void;
  color: string;
}

const ColorPicker: React.FC<ColorPickerProps> = props => {
  const { color, onChange } = props;

  const [open, setOpen] = useState(false);
  const [ref, hasClickedOutside] = useClickOutside();

  useEffect(() => {
    if (hasClickedOutside) {
      setOpen(false);
    }
  }, [hasClickedOutside]);

  return (
    <div className="colorpicker-wrap" ref={ref}>
      <div className={`colorpicker ${open ? "colorpicker-open" : ""}`} onClick={() => setOpen(!open)}>
        <div
          className="colorpicker-selectedColor"
          style={{
            backgroundColor: color,
          }}
        />
        <div className="arrow-container">
          {open ? <img src={open_arrow} alt="open_arrow" /> : <img src={closed_arrow} alt="closed_arrow" />}
        </div>
      </div>
      <div className="colorpicker-dropdown">
        {open ? <SketchPicker color={color} onChange={val => onChange(val.hex)} /> : null}
      </div>
    </div>
  );
};

export default ColorPicker;
