import React, { useCallback, useMemo } from "react";
import { Field, FieldAttributes, useFormikContext } from "formik";
import moment from "moment";

import { AnnouncementStatus, IAnnouncementInteraction, IEventForm, IPoll, PollStatus } from "../../../../interfaces";

import { TimerPicker } from "shared/components/StyledTimerPicker";
import { InfoIcon, CustomSwitchControl } from "shared";
import edit_icon_blue from "assets/icons/edit_icon_blue.svg";
import ic_remove from "assets/icons/ic_remove.svg";

import "./styles.scss";

export enum EInteractionKind {
  polls = "polls",
  announcements = "announcements",
}
export interface IInteraction {
  interaction: IPoll | IAnnouncementInteraction;
  kind: EInteractionKind;
  interactionIndex: number | null;
}

interface IProps {
  interactionInfo: IInteraction;
  editHandler: () => void;
  deleteHandler: () => void;
  onMouseDown: () => void;
}

const Interaction: React.FC<IProps> = ({
  interactionInfo: { interaction, interactionIndex, kind },
  editHandler,
  deleteHandler,
  onMouseDown,
}) => {
  const {
    setFieldValue,
    values: { starting_at },
  } = useFormikContext<IEventForm>();

  const postAutomatically = useMemo(() => interaction.status === "scheduled", [interaction.status]);
  const allowEdit = useMemo(() => {
    return kind === EInteractionKind.polls
      ? [PollStatus.draft, PollStatus.scheduled].includes(interaction.status as PollStatus)
      : [AnnouncementStatus.draft, AnnouncementStatus.scheduled].includes(interaction.status as AnnouncementStatus);
  }, [interaction.status, kind]);

  const changeInteractionStatus = useCallback(() => {
    setFieldValue(`${kind}[${interactionIndex}][status]`, postAutomatically ? "draft" : "scheduled");
    setFieldValue(`${kind}[${interactionIndex}][scheduled_at]`, postAutomatically ? null : moment(starting_at));
  }, [interactionIndex, kind, postAutomatically, setFieldValue, starting_at]);

  const submitTimerPicker = useCallback(
    (value: number) => {
      setFieldValue(`${kind}[${interactionIndex}][scheduled_at]`, moment(starting_at).add(value, "minutes"));
    },
    [interactionIndex, kind, setFieldValue, starting_at],
  );

  return (
    <div className="interaction" onMouseDown={onMouseDown}>
      <div className="interaction-body">
        <div className="interaction-body-type">
          <div className="interaction-body-type-title">{kind}</div>
          <div className="interaction-body-type-picker">{interaction.title}</div>
        </div>
        {postAutomatically && (
          <div className="interaction-body-postTime">
            <div className="interaction-body-postTime-title">
              <div className="interaction-body-postTime-title-text">Post at</div>
              <InfoIcon
                text={
                  <>
                    "Post at" time is calculated from the start time of the event. For example, if you want to send a
                    interaction 10 minutes into the event starting, set the post time for 00:10.
                  </>
                }
              />
            </div>
            <div className="interaction-body-postTime-picker">
              <Field name={`${kind}[${interactionIndex}][scheduled_at]`}>
                {({ field, form, meta }: FieldAttributes<any>) => (
                  <TimerPicker
                    {...field}
                    {...form}
                    {...meta}
                    onSubmit={submitTimerPicker}
                    start_time={starting_at}
                    popoverClass="interactionTimer-picker"
                  />
                )}
              </Field>
            </div>
          </div>
        )}
        {interaction && (
          <div className="interaction-body-action">
            {allowEdit ? <img src={edit_icon_blue} alt="edit" onClick={editHandler} /> : null}
            <img src={ic_remove} alt="remove" onClick={deleteHandler} />
          </div>
        )}
      </div>
      <div className="interaction-footer">
        {allowEdit ? (
          <CustomSwitchControl
            className="interaction-footer-switcher"
            checked={postAutomatically}
            onChange={changeInteractionStatus}
            label="Post Automatically"
          />
        ) : (
          <div />
        )}
        <div className={`interaction-footer-status ${interaction.status}`}>{interaction.status}</div>
      </div>
    </div>
  );
};

export default Interaction;
