import React, { FC, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

import { checkMeetingRole } from "../../../utils";
import { SermonRoles } from "../../../interfaces";
import { getMember } from "../../../../Auth/store/selectors";
import { updateSermon } from "../../../store/actions";
import { getEvent } from "../../../store/selectors";
import { EventHostNotesContent } from "../EventHostNotesContent";
import { notificationActions } from "../../../../Notifications/store/actions";
import { HOST_NOTES_CHARACTER_LIMIT } from "../../../constants";

import { AlertDialog, Button, EventSideDialog } from "shared";
import { TextEditor } from "shared/components/TextEditor";

import "./EventHostNotes.scss";

interface IEventHostNotesDialogProps {
  open: boolean;
  onClose?: () => void;
}

const EventHostNotes: FC<IEventHostNotesDialogProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const currentEvent = useSelector(getEvent());
  const currentMember = useSelector(getMember());

  const hostNotes = currentEvent?.host_notes;

  const initialEditorState = useMemo(() => {
    return hostNotes ? EditorState.createWithContent(convertFromRaw(JSON.parse(hostNotes))) : EditorState.createEmpty();
  }, [hostNotes]);

  const [editNotes, setEditNotes] = useState(false);
  const [unsavedChangesAlert, setUnsavedChangesAlert] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [editorState, setEditorState] = useState(initialEditorState);

  const canManageHostNotes = useMemo(() => {
    const hasAccessToManage =
      currentEvent &&
      currentMember?.id &&
      checkMeetingRole(currentEvent, currentMember, [SermonRoles.greeter, SermonRoles.host, SermonRoles.greeterHost]);

    return Boolean(hasAccessToManage);
  }, [currentEvent, currentMember]);

  const hasUnsavedChanges = useMemo(() => {
    if (hostNotes === null) {
      return editorState.getCurrentContent().hasText();
    } else {
      return hostNotes !== JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    }
  }, [hostNotes, editorState]);

  const addHostNotes = useCallback(() => {
    setEditNotes(true);
    setEditorState(initialEditorState);
  }, [initialEditorState]);

  const updateHostNotes = useCallback(() => {
    if (currentEvent && currentMember && hasUnsavedChanges) {
      const contentState = editorState.getCurrentContent();
      if (!contentState.hasText()) {
        setShowDeleteAlert(true);
        return;
      } else if (contentState.getPlainText().length >= HOST_NOTES_CHARACTER_LIMIT) {
        dispatch(
          notificationActions.error(
            "Cannot save changes",
            "Text is too long and cannot be saved. Text must be less than 15,000 characters.",
          ),
        );
        return;
      } else {
        dispatch(
          updateSermon.request({
            data: {
              host_notes: JSON.stringify(convertToRaw(contentState)).replace(/(\p{Extended_Pictographic})/gu, ""),
              last_editor_host_notes: `${currentMember.first_name} ${currentMember.last_name}`,
              id: currentEvent.id,
            },
          }),
        );
      }
    }
    setEditNotes(false);
  }, [dispatch, editorState, currentEvent, currentMember, hasUnsavedChanges]);

  const deleteHostNotes = useCallback(() => {
    if (currentEvent) {
      dispatch(
        updateSermon.request({
          data: {
            host_notes: null,
            last_editor_host_notes: null,
            id: currentEvent.id,
          },
        }),
      );
      setEditorState(EditorState.createEmpty());
      setShowDeleteAlert(false);
      setEditNotes(false);
    }
  }, [currentEvent, dispatch]);

  const onBack = useCallback(() => {
    if (hasUnsavedChanges) {
      setUnsavedChangesAlert(true);
    } else {
      setEditNotes(false);
    }
  }, [hasUnsavedChanges]);

  return (
    <>
      <AlertDialog
        mode="confirm"
        open={showDeleteAlert}
        confirmText="Delete"
        title="Delete the Host Notes?"
        message="Are you sure that you want to delete the Host Notes?"
        onCancel={() => setShowDeleteAlert(false)}
        onConfirm={deleteHostNotes}
        variant="brown"
        confirmClassName="defaultButtons-remove"
      />
      <AlertDialog
        mode="confirm"
        open={unsavedChangesAlert}
        confirmText="Leave"
        title="Are you sure you want to leave?"
        message="You have unsaved changes."
        onCancel={() => setUnsavedChangesAlert(false)}
        onConfirm={() => {
          setEditNotes(false);
          setUnsavedChangesAlert(false);
          setEditorState(initialEditorState);
        }}
        variant="brown"
        confirmClassName="defaultButtons-confirm"
      />
      <EventSideDialog
        className="eventside-dialog-hostNotes"
        title="Host Notes"
        open={open}
        onClose={!editNotes ? onClose : undefined}
        onBack={editNotes ? onBack : undefined}
      >
        <div className="hostNotes">
          {hostNotes && !editNotes ? (
            <EventHostNotesContent
              hostNotes={hostNotes}
              editedBy={currentEvent?.last_editor_host_notes || ""}
              onEdit={() => {
                setEditorState(initialEditorState);
                setEditNotes(true);
              }}
              onDelete={() => setShowDeleteAlert(true)}
            />
          ) : editNotes ? (
            <div className="hostNotes-editor">
              <TextEditor editorState={editorState} setEditorState={setEditorState} isEvent />
            </div>
          ) : (
            <div className="hostNotes-emptyState">
              <div className="hostNotes-emptyState-icon" />
              <div className="hostNotes-emptyState-title">No Host Notes yet</div>
            </div>
          )}
          {canManageHostNotes && (!currentEvent?.host_notes || editNotes) ? (
            <div className="hostNotes-addButton">
              <Button variant="orange" onClick={editNotes ? updateHostNotes : addHostNotes}>
                {editNotes ? "Save Changes" : "Add Host Notes"}
              </Button>
            </div>
          ) : null}
        </div>
      </EventSideDialog>
    </>
  );
};

export default EventHostNotes;
