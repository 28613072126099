import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { Button } from "../../../Button";

import { getUnreadMessagesCount } from "containers/Discussions/store/selectors";
import cross_icon_grey from "assets/icons/cross_icon_grey.svg";

export enum DiscussionTab {
  general = "general",
  personal = "personal",
}

export interface EventSideDialogDiscussionTabsProps {
  onClose?: () => void;
  onChangeTab?: (newTab: DiscussionTab) => void;
  selectedTab?: DiscussionTab;
}

const EventSideDialogDiscussionTabs: React.FC<EventSideDialogDiscussionTabsProps> = props => {
  const { onClose, onChangeTab, selectedTab = DiscussionTab.general } = props;
  const { personalUnread, eventGeneralUnread } = useSelector(getUnreadMessagesCount());

  return (
    <div className="eventSideDialog-header tabs">
      <div className="eventSideDialog-header-title">
        <div className="eventSideDialog-header-tabs">
          <div
            className={classNames("eventSideDialog-header-tab", {
              active: selectedTab === DiscussionTab.general,
              notification: !!eventGeneralUnread,
            })}
          >
            <Button
              fontSize="22"
              variant={selectedTab === DiscussionTab.general ? "white-text" : "custom-text"}
              color="#93808d"
              onClick={() => onChangeTab?.(DiscussionTab.general)}
            >
              General
            </Button>
          </div>
          <div
            className={classNames("eventSideDialog-header-tab", {
              active: selectedTab === DiscussionTab.personal,
              notification: !!personalUnread,
            })}
          >
            <Button
              fontSize="22"
              variant={selectedTab === DiscussionTab.personal ? "white-text" : "custom-text"}
              color="#93808d"
              onClick={() => onChangeTab?.(DiscussionTab.personal)}
            >
              My Chats
            </Button>
          </div>
        </div>
        {onClose && (
          <Button width={24} variant="black-text" className="eventSideDialog-close-btn" onClick={onClose}>
            <img src={cross_icon_grey} alt="cross icon" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default EventSideDialogDiscussionTabs;
