import { matchPath } from "react-router";
import moment from "moment";

import config from "../../../config";
import { NamesOfParentRoutes } from "../../../constants";
import { ICommunityDialogValues } from "../components/Community/CommunityDialog/CommunityDialog";
import { EAnalyticsRange, ICommunity, ICommunityLandingSettings, IProduct } from "../interfaces";
import { AnnouncementType, IAnnouncementTemplate, IPollTemplate } from "../../Sermons";

import { getTimezone } from "shared/utils";

export const generateInvitationLink = (hash: string): string =>
  `${config.applicationUrl}${NamesOfParentRoutes.REGISTER}/${hash}`;

export const isMatchedPath = (locationPath: string, pathToCheck: string | string[], exact = true) => {
  if (typeof pathToCheck === "string") {
    return !!matchPath(locationPath, {
      path: pathToCheck,
      exact,
    });
  }
  return pathToCheck.some(
    path =>
      !!matchPath(locationPath, {
        path: path,
        exact,
      }),
  );
};

export const prepareCommunityForm = (community?: ICommunity | null): ICommunityDialogValues => {
  return {
    name: community ? community.name : "",
    main_contact: community ? community.main_contact : null,
    address_1: community ? community.address_1 : "",
    address_2: community ? community.address_2 : "",
    city: community ? community.city : "",
    state: community ? community.state : "",
    zipcode: community ? community.zipcode : "",
    country: community ? community.country : "",
    image_url: community ? community.image_url : undefined,
    visibility: community ? community.visibility : true,
    invitation_link: community ? generateInvitationLink(community.hash) : "",
    timezone: community ? community.timezone : getTimezone(),
    website: community ? community.website : "",
    ga_id: community?.ga_id ?? "",
    is_verified: community ? community.is_verified : false,
  };
};

export const prepareLandingSettingsForm = (
  settings?: ICommunityLandingSettings | null,
  defaultSubdomain = "",
): ICommunityLandingSettings => {
  return {
    community_id: settings?.community_id || 0,
    subdomain: settings?.subdomain || defaultSubdomain,
    theme_color: settings?.theme_color || "dark",
    accent_color: settings?.accent_color || "#E1A077",
    text_color: settings?.text_color || "white",
    text_case: settings?.text_case || "uppercase",
    logo: settings?.logo || "",
    links: settings?.links || [],
    favicon_url: settings?.favicon_url || null,
  };
};

export const defineAnalyticsDateRange = (rangeType: EAnalyticsRange, from?: Date, to?: Date) => {
  let unit: moment.DurationInputArg2;
  const format = "MM-DD-YYYY";
  switch (rangeType) {
    case EAnalyticsRange.last_week:
      unit = "weeks";
      break;
    case EAnalyticsRange.last_month:
      unit = "month";
      break;
    case EAnalyticsRange.last_year:
      unit = "year";
      break;
    case EAnalyticsRange.custom:
      return { from: moment(from).format(format), to: moment(to).format(format) };
    default:
      unit = "weeks";
  }
  return { from: moment().subtract(1, unit).format(format), to: moment().format(format) };
};

export const preparePollTemplateForm = (community_id: number, pollTemplate?: IPollTemplate | null): IPollTemplate => {
  return {
    id: pollTemplate?.id,
    title: pollTemplate?.title || "",
    poll_question: pollTemplate?.poll_question || "",
    multiple_answers: pollTemplate?.multiple_answers || false,
    show_results: pollTemplate?.show_results || false,
    options: pollTemplate?.options || [{ option_text: "" }, { option_text: "" }],
    community_id,
  };
};

export const prepareAnnouncementTemplateForm = (
  community_id: number,
  announcementTemplate?: IAnnouncementTemplate | null,
) => {
  return {
    id: announcementTemplate?.id,
    title: announcementTemplate?.title || "",
    description: announcementTemplate?.description || "",
    button_text: announcementTemplate?.button_text || "",
    button_link: announcementTemplate?.button_link || "",
    type: announcementTemplate?.type || AnnouncementType.panel,
    use_button: Boolean(announcementTemplate?.button_text),
    community_id,
  };
};

export const getMAUSliderMarks = (products: IProduct[], maxUsers: number) => {
  const overLimitMAU = maxUsers + maxUsers / 2;
  return [
    ...products.map((p, index) => ({
      value: index,
      label: p.licensed_member_count,
      mau: p.licensed_member_count,
    })),
    {
      value: products.length,
      label: `${overLimitMAU}+`,
      mau: overLimitMAU,
    },
  ];
};
