import "./index.scss";

import React, { useMemo } from "react";
import classNames from "classnames";
import moment from "moment";

import { AnnouncementStatus, AnnouncementStatusMap, IAnnouncement } from "containers/Sermons/interfaces";
import { Button, ThreeDotsPopup } from "shared";

interface IAnnouncementCardProps {
  announcement: IAnnouncement;
  canManageAnnouncements: boolean;
  onEdit: (announcement: IAnnouncement) => void;
  onDelete: (announcement: IAnnouncement) => void;
  onChangeStatus: (announcement: IAnnouncement, status: AnnouncementStatus) => void;
  onView: (announcement: IAnnouncement) => void;
}

const AnnouncementCard: React.FC<IAnnouncementCardProps> = props => {
  const { announcement, canManageAnnouncements, onChangeStatus, onEdit, onDelete, onView } = props;

  const renderButton = useMemo(() => {
    if (!announcement.button_text || !announcement.button_link) {
      return null;
    }
    return (
      <Button
        className="announcement-main-button"
        variant="orange-outline"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          window.open(announcement.button_link);
        }}
      >
        {announcement.button_text}
      </Button>
    );
  }, [announcement]);

  const renderDraftControls = useMemo(() => {
    return (
      <div className="announcement-controls draft">
        <Button
          className="announcement-button-publish"
          variant="white-text"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            onChangeStatus(announcement, AnnouncementStatus.published);
          }}
        >
          Publish
        </Button>
        <ThreeDotsPopup skipScroll={true}>
          <div className="announcement-dots-popup-item" onClick={() => onEdit(announcement)}>
            Edit
          </div>
          <div className="announcement-dots-popup-item delete" onClick={() => onDelete(announcement)}>
            Delete
          </div>
        </ThreeDotsPopup>
      </div>
    );
  }, [announcement, onEdit, onDelete, onChangeStatus]);

  const renderScheduledControls = useMemo(() => {
    return (
      <div className="announcement-controls scheduled">
        <Button
          className="announcement-button-publish"
          variant="white-text"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            onChangeStatus(announcement, AnnouncementStatus.published);
          }}
        >
          Publish
        </Button>
        <ThreeDotsPopup skipScroll={true}>
          <div className="announcement-dots-popup-item" onClick={() => onEdit(announcement)}>
            Edit
          </div>
          {announcement.status === AnnouncementStatus.scheduled ? (
            <div
              className="announcement-dots-popup-item unschedule"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onChangeStatus(announcement, AnnouncementStatus.draft);
              }}
            >
              Unschedule
            </div>
          ) : null}
          <div className="announcement-dots-popup-item delete" onClick={() => onDelete(announcement)}>
            Delete
          </div>
        </ThreeDotsPopup>
      </div>
    );
  }, [announcement, onDelete, onEdit, onChangeStatus]);

  const renderPublishedControls = useMemo(() => {
    return (
      <div className="announcement-controls published">
        <div
          className="announcement-button-delete"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            onDelete(announcement);
          }}
        />
      </div>
    );
  }, [onDelete, announcement]);

  const renderControls = useMemo(() => {
    return announcement.status === AnnouncementStatus.draft
      ? renderDraftControls
      : announcement.status === AnnouncementStatus.scheduled
      ? renderScheduledControls
      : renderPublishedControls;
  }, [announcement.status, renderDraftControls, renderPublishedControls, renderScheduledControls]);

  const renderFooter = useMemo(() => {
    if (!canManageAnnouncements) {
      return null;
    }
    return (
      <>
        <div className="announcement-divider" />
        <div className="announcement-footer">
          <div className={classNames("announcement-footer-status", announcement.status)}>
            {AnnouncementStatusMap[announcement.status]}
          </div>
          <div className="announcement-footer-controls">{renderControls}</div>
        </div>
      </>
    );
  }, [announcement, renderControls, canManageAnnouncements]);

  const creatorFullName = useMemo(() => {
    return announcement.status === AnnouncementStatus.scheduled
      ? "Will be published at"
      : `${announcement.creator.first_name} ${announcement.creator.last_name}`;
  }, [announcement]);

  const formattedCreatedAt = useMemo(() => {
    return moment(
      announcement.status === AnnouncementStatus.scheduled ? announcement.scheduled_at : announcement.created_at,
    ).format("hh:mm A");
  }, [announcement]);

  return (
    <div className="announcement-card" onClick={() => onView(announcement)}>
      <div className="announcement-title">{announcement.title}</div>
      <div className="announcement-created">
        {creatorFullName} {formattedCreatedAt}
      </div>
      <div className="announcement-description">{announcement.description}</div>
      {renderButton}
      {renderFooter}
    </div>
  );
};

export default AnnouncementCard;
