import React from "react";

import information from "assets/icons/information-blue.svg";

import "./styles.scss";

interface Props {
  text: string | React.ReactElement;
  className?: string;
}

const InfoIcon: React.FC<Props> = ({ text, className }) => {
  return (
    <div className={`infoIcon ${className ? className : ""}`}>
      <img src={information} alt="information" className="infoIcon-image" />
      <div className="infoIcon-text">{text}</div>
    </div>
  );
};

export default InfoIcon;
