import { createAsyncAction, createStandardAction } from "typesafe-actions";

import { ERecordingStatus, GenerateTokenPayload, MeetingStateType } from "../interfaces";
import { MeetingActionTypes } from "./constants";

import { ClassRoomSettings } from "containers/Sermons";
import { IMember } from "containers/Member";

export const generateEightToEightToken = createAsyncAction(
  MeetingActionTypes.GENERATE_EIGHT_TO_EIGHT,
  MeetingActionTypes.GENERATE_EIGHT_TO_EIGHT_SUCCESS,
  MeetingActionTypes.GENERATE_EIGHT_TO_EIGHT_FAILURE,
)<GenerateTokenPayload, { token: string }, Error>();

export const setEightToEightUser = createStandardAction(MeetingActionTypes.SET_EIGHT_TO_EIGHT_USER)<
  MeetingStateType["eightToEightUser"]
>();

export const loadClassRooms = createAsyncAction(
  MeetingActionTypes.LOAD_CLASSROOMS,
  MeetingActionTypes.LOAD_CLASSROOMS_SUCCESS,
  MeetingActionTypes.LOAD_CLASSROOMS_FAILURE,
)<number, { classrooms: ClassRoomSettings[] }, Error>();

export const setEventClassrooms = createAsyncAction(
  MeetingActionTypes.WS_EVENT_CLASSROOMS_REQUEST,
  MeetingActionTypes.WS_EVENT_CLASSROOMS_SUCCESS,
  MeetingActionTypes.WS_EVENT_CLASSROOMS_FAILURE,
)<{ classrooms: ClassRoomSettings[] }, { classrooms: ClassRoomSettings[] }, Error>();

export const setCurrentClassroom = createStandardAction(MeetingActionTypes.SET_CURRENT_CLASSROOM)<
  MeetingStateType["current_classroom"]
>();

export const joinClassroom = createStandardAction(MeetingActionTypes.JOIN_TO_CLASSROOM)<{
  code: string;
  member: IMember;
  method: string;
}>();

export const leaveClassroom = createStandardAction(MeetingActionTypes.LEAVE_CLASSROOM)<{ method: string }>();

export const clearMeetingState = createStandardAction(MeetingActionTypes.CLEAR_MEETING_STATE)();

export const setFullClassroom = createStandardAction(MeetingActionTypes.SET_FULL_CLASSROOM)<boolean>();

export const setRecordingStatus = createStandardAction(MeetingActionTypes.SET_RECORDING_STATUS)<ERecordingStatus>();

export const setPreJoinPage = createStandardAction(MeetingActionTypes.SET_PRE_JOIN_PAGE)<boolean>();

export const setFollowMe = createStandardAction(MeetingActionTypes.SET_FOLLOW_ME)<boolean>();
