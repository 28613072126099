import React, { FC } from "react";

import { Overlay } from "../../index";
import EventTour from "../../../containers/Sermons/components/EventTour";
import { AppTheme } from "../../../containers/App/interfaces/themeType";

const EventTourPreview: FC<{
  theme: AppTheme;
  show: boolean;
  showMeAround: boolean;
  closeTour: () => void;
  skipTour: () => void;
  countSteps: number;
  tourTitle?: string | null;
  tourDescription?: string | null;
}> = ({ show, closeTour, skipTour, theme, countSteps, showMeAround, tourTitle, tourDescription }) => {
  return (
    <Overlay theme={theme} show={show} onClose={closeTour}>
      <EventTour
        closeTour={closeTour}
        skipTour={skipTour}
        countSteps={countSteps}
        showMeAround={showMeAround}
        tourTitle={tourTitle}
        tourDescription={tourDescription}
      />
    </Overlay>
  );
};

export default EventTourPreview;
