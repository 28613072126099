import React from "react";
import { Link } from "@material-ui/core";
import classnames from "classnames";

import { IStyledLink } from "shared/interfaces";

import "./styledLink.scss";

const StyledLink: React.FC<IStyledLink> = ({
  children,
  href = "about:blank",
  target = "_blank",
  className,
  download,
}) => {
  return (
    <Link
      download={download}
      href={href}
      target={target}
      className={classnames("styledLink", className ? className : "")}
    >
      {children}
    </Link>
  );
};

export default StyledLink;
