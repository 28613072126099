import { io, Socket } from "socket.io-client";

import "webrtc-adapter";

import { default as store } from "../../../index";
import { actions } from "../store";
import * as types from "../constants/connectionTypes";
import config from "../../../config";
import tokenHandler from "../../../shared/utils/tokenHandler";
import { RoomInterface } from "../interfaces";
import history from "../../../shared/history/history";
import { NamesOfParentRoutes } from "../../../constants";

import { IMember } from "containers/Member";
import { notificationActions } from "containers/Notifications/store/actions";

type ClientId = string;

export interface Data {
  from_id: ClientId;
  sdp: RTCSessionDescription;
  member: IMember;
  isMutted: boolean;
  isVideoDisabled: boolean;
  code?: string;
}

const { STREAM } = NamesOfParentRoutes;

class PeerConnection {
  private peerConnections: Array<{ id: ClientId; pc: RTCPeerConnection }>;
  private socket!: Socket & { query?: { authorization: string } };
  private stream: null | MediaStream;
  public code: undefined | string;
  private iceConnectionStatusMap: { [key: string]: { status: RTCIceConnectionState; retries: number } } = {};
  private connectionStateStatusMap: { [key: string]: RTCPeerConnectionState } = {};
  private isMutted: boolean;
  private isVideoDisabled: boolean;
  private cleanUpTimer: number | null;
  private member: IMember | null;

  private token: boolean;

  constructor() {
    this.peerConnections = [];
    this.code = undefined;
    this.stream = null;
    this.isMutted = false;
    this.isVideoDisabled = false;
    this.cleanUpTimer = null;
    this.member = null;
    this.token = !!tokenHandler.get();
    this.sockekInitialization();
  }

  private sockekInitialization() {
    const token = tokenHandler.get();
    this.token = !!tokenHandler.get();
    this.socket = io(`${config.wsApiUrl}`, {
      path: config.wsPath,
      transports: ["websocket", "polling"],
      query: { authorization: token ? `${token}` : "" },
    });
    this.socket.on(types.JOIN, this.createOffer);
    this.socket.on(types.OFFER, this.createAnswer);
    this.socket.on(types.ANSWER, this.receivedAnswer);
    this.socket.on(types.CANDIDATE, this.receivedCandidate);
    this.socket.on(types.ROOMS, this.updateRooms);
    this.socket.on(types.EXIT, ({ from_id }: Data) => this.disconnect(from_id));
    this.socket.on(types.MUTE, this.handleMuted);
    this.socket.on(types.DISABLE_VIDEO, this.handleVideoStateChanged);
    this.socket.on(types.ROOM_FULL, this.fullRoomHandler);
    this.socket.on(types.CONNECT, this.socketConnectHandler);
    this.socket.on(types.DISCONNECT, this.socketDisconnectHandler);
    this.socket.on(types.ANOTHER_ROOM_JOIN, this.anotherRoomJoinHandler);
    this.socket.io.on(types.RECONNECT_ATTEMPT, this.socketReconnectAttemptHandler);
  }

  private recreateOffer = (from_id: ClientId) => {
    if (store) {
      const {
        breakoutRooms: { isConnecting },
      } = store.getState();
      if (this.code && isConnecting) {
        this.createOffer({ from_id, code: this.code, member: this.member });
      }
    }
  };

  private redirectFromRoom = () => {
    if (store) {
      const {
        breakoutRooms: { isConnecting },
        sermons: { sermon },
      } = store.getState();
      if (isConnecting) {
        store.dispatch(actions.exitRoom.request());
        history.push(`${STREAM}/${sermon?.code}`);
      }
    }
  };

  public connectSocket = (stream: MediaStream, code: string, meeting_id: number, member: IMember): Promise<string> => {
    return new Promise(resolve => {
      this.stream = stream;
      this.member = member;

      const type = code === null ? types.CREATE : types.JOIN;

      if (code && type === types.JOIN) {
        this.code = code;
        resolve(code);
      }

      if (meeting_id) {
        this.joinMeeting(meeting_id);
      }
      this.cleanUpTimer = setInterval(this.cleanupConnections, 10000);

      this.socket?.emit(type, { meeting_id: meeting_id, code, member });
    });
  };

  public updateSocketToken() {
    if (!this.token) {
      this.sockekInitialization();
    }
  }

  public getRooms(meeting_id: number) {
    this.socket?.emit(types.ROOMS, { meeting_id });
  }

  public joinMeeting(meeting_id: number) {
    this.socket?.emit(types.JOIN_MEETING, { meeting_id });
  }

  public mute(state: boolean) {
    this.isMutted = state;
    this.socket?.emit(types.MUTE, { isMutted: this.isMutted, code: this.code });
  }

  public disableVideo(state: boolean) {
    this.isVideoDisabled = state;
    this.socket?.emit(types.DISABLE_VIDEO, {
      isVideoDisabled: this.isVideoDisabled,
      code: this.code,
    });
  }

  private socketConnectHandler = () => {
    if (store) {
      const {
        breakoutRooms: { isConnecting },
        sermons: { sermon },
      } = store.getState();

      if (isConnecting) {
        // eslint-disable-next-line no-console
        console.log(new Date(), "connect", `[event-id]-${sermon?.id}-[room-code-${this.code}]`);
        // eslint-disable-next-line no-console
        console.log(new Date(), "connect-current-state", store.getState().breakoutRooms);

        if (sermon) {
          this.joinMeeting(sermon.id);
        }
        if (this.code) {
          this.socket.emit(types.JOIN, { code: this.code, member: this.member });
        }
      }
    }
  };

  private socketReconnectAttemptHandler = (attempt: number) => {
    if (attempt > 5) {
      this.redirectFromRoom();
    }
  };

  private socketDisconnectHandler = (reason: Socket.DisconnectReason) => {
    this.peerConnections.forEach(p => {
      this.disconnect(p.id);
    });
    if (reason === "ping timeout") {
      this.redirectFromRoom();
    }
  };

  private handleMuted = (data: Data) => {
    if (this.validateRequest(data, true, false)) return;

    const { isMutted, from_id } = data;
    if (store) {
      store.dispatch(actions.handleFriendMuted({ from_id, isMutted: !!isMutted }));
    }
  };

  private handleVideoStateChanged = (data: Data) => {
    if (this.validateRequest(data, true, false)) return;

    const { isVideoDisabled, from_id } = data;
    if (store) {
      store.dispatch(actions.handleFriendVideo({ from_id, isVideoDisabled: !!isVideoDisabled }));
    }
  };

  private prepareConnection = (clientId: ClientId, member: IMember): RTCPeerConnection => {
    const pc = new RTCPeerConnection({ iceServers: config.iceServers });

    pc.onicecandidate = (e: RTCPeerConnectionIceEvent) => this.handleIceCandidate(e, clientId);
    pc.ontrack = (e: RTCTrackEvent) => this.handleTrack(e, clientId, member, this.isMutted, this.isVideoDisabled);
    pc.oniceconnectionstatechange = () => this.handleIceConnectionStateChange(clientId);
    pc.onconnectionstatechange = () => {
      if (pc.connectionState === "connected") {
        this.mute(this.isMutted);
        this.disableVideo(this.isVideoDisabled);
      }
      if (pc.connectionState === "closed") {
        this.disconnect(clientId);
      }
    };
    //  pc.onnegotiationneeded = () => this.handleNegotiationNeededEvent(clientId, pc);
    // eslint-disable-next-line no-console
    pc.onicecandidateerror = (error: any) => console.error(error);

    pc.onsignalingstatechange = () => {
      if (pc.signalingState === "closed") {
        this.disconnect(clientId);
      }
    };

    this.stream?.getTracks().forEach(track => {
      this.stream && pc.addTrack(track, this.stream);
    });
    if (this.stream !== null) {
      this.setPeerConnection(clientId, pc);
    }

    return pc;
  };

  public changeStreamSettings = (stream: MediaStream) => {
    try {
      this.stream = stream;
      if (stream) {
        const videoTrack = this.stream.getVideoTracks()[0];
        const audiTrack = this.stream.getAudioTracks()[0];
        this.peerConnections?.forEach(pc => {
          const vT = pc.pc.getSenders().find(s => {
            return s.track?.kind === videoTrack.kind;
          });
          const aT = pc.pc.getSenders().find(s => {
            return s.track?.kind === audiTrack.kind;
          });
          // eslint-disable-next-line no-console
          console.log(new Date(), " changeStreamSettings found vT:", vT);
          vT?.replaceTrack(videoTrack);
          // eslint-disable-next-line no-console
          console.log(new Date(), " changeStreamSettings found aT:", vT);
          aT?.replaceTrack(audiTrack);
        });
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(new Date(), "changeStreamSettings", e);
    }
  };

  private createOffer = async (data: any): Promise<void> => {
    const { from_id, member } = data;

    if (this.validateRequest(data, true, false, "createOffer-prevent-offer-to-myself")) {
      return;
    }

    if (this.validateRequest(data, false, true, "createOffer-WRONG_ROOM_CODE")) {
      return;
    }

    if (this.getPeerConnection(from_id)) {
      return;
    }
    const pc = this.prepareConnection(from_id, member);

    const sessionDescription = await pc.createOffer({
      iceRestart: true,
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    });
    await pc.setLocalDescription(sessionDescription);

    this.sendSDP(from_id, sessionDescription);
  };

  public createAnswer = async (data: Data): Promise<void> => {
    const { from_id, sdp, member } = data;

    if (this.validateRequest(data, true, false, "createAnswer-prevent-answer-to-myself")) {
      return;
    }
    if (this.validateRequest(data, false, true, "createAnswer-WRONG_ROOM_CODE")) {
      return;
    }

    const pc = this.getPeerConnection(from_id) || this.prepareConnection(from_id, member);

    if (!pc) {
      return;
    }

    const offerCollision = pc.signalingState !== "stable";

    const receivedOffer = new RTCSessionDescription(sdp);

    try {
      if (offerCollision) {
        await Promise.all([pc.setLocalDescription({ type: "rollback" }), pc.setRemoteDescription(receivedOffer)]);
      } else {
        await pc.setRemoteDescription(receivedOffer);
      }
      //  await pc.setRemoteDescription(receivedOffer);
      const sessionDescription = await pc.createAnswer();

      await pc.setLocalDescription(sessionDescription);

      this.sendSDP(from_id, sessionDescription);
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(new Date(), "createAnswer", e);
    }
  };

  public receivedAnswer = async (data: Data): Promise<void> => {
    const { from_id, sdp, member, isMutted, isVideoDisabled } = data;

    if (this.validateRequest(data, false, true, "receivedAnswer-WRONG_ROOM_CODE")) {
      return;
    }
    const receivedAnswer = new RTCSessionDescription(sdp);
    const pc = this.getPeerConnection(from_id);

    if (!pc) {
      return;
    }

    pc.ontrack = (e: RTCTrackEvent) => this.handleTrack(e, from_id, member, isMutted, isVideoDisabled);

    try {
      await pc.setRemoteDescription(receivedAnswer);
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(new Date(), "receivedAnswer", e);
    }
  };

  public receivedCandidate = async (data: any) => {
    const candidate = new RTCIceCandidate(data.sdp.ice);
    const pc = this.getPeerConnection(data.from_id);
    try {
      if (pc) {
        await pc.addIceCandidate(candidate).catch(async e => {
          await pc.setRemoteDescription(data.sdp).catch(e => {
            // eslint-disable-next-line no-console
            console.error(new Date(), "receivedCandidate-setRemoteDescription", e);
          });
          // eslint-disable-next-line no-console
          console.error(new Date(), "receivedCandidate-addIceCandidate", e);
        });
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(new Date(), "receivedCandidate", e);
    }
  };

  private sendSDP = (clientId: ClientId, sessionDescription: RTCSessionDescription | RTCSessionDescriptionInit) => {
    const data = {
      to_id: clientId,
      code: this.code,
      sdp: sessionDescription,
      isMutted: this.isMutted,
      isVideoDisabled: this.isVideoDisabled,
      member: this.member,
    };

    if (sessionDescription.type) {
      this.socket?.emit(sessionDescription.type, data);
    }
  };

  private handleTrack = async (
    e: RTCTrackEvent,
    clientId: ClientId,
    member: IMember,
    isMutted: boolean,
    isVideoDisabled: boolean,
  ): Promise<void> => {
    const [stream] = e.streams;

    if (store) {
      const {
        breakoutRooms: { streams, isConnecting },
      } = store.getState();

      const isStreamExists = !!streams.find(item => item.member.id === member.id);

      if (isConnecting) {
        if (!isStreamExists) {
          store.dispatch(
            actions.addStream({ clientId, stream, member, isMutted, isVideoDisabled, hasConnectionIssue: false }),
          );
        } else {
          store.dispatch(
            actions.handleConnectionIssue({
              clientId,
              stream,
              member,
              isMutted,
              isVideoDisabled,
              hasConnectionIssue: false,
            }),
          );
          this.socketConnectHandler();
        }
      }
    }
  };

  private handleIceCandidate = (e: RTCPeerConnectionIceEvent, clientId: ClientId): void => {
    // for Tricle ICE
    if (e.candidate) {
      const data = {
        to_id: clientId,
        code: this.code,
        sdp: {
          type: "candidate",
          ice: e.candidate,
        },
      };

      this.socket?.emit(types.CANDIDATE, data);
    }
    // const pc = this.getPeerConnection(clientId);
    // // // for Vanilla ICE
    // if (pc?.localDescription) {
    //   this.sendSDP(clientId, pc.localDescription);
    // }
  };

  // private handleNegotiationNeededEvent = async (clientId: ClientId, pc: any) => {
  //   if (pc.signalingState !== "stable") return;
  //   pc._negotiating = true;
  //   try {
  //     //   const sessionDescription = await pc.createOffer();
  //     await pc.setLocalDescription();
  //     this.sendSDP(clientId, pc.localDescription);
  //   } catch (e: any) {
  //     // eslint-disable-next-line no-console
  //     console.error(new Date(), "handleNegotiationNeededEvent", clientId, e);
  //   } finally {
  //     pc._negotiating = false;
  //   }
  // };

  private getPeerConnection = (clientId: ClientId) => {
    const peerConnection = this.peerConnections.find(({ id }) => id === clientId);

    return peerConnection?.pc;
  };

  private setPeerConnection = (clientId: ClientId, pc: RTCPeerConnection) => {
    const index = this.peerConnections.findIndex(({ id }) => id === clientId);
    const peerConnection = {
      id: clientId,
      pc,
    };

    if (index < 0) {
      this.peerConnections = [...this.peerConnections, peerConnection];
    } else {
      this.peerConnections = [
        ...this.peerConnections.slice(0, index),
        peerConnection,
        ...this.peerConnections.slice(index + 1),
      ];
    }

    // eslint-disable-next-line no-console
    //  console.log("setPeerConnection", this.peerConnections);
  };

  private updateRooms = (data: RoomInterface[]) => {
    if (store) {
      const {
        sermons: { sermon: currentEvent },
      } = store.getState();
      if (!!currentEvent?.id && !!data.length && currentEvent.id !== data?.[0]?.meeting_id) {
        // eslint-disable-next-line no-console
        console.log(new Date(), "received-wrong-socket-from-another-event");
        return;
      }
      store.dispatch(actions.updateRooms.success(data));
    }
  };

  private disconnect = async (clientId: ClientId, removeStream = true): Promise<void> => {
    const pc = this.getPeerConnection(clientId);

    if (store && removeStream) {
      store.dispatch(actions.removeStream(clientId));
    }

    if (!removeStream && store) {
      store.dispatch(actions.streamConnectionIssue({ clientId, hasConnectionIssue: true }));
    }

    if (pc) {
      // eslint-disable-next-line no-console
      console.log(new Date(), clientId, pc.connectionState, pc.iceConnectionState, "disconnected");
      pc.close();
      pc.oniceconnectionstatechange = null;
      pc.ontrack = null;
      pc.oniceconnectionstatechange = null;
      this.peerConnections = this.peerConnections.filter(({ id }) => id !== clientId);
      // eslint-disable-next-line no-console
      //  console.log("disconnect", this.peerConnections);
    }
  };

  private handleIceConnectionStateChange = async (clientId: ClientId) => {
    const pc = this.getPeerConnection(clientId);

    if (pc) {
      console.info(new Date(), "IceConnectionState changed to: ", pc.iceConnectionState);
      if (!this.iceConnectionStatusMap[clientId]) {
        this.iceConnectionStatusMap[clientId] = {
          status: "new",
          retries: 0,
        };
      }
      switch (pc.iceConnectionState) {
        case "connected":
          if (this.iceConnectionStatusMap[clientId].status === "disconnected") {
            store.dispatch(actions.streamConnectionIssue({ clientId, hasConnectionIssue: false }));
          }
          break;
        case "disconnected":
          store.dispatch(actions.streamConnectionIssue({ clientId, hasConnectionIssue: true }));
          break;
        case "failed":
          this.disconnect(clientId);
          this.recreateOffer(clientId);
          break;
        case "closed":
          this.disconnect(clientId, false);
          break;
        default:
          break;
      }
      this.iceConnectionStatusMap[clientId].status = pc.iceConnectionState;
    }
  };

  private cleanupConnections = () => {
    this.peerConnections.forEach(p => {
      if (
        this.connectionStateStatusMap[p.id] === p.pc.connectionState &&
        p.pc.connectionState !== "connected" &&
        p.pc.connectionState !== "new"
      ) {
        // eslint-disable-next-line no-console
        console.log(new Date(), "clenupConnections", "connectionState - disconnect");
        this.disconnect(p.id);
      }
      if (p.pc.connectionState === "disconnected") {
        store.dispatch(actions.streamConnectionIssue({ clientId: p.id, hasConnectionIssue: true }));
      }
      if (p.pc.connectionState === "failed") {
        this.disconnect(p.id);
        this.recreateOffer(p.id);
      }
      this.connectionStateStatusMap[p.id] = p.pc.connectionState;
    });
  };

  public exit = () => {
    if (this.code) {
      this.socket?.emit(types.EXIT, { code: this.code, from_id: this.socket.id });
    }

    if (this.cleanUpTimer) {
      clearInterval(this.cleanUpTimer);
    }

    this.peerConnections = [];
    this.isMutted = false;
    this.isVideoDisabled = false;
    this.cleanUpTimer = null;
    this.code = undefined;
    this.member = null;
  };

  private validateRequest(data: Data, validateFromId = false, validateRoomCode = false, message = "") {
    let isInvalid = false;
    if (validateFromId) {
      isInvalid = data.from_id === this.socket.id;
    }
    if (validateRoomCode) {
      isInvalid = data.code !== this.code;
    }
    if (isInvalid && message) {
      const { from_id, member, code } = data;
      // eslint-disable-next-line no-console
      console.log(new Date(), message, { from_id, member, code });
    }
    return isInvalid;
  }

  private fullRoomHandler = (data: { to_id: string }) => {
    if (data.to_id !== this.socket.id) {
      // eslint-disable-next-line no-console
      console.log(new Date(), "received-wrong-socket");
      return;
    }
    if (store) {
      if (this.code) {
        history.push(`${window.location.pathname.replace(this.code, "")}`);
      }
      // eslint-disable-next-line no-console
      console.log(new Date(), "backend-Room is full");
      store.dispatch(actions.exitRoom.request());
      store.dispatch(notificationActions.error("Room is full", "Please, choose another one."));
    }
  };

  private anotherRoomJoinHandler = ({ member_id }: { code: string; member_id: number }) => {
    if (store) {
      const {
        auth: { member },
      } = store.getState();
      if (member?.id === member_id) {
        // eslint-disable-next-line no-console
        console.log(new Date(), "another-room-join-disconnect");
        store.dispatch(actions.exitRoom.request());
        store.dispatch(
          notificationActions.info("Room changed", "You have joined to another room. This connection was closed."),
        );
      }
    }
  };
}

export const peerConnection = new PeerConnection();
