import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import StreamEventPlayer from "../StreamEventPlayer";
import { getEventSettings, getGoingLiveTime } from "../../store/selectors";

import {
  EEventStreamType,
  EEventVideoType,
  EventStatus,
  IEvent,
  IEventVideoSettings,
} from "containers/Sermons/interfaces";
import useElementSize from "shared/hooks/ElementSizeHook/ElementSizeHook";
import { selectIsImutted, selectStream, selectStreams } from "containers/BreakoutRooms/store/selectors";
import { defaultVideoSettings } from "containers/Sermons/constants";
import { Button } from "shared";
import { cancelUrlWaiting, waitForUrlExists } from "shared/stores/general/actions";
import { useIsMobile } from "shared/hooks/IsMobileHook";

import "./index.scss";

interface IVideoStream {
  event: IEvent;
  settings?: IEventVideoSettings;
}

enum PayerState {
  play = "play",
  paused = "paused",
  ended = "ended",
}

const VideoStream: React.FC<IVideoStream> = ({ event, settings = defaultVideoSettings }) => {
  const [ref, setRef] = useState(false);
  const [isReady, setReady] = useState(false);
  const [playerState, setPlayerState] = useState<PayerState | null>(null);
  const [volume, setVolume] = useState<number>(settings.volume);
  const [player, setPlayer] = useState<ReactPlayer | null>(null);
  const [startTimeSet, setStartTimeSet] = useState(false);
  const [muted, setMuted] = useState(settings.muted);
  const [renderPlayer, setRenderPlayer] = useState(true);
  const [syncIntervalRun, setSyncIntervalRun] = useState<number | null>(null);
  const [syncButtonShown, setSyncButtonShown] = useState(false);
  const [hlsLive, setHlsLive] = useState<boolean>();

  const dispatch = useDispatch();

  const isImutted = useSelector(selectIsImutted());
  const goingLiveTime = useSelector(getGoingLiveTime());
  const stream = useSelector(selectStream());
  const userStreams = useSelector(selectStreams());
  const { isLoading } = useSelector(getEventSettings());
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const { height: containerHeight } = useElementSize(videoContainerRef.current, 300);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (videoContainerRef.current) {
      setRef(true);
    }
  }, [ref, event]);

  const playerHandler = (payerEvent: PayerState) => () => {
    setPlayerState(payerEvent);
  };

  useEffect(() => {
    const allMemberMuted = userStreams.every(userStream => userStream.isMutted);
    if (isReady) {
      (isImutted && allMemberMuted) || !stream ? setVolume(1) : setVolume(0.2);
    }
  }, [isImutted, isReady, stream, userStreams]);

  const onHlsLevelLoaded = useCallback(
    function (eventName: "hlsLevelLoaded", level: any) {
      if (hlsLive === undefined) {
        setHlsLive(level.details?.live ?? false);
        // @ts-ignore
        this.off("hlsLevelLoaded", onHlsLevelLoaded);
      }
    },
    [hlsLive],
  );

  const onReady = useCallback(() => {
    if (player && event.stream_type === EEventStreamType.RESI) {
      const ip = player.getInternalPlayer("hls");
      if (ip) {
        ip.on("hlsLevelLoaded", onHlsLevelLoaded, ip);
      }
    }
    if (event.status === EventStatus.live && event.stream_type !== EEventStreamType.CUSTOM) {
      setPlayerState(PayerState.play);
      setReady(true);
    }
  }, [event.status, event.stream_type, onHlsLevelLoaded, player]);

  useEffect(() => {
    if (!player) {
      setStartTimeSet(false);
      setMuted(true);
    }
  }, [player]);

  useEffect(() => {
    if (event && event.stream_type === EEventStreamType.ALTAR_LIVE && event.stream_url) {
      dispatch(
        waitForUrlExists(event.stream_url, 5000, () => {
          setRenderPlayer(false);
          setImmediate(() => setRenderPlayer(true));
        }),
      );
    }
    return () => {
      dispatch(cancelUrlWaiting());
    };
  }, [dispatch, event]);

  useEffect(
    () => () => {
      if (syncIntervalRun) {
        clearInterval(syncIntervalRun);
        setSyncIntervalRun(null);
      }
    },
    [syncIntervalRun],
  );

  const checkYouTubeVideoType = useCallback(
    (video_type: EEventVideoType) => event.stream_type === EEventStreamType.YOUTUBE && event.video_type === video_type,
    [event],
  );

  const needToCheckTimeDiff = useMemo(() => {
    return (
      event.stream_type &&
      ([EEventStreamType.m3u8, EEventStreamType.RESI, EEventStreamType.ALTAR].includes(event.stream_type) ||
        checkYouTubeVideoType(EEventVideoType.pre_recorded))
    );
  }, [event.stream_type, checkYouTubeVideoType]);

  const checkTimeDiff = useCallback(() => {
    if (startTimeSet && player && goingLiveTime > 0) {
      const currentTime = player.getCurrentTime();
      const duration = player.getDuration();
      setSyncButtonShown(
        !!currentTime &&
          currentTime + 10 < duration &&
          Math.abs(currentTime - (Date.now() - goingLiveTime) / 1000) > 10,
      );
    }
  }, [goingLiveTime, player, startTimeSet]);

  useEffect(() => {
    if (
      !muted &&
      startTimeSet &&
      needToCheckTimeDiff &&
      !syncIntervalRun &&
      player &&
      playerState &&
      [PayerState.play, PayerState.paused].includes(playerState)
    ) {
      setSyncIntervalRun(
        setInterval(() => {
          checkTimeDiff();
        }, 3000),
      );
    }
  }, [checkTimeDiff, goingLiveTime, muted, needToCheckTimeDiff, player, playerState, startTimeSet, syncIntervalRun]);

  const onSeek = useCallback(() => {
    if (needToCheckTimeDiff) {
      checkTimeDiff();
    }
  }, [checkTimeDiff, needToCheckTimeDiff]);

  const syncVideo = useCallback(() => {
    if (player && goingLiveTime) {
      const calculatedVideoTime = Math.round((Date.now() - goingLiveTime) / 1000);
      const duration = player.getDuration();
      // -0.4 is needed because Vimeo player starts playing from the beginning if position is moved to `duration`
      // -2 - to save connection time
      player.seekTo(calculatedVideoTime > duration ? duration - 0.4 : calculatedVideoTime - 2);
      setSyncButtonShown(false);

      return true;
    }
    return false;
  }, [goingLiveTime, player]);

  const onProgress = useCallback(() => {
    if (
      !startTimeSet &&
      (event.stream_type !== EEventStreamType.RESI || hlsLive === false) &&
      !checkYouTubeVideoType(EEventVideoType.live) &&
      event.stream_type !== EEventStreamType.FB
    ) {
      const synced = syncVideo();
      if (synced) {
        setStartTimeSet(true);
      }
    }
  }, [event.stream_type, hlsLive, startTimeSet, syncVideo, checkYouTubeVideoType]);

  return (
    <div className="videoStream" ref={videoContainerRef}>
      <div
        className={classnames("videoStream-container", event.stream_type === EEventStreamType.YOUTUBE && playerState, {
          "videoStream-container-loading": isLoading,
        })}
        style={{ paddingBottom: containerHeight }}
      >
        {syncButtonShown && (
          <Button type="button" variant="white" width={190} onClick={syncVideo} className="sync-video">
            <svg
              className="refresh-icon"
              width="24"
              height="16"
              viewBox="0 0 24 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.5 9.5L20.414 12.586L19 14L14.5 9.5L15.914 8.086L18 10.172V8C18 4.692 15.308 2 12 2V0C14.1217 0 16.1566 0.842855 17.6569 2.34315C19.1571 3.84344 20 5.87827 20 8V10.172L22.086 8.086L23.5 9.5ZM6 8V5.828L8.086 7.914L9.5 6.5L5 2L3.586 3.414L0.5 6.5L1.914 7.914L4 5.828V8C4 10.1217 4.84285 12.1566 6.34315 13.6569C7.84344 15.1571 9.87827 16 12 16V14C8.692 14 6 11.308 6 8Z"
                fill="black"
              />
            </svg>
            <span>Sync with Everyone</span>
          </Button>
        )}
        {muted && isReady && (
          <>
            <div className={classnames("text", { isMobile })}>
              <div className="text-welcome">Welcome</div>
              <div className="text-description">Click the button below to start watching</div>
            </div>
            <div className="blurScreen" />
            <Button
              type="button"
              variant="white"
              width={134}
              onClick={() => setMuted(false)}
              className={classnames("unmute", { isMobile })}
            >
              <span>Play Video</span>
            </Button>
          </>
        )}
        {renderPlayer && (
          <StreamEventPlayer
            streamType={event.stream_type}
            streamUrl={event.stream_url}
            videoContainer={videoContainerRef.current}
            playerRef={instance => setPlayer(instance)}
            playerClassName="player"
            onEnded={playerHandler(PayerState.ended)}
            onPause={playerHandler(PayerState.paused)}
            onPlay={playerHandler(PayerState.play)}
            onReady={onReady}
            onProgress={onProgress}
            volume={muted ? 0 : volume}
            muted={muted}
            controls={settings.controls}
            playing={settings.playing}
            pip={settings.pip}
            onSeek={onSeek}
          />
        )}
      </div>
    </div>
  );
};
export default VideoStream;
