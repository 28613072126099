import produce from "immer";
import { Reducer } from "redux";

import { IStripeState } from "../../interfaces/Stripe";
import { StripeActionTypes } from "./constants";

const initialState: IStripeState = {
  stripe: null,
};

const reducer: Reducer<IStripeState> = (state = initialState, action) => {
  switch (action.type) {
    case StripeActionTypes.LOAD_SUCCESS: {
      return produce(state, nextState => {
        nextState.stripe = action.payload;
      });
    }
    case StripeActionTypes.LOAD_FAIL:
    case StripeActionTypes.LOAD_CLEAR: {
      return produce(state, nextState => {
        nextState.stripe = null;
      });
    }
    default:
      return state;
  }
};

export default reducer;
