import React, { PropsWithChildren } from "react";
import classnames from "classnames";

import "./styles.scss";

type Props = {
  className?: string;
};

const EntityMainInfo: React.FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return (
    <div className={classnames("entity-main-info", className)}>
      <div className="bg-el el-1" />
      <div className="bg-el el-2" />
      <div className="bg-el el-3" />
      <div className="bg-el el-4" />
      {children}
    </div>
  );
};

export default EntityMainInfo;
