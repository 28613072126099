import { createSelector } from "reselect";

import { AltarStore } from "../../../shared/types";
import { AnnouncementStatus } from "../interfaces";

const selectSermons = (state: AltarStore) => state.sermons;

export const getSermonList = () => createSelector(selectSermons, state => state.sermonList);
export const getEvent = () => createSelector(selectSermons, state => state.sermon);
export const getEventStatus = () => createSelector(selectSermons, state => state.sermon?.status);
export const getError = () => createSelector(selectSermons, state => state.error);
export const getSermonMembers = () => createSelector(selectSermons, state => state.sermonMembers);
export const getEventMembers = () => createSelector(selectSermons, state => state.eventMembers);
export const getSignature = () => createSelector(selectSermons, state => state.signature);
export const getState = () => createSelector(selectSermons, state => state.state);
export const slotIsAvailable = () => createSelector(selectSermons, state => state.slotIsAvailable);
export const getEventIntegrations = () => createSelector(selectSermons, state => state.eventIntegrations);
export const getEventShortLink = () => createSelector(selectSermons, state => state.eventShortLink);
export const getBlockedMembers = () => createSelector(selectSermons, state => state.blockedEventMembers);
export const getEventSettings = () => createSelector(selectSermons, state => state.eventSettings);
export const getSocketConnected = () => createSelector(selectSermons, state => state.socketConnected);
export const getGoingLiveTime = () => createSelector(selectSermons, state => state.goingLiveTime);
export const getAllowEnded = () => createSelector(selectSermons, state => state.allowEnded);
export const getChangingStatus = () => createSelector(selectSermons, state => state.changingStatus);
export const getLeavingEvent = () => createSelector(selectSermons, state => state.leavingEvent);
export const getRecurringEvent = () => createSelector(selectSermons, state => state.recurringEvent);
export const isParticipantsLimitExceeded = () =>
  createSelector(selectSermons, state => state.participantsLimitExceeded);
export const getUpdatedFields = () => createSelector(selectSermons, state => state.updatedFields);
export const getAnnouncements = () => createSelector(selectSermons, state => state.announcements);
export const getUnreadAnnouncementsCount = () =>
  createSelector(
    selectSermons,
    state => state.announcements.filter(item => !item.is_read && item.status === AnnouncementStatus.published).length,
  );
export const getSelectedAnnouncement = () => createSelector(selectSermons, state => state.selectedAnnouncement);
export const getPolls = () => createSelector(selectSermons, state => state.polls);
export const getEventHostOrAdmin = () => createSelector(selectSermons, state => state.isEventHostOrAdmin);
export const getWaitingToJoinMembers = () => createSelector(selectSermons, state => state.waitingToJoinMembers);
export const getKnockRejected = () => createSelector(selectSermons, state => state.knockRejected);
export const getChangeStatusOptions = () => createSelector(selectSermons, state => state.changeStatusOptions);
export const getIsVimeoTokenValid = () => createSelector(selectSermons, state => state.isVimeoTokenValid);
export const getPollTemplates = () => createSelector(selectSermons, state => state.pollTemplates);
export const getAnnouncementTemplates = () => createSelector(selectSermons, state => state.announcementTemplates);
