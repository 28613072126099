import React, { ReactNode } from "react";
import classnames from "classnames";

import { StyledArch, StyledPolygon, StyledSquare, StyledSquarePolygon } from "../Figures";

import "./styles.scss";

type Props = {
  mainImgSrc?: string;
  title?: string | ReactNode;
  description?: string | ReactNode;
  className?: string;
};

const Blocker: React.FC<Props> = ({ title, description, className, mainImgSrc, children }) => {
  return (
    <div className={classnames("blocker-container", className)}>
      <div className="blocker-figures">
        <StyledArch className="blocker-figures-arch" />
        <StyledArch className="blocker-figures-arch small" fill="#F0B48F" />
        <StyledSquarePolygon className="blocker-figures-squarePolygon small" />
        <StyledSquarePolygon className="blocker-figures-squarePolygon" />
        <StyledPolygon className="blocker-figures-polygon" fill="#7D745A" />
        <StyledSquare className="blocker-figures-square" />
      </div>
      <div className="blocker">
        {mainImgSrc && (
          <div className="blocker-image">
            <img src={mainImgSrc} alt="blocker" />
          </div>
        )}
        {title && <h1 className="blocker-title">{title}</h1>}
        {description && <div className="blocker-description">{description}</div>}
        {children}
      </div>
    </div>
  );
};
export default Blocker;
