import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import api from "../api";
import actionTypes from "./constants";
import { deleteUser, updateUser } from "./actions";
import { IUser } from "../../Auth";

import { ResponseError } from "shared/interfaces";
import { getUserData, logout } from "containers/Auth/store/actions";
import { startLoading, stopLoading } from "containers/App/store/actions";
import { tokenHandler } from "shared/utils";
import { actions } from "containers/Auth/store";
import history from "shared/history/history";
import { notificationActions } from "containers/Notifications/store/actions";
import { fetchMembersInCommunity } from "containers/Member/store/actions";

function* updateUserById({ payload: { data, callback, community } }: AnyAction) {
  let error: ResponseError | null = null;
  try {
    yield put(startLoading());
    const response: IUser = yield call(api.updateUser, { ...data, confirmPassword: undefined });
    yield put(updateUser.success(response));
    yield put(getUserData.request());
    if (community) {
      yield put(actions.getMember.request({ community_id: community.id }));
      yield put(fetchMembersInCommunity.request({ communityCode: community.code }));
    }
  } catch (err: any) {
    yield put(updateUser.failure(err));
    error = err;
  } finally {
    yield put(stopLoading());
  }

  if (callback) {
    callback(error);
  }
}

function* confirmEmail({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const { token } = yield call(api.confirmEmail, payload);
    yield tokenHandler.set(token);
    yield put(actions.login.success());
    yield put(notificationActions.success("The email has been successfully updated"));
    yield put(actions.getUserData.request());
  } catch (error: any) {
    yield put(actions.login.failure(error.message));
    yield put(notificationActions.error("The link has expired"));
  } finally {
    yield put(stopLoading());
    yield history.push("/");
  }
}

function* deleteUserSaga({ payload }: ReturnType<typeof deleteUser.request>) {
  const { user_id, isSeatSamePage } = payload;
  try {
    yield put(startLoading());
    yield call(api.deleteUser, user_id);
    yield put(logout.request({ isSeatSamePage }));
  } catch (error: any) {
    yield put(notificationActions.error("Can't delete user"));
  } finally {
    yield put(stopLoading());
  }
}

export default function* memberSaga() {
  yield takeLatest(updateUser.request, updateUserById);
  yield takeLatest(actionTypes.CONFIRM_EMAIL_REQUEST, confirmEmail);
  yield takeLatest(deleteUser.request, deleteUserSaga);
}
