import { action, createAsyncAction } from "typesafe-actions";
import { History } from "history";

import { MemberActionTypes } from "./constants";
import {
  IMemberDialogValuesRequest,
  MemberInvitationLinkAction,
  IFetchInvitationDateRequest,
  BaseMember,
  IMember,
  IFetchMember,
  IMembersInCommunityRequest,
  IExistMemberRequest,
  IPrivateInvitationRequest,
  IPrivateInvitation,
} from "../interfaces";
import { IRestrictMember } from "../../Sermons";

import { RequestWithCallback, ResponseError } from "shared/interfaces";

export const fetchMembersInCommunityToModal = createAsyncAction(
  MemberActionTypes.FETCH_MEMBERS_TO_MODAL,
  MemberActionTypes.FETCH_MEMBERS_TO_MODAL_SUCCESS,
  MemberActionTypes.FETCH_MEMBERS_TO_MODAL_FAILURE,
)<IMembersInCommunityRequest, IMember[], ResponseError>();

export const fetchExistMember = createAsyncAction(
  MemberActionTypes.FETCH_EXIST_MEMBER,
  MemberActionTypes.FETCH_EXIST_MEMBER_SUCCESS,
  MemberActionTypes.FETCH_EXIST_MEMBER_FAILURE,
  MemberActionTypes.FETCH_EXIST_MEMBER_CLEAR,
)<IExistMemberRequest, IMember, ResponseError, undefined>();

export const fetchMemberByCode = createAsyncAction(
  MemberActionTypes.FETCH_MEMBER_BY_CODE,
  MemberActionTypes.FETCH_MEMBER_BY_CODE_SUCCESS,
  MemberActionTypes.FETCH_MEMBER_BY_CODE_FAILURE,
)<IFetchMember, IMember | null, ResponseError>();

export const fetchMembersInCommunity = createAsyncAction(
  MemberActionTypes.FETCH_MEMBER_IN_COMMUNITY,
  MemberActionTypes.FETCH_MEMBER_IN_COMMUNITY_SUCCESS,
  MemberActionTypes.FETCH_MEMBER_IN_COMMUNITY_FAILURE,
)<IMembersInCommunityRequest, IMember[], ResponseError>();

export const createMember = createAsyncAction(
  MemberActionTypes.CREATE_MEMBER,
  MemberActionTypes.CREATE_MEMBER_SUCCESS,
  MemberActionTypes.CREATE_MEMBER_FAILURE,
)<IMemberDialogValuesRequest, BaseMember, ResponseError>();

export const updateMember = createAsyncAction(
  MemberActionTypes.UPDATE_MEMBER,
  MemberActionTypes.UPDATE_MEMBER_SUCCESS,
  MemberActionTypes.UPDATE_MEMBER_FAILURE,
)<IMemberDialogValuesRequest, BaseMember, ResponseError>();

export const connectToCommunity = createAsyncAction(
  MemberActionTypes.CONNECT_MEMBER_TO_COMMUNITY,
  MemberActionTypes.CONNECT_MEMBER_TO_COMMUNITY_SUCCESS,
  MemberActionTypes.CONNECT_MEMBER_TO_COMMUNITY_FAILURE,
)<IMemberDialogValuesRequest, BaseMember, ResponseError>();

export const removeMember = createAsyncAction(
  MemberActionTypes.REMOVE_MEMBER,
  MemberActionTypes.REMOVE_MEMBER_SUCCESS,
  MemberActionTypes.REMOVE_MEMBER_FAILURE,
)<{ id: number; community_id: number }, string | number, ResponseError>();

export const leaveMember = createAsyncAction(
  MemberActionTypes.LEAVE_MEMBER,
  MemberActionTypes.LEAVE_MEMBER_SUCCESS,
  MemberActionTypes.LEAVE_MEMBER_FAILURE,
)<{ id: number; community_id: number; callback?: () => void }, string | number, ResponseError>();

export const removeMembers = createAsyncAction(
  MemberActionTypes.REMOVE_MEMBERS,
  MemberActionTypes.REMOVE_MEMBERS_SUCCESS,
  MemberActionTypes.REMOVE_MEMBERS_FAILURE,
)<RequestWithCallback<{ memberIds: number[]; community_id: number }>, number[], ResponseError>();

export const checkUserIsVerified = createAsyncAction(
  MemberActionTypes.CHECK_USER_IS_VERIFIED_REQUEST,
  MemberActionTypes.CHECK_USER_IS_VERIFIED_SUCCESS,
  MemberActionTypes.CHECK_USER_IS_VERIFIED_FAILURE,
)<{ userId: number; communityId: number; callback?: () => void }, undefined, undefined>();

export const fetchMeetingMembers = createAsyncAction(
  MemberActionTypes.FETCH_MEETING_MEMBERS_REQUEST,
  MemberActionTypes.FETCH_MEETING_MEMBERS_SUCCESS,
  MemberActionTypes.FETCH_MEETING_MEMBERS_FAILURE,
)<{ code: string; customErrorHandling: boolean; withLoader?: boolean }, BaseMember[], string>();

export const sendPrivateInvitations = createAsyncAction(
  MemberActionTypes.SEND_PRIVATE_INVITATION_REQUEST,
  MemberActionTypes.SEND_PRIVATE_INVITATION_SUCCESS,
  MemberActionTypes.SEND_PRIVATE_INVITATION_FAILURE,
)<IPrivateInvitationRequest, IPrivateInvitation[], ResponseError>();

export const fetchInvitationDate = createAsyncAction(
  MemberActionTypes.FETCH_INVITATION_DATE_REQUEST,
  MemberActionTypes.FETCH_INVITATION_DATE_SUCCESS,
  MemberActionTypes.FETCH_INVITATION_DATE_FAILURE,
)<IFetchInvitationDateRequest, undefined, undefined>();

export const fetchChatToken = createAsyncAction(
  MemberActionTypes.FETCH_CHAT_TOKEN,
  MemberActionTypes.FETCH_CHAT_TOKEN_SUCCESS,
  MemberActionTypes.FETCH_CHAT_TOKEN_FAILURE,
)<number, string, undefined>();

export const fetchAnonymousChatToken = createAsyncAction(
  MemberActionTypes.FETCH_ANONYMOUS_CHAT_TOKEN,
  MemberActionTypes.FETCH_ANONYMOUS_CHAT_TOKEN_SUCCESS,
  MemberActionTypes.FETCH_ANONYMOUS_CHAT_TOKEN_FAILURE,
)<{ id: string; name: string }, string, undefined>();

export const fetchRestrictedMembers = createAsyncAction(
  MemberActionTypes.FETCH_RESTRICTED_MEMBERS_REQUEST,
  MemberActionTypes.FETCH_RESTRICTED_MEMBERS_SUCCESS,
  MemberActionTypes.FETCH_RESTRICTED_MEMBERS_FAILURE,
)<{ event_id: number }, IRestrictMember[], undefined>();

export const deleteMemberRestriction = (member_id: number, event_id: number) => {
  return action(MemberActionTypes.DELETE_MEMBER_RESTRICTION, { member_id, event_id });
};

export const restrictMember = (payload: IRestrictMember) => {
  return action(MemberActionTypes.RESTRICT_MEMBER, payload);
};

export const setCurrentRestrictedMember = (member: IRestrictMember | null) => {
  return action(MemberActionTypes.SET_CURRENT_RESTRICTED_MEMBER, member);
};

export const getMemberInvitationLink = (data: MemberInvitationLinkAction, history?: History) =>
  action(MemberActionTypes.GET_MEMBER_INVITATION_LINK, { data, history });

export const setUserMember = (member: BaseMember) => action(MemberActionTypes.GET_MEMBER_INVITATION_LINK, member);
