import React, { useState } from "react";
import { useSelector } from "react-redux";

import { BenchRow } from "../../components";
import { EventMembersCounter } from "../../../Sermons";
import { BreakoutRoomTypes } from "../../interfaces";
import BenchesGradient from "../../../Sermons/components/EventContainer/BenchesGradient";

import chunkArray from "shared/utils/chunkArray";
import { selectRooms } from "containers/BreakoutRooms/store/selectors";
import { AlertDialog } from "shared";

import "./index.scss";

const EventSeats: React.FC = () => {
  const rooms = useSelector(selectRooms());
  const benches = chunkArray(
    rooms.filter(r => r.type === BreakoutRoomTypes.auditorium),
    2,
  );
  const [isRoomFull, openRoomIsFull] = useState(false);

  return (
    <div className="seats">
      <AlertDialog
        open={isRoomFull}
        title="No seat available"
        message="This row is full, please choose another seat."
        onConfirm={() => openRoomIsFull(false)}
        mode="info"
        confirmText="Got it"
        variant="brown"
      />
      {benches.map((bench, idx) => (
        <BenchRow key={idx} benches={bench} orderNumber={idx + 1} onBenchFull={openRoomIsFull} />
      ))}
      <EventMembersCounter />
      <BenchesGradient />
    </div>
  );
};
export default EventSeats;
