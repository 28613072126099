import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import moment from "moment";
import classnames from "classnames";

import { NamesOfParentRoutes } from "../../../../../constants";
import NextEventCountDown from "./NextEventCountDown";

import { getEventTypeName, getRemainingTime, selectors } from "containers/Sermons";
import { Button } from "shared/components/Button";

import "./index.scss";

interface INextCard {
  onNextEvent: () => void;
}

const NextEventCard: React.FC<INextCard> = ({ onNextEvent }) => {
  const history = useHistory();

  const [showCard, setShowCard] = useState(true);

  const currentEvent = useSelector(selectors.getEvent());
  const nextEvent = useSelector(selectors.getRecurringEvent());

  const eventTypeName = useMemo(() => getEventTypeName(nextEvent?.type, true), [nextEvent]);

  if (!nextEvent) {
    return null;
  }

  if (nextEvent.code === currentEvent?.code) {
    return null;
  }
  const { name, starting_at, image_url, code } = nextEvent;
  const handleNextClick = () => {
    onNextEvent();
    history.push(`${NamesOfParentRoutes.STREAM}/${code}`);
  };

  const remainingTime = getRemainingTime(nextEvent);

  return (
    <div
      className={classnames("next-eventCard-overlay", { show: showCard && nextEvent })}
      onClick={() => setShowCard(false)}
    >
      <div className="next-eventCard" onClick={e => e.stopPropagation()}>
        {image_url && (
          <div className="next-eventCard-image">
            <img src={image_url} alt="event " />
          </div>
        )}
        <Button
          variant="custom-text"
          type="button"
          className="next-eventCard-close"
          onClick={() => setShowCard(false)}
        />
        <div className="next-eventCard-description">
          <div className="next-eventCard-description-title">{`Next ${eventTypeName}`}</div>
          <div className="next-eventCard-description-name">{name}</div>
          <div className="next-eventCard-description-date">{moment(starting_at).format("dddd, MMMM D, LT")}</div>
          <div className="next-eventCard-description-countDown">
            <NextEventCountDown initTime={remainingTime} />
          </div>
          <div className="next-eventCard-description-nextBtn">
            <Button variant="orange" onClick={handleNextClick}>
              {`Next ${eventTypeName}`}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NextEventCard;
