import React, { useCallback, useEffect, useMemo, useRef, useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/styles";
import { Grid, List, ListItem, ListItemIcon, ListItemText, Paper } from "@material-ui/core";
import { RouteChildrenProps, Switch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { handleFirebasePurchase } from "../../../../utils/firebase";
import { trackPurchaseEvent } from "../../../../utils/fbPixel";
import { CommunityIntroStepsContainer } from "../CommunityIntroStepsContainer";
import { getSubscriptionAttention } from "../../store/selectors";
import MAULimitBanner from "../../components/Community/MAULimitBanner";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../../constants";
import { authentificated, getOnboardingData, getToken, getUser } from "../../../Auth/store/selectors";
import routeAssessor from "../../../../utils/routeAssessor";
import { privateRoutes } from "../../../App/routes";
import { fetchMembersInCommunity } from "../../../Member/store/actions";
import { dashboardPanelTheme } from "../../../../theme";
import { clearOnboardingData, getUserData, logout, setToken } from "../../../Auth/store/actions";
import { CommunityDialog, EStripeStatusCode, IProduct, StripeCancelPopup, StripeSuccessPopup } from "../../";
import { actions, selectors } from "../../store";
import { Loader } from "../../../App";

import { getInitials, usePermissions, useUnreadCount } from "shared";
import { isMatchedPath } from "containers/Community/utils";
import Profile from "containers/User/components/Profile";
import { useGetStream } from "shared/hooks/GetStreamHook";
import { getUnreadMessagesCount } from "containers/Discussions/store/selectors";
import { CommunityPermissions, ERoles } from "containers/Auth/interfaces";
import {
  clearCurrentCommunity,
  createTrialForCommunityWithoutSubscription,
  getCommunitySubscriptions,
  sendHubSpotFormData,
  setSubscriptionAttention,
} from "containers/Community/store/actions";
import { loadStripe } from "shared/stores/stripe/actions";
import { AlertDialog, Blocker } from "shared/components";
import { objectToSearchParams, searchParamsToObject } from "shared/utils/searchParams";
import { EStripeResult } from "shared/interfaces/Stripe";
import { CommunitySwitch } from "containers/Onboarding/components/CommunitySwitch";
import CommunityCreationBlocker from "containers/Onboarding/components/CommunityCreationBlocker";
import { checkRoles } from "utils/ACL";
import { getDashboardState } from "containers/App/store/selectors";
import { ELocalStorage } from "shared/interfaces/Localstorage";
import { useIsMobile } from "shared/hooks/IsMobileHook";
import roundedArrow from "assets/icons/rounded_arrow.svg";
import church_icon from "assets/icons/church_icon.svg";
import community_dashboard from "assets/icons/community_dashboard.svg";
import date_blue from "assets/icons/date_blue.svg";
import members_blue from "assets/icons/members_blue.svg";
import conversations_blue from "assets/icons/conversations_blue.svg";
import settings from "assets/icons/settings.svg";
import analytics_icon from "assets/icons/analytics_icon.svg";
import laptop_dashboard from "assets/images/laptop_dashboard.png";

import "./dashboard.scss";

const { COMMUNITIES, CREATION, EVENT_DETAILS, EVENT, EVENTS, STREAM, DASHBOARD } = NamesOfParentRoutes;

const menuItems = [
  {
    text: "Dashboard",
    img: community_dashboard,
    path: "dashboard",
    forManager: true,
  },
  {
    text: "Events & Meetings",
    img: date_blue,
    path: "events",
    forManager: false,
  },
  {
    text: "Members",
    img: members_blue,
    path: "people",
    forManager: false,
  },
  {
    text: "Chats",
    img: conversations_blue,
    path: "conversations",
    forManager: false,
  },
  {
    text: "Analytics",
    img: analytics_icon,
    path: "analytics",
    forManager: true,
  },
  {
    text: "Community Settings",
    img: settings,
    path: "settings",
    forManager: true,
  },
];

const getStripeSuccessMessage = (membersCount?: number, isPaymentMethod?: boolean) =>
  isPaymentMethod
    ? "You successfully added payment method."
    : `You successfully upgraded your subscription ${
        membersCount ? `up to ${membersCount} Monthly Active Users` : ""
      }. Keep growing your online engagement with full-featured meetings & events!`;

const Dashboard: React.FC<RouteChildrenProps<any, any>> = ({ location, match, history }) => {
  const communityCode = match && (match.params as { community: string }).community;

  const streamEventUrl = isMatchedPath(location.pathname, [`/:communityCode${STREAM}/:eventCode`]);

  const isEventView = isMatchedPath(location.pathname, `/:community${EVENT}/:event_id${EVENT_DETAILS}`);

  const isCommunitySearch = isMatchedPath(location.pathname, COMMUNITIES);

  const isCommunityCreation = isMatchedPath(location.pathname, `${COMMUNITIES}${CREATION}`);

  const isCommunityEvents = isMatchedPath(location.pathname, `/:community${EVENTS}`);

  const { t } = useTranslation();

  const chatClient = useGetStream();

  const [openLogout, setOpenLogout] = useState(false);
  const [joinCommunity, setJoinCommunity] = useState(false);
  const [openCommunityDetails, setOpenCommunityDetails] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(location && location.pathname);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const [updatedProductId, setUpdatedProductId] = useState(0);
  const [updateSubscriptionInterval, setUpdateSubscriptionInterval] = useState<number | null>(null);
  const [updateSubscriptionIntervalForTrial, setUpdateSubscriptionIntervalForTrial] = useState<number | null>(null);
  const [stripeSuccessOpened, setStripeSuccessOpened] = useState(false);
  const [stripeCancelOpened, setStripeCancelOpened] = useState(false);
  const [stripeSuccessMessage, setStripeSuccessMessage] = useState("");
  const [profileImageError, setProfileImageError] = useState(false);
  const [prevSub, setPrevSub] = useState<IProduct | null>(null);

  const dispatch = useDispatch();

  const currentUser = useSelector(getUser());
  const isAuthentificated = useSelector(authentificated());
  const userCommunities = useSelector(selectors.getUserCommunities());
  const community = useSelector(selectors.getCommunity());
  const onboardingData = useSelector(getOnboardingData());
  const unreadMessages = useSelector(getUnreadMessagesCount());
  const disableDashboard = useSelector(getDashboardState());
  const subscriptionAttention = useSelector(getSubscriptionAttention());
  const storeToken = useSelector(getToken());

  useUnreadCount(chatClient);

  const communitySubscriptions = useSelector(selectors.getCommunitySubscriptions());
  const canceledSubscription = communitySubscriptions?.canceledSubscription;
  const currentProduct = communitySubscriptions?.currentSubscriptions[0];
  const nextProduct = currentProduct
    ? communitySubscriptions?.possibleProducts.find(({ id }) => id === currentProduct.next_tier_product_id)
    : communitySubscriptions?.possibleProducts[0];

  const hasPaidSubscription = communitySubscriptions?.currentSubscriptions.some(
    subscription => subscription.title !== "Free",
  );
  const {
    stripeSuccess,
    communityCreation,
    storeToken: storeTokenQueryParam,
    ...restParams
  } = useMemo(() => searchParamsToObject(location.search), [location]);
  const isMobile = useIsMobile();
  const isMember = currentUser && currentUser.members?.length > 0;
  const isCommunityManager = community && checkRoles([ERoles.manager], community.id);
  const isAdmin = checkRoles([ERoles.admin]);

  const scrollableDiv = useRef<HTMLDivElement>(null);
  const [scrolling, setScrolling] = useState(false);

  const activateHandler = () => {
    if (communityCode) {
      history.push(`/${communityCode}${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.BILLING_SETTINGS}`);
    }
  };

  useEffect(() => {
    if (storeTokenQueryParam && !storeToken) {
      dispatch(setToken(storeTokenQueryParam));
    }
  }, [dispatch, storeToken, storeTokenQueryParam]);

  useEffect(() => {
    const onScroll = () => setScrolling(window.pageYOffset > 0);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    setProfileImageError(false);
  }, [currentUser]);

  useEffect(() => {
    if (restParams.next_tier_product_id) {
      setUpdatedProductId(Number(restParams.next_tier_product_id));
    }
  }, [restParams.next_tier_product_id]);

  useEffect(() => {
    if (!isAuthentificated && streamEventUrl) {
      history.push(location.pathname.replace(`${communityCode}/`, ""));
    }
  }, [communityCode, history, isAuthentificated, location.pathname, streamEventUrl]);

  useEffect(() => {
    if (isEventView) {
      setIsCollapsed(true);
    }
  }, [isEventView]);

  useEffect(() => {
    if (isAuthentificated) {
      dispatch(getUserData.request());
    }
  }, [dispatch, isAuthentificated, communityCode]);

  useEffect(() => {
    currentUser && dispatch(actions.getUserCommunities.request({ currentUser }));
  }, [dispatch, currentUser, communityCode]);

  useEffect(() => {
    const path = location.pathname;
    if (!communityCode && currentUser && path.indexOf(COMMUNITIES) !== 0) {
      if (currentUser.members?.length === 1 && path === "/") {
        const isManager =
          currentUser.members[0].community?.id &&
          checkRoles([ERoles.admin, ERoles.manager], currentUser.members[0].community.id);
        history.push(`/${currentUser.members[0].community?.code}${isManager ? DASHBOARD : EVENTS}`);
      } else {
        history.push(COMMUNITIES);
      }
    }
  }, [communityCode, currentUser, history, location]);

  useEffect(() => {
    if (communityCode && isAuthentificated) {
      dispatch(actions.getCommunity.request(communityCode));
    }
  }, [communityCode, dispatch, isAuthentificated]);

  useEffect(() => {
    if (onboardingData?.communityCode && currentUser && userCommunities) {
      const { communityCode } = onboardingData;
      const userHasCommunity = currentUser.members.find(({ community, has_accepted_invitation }) => {
        return community?.code === communityCode && has_accepted_invitation;
      });
      userHasCommunity ? dispatch(clearOnboardingData()) : setJoinCommunity(true);
    }
  }, [onboardingData, currentUser, userCommunities, dispatch]);

  useEffect(() => {
    if (communityCode && isAuthentificated) {
      dispatch(fetchMembersInCommunity.request({ communityCode }));
    }
  }, [dispatch, communityCode, isAuthentificated]);

  const editPermissions = [CommunityPermissions.edit];
  const canEdit = usePermissions(editPermissions, community?.id);

  useEffect(() => {
    if (communityCode && isAuthentificated) {
      dispatch(getCommunitySubscriptions.request({ code: communityCode }));
    }
  }, [dispatch, communityCode, isAuthentificated]);

  useEffect(() => {
    if (!!location && location.pathname !== selectedIndex) {
      setSelectedIndex(location.pathname);
    }
  }, [location, selectedIndex, setSelectedIndex]);

  useEffect(() => {
    if (communitySubscriptions) {
      // TODO: move publishableKey from subscriptions
      dispatch(loadStripe.request(communitySubscriptions.publishableKey));
    }
  }, [communitySubscriptions, dispatch]);

  useEffect(() => {
    if (window.screen.width >= 1600 && !isCommunityCreation) {
      setIsCollapsed(false);
    }
  }, [isCommunityCreation]);

  useEffect(() => {
    isCommunityCreation && setIsCollapsed(true);
  }, [isCommunityCreation]);

  useEffect(() => {
    if (community) {
      dispatch(actions.getCommunityStreamingStatistics.request(community.id));
      dispatch(actions.getMAUData.request(community.id));
    }
  }, [dispatch, community]);

  useEffect(() => {
    if (communitySubscriptions && !prevSub && !updatedProductId) {
      setPrevSub(communitySubscriptions.currentSubscriptions[0] || communitySubscriptions.canceledSubscription);
    }
  }, [communitySubscriptions, prevSub, updatedProductId]);

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, path: string) => {
    setSelectedIndex(path);
    history.push(path);
  };

  const checkActiveNavItem = (rout: string) => (selectedIndex === rout ? "dashboard-navItem-active" : "");

  const signOut = () => dispatch(logout.request({ reload: false }));

  const joinToCommunityHandler = () => {
    if (onboardingData?.invitationHash) {
      dispatch(actions.joinToNewCommunity.request(onboardingData.invitationHash));
    }
    setJoinCommunity(false);
  };

  const skipJoinToCommunity = () => {
    dispatch(actions.clearCurrentCommunity());
    dispatch(clearOnboardingData());
    setJoinCommunity(false);
    history.push(COMMUNITIES);
  };

  const toggleIsCollapsed = () => {
    setIsCollapsed(prev => !prev);
  };

  const clearStripeResult = useCallback(() => {
    history.replace({ search: objectToSearchParams(restParams) });
  }, [history, restParams]);

  const onSwitchCommunity = !isCommunitySearch
    ? () => {
        dispatch(clearCurrentCommunity());
        history.push(COMMUNITIES);
      }
    : void 0;

  const handleCloseSuccessPopUp = useCallback(() => {
    clearStripeResult();
    setStripeSuccessMessage("");
    setStripeSuccessOpened(false);
  }, [clearStripeResult]);

  const handleSubmitFeedback = useCallback(
    (message: string) => {
      if (community) {
        const payload = {
          message,
          community_id: community.id,
          callback: handleCloseSuccessPopUp,
        };
        return dispatch(sendHubSpotFormData.request(payload));
      }
      handleCloseSuccessPopUp();
    },
    [dispatch, handleCloseSuccessPopUp, community],
  );

  useEffect(() => {
    const subscription = currentProduct || canceledSubscription;

    if (stripeSuccess === EStripeResult.success && communityCode && !updateSubscriptionInterval) {
      setUpdateSubscriptionInterval(
        setInterval(() => {
          dispatch(actions.getCommunitySubscriptions.request({ code: communityCode, hideLoading: true }));
        }, 2000),
      );
    }
    if (stripeSuccess === EStripeResult.paymentMethod) {
      setStripeSuccessOpened(true);
      setStripeSuccessMessage(getStripeSuccessMessage(undefined, true));
    }
    if (
      subscription &&
      updatedProductId &&
      updatedProductId === subscription.product_id &&
      updateSubscriptionInterval &&
      community &&
      currentUser &&
      communitySubscriptions &&
      [EStripeStatusCode.active, EStripeStatusCode.past_due, EStripeStatusCode.incomplete].includes(
        subscription.stripe_status_code,
      ) &&
      (!prevSub ||
        prevSub.id !== subscription.id ||
        prevSub.product_id !== subscription.product_id ||
        prevSub.stripe_status_code !== subscription.stripe_status_code)
    ) {
      clearInterval(updateSubscriptionInterval);
      setUpdateSubscriptionInterval(null);
      clearStripeResult();
      setPrevSub(subscription);
      if (subscription.stripe_status_code === EStripeStatusCode.active) {
        setStripeSuccessOpened(true);
        trackPurchaseEvent(community, currentUser, communitySubscriptions);
        handleFirebasePurchase(community, communitySubscriptions);
      } else {
        setStripeCancelOpened(true);
      }
    }
  }, [
    clearStripeResult,
    communityCode,
    currentProduct,
    dispatch,
    stripeSuccess,
    updateSubscriptionInterval,
    updatedProductId,
    community,
    currentUser,
    communitySubscriptions,
    canceledSubscription,
    prevSub,
  ]);

  useEffect(
    () => () => {
      if (updateSubscriptionInterval) {
        clearInterval(updateSubscriptionInterval);
      }
      if (updateSubscriptionIntervalForTrial) {
        clearInterval(updateSubscriptionIntervalForTrial);
      }
    },
    [communitySubscriptions, updateSubscriptionInterval, updateSubscriptionIntervalForTrial],
  );

  useEffect(() => {
    if (
      community &&
      canEdit &&
      !currentProduct &&
      canceledSubscription &&
      [EStripeStatusCode.past_due, EStripeStatusCode.incomplete, EStripeStatusCode.paused].includes(
        canceledSubscription.stripe_status_code,
      )
    ) {
      const canceledSubscriptionAttentionIds = JSON.parse(
        localStorage.getItem(ELocalStorage.subscriptionAttentionClosed) || "[]",
      );
      if (!canceledSubscriptionAttentionIds.includes(community?.id)) {
        dispatch(setSubscriptionAttention(true));
      }
    } else {
      if (subscriptionAttention) {
        dispatch(setSubscriptionAttention(false));
      }
    }
  }, [dispatch, canceledSubscription, currentProduct, canEdit, community, subscriptionAttention]);

  useEffect(() => {
    if (stripeSuccess === EStripeResult.billing) {
      if (currentProduct) {
        setStripeSuccessMessage(getStripeSuccessMessage(currentProduct.licensed_member_count));
        setStripeSuccessOpened(true);
        dispatch(setSubscriptionAttention(false));
      } else if (canceledSubscription) {
        setStripeCancelOpened(true);
      }
    }
  }, [stripeSuccess, currentProduct, dispatch, canceledSubscription]);

  useEffect(() => {
    if (
      communityCode &&
      canEdit &&
      !updateSubscriptionIntervalForTrial &&
      !canceledSubscription &&
      communitySubscriptions?.currentSubscriptions.length === 0
    ) {
      dispatch(
        createTrialForCommunityWithoutSubscription({
          community_code: communityCode,
          callback: () => {
            setUpdateSubscriptionIntervalForTrial(
              setInterval(() => {
                dispatch(actions.getCommunitySubscriptions.request({ code: communityCode, hideLoading: true }));
              }, 500),
            );
          },
        }),
      );
    } else if ((canceledSubscription || currentProduct) && updateSubscriptionIntervalForTrial) {
      clearInterval(updateSubscriptionIntervalForTrial);
      setUpdateSubscriptionIntervalForTrial(null);
    }
  }, [
    canceledSubscription,
    communitySubscriptions,
    dispatch,
    communityCode,
    updateSubscriptionIntervalForTrial,
    currentProduct,
    canEdit,
  ]);

  const activateButtonText = () => {
    if (canceledSubscription && !hasPaidSubscription) {
      return `Reactivate${isCollapsed ? "" : " Account"}`;
    } else if (!currentProduct?.price && !canceledSubscription) {
      return `Activate${isCollapsed ? "" : " Account"}`;
    } else if (currentProduct?.stripe_status_code === EStripeStatusCode.trialing) {
      return `Trial${isCollapsed ? "" : " Plan"}`;
    }
    return `Update${isCollapsed ? "" : " Plan"}`;
  };

  const showSorry = useMemo(() => {
    return isMobile && !isCommunityCreation && !isCommunitySearch;
  }, [isMobile, isCommunityCreation, isCommunitySearch]);

  if (showSorry) {
    return isCommunityEvents ? (
      <CommunityCreationBlocker communityCode={communityCode} isSelect={!communityCreation} />
    ) : (
      <Blocker
        mainImgSrc={laptop_dashboard}
        title="Sorry"
        description="This page is only available for Desktop.
        Please use your laptop to see all available events and Meetings"
      />
    );
  }

  return (
    <React.Fragment>
      {openCommunityDetails && (
        <CommunityDialog
          open={openCommunityDetails}
          onClose={() => setOpenCommunityDetails(false)}
          community={community}
        />
      )}
      <Profile show={showProfile} onClose={() => setShowProfile(false)} onLogout={() => setOpenLogout(true)} />
      <div className="dashboard">
        <StripeSuccessPopup
          opened={stripeSuccessOpened}
          message={stripeSuccessMessage || getStripeSuccessMessage(currentProduct?.licensed_member_count)}
          onClose={handleCloseSuccessPopUp}
          onSubmitMessage={handleSubmitFeedback}
        />
        <StripeCancelPopup
          opened={stripeSuccess === EStripeResult.fail || stripeCancelOpened}
          onClose={() => {
            clearStripeResult();
            setStripeCancelOpened(false);
          }}
        />
        <AlertDialog
          mode="confirm"
          open={openLogout}
          onCancel={() => setOpenLogout(false)}
          onConfirm={signOut}
          confirmText="Log out"
          title="Logout"
          message="Are you sure you want to log out of this account ?"
        />
        <AlertDialog
          mode="confirm"
          open={joinCommunity}
          onCancel={skipJoinToCommunity}
          onConfirm={joinToCommunityHandler}
          cancelText="skip"
          confirmText="join"
          title="Join Community"
          message={`You are going to join the ${
            onboardingData && onboardingData.communityName ? onboardingData.communityName : "New Community"
          }.
          Are you sure?`}
        />
        <div
          className={classnames(
            "dashboard-panel",
            isCollapsed && "dashboard-panel-collapsed",
            disableDashboard && "dashboard-disabled",
          )}
        >
          <div
            className={classnames("collapse-arrows-wrapper", { collapsed: isCollapsed })}
            onClick={toggleIsCollapsed}
          />
          <div className={classnames("collapse-arrows", { collapsed: isCollapsed })} onClick={toggleIsCollapsed}>
            <img src={roundedArrow} alt="collapse menu" />
          </div>
          <ThemeProvider theme={dashboardPanelTheme}>
            <Paper className={"dashboard-panel-container"} square={true}>
              <Grid className="dashboard-community-info">
                <CommunitySwitch
                  editLabel={isCollapsed ? t("Edit") : t("Edit Community")}
                  upgradeLabel={activateButtonText()}
                  isUpgradeEnabled={Boolean(community && canEdit && nextProduct)}
                  isCollapsed={isCollapsed}
                  items={userCommunities}
                  selected={community}
                  onSwitch={onSwitchCommunity}
                  onClick={() => community && setOpenCommunityDetails(true)}
                  showSwitcher={!isCommunityCreation}
                  upgradeButtonVariant={
                    currentProduct?.stripe_status_code === EStripeStatusCode.trialing ? "orange" : "blue"
                  }
                  activateHandler={activateHandler}
                />
              </Grid>
              <Grid item className="dashboard-navContainer">
                <List className="dashboard-nav" component="nav">
                  {menuItems &&
                    isMember &&
                    userCommunities?.length > 0 &&
                    !isCommunitySearch &&
                    !isCommunityCreation &&
                    menuItems.map((item, index) =>
                      !item.forManager || isCommunityManager || isAdmin ? (
                        <ListItem
                          key={index}
                          button
                          className={classnames(
                            "dashboard-navItem",
                            checkActiveNavItem(`/${communityCode}/${item.path}`),
                            isCollapsed && "dashboard-navItem-collapsed",
                            !community && "dashboard-navItem-disabled",
                          )}
                          selected={selectedIndex === `/${communityCode}/${item.path}`}
                          onClick={event =>
                            handleListItemClick(event, `/${communityCode || community?.code}/${item.path}`)
                          }
                        >
                          <ListItemIcon className={"dashboard-navIcon"}>
                            <img src={item.img} alt={item.text} />
                            {!!unreadMessages.personalUnread && item.path === "conversations" && (
                              <div className="notification" />
                            )}
                            {item.path === "analytics" && <div className="new-mark">NEW</div>}
                          </ListItemIcon>
                          {!isCollapsed && (
                            <ListItemText classes={{ primary: "dashboard-navItem-text" }} primary={t(item.text)} />
                          )}
                        </ListItem>
                      ) : null,
                    )}
                </List>
              </Grid>
              <Grid item xs={"auto"} className={classnames("dashboard-footerContainer", { collapsed: isCollapsed })}>
                <div className="dashboard-footer">
                  {hasPaidSubscription && canEdit && <CommunityIntroStepsContainer isCollapsed={isCollapsed} />}
                  {canEdit && (
                    <div>
                      <div className="editCommunity">
                        <div
                          className={classnames("editCommunity-btn", { collapsed: isCollapsed })}
                          onClick={() => setOpenCommunityDetails(true)}
                        >
                          <img className="editCommunity-icon" src={church_icon} alt="community icon" />
                          {!isCollapsed && <p>{t("Community Profile")}</p>}
                        </div>
                        {!isCollapsed && community && (
                          <div className="editCommunity-status">{hasPaidSubscription ? "Active" : "Inactive"}</div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className={classnames("dashboard-divider", { collapsed: isCollapsed })} />
                  <Grid item xs={12} className={classnames("dashboard-profile", { collapsed: isCollapsed })}>
                    <div className="dashboard-profile-user" onClick={() => setShowProfile(true)}>
                      <div
                        className={classnames("dashboard-profile-user-image", {
                          empty: !currentUser?.image_url || profileImageError,
                        })}
                      >
                        {currentUser?.image_url && !profileImageError ? (
                          <img
                            src={currentUser.image_url}
                            alt={`${currentUser?.first_name} ${currentUser?.last_name}`}
                            onError={() => setProfileImageError(true)}
                          />
                        ) : (
                          currentUser && getInitials(currentUser)
                        )}
                      </div>
                      {!isCollapsed && (
                        <div>
                          <div className="dashboard-profile-user-name">
                            {currentUser ? `${currentUser.first_name} ${currentUser.last_name}` : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </Grid>
                </div>
              </Grid>
            </Paper>
          </ThemeProvider>
        </div>
        <div
          className={classnames(
            "dashboard-body",
            scrolling && "dashboard-body-overflow",
            (isCollapsed || isEventView) && "dashboard-body-collapsed",
            disableDashboard && "dashboard-body-disabled",
          )}
          ref={scrollableDiv}
        >
          {(isAdmin || isCommunityManager) && (
            <MAULimitBanner panel={disableDashboard ? "hidden" : isCollapsed ? "collapsed" : "shown"} />
          )}

          {isCommunitySearch && (
            <div className="dashboard-body-logout-mobile">
              <span onClick={() => setOpenLogout(true)}>Log out</span>
            </div>
          )}
          <Suspense fallback={<Loader />}>
            <Switch>{privateRoutes.map(route => routeAssessor(null, route))}</Switch>
          </Suspense>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
