export enum AuthActionTypes {
  CHECK_USER = "@@AUTH/CHECK_USER",
  CHECK_USER_SUCCESS = "@@AUTH/CHECK_USER_SUCCESS",
  CHECK_USER_FAILURE = "@@AUTH/CHECK_USER_FAILURE",

  LOGIN = "@@AUTH/LOGIN",
  LOGIN_SUCCESS = "@@AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@@AUTH/LOGIN_FAILURE",

  SOCIAL_LOGIN = "@@AUTH/SOCIAL_LOGIN",
  SOCIAL_LOGIN_SUCCESS = "@@AUTH/SOCIAL_LOGIN_SUCCESS",
  SOCIAL_LOGIN_FAILURE = "@@AUTH/SOCIAL_LOGIN_FAILURE",

  MAGIC_LOGIN = "@@AUTH/MAGIC_LOGIN",

  CONFIRM_VERIFY_USER = "@@AUTH/CONFIRN_VERIFY_USER",
  CONFIRM_VERIFY_USER_SUCCESS = "@@AUTH/CONFIRN_VERIFY_USER_SUCCESS",
  CONFIRM_VERIFY_USER_FAILURE = "@@AUTH/CONFIRN_VERIFY_USER_FAILURE",

  LOGOUT = "@@AUTH/LOGOUT",
  LOGOUT_SUCCESS = "@@AUTH/LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "@@AUTH/LOGOUT_FAILURE",

  REGISTRATION = "@@AUTH/REGISTRATION",
  REGISTRATION_SUCCESS = "@@AUTH/REGISTRATION_SUCCESS",
  REGISTRATION_FAILURE = "@@AUTH/REGISTRATION_FAILURE",

  SET_ONBOARDING_DATA = "@@AUTH/SET_ONBOARDING_DATA",
  CLEAR_ONBOARDING_DATA = "@@AUTH/CLEAR_ONBOARDING_DATA",
  SET_LOADING = "@@AUTH/SET_LOGIN_LOADING",
  SET_ERROR = "@@AUTH/SET_ERROR",
  DO_UNAUTHORIZED = "@@AUTH/DO_UNAUTHORIZED",
  SET_FORGOT_EMAIL = "@@AUTH/SET_FORGOT_EMAIL",
  CLEAR_FORGOT_EMAIL = "@@AUTH/CLEAR_FORGOT_EMAIL",

  FORGOT = "@@AUTH/FORGOT",
  FORGOT_SUCCESS = "@@AUTH/FORGOT_SUCCESS",
  FORGOT_FAILURE = "@@AUTH/FORGOT_FAILURE",

  RESET = "@@AUTH/RESET",
  RESET_SUCCESS = "@@AUTH/RESET_SUCCESS",
  RESET_FAILURE = "@@AUTH/RESET_FAILURE",

  CHECK_RESET_HASH = "@@AUTH/CHECK_RESET_HASH",
  CHECK_RESET_HASH_SUCCESS = "@@AUTH/CHECK_RESET_HASH_SUCCESS",
  CHECK_RESET_HASH_FAILURE = "@@AUTH/CHECK_RESET_HASH_FAILURE",

  GET_MEMBER = "@@AUTH/GET_MEMBER",
  GET_MEMBER_SUCCESS = "@@AUTH/GET_MEMBER_SUCCESS",
  GET_MEMBER_FAILURE = "@@AUTH/GET_MEMBER_FAILURE",

  SEND_MAGIC_LINK_REQUEST = "@@AUTH/SEND_MAGIC_LINK_REQUEST",
  SEND_MAGIC_LINK_SUCCESS = "@@AUTH/SEND_MAGIC_LINK_SUCCESS",
  SEND_MAGIC_LINK_FAILURE = "@@AUTH/SEND_MAGIC_LINK_FAILURE",
  SEND_MAGIC_LINK_CLEAR = "@@AUTH/SEND_MAGIC_LINK_CLEAR",

  SET_EMAIL_RESENT = "@@AUTH/SET_EMAIL_RESENT",

  GET_TOKEN_BY_HASH = "@@AUTH/GET_TOKEN_BY_HASH",

  SET_UNVERIFIED_USER_ID = "@@AUTH/SET_UNVERIFIED_USER_ID",

  SET_REGISTRATION_DATA = "@@AUTH/SET_REGISTRATION_DATA",

  SET_USER_IS_NEW = "@@AUTH/SET_USER_IS_NEW",

  CLEAR_CURRENT_USER = "@@AUTH/CLEAR_CURRENT_USER",

  CLEAR_CONFIRMATION_ERROR = "@@AUTH/CLEAR_CONFIRMATION_ERROR",

  SET_TOKEN = "@@AUTH/SET_TOKEN",
}
