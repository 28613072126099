import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMember } from "../../../../Auth/store/selectors";

import { AlertDialog, Button } from "shared";
import { setIsLeavingEvent } from "containers/Sermons/store/actions";
import { getEvent, getLeavingEvent } from "containers/Sermons/store/selectors";
import { getEventTypeName, memberHasEventRoleOrAdmin } from "containers/Sermons";

import "./styles.scss";

type Props = {
  onLeaveEvent: (shouldRedirect: boolean, toBilling: boolean, leaveEventRedirect: boolean) => void;
};

const LeaveEvent: React.FC<Props> = ({ onLeaveEvent }) => {
  const dispatch = useDispatch();

  const isLeavingEvent = useSelector(getLeavingEvent());
  const currentEvent = useSelector(getEvent());
  const currentMember = useSelector(getMember());

  const eventTypeName = useMemo(() => getEventTypeName(currentEvent?.type), [currentEvent]);

  const leaveEventAdditionalOption = useMemo(() => {
    return currentEvent && currentMember
      ? memberHasEventRoleOrAdmin(currentEvent, currentMember) && !!currentEvent.leave_event_url
      : false;
  }, [currentEvent, currentMember]);

  const handleLeaveClick = useCallback(() => {
    dispatch(setIsLeavingEvent(true));
  }, [dispatch]);
  const handleCancelLeaving = useCallback(() => {
    dispatch(setIsLeavingEvent(false));
  }, [dispatch]);

  const handleConfirmLeaving = useCallback(
    (shouldRedirect: boolean, leaveEventRedirect: boolean) => {
      handleCancelLeaving();
      onLeaveEvent(shouldRedirect, false, leaveEventRedirect);
    },
    [handleCancelLeaving, onLeaveEvent],
  );

  const alertButtons = useMemo(() => {
    return [
      <button className="defaultButtons-cancel" onClick={handleCancelLeaving} key={0} type="button">
        Cancel
      </button>,
      <button className="defaultButtons-info" onClick={() => handleConfirmLeaving(true, false)} key={1} type="button">
        Back to Dashboard
      </button>,
      <button className="defaultButtons-confirm" onClick={() => handleConfirmLeaving(true, true)} key={2} type="button">
        Leave
      </button>,
    ];
  }, [handleCancelLeaving, handleConfirmLeaving]);

  return (
    <>
      <AlertDialog
        open={isLeavingEvent}
        title={`Leave ${eventTypeName}`}
        message={`Are you sure you want to leave the ${eventTypeName}? ${
          leaveEventAdditionalOption ? "Click Back to Home if you want to return to your Admin Dashboard." : ""
        }`}
        onConfirm={() => handleConfirmLeaving(true, true)}
        onCancel={handleCancelLeaving}
        mode="confirm"
        confirmText="leave"
        cancelText="cancel"
        variant="brown"
        customButtons={leaveEventAdditionalOption ? alertButtons : undefined}
      />
      <Button onClick={handleLeaveClick} width={56} height={56} className="leave-event icon">
        <span />
      </Button>
    </>
  );
};

export default LeaveEvent;
