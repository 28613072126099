import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import { actions } from "../../../store";
import { useMemberCID } from "../../../../../shared/hooks/MemberCIDHook";
import { useGetStream } from "../../../../../shared/hooks/GetStreamHook";

import { AlertDialog, StyledTextField } from "shared";

import "./styles.scss";

type Props = {
  opened: boolean;
  onClose: () => void;
};

const AnonymousUserRenamePopup: React.FC<Props> = ({ opened, onClose }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const cid = useMemberCID();
  const chatClient = useGetStream();

  const updateUserName = async (value: { name?: string }) => {
    if (value?.name && cid) {
      dispatch(actions.changeMemberUserName(cid, value.name));

      localStorage.setItem("custom_name", value.name);
      if (chatClient) {
        await chatClient?.upsertUser({ id: cid, name: value.name });
      }
    }
    onClose();
  };

  useEffect(() => {
    if (opened) {
      inputRef?.current?.focus();
    }
  }, [opened]);

  return (
    <Formik
      validationSchema={Yup.object({
        name: Yup.string().max(30, "Must be 30 characters or less").required(),
      })}
      initialValues={{ name: "" }}
      enableReinitialize
      onSubmit={updateUserName}
    >
      {({ values, errors, handleChange, handleSubmit, touched, setErrors }) => {
        return (
          <AlertDialog
            open={opened}
            title="Name Settings"
            message={"Edit your display name so people know that you’re here!"}
            mode="confirm"
            confirmText="save"
            onConfirm={handleSubmit}
            onCancel={() => {
              setErrors({});
              onClose();
            }}
            variant="brown"
            hasForm
          >
            <Form className="table-user-rename-popup">
              <StyledTextField
                inputRef={inputRef}
                fullWidth
                name="name"
                value={values.name}
                onChange={handleChange}
                errors={touched.name && errors}
                required
              />
            </Form>
          </AlertDialog>
        );
      }}
    </Formik>
  );
};

export default AnonymousUserRenamePopup;
