import { IChatNotificationSettings } from "../../containers/Notifications";

export default {
  get: () => {
    try {
      const settings = localStorage.getItem("notifySettings");
      if (settings) {
        return JSON.parse(settings);
      }
    } catch (err: any) {
      return null;
    }
  },
  set: (settings: IChatNotificationSettings) => {
    if (settings) {
      localStorage.setItem("notifySettings", JSON.stringify(settings));
    }
  },
  remove: () => {
    localStorage.removeItem("notifySettings");
  },
};
