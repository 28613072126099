import React, { useCallback, useMemo, useRef, useState } from "react";
import { MessageInput } from "stream-chat-react";
import { useSelector } from "react-redux";

import { EditMessageObj } from "../../interfaces";
import { CustomMessageInput } from "../CustomMessageInput";
import { DiscussionTheme } from "../../containers/DiscussionsContainer/DiscussionsContainer";
import { getCurrentRestrictedMember } from "../../../Member/store/selectors";
import { getEvent } from "../../../Sermons/store/selectors";
import { getSelectedChannelDetails } from "../../store/selectors";

import { AlertDialog } from "shared";
import { useGetStream } from "shared/hooks/GetStreamHook";

interface CustomMessageInputWrapperProp {
  handleStopEditing: () => void;
  handleClickInput?: () => void;
  editMessageDetails: EditMessageObj;
  disabledInput?: boolean;
  theme?: DiscussionTheme;
  uniqueTooltipId: string;
  openRestrictionAlert: () => void;
}

const CustomMessageInputWrapper: React.FC<CustomMessageInputWrapperProp> = props => {
  const {
    editMessageDetails,
    handleStopEditing,
    theme,
    disabledInput,
    handleClickInput,
    uniqueTooltipId,
    openRestrictionAlert,
  } = props;

  const currentRestrictedMember = useSelector(getCurrentRestrictedMember());
  const currentEvent = useSelector(getEvent());
  const selectedChannel = useSelector(getSelectedChannelDetails());

  const componentKey = editMessageDetails.message ? editMessageDetails.message.id : "";
  const chatClient = useGetStream();
  const [deleteDialogIsOpened, setDeleteDialogIsOpened] = useState(false);

  const cursorPositionRef = useRef(0);

  const editMessageId = useMemo(
    () => (editMessageDetails && editMessageDetails.message ? editMessageDetails.message.id : null),
    [editMessageDetails],
  );

  const channelWithGreeter = useMemo(() => {
    return currentEvent?.greeters.some(g => selectedChannel?.members.some(m => m.id === g.id));
  }, [currentEvent, selectedChannel]);

  const handleDeleteMessage = useCallback(async () => {
    if (chatClient && editMessageId) {
      await chatClient.deleteMessage(editMessageId);
      handleStopEditing();
    }
  }, [chatClient, editMessageId, handleStopEditing]);

  return (
    <>
      <AlertDialog
        title="Delete the message?"
        message="Are you sure you want to delete this message?"
        open={deleteDialogIsOpened}
        onConfirm={() => {
          setDeleteDialogIsOpened(false);
          handleDeleteMessage();
        }}
        onCancel={() => setDeleteDialogIsOpened(false)}
        confirmText="Delete"
        cancelText="Cancel"
        mode="cancel"
        variant="brown"
        dialogClassName="alert-dialog-popup"
        confirmClassName="defaultButtons-remove"
      />
      <MessageInput
        disabled={disabledInput}
        key={componentKey}
        {...editMessageDetails}
        Input={() => (
          <CustomMessageInput
            handleDelete={() => setDeleteDialogIsOpened(true)}
            theme={theme}
            handleStopEditing={handleStopEditing}
            editMessageDetails={editMessageDetails}
            onInputClick={handleClickInput}
            disabled={disabledInput}
            uniqueTooltipId={uniqueTooltipId}
            cursorPositionRef={cursorPositionRef}
            hasChatRestriction={!!currentRestrictedMember && !channelWithGreeter}
            openRestrictionAlert={openRestrictionAlert}
          />
        )}
      />
    </>
  );
};

export default CustomMessageInputWrapper;
