export interface INotification {
  key?: string;
  title: string;
  message?: string;
  variant: Variant | undefined;
}

export interface INotificationAnnouncement {
  id?: number;
  title: string;
  message: string;
  button_text?: string;
  button_link?: string;
}

export interface IChatNotificationSettings {
  notifyBanner: boolean;
  notifySound: boolean;
}

export enum Variant {
  default = "default",
  error = "error",
  success = "success",
  warning = "warning",
  info = "info",
}
