import { useSelector } from "react-redux";

import { getBrowserInfo } from "../../stores/browser/selectors";

export const useIsMobile = () => {
  const browserInfo = useSelector(getBrowserInfo());

  return !browserInfo || !browserInfo.os
    ? false
    : browserInfo.os === "Android OS" ||
        (browserInfo.os === "iOS" &&
          // @ts-ignore
          !["MacIntel", "iPad"].includes(navigator?.userAgentData?.platform || navigator.platform));
};
