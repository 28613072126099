import { Field, FieldArray, FieldAttributes, useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { FormControlLabel, RadioGroup } from "@material-ui/core";

import { RoomsSection } from "../RoomsSection";
import Divider from "../Divider";

import { EAuditoriumRoomsType, EEventPreview, EPreEventType, IEventForm } from "containers/Sermons";
import { classroomOptions } from "containers/Sermons/constants";
import { AlertDialog, Button, CustomTextField, DropDown, StyledLink, StyledRadio } from "shared";
import { LINKS } from "shared/constants/links";
import trash from "assets/icons/trash.svg";
import add_navigation_link from "assets/icons/add-navigation-link.svg";
import auditorium_with_rows_preview from "assets/images/auditorium_with_rows_preview.png";
import auditorium_with_tables_preview from "assets/images/auditorium_with_tables_preview.png";
import auditorium_without_tables_rows_preview from "assets/images/auditorium_without_tables_rows_preview.png";
import lobby_with_tables from "assets/images/lobby_with_tables_preview.png";
import lobby_with_classrooms from "assets/images/lobby_with_classrooms_preview.png";

import "./index.scss";

interface IProps {
  isMeetingEvent?: boolean;
}

export default function RoomsStep({ isMeetingEvent }: IProps) {
  const {
    values: {
      classrooms: { classRoomItems, enabled: classroomEnabled, mainClassroom },
      auditorium_rooms: { auditoriumRoomsEnabled, auditoriumRoomsCount },
      lobby_rooms: { lobbyRoomsEnabled, lobbyRoomsCount },
      type,
      stream_type,
      pre_event_type,
      auditorium_rooms_type,
    },
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormikContext<IEventForm>();

  const [roomSize, setRoomSize] = useState(20);
  const [streamTypeError, setStreamTypeError] = useState(false);
  const [streamWithoutRows, setStreamWithoutRows] = useState(false);

  const atLeastOneOption = useMemo(() => {
    return auditoriumRoomsEnabled || lobbyRoomsEnabled || !!classRoomItems.length;
  }, [auditoriumRoomsEnabled, lobbyRoomsEnabled, classRoomItems]);

  const auditoriumPreview = useMemo(() => {
    switch (auditorium_rooms_type) {
      case EAuditoriumRoomsType.row:
        return auditorium_with_rows_preview;
      case EAuditoriumRoomsType.table:
        return auditorium_with_tables_preview;
      case EAuditoriumRoomsType.none:
        return auditorium_without_tables_rows_preview;
    }
  }, [auditorium_rooms_type]);

  const onRemoveRoom = useCallback(
    (idToRemove: number) => {
      setFieldValue("classrooms", {
        classRoomItems: classRoomItems.filter(({ id }) => id !== idToRemove),
        enabled: classroomEnabled,
        mainClassroom,
      });
    },
    [classRoomItems, classroomEnabled, setFieldValue, mainClassroom],
  );

  const updateClassrooms = useCallback(
    (roomSize: number) => {
      const room_size = Number(roomSize);
      if (Number.isFinite(room_size)) {
        setRoomSize(room_size);
        const cr = classRoomItems.map(c => ({ ...c, room_size }));
        setFieldValue("classrooms", { classRoomItems: cr, enabled: classroomEnabled, mainClassroom });
      }
    },
    [classRoomItems, classroomEnabled, mainClassroom, setFieldValue],
  );

  const handleAddClassroom = useCallback(() => {
    setFieldValue("classrooms", {
      classRoomItems: [
        ...(classRoomItems || []),
        {
          room_size: roomSize,
          is_main: false,
          name: "",
          description: "",
          id: Math.random().toString(36).substring(7),
        },
      ],
      mainClassroom,
      enabled: classroomEnabled,
    });
  }, [classRoomItems, classroomEnabled, mainClassroom, roomSize, setFieldValue]);

  const handleToggleSwitcher = useCallback(
    (type: "auditorium_rooms" | "lobby_rooms" | "classrooms") => () => {
      switch (type) {
        case "auditorium_rooms":
          if (stream_type && auditoriumRoomsEnabled) {
            setStreamWithoutRows(true);
          }
          setFieldValue("auditorium_rooms", {
            auditoriumRoomsEnabled: !auditoriumRoomsEnabled,
            auditoriumRoomsCount,
          });
          setFieldValue("auditorium_rooms_type", EAuditoriumRoomsType.row);
          break;
        case "lobby_rooms":
          setFieldValue("lobby_rooms", {
            lobbyRoomsEnabled: !lobbyRoomsEnabled,
            lobbyRoomsCount,
          });
          break;
        case "classrooms":
          setFieldValue("classrooms", {
            classRoomItems: [...(classRoomItems || [])],
            enabled: !classroomEnabled,
            mainClassroom,
          });
          break;
      }
    },
    [
      setFieldValue,
      classRoomItems,
      classroomEnabled,
      auditoriumRoomsCount,
      auditoriumRoomsEnabled,
      lobbyRoomsCount,
      lobbyRoomsEnabled,
      mainClassroom,
      stream_type,
    ],
  );

  const handleChangeRoomsCount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, type: "auditorium_rooms" | "lobby_rooms") => {
      const { value } = e.target;
      const parsedValue = parseInt(e.target.value);
      if (value && isNaN(parsedValue)) {
        return;
      }
      switch (type) {
        case "auditorium_rooms":
          setFieldValue(type, {
            auditoriumRoomsCount: value ? parsedValue : value,
            auditoriumRoomsEnabled,
          });
          break;
        case "lobby_rooms":
          setFieldValue(type, { lobbyRoomsCount: value ? parsedValue : value, lobbyRoomsEnabled });
          break;
      }
    },
    [setFieldValue, auditoriumRoomsEnabled, lobbyRoomsEnabled],
  );

  useEffect(() => {
    if (errors.stream_type) {
      setStreamTypeError(true);
    }
  }, [errors]);

  useEffect(() => {
    if (pre_event_type !== EPreEventType.none && !auditoriumRoomsEnabled) {
      setStreamWithoutRows(true);
    }
  }, [pre_event_type, auditoriumRoomsEnabled]);

  return (
    <div className="roomsStep-wrapper">
      <AlertDialog
        open={streamTypeError}
        title="Set up your space"
        message="You have turned on the Auditorium but have not selected a stream source. Please select your stream source to be able to publish or save changes."
        onConfirm={() => setStreamTypeError(false)}
        mode="info"
        confirmText="Got it"
      />
      <AlertDialog
        open={streamWithoutRows}
        title={stream_type ? "Set up your space" : "Pre-Event Videos and Images"}
        message={
          stream_type
            ? "You have selected a stream source, but have turned off the Auditorium. If you don't turn on the Auditorium, the live stream won't play. Do you want to continue without turning on the Auditorium?"
            : "You have selected Pre-Event video or images, but have turned off the Auditorium. If you don't turn on the Auditorium, the pre-event images won't be shown. Do you want to continue without turning on the Auditorium?"
        }
        onCancel={() => {
          setStreamWithoutRows(false);
          handleToggleSwitcher("auditorium_rooms")();
        }}
        onConfirm={() => setStreamWithoutRows(false)}
        mode="confirm"
        confirmText="Yes, Continue"
        cancelText="No, Go Back"
      />
      <div className="step-title">
        Select how many {isMeetingEvent ? "" : "rows,"} tables and rooms you want to enable.
      </div>
      <StyledLink className="learn-more" href={LINKS.roomAndTables}>
        Learn more about rows, tables and rooms
      </StyledLink>
      <Divider />
      <div className="validation-error">
        {!atLeastOneOption
          ? "At least one option must be selected"
          : errors?.stream_type
          ? "Please select your stream source to be able to publish or save changes"
          : null}
      </div>
      {!isMeetingEvent && (
        <RoomsSection
          onToggle={handleToggleSwitcher("auditorium_rooms")}
          switchLabel="Auditorium"
          enabled={auditoriumRoomsEnabled}
          description="Turn on the Auditorium and connect a pre-recorded video or live stream feed."
          previewImg={auditoriumPreview}
          enabledPreviewBtn={auditoriumRoomsCount > 0 || auditorium_rooms_type === EAuditoriumRoomsType.none}
          previewType={EEventPreview.auditorium_rooms}
          previewItemCount={auditoriumRoomsCount}
        >
          <div className="auditoriumRooms">
            <RadioGroup
              name="auditorium_rooms_type"
              value={auditorium_rooms_type}
              onChange={handleChange}
              className="auditoriumRooms-control"
            >
              <FormControlLabel
                value={EAuditoriumRoomsType.row}
                control={<StyledRadio />}
                className={classnames("auditoriumRooms-control-radio", {
                  disabled: auditorium_rooms_type !== EAuditoriumRoomsType.row,
                })}
                label={
                  <Field name="auditorium_rooms.auditoriumRoomsCount">
                    {() => (
                      <CustomTextField
                        label="Number of rows available"
                        name="auditorium_rooms.auditoriumRoomsCount"
                        helperText={errors?.auditorium_rooms?.auditoriumRoomsCount}
                        value={auditoriumRoomsCount}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeRoomsCount(e, "auditorium_rooms")
                        }
                        onBlur={handleBlur}
                        onClick={() => setFieldValue("auditorium_rooms_type", EAuditoriumRoomsType.row)}
                      />
                    )}
                  </Field>
                }
              />
              <FormControlLabel
                value={EAuditoriumRoomsType.table}
                control={<StyledRadio />}
                className={classnames("auditoriumRooms-control-radio", {
                  disabled: auditorium_rooms_type !== EAuditoriumRoomsType.table,
                })}
                label={
                  <Field name="auditorium_rooms.auditoriumRoomsCount">
                    {() => (
                      <CustomTextField
                        label="Number of tables available"
                        name="auditorium_rooms.auditoriumRoomsCount"
                        helperText={errors?.auditorium_rooms?.auditoriumRoomsCount}
                        value={auditoriumRoomsCount}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeRoomsCount(e, "auditorium_rooms")
                        }
                        onBlur={handleBlur}
                        onClick={() => setFieldValue("auditorium_rooms_type", EAuditoriumRoomsType.table)}
                      />
                    )}
                  </Field>
                }
              />
              <FormControlLabel
                value={EAuditoriumRoomsType.none}
                control={<StyledRadio />}
                className={classnames("auditoriumRooms-control-radio", {
                  disabled: auditorium_rooms_type !== EAuditoriumRoomsType.none,
                })}
                label={<div className="text-label">No rows or tables available, video only</div>}
              />
            </RadioGroup>
            <div className="auditoriumRooms-description">
              Each row or table has 4 seats. (i.e. if you want up to 40 people to be able to take a seat, add 10 rows or
              tables)
            </div>
          </div>
        </RoomsSection>
      )}
      <RoomsSection
        onToggle={handleToggleSwitcher("lobby_rooms")}
        switchLabel="Lobby with Tables"
        enabled={lobbyRoomsEnabled}
        description="Turn on the Tables in the Lobby that can be named inside the Event."
        previewImg={lobby_with_tables}
        enabledPreviewBtn={lobbyRoomsCount > 0}
        previewType={EEventPreview.lobby_rooms}
        previewItemCount={lobbyRoomsCount}
      >
        <Field name={`lobby_rooms.lobbyRoomsCount`}>
          {({ field, form }: FieldAttributes<any>) => (
            <div className={classnames("lobbyRoomsControl", { withError: form.errors?.lobby_rooms?.lobbyRoomsCount })}>
              <CustomTextField
                label={
                  <>
                    <div className="text-label-title">Number of tables available</div>
                    <div className="text-label-description">
                      Each table has 4 seats. (i.e. if you want up to 40 people to be able to take a seat, add 10
                      tables)
                    </div>
                  </>
                }
                name="lobby_rooms.lobbyRoomsCount"
                helperText={form.errors?.lobby_rooms?.lobbyRoomsCount}
                value={field.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeRoomsCount(e, "lobby_rooms")}
                onBlur={handleBlur}
              />
            </div>
          )}
        </Field>
      </RoomsSection>
      <RoomsSection
        onToggle={handleToggleSwitcher("classrooms")}
        switchLabel="Lobby with Rooms"
        enabled={classroomEnabled}
        description={`Select the number of Rooms in the Lobby you want to add to your ${type}. Rooms names and descriptions can be edited from the ${type} editor at any time.`}
        previewImg={lobby_with_classrooms}
        enabledPreviewBtn={!!classRoomItems.length}
        previewType={EEventPreview.classrooms}
        previewItemCount={classRoomItems.length}
      >
        <div className="room-size">
          <p>How many people can join each room</p>
          <Field>
            {({ form }: FieldAttributes<any>) => (
              <DropDown
                size="full"
                width={216}
                items={classroomOptions}
                spacer={false}
                value={
                  classRoomItems.length > 0 && classRoomItems[0].room_size
                    ? classRoomItems[0].room_size
                    : roomSize || ""
                }
                onChange={(value: string | number) => updateClassrooms(Number(value))}
                isMenu
                editable
                error={form.errors?.classrooms?.classRoomItems[0]?.room_size}
              />
            )}
          </Field>
        </div>
        <div className="classroom-add-wrapper">
          <FieldArray name="classrooms.classRoomItems">
            {() =>
              classRoomItems.map((c, i) => (
                <div className="classroom" key={c.id}>
                  <div className="class-room-title-wrapper">
                    <div className="title">Room {i + 1}</div>
                    <div className="remove-button" onClick={() => onRemoveRoom(c.id || 0)}>
                      <img src={trash} alt="Remove" />
                      <span>Delete Room</span>
                    </div>
                  </div>
                  <Field name={`classrooms.classRoomItems[${i}].name`}>
                    {({ field, form }: FieldAttributes<any>) => (
                      <div className="item">
                        <CustomTextField
                          className="field"
                          fullWidth
                          label="Name"
                          placeholder="Type room name"
                          name={`classrooms.classRoomItems[${i}].name`}
                          helperText={
                            (form.touched?.classrooms?.classRoomItems?.[i]?.name || form.touched?.classrooms) &&
                            form.errors?.classrooms?.classRoomItems?.[i]?.name
                          }
                          value={field.value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          showMaxCounter
                          max={50}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name={`classrooms.classRoomItems[${i}].description`}>
                    {({ field, form }: FieldAttributes<any>) => (
                      <div className="item">
                        <CustomTextField
                          helperText={
                            (form.touched?.classrooms?.classRoomItems?.[i]?.description || form.touched?.classrooms) &&
                            form.errors?.classrooms?.classRoomItems?.[i]?.description
                          }
                          className="field"
                          fullWidth
                          label="Description"
                          placeholder="Type description of the room"
                          name={`classrooms.classRoomItems[${i}].description`}
                          value={field.value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          showMaxCounter
                          max={100}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              ))
            }
          </FieldArray>
          <Button width={136} variant="blue-text" className="add-step" onClick={handleAddClassroom} type="button">
            <img src={add_navigation_link} alt="add-new-member" />
            Add New Room
          </Button>
        </div>
      </RoomsSection>
    </div>
  );
}
