import React from "react";
import classnames from "classnames";

import { StyledCircle, StyledPolygon, StyledArch, StyledSquarePolygon, Button } from "..";

import "./authMain.scss";

interface IAuthMain {
  onProceed?: () => void;
  buttonText?: string;
  onContinue?: () => void;
  bgImageSrc?: string;
}

const AuthMain: React.FC<IAuthMain> = ({ onContinue, onProceed, buttonText, children, bgImageSrc }) => {
  return (
    <div className="authMain">
      <div className="authMain-figures">
        <StyledCircle className="authMain-figures-circle" />
        <StyledPolygon className="authMain-figures-polygon" />
        <StyledArch className="authMain-figures-arch" />
        <StyledSquarePolygon className="authMain-figures-squarePolygon" />
      </div>
      {!!bgImageSrc && (
        <div className="authMain-bg">
          <img src={bgImageSrc} alt="background" />
        </div>
      )}
      <div className="authMain-actions">
        {onProceed && (
          <Button width={192} variant="blue" onClick={onProceed}>
            {buttonText ? buttonText : "proceed"}
          </Button>
        )}
        {onContinue && (
          <Button width="172" variant="orange" type="button" onClick={onContinue}>
            {buttonText ? buttonText : "begin"}
          </Button>
        )}
      </div>
      <div className={classnames("authMain-content", onContinue || onProceed ? "header" : "")}>{children}</div>
    </div>
  );
};

export default AuthMain;
