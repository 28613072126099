import React, { useEffect, useState, useCallback, useMemo } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useParams } from "react-router";
import lodash from "lodash";
import { UserResponse } from "stream-chat";

import { CommunityMembersList } from "./CommunityMembersList";
import useDebounce from "../../hooks/DebounceHook/DebounceHook";
import SelectedMembers from "./SelectedMembers/SelectedMembers";
import { NoMatchesFound } from "./NoMatchesFound";
import { EEmptyStateTextType, OnSelectWithMembers, TSelectMembersModal } from "./interfaces";
import CanView from "../CanView/CanView";
import StyledTextField from "../StyledTextField/StyledTextField";
import Button, { ButtonVariants } from "../Button/Button";
import DropDown from "../DropDown/DropDown";
import { useGetStream } from "../../hooks/GetStreamHook";
import { MODAL_ROOT_ID } from "../../../constants";

import { hideWidget, showWidget } from "utils/hubspot";
import { notEmptyArray } from "utils/notEmptyArray";
import { fetchMembersInCommunityToModal } from "containers/Member/store/actions";
import { ESCAPE_KEYS, IRouteProps } from "shared";
import { getCommunity } from "containers/Community/store/selectors";
import { getMember, getMembersToModal } from "containers/Member/store/selectors";
import { IMemberToMembersModal, IMember } from "containers/Member";
import { IDropDown, IDropDownItem, SearchInput } from "shared/components/index";

import "./selectMembersModal.scss";

const modalNode = document.getElementById(MODAL_ROOT_ID);

const prepareItemToView = (member: IMember | null): IMemberToMembersModal | null => {
  if (member) {
    const { last_name, first_name, image_url, email, should_create_as_manager } = member;
    return {
      last_name,
      first_name,
      image_url,
      email,
      should_create_as_manager,
    };
  }
  return null;
};

enum FilterValues {
  ALL = "all",
  ONLINE = "online",
  OFFLINE = "offline",
}

enum SortValues {
  ASC = "asc",
  DESC = "desc",
}

const filterOptions: IDropDownItem[] = [
  {
    text: "Show all",
    value: FilterValues.ALL,
  },
  {
    text: "Online",
    value: FilterValues.ONLINE,
  },
  {
    text: "Offline",
    value: FilterValues.OFFLINE,
  },
];

const sortOptions: IDropDownItem[] = [
  {
    text: "A-Z",
    value: SortValues.ASC,
  },
  {
    text: "Z-A",
    value: SortValues.DESC,
  },
];

const emptyStateText = {
  [EEmptyStateTextType.chat]: {
    [FilterValues.ALL]: "No members to chat with yet",
    [FilterValues.ONLINE]: "No members to chat with are currently Online",
    [FilterValues.OFFLINE]: "No members to chat with are currently Offline",
  },
  [EEmptyStateTextType.event]: {
    [FilterValues.ALL]: "No Members in the community yet",
    [FilterValues.ONLINE]: "No Members in the community are currently Online",
    [FilterValues.OFFLINE]: "No Members in the community are currently Offline",
  },
};

const SelectMembersModal: React.FC<TSelectMembersModal> = props => {
  const {
    open,
    onClose,
    onSearch,
    multiselect,
    title,
    selectText,
    notAllowedMemberIds,
    withName,
    onSelect,
    onSelectWithName,
    notFoundText,
    useFilters,
    allowSelectQty,
    theme,
    emptyStateTextType,
    managerView = false,
    styleTheme = "light",
  } = props;

  const { communityCode } = useParams<IRouteProps>();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const chatClient = useGetStream();
  const [filter, setFilter] = useState<FilterValues>(useFilters ? FilterValues.ONLINE : FilterValues.ALL);
  const [sort, setSort] = useState<string>(SortValues.ASC);
  const [threadName, setThreadName] = useState<string>("");
  const [membersList, setMembersList] = useState<IMember[]>([]);
  const [onlineMemberIds, setOnlineMemberIds] = useState<number[] | undefined>(undefined);
  const [selectedItems, setSelectedItems] = useState<IMemberToMembersModal[]>([]);
  const [showList, setShowList] = useState(filter !== FilterValues.ONLINE);
  const [search, setSearch] = useState("");

  const debounceSearch = useDebounce(search, 500, 3);

  const currentCommunity = useSelector(getCommunity());
  const communitiesMembersList = useSelector(getMembersToModal());
  const initialMemberList = useSelector(getMember());

  const nameVisible = !!(withName && selectedItems.length > 1);

  const getDefaultMembersList = useCallback(
    community => {
      return communitiesMembersList?.filter(
        memberCommunity =>
          memberCommunity.community_id === community.id && !notAllowedMemberIds?.find(id => id === memberCommunity.id),
      );
    },
    [communitiesMembersList, notAllowedMemberIds],
  );
  const fetchOnlineMembers = useCallback(
    async (members: IMember[]) => {
      const memberIds = members.map(({ id }) => id.toString());
      const membersChunks = lodash.chunk(memberIds, 100);
      let totalMembers: UserResponse[] = [];
      for (const chunk of membersChunks) {
        const response = await chatClient?.queryUsers(
          {
            id: {
              $in: chunk,
            },
          },
          {},
          {
            limit: chunk.length,
          },
        );
        const users = response?.users || [];
        totalMembers = totalMembers.concat(users);
      }
      const onlineMemberIds = totalMembers.filter(member => member.online).map(member => Number(member.id));

      setOnlineMemberIds(onlineMemberIds);
    },
    [chatClient],
  );
  const selectedMembersToView = useCallback(() => {
    return [...selectedItems];
  }, [selectedItems]);
  const getSelectedIds = useCallback(() => {
    return selectedItems.map(item => item.id).filter(notEmptyArray);
  }, [selectedItems]);
  const selectAllHandler = useCallback(() => {
    const membersToView = membersList.map(member => prepareItemToView(member));
    membersToView && setSelectedItems([...membersList]);
  }, [membersList]);
  const unselectAllHandler = useCallback(() => {
    setSelectedItems([]);
  }, []);

  useEffect(() => {
    if (communityCode || currentCommunity) {
      dispatch(
        fetchMembersInCommunityToModal.request({
          communityCode: communityCode || currentCommunity?.code || "",
          params: { search: debounceSearch },
        }),
      );
    }
  }, [debounceSearch, dispatch, communityCode, currentCommunity]);

  useEffect(() => {
    open && onSearch && onSearch(debounceSearch);
  }, [open, debounceSearch, onSearch]);
  useEffect(() => {
    const escFunction = (e: KeyboardEvent) => {
      if (ESCAPE_KEYS.includes(String(e.keyCode))) {
        onClose && onClose();
      }
    };

    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [onClose]);
  useEffect(() => {
    if (open) {
      hideWidget();
      document.body.classList.add("hideOverflow-sideDialog");
    }
    return () => {
      showWidget();
      document.body.classList.remove("hideOverflow-sideDialog");
      setSelectedItems([]);
      setOnlineMemberIds([]);
    };
  }, [open]);
  useEffect(() => {
    if (!useFilters || !membersList || !membersList.length || onlineMemberIds !== undefined) {
      return;
    }

    fetchOnlineMembers(membersList);
    // eslint-disable-next-line
  }, [membersList]);
  useEffect(() => {
    if (!currentCommunity) {
      return;
    }
    let newList: IMember[] = getDefaultMembersList(currentCommunity);
    switch (filter) {
      case FilterValues.ONLINE:
        newList = onlineMemberIds ? newList.filter(member => onlineMemberIds.includes(member.id)) : newList;
        break;
      case FilterValues.OFFLINE:
        newList = newList.filter(member => !onlineMemberIds?.includes(member.id));
        break;
      default:
        break;
    }
    switch (sort) {
      case SortValues.ASC:
        newList = newList.sort((a, b) => {
          const aName = `${a.first_name.toLowerCase()} ${a.last_name.toLowerCase()}`.trim();
          const bName = `${b.first_name.toLowerCase()} ${b.last_name.toLowerCase()}`.trim();
          return aName > bName ? 1 : -1;
        });
        break;
      case SortValues.DESC:
        newList = newList.sort((a, b) => {
          const aName = `${a.first_name.toLowerCase()} ${a.last_name.toLowerCase()}`.trim();
          const bName = `${b.first_name.toLowerCase()} ${b.last_name.toLowerCase()}`.trim();
          return aName < bName ? 1 : -1;
        });
        break;
      default:
        break;
    }
    setMembersList(newList);
  }, [filter, sort, getDefaultMembersList, currentCommunity, onlineMemberIds]);

  useEffect(() => {
    if (filter === FilterValues.ONLINE && !!onlineMemberIds) {
      setShowList(true);
    }
  }, [filter, onlineMemberIds]);

  const modalButtonWidth = useMemo<number>(() => {
    switch (theme) {
      case "stream":
        return 120;
      default:
        return 120;
    }
  }, [theme]);
  const cancelButtonVariant = useMemo<ButtonVariants>(() => {
    switch (theme) {
      case "stream":
        return "purple";
      default:
        return withName ? "blue-outline" : "blue-text";
    }
  }, [theme, withName]);
  const selectButtonVariant = useMemo<ButtonVariants>(() => {
    return !selectedItems.length ? "disabled" : "blue";
  }, [selectedItems]);
  const dropdownVariant = useMemo<IDropDown["variant"]>(() => {
    switch (theme) {
      case "stream":
        return "purple";
      default:
        return "default";
    }
  }, [theme]);
  const dropdownStyles = useMemo(() => {
    switch (theme) {
      case "stream":
        return {
          width: 152,
          height: 48,
        };
      default:
        return { width: 144, height: 40 };
    }
  }, [theme]);

  const submitSelect = useCallback(() => {
    onSearch && onSearch("");

    const membersToSelect: IMember[] = [];
    selectedItems.forEach(item => {
      const initialMember = initialMemberList.find(member => member.id === item.id);
      if (initialMember) {
        membersToSelect.push(initialMember);
      }
    });

    (onSelect as OnSelectWithMembers)(membersToSelect);
    if (withName && onSelectWithName) {
      onSelectWithName({ members: membersToSelect, name: threadName });
    }
  }, [onSearch, onSelect, onSelectWithName, selectedItems, threadName, withName, initialMemberList]);

  const selectMember = useCallback(
    (member: IMemberToMembersModal) => {
      if (multiselect) {
        const alreadySelected = !!selectedItems.find(item => item.id === member.id);
        alreadySelected
          ? setSelectedItems(selectedItems.filter(i => i.id !== member.id))
          : setSelectedItems([...selectedItems, { ...member }]);
      } else {
        setSelectedItems([{ ...member }]);
      }
    },
    [multiselect, selectedItems],
  );

  const nameErrors = threadName && threadName.length > 100 ? { name: "Discussion name is too long" } : null;
  const errorText = debounceSearch ? notFoundText : emptyStateText[emptyStateTextType][filter];
  return (
    modalNode &&
    createPortal(
      open && (
        <div className={classNames("selectMembersModalContainer", theme && { [theme]: !!theme })}>
          <div className="selectMembersModal">
            <header className="selectMembersModal-header">
              <h1 className="selectMembersModal-header-title">{t(title)}</h1>
              <CanView condition={!!useFilters}>
                <DropDown
                  {...dropdownStyles}
                  variant={dropdownVariant}
                  value={filter}
                  items={filterOptions}
                  size="sm"
                  spacer={false}
                  onChange={val => setFilter(val.toString() as FilterValues)}
                />
                <DropDown
                  {...dropdownStyles}
                  variant={dropdownVariant}
                  value={sort}
                  items={sortOptions}
                  size="sm"
                  spacer={false}
                  onChange={val => setSort(val.toString())}
                  classname="sort-by"
                />
              </CanView>
              <SearchInput
                collapsable={useFilters}
                onChange={value => setSearch(value)}
                defaultValue={search || ""}
                className="selectMembersModal-searchInput"
              />
            </header>
            <div className={classNames("selectMembersModal-body", { short: !!withName && !!selectedItems.length })}>
              {membersList.length && showList ? (
                <CommunityMembersList
                  prepareMemberToView={prepareItemToView}
                  communityMembersList={membersList}
                  onSelect={selectMember}
                  multiSelect={multiselect}
                  selectedIds={getSelectedIds()}
                  allowSelectQty={allowSelectQty}
                  onlineMemberIds={onlineMemberIds}
                  theme={theme}
                  managerView={managerView}
                />
              ) : (
                <NoMatchesFound text={errorText} />
              )}
            </div>
            <footer className="selectMembersModal-footer">
              <div
                className={`selectedMembers-container 
                  ${!!selectedItems.length && "selectedMembers-container-visible"}`}
              >
                <SelectedMembers members={selectedMembersToView()} theme={styleTheme} />
              </div>

              <CanView condition={nameVisible}>
                <StyledTextField
                  className={classNames("discussionNameInput")}
                  placeholder="Type Chat Name here"
                  name="name"
                  errors={nameErrors}
                  value={threadName}
                  onChange={({ target: { value } }: { target: { value: string } }) => setThreadName(value)}
                />
              </CanView>
              <div
                className={classNames(
                  "selectMembersModal-footer-bootem",
                  !!withName && selectedItems.length > 0 ? "withMargin" : "",
                )}
              >
                <CanView condition={!!withName}>
                  <div className={classNames("selectMembersModal-additional_buttons")}>
                    <div className="selectMembersModal-select_all">
                      <Button
                        onClick={selectAllHandler}
                        variant={selectedItems.length === membersList.length ? "gray-text" : "blue-text"}
                        disabled={selectedItems.length === membersList.length}
                        type="button"
                      >
                        Select all
                      </Button>
                    </div>
                    <div className="selectMembersModal-deselect_all">
                      <Button
                        onClick={unselectAllHandler}
                        variant={selectedItems.length ? "blue-text" : "gray-text"}
                        disabled={!selectedItems.length}
                        type="button"
                      >
                        Unselect all
                      </Button>
                    </div>
                  </div>
                </CanView>
                <div className={classNames("selectMembersModal-footer-buttons")}>
                  <Button
                    className={classNames({ cancel: withName })}
                    width={modalButtonWidth}
                    type="button"
                    variant={cancelButtonVariant}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    width={modalButtonWidth}
                    type="button"
                    variant={selectButtonVariant}
                    onClick={submitSelect}
                    disabled={!selectedItems.length || !!(nameVisible && nameErrors)}
                  >
                    {selectText || "Select"}
                  </Button>
                </div>
              </div>
            </footer>
          </div>
        </div>
      ),
      modalNode,
    )
  );
};

export default SelectMembersModal;
