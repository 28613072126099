import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMediaPermissions } from "../../../store/selectors";
import { askUserMediaPermission, checkUserMediaPermissions } from "../../../store/actions";
import { MediaConstraints } from "../../../constants";
import { RtcDetect } from "../../../utils/detectRtc";

import { getBrowserInfo } from "shared/stores/browser/selectors";
import { AlertDialog } from "shared";

import "./styles.scss";

interface IPermissionsAlert {
  joinToRoom: () => void;
  setMediaPermissionsAlert: Dispatch<SetStateAction<boolean>>;
}

const PermissionsAlert: React.FC<IPermissionsAlert> = props => {
  const { joinToRoom, setMediaPermissionsAlert } = props;

  const dispatch = useDispatch();

  const mediaPermissions = useSelector(getMediaPermissions());
  const { name: browserName } = useSelector(getBrowserInfo()) || {};

  const [clickRequestPermissions, setClickRequestPermissions] = useState(false);
  const [classNameBtn, setClassNameBtn] = useState("active-btn");
  const [openAlert, setOpenAlert] = useState(false);
  const [confirmText, setConfirmText] = useState<string>();
  const [firstStepText, setFirstStepText] = useState<React.ReactNode>();
  const [secondStepText, setSecondStepText] = useState<React.ReactNode>();
  const [thirdStepText, setThirdStepText] = useState<React.ReactNode>();
  const [cameraIcon, setCameraIcon] = useState<string | null>(null);
  const [mediaPermissionsImg, setMediaPermissionsImg] = useState<string | null>(null);

  const handleCancelAlertdialog = useCallback(() => {
    setMediaPermissionsAlert(false);
    setClickRequestPermissions(false);
    setOpenAlert(false);
  }, [setMediaPermissionsAlert, setClickRequestPermissions, setOpenAlert]);

  const handleConfirmAlertDialog = useCallback(() => {
    if (mediaPermissions === "prompt") {
      dispatch(askUserMediaPermission(MediaConstraints));
      setClassNameBtn("disable-btn");
    } else if (mediaPermissions === "denied") {
      dispatch(askUserMediaPermission(MediaConstraints));
      setClickRequestPermissions(true);
    } else if (clickRequestPermissions && mediaPermissions === "granted") {
      joinToRoom();
      handleCancelAlertdialog();
    }
  }, [dispatch, mediaPermissions, joinToRoom, clickRequestPermissions, handleCancelAlertdialog]);

  const permissionsPopupAlert = useCallback(async () => {
    setOpenAlert(true);
    switch (true) {
      case mediaPermissions === "denied" && clickRequestPermissions: {
        if (browserName === "firefox" || browserName === "safari") {
          setClassNameBtn("active-btn");
          setConfirmText("Allow camera and mic");
        } else {
          setClassNameBtn("disable-btn");
          setConfirmText("I've Allowed Access");
        }
        break;
      }
      case mediaPermissions === "prompt" &&
        clickRequestPermissions &&
        (browserName === "firefox" || browserName === "safari"): {
        setClassNameBtn("active-btn");
        setConfirmText("Allow camera and mic");
        break;
      }
      case mediaPermissions === "granted" && clickRequestPermissions: {
        setClassNameBtn("active-btn");
        setConfirmText("I've Allowed Access");
        break;
      }
      case mediaPermissions === "denied" && !clickRequestPermissions: {
        if (browserName === "firefox" || browserName === "safari") {
          setClickRequestPermissions(true);
        }
        setConfirmText("Allow camera and mic");
        setClassNameBtn("active-btn");
        break;
      }
      case mediaPermissions === "prompt" && !clickRequestPermissions: {
        setConfirmText("Allow camera and mic");
        setClassNameBtn("active-btn");
        break;
      }
    }
  }, [browserName, mediaPermissions, clickRequestPermissions]);

  useEffect(() => {
    if (browserName) {
      import(`assets/icons/icon_permission_${browserName}.svg`)
        .then(path => {
          setCameraIcon(path.default);
        })
        .catch(() => {});
    }
  }, [browserName]);
  useEffect(() => {
    if (browserName) {
      import(
        `assets/images/media_permission_${clickRequestPermissions ? "2nd" : "1st"}_${browserName}.${
          browserName === "safari" && clickRequestPermissions ? "svg" : "gif"
        }`
      )
        .then(path => {
          setMediaPermissionsImg(path.default);
        })
        .catch(() => {});
    }
  }, [browserName, clickRequestPermissions]);

  useEffect(() => {
    switch (browserName) {
      case "chrome":
      case "edge-chromium": {
        if (mediaPermissions === "granted" && !clickRequestPermissions) {
          joinToRoom();
          handleCancelAlertdialog();
        } else if (mediaPermissions === "denied") {
          setClickRequestPermissions(true);
          dispatch(askUserMediaPermission(MediaConstraints));
          permissionsPopupAlert();
        } else {
          permissionsPopupAlert();
        }
        setFirstStepText(
          <>
            Click the camera icon&nbsp;
            {cameraIcon && <img src={cameraIcon} alt="camera icon" className="camera-icon" />}
            &nbsp;in the far right of the URL bar.
          </>,
        );
        setSecondStepText(<>Select ‘Always allow’ followed by ‘Done’.</>);
        setThirdStepText(<>Finally, click the ‘I’ve Allowed Access’ button below.</>);
        break;
      }
      case "safari":
      case "firefox": {
        (async () => {
          const { isWebsiteHasMicrophonePermissions, isWebsiteHasWebcamPermissions } = await RtcDetect();
          if ((!isWebsiteHasMicrophonePermissions || !isWebsiteHasWebcamPermissions) && mediaPermissions !== "prompt") {
            dispatch(checkUserMediaPermissions.success("prompt"));
          } else if (isWebsiteHasMicrophonePermissions && !clickRequestPermissions) {
            joinToRoom();
            handleCancelAlertdialog();
            return;
          }
          permissionsPopupAlert();
        })();
        if (browserName === "safari") {
          setFirstStepText(<>On your navigation bar, click on Safari to open the drop down menu.</>);
          setSecondStepText(<>Click on Preferences to open the Websites dialogue box.</>);
          setThirdStepText(
            <>
              Click on the Camera tab and the Microphone tab in the list and find the drop down menu. Click on Allow to
              give permissions.
            </>,
          );
        } else {
          setFirstStepText(
            <>
              Click the camera icon&nbsp;
              {cameraIcon && <img src={cameraIcon} alt="camera icon" className="camera-icon" />}
              &nbsp;in the far left of the URL bar.
            </>,
          );
          setSecondStepText(<>Click to unblock camera and microphone permissions.</>);
          setThirdStepText(<>Finally, click the ‘I’ve Allowed Access’ button below.</>);
        }
        break;
      }
      default: {
        joinToRoom();
        handleCancelAlertdialog();
      }
    }
  }, [
    dispatch,
    clickRequestPermissions,
    permissionsPopupAlert,
    browserName,
    joinToRoom,
    handleCancelAlertdialog,
    mediaPermissions,
    cameraIcon,
  ]);

  return (
    <AlertDialog
      variant="brown"
      mode="confirm"
      title={
        clickRequestPermissions
          ? "Unfortunately, your Microphone and Camera are locked by browser"
          : "Microphone and Camera Permissions"
      }
      message={
        clickRequestPermissions ? (
          <div className="secondText">
            <div className="text-content-title">Please follow these instructions:</div>
            <ul>
              <li>
                <span className="digit">1</span>
                <div>{firstStepText}</div>
              </li>
              <li>
                <span className="digit">2</span>
                <div>{secondStepText}</div>
              </li>
              <li>
                <span className="digit">3</span>
                <div>{thirdStepText}</div>
              </li>
            </ul>
          </div>
        ) : (
          <div>Please allow Altar to use your microphone and camera so you can see and hear others.</div>
        )
      }
      open={openAlert}
      onCancel={handleCancelAlertdialog}
      onConfirm={handleConfirmAlertDialog}
      confirmText={confirmText}
      cancelText={clickRequestPermissions ? "Continue without devices" : "Cancel"}
      confirmClassName={classNameBtn}
      dialogClassName={`mediaPermissionsDialog ${clickRequestPermissions ? "second-popup" : ""}`}
    >
      {mediaPermissionsImg && (
        <img src={mediaPermissionsImg} alt="media permissions img" className="media-permissions-img" />
      )}
    </AlertDialog>
  );
};

export default PermissionsAlert;
