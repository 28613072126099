import React, { useEffect, useState } from "react";
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import classnames from "classnames";

import "./styles.scss";

const getFriendlySize = (bytes: number) => {
  const measures = ["B", "KB", "MB", "GB"];
  let i = 0;
  let value = bytes;

  while (value >= 1024 && i <= 3) {
    value = value / 1024;
    i += 1;
  }
  return `${Math.round(value)} ${measures[i]}`;
};

type Props = DropzoneOptions & { className?: string; error?: string; openDialog?: boolean };

const UploadDropzone: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  error,
  openDialog,
  ...rest
}) => {
  const [internalError, setInternalError] = useState("");
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    if (fileRejections.length > 0) {
      const firstError = fileRejections[0].errors[0];
      setInternalError(
        firstError.code === "file-too-large" && rest.maxSize
          ? `File is larger than ${getFriendlySize(rest.maxSize)}`
          : firstError.message,
      );
    } else {
      setInternalError("");
      if (rest.onDrop) {
        rest.onDrop(acceptedFiles, fileRejections, event);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ ...rest, onDrop });

  useEffect(() => {
    if (openDialog) {
      open();
    }
  }, [open, openDialog]);

  return (
    <div className={classnames("upload-dropzone", className, { active: isDragActive })}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {children}
      </div>
      {(internalError || error) && <p className="error">{internalError || error}</p>}
    </div>
  );
};

export default UploadDropzone;
