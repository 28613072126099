import React from "react";
import moment from "moment";
import classnames from "classnames";

import blue_calendar from "assets/icons/blue_calendar.svg";

import "./customDatePickerInput.scss";

type Props = {
  value: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  label: React.ReactNode;
  classes?: string;
};

const CustomDatePickerInput: React.FunctionComponent<Props> = ({ value, onClick, label, classes }) => {
  return (
    <div className={`datePicker-wrapper ${classes || ""}`}>
      {label && <span className="pickerLabel">{label}</span>}
      <div className={classnames("datePicker", label && "datePicker-label")} onClick={onClick}>
        <div>{moment(value).format("MM/DD/YYYY")}</div>
        <img src={blue_calendar} alt="calendar icon" />
      </div>
    </div>
  );
};
export default CustomDatePickerInput;
