import React, { useCallback, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { sendMagicLink, setError, setRegistrationData } from "../../store/actions";
import { authError, getOnboardingData } from "../../store/selectors";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../../constants";
import LoginOptions from "../LoginOptions";

import { ILoginForm, IUser } from "containers/Auth";
import { SquareButton } from "shared";

import "../styles/auth.scss";

const { AUTH, EVENTS } = NamesOfParentRoutes;
const { FORGOT } = NameOfRoutes;

type Props = {
  user: IUser;
  onBack: () => void;
};
const Login: React.FC<Props> = ({ user, onBack }) => {
  const { values, handleSubmit, setFieldValue, setFieldError } = useFormikContext<ILoginForm>();

  const loginError = useSelector(authError());
  const onboardingData = useSelector(getOnboardingData());

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setRegistrationData(null));
  }, [dispatch]);

  useEffect(() => {
    if (loginError) {
      setFieldError(
        "password",
        loginError.meta?.isActive === false
          ? "User is inactive. Check your email."
          : "Password you entered is incorrect.",
      );
      dispatch(setError(null));
    }
  }, [dispatch, loginError, setFieldError]);

  useEffect(
    () => () => {
      setFieldValue("password", "");
      setFieldError("password", "");
      setFieldError("email", "");
    },
    [dispatch, setFieldError, setFieldValue],
  );

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (["NumpadEnter", "Enter"].includes(e.code)) {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  useEffect(() => {
    document.addEventListener("keypress", onKeyPress);
    return () => document.removeEventListener("keypress", onKeyPress);
  }, [onKeyPress]);

  const sendMagicLinkHandler = () => {
    let redirectTo: string | undefined;
    if (onboardingData) {
      if (!onboardingData.email || onboardingData.email === values.email) {
        redirectTo = onboardingData.communityCode ? `/${onboardingData.communityCode}${EVENTS}` : void 0;
      }
    }

    dispatch(sendMagicLink.request({ email: user.email, redirectTo }));
  };

  const forgotHandler = () => {
    history.push(`${AUTH}${FORGOT}?back=1`);
  };

  return (
    <Grid container className="auth-root login">
      <Grid container>
        <SquareButton width={72} variant="blue-text" className="back" onClick={onBack}>
          Back
        </SquareButton>
        <div className="auth-description">
          <div className="auth-description-title">Welcome Back, {user.first_name}!</div>
          <div className="auth-description-subtitle no-mobile" />
        </div>
      </Grid>
      <LoginOptions onSendMagicLink={sendMagicLinkHandler} onForgot={forgotHandler} onSubmit={handleSubmit} />
    </Grid>
  );
};

export default Login;
