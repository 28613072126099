import { Reducer } from "redux";
import produce from "immer";

import { CommunityActionsTypes } from "./constants";
import {
  ICommunityItem,
  ICommunity,
  ICommunitySubscriptions,
  ICommunityLandingSettings,
  ICommunityStreamingStatistics,
  ICommunityIntroStep,
  IMAUData,
  IAnalytics,
} from "../interfaces";
import { IUser } from "../../Auth";

import { ResponseError } from "shared/interfaces";

export interface CommunityState {
  allCommunities: ICommunityItem[];
  userCommunities: ICommunityItem[];
  community: ICommunity | null;
  allUsers: IUser[];
  communityInvitationHash: string | null;
  error: ResponseError | null;
  subscriptions: ICommunitySubscriptions | null;
  users: IUser[];
  communitySettingsFormSubmit: null | string;
  allSudomains: string[];
  landingSettings: ICommunityLandingSettings | null;
  communitiesCount: number;
  streamingStatistics: ICommunityStreamingStatistics | null;
  introductionSteps: ICommunityIntroStep[];
  subscriptionAttention: boolean;
  mauData: IMAUData | null;
  mauBannerHidden: boolean;
  analytics: IAnalytics | null;
}
const initialState: CommunityState = {
  allCommunities: [],
  userCommunities: [],
  community: null,
  allUsers: [],
  communityInvitationHash: null,
  error: null,
  subscriptions: null,
  users: [],
  communitySettingsFormSubmit: null,
  allSudomains: [],
  landingSettings: null,
  communitiesCount: 0,
  streamingStatistics: null,
  introductionSteps: [],
  subscriptionAttention: false,
  mauData: null,
  mauBannerHidden: false,
  analytics: null,
};

const reducer: Reducer<CommunityState> = (state: CommunityState = initialState, action) => {
  switch (action.type) {
    case CommunityActionsTypes.GET_COMMUNITIES_LIST_SUCCESS:
      return produce(state, nextState => {
        nextState.allCommunities = action.payload.map((item: ICommunityItem) => {
          const { id, name, main_contact_id, meetings_count, members_count, code, image_url } = item;
          return { id, name, main_contact_id, meetings_count, members_count, code, image_url };
        });
      });
    case CommunityActionsTypes.GET_USER_COMMUNITIES_LIST_SUCCESS:
      return produce(state, nextState => {
        nextState.userCommunities = action.payload;
      });
    case CommunityActionsTypes.GET_COMMUNITY_SUCCESS:
    case CommunityActionsTypes.GET_COMMUNITY_WITH_VIMEO_TOKEN_SUCCESS:
      return produce(state, nextState => {
        nextState.community = action.payload;
        nextState.error = null;
      });
    case CommunityActionsTypes.GET_COMMUNITY_FAILURE:
      return produce(state, nextState => {
        nextState.error = action.payload;
        nextState.community = null;
      });
    case CommunityActionsTypes.UPDATE_COMMUNITY_SUCCESS:
      return produce(state, nextState => {
        nextState.community = action.payload;
        nextState.userCommunities = [...(state.userCommunities || [])].map(community => {
          const { payload } = action;
          return community.id === action.payload.id ? payload : community;
        });
      });
    case CommunityActionsTypes.CREATE_COMMUNITY_SUCCESS:
      return produce(state, nextState => {
        nextState.userCommunities = [...(state.userCommunities || []), { ...action.payload }];
      });
    case CommunityActionsTypes.DELETE_COMMUNITY_SUCCESS:
      return produce(state, nextState => {
        nextState.userCommunities = state.userCommunities.filter(({ id }) => id !== action.payload);
        nextState.community = null;
      });
    case CommunityActionsTypes.GET_ALL_USERS_SUCCESS:
      return produce(state, nextState => {
        nextState.allUsers = action.payload;
      });
    case CommunityActionsTypes.COMMUNITY_INVITATION_HASH_SUCCESS:
      return produce(state, nextState => {
        nextState.communityInvitationHash = action.payload;
      });
    case CommunityActionsTypes.COMMUNITY_INVITATION_HASH_CLEAR:
    case CommunityActionsTypes.COMMUNITY_INVITATION_HASH_FAILURE:
      return produce(state, nextState => {
        nextState.communityInvitationHash = null;
      });
    case CommunityActionsTypes.COMMUNITY_SUBSCRIPTIONS_SUCCESS:
      return produce(state, nextState => {
        nextState.subscriptions = action.payload;
      });
    case CommunityActionsTypes.COMMUNITY_STRIPE_ACCOUNT_LINK_SUCCESS:
      return produce(state, nextState => {
        nextState.community = state.community ? { ...state.community, stripeAccountLink: action.payload } : null;
      });
    case CommunityActionsTypes.COMMUNITY_STRIPE_ACCOUNT_LINK_FAILURE:
      return produce(state, nextState => {
        nextState.community = state.community ? { ...state.community, stripeAccountLink: null } : null;
      });
    case CommunityActionsTypes.COMMUNITY_STRIPE_ACCOUNT_LINK_CLEAR:
      return produce(state, nextState => {
        nextState.community = state.community ? { ...state.community, stripeAccountLink: undefined } : null;
      });
    case CommunityActionsTypes.COMMUNITY_USERS_SUCCESS:
      return produce(state, nextState => {
        nextState.users = action.payload;
      });
    case CommunityActionsTypes.COMMUNITY_USERS_FAILURE:
      return produce(state, nextState => {
        nextState.users = [];
      });
    case CommunityActionsTypes.CLEAR_CURRENT_COMMUNITY:
      return produce(state, nextState => {
        nextState.community = null;
        nextState.streamingStatistics = null;
        nextState.mauData = null;
        nextState.analytics = null;
      });
    case CommunityActionsTypes.CHANGE_COMMUNITY_SETTINGS_FORM:
      return produce(state, nextState => {
        nextState.communitySettingsFormSubmit = action.payload;
      });
    case CommunityActionsTypes.GET_SUBDOMAIN_LIST_SUCCESS:
      return produce(state, nextState => {
        nextState.allSudomains = action.payload;
      });
    case CommunityActionsTypes.GET_COMMUNITY_LANDING_SETTINGS_SUCCESS:
    case CommunityActionsTypes.CREATE_COMMUNITY_LANDING_SETTINGS_SUCCESS:
    case CommunityActionsTypes.UPDATE_COMMUNITY_LANDING_SETTINGS_SUCCESS:
      return produce(state, nextState => {
        nextState.landingSettings = action.payload;
      });
    case CommunityActionsTypes.GET_COMMUNITIES_COUNT_SUCCESS:
      return produce(state, nextState => {
        nextState.communitiesCount = action.payload;
      });
    case CommunityActionsTypes.GET_COMMUNITIES_COUNT_FAILURE:
      return produce(state, nextState => {
        nextState.communitiesCount = 0;
      });
    case CommunityActionsTypes.CLEAR_CURRENT_LANDING_SETTINGS:
      return produce(state, nextState => {
        nextState.landingSettings = null;
      });
    case CommunityActionsTypes.GET_COMMUNITIES_STREAMING_STATISTICS_SUCCESS:
      return produce(state, nextState => {
        nextState.streamingStatistics = action.payload;
      });
    case CommunityActionsTypes.GET_COMMUNITY_INTRODUCTION_STEPS_SUCCESS:
      return produce(state, nextState => {
        nextState.introductionSteps = action.payload || [];
      });
    case CommunityActionsTypes.SET_SUBSCRIPTION_ATTENTION:
      return produce(state, nextState => {
        nextState.subscriptionAttention = action.payload;
      });
    case CommunityActionsTypes.GET_MAU_DATA_SUCCESS:
      return produce(state, nextState => {
        nextState.mauData = action.payload;
      });
    case CommunityActionsTypes.SET_MAU_BANNER_HIDDEN:
      return produce(state, nextState => {
        nextState.mauBannerHidden = action.payload;
      });
    case CommunityActionsTypes.FETCH_ANALYTICS_SUCCESS:
      return produce(state, nextState => {
        nextState.analytics = action.payload;
      });
    default:
      return state;
  }
};
export { reducer as CommunityReducer };
