import { EEventStreamType } from "../../containers/Sermons/interfaces/Sermon";

const WEBSITE = "https://www.altarlive.com";
const SUPPORTARTICLES = "/support-articles";

export const LINKS = {
  website: WEBSITE,
  pricing: `${WEBSITE}/pricing`,
  support: `${WEBSITE}/support`,
  contactUs: `${WEBSITE}/contact-us`,
  producerAndroidTablet: `${WEBSITE}${SUPPORTARTICLES}/download-the-altar-producer-app-for-android`,
  presenterIOS: `${WEBSITE}${SUPPORTARTICLES}/download-the-altar-presenter-app-for-ios`,
  participantIOS: `${WEBSITE}${SUPPORTARTICLES}/download-the-altar-app-for-ios`,
  hospitalityTeam: `${WEBSITE}${SUPPORTARTICLES}/role-of-the-hospitality-team`,
  permissions: `${WEBSITE}${SUPPORTARTICLES}/editing-member-permissions-roles`,
  connectingAltarLiveToWebsite: `${WEBSITE}${SUPPORTARTICLES}/connecting-altar-live-to-your-website`,
  roomAndTables: `${WEBSITE}${SUPPORTARTICLES}/understanding-rows-rooms-and-tables`,
  streamUrlSupport: {
    default: `${WEBSITE}${SUPPORTARTICLES}/how-to-connect-your-live-stream`,
    [EEventStreamType.FB]: `${WEBSITE}${SUPPORTARTICLES}/connecting-facebook`,
    [EEventStreamType.YOUTUBE]: `${WEBSITE}${SUPPORTARTICLES}/connecting-youtube`,
    [EEventStreamType.VIMEO]: `${WEBSITE}${SUPPORTARTICLES}/connecting-vimeo-to-altar-live`,
    // [EEventStreamType.TWITCH]: `${WEBSITE}${SUPPORTARTICLES}/connecting-twitch-to-altar-live`,
    [EEventStreamType.RESI]: `${WEBSITE}${SUPPORTARTICLES}/connecting-resi-to-altar-live`,
    [EEventStreamType.CASTR]: `${WEBSITE}${SUPPORTARTICLES}/connecting-castr-to-altar-live`,
    [EEventStreamType.CUSTOM]: `${WEBSITE}${SUPPORTARTICLES}/connecting-stream-provider`,
    [EEventStreamType.ALTAR]: `${WEBSITE}${SUPPORTARTICLES}/uploading-pre-recorded-videos-to-altar-live`,
    [EEventStreamType.ALTAR_LIVE]: `${WEBSITE}${SUPPORTARTICLES}/connecting-rtmp`,
    [EEventStreamType.VIMEO_LIVE]: `${WEBSITE}${SUPPORTARTICLES}/connecting-rtmp`,
    [EEventStreamType.m3u8]: `${WEBSITE}${SUPPORTARTICLES}/using-hls-streaming`,
    [EEventStreamType.dacast]: `${WEBSITE}${SUPPORTARTICLES}/connecting-dacast-to-altar-live`,
    [EEventStreamType.churchstreaming]: `${WEBSITE}${SUPPORTARTICLES}/connecting-churchstreaming-tv`,
    [EEventStreamType.STREAMING_CHURCH_TV]: `${WEBSITE}${SUPPORTARTICLES}/connecting-streamingchurch-tv-to-altar-live`,
  },
  rtpm: `${WEBSITE}${SUPPORTARTICLES}/rtmp-stream-settings-for-multi-streaming`,
  preEventSlides: `${WEBSITE}${SUPPORTARTICLES}/how-to-create-pre-event-slides`,
  postEventTables: `${WEBSITE}${SUPPORTARTICLES}/how-to-set-up-the-social-lobby`,
  terms: `${WEBSITE}/terms`,
  privacy: `${WEBSITE}/privacy`,
  walkthrough: `${WEBSITE}/calendly/demo`,
  tribe: "https://www.facebook.com/groups/altarlive",
  ga: "https://analytics.google.com",
  templates: `${WEBSITE}${SUPPORTARTICLES}/using-poll-and-announcement-templates`,
  changeMainContact: `${WEBSITE}${SUPPORTARTICLES}/how-to-change-your-communitys-main-contact`,
  interactions: `${WEBSITE}${SUPPORTARTICLES}/how-to-schedule-interactions`,
  create_meeting: `${WEBSITE}${SUPPORTARTICLES}/how-to-create-a-meeting`,
  create_event: `${WEBSITE}${SUPPORTARTICLES}/how-to-create-an-event-or-service`,
};
