import React from "react";
import { TypingIndicatorProps, useChatContext, useTypingContext } from "stream-chat-react";

import "./customTypingIndicator.scss";

const firstName = (fullName: string) => fullName.split(" ")[0];

interface ICustomTypingIndicator extends TypingIndicatorProps {
  isInterlocutorLeftChannel?: string;
}

const CustomTypingIndicator: React.FC<ICustomTypingIndicator> = props => {
  const { client } = useChatContext();
  const { typing } = useTypingContext();
  const { isInterlocutorLeftChannel } = props;

  let text = "";
  const typingMembers = typing
    ? Object.values(typing)
        .filter(({ user }) => user && user.id !== client.user?.id)
        .map(({ user }) => user?.name || `User #${user?.id}`)
    : [];
  if (typingMembers.length === 1) {
    text = `${firstName(typingMembers[0])} is typing...`;
  } else if (typingMembers.length === 2) {
    text = `${firstName(typingMembers[0])} and ${firstName(typingMembers[1])} are typing…`;
  } else if (typingMembers.length > 2) {
    text = `Several people are typing…`;
  }
  return (
    <div className="typingIndicator">
      <p className={isInterlocutorLeftChannel ? "isInterlocutorLeftChannel" : ""}>
        {text || (isInterlocutorLeftChannel ? `${isInterlocutorLeftChannel} left the discussion` : "")}
      </p>
    </div>
  );
};

export default CustomTypingIndicator;
