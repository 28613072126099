import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getCommunity } from "containers/Community/store/selectors";
import { getUser } from "containers/Auth/store/selectors";
import { getEvent } from "containers/Sermons/store/selectors";

const useCurrentUserMember = () => {
  const community = useSelector(getCommunity());
  const currentUser = useSelector(getUser());
  const event = useSelector(getEvent());

  return useMemo(
    () =>
      (!!currentUser &&
        currentUser.members.find(
          ({ community_id }) => community_id === community?.id || community_id === event?.community_id,
        )) ||
      null,
    [currentUser, community, event],
  );
};

export default useCurrentUserMember;
