import { useCallback, useEffect, useState } from "react";

import { IEvent, IMediaDevicesState } from "../../../containers/Sermons";

const useMediaDevicesHook = (event: IEvent) => {
  const { id: eventId, start_with_audio_muted, start_with_video_muted } = event;

  const [mediaDevicesState, setMediaDevicesState] = useState<IMediaDevicesState>({
    audioMuted: null,
    videoMuted: null,
  });

  useEffect(() => {
    const storageMediaDevices = sessionStorage.getItem(`mediaDevicesStatus-${eventId}`);
    if (storageMediaDevices) {
      setMediaDevicesState(JSON.parse(storageMediaDevices));
    } else {
      setMediaDevicesState({ audioMuted: start_with_audio_muted, videoMuted: start_with_video_muted });
    }
  }, [eventId, start_with_audio_muted, start_with_video_muted]);

  useEffect(() => {
    sessionStorage.setItem(`mediaDevicesStatus-${eventId}`, JSON.stringify(mediaDevicesState));
  }, [mediaDevicesState, eventId]);

  const setMediaDevices = useCallback((values: { audioMuted?: boolean; videoMuted?: boolean }) => {
    setMediaDevicesState(prevState => ({ ...prevState, ...values }));
  }, []);

  return { setMediaDevices, ...mediaDevicesState };
};

export default useMediaDevicesHook;
