import React from "react";
import classnames from "classnames";

import { ICommunity, ICommunityLandingSettings } from "containers/Community";
import { StyledLink } from "shared/components/StyledLink";

import "./index.scss";
interface LandingPageFooterProps {
  settings: ICommunityLandingSettings;
  community: ICommunity | null;
}

export default function LandingFooter({ settings, community }: LandingPageFooterProps) {
  const nameLength = community?.name?.length || 0;
  return (
    <div className="landing-footer">
      <div className="information-divider" />
      <div className="landing-footer-wrapper">
        <div className="information-wrapper top">
          <div className="information-community">
            {settings.logo ? (
              <div className="logo-wrapper">
                <img src={settings.logo} alt="community-logo" />
              </div>
            ) : null}
            <div
              className={classnames(
                "information-community-name",
                nameLength > 126 ? "sm" : nameLength > 62 ? "md" : "lg",
              )}
            >
              {community?.name || null}
            </div>
          </div>
          <div className="links-wrapper">
            {settings.links.map(l => (
              <a href={l.value} target="_blank" rel="noopener noreferrer" key={l.id}>
                {l.name}
              </a>
            ))}
          </div>
        </div>
        <div className="information-wrapper bottom">
          {community && <div className="address-wrapper">{community.address_1 || community.address_2 || null}</div>}
          <StyledLink className="poweredBy" href={settings.support_link}>
            Powered by Altar Live with
          </StyledLink>
        </div>
      </div>
    </div>
  );
}
