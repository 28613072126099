export enum FIREBASE_EVENTS {
  LOGIN = "login",
  SIGN_UP = "signup",
  JOIN_EVENT = "join_event",
  JOIN_EVENT_ROW = "join_event_row",
  JOIN_EVENT_TABLE = "join_event_table",
  JOIN_EVENT_ROOM = "join_event_room",
  JOIN_MEETING = "join_meeting",
  CHAT_EVENT = "chat",
  NEW_COMMUNITY = "new_community",
  PURCHASE = "purchase",
  CHECK_IN = "check_in",
  CREATE_EVENT = "create_event",
}

export interface FirebaseGlobalParams {
  user_id?: number;
  name?: string;
  email?: string;
  community_id?: number;
  community_name?: string;
  is_admin?: number;
}

export interface FirebaseCeremonyPayload {
  user_zoom_id: number;
  community_name: string;
  event_name: string;
}

export type FirebaseChatPayload = FirebaseGlobalParams;

export enum EFirebaseSignUpMethod {
  google = "google",
  facebook = "facebook",
  email = "email",
  magicLink = "magic_link",
}

export interface FirebaseLoginPayload extends FirebaseGlobalParams {
  method: EFirebaseSignUpMethod;
}

export interface FirebaseSignUpPayload extends FirebaseGlobalParams {
  method: EFirebaseSignUpMethod;
}

export interface FirebaseNewCommunityPayload extends FirebaseGlobalParams {
  country: string;
  state: string;
  city: string;
}

export interface FirebasePurchasePayload extends FirebaseGlobalParams {
  product: string;
  value: string;
  stripe_status: string;
}

export interface FirebaseJoinEventPayload extends FirebaseGlobalParams {
  event_id: number;
  event_name: string;
  meeting_type?: "personal" | "scheduled";
}

export type FirebaseCheckInEventPayload = FirebaseGlobalParams;
