import React, { useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "lodash";

import { NamesOfParentRoutes } from "../../../../constants";
import SpecificRoomAlert from "../SpecificRoomAlert/SpecificRoomAlert";

import { ICommunityLandingSettings } from "containers/Community";
import { AlertDialog, Button, DropDown, IDropDownItem, StyledAltarLogo } from "shared/components";
import { IMember } from "containers/Member/interfaces";
import {
  actions,
  checkMeetingRole,
  EAuditoriumRoomsType,
  EventDashboardTabs,
  EventStatus,
  EventType,
  getEventTypeName,
  IChangeEventStatusOptions,
  IEvent,
  ProfileBtn,
  SermonRoles,
} from "containers/Sermons";
import { EventPermissions, IUser } from "containers/Auth/interfaces";
import { copyTextHandler } from "shared/utils";
import { notificationActions } from "containers/Notifications/store/actions";
import { useCurrentUserMember, usePermissions, StyledLink } from "shared";
import { LINKS } from "shared/constants/links";
import { EFeature } from "containers/Community/interfaces";
import { useFeatures } from "shared/hooks/FeaturesHook";
import { getAllowEnded, getGoingLiveTime } from "containers/Sermons/store/selectors";
import { isHubspotOpened } from "containers/App/store/selectors";
import Profile from "containers/User/components/Profile";
import { logout } from "containers/Auth/store/actions";
import { EventTooltip } from "shared/components/EventTooltip";
import { closeWidget, hideWidget, openWidget, showWidget } from "utils/hubspot";
import { getFollowMe, getPreJoinPage, getRecordingStatus, selectClassrooms } from "containers/Meeting/store/selectors";
import { ERecordingStatus } from "containers/Meeting/interfaces";
import { setFollowMe, setRecordingStatus } from "containers/Meeting/store/actions";
import help2 from "assets/icons/help2.svg";
import person_icon_white from "assets/icons/person_icon_white.svg";

import "./eventHeader.scss";

interface IEventHeader {
  title?: string;
  onLogin: () => void;
  event: IEvent | null;
  member: IMember | null;
  user: IUser | null;
  hideButtons?: boolean;
  settings?: ICommunityLandingSettings | null;
  eventTypeMeeting?: boolean;
}

const getTitle = (event: IEvent | null, withDate: boolean) => {
  if (!event) return "";
  const { community, starting_at } = event;
  const name = community?.name || "";
  const dayOfWeek = moment(starting_at).format("dddd");
  const month = moment(starting_at).format("MMMM");
  const dayOfMonth = moment(starting_at).format("Do");
  const startDate = withDate ? `${dayOfWeek} ${month} ${dayOfMonth}` : "";
  return (
    <>
      <div className="eventHeader-title-event">{event.name}</div>
      <div className="eventHeader-title-community">{`${name ? name + (withDate ? ", " : "") : ""}${startDate}`}</div>
    </>
  );
};

const { BREAKOUTROOMS, CLASSROOMS, WATCH_PARTY } = NamesOfParentRoutes;

const EventHeader: React.FC<IEventHeader> = ({
  event,
  onLogin,
  member,
  user,
  hideButtons,
  settings,
  eventTypeMeeting,
}) => {
  const dispatch = useDispatch();

  const [moveToLobbyAlertOpened, setMoveToLobbyAlertOpened] = useState(false);
  const [moveToLiveAlertOpened, setMoveToLiveAlertOpened] = useState(false);
  const [endEventAlertOpened, setEndEventAlertOpened] = useState(false);
  const [startEventAlertOpened, setStartEventAlertOpened] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const editPermissions = useMemo(() => [EventPermissions.edit], []);
  const canEdit = usePermissions(editPermissions, event?.community_id);
  const [showProfile, setShowProfile] = useState(false);
  const [specificRoomAlertOpened, setSpecificRoomAlertOpened] = useState(false);
  const [moveTo, setMoveTo] = useState("");

  const allowEnded = useSelector(getAllowEnded());
  const goingLiveTime = useSelector(getGoingLiveTime());
  const hubspotOpened = useSelector(isHubspotOpened());
  const recordingStatus = useSelector(getRecordingStatus());
  const preJoinPage = useSelector(getPreJoinPage());
  const classrooms = useSelector(selectClassrooms());
  const followMe = useSelector(getFollowMe());

  const currentUserMember = useCurrentUserMember();
  const features = useFeatures();

  const toggleHubspot = () => {
    if (hubspotOpened) {
      closeWidget();
      hideWidget();
      return;
    }
    showWidget();
    openWidget();
  };

  const canMoveToAuditoriumRooms = useMemo(() => {
    return (
      event?.status === EventStatus.lobby &&
      (!!event.auditorium_rooms_count || event.auditorium_rooms_type === EAuditoriumRoomsType.none || eventTypeMeeting)
    );
  }, [event?.status, event?.auditorium_rooms_count, event?.auditorium_rooms_type, eventTypeMeeting]);
  const canMoveToLobbyRooms = useMemo(() => !!event?.lobby_rooms_count, [event?.lobby_rooms_count]);
  const showJoinLink = useMemo(() => event?.status !== EventStatus.ended && event?.join_link, [event]);

  const showAdminMenu = useMemo(
    () =>
      event &&
      !(event.type === EventType.meeting && preJoinPage) &&
      event.status !== EventStatus.ended &&
      (canEdit ||
        (event && currentUserMember && checkMeetingRole(event, currentUserMember, [SermonRoles.greeterHost]))),
    [canEdit, currentUserMember, event, preJoinPage],
  );

  const eventTypeName = useMemo(() => getEventTypeName(event?.type), [event]);
  const lobbyClassrooms = useMemo(() => classrooms.filter(c => !c.is_main), [classrooms]);

  const adminMenuItems = useMemo(() => {
    const items: IDropDownItem[] = [];
    if (event) {
      if (
        [EventStatus.scheduled, EventStatus.live, EventStatus.lobby].includes(event.status) &&
        features.includes(EFeature.lobby)
      ) {
        if (canMoveToLobbyRooms) {
          items.push({ value: 1, text: "Move Everyone to Lobby: Table View" });
        }
        if (lobbyClassrooms.length) {
          items.push(
            { value: 2, text: "Move Everyone to Lobby: Room View" },
            { value: 3, text: "Move Everyone to Lobby: Specific Room" },
          );
        }
      }

      if (canMoveToAuditoriumRooms) {
        items.push({
          value: 4,
          text: `Move Everyone to ${event.type === EventType.meeting ? "Meeting Room" : "Auditorium"}`,
        });
      }

      if (event.type === EventType.meeting && [EventStatus.live, EventStatus.started].includes(event?.status)) {
        items.push(
          {
            value: 5,
            text: "Record the Meeting",
            className: `record ${recordingStatus !== ERecordingStatus.not_recording || preJoinPage ? "disabled" : ""}`,
          },
          {
            value: 6,
            text: `${followMe ? "Turn Off" : "Enable"} "Follow Me" Option`,
            className: `followMe ${followMe ? "enabled" : ""}`,
          },
        );
      }

      if (!event.is_default) {
        items.push({
          value: 7,
          text: `End ${capitalize(eventTypeName)} for All`,
          className: "end-event",
        });
      }
    }

    return items;
  }, [
    features,
    event,
    eventTypeName,
    recordingStatus,
    preJoinPage,
    lobbyClassrooms,
    followMe,
    canMoveToAuditoriumRooms,
    canMoveToLobbyRooms,
  ]);

  const showGoLive = useMemo(() => {
    return (
      showAdminMenu &&
      (!!event?.auditorium_rooms_count || event?.auditorium_rooms_type === EAuditoriumRoomsType.none) &&
      !goingLiveTime &&
      !eventTypeMeeting &&
      [EventStatus.scheduled, EventStatus.lobby].includes(event.status)
    );
  }, [
    event?.auditorium_rooms_count,
    event?.auditorium_rooms_type,
    eventTypeMeeting,
    goingLiveTime,
    showAdminMenu,
    event?.status,
  ]);

  const copyLinkHandler = useCallback(() => {
    if (event?.join_link) {
      copyTextHandler(event.join_link);
      dispatch(notificationActions.success("The link is copied to the clipboard"));
    }
  }, [event, dispatch]);

  const closeAlerts = useCallback(() => {
    setMoveToLobbyAlertOpened(false);
    setMoveToLiveAlertOpened(false);
    setEndEventAlertOpened(false);
    setStartEventAlertOpened(false);
    setOpenLogout(false);
    setSpecificRoomAlertOpened(false);
  }, []);

  const logoutHandler = () => dispatch(logout.request({ isSeatSamePage: true, reload: true }));

  const handleEditProfile = useCallback(() => {
    dispatch(actions.updateEventSettings({ activeDashboardTab: EventDashboardTabs.editProfile }));
  }, [dispatch]);

  const adminMenuClickHandler = useCallback(
    (menuId: number | string) => {
      switch (menuId) {
        case 1:
          setMoveToLobbyAlertOpened(true);
          setMoveTo(BREAKOUTROOMS);
          break;
        case 2:
          setMoveToLobbyAlertOpened(true);
          setMoveTo(CLASSROOMS);
          break;
        case 3:
          setSpecificRoomAlertOpened(true);
          break;
        case 4:
          setMoveToLiveAlertOpened(true);
          setMoveTo(WATCH_PARTY);
          break;
        case 5:
          if (recordingStatus === ERecordingStatus.not_recording) {
            dispatch(setRecordingStatus(ERecordingStatus.hasStarted));
          }
          break;
        case 6:
          dispatch(setFollowMe(!followMe));
          break;
        case 7:
          setEndEventAlertOpened(true);
          break;
        default:
          return;
      }
    },
    [dispatch, recordingStatus, followMe],
  );

  const changeStatus = useCallback(
    (status: EventStatus, changeStatusOptions?: IChangeEventStatusOptions) => {
      if (event?.id) {
        if (status === EventStatus.ended) {
          dispatch(actions.setAllowEnded(true));
        }
        dispatch(
          actions.updateSermon.request({
            data: {
              status,
              id: event.id,
              start_event_option: null,
            },
            changeStatusOptions,
          }),
        );
      }
      closeAlerts();
      setMoveTo("");
    },
    [closeAlerts, dispatch, event],
  );

  const handleClickLogo = useCallback(() => dispatch(actions.setIsLeavingEvent(true)), [dispatch]);

  return (
    <header className={classnames("eventHeader")}>
      <AlertDialog
        open={openLogout}
        title="Logout"
        message="Are you sure you want to log out of this account ?"
        onConfirm={logoutHandler}
        onCancel={closeAlerts}
        mode="confirm"
        confirmText="log out"
        cancelText="cancel"
        variant="brown"
      />
      <AlertDialog
        open={startEventAlertOpened}
        title="Go Live"
        message={`The ${eventTypeName} will start for everyone. Are you sure?`}
        onConfirm={() => changeStatus(EventStatus.live)}
        onCancel={closeAlerts}
        mode="confirm"
        confirmText="Go Live"
        cancelText="cancel"
        variant="brown"
      />
      <AlertDialog
        open={moveToLobbyAlertOpened}
        title={`Move Everyone to the ${moveTo === CLASSROOMS ? "Room" : "Table"} View in the Lobby`}
        message={`Are you sure you want to move everyone into the ${
          moveTo === CLASSROOMS ? "Room" : "Table"
        } View in the Lobby?`}
        onConfirm={() => changeStatus(EventStatus.lobby, { moveTo, roomCode: "" })}
        onCancel={closeAlerts}
        mode="confirm"
        confirmText="yes"
        cancelText="cancel"
        variant="brown"
        dialogClassName="no-transform-header"
      />
      <AlertDialog
        open={moveToLiveAlertOpened}
        title="Move Everyone to Auditorium"
        message="Are you sure you want to move everyone into the Auditorium?"
        onConfirm={() =>
          changeStatus(goingLiveTime || event?.type === EventType.meeting ? EventStatus.live : EventStatus.scheduled, {
            moveTo,
            roomCode: "",
          })
        }
        onCancel={closeAlerts}
        mode="confirm"
        confirmText="yes"
        cancelText="cancel"
        variant="brown"
        dialogClassName="no-transform-header"
      />
      <AlertDialog
        open={endEventAlertOpened}
        title={`End ${eventTypeName} and Table Session for All`}
        message={`Are you sure you want to end ${eventTypeName} and table session for all?`}
        onConfirm={() => changeStatus(EventStatus.ended)}
        onCancel={closeAlerts}
        mode="confirm"
        confirmText="yes"
        cancelText="cancel"
        variant="brown"
      />
      {lobbyClassrooms.length && specificRoomAlertOpened ? (
        <SpecificRoomAlert
          openAlert={specificRoomAlertOpened}
          closeAlert={closeAlerts}
          onConfirm={roomCode => changeStatus(EventStatus.lobby, { moveTo: CLASSROOMS, roomCode })}
          classrooms={lobbyClassrooms}
        />
      ) : null}
      <div className="eventHeader-left">
        <div className="eventHeader-logo">
          {settings && settings.logo ? (
            <img src={settings.logo} alt="" height={32} onClick={handleClickLogo} />
          ) : (
            <StyledAltarLogo fill="#C9C0C6" width={112} height={32} onClick={handleClickLogo} />
          )}
        </div>
        <div className="eventHeader-title">{getTitle(event, !event?.is_default)}</div>
      </div>
      <div className="eventHeader-right">
        <nav className="eventHeader-nav-items">
          {!hideButtons && (
            <>
              {showJoinLink && (
                <div className="eventHeader-nav-item copy-url-btn">
                  <Button onClick={copyLinkHandler} variant="orange-outline" width={200}>
                    <span>Share Invitation Link</span>
                  </Button>
                </div>
              )}
              {showGoLive && (
                <div className="eventHeader-nav-item golive-btn">
                  <EventTooltip
                    title="Start the video feed"
                    placement="bottom-end"
                    classNames="eventHeader-tooltip golive"
                  >
                    <Button onClick={() => setStartEventAlertOpened(true)} variant="carrot" width={120}>
                      <span>Go Live</span>
                    </Button>
                  </EventTooltip>
                </div>
              )}
              {showAdminMenu && (
                <div className="eventHeader-nav-item admin-menu">
                  <DropDown
                    size="full"
                    items={adminMenuItems}
                    spacer={false}
                    placeholder="Host Options"
                    onChange={adminMenuClickHandler}
                    isMenu
                    variant="brown"
                  />
                </div>
              )}
            </>
          )}
          {event && (event.status !== EventStatus.ended || allowEnded) && (
            <>
              <div className="eventHeader-nav-item help-btn">
                <EventTooltip title="Help" placement="bottom-end" classNames="eventHeader-tooltip">
                  {!showAdminMenu ? (
                    <>
                      <StyledLink href={LINKS.support}>
                        <img src={help2} alt="help icon" />
                        Help
                      </StyledLink>
                    </>
                  ) : (
                    <Button onClick={toggleHubspot} width={58} variant="black-text">
                      <img src={help2} alt="help icon" />
                      Help
                    </Button>
                  )}
                </EventTooltip>
              </div>

              <div className="eventHeader-nav-item ">
                {member || user ? (
                  <>
                    <ProfileBtn user={user} member={member} onClick={() => setShowProfile(true)} />
                    <Profile
                      show={showProfile}
                      onClose={() => setShowProfile(false)}
                      onLogout={() => setOpenLogout(true)}
                      theme="dark"
                      className="stream-user-profile"
                      onEdit={handleEditProfile}
                    />
                  </>
                ) : (
                  <Button
                    width={144}
                    variant="blue"
                    id="login-button"
                    radius={40}
                    className="login-btn"
                    onClick={onLogin}
                  >
                    <img src={person_icon_white} alt="person icon" />
                    <div>Log in</div>
                  </Button>
                )}
              </div>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};
export default EventHeader;
