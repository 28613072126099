import React, { useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import classnames from "classnames";

import StreamEventPlayer from "../../../StreamEventPlayer";
import { getRandomInt, getS3Url } from "../../../../utils";

import { EAuditoriumRoomsType, EEventPreview, EEventStreamType, IEventForm } from "containers/Sermons/interfaces";
import { Button, StyledAltarLogo } from "shared";
import { EFileStatus, EFileType } from "shared/interfaces/File";
import event_chat_mockup from "assets/images/event_chat_mockup.svg";
import table_mockup_fill_0_seats from "assets/images/table_mockup_fill_0_seats.png";
import table_mockup_fill_2_seats from "assets/images/table_mockup_fill_2_seats.png";
import table_mockup_fill_4_seats from "assets/images/table_mockup_fill_4_seats.png";
import rowSeat_mockup_dark from "assets/icons/rowSeat_mockup_dark.svg";
import rowSeat_mockup_light from "assets/icons/rowSeat_mockup_light.svg";
import auditorium_mockup_with_seat from "assets/icons/auditorium_mockup_with_seat.svg";
import auditorium_mockup_without_seat from "assets/icons/auditorium_mockup_without_seat.svg";

import "./styles.scss";

const rowsArr = [rowSeat_mockup_dark, rowSeat_mockup_light];
const tablesArr = [table_mockup_fill_0_seats, table_mockup_fill_2_seats, table_mockup_fill_4_seats];
const auditoriumArr = [auditorium_mockup_with_seat, auditorium_mockup_without_seat];

type Props = {
  onClose: () => void;
  previewType: EEventPreview;
  itemCount: number;
};
const StreamPreview: React.FC<Props> = ({ onClose, previewType, itemCount }) => {
  const [videoContainer, setVideoContainer] = useState<HTMLDivElement | null>(null);
  const [preEventSlide, setPreEventSlide] = useState("");
  const {
    values: {
      stream_type,
      stream_url,
      pre_images,
      pre_images_interval,
      pre_event_video_url,
      files,
      is_pre_event_video_loop,
      is_pre_event_video_autoplay,
      auditorium_rooms_type,
    },
  } = useFormikContext<IEventForm>();

  const rowsView = useMemo(() => {
    return new Array(itemCount).fill("").map((_, rowIndex) => {
      return (
        <div
          className={classnames("row-item", { lastItem: itemCount % 2 && rowIndex === itemCount - 1 })}
          key={rowIndex}
        >
          {new Array(4).fill("").map((_, seatIndex) => (
            <img src={rowsArr[getRandomInt(2)]} alt="row-seat" key={seatIndex} />
          ))}
        </div>
      );
    });
  }, [itemCount]);

  const tablesView = useMemo(() => {
    return new Array(itemCount).fill("").map((_, index) => {
      return <img src={tablesArr[getRandomInt(3)]} alt="table" key={index} />;
    });
  }, [itemCount]);

  const classroomsView = useMemo(() => {
    return new Array(itemCount).fill("").map((_, index) => {
      return <img src={auditoriumArr[getRandomInt(2)]} alt="auditorium" key={index} />;
    });
  }, [itemCount]);

  const streamSize = useMemo(() => {
    return {
      width: auditorium_rooms_type === EAuditoriumRoomsType.none ? 1000 : 704,
      height: auditorium_rooms_type === EAuditoriumRoomsType.none ? 568 : 400,
    };
  }, [auditorium_rooms_type]);

  const auditoriumRoomsView = useMemo(() => {
    return auditorium_rooms_type === EAuditoriumRoomsType.row ? (
      <div className="stream-preview-main-auditoriumView-rows">{rowsView}</div>
    ) : auditorium_rooms_type === EAuditoriumRoomsType.table ? (
      <div className="stream-preview-main-auditoriumView-tables">{tablesView}</div>
    ) : null;
  }, [rowsView, tablesView, auditorium_rooms_type]);

  const streamBackImg = useMemo(() => {
    return <div className="stream-preview-main-auditoriumView-back-img" style={streamSize} />;
  }, [streamSize]);

  const preEventVideo = useMemo(() => {
    if (previewType === EEventPreview.preEventVideo) {
      const preEventVideoData = files?.filter(
        file => file.type === EFileType.preEventVideo && file.status === EFileStatus.ready,
      );
      return preEventVideoData?.length ? getS3Url(preEventVideoData[preEventVideoData.length - 1].origin_path) : null;
    }
    return null;
  }, [files, previewType]);

  useEffect(() => {
    let interval = 0;
    if (previewType === EEventPreview.preEventSlides && pre_images.length > 0 && pre_images_interval) {
      let i = 0;
      setPreEventSlide(pre_images[i] || "");

      if (pre_images.length > 1) {
        interval = setInterval(() => {
          i = (i + 1) % pre_images.length;
          setPreEventSlide(pre_images[i] || "");
        }, pre_images_interval * 1000);
      }
    }
    return () => clearInterval(interval);
  }, [pre_images, pre_images_interval, previewType]);

  return (
    <div className="stream-preview">
      <div className="stream-preview-header">
        <StyledAltarLogo fill="#c9c0c6" width={112} height={32} />
        <div className="stream-preview-header-el1">
          <div className="stream-preview-header-el1-top" />
          <div className="stream-preview-header-el1-bottom" />
        </div>
        <div className="stream-preview-header-el2" />
        <div className="stream-preview-header-el2" />
        <div className="stream-preview-header-el2" />
        <Button variant="blue" className="select-seat" type="button" width={180} onClick={onClose}>
          Quit Preview
          <span className="close" />
        </Button>
      </div>
      <div className="stream-preview-main">
        {previewType === EEventPreview.auditorium_rooms ? (
          <div className="stream-preview-main-auditoriumView" ref={setVideoContainer}>
            {stream_type && [EEventStreamType.YOUTUBE].includes(stream_type) ? (
              <StreamEventPlayer
                streamType={stream_type}
                streamUrl={stream_url}
                videoContainer={videoContainer}
                controls
                {...streamSize}
              />
            ) : (
              streamBackImg
            )}
            {auditoriumRoomsView}
          </div>
        ) : previewType === EEventPreview.lobby_rooms ? (
          <div className="stream-preview-main-lobbyView">
            <div className="stream-preview-main-lobbyView-tables">{tablesView}</div>
          </div>
        ) : previewType === EEventPreview.classrooms ? (
          <div className="stream-preview-main-lobbyView">
            <div className="stream-preview-main-lobbyView-classrooms">{classroomsView}</div>
          </div>
        ) : previewType === EEventPreview.preEventSlides ? (
          <div className="stream-preview-main-auditoriumView">
            {pre_images.length ? (
              <div className="stream-preview-main-auditoriumView-slides" style={streamSize}>
                <img src={preEventSlide} />
              </div>
            ) : (
              streamBackImg
            )}
            {auditoriumRoomsView}
          </div>
        ) : previewType === EEventPreview.preEventVideo ? (
          <div className="stream-preview-main-auditoriumView" ref={setVideoContainer}>
            {pre_event_video_url || preEventVideo ? (
              <StreamEventPlayer
                streamType={null}
                streamUrl={preEventVideo || pre_event_video_url || ""}
                videoContainer={videoContainer}
                loop={is_pre_event_video_loop}
                playing={is_pre_event_video_autoplay}
                controls
                {...streamSize}
              />
            ) : (
              streamBackImg
            )}
            {auditoriumRoomsView}
          </div>
        ) : null}
        <div className="stream-preview-main-membersCounter">
          <div className="stream-preview-main-membersCounter-el1" />
        </div>
      </div>
      <div className="stream-preview-chat">
        <div className="stream-preview-chat-header">
          <div className="stream-preview-chat-header-el1" />
          <div className="stream-preview-chat-header-el2" />
        </div>
        <div className="stream-preview-chat-main">
          <img src={event_chat_mockup} alt="chat" />
        </div>
        <div className="stream-preview-chat-footer">
          <div className="stream-preview-chat-footer-el1" />
          <div className="stream-preview-chat-footer-el2" />
          <div className="stream-preview-chat-footer-el3" />
        </div>
      </div>
      <div className="stream-preview-menu">
        <div className="stream-preview-menu-el1" />
        <div className="stream-preview-menu-el1" />
        <div className="stream-preview-menu-el1" />
        <div className="stream-preview-menu-el1" />
        <div className="stream-preview-menu-el1" />
        <div className="stream-preview-menu-el1" />
        <div className="stream-preview-menu-el2" />
        <div className="stream-preview-menu-el1" />
      </div>
    </div>
  );
};

export default StreamPreview;
