import { call, put, takeLatest, throttle } from "redux-saga/effects";

import { api } from "../api";
import * as actions from "./actions";

import { ClassRoomSettings } from "containers/Sermons";

export function* generateEightToEightToken(
  action: ReturnType<typeof actions.generateEightToEightToken.request>,
): Generator {
  try {
    const token = yield call(api.generateToken, action.payload);

    yield put(actions.generateEightToEightToken.success(token as { token: string }));
  } catch (e: any) {
    yield put(actions.generateEightToEightToken.failure(e));
  }
}

export function* loadClassRooms(action: ReturnType<typeof actions.loadClassRooms.request>): Generator {
  try {
    const classrooms = yield call(api.loadClassrooms, action.payload);

    yield put(actions.loadClassRooms.success(classrooms as { classrooms: ClassRoomSettings[] }));
    if ((classrooms as { classrooms: ClassRoomSettings[] }).classrooms.length === 1) {
      yield put(actions.setCurrentClassroom((classrooms as { classrooms: ClassRoomSettings[] }).classrooms[0]));
    }
  } catch (e: any) {
    yield put(actions.loadClassRooms.failure(e));
  }
}

function* setEventClassroomsSaga({ payload }: ReturnType<typeof actions.setEventClassrooms.request>) {
  try {
    yield put(actions.setEventClassrooms.success(payload));
  } catch (e: any) {
    yield put(actions.setEventClassrooms.failure(e));
  }
}

function* meetingSaga() {
  yield takeLatest(actions.generateEightToEightToken.request, generateEightToEightToken);
  yield takeLatest(actions.loadClassRooms.request, loadClassRooms);
  yield throttle(3000, actions.setEventClassrooms.request, setEventClassroomsSaga);
}

export default meetingSaga;
