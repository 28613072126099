import React, { useEffect, useMemo, useState } from "react";
import classnames from "classnames";

import {
  EventSideDialogDiscussionHeader,
  EventSideDialogDiscussionHeaderProps,
  EventSideDialogHeader,
  EventSideDialogHeaderProps,
  EventSideDialogHeaderTypes,
  EventSideDialogInteractionTabs,
  EventSideDialogPollTabsProps,
} from "./EventSideDialogHeader";
import EventSideDialogDiscussionTabs, {
  EventSideDialogDiscussionTabsProps,
} from "./EventSideDialogHeader/EventSideDialogDiscussionTabs";
import EventSideDialogPollHeader from "./EventSideDialogHeader/EventSideDialogPollHeader";

import "./eventSideDialog.scss";

interface IEventSideDialog {
  open: boolean;
  className?: string;
  headerType?: EventSideDialogHeaderTypes;
}

type EventSideDialogProps = IEventSideDialog &
  EventSideDialogDiscussionHeaderProps &
  EventSideDialogHeaderProps &
  EventSideDialogDiscussionTabsProps &
  EventSideDialogPollTabsProps;

const EventSideDialog: React.FC<EventSideDialogProps> = props => {
  const {
    onClose,
    onChangeTab,
    selectedTab,
    title,
    children,
    open,
    className,
    onBack,
    image,
    imageColor,
    showImage = false,
    headerType,
    interactionTab,
    setInteractionTab,
  } = props;

  const [isScrolledContent, setIsScrolledContent] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop: number = (e.target as any)?.scrollTop || 0;
    if (!!scrollTop && headerType !== EventSideDialogHeaderTypes.members) {
      setIsScrolledContent(true);
    } else {
      setIsScrolledContent(false);
    }
  };

  useEffect(() => {
    setIsScrolledContent(false);
  }, [selectedTab, headerType]);

  const header = useMemo(() => {
    switch (headerType) {
      case EventSideDialogHeaderTypes.discussions:
        return (
          <EventSideDialogDiscussionHeader
            title={title}
            onBack={onBack}
            image={image}
            imageColor={imageColor}
            showImage={showImage}
          />
        );
      case EventSideDialogHeaderTypes.discussion_tabs:
        return <EventSideDialogDiscussionTabs onClose={onClose} onChangeTab={onChangeTab} selectedTab={selectedTab} />;
      case EventSideDialogHeaderTypes.poll_creation:
        return <EventSideDialogPollHeader title={title} onBack={onBack} image={image} />;
      case EventSideDialogHeaderTypes.interaction_tabs:
        return (
          <EventSideDialogInteractionTabs
            onClose={onClose}
            setInteractionTab={setInteractionTab}
            interactionTab={interactionTab}
          />
        );
      case EventSideDialogHeaderTypes.default:
      default:
        return (
          <EventSideDialogHeader
            onClose={onClose}
            onBack={onBack}
            title={title}
            image={image}
            imageColor={imageColor}
            showImage={showImage}
          />
        );
    }
  }, [
    headerType,
    image,
    imageColor,
    interactionTab,
    onBack,
    onChangeTab,
    onClose,
    selectedTab,
    setInteractionTab,
    showImage,
    title,
  ]);

  return (
    <div
      className={classnames("eventSideDialog", className, {
        open,
      })}
    >
      <div className="eventSideDialog-container">
        {header}
        <div className={classnames("eventSideDialog-linear-gradient", { visible: isScrolledContent })} />
        <div className="eventSideDialog-body" onScroll={handleScroll}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default EventSideDialog;
