import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";

import { validateSchema } from "./formValidators";
import { EventAnnouncementMainForm } from "../EventAnnouncementMainForm";
import { prepareAnnouncementForm } from "../../../../utils";

import {
  AnnouncementStatus,
  IAnnouncement,
  IAnnouncementDialogValues,
  IAnnouncementTemplate,
} from "containers/Sermons/interfaces";
import { Button } from "shared";

import "./index.scss";

interface IEventAnnouncementFormProps {
  announcement: IAnnouncement | IAnnouncementTemplate | null;
  onSubmit: (values: IAnnouncementDialogValues) => void;
  onChange?: () => void;
}

const EventAnnouncementForm: React.FC<IEventAnnouncementFormProps> = props => {
  const { onSubmit, onChange, announcement } = props;
  const [initValue, setInitValue] = useState<IAnnouncementDialogValues>(prepareAnnouncementForm());

  const formOnChange = useCallback(() => onChange && onChange(), [onChange]);

  useEffect(() => {
    setInitValue(prepareAnnouncementForm(announcement));
  }, [announcement]);

  const handleSubmit = useCallback(
    (values: IAnnouncementDialogValues) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <Formik
      initialValues={initValue}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validateSchema}
    >
      {({ handleSubmit, setFieldValue }) => {
        return (
          <div className="announcement-form-wrapper">
            <EventAnnouncementMainForm formOnChange={formOnChange} />
            <div className="announcement-form-buttons">
              <Button
                variant="orange"
                onClick={() => {
                  setFieldValue("status", AnnouncementStatus.published);
                  handleSubmit();
                }}
              >
                Publish
              </Button>
              <Button
                variant="white-text"
                onClick={() => {
                  setFieldValue(
                    "status",
                    announcement?.status === AnnouncementStatus.scheduled
                      ? AnnouncementStatus.scheduled
                      : AnnouncementStatus.draft,
                  );
                  handleSubmit();
                }}
              >
                Save as {announcement?.status === AnnouncementStatus.scheduled ? "scheduled" : "draft"}
              </Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default EventAnnouncementForm;
