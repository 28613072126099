import React from "react";
import { useSelector } from "react-redux";
import { Form, useFormikContext } from "formik";

import { RegisterShape } from "../../interfaces";

import { AuthAside, AuthMain, CustomFormikField, ImageUpload, SquareButton, StyledCircle } from "shared";
import { getOnboardingData } from "containers/Auth/store/selectors";
import member from "assets/images/member.png";
import empty_person_2 from "assets/icons/empty_person_2.svg";

import "./styles.scss";

type Props = {
  onBack: () => void;
};

const Registration: React.FC<Props> = ({ onBack }) => {
  const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = useFormikContext<RegisterShape>();
  const onboardingData = useSelector(getOnboardingData());
  return (
    <div className="registration">
      <AuthAside header>
        <div className="registration-aside">
          <StyledCircle className="registration-aside-figure" fill="#F0B48F" />
          <div className="registration-aside-description">
            <h1 className="registration-aside-header">Welcome to Altar Live!</h1>
            <p>
              First, create a profile. Then you will be able to search <br />
              for join your community.
            </p>
          </div>
          <div className="registration-aside-img">
            <img src={member} alt="member" />
          </div>
        </div>
      </AuthAside>
      <AuthMain>
        <div className="registration-main">
          <div className="registration-main-header">
            <SquareButton width={72} variant="blue-text" className="back" onClick={onBack}>
              Back
            </SquareButton>
            <h3>Set up your profile</h3>
          </div>
          <div className="registration-divider" />
          <Form className="registration-main-container">
            <div className="registration-main-row avatar">
              <ImageUpload
                showOptions
                maxMb={2}
                value={values.image_url}
                onChange={handleChange("image_url")}
                backgroundImage={`url(${empty_person_2})`}
                variant="circle"
              />
            </div>
            <div className="registration-main-row">
              <CustomFormikField
                fullWidth
                label="First Name"
                name="first_name"
                required
                errors={touched.first_name && errors}
                value={values.first_name}
                onChange={handleChange}
              />
              <CustomFormikField
                fullWidth
                label="Last Name"
                name="last_name"
                required
                errors={touched.last_name && errors}
                value={values.last_name}
                onChange={handleChange}
              />
            </div>
            <div className="registration-main-row">
              <CustomFormikField
                fullWidth
                required
                label="Email"
                name="email"
                errors={touched.email && errors}
                value={values.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue("email", e.target.value.trim())}
                disabled={onboardingData?.email && onboardingData?.email === values.email}
              />
            </div>
            <div className="registration-main-row">
              <CustomFormikField
                fullWidth
                label="Phone"
                name="phone"
                errors={touched.phone && errors}
                value={values.phone}
                onChange={handleChange}
              />
            </div>
            <div className="registration-main-row">
              <CustomFormikField
                fullWidth
                label="Password"
                name="password"
                required
                errors={touched.password && errors}
                value={values.password}
                onChange={handleChange}
                type="password"
                autoComplete="new-password"
              />
            </div>
            <div className="registration-main-row">
              <CustomFormikField
                fullWidth
                label="Confirm Password"
                placeholder=""
                name="password_confirm"
                required
                errors={touched.password_confirm && errors}
                value={values.password_confirm}
                onChange={handleChange}
                type="password"
              />
            </div>
            <div className="registration-main-row">
              <SquareButton radius={9} variant="blue" type="button" onClick={() => handleSubmit()}>
                Sign Up
              </SquareButton>
            </div>
          </Form>
        </div>
      </AuthMain>
    </div>
  );
};

export default Registration;
