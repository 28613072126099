import React from "react";

import { Button } from "shared";
import early_adopters from "assets/images/early_adopters.png";

import "./index.scss";

const EarlyAdopters: React.FC<{ showCurrentPlans: (actionType: string) => void }> = ({ showCurrentPlans }) => {
  return (
    <div className="early-adopters">
      <div className="early-adopters_title">Thank you for being one of our early adopters!</div>
      <div className="early-adopters_description">
        The plan you are on is considered a legacy plan. While we no longer offer this plan on our pricing pages, this
        plan is still available for your community account.
        <br />
        <br />
        This plan and your current MAU limit will remain available for you until you decide to change plans. If you’ve
        run out of MAU please switch to one of our current plans.
      </div>
      <div className="early-adopters_img">
        <img src={early_adopters} alt="Early Adopters" />
      </div>
      <Button variant="orange" onClick={() => showCurrentPlans("plans")}>
        See Current Plans
      </Button>
    </div>
  );
};

export default EarlyAdopters;
