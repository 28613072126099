import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import classnames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import StreamEventPlayer from "../../../StreamEventPlayer";
import StreamPreview from "../StreamPreview";
import AltarVideoManager from "../AltarVideoManager";
import { getEvent } from "../../../../store/selectors";
import StreamRelay from "../StreamRelay";
import StreamStatistics from "../StreamingStatistics";
import StreamVimeoRelay from "../StreamVimeoRelay";

import { EEventPreview, EEventStreamType, EEventVideoType, IEventForm } from "containers/Sermons/interfaces";
import { AlertDialog, SquareButton, SquareTextField, StyledLink } from "shared";
import { LINKS } from "shared/constants/links";
import { getUploadProgress } from "shared/stores/aws/selectors";
import { uploadToS3 } from "shared/stores/aws/actions";
import { EFileType } from "shared/interfaces/File";
import { setFile } from "shared/stores/file/actions";
import { getCommunityStreamingStatistics } from "containers/Community/store/selectors";
import cross_icon_dark_grey from "assets/icons/cross_icon_dark_grey.svg";
import trash from "assets/icons/trash.svg";
import event_preview from "assets/images/event_preview.png";

import "./styles.scss";

type Props = {
  onChange: () => void;
  saveDraft: () => void;
};

const handleKeyDown = (e: KeyboardEvent) => {
  if (e.key === "Enter") {
    e.preventDefault();
    e.stopPropagation();
  }
};

const StreamProviderDetails: React.FC<Props> = ({ onChange, saveDraft }) => {
  const [fullScreenPreview, setFullScreenPreview] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const {
    values: {
      stream_type,
      stream_url,
      video_type,
      auditorium_rooms: { auditoriumRoomsCount },
    },
    errors,
    handleChange,
    handleBlur,
    validateForm,
    setFieldError,
    setFieldValue,
  } = useFormikContext<IEventForm>();
  const [videoContainer, setVideoContainer] = useState<HTMLDivElement | null>(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uploadProgress = useSelector(getUploadProgress());
  const currentEvent = useSelector(getEvent());
  const streamingStatistics = useSelector(getCommunityStreamingStatistics());

  const fileName = currentEvent?.files?.find(({ type }) => type === EFileType.eventVideo)?.name || "";

  const isUploading = uploadProgress !== null;

  const streamProviderTypeClass = useMemo(
    () =>
      stream_type === EEventStreamType.YOUTUBE && video_type === EEventVideoType.live
        ? `${stream_type}_live`
        : stream_type,
    [stream_type, video_type],
  );

  const content = useMemo(() => {
    if (stream_type === EEventStreamType.VIMEO_LIVE) {
      return <StreamVimeoRelay />;
    }
    if (stream_type === EEventStreamType.ALTAR_LIVE) {
      return <StreamRelay />;
    }
    return (
      <>
        {stream_type === EEventStreamType.ALTAR ? (
          <AltarVideoManager saveDraft={saveDraft} />
        ) : (
          <>
            <p className="label">{stream_type === EEventStreamType.CUSTOM ? "Custom Embed Code" : "Stream URL"}</p>
            <SquareTextField
              className="field"
              fullWidth
              placeholder={`Type ${stream_type === EEventStreamType.CUSTOM ? "Embed code" : "Stream URL"} here`}
              name="stream_url"
              errors={errors}
              value={stream_url}
              onChange={async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                handleChange(e);
                setImmediate(() => validateForm());
              }}
              onBlur={handleBlur}
              required
              multiline={stream_type === EEventStreamType.CUSTOM}
              onKeyUp={stream_type === EEventStreamType.CUSTOM ? handleKeyDown : void 0}
              onKeyDown={stream_type !== EEventStreamType.CUSTOM ? handleKeyDown : void 0}
              rows={4}
            />
          </>
        )}
        <p className="label">Preview</p>
        <div className="preview">
          <div className="preview-inline" ref={setVideoContainer}>
            {stream_type === EEventStreamType.ALTAR && isUploading ? (
              <div className="uploading-progress">
                Uploading{uploadProgress === 100 && " Completed!"}
                <div className="total">
                  <div
                    className={classnames("loaded", { completed: uploadProgress === 100 })}
                    style={{ width: `${uploadProgress}%` }}
                  />
                </div>
                <span className="percent">{uploadProgress}%</span>
              </div>
            ) : stream_url && !errors.stream_url ? (
              <div className={classnames({ "altar-video": stream_type === EEventStreamType.ALTAR })}>
                <StreamEventPlayer
                  streamType={stream_type}
                  streamUrl={stream_url}
                  videoContainer={videoContainer}
                  muted
                  playing
                />
              </div>
            ) : (
              <>
                <span className="play-icon-border">
                  <span className="play-icon" />
                </span>
                <span>{stream_type === EEventStreamType.ALTAR ? "The video" : "Your link"} preview</span>
                <span>will be displayed here</span>
              </>
            )}
            {stream_type === EEventStreamType.ALTAR && (stream_url || isUploading) && (
              <div className="file-actions">
                <div className="file-name">{fileName}</div>
                <img
                  className={classnames("delete-file", { uploading: isUploading })}
                  src={isUploading ? cross_icon_dark_grey : trash}
                  alt="delete file"
                  onClick={() => setShowDeleteAlert(true)}
                />
              </div>
            )}
          </div>
          <div className="preview-fullscreen">
            <img src={event_preview} alt="preview" />
            <SquareButton
              type="button"
              variant={isUploading ? "disabled" : "blue"}
              disabled={isUploading}
              onClick={() => {
                if (stream_url && !errors.stream_url && !isUploading) {
                  setFullScreenPreview(true);
                }
              }}
            >
              Full Preview
            </SquareButton>
          </div>
        </div>
      </>
    );
  }, [
    errors,
    fileName,
    handleBlur,
    handleChange,
    saveDraft,
    stream_type,
    stream_url,
    uploadProgress,
    validateForm,
    videoContainer,
    isUploading,
  ]);

  useEffect(() => {
    if (errors.stream_url) {
      setFieldError("stream_url", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldError]);

  useEffect(() => {
    return () => {
      dispatch(setFile(null));
    };
  }, [dispatch]);

  const deleteVideo = useCallback(() => {
    setFieldValue("stream_url", "");
    setShowDeleteAlert(false);
    dispatch(uploadToS3.cancel());
  }, [dispatch, setFieldValue]);

  return (
    <div className="stream-provider-details">
      <AlertDialog
        mode="confirm"
        open={showDeleteAlert}
        confirmText="Delete"
        title={`Delete ${uploadProgress === null ? "Video" : "Upload"}`}
        message={
          uploadProgress === null
            ? "Are you sure that you want to delete this video from Altar Video Player?"
            : "Are you sure that you want to delete the upload of this video from Altar Video Player?"
        }
        onCancel={() => setShowDeleteAlert(false)}
        onConfirm={deleteVideo}
        dialogClassName="delete-video-alert"
        confirmClassName="delete-button"
      />
      {fullScreenPreview ? (
        <StreamPreview
          onClose={() => setFullScreenPreview(false)}
          previewType={EEventPreview.auditorium_rooms}
          itemCount={auditoriumRoomsCount}
        />
      ) : (
        <>
          <div className="stream-provider-details-header">
            <div className={classnames("provider-logo", streamProviderTypeClass)}>
              {stream_type === EEventStreamType.CUSTOM && <span>Custom Embed Code</span>}
            </div>
            <SquareButton type="button" variant="blue-text" width={75} className="change-provider" onClick={onChange}>
              Change
            </SquareButton>
          </div>
          <div className="stream-provider-details-content">
            {stream_type === EEventStreamType.ALTAR_LIVE && <StreamStatistics onSwitchProvider={onChange} />}
            {(stream_type !== EEventStreamType.ALTAR_LIVE ||
              (streamingStatistics &&
                streamingStatistics.usedSeconds < streamingStatistics.allowedHours * 60 * 60)) && (
              <>
                <p className="description">
                  <Trans
                    i18nKey={`event.edit.streamDescription.${
                      stream_type === EEventStreamType.YOUTUBE ? `${stream_type}.${video_type}` : stream_type
                    }`}
                    components={{ br: <br />, i: <i />, b: <b />, ul: <ul />, li: <li /> }}
                  />
                </p>
                <StyledLink className="learn-more" href={LINKS.streamUrlSupport[stream_type || "default"]}>
                  {t(`event.edit.streamLearnMore.${stream_type}`)}
                </StyledLink>
                {content}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StreamProviderDetails;
