import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ILoginForm } from "../../interfaces";
import { authError } from "../../store/selectors";
import { LINKS } from "../../../../shared/constants/links";

import { StyledTextField, SquareButton, StyledLink } from "shared";

import "../styles/auth.scss";

type Props = {
  onBack?: () => void;
};

const Forgot: React.FunctionComponent<Props> = ({ onBack }) => {
  const { t } = useTranslation();

  const { values, errors, setFieldValue, handleBlur, handleSubmit, setFieldError } = useFormikContext<ILoginForm>();

  const loginError = useSelector(authError());

  useEffect(() => {
    setFieldError("email", loginError?.message || "");
  }, [loginError, setFieldError]);

  useEffect(() => {
    setFieldError("password", loginError ? "Password you entered is incorrect." : "");
  }, [loginError, setFieldError]);

  return (
    <Grid container className="auth-root forgot">
      <Grid item>
        {onBack && (
          <SquareButton width={72} variant="blue-text" className="back" onClick={onBack}>
            Back
          </SquareButton>
        )}
        <div className="auth-description">
          <div className="auth-description-title">{t("Forgot your password?")}</div>
          <div className="auth-description-subtitle no-mobile" />
        </div>
      </Grid>

      <form id="loginForm" onSubmit={handleSubmit}>
        <p className="block-description">Please, enter your email address</p>
        <Grid container justify="center">
          <Field name="email">
            {() => {
              return (
                <StyledTextField
                  fullWidth
                  name="email"
                  errors={errors}
                  value={values.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue("email", e.target.value.trim())}
                  onBlur={handleBlur}
                />
              );
            }}
          </Field>
        </Grid>
        <Grid container justify="flex-end">
          <StyledLink className="auth-no-access" href={LINKS.support}>
            I don’t have access to this email
          </StyledLink>
        </Grid>
        <Grid container justify="center">
          <Grid item className="auth-submitBtn">
            <SquareButton type="submit" variant={!values.email ? "disabled" : "orange"} disabled={!values.email}>
              {t("Continue")}
            </SquareButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default Forgot;
