import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import classnames from "classnames";

import SubscriptionBanner from "../SubscriptionBanner/SubscriptionBanner";
import { getCommunity, getCommunitySubscriptions, getMAUData } from "../../../store/selectors";
import { communityStripeAccountLink, goToStripeAccount } from "../../../store/actions";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../../../constants";
import { EStripeStatusCode, IProduct } from "../../../interfaces";
import { EarlyAdopters } from "../EarlyAdopters";

import { IRouteProps, SquareButton } from "shared";
import subscription_dates from "assets/icons/subscription-dates.svg";
import information_mau from "assets/icons/information-mau.svg";

import "./index.scss";

const SubscriptionDetails = () => {
  const communitySubscriptions = useSelector(getCommunitySubscriptions());
  const [currentProduct, setCurrentProduct] = useState<IProduct | null>(null);
  const dispatch = useDispatch();
  const { communityCode } = useParams<IRouteProps>();
  const history = useHistory();
  const { pathname } = useLocation();
  const community = useSelector(getCommunity());
  const mauData = useSelector(getMAUData());

  const popupRef = useRef<HTMLDivElement>(null);

  const hoverHandler = () => {
    if (popupRef.current) {
      popupRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  const additionalLinkHandler = (actionType: string) => {
    if (actionType === "contact-us") {
      const win = window.open("https://www.altarlive.com/contact-us", "_blank");
      if (win) {
        win.focus();
      }
      return;
    } else {
      const pricingPath = communityCode
        ? `/${communityCode}${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.PRICING_SETTINGS}`
        : `/${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.PRICING_SETTINGS}`;
      history.replace(pricingPath);
    }
  };

  const usedMAU = useMemo(() => {
    if (currentProduct && communitySubscriptions && mauData) {
      if (mauData.active < currentProduct.licensed_member_count) {
        return (mauData.active / currentProduct.licensed_member_count) * 100;
      } else {
        return 100;
      }
    }
    return 0;
  }, [communitySubscriptions, currentProduct, mauData]);

  useEffect(() => {
    if (communitySubscriptions?.currentSubscriptions[0]) {
      setCurrentProduct(communitySubscriptions?.currentSubscriptions[0]);
    } else {
      if (communitySubscriptions?.canceledSubscription) {
        setCurrentProduct(communitySubscriptions?.canceledSubscription);
      }
    }
  }, [currentProduct, communitySubscriptions]);

  useEffect(() => {
    if (community && community.stripeAccountLink === undefined) {
      dispatch(communityStripeAccountLink.request({ communityCode: community.code, returnPath: pathname }));
    }
  }, [community, dispatch, pathname]);

  const goToStripeAccountHandler = useCallback(() => {
    if (community) {
      dispatch(goToStripeAccount({ communityCode: community.code, returnPath: pathname }));
    }
  }, [community, dispatch, pathname]);

  const navigateToPayment = () => {
    history.replace(
      communityCode
        ? `/${communityCode}${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.PRICING_SETTINGS}`
        : `/${NamesOfParentRoutes.COMMUNITY_SETTINGS}${NameOfRoutes.PRICING_SETTINGS}`,
    );
  };

  const upgradeHandler = () => {
    if (community?.stripeAccountLink && currentProduct?.stripe_status_code === EStripeStatusCode.paused) {
      goToStripeAccountHandler();
    } else {
      navigateToPayment();
    }
  };

  const overage = mauData && currentProduct && mauData.active > currentProduct.licensed_member_count;

  return (
    <div className="subscription-details-wrapper">
      {currentProduct ? (
        <>
          <div className="subscription-detail-information">
            <div className="header-information-wrapper">
              <div className="plan-title-wrapper">
                <div className="title-information">
                  <div className="plan-title">{currentProduct?.title}</div>
                  {currentProduct.stripe_status_code !== EStripeStatusCode.active ? (
                    <div className={`badge ${currentProduct.stripe_status_code}`}>
                      {currentProduct.stripe_status_code === EStripeStatusCode.trialing
                        ? "FREE TRIAL"
                        : currentProduct.stripe_status_code.replace("_", " ")}
                    </div>
                  ) : null}
                </div>
                <div className="subscription-dates">
                  <img src={subscription_dates} alt="subscription-dates" />
                  <span>
                    {new Date(currentProduct?.last_payment_date || "").toLocaleDateString()} –
                    {new Date(currentProduct?.next_payment_date || "").toLocaleDateString()}
                  </span>
                </div>
              </div>
              <div className="plan-actions-wrapper">
                <button className="blue-button" onClick={upgradeHandler} disabled={!currentProduct.is_active}>
                  {community?.stripeAccountLink && currentProduct?.stripe_status_code === EStripeStatusCode.paused
                    ? "Update Billing Info"
                    : communitySubscriptions?.hasPaymentMethod ||
                      [EStripeStatusCode.canceled, EStripeStatusCode.past_due, EStripeStatusCode.incomplete].includes(
                        currentProduct.stripe_status_code,
                      )
                    ? "Upgrade Plan"
                    : "I'm Ready to Purchase"}
                </button>
                {currentProduct?.stripe_status_code !== EStripeStatusCode.paused && (
                  <div
                    className="subscription-wrapper-actions"
                    onClick={e => e.stopPropagation()}
                    onMouseOver={() => hoverHandler()}
                  >
                    <div className="three-dots-button">
                      <div className="dot" />
                      <div className="dot" />
                      <div className="dot" />
                    </div>
                    <div className="stripe-buttons-wrapper" ref={popupRef}>
                      {community?.stripeAccountLink ? (
                        <>
                          <div className="move-to-stripe" onClick={goToStripeAccountHandler}>
                            Update Billing Information
                          </div>
                          {currentProduct.stripe_status_code === "active" ? (
                            <div className="move-to-stripe cancel" onClick={goToStripeAccountHandler}>
                              Cancel Subscription
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="header-border" />
            <div className="subscription-content-wrapper">
              <div className="content-description">
                Give your entire team access to your account and grow your online engagement with full-featured meetings
                & events. Only pay for what you use.
              </div>
              <div
                className={classnames("used-time-progress-bar", {
                  warning: overage,
                })}
              >
                <div className="total">
                  <div className={classnames("used", { reached: false })} style={{ width: `${usedMAU}%` }}>
                    {<div className="tooltip">{mauData?.active || 0}</div>}
                  </div>
                  <div className="tip">
                    <div className="tip-minimum-value">0 MAU</div>
                    <div className="tip-maximum-value">{currentProduct.licensed_member_count} MAU</div>
                  </div>
                </div>
              </div>
              {[EStripeStatusCode.past_due, EStripeStatusCode.incomplete, EStripeStatusCode.paused].includes(
                currentProduct.stripe_status_code,
              ) && (
                <p className="warning-message">
                  Your payment method failed or had insufficient funds. Please update your payment method to prevent
                  your account from being deactivated.
                </p>
              )}
              <div className="current-subscription-information">
                <div
                  className={classnames("mau-information-wrapper", {
                    warning: overage,
                  })}
                >
                  <div className="block-title">This month you used</div>
                  <div className="block-value">
                    {mauData?.active || 0} out of {currentProduct.licensed_member_count} monthly active users
                  </div>
                </div>
                <div className={`reset-date-wrapper ${currentProduct.stripe_status_code}`}>
                  {![EStripeStatusCode.canceled, EStripeStatusCode.past_due, EStripeStatusCode.incomplete].includes(
                    currentProduct.stripe_status_code,
                  ) ? (
                    <>
                      <div className="block-title">Monthly active user count will reset</div>
                      <div className="block-value">
                        on{" "}
                        {new Date(currentProduct?.next_payment_date || "").toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="block-title">Your subscription has been inactive since </div>
                      <div className="block-value">
                        {new Date(currentProduct?.last_payment_date || "").toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!currentProduct.is_active ? <EarlyAdopters showCurrentPlans={additionalLinkHandler} /> : null}
          <div className="mau-information-tooltip-wrapper">
            <div className="information-title-wrapper">
              <img src={information_mau} alt="information-mau" />
              <span>What are Monthly Active Users (MAU)?</span>
            </div>
            <div className="information-description-wrapper">
              A Monthly Active User (MAU) is defined as a unique, logged in user who attended at least one meeting or
              event, with at least one other user, in the same month. Anonymous visitors and attendees are not counted
              as monthly active users.
            </div>
          </div>
        </>
      ) : (
        <div className="no-subscription">
          <div className="no-subscription-inner">
            <p>No subscription yet</p>
            <SquareButton variant="blue" onClick={() => navigateToPayment()} width={208}>
              Ready To Purchase
            </SquareButton>
          </div>
        </div>
      )}
      <SubscriptionBanner />
    </div>
  );
};

export default SubscriptionDetails;
