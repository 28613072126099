import { withStyles } from "@material-ui/core";

export const GlobalCss = withStyles({
  "@global": {
    ".MuiFormHelperText-root.Mui-error": {
      marginTop: "12px",
      textAlign: "right",
    },
    ".MuiInputLabel-asterisk": {
      color: "red",
    },
    ".MuiTypography-h1": {
      fontSize: "2.7rem",
    },
    ".MuiTabs-indicator": {
      backgroundColor: "rgba(66, 136, 32, 0)",
    },
    ".MuiTab-wrapper": {
      color: "#3D3D3C",
      fontSize: "16px",
      fontFace: "Arial",
      textTransform: "none",
      paddingLeft: "17px",
      opacity: 1,
      alignItems: "normal",
    },
    ".MuiTab-root": {
      textAlign: "left",
    },
    ".MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "rgba(66, 136, 32, 0.27)",
    },
    ".MuiTypography-colorPrimary": {
      color: "#428820",
    },
    ".MuiOutlinedInput-notchedOutline": {},
    ".MuiFormLabel-root": {
      fontFamily: "Heebo",
    },
    ".MuiFormLabel-root.Mui-focused.MuiInputLabel-outlined": {
      borderColor: "#428820",
    },
    ".MuiInputBase-input": {
      fontSize: "18px !important",
      fontFamily: "Heebo !important",
    },
    'input[type="search"]::-webkit-search-cancel-button': {
      "-webkit-appearance": "none",
      color: "#ffffff",
      width: "16px",
      height: "16px",
      background: "url(/assets/icons/x.svg)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      cursor: "pointer",
    },
    body: {
      backgroundColor: "#f8f4f3",
    },
  },
})(() => null);
