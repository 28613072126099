import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import { useSelector } from "react-redux";

import { StyledRadio } from "../../StyledRadio";
import { AlertDialog } from "../../AlertDialog";

import { ClassRoomSettings } from "containers/Sermons";
import { getEventMembers } from "containers/Sermons/store/selectors";
import { EventMemberTypes } from "containers/Sermons/constants";

import "./specificRoomAlert.scss";

interface Props {
  openAlert: boolean;
  closeAlert: () => void;
  onConfirm: (roomCode: string) => void;
  classrooms: ClassRoomSettings[];
}

const SpecificRoomAlert: React.FC<Props> = ({ openAlert, closeAlert, onConfirm, classrooms }) => {
  const eventMembers = useSelector(getEventMembers());

  const [selectedRoomCode, setSelectedRoomCode] = useState(classrooms[0].code);
  const [roomCapacityExceeded, setRoomCapacityExceeded] = useState(false);

  const selectedClassroom = useMemo(
    () => classrooms.find(r => r.code === selectedRoomCode),
    [classrooms, selectedRoomCode],
  );

  useEffect(() => {
    if (eventMembers && selectedClassroom) {
      const eventMembersCount = eventMembers.filter(m => m.type === EventMemberTypes.member).length;
      setRoomCapacityExceeded(selectedClassroom.room_size < eventMembersCount);
    }
  }, [eventMembers, selectedClassroom]);

  const confirmHandler = useCallback(() => {
    if (!roomCapacityExceeded && selectedRoomCode) {
      onConfirm(selectedRoomCode);
    }
  }, [selectedRoomCode, onConfirm, roomCapacityExceeded]);

  return (
    <>
      <AlertDialog
        open={openAlert}
        title="Move Everyone to Specific Room"
        message="Are you sure you want to move everyone into"
        onConfirm={confirmHandler}
        onCancel={() => closeAlert()}
        mode="confirm"
        confirmText="Apply"
        cancelText="Cancel"
        variant="brown"
        dialogClassName="specificRoomAlert"
        confirmClassName={`${roomCapacityExceeded ? "disabled" : ""}`}
      >
        <div className="specificRoomAlert-content">
          <div className="specificRoomAlert-content-roomList">
            {roomCapacityExceeded && (
              <div className="specificRoomAlert-content-error">
                This option is currently unavailable. <br />
                The number of attendees exceeds the capacity of the Rooms.
              </div>
            )}
            <RadioGroup value={selectedRoomCode} onChange={e => setSelectedRoomCode(e.target.value)}>
              {classrooms.map((c, index) => {
                return (
                  <FormControlLabel
                    disabled={roomCapacityExceeded}
                    key={c.id}
                    value={roomCapacityExceeded || c.code}
                    control={<StyledRadio />}
                    label={
                      <div className="specificRoomAlert-content-roomList-label">
                        <div className="specificRoomAlert-content-roomList-label-name">{`Room ${index + 1}: ${
                          c.name
                        }`}</div>
                        <div className="specificRoomAlert-content-roomList-label-membersCount">{`(${c.members?.length}/${c.room_size})`}</div>
                      </div>
                    }
                  />
                );
              })}
            </RadioGroup>
          </div>
          <div className="specificRoomAlert-content-description">
            Anonymous visitors will see a prompt inviting them to sign in to be able to join into the room.
          </div>
        </div>
      </AlertDialog>
    </>
  );
};
export default SpecificRoomAlert;
