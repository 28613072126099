import styled from "styled-components";

import { Button } from "../Button";

export default styled(Button)`
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  line-height: 20px;
  letter-spacing: 0.03em;
`;
