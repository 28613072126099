import React from "react";
import { FieldAttributes } from "formik";

import { StyledDatePicker } from "../StyledDatePicker";

import "./styles.scss";

const SquareDatePicker: React.FC<FieldAttributes<any>> = props => {
  return <StyledDatePicker {...props} classes={`${props.classes || ""} square-date-picker`} />;
};

export default SquareDatePicker;
