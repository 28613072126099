import { MediaDevicesList } from "../../containers/BreakoutRooms/interfaces";
import { ISelectedDevices } from "../../containers/Meeting/interfaces";
import { IDropDownItem } from "../components";

export const prepareDropDownMediaItems = (deviceList: MediaDevicesList<MediaDeviceInfo> | null) => {
  const items: MediaDevicesList<IDropDownItem> = { audioinput: [], videoinput: [], audiooutput: [] };
  if (deviceList) {
    (Object.keys(items) as MediaDeviceKind[]).forEach(i => {
      items[i] = deviceList[i]?.map(({ label }: MediaDeviceInfo) => ({ text: label, value: label })) || [];
    });
  }
  return items;
};

export const getStreamDevicesValue = (stream: MediaStream | null) => {
  const setItems = (t: MediaStreamTrack) => ({ deviceId: t.getSettings().deviceId, label: t.label });
  const audio = stream?.getAudioTracks()?.map(setItems)[0];
  const video = stream?.getVideoTracks()?.map(setItems)[0];
  if (audio?.deviceId && audio.label && video?.deviceId && video.label) {
    storageMediaDevicesInfo.set({ audio, video });
  }
  return { audio, video };
};

export const storageMediaDevicesInfo = {
  get: (): ISelectedDevices | undefined => {
    try {
      const storageMedia = localStorage.getItem("preJoin");
      return storageMedia ? JSON.parse(storageMedia) : undefined;
    } catch (e) {
      console.info("Can't get media devices", e);
    }
  },

  set: ({ audio, video }: ISelectedDevices) => {
    try {
      if (audio && video) {
        localStorage.setItem("preJoin", JSON.stringify({ audio, video }));
      }
    } catch (e) {
      console.info("Can't set media devices", e);
    }
  },
};

export const changeMediaConstraints = (
  deviceLabels: Array<string | undefined>,
  userDevices: MediaDevicesList<MediaDeviceInfo> | null,
  constraints: MediaStreamConstraints,
) => {
  if (userDevices) {
    const mediaDeviceKinds = ["audioinput", "videoinput"] as MediaDeviceKind[];
    mediaDeviceKinds.forEach(kind => {
      userDevices[kind]?.forEach(({ deviceId, label }) => {
        const shortKindName = kind.replace("input", "") as keyof MediaStreamConstraints;
        if (
          deviceLabels.includes(label) &&
          constraints[shortKindName] &&
          typeof constraints[shortKindName] !== "boolean"
        ) {
          // @ts-ignore
          constraints[shortKindName].deviceId = deviceId;
        }
      });
    });
  }
  return { ...constraints };
};
