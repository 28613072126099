import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Field, useFormikContext } from "formik";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import StreamProviderDetails from "../StreamProviderDetails";
import StreamProviderCard from "../StreamProviderCard";
import Divider from "../Divider";

import { AlertDialog, StyledLink, StyledRadio } from "shared";
import { EEventStreamType, EEventVideoType, IEventForm } from "containers/Sermons/interfaces";
import { LINKS } from "shared/constants/links";
import { uploadToS3 } from "shared/stores/aws/actions";
import { getUploadProgress } from "shared/stores/aws/selectors";
import { getEvent } from "containers/Sermons/store/selectors";
import useSubscriptions from "shared/hooks/SubscriptionsHook";
import { getCommunity } from "containers/Community/store/selectors";

import "./styles.scss";

type Props = {
  saveDraft: () => void;
};

const StreamStep: React.FC<Props> = ({ saveDraft }) => {
  const {
    values: {
      stream_type,
      video_type,
      stream_url,
      auditorium_rooms: { auditoriumRoomsCount, auditoriumRoomsEnabled },
    },
    errors,
    handleChange,
    setFieldValue,
    validateForm,
  } = useFormikContext<IEventForm>();

  let timer: number;

  const [showProviders, setShowProviders] = useState(!stream_url && stream_type !== EEventStreamType.ALTAR_LIVE);
  const [notVerifiedOpened, setNotVerifiedOpened] = useState(false);

  const dispatch = useDispatch();

  const uploadProgress = useSelector(getUploadProgress());
  const event = useSelector(getEvent());
  const currentCommunity = useSelector(getCommunity());

  const { hasPaidSubscription } = useSubscriptions();

  useEffect(() => {
    if (event?.code && uploadProgress === 100) {
      setTimeout(() => dispatch(uploadToS3.cancel()), 1500);
    }
  }, [dispatch, event, uploadProgress]);

  const handleChangeVideoType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      "stream_url",
      stream_type === EEventStreamType.ALTAR && event?.stream_type === EEventStreamType.ALTAR ? event.stream_url : "",
      false,
    );
    setFieldValue("video_type", e.target.value, false);
  };

  const providers = useMemo(
    () => [
      {
        value: EEventStreamType.ALTAR,
        show: EEventVideoType.pre_recorded === video_type,
      },
      {
        value: EEventStreamType.ALTAR_LIVE,
        show: EEventVideoType.live === video_type,
      },
      {
        value: EEventStreamType.VIMEO_LIVE,
        show: EEventVideoType.live === video_type,
      },
      {
        value: EEventStreamType.FB,
        show: EEventVideoType.live === video_type,
      },
      {
        value: EEventStreamType.YOUTUBE,
        show: true,
      },
      {
        value: EEventStreamType.VIMEO,
        show: EEventVideoType.live === video_type,
      },
      {
        value: EEventStreamType.RESI,
        show: true,
      },
      {
        value: EEventStreamType.CASTR,
        show: EEventVideoType.live === video_type,
      },
      // {
      //   value: EEventStreamType.CUSTOM,
      //   show: EEventVideoType.live === video_type,
      // },
      {
        value: EEventStreamType.m3u8,
        show: true,
      },
      {
        value: EEventStreamType.dacast,
        show: true,
      },
      {
        value: EEventStreamType.churchstreaming,
        show: true,
      },
      {
        value: EEventStreamType.STREAMING_CHURCH_TV,
        show: true,
      },
    ],
    [video_type],
  );

  const providersCards = useMemo(() => {
    return providers.map(({ value, show }) => {
      return show ? (
        <FormControlLabel
          key={value}
          value={value}
          control={
            <StreamProviderCard
              streamType={value}
              hasPaidSubscription={hasPaidSubscription}
              included={
                hasPaidSubscription &&
                (value !== EEventStreamType.ALTAR_LIVE ||
                  Boolean(currentCommunity?.stream_settings || currentCommunity?.is_verified))
              }
              videoType={video_type}
            />
          }
          label=""
          checked={value === stream_type}
        />
      ) : null;
    });
  }, [currentCommunity, hasPaidSubscription, providers, stream_type, video_type]);

  const goToProviders = useCallback(() => {
    setShowProviders(true);
    validateForm();
  }, [validateForm]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>, newVal: string) => {
    if (
      newVal === EEventStreamType.ALTAR_LIVE &&
      !currentCommunity?.stream_settings &&
      !currentCommunity?.is_verified
    ) {
      clearTimeout(timer);
      setNotVerifiedOpened(true);
    } else {
      setFieldValue(
        "stream_url",
        newVal === EEventStreamType.ALTAR && event?.stream_type === EEventStreamType.ALTAR ? event.stream_url : "",
      );
      if (!auditoriumRoomsEnabled) {
        setFieldValue("auditorium_rooms", {
          auditoriumRoomsEnabled: true,
          auditoriumRoomsCount,
        });
      }
      handleChange(e);
    }
  };

  return (
    <div className="event-stream-step">
      <AlertDialog
        open={notVerifiedOpened}
        title="Contact Us"
        message="To enable RTMP streaming, please contact us to verify your account"
        mode="confirm"
        confirmText="Contact Altar"
        onConfirm={() => {
          setNotVerifiedOpened(false);
          window.open(LINKS.contactUs, "_blank");
        }}
        onCancel={() => setNotVerifiedOpened(false)}
      />
      <div className="event-stream-step-header">
        <p className={classnames({ error: Object.keys(errors).length > 0 && showProviders })}>
          {showProviders
            ? "Select a stream platform below and follow the instructions to connect your video feed."
            : "Fill in the details below."}
        </p>
      </div>
      {showProviders && (
        <StyledLink className="learn-more" href={LINKS.streamUrlSupport.default}>
          Learn more about connecting your Stream feed
        </StyledLink>
      )}
      <Divider />
      <div className="event-stream-step-content">
        {showProviders ? (
          <>
            <RadioGroup name="video_type" value={video_type} onChange={handleChangeVideoType} className="video-options">
              <FormControlLabel
                value={EEventVideoType.live}
                control={<StyledRadio />}
                label="Live Video"
                className="option"
              />
              <FormControlLabel
                value={EEventVideoType.pre_recorded}
                control={<StyledRadio />}
                label="Pre-recorded Video"
                className="option"
              />
            </RadioGroup>
            <Field name="stream_type">
              {() => {
                return (
                  <RadioGroup
                    name="stream_type"
                    value={stream_type}
                    onClick={() => {
                      clearTimeout(timer);
                      timer = setTimeout(() => {
                        setShowProviders(false);
                      }, 0);
                    }}
                    onChange={onChangeHandler}
                    className="stream-type-radio"
                  >
                    {providersCards}
                  </RadioGroup>
                );
              }}
            </Field>
          </>
        ) : (
          <StreamProviderDetails onChange={goToProviders} saveDraft={saveDraft} />
        )}
      </div>
    </div>
  );
};

export default StreamStep;
