import { AnyAction } from "redux";
import { all, call, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";

import history from "../../../shared/history/history";
import { USER_ROLES } from "../../../shared/constants";
import {
  IAnalytics,
  ICommunity,
  ICommunityIntroStep,
  ICommunityItem,
  ICommunityLandingSettings,
  ICommunitySubscriptionRequest,
  ICommunitySubscriptions,
  ICreateCommunity,
  IMAUData,
  IUpdateCommunity,
  IUpgradeCommunitySubscription,
} from "../interfaces";
import { ERoles, IUser, IUserRole } from "../../Auth/interfaces";
import { NamesOfParentRoutes } from "../../../constants";
import { updateAnalyticsCommunity } from "./actions";
import { getCommunity } from "./selectors";
import { notificationActions } from "../../Notifications/store/actions";
import api from "../api";
import { startLoading, stopLoading } from "../../App/store/actions";
import { handleFirebaseEvent } from "../../../utils/firebase";
import { checkRoles } from "../../../utils/ACL";
import { actions as memberActions } from "../../Member/store/index";
import { CommunityState } from "./reducer";

import { actions, constants } from "./";

import { ECookieNames, getCookieByName } from "shared";
import { FIREBASE_EVENTS, FirebaseCheckInEventPayload } from "shared/interfaces/Firebase";
import { getUser } from "containers/Auth/store/selectors";
import { clearOnboardingData, getUserData } from "containers/Auth/store/actions";
import GA from "utils/ga";

const { ADMIN } = USER_ROLES;

const { EVENTS } = NamesOfParentRoutes;

function* getCommunitiesSaga({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const communities: ICommunityItem[] = yield call(api.getCommunities, {
      search: payload,
      limit: 10000,
      order: "name",
      direction: "asc",
    });
    if (communities) {
      yield put(actions.getCommunities.success(communities));
    } else {
      yield put(notificationActions.error("Cannot fetch communities"));
    }
  } catch (error: any) {
    yield put(notificationActions.error("Cannot fetch communities", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* getUserCommunitiesSaga({ payload: { currentUser, search } }: AnyAction) {
  try {
    yield put(startLoading());
    let communities: ICommunityItem[] = yield call(api.getCommunities, {
      search,
      limit: 10000,
      order: "name",
      direction: "asc",
    });
    if (!communities) {
      yield put(notificationActions.error("Cannot fetch communities"));
    }
    if (!currentUser) {
      yield put(notificationActions.error("Cannot find user"));
    }
    const roles = currentUser.user_roles as IUserRole[];
    const isAdmin = roles.some(r => r.role.name === ADMIN.name);
    const userCommunityIds = roles.map(role => role.community_id);
    communities = isAdmin ? communities : communities.filter(community => userCommunityIds.includes(community.id));
    yield put(actions.getUserCommunities.success(communities));
  } catch (error: any) {
    yield put(notificationActions.error("Cannot fetch communities", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* createCommunitySaga({ payload }: { payload: ICreateCommunity }) {
  try {
    yield put(startLoading());
    const hubSpotUserToken = getCookieByName(ECookieNames.hubspotutk);
    const newCommunity: ICommunity = yield call(api.createCommunity, { ...payload, hubSpotUserToken });
    const { hash } = yield call(api.getInvitationHash, newCommunity.code);
    if (newCommunity && hash) {
      yield put(actions.createCommunity.success(newCommunity));
      yield put(actions.getCommunities.request(""));
      yield put(notificationActions.success("Community was successfully created"));
      yield put(actions.getCommunity.success({ ...newCommunity, hash }));
      history.push(`/${newCommunity.id}/people`);
    }
  } catch (error: any) {
    yield put(notificationActions.error("Cannot create community", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* getCommunitySaga({ payload }: { payload: string }) {
  try {
    yield put(startLoading());
    const community: ICommunity = yield call(api.getCommunity, payload);
    const { hash } = yield call(api.getInvitationHash, payload);
    if (community && hash) {
      yield put(actions.getCommunity.success({ ...community, hash }));
      GA.reInit(community.ga_id);
    } else {
      yield put(notificationActions.error("Cannot fetch community"));
    }
    yield put(updateAnalyticsCommunity());
  } catch (error: any) {
    yield put(actions.getCommunity.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* getCommunityWithVimeoToken({ payload }: ReturnType<typeof actions.getCommunityWithVimeoToken.request>) {
  try {
    yield put(startLoading());
    const community: ICommunity = yield call(api.getCommunityVimeoToken, payload.code);

    if (community.vimeo_token) {
      payload.callback("vimeo_token", community.vimeo_token.split("").reverse().join(""));
      if (payload.type === "update_event") {
        yield put(actions.getCommunity.success({ ...community }));
        GA.reInit(community.ga_id);
      }
    }
  } catch (error: any) {
    yield put(actions.getCommunityWithVimeoToken.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* updateCommunitySaga({ payload }: { payload: IUpdateCommunity }) {
  try {
    yield put(startLoading());
    const community: ICommunityItem = yield call(api.updateCommunity, payload.communityId, payload.community);
    const { hash } = yield call(api.getInvitationHash, payload.communityCode);
    if (community && hash) {
      yield put(actions.updateCommunity.success({ ...community, hash }));
      GA.reInit(community.ga_id);
      if (!payload.silent) {
        yield put(notificationActions.success("Community was successfully updated"));
      }
    } else {
      yield put(notificationActions.error("Cannot update community"));
    }
    yield put(updateAnalyticsCommunity());
  } catch (error: any) {
    yield put(notificationActions.error("Cannot update community", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* deleteCommunitySaga({ payload }: ReturnType<typeof actions.deleteCommunity.request>) {
  try {
    yield put(startLoading());
    const { community_id, callback } = payload;
    yield call(api.deleteCommunity, community_id);
    yield put(actions.deleteCommunity.success(community_id));
    yield call(callback);
  } catch (error: any) {
    yield put(notificationActions.error("Cannot delete community", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* getCommunitySubscriptionsSaga({
  payload: { code, hideLoading, callBack },
}: {
  payload: ICommunitySubscriptionRequest;
}) {
  try {
    if (!hideLoading) {
      yield put(startLoading());
    }
    const subscriptions: ICommunitySubscriptions = yield call(api.getCommunitySubscriptions, code);
    if (subscriptions) {
      yield put(actions.getCommunitySubscriptions.success(subscriptions));
      callBack && callBack(subscriptions);
    } else {
      yield put(notificationActions.error("Cannot fetch community subscriptions"));
    }
  } catch (error: any) {
    yield put(actions.getCommunitySubscriptions.failure(error));
    history.push(`/${code}${EVENTS}`);
  } finally {
    if (!hideLoading) {
      yield put(stopLoading());
    }
  }
}

function* communityUpgradeSubscriptionSaga({
  payload: { data, cb },
}: {
  payload: { data: IUpgradeCommunitySubscription; cb: () => void };
}) {
  try {
    yield put(startLoading());
    const communitySubscription: ICommunitySubscriptions = yield call(
      api.upgradeCommunitySubscription,
      data.communityCode,
      data,
    );
    yield put(actions.communityUpgradeSubscription.success(communitySubscription));
    cb();
  } catch (error: any) {
    yield put(notificationActions.error("Cannot upgrade subscription", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* getAllUsersSaga() {
  try {
    yield put(startLoading());
    const data: IUser[] = yield call(api.getAllUsers);
    yield put(actions.getAllUsers.success(data));
  } catch (error: any) {
    yield put(notificationActions.error("Cannot fetch users", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* joinToNewCommunitySaga({ payload }: AnyAction) {
  try {
    yield put(startLoading());
    const hubSpotUserToken = getCookieByName(ECookieNames.hubspotutk);
    const { community_code } = yield call(api.joinToNewCommunity, payload, { hubSpotUserToken });
    yield put(getUserData.request());
    history.push(`/${community_code}${EVENTS}`);
    yield put(clearOnboardingData());
    yield put(memberActions.fetchMembersInCommunity.request({ communityCode: community_code }));
  } catch (error: any) {
    yield put(notificationActions.error("Cannot join to this community", (error && error.message) || ""));
  } finally {
    yield put(stopLoading());
  }
}

function* getInvitationHashSaga({ payload: code }: { type: string; payload: string }) {
  try {
    yield put(startLoading());
    const { hash } = yield call(api.getInvitationHash, code);
    yield put(actions.communityInvitationHash.success(hash));
  } catch (error: any) {
    yield put(actions.communityInvitationHash.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* getStripeAccountLinkSaga({
  payload,
}: {
  type: string;
  payload: { communityCode: string; returnPath: string };
}) {
  try {
    yield put(startLoading());
    const { url }: { url: string } = yield call(api.getStripeAccountLink, payload);
    yield put(actions.communityStripeAccountLink.success(url));
  } catch (error: any) {
    yield put(actions.communityStripeAccountLink.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* goToStripeAccountSaga({ payload }: { type: string; payload: { communityCode: string; returnPath: string } }) {
  yield put(actions.communityStripeAccountLink.request(payload));
  const result: AnyAction = yield take([
    actions.communityStripeAccountLink.success,
    actions.communityStripeAccountLink.failure,
  ]);
  if (result.type === constants.CommunityActionsTypes.COMMUNITY_STRIPE_ACCOUNT_LINK_SUCCESS) {
    window.location.replace(result.payload);
  }
}

function* updateAnalyticsCommunitySaga() {
  const [user, community]: [IUser, ICommunity] = yield all([select(getUser()), select(getCommunity())]);
  if (community) {
    const fireBaseData: FirebaseCheckInEventPayload = {
      community_id: community.id,
      community_name: community.name,
      is_admin: 0,
    };
    if (user) {
      fireBaseData.is_admin = Number(checkRoles([ERoles.admin, ERoles.manager], community.id));
    }
    handleFirebaseEvent(FIREBASE_EVENTS.CHECK_IN, fireBaseData);
  }
}

function* getCommunityUsersSaga({ payload }: ReturnType<typeof actions.getCommunityUsers.request>) {
  try {
    yield put(startLoading());
    const users: IUser[] = yield call(api.getCommunityUsers, payload);
    yield put(actions.getCommunityUsers.success(users));
  } catch (error: any) {
    yield put(actions.getCommunityUsers.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* getLandingSettingsSubdomains() {
  try {
    yield put(startLoading());
    const subdomains: string[] = yield call(api.getLandingSettingsSubdomains);
    yield put(actions.getSubdomainList.success(subdomains));
  } catch (error: any) {
    yield put(actions.getSubdomainList.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* getCommunityLandingSettings({ payload }: ReturnType<typeof actions.getCommunityLandingSettings.request>) {
  try {
    yield put(startLoading());
    const { settings }: { settings: ICommunityLandingSettings } = yield call(api.getCommunityLandingSettings, payload);
    yield put(actions.getCommunityLandingSettings.success(settings));
  } catch (error: any) {
    yield put(actions.getCommunityLandingSettings.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* createCommunityLandingSettings({
  payload,
}: ReturnType<typeof actions.createCommunityLandingSettings.request>) {
  try {
    yield put(startLoading());
    if (payload) {
      const { settings }: { settings: ICommunityLandingSettings } = yield call(
        api.createCommunityLandingSettings,
        payload,
      );
      yield put(actions.createCommunityLandingSettings.success(settings));
      yield put(notificationActions.success("The community landing settings were successfully saved"));
      yield put(actions.getSubdomainList.request());
    }
  } catch (error: any) {
    yield put(notificationActions.error("Cannot create landing settings", (error && error.message) || ""));
    yield put(actions.createCommunityLandingSettings.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* updateCommunityLandingSettings({
  payload,
}: ReturnType<typeof actions.updateCommunityLandingSettings.request>) {
  try {
    yield put(startLoading());
    if (payload && payload.id) {
      const { settings }: { settings: ICommunityLandingSettings } = yield call(
        api.updateCommunityLandingSettings,
        payload.id,
        payload,
      );
      yield put(actions.updateCommunityLandingSettings.success(settings));
      yield put(notificationActions.success("The community landing settings were successfully saved"));
      yield put(actions.getSubdomainList.request());
    }
  } catch (error: any) {
    yield put(notificationActions.error("Cannot update landing settings", (error && error.message) || ""));
    yield put(actions.updateCommunityLandingSettings.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* getCommunitiesCountSaga() {
  try {
    yield put(startLoading());
    const count: number = yield call(api.getCommunitiesCount);
    yield put(actions.getCommunitiesCount.success(count));
  } catch (error: any) {
    yield put(actions.getCommunitiesCount.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* getCommunityStreamingStatisticsSaga({
  payload,
}: ReturnType<typeof actions.getCommunityStreamingStatistics.request>) {
  try {
    yield put(startLoading());
    const statistics: CommunityState["streamingStatistics"] = yield call(api.getCommunityStreamStatistics, payload);
    yield put(actions.getCommunityStreamingStatistics.success(statistics));
  } catch (error: any) {
    yield put(actions.getCommunityStreamingStatistics.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* getCommunityIntroductionStepsSaga({
  payload,
}: ReturnType<typeof actions.getCommunityIntroductionSteps.request>) {
  try {
    yield put(startLoading());
    const introductionSteps: ICommunityIntroStep[] = yield call(api.getCommunityIntroductionSteps, payload);
    yield put(actions.getCommunityIntroductionSteps.success(introductionSteps));
  } catch (error: any) {
    yield put(actions.getCommunityIntroductionSteps.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* completeCommunityIntroductionStepSaga({
  payload,
}: ReturnType<typeof actions.completeCommunityIntroductionStep>) {
  try {
    const { community_id, step_id } = payload;
    const introductionSteps: ICommunityIntroStep[] = yield call(
      api.completeCommunityIntroductionStep,
      { step_id },
      community_id,
    );
    yield put(actions.getCommunityIntroductionSteps.success(introductionSteps));
  } catch (error: any) {
    yield put(actions.getCommunityIntroductionSteps.failure());
  }
}

function* createTrialForCommunityWithoutSubscriptionSaga({
  payload,
}: ReturnType<typeof actions.createTrialForCommunityWithoutSubscription>) {
  try {
    yield put(startLoading());
    const { community_code, callback } = payload;
    yield call(api.createTrialForCommunityWithoutSubscription, { community_code });
    callback();
  } catch (error) {
    // skip errors
  } finally {
    yield put(stopLoading());
  }
}

function* sendHubSpotFormDataSaga({ payload }: ReturnType<typeof actions.sendHubSpotFormData.request>) {
  try {
    yield put(startLoading());
    const { callback, ...data } = payload;
    yield call(api.sendHubSpotFormData, data);
    yield put(notificationActions.success("The feedback was successfully sent"));
    callback();
  } catch (error: any) {
    yield put(notificationActions.error("Error occurred", (error && error.message) || ""));
    yield put(actions.sendHubSpotFormData.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* getMAUDataSaga({ payload }: ReturnType<typeof actions.getMAUData.request>) {
  try {
    yield put(startLoading());
    const mauData: IMAUData = yield call(api.getMAUData, payload);
    yield put(actions.getMAUData.success(mauData));
  } catch (error: any) {
    yield put(actions.getMAUData.failure());
  } finally {
    yield put(stopLoading());
  }
}

function clearCommunitySaga() {
  GA.stopCurrentTracking();
}

function* fetchAnalyticsSaga({ payload }: ReturnType<typeof actions.fetchAnalytics.request>) {
  try {
    yield put(startLoading());
    const { range, ...rest } = payload;
    const analytics: IAnalytics = yield call(api.fetchAnalytics, rest);
    yield put(actions.fetchAnalytics.success({ ...analytics, range }));
  } catch (error: any) {
    yield put(actions.fetchAnalytics.failure());
  } finally {
    yield put(stopLoading());
  }
}

function* communitySagas() {
  yield takeLatest(constants.CommunityActionsTypes.GET_USER_COMMUNITIES_LIST, getUserCommunitiesSaga);
  yield takeLatest(constants.CommunityActionsTypes.GET_COMMUNITIES_LIST, getCommunitiesSaga);
  yield takeLatest(actions.createCommunity.request, createCommunitySaga);
  yield takeLatest(actions.updateCommunity.request, updateCommunitySaga);
  yield takeLatest(actions.deleteCommunity.request, deleteCommunitySaga);
  yield takeLatest(actions.getCommunity.request, getCommunitySaga);
  yield takeLatest(actions.getCommunityWithVimeoToken.request, getCommunityWithVimeoToken);
  yield takeLatest(constants.CommunityActionsTypes.GET_ALL_USERS_REQUEST, getAllUsersSaga);
  yield takeLatest(constants.CommunityActionsTypes.JOIN_TO_NEW_COMMUNITY_REQUEST, joinToNewCommunitySaga);
  yield takeLatest(constants.CommunityActionsTypes.COMMUNITY_INVITATION_HASH_REQUEST, getInvitationHashSaga);
  yield takeLatest(actions.getCommunitySubscriptions.request, getCommunitySubscriptionsSaga);
  yield takeLatest(actions.communityUpgradeSubscription.request, communityUpgradeSubscriptionSaga);
  yield takeLatest(constants.CommunityActionsTypes.COMMUNITY_STRIPE_ACCOUNT_LINK_REQUEST, getStripeAccountLinkSaga);
  yield takeLatest(constants.CommunityActionsTypes.GO_TO_STRIPE_ACCOUNT, goToStripeAccountSaga);
  yield takeEvery(constants.CommunityActionsTypes.UPDATE_ANALYTICS_COMMUNITY, updateAnalyticsCommunitySaga);
  yield takeLatest(constants.CommunityActionsTypes.COMMUNITY_USERS_REQUEST, getCommunityUsersSaga);

  yield takeLatest(actions.getSubdomainList.request, getLandingSettingsSubdomains);
  yield takeLatest(actions.getCommunityLandingSettings.request, getCommunityLandingSettings);
  yield takeLatest(actions.createCommunityLandingSettings.request, createCommunityLandingSettings);
  yield takeLatest(actions.updateCommunityLandingSettings.request, updateCommunityLandingSettings);
  yield takeLatest(constants.CommunityActionsTypes.GET_COMMUNITIES_COUNT_REQUEST, getCommunitiesCountSaga);
  yield takeLatest(actions.getCommunityStreamingStatistics.request, getCommunityStreamingStatisticsSaga);
  yield takeLatest(actions.getCommunityIntroductionSteps.request, getCommunityIntroductionStepsSaga);
  yield takeLatest(actions.completeCommunityIntroductionStep, completeCommunityIntroductionStepSaga);
  yield takeLatest(actions.createTrialForCommunityWithoutSubscription, createTrialForCommunityWithoutSubscriptionSaga);
  yield takeLatest(actions.sendHubSpotFormData.request, sendHubSpotFormDataSaga);
  yield takeLatest(actions.getMAUData.request, getMAUDataSaga);
  yield takeLatest(constants.CommunityActionsTypes.CLEAR_CURRENT_COMMUNITY, clearCommunitySaga);
  yield takeLatest(actions.fetchAnalytics.request, fetchAnalyticsSaga);
}

export default communitySagas;
