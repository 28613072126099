import React from "react";

import PollResults from "../PollResults/PollResults";
import PollVoting from "../PollVoting/PollVoting";
import { IPoll, PollStatus } from "../../../../interfaces";

import { timeToAMPM } from "shared/utils/getStream";

import "./pollDetails.scss";

interface IPollDetails {
  poll: IPoll | null;
  memberVoted: boolean;
  canEditPoll: boolean;
}

const PollDetails: React.FC<IPollDetails> = props => {
  const { poll, memberVoted } = props;

  return poll ? (
    <div className="pollDetails">
      <div className="pollDetails-createInfo">
        {`${poll.updated_by || poll.created_by} ${timeToAMPM(poll.updated_at || poll.created_at)}`}
      </div>
      <div className="pollDetails-question">
        {poll.poll_question}
        <span>{poll.show_results ? " (Live results)" : ""}</span>
      </div>
      {memberVoted || poll.status !== PollStatus.published ? <PollResults poll={poll} /> : <PollVoting poll={poll} />}
    </div>
  ) : null;
};

export default PollDetails;
