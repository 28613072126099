import { createSelector } from "reselect";

import { AltarStore } from "shared";

const selectBreakoutRooms = (state: AltarStore) => state.breakoutRooms;

export const selectIsConnecting = () => createSelector(selectBreakoutRooms, state => state.isConnecting);
export const selectIsEnded = () => createSelector(selectBreakoutRooms, state => state.isEnded);
export const selectRoomCode = () => createSelector(selectBreakoutRooms, state => state.roomCode);
export const selectStream = () => createSelector(selectBreakoutRooms, state => state.stream);
export const selectStreams = () => createSelector(selectBreakoutRooms, state => state.streams);
export const selectRooms = () => createSelector(selectBreakoutRooms, state => state.rooms);
export const selectIsImutted = () => createSelector(selectBreakoutRooms, state => state.isImutted);
export const getUserDevices = () => createSelector(selectBreakoutRooms, state => state.userDevices);
export const selectVideoState = () => createSelector(selectBreakoutRooms, state => state.isVideoDisabled);
export const getUserMediaError = () => createSelector(selectBreakoutRooms, state => state.userMediaError);
export const getMediaPermissions = () => createSelector(selectBreakoutRooms, state => state.mediaPermissions);
export const getCurrentBreakOutRoomScreen = () =>
  createSelector(selectBreakoutRooms, state => state.currentBreakOutRoomScreen);
