import React, { useMemo, useState } from "react";
import moment from "moment";

import { EFileDownloadState } from "../../../interfaces";

import { IFile } from "shared/interfaces/File";
import download_record_complete from "assets/icons/download_record_complete.svg";
import download_record from "assets/icons/download_record.svg";
import download_record_processing from "assets/images/download_record_processing.gif";

type Props = {
  file: IFile;
  downloadHandler: (url: string, fileName: string, setProgress: (progressStatus: EFileDownloadState) => void) => void;
  index: number;
  downloadAllProgress: EFileDownloadState | null;
};

const RecordPopupItem: React.FC<Props> = ({ file, downloadHandler, index, downloadAllProgress }) => {
  const [progress, setProgress] = useState<EFileDownloadState>(EFileDownloadState.init);
  const fileType = file.origin_path.match(/.\w+$/)?.[0];

  const downloadImgSrc = useMemo(() => {
    return (downloadAllProgress || progress) === EFileDownloadState.complete
      ? download_record_complete
      : (downloadAllProgress || progress) === EFileDownloadState.processing
      ? download_record_processing
      : download_record;
  }, [progress, downloadAllProgress]);

  return (
    <>
      {fileType ? (
        <div
          className="meeting-records-item"
          key={file.id}
          onClick={() => downloadHandler(file.path || file.origin_path, `${file.name}${fileType}`, setProgress)}
        >
          <div className="meeting-records-item-name">
            <img src={downloadImgSrc} alt="download" />
            record-{index + 1}
            {fileType}
          </div>
          <div className="meeting-records-item-duration">{moment.utc(file.duration * 1000).format("HH:mm:ss")}</div>
        </div>
      ) : null}
    </>
  );
};

export default RecordPopupItem;
