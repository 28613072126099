import { VariantType } from "notistack";

export enum NotificationActionTypes {
  REMOVE_SNACKBAR = "@@NOTIFICATIONS/REMOVE_SNACKBAR",
  ENQUEUE_SNACKBAR = "@@NOTIFICATIONS/ENQUEUE_SNACKBAR",
  CHAT_NOTIFICATIONS = "@@NOTIFICATIONS/CHAT_NOTIFICATIONS",

  ENABLE_POPUP = "@@NOTIFICATIONS/ENABLE_POPUP",
  REMOVE_POPUP = "@@NOTIFICATIONS/REMOVE_POPUP",
  ENABLE_NOTIFICATION = "@@NOTIFICATIONS/ENABLE_NOTIFICATION",
  REMOVE_NOTIFICATION = "@@NOTIFICATIONS/REMOVE_NOTIFICATION",
}

export const NotificationVariants: VariantType[] = ["success", "error", "warning", "info"];
