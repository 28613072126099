import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../shared/components/Button";

import "./loginButtons.scss";

export interface LoginButtonsProps {
  onLogin?: () => void;
  onSignup?: () => void;
}

export const LoginButtons: React.FC<LoginButtonsProps> = props => {
  const { onLogin, onSignup } = props;
  const { t } = useTranslation();

  return (
    <div className="login-buttons">
      <div className="login-buttons_text">To chat with other people in the event please Log In or Sign Up</div>
      <div className="login-buttons_btn_wrap">
        <Button variant="white-text" width={120} onClick={onSignup}>
          {t("Sign Up")}
        </Button>
        <Button variant="white-text" width={120} onClick={onLogin}>
          {t("Log In")}
        </Button>
      </div>
    </div>
  );
};
