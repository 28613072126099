import { createAsyncAction, createStandardAction } from "typesafe-actions";

import {
  BreakoutRoomsStateType,
  IMediaPermissionsState,
  IRoomUpdate,
  MediaDevicesList,
  MemberStreamInterface,
} from "../interfaces";
import { BreakOutRoomActionTypes } from "./constants";

import { IMember } from "containers/Member";

export const getUserMedia = createAsyncAction(
  BreakOutRoomActionTypes.GET_USER_MEDIA,
  BreakOutRoomActionTypes.GET_USER_MEDIA_SUCCESS,
  BreakOutRoomActionTypes.GET_USER_MEDIA_FAILURE,
)<MediaStreamConstraints, MediaStream, Error>();

export const setRoomCode = createStandardAction(BreakOutRoomActionTypes.SET_ROOM_CODE)<
  BreakoutRoomsStateType["roomCode"]
>();

export const connectSocket = createAsyncAction(
  BreakOutRoomActionTypes.CONNECT_SOCKET,
  BreakOutRoomActionTypes.CONNECT_SOCKET_SUCCESS,
  BreakOutRoomActionTypes.CONNECT_SOCKET_FAILURE,
)<{ meeting_id: number; room_code: string; member: IMember }, BreakoutRoomsStateType["roomCode"], Error>();

export const exitRoom = createAsyncAction(
  BreakOutRoomActionTypes.EXIT_ROOM,
  BreakOutRoomActionTypes.EXIT_ROOM_SUCCESS,
  BreakOutRoomActionTypes.EXIT_ROOM_FAILURE,
)<{ callback?: () => void } | void, undefined, Error>();

export const addStream = createStandardAction(BreakOutRoomActionTypes.ADD_STREAM)<MemberStreamInterface>();

export const streamConnectionIssue = createStandardAction(BreakOutRoomActionTypes.STREAM_CONNECTION_ISSUE)<{
  clientId: MediaStream["id"];
  hasConnectionIssue: boolean;
}>();

export const handleConnectionIssue = createStandardAction(
  BreakOutRoomActionTypes.HANDLE_CONNECTION_ISSUE,
)<MemberStreamInterface>();

export const removeStream = createStandardAction(BreakOutRoomActionTypes.REMOVE_STREAM)<MediaStream["id"]>();

export const callRoom = createAsyncAction(
  BreakOutRoomActionTypes.CALL_ROOM,
  BreakOutRoomActionTypes.EXIT_ROOM_SUCCESS,
  BreakOutRoomActionTypes.EXIT_ROOM_FAILURE,
)<BreakoutRoomsStateType["roomCode"], undefined, Error>();

export const leaveRoom = createStandardAction(BreakOutRoomActionTypes.LEAVE_ROOM)();
export const joinRoom = createStandardAction(BreakOutRoomActionTypes.JOIN_ROOM)();

export const resetState = createStandardAction(BreakOutRoomActionTypes.RESET_STATE)();

export const updateRooms = createAsyncAction(
  BreakOutRoomActionTypes.UPDATE_ROOMS,
  BreakOutRoomActionTypes.UPDATE_ROOMS_SUCCESS,
  BreakOutRoomActionTypes.UPDATE_ROOMS_FAILURE,
)<number, BreakoutRoomsStateType["rooms"], Error>();

export const joinMeeting = createAsyncAction(
  BreakOutRoomActionTypes.JOIN_MEETING,
  BreakOutRoomActionTypes.JOIN_MEETING_SUCCESS,
  BreakOutRoomActionTypes.JOIN_MEETING_FAILURE,
)<number, undefined, Error>();

export const pageReload = createAsyncAction(
  BreakOutRoomActionTypes.PAGE_RELOAD,
  BreakOutRoomActionTypes.PAGE_RELOAD_SUCCESS,
  BreakOutRoomActionTypes.PAGE_RELOAD_FAILURE,
)<undefined, undefined, Error>();

export const handleFriendMuted = createStandardAction(BreakOutRoomActionTypes.HANDLE_FRIEND_MUTE)<{
  from_id: string;
  isMutted: boolean;
}>();

export const handleFriendVideo = createStandardAction(BreakOutRoomActionTypes.HANDLE_FRIEND_VIDEO)<{
  from_id: string;
  isVideoDisabled: boolean;
}>();

export const muteMe = createStandardAction(BreakOutRoomActionTypes.MUTE_ME)<boolean>();
export const disableMyVideo = createStandardAction(BreakOutRoomActionTypes.DISABLE_MY_VIDEO)<boolean>();

export const getUserMediaDevices = createAsyncAction(
  BreakOutRoomActionTypes.GET_USER_MEDIA_DEVICES,
  BreakOutRoomActionTypes.GET_USER_MEDIA_DEVICES_SUCCESS,
  BreakOutRoomActionTypes.GET_USER_MEDIA_DEVICES_FAILURE,
)<undefined, MediaDevicesList<MediaDeviceInfo>, Error>();

export const updateStreamSettings = createAsyncAction(
  BreakOutRoomActionTypes.UPDATE_STREAM_SETTINGS,
  BreakOutRoomActionTypes.UPDATE_STREAM_SETTINGS_SUCCESS,
  BreakOutRoomActionTypes.UPDATE_STREAM_SETTINGS_FAILURE,
)<MediaStream, MediaStream, Error>();

export const updateRoom = createStandardAction(BreakOutRoomActionTypes.UPDATE_ROOM)<IRoomUpdate>();

export const checkUserMediaPermissions = createAsyncAction(
  BreakOutRoomActionTypes.CHECK_USER_MEDIA_PERMISSIONS,
  BreakOutRoomActionTypes.CHECK_USER_MEDIA_PERMISSIONS_SUCCESS,
  BreakOutRoomActionTypes.CHECK_USER_MEDIA_PERMISSIONS_FAILURE,
)<IMediaPermissionsState, PermissionState, undefined>();

export const askUserMediaPermission = createStandardAction(
  BreakOutRoomActionTypes.ASK_USER_MEDIA_PERMISSIONS,
)<MediaStreamConstraints>();

export const changeCurrentBreakoutRoomScreen = createStandardAction(
  BreakOutRoomActionTypes.CHANGE_BREAKOUT_ROOM_SCREEN,
)<BreakoutRoomsStateType["currentBreakOutRoomScreen"]>();
