import { request } from "../../../shared/utils";
import { METHODS, API } from "../../../shared/constants";
import { IContentShape } from "../../App/interfaces";
import {
  ICreateCommunity,
  ICreateCommunityLandingSettings,
  IUpgradeCommunitySubscription,
  SendHubSpotFormDataRequest,
} from "../interfaces";

export default {
  getCommunities: (params: IContentShape) => request(METHODS.GET, API.COMMUNITIES)({ params }),
  getCommunity: (code: string) => request(METHODS.GET, API.COMMUNITY(code))(),
  getCommunityVimeoToken: (code: string) => request(METHODS.GET, API.COMMUNITY_VIMEO_TOKEN(code))(),
  createCommunity: (params: ICreateCommunity & { hubSpotUserToken?: string }) =>
    request(METHODS.POST, API.CREATE_COMMUNITY)(params),
  updateCommunity: (communityId: number | string, payload: Partial<ICreateCommunity>) =>
    request(METHODS.PUT, API.UPDATE_COMMUNITY(communityId))(payload),
  deleteCommunity: (communityId: number) => request(METHODS.DELETE, API.DELETE_COMMUNITY(communityId))(),
  getAllUsers: () => request(METHODS.GET, API.USERS)(),
  getInvitationHash: (communityCode: string) => request(METHODS.GET, API.FETCH_COMMUNITY_INVITATION(communityCode))(),
  joinToNewCommunity: (invitationHash: string, body: { hubSpotUserToken?: string }) =>
    request(METHODS.POST, API.JOIN_TO_NEW_COMMUNITY(invitationHash))(body),
  getCommunitySubscriptions: (communityCode: string) =>
    request(METHODS.GET, API.COMMUNITY_SUBSCRIPTIONS(communityCode))(),
  upgradeCommunitySubscription: (communityCode: string, payload: IUpgradeCommunitySubscription) =>
    request(METHODS.PUT, API.COMMUNITY_SUBSCRIPTION_UPGRADE(communityCode))(payload),
  getStripeAccountLink: (payload: { communityCode: string; returnPath: string }) =>
    request(METHODS.POST, API.COMMUNITY_STRIPE_ACCOUNT_LINK)(payload),
  getCommunityUsers: (communityId: number) => request(METHODS.GET, API.COMMUNITY_USERS(communityId))(),

  getLandingSettingsSubdomains: () => request(METHODS.GET, API.COMMUNITY_LANDING_SETTINGS_SUBDOMAIN)(),
  getCommunityLandingSettings: (communityId: number) =>
    request(METHODS.GET, API.COMMUNITY_LANDING_SETTINGS(communityId))(),
  createCommunityLandingSettings: (payload: ICreateCommunityLandingSettings) =>
    request(METHODS.POST, API.CREATE_COMMUNITY_LANDING_SETTINGS)(payload),
  updateCommunityLandingSettings: (settings_id: number, payload: ICreateCommunityLandingSettings) =>
    request(METHODS.PUT, API.COMMUNITY_LANDING_SETTINGS(settings_id))(payload),
  getCommunitiesCount: () => request(METHODS.GET, API.GET_COMMUNITIES_COUNT)(),
  getCommunityAppLink: (communityCode: string) => request(METHODS.GET, API.GET_COMMUNITY_APP_LINK(communityCode))(),
  getCommunityStreamStatistics: (id: number) => request(METHODS.GET, API.GET_COMMUNITY_STREAMING_STATISTICS(id))(),
  getCommunityIntroductionSteps: (community_id: number) =>
    request(METHODS.GET, API.GET_COMMUNITY_INTRODUCTION_STEPS(community_id))(),
  completeCommunityIntroductionStep: (payload: { step_id: number }, community_id: number) =>
    request(METHODS.POST, API.COMPLETE_COMMUNITY_INTRODUCTION_STEP(community_id))(payload),
  createTrialForCommunityWithoutSubscription: (payload: { community_code: string }) =>
    request(METHODS.POST, API.CREATE_TRIAL_FOR_COMMUNITY_WITHOUT_SUBSCRIPTION)(payload),
  sendHubSpotFormData: ({ community_id, ...data }: SendHubSpotFormDataRequest) =>
    request(METHODS.POST, API.HOT_SPOT_FORM(community_id))(data),
  getMAUData: (id: number) => request(METHODS.GET, API.GET_MAU_DATA(id))(),
  fetchAnalytics: ({ id, dateRange: params }: { id: number; dateRange: { from: string; to: string } }) =>
    request(METHODS.GET, API.FETCH_ANALYTICS(id))({ params }),
};
