import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";

import Divider from "../Divider";
import { Interaction } from "../Interaction";
import { EInteractionKind, IInteraction } from "../Interaction/Interaction";
import { CreatePollInteraction } from "../CreatePollInteraction";
import { preparePollForm } from "../../../EventContainer/EventPollsDialog/PollCreation/PollCreation";
import { getAnnouncementTemplates, getPollTemplates } from "../../../../store/selectors";
import { fetchAnnouncementTemplates, fetchPollTemplates } from "../../../../store/actions";
import { getCommunity } from "../../../../../Community/store/selectors";
import { CreateAnnouncementInteraction } from "../CreateAnnouncementInteraction";

import {
  IAnnouncementInteraction,
  IAnnouncementTemplate,
  IEventForm,
  IPoll,
  IPollTemplate,
  PollStatus,
  prepareAnnouncementForm,
} from "containers/Sermons";
import { AlertDialog, Button, DropDown, StyledLink, StyledRadio } from "shared";
import { LINKS } from "shared/constants/links";
import add_icon from "assets/icons/add-navigation-link.svg";

import "./styles.scss";

enum ENewInteractionType {
  newInteraction = "newInteraction",
  templateInteraction = "templateInteraction",
}

const interactionReduce = (kind: EInteractionKind) => {
  return (
    acc: IInteraction[],
    interaction: IPoll | IAnnouncementInteraction,
    interactionIndex: number,
  ): IInteraction[] => [...acc, { interaction, kind, interactionIndex }];
};

const InteractionsStep: React.FC = () => {
  const dispatch = useDispatch();

  const {
    values: { polls, announcements },
    setFieldValue,
  } = useFormikContext<IEventForm>();

  const pollTemplates = useSelector(getPollTemplates());
  const announcementTemplates = useSelector(getAnnouncementTemplates());
  const community = useSelector(getCommunity());

  const [disableDrag, setDisableDrag] = useState(false);
  const [newInteraction, setNewInteraction] = useState<ENewInteractionType | null>(null);
  const [selectedKind, setSelectedKind] = useState<EInteractionKind>(EInteractionKind.polls);
  const [interactionToDelete, setInteractionToDelete] = useState<IInteraction | null>(null);
  const [selectedInteraction, setSelectedInteraction] = useState<IInteraction | null>(null);
  const [selectedInteractionTemplate, setSelectedInteractionTemplate] = useState<
    IPollTemplate | IAnnouncementTemplate | null
  >(null);

  const interactions = useMemo(() => {
    return [
      ...polls.reduce(interactionReduce(EInteractionKind.polls), [] as IInteraction[]),
      ...announcements.reduce(interactionReduce(EInteractionKind.announcements), [] as IInteraction[]),
    ].sort((a, b) => a.interaction.position - b.interaction.position);
  }, [announcements, polls]);

  const templatesDropDownList = useMemo(() => {
    if (newInteraction === ENewInteractionType.templateInteraction) {
      setSelectedInteractionTemplate(
        (selectedKind === EInteractionKind.polls ? pollTemplates[0] : announcementTemplates[0]) || null,
      );
    }
    const templateKindToList = selectedKind === EInteractionKind.polls ? pollTemplates : announcementTemplates;
    return templateKindToList.map(template => ({
      text: template.title,
      value: template.id,
    }));
  }, [announcementTemplates, newInteraction, pollTemplates, selectedKind]);

  const editInteractionHandler = useCallback(({ interaction, kind, ...rest }) => {
    setSelectedKind(kind);
    setSelectedInteraction({
      ...rest,
      kind,
      interaction:
        kind === EInteractionKind.polls
          ? { ...interaction, ...preparePollForm(interaction) }
          : { ...interaction, ...prepareAnnouncementForm(interaction) },
    });
  }, []);

  const cancelInteractionHandler = useCallback(() => {
    setSelectedInteraction(null);
    setNewInteraction(null);
    setInteractionToDelete(null);
    setSelectedInteractionTemplate(null);
  }, []);

  const deleteInteractionHandler = useCallback(() => {
    if (interactionToDelete) {
      const { kind, interactionIndex } = interactionToDelete;
      setFieldValue(
        kind,
        [...{ polls, announcements }[kind]].filter((i, index) => interactionIndex !== index),
      );
      setInteractionToDelete(null);
    }
  }, [announcements, interactionToDelete, polls, setFieldValue]);

  const submitInteraction = useCallback(
    values => {
      if (selectedInteraction) {
        const { kind, interactionIndex } = selectedInteraction;
        if (interactionIndex !== null) {
          setFieldValue(`${kind}[${interactionIndex}]`, values);
        } else {
          setFieldValue(kind, [...interactions.filter(i => i.kind === kind).map(i => i.interaction), values]);
        }
      }
      setSelectedInteraction(null);
    },
    [interactions, selectedInteraction, setFieldValue],
  );

  const addInteractionHandler = useCallback(() => {
    switch (selectedKind) {
      case EInteractionKind.polls: {
        setSelectedInteraction({
          interaction: {
            ...preparePollForm(
              selectedInteractionTemplate
                ? {
                    ...(selectedInteractionTemplate as IPollTemplate),
                    id: undefined,
                    status: PollStatus.draft,
                    meeting_id: undefined,
                    options: (selectedInteractionTemplate as IPollTemplate).options.map(o => ({
                      ...o,
                      vote_results: [],
                    })),
                    position: interactions[interactions.length - 1]?.interaction.position + 1 || 0,
                  }
                : null,
            ),
            temporary_key: Date.now(),
          },
          kind: EInteractionKind.polls,
          interactionIndex: null,
        });
        break;
      }

      case EInteractionKind.announcements: {
        setSelectedInteraction({
          interaction: {
            ...prepareAnnouncementForm(
              selectedInteractionTemplate ? (selectedInteractionTemplate as IAnnouncementTemplate) : null,
            ),
            position: interactions[interactions.length - 1]?.interaction.position + 1 || 0,
            temporary_key: Date.now(),
          },
          kind: EInteractionKind.announcements,
          interactionIndex: null,
        });
        break;
      }
    }
    setNewInteraction(null);
    setSelectedInteractionTemplate(null);
  }, [interactions, selectedInteractionTemplate, selectedKind]);

  const changeSelectedTemplate = useCallback(
    value => {
      const templatesToFind = [...(selectedKind === EInteractionKind.polls ? pollTemplates : announcementTemplates)];
      setSelectedInteractionTemplate(templatesToFind.find(t => t.id === value) || null);
    },
    [announcementTemplates, pollTemplates, selectedKind],
  );

  const onChangeOrder = useCallback(
    (sourceId: string, sourceIndex: number, targetIndex: number) => {
      const pollsOrder: IPoll[] = [];
      const announcementsOrder: IAnnouncementInteraction[] = [];
      const newOrder = swap(interactions, sourceIndex, targetIndex);
      newOrder.forEach(({ kind, interaction }, index) => {
        kind === EInteractionKind.polls
          ? pollsOrder.push({ ...interaction, position: index } as IPoll)
          : announcementsOrder.push({ ...interaction, position: index } as IAnnouncementInteraction);
      });
      setFieldValue("polls", pollsOrder);
      setFieldValue("announcements", announcementsOrder);
      setDisableDrag(true);
    },
    [interactions, setFieldValue],
  );

  useEffect(() => {
    if (community) {
      dispatch(fetchPollTemplates.request(community.id));
      dispatch(fetchAnnouncementTemplates.request(community.id));
    }
  }, [community, dispatch]);

  return (
    <div className="interactionsStep">
      <AlertDialog
        open={!!interactionToDelete}
        title="Delete Interaction"
        message="Are you sure that you want to delete this interaction?"
        onConfirm={deleteInteractionHandler}
        onCancel={cancelInteractionHandler}
        mode="confirm"
        confirmText="Delete"
        confirmClassName="Cancel"
      />
      <AlertDialog
        open={!!newInteraction}
        title={
          newInteraction === ENewInteractionType.templateInteraction ? "Use Interaction Template" : "New Interaction"
        }
        onConfirm={addInteractionHandler}
        onCancel={cancelInteractionHandler}
        mode="confirm"
        confirmText="Next"
        confirmClassName="Cancel"
        dialogClassName="interactionsStep-new-alertDialog"
      >
        <div className="interactionsStep-new">
          <RadioGroup value={selectedKind} onChange={e => setSelectedKind(e.target.value as EInteractionKind)}>
            <FormControlLabel
              value={EInteractionKind.polls}
              control={<StyledRadio />}
              label="Poll"
              className="interactionsStep-new-kind"
            />
          </RadioGroup>
          <RadioGroup value={selectedKind} onChange={e => setSelectedKind(e.target.value as EInteractionKind)}>
            <FormControlLabel
              value={EInteractionKind.announcements}
              control={<StyledRadio />}
              label="Announcement"
              className="interactionsStep-new-kind"
            />
          </RadioGroup>
          {newInteraction === ENewInteractionType.templateInteraction && (
            <div className="interactionsStep-new-template">
              <DropDown
                items={templatesDropDownList}
                onChange={changeSelectedTemplate}
                size="full"
                spacer={false}
                value={selectedInteractionTemplate?.id}
              />
            </div>
          )}
        </div>
      </AlertDialog>
      <div className="interactionsStep-title">Interactions</div>
      <div className="interactionsStep-description">
        Interactions are used to engage your attenders during an event. Anyone with the Host role or Admin permissions
        can post interactions during the event. You can add Interactions to a schedule for Hosts to manually post during
        the event, or you can choose to turn on automatic posting.
      </div>
      <div className="interactionsStep-learn">
        <StyledLink className="templates-learnMore" href={LINKS.interactions}>
          Learn how to Schedule Interactions
        </StyledLink>
      </div>
      <Divider />
      <div className="interactionsStep-interactions">
        <GridContextProvider onChange={onChangeOrder}>
          <GridDropZone
            id="interactions"
            boxesPerRow={1}
            rowHeight={184}
            className="interactions-dropzone"
            style={{ height: interactions.length * 184 }}
            disableDrag={disableDrag}
          >
            {interactions.map(i => (
              <GridItem className="interactions-dropzone-item" key={i.interaction.id || i.interaction.temporary_key}>
                <div className="editIcons-drag" onMouseDown={() => setDisableDrag(false)} />
                <Interaction
                  onMouseDown={() => setDisableDrag(true)}
                  interactionInfo={i}
                  editHandler={() => editInteractionHandler(i)}
                  deleteHandler={() => setInteractionToDelete(i)}
                />
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      </div>
      <div className="interactionsStep-add">
        <Button
          width={180}
          variant="blue-text"
          onClick={() => setNewInteraction(ENewInteractionType.newInteraction)}
          type="button"
        >
          <img src={add_icon} alt="add" />
          Add New Interaction
        </Button>
        <span>or</span>
        <Button
          width={182}
          variant="blue-text"
          onClick={() => setNewInteraction(ENewInteractionType.templateInteraction)}
          type="button"
        >
          Use Interaction Template
        </Button>
      </div>
      {selectedInteraction &&
        (selectedKind === EInteractionKind.polls ? (
          <CreatePollInteraction
            submitInteraction={submitInteraction}
            interaction={selectedInteraction.interaction as IPoll}
            openInteraction={!!selectedInteraction}
            onCancel={cancelInteractionHandler}
          />
        ) : selectedKind === EInteractionKind.announcements ? (
          <CreateAnnouncementInteraction
            submitInteraction={submitInteraction}
            interaction={selectedInteraction.interaction as IAnnouncementInteraction}
            openInteraction={!!selectedInteraction}
            onCancel={cancelInteractionHandler}
          />
        ) : null)}
    </div>
  );
};

export default InteractionsStep;
