import React from "react";

import "./index.scss";
import { SubscriptionBanner, Testimonials, EditSubscription, FAQ } from "../";

const faqItems = [
  {
    title: "Who counts as an active Altar Live user/member?",
    value:
      "A Monthly Active User (MAU) is defined as a unique, logged in user who attended at least one meeting or event in the same month. Anonymous visitors and attendees are not counted as monthly active users.",
  },
  {
    title: "How much will Altar Live cost?",
    value:
      "You only pay for what you use. You can choose to pay month to month, or pay annually. As more people create user accounts, you can choose to let the platform automatically upgrade you or upgrade manually. You only have to pay for people who create user accounts and are actively using the platform, joining events or meetings.",
  },
  {
    title: "Do you offer a free trial?",
    value:
      "Yes, we offer a 30-day free trial for all new accounts! You can create an account for free and try out all the features with your entire team.",
  },
  {
    title: "Do I have to pay for anonymous visitors or people who join my events?",
    value:
      "No - there is no limit to number of anoynmous visitors or people who join your events. Altar prioritizes accessibility, growth and reach. Only when someone logs in as a member are they considered a monthly active user.",
  },
];

const PaymentDetails = () => {
  return (
    <div className="payment-details-wrapper">
      <div className="payment-information-wrapper">
        <div className="main-content-wrapper">
          <EditSubscription />
          <FAQ items={faqItems} />
        </div>
        <div className="right-sidebar-content-wrapper">
          <Testimonials />
        </div>
      </div>
      <div className="banner">
        <SubscriptionBanner />
      </div>
    </div>
  );
};

export default PaymentDetails;
