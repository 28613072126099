import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { Grid } from "@material-ui/core";
import classNames from "classnames";

import { ICommunityLandingSettings } from "../../../interfaces";
import { selectLandingSettings } from "../../../store/selectors";
import { notificationActions } from "../../../../Notifications/store/actions";

import { copyTextHandler, CustomFormikField } from "shared";
import config from "config";
import copy_icon_disabled from "assets/icons/copy_icon_disabled.svg";
import copy_icon from "assets/icons/copy_icon.svg";

import "./index.scss";

const AppearancePage: React.FC = () => {
  const dispatch = useDispatch();
  const { values, handleChange, handleBlur, errors } = useFormikContext<ICommunityLandingSettings>();

  const landingSettings = useSelector(selectLandingSettings());

  const copyDomainHandler = useCallback(
    (value: string) => {
      if (value) {
        copyTextHandler(value);
        dispatch(notificationActions.success("Community link copied to the clipboard"));
      }
    },
    [dispatch],
  );

  return (
    <div className="appearance-page">
      <div className="left-side">
        <Grid className="landingSettings-domain">
          <div className="domain-settings-information-text">
            You can customize the URL of your Community landing page below. ex. online-jesus.lobby.church.
          </div>
          <div className="subdomain-full-length">
            <CustomFormikField
              className="subdomain-field"
              fullWidth
              required
              label="Your Community Subdomain"
              name="subdomain"
              errors={errors}
              value={values.subdomain}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <div className="subdomain-value">{config.subdomainPartUrl}</div>
          </div>
        </Grid>
      </div>
      <div className="right-side">
        <Grid className="landingSettings-domain">
          <div className="domain-settings-copy-link right-side">
            <button
              disabled={!landingSettings}
              className={classNames({ disabled: !landingSettings })}
              onClick={() => copyDomainHandler(`https://${values.subdomain}${config.subdomainPartUrl}`)}
            >
              <img src={!landingSettings ? copy_icon_disabled : copy_icon} alt="copy link" />
              <span>Copy Community Link</span>
            </button>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default AppearancePage;
