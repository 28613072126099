import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import { IAnnouncementTemplate, IAnnouncementTemplateForm } from "../../../../Sermons";
import { createAnnouncementTemplate, updateAnnouncementTemplate } from "../../../../Sermons/store/actions";
import { prepareAnnouncementTemplateForm } from "../../../utils";
import { validateSchema } from "../../../../Sermons/components/EventContainer/EventAnnouncementsDialog/EventAnnouncementForm/formValidators";
import { EventAnnouncementMainForm } from "../../../../Sermons/components/EventContainer/EventAnnouncementsDialog/EventAnnouncementMainForm";

import { AlertDialog } from "shared";

import "./index.scss";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  announcementTemplate: IAnnouncementTemplate | null;
  community_id: number;
}

const CreateAnnouncementTemplate: React.FC<Props> = ({ open, setOpen, announcementTemplate, community_id }) => {
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState<IAnnouncementTemplateForm>(
    prepareAnnouncementTemplateForm(community_id),
  );

  const onSubmit = useCallback(
    (values: IAnnouncementTemplateForm) => {
      const preparedData = {
        id: values.id,
        title: values.title,
        description: values.description,
        button_text: values.use_button ? values.button_text : "",
        button_link: values.use_button ? values.button_link : "",
        type: values.type,
        community_id,
      };
      if (preparedData.id) {
        dispatch(updateAnnouncementTemplate.request({ id: preparedData.id, preparedData }));
      } else {
        dispatch(createAnnouncementTemplate.request(preparedData));
      }
      setOpen(false);
    },
    [setOpen, community_id, dispatch],
  );

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (announcementTemplate) {
      setFormValues(prepareAnnouncementTemplateForm(community_id, announcementTemplate));
    }
    return () => {
      setFormValues(prepareAnnouncementTemplateForm(community_id, null));
    };
  }, [announcementTemplate, community_id]);

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validateSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit }) => (
        <AlertDialog
          open={open}
          title={announcementTemplate ? "Edit Template" : "New Template"}
          onConfirm={handleSubmit}
          onCancel={onCancel}
          mode="confirm"
          confirmText="Save"
          confirmClassName="Cancel"
          dialogClassName="templates-creationDialog"
          hasForm
        >
          <div className="announcementCreation-template">
            <EventAnnouncementMainForm />
          </div>
        </AlertDialog>
      )}
    </Formik>
  );
};

export default CreateAnnouncementTemplate;
