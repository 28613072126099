import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import classnames from "classnames";

import { NamesOfParentRoutes } from "../../../../constants";
import { TableItem } from "../../components";
import { EventPermissions } from "../../../Auth";
import { checkMeetingRole, EventMembersCounter, EventType, SermonRoles } from "../../../Sermons";
import { BreakoutRoomTypes, RoomInterface } from "../../interfaces";
import { TableRenamePopup } from "../../components/BreakoutRoom/TableRenamePopup";

import { selectRoomCode, selectRooms, selectStream } from "containers/BreakoutRooms/store/selectors";
import { getEvent, getEventSettings } from "containers/Sermons/store/selectors";
import { IRouteProps, useCurrentUserMember, usePermissions } from "shared";
import BenchesGradient from "containers/Sermons/components/EventContainer/BenchesGradient";
import { TablesClassroomSwitcher } from "containers/Meeting/components";
import { selectClassrooms } from "containers/Meeting/store/selectors";

import "./index.scss";

const { STREAM, BREAKOUTROOMS, WATCH_PARTY } = NamesOfParentRoutes;

function TableListContainer({ isAuditorium = false }: { isAuditorium?: boolean }) {
  const { eventCode, communityCode } = useParams<IRouteProps>();

  const [changeNameOpened, setChangeNameOpened] = useState(false);
  const [roomToUpdate, setRoomToUpdate] = useState<RoomInterface | null>(null);
  const stream = useSelector(selectStream());
  const rooms = useSelector(selectRooms());
  const classrooms = useSelector(selectClassrooms());
  const roomCode = useSelector(selectRoomCode());
  const { activeDashboardTab } = useSelector(getEventSettings());
  const event = useSelector(getEvent());

  const history = useHistory();
  useEffect(() => {
    if (roomCode) {
      history.push(
        `${communityCode ? `/${communityCode}` : ""}${STREAM}/${eventCode}${
          isAuditorium ? WATCH_PARTY : BREAKOUTROOMS
        }/${roomCode}`,
      );
    }
  }, [roomCode, history, eventCode, communityCode, isAuditorium]);

  const canEdit = usePermissions([EventPermissions.edit], event?.community_id);
  const currentUserMember = useCurrentUserMember();
  const canEditName = useMemo(
    () =>
      Boolean(
        canEdit ||
          (event && currentUserMember && checkMeetingRole(event, currentUserMember, [SermonRoles.greeterHost])),
      ),
    [canEdit, currentUserMember, event],
  );

  const changeName = useCallback((room: RoomInterface) => {
    setRoomToUpdate(room);
    setChangeNameOpened(true);
  }, []);

  const tableList = useMemo(() => {
    return rooms
      .filter(r => r.type === (isAuditorium ? BreakoutRoomTypes.auditorium : BreakoutRoomTypes.lobby))
      .map((room, idx) => (
        <TableItem
          room={room}
          key={room.code}
          index={idx}
          changeName={canEditName ? changeName : undefined}
          isAuditorium={isAuditorium}
        />
      ));
  }, [rooms, isAuditorium, canEditName, changeName]);

  return (
    <div className="table-list-wrapper">
      {!isAuditorium && classrooms.length > (event?.type === EventType.meeting ? 1 : 0) ? (
        <TablesClassroomSwitcher breakOutRoomJoined={!!stream} />
      ) : null}
      <BenchesGradient />
      <TableRenamePopup
        room={roomToUpdate}
        opened={changeNameOpened}
        onClose={() => {
          setChangeNameOpened(false);
          setRoomToUpdate(null);
        }}
        memberId={currentUserMember?.id}
      />
      <div className="table-list-content">
        <div className={classnames("tables", { "tables-sideBar": !!activeDashboardTab })}>{tableList}</div>
      </div>
      <EventMembersCounter />
    </div>
  );
}

export default React.memo(TableListContainer);
