import { request } from "../../../shared/utils";
import { METHODS, API } from "../../../shared/constants";
import { IConfirmationEmailData, IPerson } from "../interfaces";
import { ECreationKind } from "../../../shared";

export default {
  checkLinkByHash: (hash: string) => request(METHODS.GET, API.FETCH_COMMUNITY_BY_HASH(hash))(),
  checkUserByEmail: (email: string) => request(METHODS.GET, API.FETCH_USER_BY_EMAIL(email))(),
  sendUserConfirmationEmail: (
    params: Pick<IConfirmationEmailData, "email" | "redirectTo" | "hash" | "withCode" | "communityCreation">,
  ) => request(METHODS.GET, API.SEND_USER_CONFIRMATION_EMAIL)({ params }),
  checkEmailIsConfirmed: (hash: string) => request(METHODS.GET, API.CHECK_EMAIL_IS_CONFIRMED(hash))(),
  createProfile: (hash: string, body: Partial<IPerson> & { hubSpotUserToken?: string }, kind: ECreationKind) =>
    request(METHODS.POST, API.CREATE_PROFILE(hash))(body, { params: { kind } }),
  registerMemberForNewUser: (body: Partial<IPerson>) => request(METHODS.POST, API.REGISTER_MEMBER)(body),
  checkUserVerification: (userId: number) => request(METHODS.GET, API.CHECK_USER_VERIFICATION(userId))(),
};
