import { Reducer } from "redux";
import produce from "immer";

import { OnboardingStateType } from "../interfaces";
import { OnboardingActionTypes } from "./constants";

const initialState: OnboardingStateType = {
  user_id: null,
  user: null,
  member: null,
  communityData: null,
  community: null,
  emailExists: false,
};

const reducer: Reducer<OnboardingStateType> = (state: OnboardingStateType = initialState, action) => {
  switch (action.type) {
    case OnboardingActionTypes.CHECK_INVITATION_LINK_SUCCESS:
      return produce(state, nextState => {
        nextState.communityData = action.payload;
      });
    case OnboardingActionTypes.CREATE_PROFILE_SUCCESS:
      return produce(state, nextState => {
        nextState.user_id = action.payload;
      });

    case OnboardingActionTypes.CHECK_EMAIL_SUCCESS:
      return produce(state, nextState => {
        nextState.emailExists = action.payload;
      });
    case OnboardingActionTypes.CREATE_USER_SUCCESS:
    case OnboardingActionTypes.UPDATE_USER_SUCCESS:
    case OnboardingActionTypes.SET_USER:
      return produce(state, nextState => {
        nextState.user = action.payload;
      });

    case OnboardingActionTypes.CREATE_COMMUNITY_SUCCESS:
    case OnboardingActionTypes.GET_ONBOARDING_COMMUNITY_SUCCESS:
      return produce(state, nextState => {
        nextState.community = action.payload;
      });
    case OnboardingActionTypes.UPDATE_COMMUNITY_SUCCESS:
      return produce(state, nextState => {
        nextState.community = action.payload;
      });
    case OnboardingActionTypes.REGISTER_MEMBER_SUCCESS:
      return produce(state, nextState => {
        nextState.member = action.payload;
      });

    case OnboardingActionTypes.CLEAR_ONBOARDING:
      return produce(state, () => initialState);
    case OnboardingActionTypes.OPEN_COMMUNITY_IN_APP_SUCCESS:
      return produce(state, () => {
        window.location.assign(action.payload.deepLink);
      });
    default:
      return state;
  }
};
export { reducer as OnboardingReducer };
