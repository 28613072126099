import React, { useEffect, useMemo } from "react";

import { ICommunityLandingSettings } from "containers/Community";

interface ThemeProviderProps {
  children: React.ReactNode;
  settings: ICommunityLandingSettings;
}

interface ThemeItem {
  background: string;
  contentBackground: string;
  textColor: string;
  elementColor: string;
  elementBackground: string;
}

interface ThemeColors {
  dark: ThemeItem;
  light: ThemeItem;
}

const themeColors: ThemeColors = {
  dark: {
    background: "#181611",
    contentBackground: "#292723",
    textColor: "#FFFFFF",
    elementBackground: "#FFFFFF",
    elementColor: "#000000",
  },
  light: {
    background: "#F0F0F0",
    contentBackground: "#FFFFFF",
    textColor: "#372E35",
    elementBackground: "#000000",
    elementColor: "#FFFFFF",
  },
};

export default function LandingThemeProvider({ children, settings }: ThemeProviderProps) {
  const themeColor = useMemo(() => {
    return settings.theme_color === "light" ? themeColors.light : themeColors.dark;
  }, [settings]);

  useEffect(() => {
    if (settings) {
      document.documentElement.style.setProperty("--backgroud-color", themeColor.background);
      document.documentElement.style.setProperty("--content-backgroud-color", themeColor.contentBackground);
      document.documentElement.style.setProperty("--element-background-color", themeColor.elementBackground);
      document.documentElement.style.setProperty("--element-color", themeColor.elementColor);
      document.documentElement.style.setProperty("--accent-color", settings.accent_color);
      document.documentElement.style.setProperty("--text-color", themeColor.textColor);
      document.documentElement.style.setProperty("--text-color-link", settings.text_color);
      document.documentElement.style.setProperty("--menu-case", settings.text_case);
    }
  }, [settings, themeColor]);

  return <div className="landing-wrapper">{children}</div>;
}
