import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import classnames from "classnames";

import { EEventStreamType } from "../../interfaces";
import { calcPlayerSize, parseIframeProps, PlayerSize } from "../../utils";
import { streamUrlValidationRexExp } from "../../constants";

import useElementSize from "shared/hooks/ElementSizeHook/ElementSizeHook";

import "./styles.scss";

interface Props extends ReactPlayerProps {
  streamType: EEventStreamType | null;
  streamUrl: string | null;
  videoContainer: HTMLDivElement | null;
  playerRef?: React.RefCallback<ReactPlayer>;
  playerClassName?: string;
}

const StreamEventPlayer: React.FC<Props> = ({
  streamType,
  streamUrl,
  videoContainer,
  playerRef,
  playerClassName,
  ...rest
}) => {
  const [player, setPlayer] = useState<ReactPlayer | null>(null);
  const [playerSize, setPlayerSize] = useState<PlayerSize | null>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [renderPlayer, setRenderPlayer] = useState(true);
  const [customIframeSize, setCustomIframeSize] = useState({ width: "100%", height: "100%" });

  const iframe: HTMLIFrameElement | null = (
    (player as ReactPlayerProps)?.wrapper as unknown as HTMLDivElement
  )?.querySelector("iframe");
  iframe?.addEventListener("load", () => setIframeLoaded(true));

  useEffect(() => {
    if (iframe) {
      const allowAttr = iframe.getAttribute("allow");
      if (!allowAttr || allowAttr.indexOf("autoplay") === -1) {
        iframe.setAttribute("allow", `${allowAttr}; autoplay;`);
        iframe.setAttribute("src", iframe.src);
      }
    }
  }, [iframe]);

  const { height: containerHeight, width: containerWith } = useElementSize(videoContainer, 300);

  useEffect(() => {
    if (
      (streamType === EEventStreamType.FB ||
        !!streamUrl?.match(streamUrlValidationRexExp[EEventStreamType.FB])?.length) &&
      !!containerHeight &&
      !!containerWith
    ) {
      const playerSize = calcPlayerSize(containerWith, containerHeight, iframe);
      setPlayerSize(playerSize);
    }
  }, [containerWith, containerHeight, streamType, iframeLoaded, iframe, streamUrl]);

  const customIframeProps = useMemo<Record<string, any>>(() => {
    if (streamUrl && streamType === EEventStreamType.CUSTOM) {
      return parseIframeProps(streamUrl);
    }
    return {};
  }, [streamType, streamUrl]);

  const rerenderCustomIframe = useCallback(() => {
    if (containerHeight && containerWith) {
      setCustomIframeSize({ width: `${containerWith}px`, height: `${containerHeight}px` });
      setRenderPlayer(false);
      setImmediate(() => setRenderPlayer(true));
    }
  }, [containerHeight, containerWith]);

  useEffect(() => {
    if (streamType === EEventStreamType.CUSTOM) {
      rerenderCustomIframe();

      // reinitialize iframe to force inside logic to reset player sizes
      window.addEventListener("load", rerenderCustomIframe);
      return () => window.removeEventListener("load", rerenderCustomIframe);
    }
  }, [rerenderCustomIframe, streamType]);

  return renderPlayer ? (
    streamType === EEventStreamType.CUSTOM ? (
      <iframe title="video-stream" {...customIframeProps} {...customIframeSize} />
    ) : (
      <ReactPlayer
        url={streamUrl || undefined}
        width={playerSize ? `${playerSize.width}px` : "100%"}
        height={playerSize ? `${playerSize.height}px` : "100%"}
        className={classnames("stream-event-player", playerClassName)}
        ref={instance => {
          setPlayer(instance);
          if (playerRef) {
            playerRef(instance);
          }
        }}
        {...rest}
      />
    )
  ) : null;
};

export default StreamEventPlayer;
