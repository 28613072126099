import React from "react";
import classNames from "classnames";

import { Button } from "../../../Button";
import { EInteractionTab } from "./types";

import cross_icon_grey from "assets/icons/cross_icon_grey.svg";

export interface EventSideDialogPollTabsProps {
  onClose?: () => void;
  setInteractionTab?: (newTab: EInteractionTab) => void;
  interactionTab?: EInteractionTab;
}

const EventSideDialogInteractionTabs: React.FC<EventSideDialogPollTabsProps> = props => {
  const { onClose, setInteractionTab, interactionTab = EInteractionTab.published } = props;

  return (
    <div className="eventSideDialog-header tabs">
      <div className="eventSideDialog-header-title">
        <div className="eventSideDialog-header-tabs">
          <div
            className={classNames("eventSideDialog-header-tab", {
              active: interactionTab === EInteractionTab.published,
            })}
          >
            <Button
              fontSize="22"
              variant={interactionTab === EInteractionTab.published ? "white-text" : "custom-text"}
              color="#93808d"
              onClick={() => setInteractionTab?.(EInteractionTab.published)}
            >
              Published
            </Button>
          </div>
          <div
            className={classNames("eventSideDialog-header-tab", {
              active: interactionTab === EInteractionTab.draft,
            })}
          >
            <Button
              fontSize="22"
              variant={interactionTab === EInteractionTab.draft ? "white-text" : "custom-text"}
              color="#93808d"
              onClick={() => setInteractionTab?.(EInteractionTab.draft)}
            >
              Draft
            </Button>
          </div>
        </div>
        {onClose && (
          <Button width={24} variant="black-text" className="eventSideDialog-close-btn" onClick={onClose}>
            <img src={cross_icon_grey} alt="cross icon" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default EventSideDialogInteractionTabs;
