export enum EventSideDialogHeaderTypes {
  default = "default",
  discussions = "discussions",
  discussion_tabs = "discussion_tabs",
  poll_creation = "poll_creation",
  interaction_tabs = "interaction_tabs",
  members = "members",
}

export enum EInteractionTab {
  published = "published",
  draft = "draft",
}
