import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import { IMember } from "../../../../Member";
import { CustomAvatar } from "../../../../Discussions";
import { joinMeetingKnockResponse } from "../../../store/actions";

import { Button } from "shared";

import "./eventMemberListItem.scss";

interface Props {
  waitingMember: IMember;
  knockRequestedDate: number;
}

const WaitingToJoinMemberListItem: React.FC<Props> = ({ waitingMember, knockRequestedDate }) => {
  const dispatch = useDispatch();

  const [timeHasPassed, setTimeHasPassed] = useState(moment().diff(knockRequestedDate, "minutes"));

  const { image_url, last_name, first_name } = waitingMember;
  const interval = setInterval(() => {
    setTimeHasPassed(moment().diff(knockRequestedDate, "minutes"));
  }, 20000);

  const actionsHandler = useCallback(
    (allow: boolean) => {
      const allowed = allow ? [waitingMember.id] : [];
      const denied = allow ? [] : [waitingMember.id];
      dispatch(joinMeetingKnockResponse(allowed, denied));
    },
    [dispatch, waitingMember],
  );

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, [interval]);

  return (
    <div className="waitingToJoinMember">
      <div className="waitingToJoinMember-info">
        <div className="waitingToJoinMember-info-avatar">
          <CustomAvatar
            image={image_url || undefined}
            name={`${first_name} ${last_name}`}
            size={32}
            member={waitingMember}
          />
        </div>
        <div className="waitingToJoinMember-info-name">{`${first_name} ${last_name}`}</div>
        <div className="waitingToJoinMember-info-time">{timeHasPassed}&nbsp;m</div>
      </div>
      <div className="waitingToJoinMember-description">
        The user has sent a request to join the meeting.
        <br />
        Would you like this user to join?
      </div>
      <div className="waitingToJoinMember-action">
        <Button variant="white-text" width={140} height={40} onClick={() => actionsHandler(false)}>
          Deny
        </Button>
        <Button variant="blue" width={140} height={40} onClick={() => actionsHandler(true)}>
          Allow
        </Button>
      </div>
    </div>
  );
};
export default WaitingToJoinMemberListItem;
