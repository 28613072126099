import { Reducer } from "redux";
import produce from "immer";

import { AuthActionTypes } from "./constants";
import { authStateType } from "../interfaces";
import { tokenHandler } from "../../../shared/utils";

const initialState: authStateType = {
  authentificated: !!tokenHandler.get(),
  loaded: true,
  loginLoading: false,
  error: null,
  user: null,
  onboardingData: null,
  userId: null,
  forgotEmail: null,
  magicLinkSent: false,
  emailResent: false,
  registrationData: null,
  conformationError: "",
  token: null,
};
const reducer: Reducer<authStateType> = (state: authStateType = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.SET_LOADING:
      return produce(state, nextState => {
        nextState.loaded = !state.loaded;
      });
    case AuthActionTypes.LOGOUT_FAILURE:
      return state;
    case AuthActionTypes.SET_ERROR: {
      return produce(state, nextState => {
        nextState.error = action.payload;
        nextState.loginLoading = false;
      });
    }
    case AuthActionTypes.DO_UNAUTHORIZED:
      return produce(state, nextState => {
        nextState.loaded = true;
        nextState.authentificated = false;
        nextState.user = null;
      });
    case AuthActionTypes.LOGOUT:
      return produce(state, nextState => {
        nextState.loaded = true;
        nextState.authentificated = false;
      });
    case AuthActionTypes.LOGOUT_SUCCESS:
      return produce(state, nextState => {
        nextState.loaded = true;
        nextState.authentificated = false;
        nextState.user = null;
        nextState.userId = null;
      });
    case AuthActionTypes.CHECK_USER_SUCCESS:
      if (!action.payload) {
        return produce(state, nextState => {
          nextState.loaded = true;
        });
      }
      return produce(state, nextState => {
        nextState.authentificated = true;
        nextState.loaded = true;
        nextState.user = { ...action.payload };
      });
    case AuthActionTypes.CLEAR_CURRENT_USER:
      return produce(state, nextState => {
        nextState.user = null;
      });
    case AuthActionTypes.LOGIN:
      return produce(state, nextState => {
        nextState.loginLoading = true;
      });
    case AuthActionTypes.LOGIN_SUCCESS:
      return produce(state, nextState => {
        if (action.payload) {
          nextState.userId = action.payload;
        } else {
          nextState.userId = null;
          nextState.authentificated = true;
          nextState.loaded = true;
          nextState.loginLoading = false;
          nextState.error = null;
        }
      });
    case AuthActionTypes.LOGIN_FAILURE:
      return produce(state, nextState => {
        nextState.loaded = true;
        nextState.error = action.payload;
        nextState.loginLoading = false;
      });

    case AuthActionTypes.REGISTRATION_SUCCESS:
      return produce(state, nextState => {
        nextState.userId = action.payload;
        nextState.error = null;
      });
    case AuthActionTypes.REGISTRATION_FAILURE:
      return produce(state, nextState => {
        nextState.userId = null;
        nextState.error = action.payload;
      });
    case AuthActionTypes.SET_ONBOARDING_DATA:
      return produce(state, nextState => {
        nextState.onboardingData = action.payload;
      });
    case AuthActionTypes.CLEAR_ONBOARDING_DATA:
      return produce(state, nextState => {
        nextState.onboardingData = null;
      });
    case AuthActionTypes.SET_FORGOT_EMAIL:
      return produce(state, nextState => {
        nextState.forgotEmail = action.payload;
      });
    case AuthActionTypes.CLEAR_FORGOT_EMAIL:
      return produce(state, nextState => {
        nextState.forgotEmail = null;
      });
    case AuthActionTypes.GET_MEMBER_SUCCESS:
      return produce(state, nextState => {
        nextState.member = action.payload;
      });
    case AuthActionTypes.SEND_MAGIC_LINK_SUCCESS:
      return produce(state, nextState => {
        nextState.magicLinkSent = true;
      });
    case AuthActionTypes.SEND_MAGIC_LINK_FAILURE:
    case AuthActionTypes.SEND_MAGIC_LINK_CLEAR:
      return produce(state, nextState => {
        nextState.magicLinkSent = false;
      });
    case AuthActionTypes.SET_EMAIL_RESENT:
      return produce(state, nextState => {
        nextState.emailResent = action.payload;
      });
    case AuthActionTypes.SET_UNVERIFIED_USER_ID:
      return produce(state, nextState => {
        nextState.userId = null;
      });
    case AuthActionTypes.SET_REGISTRATION_DATA:
      return produce(state, nextState => {
        nextState.registrationData = action.payload;
      });
    case AuthActionTypes.SET_USER_IS_NEW:
      return produce(state, nextState => {
        nextState.isNew = action.payload;
      });
    case AuthActionTypes.CONFIRM_VERIFY_USER_SUCCESS:
      return produce(state, nextState => {
        nextState.conformationError = "";
      });
    case AuthActionTypes.CONFIRM_VERIFY_USER_FAILURE:
      return produce(state, nextState => {
        nextState.conformationError = action.payload;
      });
    case AuthActionTypes.CLEAR_CONFIRMATION_ERROR:
      return produce(state, nextState => {
        nextState.conformationError = "";
      });
    case AuthActionTypes.SET_TOKEN:
      return produce(state, nextState => {
        nextState.token = action.payload;
      });
    default:
      return state;
  }
};
export { reducer as AuthReducer };
