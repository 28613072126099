import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import classnames from "classnames";
import * as HttpStatus from "http-status-codes";

import MobileWrapperCountDown from "./MobileWrapperCountDown";
import MobileWrapperLobby from "./MobileWrapperLobby";
import MobileWrapperWarning from "./MobileWrapperWarning";
import MobileWrapperStream from "./MobileWrapperStream";
import { NamesOfParentRoutes } from "../../../../../constants";
import { getChangingStatus } from "../../../store/selectors";

import { EEventRepeat, EventStatus, getEventTimeRange, selectors } from "containers/Sermons";
import { IEvent } from "containers/Sermons/interfaces";
import { AlertDialog, Button } from "shared/components";
import { StyledAltarLogo } from "shared/components/StyledAltarLogo";
import { fetchEventShortLink, getRecurringEvent } from "containers/Sermons/store/actions";
import { IRouteProps, ResponseError } from "shared/interfaces";
import { isMatchedPath } from "containers/Community/utils";
import { getEventTypeName } from "containers/Sermons/utils";
import { useStreamStatuses } from "shared/hooks";

import "./styles.scss";

const { STREAM, BREAKOUTROOMS } = NamesOfParentRoutes;

const getTitle = (event: IEvent | null, showDate = true) => {
  if (!event) return "";
  const { community, starting_at } = event;
  const name = community?.name || "";
  const dayOfWeek = moment(starting_at).format("dddd");
  const month = moment(starting_at).format("MMMM");
  const dayOfMonth = moment(starting_at).format("Do");
  return (
    <>
      <div className="mobileWrapper-header-title-community">
        <span className="community-name">{name}</span>
        {showDate && <span>,</span>}
      </div>
      {showDate && <div className="emobileWrapper-header-title-date">{`${dayOfWeek} ${month} ${dayOfMonth}`}</div>}
    </>
  );
};

interface IMobileWrapper {
  isBlocked?: boolean;
  eventError?: ResponseError | null;
}

const MobileWrapper: React.FC<IMobileWrapper> = ({ isBlocked, eventError }) => {
  const { eventCode } = useParams<IRouteProps>();

  const history = useHistory();

  const [openJoinAlert, setOpenJoinAlert] = useState(false);

  const event = useSelector(selectors.getEvent());
  const nextEvent = useSelector(selectors.getRecurringEvent());
  const eventStatus = useSelector(selectors.getEventStatus());
  const participantsLimitExceeded = useSelector(selectors.isParticipantsLimitExceeded());
  const eventShortLink = useSelector(selectors.getEventShortLink());
  const allowEnded = useSelector(selectors.getAllowEnded());
  const changingStatus = useSelector(getChangingStatus());

  const eventTimeRange = useMemo(() => (event ? getEventTimeRange(event, "h:mm A") : ""), [event]);

  const [isStream, prevEventStatus] = useStreamStatuses();

  const showStream =
    eventStatus === EventStatus.live ||
    (eventStatus === EventStatus.lobby && prevEventStatus !== EventStatus.scheduled && changingStatus) ||
    (eventStatus === EventStatus.ended && prevEventStatus !== EventStatus.scheduled && allowEnded);

  const dispatch = useDispatch();

  const handleJoinApp = () => {
    if (eventShortLink) {
      window.open(eventShortLink, "_self");
    }
  };

  const tableClickHandler = useCallback(() => {
    setOpenJoinAlert(true);
  }, []);

  const hadleConfirmJoin = () => {
    handleJoinApp();
    setOpenJoinAlert(false);
  };

  const isLobby = useMemo(() => {
    return isMatchedPath(history.location.pathname, [
      `${STREAM}/:eventCode${BREAKOUTROOMS}`,
      `${STREAM}/:eventCode${BREAKOUTROOMS}/:roomCode`,
      `/:communityCode${STREAM}/:eventCode${BREAKOUTROOMS}`,
      `/:communityCode${STREAM}/:eventCode${BREAKOUTROOMS}/:roomCode`,
    ]);
  }, [history.location.pathname]);

  const isLiveEventStatus = eventStatus === EventStatus.live || isLobby || isStream;

  const eventContent = useMemo(() => {
    if (eventError?.code === HttpStatus.NOT_FOUND) {
      return (
        <MobileWrapperWarning
          text={`It seems this ${getEventTypeName(event?.type)} has been canceled`}
          classNames={classnames(nextEvent && "ended-event")}
        >
          {nextEvent && <MobileWrapperCountDown event={nextEvent} isNextEvent />}
        </MobileWrapperWarning>
      );
    }

    if (!event) {
      return null;
    }

    if (isLobby) {
      return <MobileWrapperLobby onClick={tableClickHandler} />;
    }

    if (eventStatus === EventStatus.ended && !allowEnded) {
      return (
        <MobileWrapperWarning text={`${getEventTypeName(event.type, true)} has ended`} classNames="ended-event">
          {nextEvent && <MobileWrapperCountDown event={nextEvent} isNextEvent />}
        </MobileWrapperWarning>
      );
    }
    if (isStream) {
      return <MobileWrapperStream event={event} onClick={tableClickHandler} showStream={showStream} />;
    }
    return <MobileWrapperCountDown event={event} />;
  }, [eventError?.code, event, isLobby, eventStatus, allowEnded, isStream, nextEvent, tableClickHandler, showStream]);

  useEffect(() => {
    const code = nextEvent?.code || event?.code;
    if (code) {
      dispatch(fetchEventShortLink.request({ code, avoidInstApp: false }));
    }
    return () => {
      dispatch(fetchEventShortLink.cancel());
    };
  }, [dispatch, nextEvent, event]);

  useEffect(() => {
    if (
      ((event && event.repeat !== EEventRepeat.never && event.status === EventStatus.ended) ||
        eventError?.code === HttpStatus.NOT_FOUND) &&
      !nextEvent
    ) {
      const eventIdentifier = event?.code || eventCode;
      if (eventIdentifier) {
        dispatch(getRecurringEvent.request({ eventIdentifier, shouldRedirect: false }));
      }
    }
  }, [event, dispatch, eventCode, eventError, nextEvent]);

  if (eventError?.code === HttpStatus.FORBIDDEN) {
    return <MobileWrapperWarning text={`This ${getEventTypeName(event?.type, true)} is for invited members only`} />;
  }

  if (isBlocked) {
    return (
      <MobileWrapperWarning
        text={
          participantsLimitExceeded
            ? `This ${getEventTypeName(event?.type)} is full`
            : `The host has removed you from this ${getEventTypeName(event?.type)}`
        }
      />
    );
  }
  return (
    <div className={classnames("mobileWrapper", { live: isLiveEventStatus })}>
      <AlertDialog
        open={openJoinAlert}
        title={eventStatus === EventStatus.lobby ? "Table" : "Watch Party"}
        message="For full interactive experience, please join us from Altar Live App"
        onConfirm={hadleConfirmJoin}
        onCancel={() => setOpenJoinAlert(false)}
        mode="confirm"
        confirmText="Join Us"
        cancelText="Cancel"
        variant="brown"
      />
      {(event || nextEvent) && (
        <header className="mobileWrapper-header">
          <div className="mobileWrapper-header-logo">
            <StyledAltarLogo fill="#C9C0C6" width={82} height={24} />
          </div>
          <div className="mobileWrapper-header-title">{getTitle(event || nextEvent, !!event)}</div>
        </header>
      )}
      <main className="mobileWrapper-content">
        {isLiveEventStatus && (
          <div className="mobileWrapper-content-header">
            <div className="event-time">{eventTimeRange}</div>
            <div className="event-name">{event?.name}</div>
          </div>
        )}
        <div
          className={classnames("mobileWrapper-content-body", {
            scheduled:
              eventStatus === EventStatus.scheduled ||
              (prevEventStatus === EventStatus.scheduled && !isLiveEventStatus),
          })}
        >
          {eventContent}
          {!isLiveEventStatus && (event || nextEvent) && (
            <div className="mobileWrapper-content-body-text">
              <span>For full interactive experience, please join us from</span>
              <strong>Altar Live App</strong>
            </div>
          )}
        </div>
      </main>
      {(event || nextEvent) && (
        <div className={classnames("app-button", { fixed: isLiveEventStatus })}>
          <Button variant="orange" width={296} onClick={handleJoinApp}>
            Open Altar Live App
          </Button>
        </div>
      )}
    </div>
  );
};

export default MobileWrapper;
