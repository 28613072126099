import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { Video, VideoMemberLabel } from "..";
import { AttendeePic } from "../AttendeePic";

import { MemberStreamInterface } from "containers/BreakoutRooms/interfaces";
import { isVideoTrackEnabled } from "containers/BreakoutRooms/utils/helperts";
import { IEventMemberResponse } from "containers/Sermons";
import { ClickOutsideHook } from "shared";

interface RoomMemberVideoProps {
  member_stream: MemberStreamInterface;
  streamMembers: IEventMemberResponse[];
  startChatHandler: (member_id: number) => void;
}

function RoomMemberVideo({ member_stream, startChatHandler, streamMembers }: RoomMemberVideoProps) {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const { stream, member, isMutted, isVideoDisabled, hasConnectionIssue } = member_stream;
  const [hasVideo, setStreamVideoState] = useState(!isVideoTrackEnabled(stream));

  const ref = useRef(null);
  const { isOutside } = ClickOutsideHook(ref);

  useEffect(() => {
    if (isOutside) {
      setIsOptionsOpen(false);
    }
  }, [isOutside]);

  useEffect(() => {
    stream.onaddtrack = () => {
      const hasVideo = !isVideoTrackEnabled(stream);
      // console.log(hasVideo);
      setStreamVideoState(hasVideo);
    };

    return () => {
      stream.onaddtrack = null;
    };
  }, [stream]);

  return (
    <div className="video-wrapper">
      <div className="video-container">
        <div className="videobox">
          <Video srcObject={stream} />
        </div>
        {member && <VideoMemberLabel muted={isMutted} memberName={`${member?.first_name} ${member?.last_name?.[0]}`} />}
        {member && (!hasVideo || isVideoDisabled) && <AttendeePic member={member} streamMembers={streamMembers} />}
        {member ? (
          <div className={classnames("direct-message-menu", { opened: isOptionsOpen })}>
            <div
              className={classnames("eventMemberListItem-btn", { opened: isOptionsOpen })}
              ref={ref}
              onClick={() => setIsOptionsOpen(prev => !prev)}
            >
              {isOptionsOpen && (
                <div className="eventMemberListItem-options">
                  <div onClick={() => startChatHandler(member?.id)} className="eventMemberListItem-options-item">
                    Direct Message
                  </div>
                </div>
              )}
              <hr />
              <hr />
              <hr />
            </div>
          </div>
        ) : null}
        {hasConnectionIssue && (
          <div className="connection-issue">
            <div className="information-hint">
              Connection interrupted <br /> Trying to reconnect...
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RoomMemberVideo;
