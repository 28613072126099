import React from "react";

import { IconBase } from "../IconBase";

import { IStyledSVG } from "shared/interfaces/StyledSVG";

export const StyledTwitterIcon: React.FC<IStyledSVG> = ({
  style = {},
  fill = "#fff",
  width = "20",
  height = "16",
  className = "",
  opacity = 1,
}) => (
  <IconBase
    fill="none"
    width={width}
    style={style}
    height={height}
    className={className}
    opacity={opacity}
    viewBox="0 0 20 16"
  >
    <path
      d="M19.7029 2.11366C19.0071 2.42199 18.2596 2.63033 17.4738 2.72449C18.2846 2.23932 18.8912 1.47572 19.1804 0.576161C18.4186 1.02865 17.5849 1.34716 16.7154 1.51783C16.1307 0.893549 15.3563 0.479766 14.5124 0.340723C13.6684 0.201679 12.8022 0.345154 12.0481 0.748872C11.2941 1.15259 10.6944 1.79396 10.3422 2.57341C9.99003 3.35287 9.90503 4.22679 10.1004 5.0595C8.55682 4.98199 7.04677 4.58079 5.66827 3.88192C4.28977 3.18305 3.07362 2.20213 2.09875 1.00283C1.76542 1.57783 1.57375 2.24449 1.57375 2.9545C1.57338 3.59366 1.73078 4.22303 2.03198 4.78677C2.33319 5.35051 2.76888 5.83119 3.30042 6.18616C2.68398 6.16655 2.08114 5.99998 1.54208 5.70033V5.75033C1.54202 6.64678 1.85211 7.51564 2.41974 8.20949C2.98736 8.90334 3.77756 9.37944 4.65625 9.557C4.0844 9.71176 3.48486 9.73456 2.90292 9.62366C3.15083 10.395 3.63375 11.0695 4.28406 11.5528C4.93437 12.036 5.71951 12.3038 6.52958 12.3187C5.15444 13.3982 3.45616 13.9837 1.70792 13.9812C1.39823 13.9812 1.08881 13.9632 0.78125 13.927C2.55581 15.068 4.62153 15.6735 6.73125 15.6712C13.8729 15.6712 17.7771 9.75616 17.7771 4.62616C17.7771 4.4595 17.7729 4.29116 17.7654 4.1245C18.5248 3.57531 19.1803 2.89524 19.7013 2.11616L19.7029 2.11366Z"
      fill={fill}
    />
  </IconBase>
);

export default StyledTwitterIcon;
