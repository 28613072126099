import { ComponentClass, Fragment, FunctionComponent, lazy } from "react";
import { RouteComponentProps, StaticContext } from "react-router";

import { ResetCompleted } from "../../Auth/components";
import { NameOfRoutes, NamesOfParentRoutes } from "../../../constants";
import { LandingPageContainer } from "../../LandingPage/containers";

const ResetContainer = lazy(() => import("../../Auth/containers/Reset/ResetContainer"));
const RegistrationContainer = lazy(() => import("../../Auth/containers/Registration/RegistrationContainer"));
const LoginContainer = lazy(() => import("../../Auth/containers/Login/LoginContainer"));
const ForgotContainer = lazy(() => import("../../Auth/containers/Forgot/ForgotContainer"));
const CommunityCreationContainer = lazy(
  () => import("../../Onboarding/containers/CommunityCreationContainer/CommunityCreationContainer"),
);
const InvitationContainer = lazy(() => import("../../Onboarding/containers/InvitationContainer/InvitationContainer"));
const SermonDetails = lazy(() => import("../../Sermons/containers/SermonDetails/SermonDetails"));
const SermonsContainer = lazy(() => import("../../Sermons/containers/Sermons/SermonsContainer"));
const EventContainer = lazy(() => import("../../Sermons/containers/EventContainer/EventContainer"));
const MembersList = lazy(() => import("../../Member/containers/MembersList/MembersList"));
const EmailConfirmation = lazy(() => import("../../User/components/EmailConfirmation/"));
const DiscussionsContainer = lazy(
  () => import("../../Discussions/containers/DiscussionsContainer/DiscussionsContainer"),
);
const CommunitySelectContainer = lazy(
  () => import("../../Onboarding/containers/CommunitySelectContainer/CommunitySelectContainer"),
);
const EventAuthStatus = lazy(() => import("../../Sermons/containers/EventAuthStatus/"));
const EventRecurringContainer = lazy(() => import("../../Sermons/containers/EventRecurring"));
const CommunityDashboardContainer = lazy(
  () => import("../../Community/containers/CommunityDashboardContainer/CommunityDashboardContainer"),
);
const CommunitySettingsContainer = lazy(
  () => import("../../Community/containers/CommunitySettingsContainer/CommunitySettingsContainer"),
);
const CommunityAnalyticsContainer = lazy(
  () => import("../../Community/containers/CommunityAnalyticsContainer/CommunityAnalyticsContainer"),
);

const {
  AUTH,
  COMMUNITIES,
  REGISTER,
  INVITE,
  ONBOARDING,
  CREATION,
  EVENT,
  EVENT_NEW,
  EVENT_EDIT,
  EVENTS,
  USERS,
  STREAM,
  COMMUNITY_SETTINGS,
  MEETING,
} = NamesOfParentRoutes;
const {
  SIGN_IN,
  RESET,
  RESET_COMPLETE,
  SIGN_UP,
  AUTH_BY_TOKEN,
  AUTH_BY_HASH,
  EVENT_AUTH_SUCCESS,
  FORGOT,
  EVENT_RECURRING,
} = NameOfRoutes;

export interface IRoute {
  path: string;
  exact?: boolean;
  component:
    | ComponentClass<any, any>
    | FunctionComponent<any>
    | ComponentClass<RouteComponentProps<any, StaticContext, any>, any>
    | FunctionComponent<RouteComponentProps<any, StaticContext, any>>
    | undefined;
  children?: IRoute[];
  pageTitle?: string;
}

export const publicRoutes: IRoute[] = [
  {
    path: "/",
    exact: true,
    component: LandingPageContainer,
    pageTitle: "",
    children: [],
  },
  {
    path: AUTH,
    exact: true,
    component: Fragment,
    children: [
      {
        path: SIGN_IN,
        exact: true,
        component: LoginContainer,
        pageTitle: "Login page",
        children: [],
      },
      {
        path: RESET_COMPLETE,
        exact: true,
        component: ResetCompleted,
        children: [],
      },
      {
        path: `${RESET}/:token`,
        exact: true,
        component: ResetContainer,
        pageTitle: "Reset password",
        children: [],
      },
      {
        path: SIGN_UP,
        exact: true,
        component: RegistrationContainer,
        pageTitle: "Registration",
        children: [],
      },
      {
        path: FORGOT,
        exact: true,
        component: ForgotContainer,
        pageTitle: "Forgot password",
        children: [],
      },
      {
        path: AUTH_BY_TOKEN,
        exact: true,
        component: LoginContainer,
        children: [],
      },
      {
        path: AUTH_BY_HASH,
        exact: true,
        component: LoginContainer,
        children: [],
      },
    ],
  },
  {
    path: `${REGISTER}/:token`,
    exact: true,
    component: InvitationContainer,
    pageTitle: "Registration",
    children: [],
  },
  {
    path: `${INVITE}/:token`,
    exact: true,
    component: InvitationContainer,
    pageTitle: "Invite",
    children: [],
  },
  {
    path: `${ONBOARDING}`,
    exact: true,
    component: InvitationContainer,
    pageTitle: "Onboarding",
    children: [],
  },
  {
    path: `${CREATION}`,
    exact: true,
    component: CommunityCreationContainer,
    pageTitle: "Creation",
    children: [],
  },
  {
    path: `${EVENT}${EVENT_RECURRING}/:eventId`,
    exact: true,
    component: EventRecurringContainer,
    pageTitle: "Recurring event",
    children: [],
  },
  {
    path: `${STREAM}${EVENT_AUTH_SUCCESS}`,
    exact: true,
    component: EventAuthStatus,
    pageTitle: "Stream",
  },
  {
    path: `${STREAM}/:eventCode`,
    component: EventContainer,
    pageTitle: "Stream",
  },
  {
    path: `${MEETING}/:eventCode`,
    component: EventContainer,
    pageTitle: "Meeting",
  },
  {
    path: `${USERS}/email-confirmation/:hash`,
    exact: true,
    component: EmailConfirmation,
    children: [],
  },
];
export const privateRoutes = [
  {
    path: "/",
    component: SermonsContainer,
    pageTitle: "Events & Meetings",
    exact: true,
  },
  {
    path: "/:communityCode/people",
    component: MembersList,
    pageTitle: "People",
  },
  {
    path: "/:communityCode/conversations",
    component: DiscussionsContainer,
    pageTitle: "Conversations",
  },
  {
    path: `/:communityCode${EVENTS}`,
    component: SermonsContainer,
    pageTitle: "Events & Meetings",
  },
  {
    path: `${COMMUNITY_SETTINGS}`,
    component: CommunitySettingsContainer,
    pageTitle: "Community Settings",
  },
  {
    path: `/:communityCode${COMMUNITY_SETTINGS}`,
    component: CommunitySettingsContainer,
    pageTitle: "Community Settings",
  },
  {
    path: `/:communityCode${EVENT}/:eventType${EVENT_NEW}`,
    component: SermonDetails,
    pageTitle: "Event",
    exact: true,
  },
  {
    path: `${COMMUNITIES}`,
    component: CommunitySelectContainer,
    pageTitle: "Find your community",
    children: [],
    exact: true,
  },
  {
    path: `${COMMUNITIES}${CREATION}`,
    component: CommunitySelectContainer,
    pageTitle: "Create a community",
    children: [],
    exact: true,
  },
  {
    path: `/:communityCode${EVENT}/:eventCode`,
    component: Fragment,
    pageTitle: "Event",
    children: [
      {
        path: "/",
        exact: true,
        component: SermonsContainer,
        pageTitle: "Event",
        children: [],
      },
      {
        path: EVENT_EDIT,
        exact: true,
        component: SermonDetails,
        pageTitle: "Edit Event",
        children: [],
      },
    ],
  },
  {
    path: `/:communityCode${STREAM}/:eventCode`,
    component: EventContainer,
    pageTitle: "Stream",
  },
  {
    path: "/:communityCode/dashboard",
    component: CommunityDashboardContainer,
    pageTitle: "Dashboard",
    exact: true,
  },
  {
    path: "/:communityCode/analytics",
    component: CommunityAnalyticsContainer,
    pageTitle: "Analytics",
    exact: true,
  },
];
