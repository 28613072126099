import "./index.scss";

import React, { useMemo } from "react";
import classnames from "classnames";
import { MinimalEmoji } from "stream-chat-react";
import { ReactionResponse } from "stream-chat";

interface ReactionItemProp {
  emojiConfig?: any;
  type: string;
  count: number;
  onClick: (e: React.BaseSyntheticEvent) => void;
  own_reactions?: ReactionResponse[];
}

const ReactionItem = (props: ReactionItemProp) => {
  const { type, count, emojiConfig, onClick, own_reactions = [] } = props;
  const currentEmoji = useMemo<MinimalEmoji>(() => {
    return emojiConfig.find((item: MinimalEmoji) => item.id === type);
  }, [emojiConfig, type]);

  const renderEmoji = useMemo(
    () => (
      <div
        className="emoji-reaction-icon"
        style={{
          backgroundImage: `url(${(currentEmoji as any).spriteUrl})`,
          backgroundSize: "200% 300%",
          backgroundPositionX: `${currentEmoji.sheet_x * 100}%`,
          backgroundPositionY: `${currentEmoji.sheet_y * 50}%`,
          width: "16px",
          height: "16px",
          display: "inline-block",
        }}
      />
    ),
    [currentEmoji],
  );

  const hasMyReaction = useMemo(() => {
    return !!own_reactions.find(reaction => reaction.type === type);
  }, [own_reactions, type]);

  if (!count) return null;

  return (
    <div className={classnames("emoji-reaction-item-wrapper", { "has-my-reaction": hasMyReaction })} onClick={onClick}>
      {renderEmoji}
      {count > 1 && <div className="emoji-reaction-item-count">{count}</div>}
    </div>
  );
};

export default React.memo(ReactionItem);
