import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Field, FieldAttributes, Formik } from "formik";
import * as Yup from "yup";
import { FormControlLabel, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { SettingsBlock } from "../";
import { NamesOfParentRoutes } from "../../../../../constants";
import { ERoles } from "../../../../Auth";
import { getMember } from "../../../../Member/store/selectors";

import {
  AlertDialog,
  Button,
  copyTextHandler,
  CustomFormikField,
  ImageUpload,
  SearchSelect,
  StyledCheckBox,
  SwitchControl,
  TimeZonePicker,
} from "shared";
import { LINKS } from "shared/constants/links";
import { checkRoles } from "utils/ACL";
import { actions, selectors } from "containers/Community/store";
import { prepareCommunityForm } from "containers/Community/utils";
import { ICommunityDialogValues } from "containers/Community/components/Community/CommunityDialog/CommunityDialog";
import { getImageBase64 } from "shared/utils/dataModifiers";
import { notificationActions } from "containers/Notifications/store/actions";
import { ICommunityItem } from "containers/Community";
import { communityValidationSchema } from "containers/Community/components/Community/CommunityDialog/formValidators";
import { CopyItem } from "containers/Sermons";
import community_default from "assets/icons/community_default.svg";

import "./index.scss";

const { COMMUNITIES } = NamesOfParentRoutes;

const GeneralSettings: React.FC = () => {
  const [openDeleteCommunityDialog, setOpenDeleteCommunityDialog] = useState(false);

  const formRef = useRef();
  const community = useSelector(selectors.getCommunity());
  const [initValues, setInitValues] = useState(prepareCommunityForm());
  const [communitiesNames, setCommunitiesNames] = useState<string[]>([]);
  const currentFormToSubmit = useSelector(selectors.getCommunitySettingsFormSubmit());

  const dispatch = useDispatch();
  const history = useHistory();

  const communityMembers = useSelector(getMember());
  const allCommunities = useSelector(selectors.getAllCommunities());

  const isAdmin = checkRoles([ERoles.admin]);
  const mainContactsList = useMemo(() => {
    return communityMembers
      .filter(({ should_create_as_manager }) => should_create_as_manager)
      .map(({ user_id, first_name, last_name }) => {
        return { id: user_id, first_name, last_name };
      });
  }, [communityMembers]);

  useEffect(() => {
    if (currentFormToSubmit && formRef.current) {
      (formRef?.current as any).handleSubmit();
    }
  }, [currentFormToSubmit, formRef]);

  useEffect(
    () => () => {
      dispatch(actions.changeCommunitySettingsForm(null));
    },
    [dispatch],
  );

  const handleSubmit = useCallback(
    (data: ICommunityDialogValues) => {
      if (community) {
        const { main_contact, invitation_link, image_url, ga_id, ...rest } = data;

        const main_contact_id = main_contact ? main_contact.id : undefined;
        const isImageChanged = !!community && community.image_url !== image_url;
        const image_base64 = getImageBase64(image_url, isImageChanged);
        const body = {
          ...rest,
          image_base64,
          main_contact_id,
          ga_id: ga_id || null,
        };

        dispatch(
          actions.updateCommunity.request({
            community: body,
            communityCode: community.code,
            communityId: community.id,
          }),
        );
        dispatch(actions.changeCommunitySettingsForm(null));
      }
    },
    [community, dispatch],
  );

  const handleDeleteCommunity = () => {
    if (community) {
      const callback = () => {
        history.push(COMMUNITIES);
      };
      dispatch(actions.deleteCommunity.request({ community_id: community.id, callback }));
      setOpenDeleteCommunityDialog(false);
    }
  };

  useEffect(() => {
    if (allCommunities.length) {
      const names = allCommunities.map((community: ICommunityItem) => community.name.trim());
      names.length && setCommunitiesNames(names);
    }
  }, [allCommunities]);

  useEffect(() => {
    return setInitValues(prepareCommunityForm(community));
  }, [community]);

  useEffect(() => {
    if (community) {
      dispatch(actions.getCommunityUsers.request(community.id));
    }
  }, [community, dispatch]);

  const validationSchema = useMemo(
    () =>
      communityValidationSchema.shape({
        name: Yup.string()
          .strict(true)
          .max(70, "Name field is max 70 characters")
          .trim("Name must be without leading and trailing whitespace")
          .required("Name is required")
          .test("name-is-taken", "Community name is already taken", (value: string) => {
            const name = value && value.trim();
            if (community) {
              return name !== initValues.name.trim() ? !communitiesNames.includes(name) : true;
            }
            return value ? !communitiesNames.includes(name) : true;
          }),
      }),
    [communitiesNames, community, initValues],
  );

  const copyInvitationHandler = useCallback(
    (value: string) => {
      if (value) {
        copyTextHandler(value);
        dispatch(notificationActions.success("Invitation link copied to the clipboard"));
      }
    },
    [dispatch],
  );

  const copyWebsiteHandler = useCallback(
    (value: string) => {
      if (value) {
        copyTextHandler(value);
        dispatch(notificationActions.success("Community website copied to the clipboard"));
      }
    },
    [dispatch],
  );

  const imageUploadInfo = useMemo(
    () => (
      <div className="image-information-wrapper">
        <div className="image-information-title">Choose Image</div>
      </div>
    ),
    [],
  );

  return (
    <div className="general-settings-wrapper">
      <AlertDialog
        open={openDeleteCommunityDialog}
        title="Delete Community"
        message="Are you sure that you want to delete this Altar Live Community? Once you delete it, events and meetings data will be lost."
        onConfirm={handleDeleteCommunity}
        onCancel={() => setOpenDeleteCommunityDialog(false)}
        mode="confirm"
        confirmText="Yes, Delete"
        confirmClassName="delete-community-button"
      />
      <Formik
        innerRef={formRef as any}
        validationSchema={validationSchema}
        initialValues={initValues}
        onSubmit={values => handleSubmit(values)}
        enableReinitialize
        validateOnChange
      >
        {({ setFieldValue, values, handleBlur, errors, handleChange, touched }) => {
          const changeVisibilityHandler = () => setFieldValue("visibility", !values.visibility);

          return (
            <>
              <SettingsBlock title="Community Details">
                <div className="left-side">
                  <CustomFormikField
                    fullWidth
                    required
                    label="Community Name"
                    placeholder="Start typing"
                    name="name"
                    errors={errors}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div className="settings-community-website">
                    <CustomFormikField
                      fullWidth
                      label="Community Website"
                      placeholder="Start typing"
                      name="website"
                      errors={errors}
                      value={values.website}
                      onChange={handleChange}
                      className="communityDialogEdit-item"
                      onBlur={handleBlur}
                    />
                    <button onClick={() => values.website && copyWebsiteHandler(values.website)} />
                  </div>
                  <Grid className="communityDialogEdit-invitation">
                    <p className="communityDialogEdit-invitation-title">Invitation Link</p>
                    <CopyItem
                      copyText={values.invitation_link}
                      onClick={() => copyInvitationHandler(values.invitation_link)}
                    />
                    <p className="communityDialogEdit-invitation-description">
                      Share this link to invite people to self-register to your community.
                    </p>
                  </Grid>
                  <Grid item className="communityDialogEdit-item communityDialogEdit-visibility">
                    <p className="communityDialogEdit-title">Visibility</p>
                    <SwitchControl
                      checked={values.visibility}
                      textOn="Public"
                      textOff="Private"
                      width={175}
                      onChange={changeVisibilityHandler}
                    />
                  </Grid>
                  <Grid item className="communityDialogEdit-item communityDialogEdit-verified">
                    {isAdmin ? (
                      <FormControlLabel
                        control={
                          <StyledCheckBox
                            withBorder
                            checked={values.is_verified}
                            onChange={handleChange}
                            name="is_verified"
                          />
                        }
                        label="Allow RTMP Streaming"
                      />
                    ) : null}
                  </Grid>
                </div>
                <div className="right-side">
                  <div className="image-selector-wrapper">
                    <ImageUpload
                      showOptions
                      maxMb={2}
                      value={values.image_url}
                      onChange={handleChange("image_url")}
                      backgroundImage={`url(${community_default})`}
                      variant="square"
                      after={imageUploadInfo}
                    />
                  </div>
                </div>
              </SettingsBlock>
              <SettingsBlock title="Address Info">
                <div className="left-side">
                  <Grid item className="communityDialogEdit-item">
                    <CustomFormikField
                      fullWidth
                      label="Address 1"
                      placeholder="Start typing"
                      name="address_1"
                      errors={errors}
                      value={values.address_1 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item className="communityDialogEdit-item">
                    <CustomFormikField
                      fullWidth
                      label="Address 2"
                      placeholder="Start typing"
                      name="address_2"
                      errors={errors}
                      value={values.address_2 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item className="communityDialogEdit-item">
                    <SearchSelect
                      className="communitySelect"
                      title="Main Contact"
                      items={mainContactsList}
                      placeholder="Select User"
                      value={values.main_contact}
                      onSelect={value => setFieldValue("main_contact", value)}
                      error={touched.main_contact && !!errors.main_contact}
                      errorText={errors.main_contact}
                    />
                  </Grid>
                  <Grid item className="communityDialogEdit-item">
                    <CustomFormikField
                      fullWidth
                      label="Town or City"
                      placeholder="Start typing"
                      name="city"
                      errors={errors}
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </div>
                <div className="right-side">
                  <Grid item className="communityDialogEdit-item">
                    <CustomFormikField
                      fullWidth
                      label="State"
                      placeholder="Start typing"
                      name="state"
                      errors={errors}
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item className="communityDialogEdit-item">
                    <CustomFormikField
                      fullWidth
                      label="Country"
                      placeholder="Start typing"
                      name="country"
                      errors={errors}
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item className="communityDialogEdit-item">
                    <CustomFormikField
                      fullWidth
                      label="Zip or Postal Code"
                      placeholder="Start typing"
                      name="zipcode"
                      errors={errors}
                      value={values.zipcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item className="communityDialogEdit-item">
                    <Field name="timezone">
                      {({ field, form, meta }: FieldAttributes<any>) => (
                        <TimeZonePicker {...field} {...form} {...meta} />
                      )}
                    </Field>
                  </Grid>
                </div>
              </SettingsBlock>
              <SettingsBlock title="Google Analytics Tracking ID">
                <Grid className="google-analytics-block">
                  <p className="google-analytics-block">
                    Want to receive analytics for your Altar Live community? This ID is unique to each site you want to
                    track separately. To get a Web Property ID,&nbsp;
                    <a href={LINKS.ga} target="_blank" rel="noopener noreferrer">
                      register your site with Google Analytics
                    </a>
                    &nbsp;or if you are already registered, go to your Google Analytics settings page to find the ID
                    next to every site profile (e.g. UA-xxxxxxx-yy, G-xxxxxxx-yy).
                  </p>
                  <CustomFormikField
                    placeholder="Type your tracking ID here"
                    name="ga_id"
                    value={values.ga_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors}
                  />
                </Grid>
              </SettingsBlock>
            </>
          );
        }}
      </Formik>
      <SettingsBlock
        title="Permanently Delete this Community"
        bottomDivider={false}
        description="The Community will no longer be available, and all data will be permanently deleted. Once you delete this Community, this information can no longer be recovered"
      >
        <Button variant="red" type="button" width={344} height={40} onClick={() => setOpenDeleteCommunityDialog(true)}>
          Delete Community
        </Button>
      </SettingsBlock>
    </div>
  );
};

export default GeneralSettings;
