import React, { ChangeEvent, useCallback, useMemo } from "react";
import { FormikErrors } from "formik";
import classnames from "classnames";

import StreamRelayInput from "../StreamRelayInput";

import { IEventRelayItem, StreamRelayFields } from "containers/Sermons/interfaces";
import { CustomSwitchControl, StyledLink } from "shared/components";
import { LINKS } from "shared/constants/links";

import "./styles.scss";

interface StreamRelaySocialProps {
  value: IEventRelayItem;
  errors: FormikErrors<IEventRelayItem>;
  onPaste: (field: StreamRelayFields) => void;
  onToggle: () => void;
  handleChange: (value: IEventRelayItem) => void;
}

const StreamRelaySocial: React.FC<StreamRelaySocialProps> = ({ onPaste, handleChange, onToggle, value, errors }) => {
  const { stream_key, stream_url, enabled } = value;

  const onChange = useCallback(
    (field: StreamRelayFields) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const data = e?.target.value || "";
      handleChange({ ...value, [field]: data });
    },
    [value, handleChange],
  );

  const isSuccessful = useMemo(() => {
    return stream_key && stream_url && !errors;
  }, [stream_key, stream_url, errors]);

  return (
    <div className={classnames("stream-relay-social", { open: enabled })}>
      <div className="stream-relay-social-switcher">
        <CustomSwitchControl checked={enabled} theme="orange" label={value.type} onChange={onToggle} />
        {isSuccessful && enabled ? (
          <div className="stream-relay-social-success">The channel was successfully added</div>
        ) : (
          <StyledLink className="stream-relay-social-link" href={LINKS.rtpm}>
            Where do I find RTMP parameters?
          </StyledLink>
        )}
      </div>
      <div className="stream-relay-social-input">
        <StreamRelayInput
          name="stream_url"
          value={stream_url}
          placeholder="ex. rtmp://live.restream.io/live"
          label="Stream RTMP URL"
          handleButtonClick={() => onPaste("stream_url")}
          handleChange={onChange("stream_url")}
          buttonText="Paste"
          errors={errors}
        />
        <StreamRelayInput
          name="stream_key"
          value={stream_key}
          inputType="password"
          label="Stream Key"
          handleButtonClick={() => onPaste("stream_key")}
          buttonText="Paste"
          placeholder="ex. gr534G9ss00$3g9Nm>!0^"
          handleChange={onChange("stream_key")}
          errors={errors}
        />
      </div>
    </div>
  );
};

export default StreamRelaySocial;
