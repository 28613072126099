import React from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { NamesOfParentRoutes } from "../../../../constants";
import { RedirectLink } from "../RedirectLink";

import { AuthWrapper, AuthAsideLogo, AuthMain } from "shared";

import "../styles/auth.scss";

const ResetCompleted: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <AuthWrapper>
      <AuthAsideLogo />
      <AuthMain>
        <div className={"auth"}>
          <div className={"auth-root"}>
            <Grid container={true} justify="flex-start">
              <Grid item={true}>
                <h2 className={"auth-title"}>{t("New password set")}</h2>
              </Grid>
            </Grid>
            <Grid container={true} justify="center">
              <Grid item={true}>
                <div className={"auth-text auth-text-resetCompleted"}>
                  {t("You are reset your password. Now you can log in")}
                </div>
              </Grid>
            </Grid>
            <Grid container={true} justify="center">
              <Grid item={true} className={"auth-linkContainer"}>
                <RedirectLink className={"auth-linkContainer-link"} to={NamesOfParentRoutes.AUTH}>
                  Login
                </RedirectLink>
              </Grid>
            </Grid>
          </div>
        </div>
      </AuthMain>
    </AuthWrapper>
  );
};

export default ResetCompleted;
