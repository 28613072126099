import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { IChannel } from "../interfaces";
import { getStreamChannels } from "../store/selectors";

import { getChannelName } from "shared/utils/getStream";
import { authentificated, getMember } from "containers/Auth/store/selectors";

type TChatName = { first_name: string; last_name: string } | null;

export const usePersonalChatName = (currentChannel?: IChannel): TChatName => {
  const streamChannels = useSelector(getStreamChannels());
  const member = useSelector(getMember());
  const isAuthenticated = useSelector(authentificated());

  const [personalChatName, setPersonalChatName] = useState<TChatName>(null);

  const currentGetStreamChannel = useMemo(() => {
    return streamChannels?.find(channel => channel.id === currentChannel?.id);
  }, [streamChannels, currentChannel]);

  useEffect(() => {
    if (currentChannel?.is_group) {
      return setPersonalChatName(null);
    }
    if (member && currentChannel && currentGetStreamChannel) {
      const { members } = currentChannel;
      const interlocutor = members.find(m => m.id !== member.id);
      if (interlocutor) {
        const { first_name, last_name } = interlocutor;
        return setPersonalChatName({ first_name, last_name });
      }

      const channelName = getChannelName(currentChannel, member);
      if (!channelName) {
        return setPersonalChatName(null);
      }
      const [first_name = " ", last_name = " "] = channelName.split(" ").filter(Boolean);
      return setPersonalChatName({ first_name, last_name });
    }
    if (!isAuthenticated && currentChannel) {
      const { members } = currentChannel;
      const { first_name = "", last_name = "" } = members[0];
      return setPersonalChatName({ first_name, last_name });
    }
    return setPersonalChatName(null);
  }, [member, currentChannel, currentGetStreamChannel, isAuthenticated]);

  return personalChatName;
};
