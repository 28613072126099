import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import { RoomInterface } from "../../../interfaces";
import { actions } from "../../../store";

import { AlertDialog, StyledTextField } from "shared";

import "./styles.scss";

type Props = {
  room: RoomInterface | null;
  opened: boolean;
  onClose: () => void;
  memberId?: number;
};

const TableRenamePopup: React.FC<Props> = ({ room, opened, onClose, memberId }) => {
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const updateRoom = (values: Pick<RoomInterface, "id" | "name">) => {
    dispatch(actions.updateRoom({ ...values, memberId }));
    onClose();
  };

  useEffect(() => {
    if (opened && room) {
      inputRef?.current?.focus();
    }
  }, [room, opened]);

  return room ? (
    <Formik
      validationSchema={Yup.object({
        name: Yup.string().max(30, "Must be 30 characters or less").nullable(),
      })}
      initialValues={{ id: room.id, name: room.name || "" }}
      enableReinitialize
      onSubmit={updateRoom}
    >
      {({ values, errors, handleChange, handleSubmit }) => {
        return (
          <AlertDialog
            open={opened}
            title="Rename Table"
            mode="confirm"
            confirmText="save"
            onConfirm={handleSubmit}
            onCancel={onClose}
            variant="brown"
            hasForm
          >
            <Form className="table-rename-popup">
              <StyledTextField
                inputRef={inputRef}
                fullWidth
                label="Table Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                errors={errors}
              />
            </Form>
          </AlertDialog>
        );
      }}
    </Formik>
  ) : null;
};

export default TableRenamePopup;
