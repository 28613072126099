import { RouteComponentProps } from "react-router";

import { IContentShape } from "../../App/interfaces";
import { EventMemberTypes } from "../constants";
import { ICommunity } from "../../Community/interfaces";
import { IAnnouncementInteraction } from "./Announcement";

import { IFile } from "shared/interfaces/File";
import { IUser } from "containers/Auth";
import { BaseMember, IGreeter, IMember } from "containers/Member";

export interface IExtensibleSteps {
  isExpanded?: boolean;
  defaultExpanded?: boolean;
  onChange?: (event: React.ChangeEvent<any>, newExpanded: boolean) => void;
  disabledChecks?: boolean;
  stepIsDone?: boolean;
  primaryTitle?: string;
  participants?: string[];
}

export interface ISelectMemberData {
  member?: BaseMember;
  onRemove?: () => void;
  onSelect?: (member: BaseMember) => void;
}

export interface IInformMemberData {
  community_member: IIntroductionMemberCommunity;
  onSendEmail: () => void;
  onTextChange: (text: string, isTitle?: boolean) => void;
}

export interface ISelectMembers {
  selectData: ISelectMemberData[];
  disableRemoveMember?: boolean;
}

export interface IInform {
  informData: (IInformMemberData | null)[];
}

export interface IInformEmail {
  isActive: boolean;
  informData: IInformMemberData | null;
}

export interface IPermissionCard {
  isActive: boolean;
  member: IIntroductionMemberCommunity | null;
}

export interface IIntroduce {
  isActive: boolean;
  onSendEmail: () => void;
  onTextChange: (text: string) => void;
  textValue: string | null;
}

export interface IMemberCard {
  member: BaseMember;
  onRemove?: () => void;
  disableRemoveMember?: boolean;
}

export interface IEmptyMemberCard {
  onSelect?: (member: BaseMember) => void;
}

export interface INoteItem {
  note: INote;
  onRemove: () => void;
}

export interface INotes {
  notes?: INote[];
}

export interface INoteValue {
  text: string;
  is_public: boolean;
}

export interface IIntroductionMemberCommunityPreview {
  is_accepted: boolean | null;
  is_sent: boolean;
  member_community: BaseMember;
}

export enum SermonRestriction {
  all = "all",
  members = "members",
  invited = "invited",
}

export enum EventType {
  meeting = "meeting",
  liveService = "live_service",
  stream = "stream",
}

export enum EventStatus {
  initial = "initial",
  draft = "draft",
  scheduled = "scheduled",
  started = "started",
  live = "live",
  lobby = "lobby",
  ended = "ended",
}

export enum EEventPreview {
  auditorium_rooms = "auditorium_rooms",
  lobby_rooms = "lobby_rooms",
  classrooms = "classrooms",
  preEventSlides = "preEventSlides",
  preEventVideo = "preEventVideo",
}

export interface IEventPreview {
  id: number;
  name: string;
  subject?: string;
  starting_at: string;
  ending_at: string;
  setup_time: number;
  post_event_time: number;
  restriction?: SermonRestriction;
  host: {
    id: number;
    first_name: string;
    last_name: string;
    image_url: string;
    role: string;
  };
  attendees: number[];
  type: EventType;
}

export interface IIntroductionMemberCommunity {
  introduction_id: number | null;
  member_community_id?: number | null;
  mail_text: string | null;
  mail_title: string | null;
  is_accepted: boolean | null;
  is_sent: boolean;
  member_community: BaseMember;
}

export interface INote {
  id: number;
  created_at: string;
  is_public: boolean;
  text: string | null;
  user: Partial<IUser>;
}

export interface IIntroduction {
  id: number | null;
  is_sent: boolean;
  mail_text: string | null;
  community_members: (IIntroductionMemberCommunity | null)[];
  user: IUser | null;
}

export interface ISteps {
  step1: boolean;
  step2: boolean;
  step3: boolean;
  step4: boolean;
  notes: boolean;
}

export interface IFetchSermonShape extends IContentShape {
  community_id: number;
  is_default?: boolean;
}

export interface ICreateIntroductionShape {
  member_community_ids: number[];
}

export interface ISendMemberEmailShape {
  community_members: (IIntroductionMemberCommunity | null)[];
  introduction_id: number;
  member_community_id: number;
}

export interface ISendMemberEmailRequest {
  introduction_id: number;
  member_community_id: number;
  mail_text: string;
  mail_title: string;
}

export interface ISendIntroduceEmailShape {
  mail_text: string;
  id: number;
}

export interface IEventCard {
  sermon: IEvent;
}

export interface TabPanelProps {
  index: any;
  value: any;
}

export enum PresenterType {
  camera = "camera",
  member = "member",
  participant = "participant",
}

export enum SermonDetailsPageMode {
  create = "create",
  edit = "edit",
  view = "view",
}

export enum SermonRoles {
  host = "host",
  producer = "producer",
  presenter = "presenter",
  member = "member",
  greeter = "greeter",
  greeterHost = "greeterHost",
}

export interface IPresenter {
  type: PresenterType;
  memberId: number | string;
  pin: string;
  member?: IMember;
}

export enum EEventScheduleDay {
  sunday = "SU",
  monday = "MO",
  tuesday = "TU",
  wednesday = "WE",
  thursday = "TH",
  friday = "FR",
  saturday = "SA",
}

export enum EFrequency {
  yearly = "YEARLY",
  monthly = "MONTHLY",
  weekly = "WEEKLY",
  daily = "DAILY",
}

export interface IEventSchedule {
  id: number;
  day_of_week: EEventScheduleDay[];
  frequency: EFrequency;
  interval: number;
  occurrences: number | null;
  until: string | null;
  scheduled_until?: string | null;
  bysetpos?: number | null;
}

export interface ICreateEventSchedule extends Omit<IEventSchedule, "id" | "meeting_id"> {
  id?: number;
}

export enum EEventRepeat {
  never = "never",
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
  lastOfMonthly = "lastOfMonthly",
  custom = "custom",
}
export enum EEventReCurringEnds {
  never = "never",
  on = "on",
  after = "after",
}

export enum EPreEventType {
  none = "none",
  video = "video",
  images = "images",
}

export interface ICreateSermonData {
  name: string;
  subject: string;
  startTime: string;
  endTime: string;
  setup_time: number;
  post_event_time: number;
  restriction: SermonRestriction;
  attendees: number[];
  communityId: number;
  producer?: number;
  host?: number;
  presenters: IPresenter[];
  type: EventType;
  slides_bulletin_name?: string;
  slides?: IEventSlide[] | null;
  status: EventStatus;
  repeat: EEventRepeat;
  schedule: ICreateEventSchedule | null;
  is_default?: boolean;
  timezone: string;
  greeters?: Partial<Pick<IGreeter, "id" | "is_admin" | "title">>[];
  host_notes?: string | null;
  last_editor_host_notes?: string | null;
  leave_event_url?: string | null;
  start_event_option?: string | null;
}

export interface IUpdateSermonData extends ICreateSermonData {
  id: number;
  scope?: EEventChangeScope;
}

export enum Notifications {
  created = "created",
  published = "published",
  updated = "updated",
  meeting = "meeting",
  liveService = "live service",
  stream = "event",
  event = "event",
}

export interface IChangeEventStatusOptions {
  moveTo?: string;
  roomCode?: string;
  doNotNotify?: boolean;
}

export interface CRUDEventBase {
  callback?: () => void;
  options?: {
    action?: Notifications;
    type?: Notifications;
  };
  silently?: boolean;
  changeStatusOptions?: IChangeEventStatusOptions;
}

export interface ICreateSermon extends CRUDEventBase {
  data: ICreateSermonData;
  callback?: (data?: any) => void;
}

export interface ICreateMultipleSermons extends ICreateSermon {
  names: string[];
}

export interface IRemoveEvent extends CRUDEventBase {
  eventId: number;
  scope?: EEventChangeScope;
}

export interface IDuplicateEvent extends CRUDEventBase {
  eventId: number;
  scope?: EEventChangeScope;
}

export interface IUpdateSermon extends CRUDEventBase {
  data: Partial<IUpdateSermonData>;
}

export interface ICheckSlotQuery {
  startTime: string;
  endTime: string;
  setup_time: number;
  post_event_time: number;
  communityId: number;
  eventId?: number;
}

export interface IEventContainerType extends RouteComponentProps {
  fetchSermon: (...args: any[]) => any;
  sermon: IEvent | null;
}

export interface IEventSlide {
  id: number | null;
  name: string;
  speaker_member_id: number | null;
  starting_at: string;
  content: string;
  meeting_id: number | null;
  type?: SlideType;
  show_in_agenda?: boolean;
}

export enum IntegrationTypes {
  zoom = "zoom",
  facebook = "facebook",
  youtube = "youtube",
}

export interface IIntegrations {
  type: IntegrationTypes;
  link?: string;
  password?: string;
  enabled: boolean;
}

export enum EZoomMeetingRecordingFileStatus {
  COMPLETED = "completed",
  PROCESSING = "processing",
}

export enum EZoomMeetingFileType {
  MP4 = "MP4",
  M4A = "M4A",
  TIMELINE = "TIMELINE",
  TRANSCRIPT = "TRANSCRIPT",
  CHAT = "CHAT",
  CC = "CC",
}

export enum EZoomMeetingRecordingType {
  SSWSVCC = "shared_screen_with_speaker_view(CC)",
  SSWSV = "shared_screen_with_speaker_view",
  SSWGV = "shared_screen_with_gallery_view",
  SV = "speaker_view",
  GV = "gallery_view",
  SS = "shared_screen",
  AO = "audio_only",
  AT = "audio_transcript",
  CF = "chat_file",
  T = "TIMELINE",
  AS = "active_speaker",
}

export enum EMeetingEventType {
  REACTION = "reaction",
  SLIDE = "slide",
}

export interface IRecording {
  id: number;
  zoom_id: string;
  meeting_id: number;
  started_at: string;
  ended_at: string;
  download_url: string;
  play_url: string;
  status: EZoomMeetingRecordingFileStatus;
  file_type: EZoomMeetingFileType;
  recording_type: EZoomMeetingRecordingType;
}

export interface IMeetingEvent {
  id: number;
  meeting_id: number;
  created_at: string;
  type: EMeetingEventType;
  value: string;
}

export enum EEventStreamType {
  FB = "facebook",
  YOUTUBE = "youtube",
  VIMEO = "vimeo",
  VIMEO_LIVE = "vimeo_live",
  // TWITCH = "twitch",
  RESI = "resi",
  CASTR = "castr",
  CUSTOM = "custom",
  ALTAR = "altar",
  ALTAR_LIVE = "altar_live",
  m3u8 = "m3u8",
  dacast = "dacast",
  churchstreaming = "churchstreaming",
  STREAMING_CHURCH_TV = "streaming_church_tv",
}

export const PRE_EVENT_VIDEO = "pre_event_video";

export enum EEventRelayType {
  FB = "facebook",
  YOUTUBE = "youtube",
  AWS = "aws",
}

export interface EStreamSettings {
  stream_url: string;
  stream_key: string;
}

export interface IEventRelayItem {
  id?: number;
  type: EEventRelayType;
  stream_url: string;
  stream_key: string;
  enabled: boolean;
}

export enum EEventVideoType {
  live = "live",
  pre_recorded = "pre_recorded",
}

export interface IEventVideoSettings {
  controls: boolean;
  muted: boolean;
  playing: boolean;
  volume: number;
  pip: boolean;
}

export enum EAuditoriumRoomsType {
  row = "row",
  table = "table",
  none = "none",
}

export interface IEvent {
  id: number;
  code: string | null;
  meeting_id: number;
  meeting_password: string | null;
  name: string;
  subject: string;
  starting_at: string;
  ending_at: string;
  setup_time: number;
  post_event_time: number;
  restriction: SermonRestriction;
  community_id: number;
  community?: ICommunity;
  producer_id: number;
  host_id: number | null;
  host: IMember | null;
  attendees: IMember[];
  presenters: IPresenter[];
  producer: IMember;
  greeters: IGreeter[];
  greeter_message?: string;
  started: boolean;
  type: EventType;
  integrations: IIntegrations[];
  status: EventStatus;
  recordings: IRecording[];
  join_link?: string;
  speaker_name: string | null;
  events: IMeetingEvent[];
  stream_type: EEventStreamType | null;
  video_type: EEventVideoType | null;
  stream_url: string | null;
  image_url: string;
  pre_images: string[];
  pre_images_interval: number | null;
  seats_per_table: number | null;
  is_archived: boolean;
  repeat: EEventRepeat;
  origin_id: number | null;
  schedule?: IEventSchedule;
  was_started?: boolean;
  is_featured?: boolean;
  showTour?: boolean;
  files?: IFile[];
  relays?: IEventRelayItem[] | null;
  stream_settings: EStreamSettings | null;
  class_room_settings: ClassRoomSettings | null;
  classrooms: ClassRoomSettings[];
  polls: IPoll[];
  announcements: IAnnouncementInteraction[];
  is_default: boolean;
  show_on_landing: boolean;
  auditorium_rooms_count: number;
  lobby_rooms_count: number;
  is_locked: boolean;
  has_platform_tour?: boolean;
  tour_title?: string;
  tour_description?: string;
  pre_event_type?: EPreEventType | null;
  pre_event_video_url?: string | null;
  is_pre_event_video_loop?: boolean;
  is_pre_event_video_autoplay?: boolean;
  host_notes?: string;
  last_editor_host_notes?: string;
  restricted_generalChat: boolean;
  start_with_audio_muted: boolean;
  start_with_video_muted: boolean;
  auditorium_rooms_type: EAuditoriumRoomsType;
  leave_event_url: string | null;
  start_event_option: string | null;
}

export interface IEventIntegrations
  extends Pick<IEvent, "status" | "type" | "integrations" | "name" | "subject" | "starting_at" | "ending_at"> {
  id: number;
}

export interface IEventStateType {
  sermon: IEvent | null;
}

export interface ICustomNameData {
  value: string;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: { customName: string } | null;
  setCustomNameError: (error: null | string) => void;
  setCustomName: (name: string) => void;
}

export interface ICountdown {
  onStartMeeting: () => void;
  permissionsGranted: boolean;
  customNameData: ICustomNameData;
  logIn: () => void;
  signUp: () => void;
}

export enum EEventChangeScope {
  current = "current",
  following = "following",
  all = "all",
}

export interface ClassRoomSettings {
  room_size: number;
  name?: string;
  code?: string;
  meeting_id?: number;
  id?: number;
  description?: string;
  members?: IMember[];
  is_main: boolean;
}

export interface IClassRoomValue {
  classRoomItems: ClassRoomSettings[];
  mainClassroom: ClassRoomSettings | null;
  enabled: boolean;
}
export interface IAuditoriumRoomsValue {
  auditoriumRoomsCount: number;
  auditoriumRoomsEnabled: boolean;
}

export interface ILobbyRoomsValue {
  lobbyRoomsCount: number;
  lobbyRoomsEnabled: boolean;
}

export interface IEventForm {
  id: number | null;
  code: string | null;
  name: string;
  subject: string;
  starting_at: string;
  ending_at: string;
  setup_time: number;
  post_event_time: number;
  restriction: SermonRestriction;
  host: IMember | null;
  producer: IMember | null;
  greeters: IGreeter[];
  greeter_message?: string;
  presenters: IPresenter[];
  attendees: IMember[];
  is_locked: boolean;
  type: EventType;
  integrations: string[];
  integrationsData: IIntegrations[];
  status: EventStatus;
  speaker_name: string | null;
  stream_type: EEventStreamType | null;
  video_type: EEventVideoType | null;
  stream_url: string | null;
  vimeo_token: string | null;
  image_url: string;
  pre_images: string[];
  pre_images_interval: number | null;
  seats_per_table: number | null;
  repeat: EEventRepeat;
  schedule: ICreateEventSchedule;
  recurring_ends_on: EEventReCurringEnds;
  is_featured: boolean;
  showTour: boolean;
  files?: IFile[];
  relays?: IEventRelayItem[] | null;
  stream_settings: EStreamSettings | null;
  class_room_settings?: ClassRoomSettings | null;
  is_default?: boolean;
  show_on_landing: boolean;
  classrooms: IClassRoomValue;
  auditorium_rooms: IAuditoriumRoomsValue;
  lobby_rooms: ILobbyRoomsValue;
  has_platform_tour?: boolean;
  tour_title?: string | null;
  tour_description?: string | null;
  pre_event_type?: EPreEventType | null;
  pre_event_video_url?: string | null;
  is_pre_event_video_loop?: boolean;
  is_pre_event_video_autoplay?: boolean;
  host_notes?: string | null;
  last_editor_host_notes?: string | null;
  restricted_generalChat: boolean;
  start_with_audio_muted: boolean;
  start_with_video_muted: boolean;
  auditorium_rooms_type: EAuditoriumRoomsType;
  polls: IPoll[];
  announcements: IAnnouncementInteraction[];
  leave_event_url: string | null;
  leave_event_action: string | null;
  start_event_option: string | null;
  was_started?: boolean;
}

export interface IEventDetails {
  mode: SermonDetailsPageMode;
  openPreview?: () => void;
  setActiveSlide?: (uid: number) => void;
}

export interface IGenerateSignatureParams {
  meetingNumber: string;
  role: 0 | 1;
}

export interface IMeetingSignature {
  signature: string;
}

export interface IEventMemberBase {
  memberId: number;
  guestName: string;
  type: EventMemberTypes;
  member?: IMember;
}

export interface IEventMember extends IEventMemberBase {
  zoomId: string;
}

export interface IEventMemberResponse extends IEventMemberBase {
  seat: string | null;
  color?: string;
}

export interface IStreamEventMember extends IEventMemberResponse {
  image_url?: string | null;
  first_name?: string;
  last_name?: string;
  color?: string;
  withSeat?: boolean;
}

export interface ISlideContentBlogImageUrlsMatch {
  startAt: number;
  matchStr: string;
}

export enum PreviewModes {
  "fullscreen",
  "half_screen",
  "mobile",
}

export type TPreviewModes = keyof typeof PreviewModes;

export enum SlideType {
  text = "text",
  image = "image",
  video = "video",
}

export type StreamRelayFields = "stream_url" | "stream_key";

export enum PollStatus {
  published = "published",
  closed = "closed",
  draft = "draft",
  scheduled = "scheduled",
}

export interface IPollVote {
  member_id: number;
  option_id: number;
}

export interface IPollRead {
  meeting_id: number;
  poll_ids: number[];
}
export interface IPollReadResponse {
  id: number;
  member_id: number | null;
  anonymous_id: string | null;
  poll_id: number;
  created_at: string;
}

export interface IPollOption {
  id?: number;
  option_text: string;
  vote_results: IPollVote[];
}

export interface IPoll {
  id?: number;
  title: string;
  poll_question: string;
  multiple_answers: boolean;
  show_results: boolean;
  options: IPollOption[];
  status: PollStatus;
  meeting_id: number | undefined;
  is_read?: boolean;
  scheduled_at?: string | null;
  position: number;
  created_at?: string;
  updated_at?: string;
  created_by?: string;
  updated_by?: string;
  temporary_key?: number;
}

export interface IPollTemplate extends Omit<IPoll, "is_read" | "meeting_id" | "status" | "options" | "position"> {
  community_id: number;
  options: Omit<IPollOption, "vote_results" | "id">[];
}
export interface IPollToSubmit {
  poll: IPoll;
  callback?: (close: boolean, disabled: boolean) => void;
}
export enum EMemberRestriction {
  chat_timeout = "chat_timeout",
  chat_ban = "chat_ban",
}

export interface IRestrictMember {
  id?: number;
  member_id: number;
  event_id: number;
  restriction_type: EMemberRestriction;
  expire_at?: string | null;
  created_by: string;
}

export interface IMediaDevicesState {
  audioMuted: boolean | null;
  videoMuted: boolean | null;
}

export enum NavigationEventAction {
  default = "default",
  custom = "custom",
}
