import React, { useMemo } from "react";

import { EventTypeCard } from "../EventTypeCard";

import { Button, Overlay, StyledLink } from "shared";
import { LINKS } from "shared/constants/links";
import { EventType } from "containers/Sermons/interfaces";
import { EFeature } from "containers/Community/interfaces";
import meeting_icon from "assets/icons/meeting-icon.svg";
import event_icon from "assets/icons/event-icon.svg";

import "./styles.scss";

const EVENT_TYPE_INFO = [
  {
    type: EventType.stream,
    imgSrc: event_icon,
    name: "Event",
    description: "Create an event with a connected live or pre-recorded video source.",
  },
  {
    type: EventType.meeting,
    imgSrc: meeting_icon,
    name: "Meeting",
    description: "Create a video conference group meeting",
  },
];

type Props = {
  opened: boolean;
  features: EFeature[];
  onClose: () => void;
  onActivate: () => void;
  onSelect: (type: EventType) => void;
};
const EventTypes: React.FC<Props> = ({ opened, onClose, onSelect, onActivate, features }) => {
  const hasMeetingFeature = useMemo(() => features.includes(EFeature.meeting), [features]);
  return opened ? (
    <Overlay show={true} onClose={onClose}>
      <div className="event-types">
        <div className="event-types__header">
          <span className="close" onClick={onClose} />
        </div>
        <div className="event-types__body">
          {EVENT_TYPE_INFO.map(({ type, ...restInfo }) => (
            <EventTypeCard
              type={type}
              isDisabled={!features.length || (!hasMeetingFeature && type === EventType.meeting)}
              onSelect={onSelect}
              showPremium={!!features.length && !hasMeetingFeature && type === EventType.meeting}
              info={restInfo}
              key={type}
            />
          ))}
        </div>
        <div
          className={`event-types__footer ${!features.length || !hasMeetingFeature ? "event-types__footer--show" : ""}`}
        >
          {!features.length ? (
            <>
              <Button className="activate-account" width="176" variant="orange" onClick={onActivate}>
                Activate account
              </Button>
            </>
          ) : !hasMeetingFeature ? (
            <>
              <StyledLink className="meeting-learnMore" href={LINKS.create_meeting}>
                Learn More about Meetings
              </StyledLink>
              <Button className="activate-account" width="176" variant="orange" onClick={onActivate}>
                Upgrade Subscription
              </Button>
            </>
          ) : null}
        </div>
      </div>
    </Overlay>
  ) : null;
};

export default EventTypes;
