import React, { useState } from "react";

import { StartTourCard } from "..";

import { Button, EventSideDialog } from "shared/components";
import { EventType, IEvent } from "containers/Sermons/interfaces";
import { getInitials, useGreetingChannel } from "shared";
import { DEFAULT_GREETER_MESSAGE } from "containers/Sermons/constants";

import "./eventWelcomeDialog.scss";

interface IEventWelcomeDialog {
  open: boolean;
  event: IEvent;
  onClose?: () => void;
  onGreeterReply: (channelId?: string) => void;
  className?: string;
  onShowMeAround?: () => void;
}

const EventWelcomeDialog: React.FC<IEventWelcomeDialog> = props => {
  const { onClose, open, event, onGreeterReply, onShowMeAround } = props;

  const [showWelcomeMsg, setShowWelcomeMsg] = useState(true);
  const { greeters } = event;

  const { currentUserIsGreeter } = useGreetingChannel();

  const renderGreeter = () => {
    if (!greeters.length || event.type === EventType.meeting) {
      return null;
    }

    const image = greeters[0]?.image_url;

    const greeterName = `${greeters[0].first_name} ${greeters[0].last_name}`;

    const greeterInitials = getInitials(greeters[0]);

    const greeterMessage = event.greeter_message || DEFAULT_GREETER_MESSAGE;

    return showWelcomeMsg ? (
      <div className="eventWelcomeDialog-card">
        <div className="eventWelcomeDialog-card-header">
          <div className="greeter">
            <div className="greeter-image">
              {image ? (
                <img src={image} alt="greeter" />
              ) : (
                <div className="greeter-image-fallback">{greeterInitials}</div>
              )}
            </div>
            <div className="greeter-title">{greeterName}</div>
            <div className="greeter-subtitle">{greeters[0]?.title ? `(${greeters[0].title})` : ""}</div>
          </div>
        </div>
        <div className="eventWelcomeDialog-card-body">
          <p className="greeter-message">{greeterMessage}</p>
        </div>
        <div className="eventWelcomeDialog-card-footer">
          {!currentUserIsGreeter && (
            <>
              <Button width={90} variant="purple-text" onClick={() => setShowWelcomeMsg(false)}>
                Dismiss
              </Button>
              <Button width={152} variant="blue" onClick={() => onGreeterReply()}>
                Reply
              </Button>
            </>
          )}
        </div>
      </div>
    ) : null;
  };
  return (
    <EventSideDialog title="Welcome" open={open} onClose={onClose}>
      <div className="eventWelcomeDialog">
        {renderGreeter()}
        {event?.type !== EventType.meeting && event?.has_platform_tour !== false && (
          <StartTourCard startTour={onShowMeAround} />
        )}
      </div>
    </EventSideDialog>
  );
};

export default EventWelcomeDialog;
