import React from "react";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import classnames from "classnames";

import "./styles.scss";

interface Props {
  classNames?: string;
  title: string;
}

const compileTooltipProps = (classNames?: string) => ({
  classes: {
    popper: classnames("membersMenuTooltip", classNames),
  },
});
const MembersMenuTooltip: React.FC<Props & TooltipProps> = ({ children, title, classNames }) => {
  return (
    <Tooltip title={title} {...compileTooltipProps(classNames)} placement="bottom">
      {children}
    </Tooltip>
  );
};

export default MembersMenuTooltip;
