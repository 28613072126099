import { AvatarProps, StreamMessage } from "stream-chat-react";
import * as Client from "stream-chat";
import { Message } from "stream-chat";

import { IMember } from "../../Member";
import { IContentShape } from "../../App";

import { IEvent } from "containers/Sermons/interfaces";

export interface IChannel {
  id: string;
  name?: string;
  description?: string;
  color?: string;
  is_group: boolean;
  members: IMember[];
  creator: IMember;
  created_by: number;
  community_id: number;
  event_id: number;
  meetings: Partial<IEvent>[];
}

export interface IDiscussionsState {
  channels: IChannel[];
  selectedChannel?: IChannel;
  activeChannel?: string;
  getStreamChannels?: Client.Channel[];
  showChatList?: boolean;
  createChatWithMember?: IMember;
  unreadMessagesCount: IUnreadMessages;
  channelsIds?: Set<string>;
  sentMessagesDates: number[];
  allowGeneralChatInput: boolean;
  showGreeterCustomChannel: boolean;
  createdGeneralChatId: string | null;
}

export type IGetChannelsParams = IContentShape;

export interface IGetChannels {
  community_id: number;
  is_group?: boolean;
  params?: IGetChannelsParams;
  callback?: () => void;
}

export interface IDiscussionDetails {
  channel: IChannel;
  isChannelOwner?: boolean;
  channelName?: string;
}

export interface ICustomAvatar extends AvatarProps {
  color?: string;
  handleClick?: () => void;
  isOnline?: boolean;
  member?: IMember | null;
  classNames?: string;
  mark?: boolean;
}

export interface EditMessageObj {
  message?: StreamMessage;
  clearEditingState?: (response?: { message: Message }) => void;
}

export interface ICreateNewChat {
  community_id: number;
  members: string[];
  name?: string;
  meeting_id?: number;
  sendGreeterMsg?: boolean;
}

export interface ICreateGeneralChat {
  meeting_id: number;
}

export interface IUpdateChannel {
  community_id: number;
  id: string;
  name?: string;
  meeting_id?: number;
  members: string[];
  description?: string;
}

export interface IUnreadMessages {
  personalUnread: number;
  eventGeneralUnread: number;
}

export enum IChannelTypes {
  livestream = "livestream",
  messaging = "messaging",
  team = "team",
}

export enum IChannelEventTypes {
  messageNewEvent = "message.new",
}

export interface IAddModerators {
  memberIds: string[];
  meeting_id: number;
  channel_id: string;
}
